import { environment } from '../environments/environment'
export class Constants {
  // web url
  static apiAddress = environment.apiAddress; // Enivornment URL

  static apiClient = environment.apiClient; // Enivornment URL

  static businessUrl = environment.businessUrl; // Enivornment URL

  static partnerUrl = environment.partnerUrl; // Enivornment URL

  static websiteUrl = environment.websiteUrl; // Enivornment URL

  static paymentUrl = environment.paymentUrl; // Enivornment URL


  static colorCode = 'rgb(46, 46, 84)';

  static iconForSinglePrice = '£ ';
  static iconForDoublePrice = '£ ';

  static domainNmae = '1212';
}
