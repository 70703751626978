import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {CountryService} from '../country.service';
import {DataService} from '../data.service';
import { DataControlService } from '../data-control.service';
import { Constants } from '../constants';

declare var $: any;
declare var Nanobar: any;
declare var toastr: any;
@Component({
  selector: 'app-download-qr',
  templateUrl: './download-qr.component.html',
  styleUrls: ['./download-qr.component.css']
})
export class DownloadQrComponent implements OnInit {

  submitBtn = false;
  number: any;
  submitData: any;
  store = '';
  chkDeviceTrue = false;
  colorCode: string;

  constructor(private dataControlService: DataControlService, private router: Router, private cs: CountryService, private dataService: DataService) {
    this.colorCode = Constants.colorCode;
    if (/android/i.test(navigator.userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=co.bestbuymall.delivery';
    }

    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      window.location.href = 'https://itunes.apple.com/pk/app/best-buy-mall/id1457338823?ls=1&mt=8';
    }

    this.router.navigate(['/']);

    // if (/android/i.test(navigator.userAgent)) {
    //   window.location.href = 'https://play.google.com/store/apps/details?id=co.bestbuymall.delivery';
    //   this.store = 'Play Store';
    // } else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    //   this.chkDeviceTrue = true;
    //   this.store = 'Apple Store';
    // } else {
    //   this.router.navigate(['/']);
    // }

  }

  submitNumber() {
    if ( this.number > 9999999999 || this.number < 999999999 || this.number === null || this.number === undefined ) {
      toastr.error('Please enter valid phone number');
    } else {
      this.dataService.addPhoneNoForDownload(window.localStorage.getItem('token'), this.number).subscribe(
        (data) => {
          this.submitData = data;
          if( this.submitData.success === true ) {
            this.submitBtn = true;
          }
        },
        (error) => {
        }
      );
    }
  }

  ngOnInit() {
    localStorage.setItem('isFirstTimeHomeOpen', "false");
    //this.dataControlService.setHeaderAddressVisiblity.next(true);

    window.scrollTo(0, 0);
    // $('.navbar').removeClass('sticky-header');
    $('.storyBackBtn').css('display', 'none');
    $('.navbar').css('background-color', '#fff');
    // $('.navbar').css('position', 'relative');
    $('.logInbtn').css('color', '#FFFFFF');
    $('.navbar').css('border-bottom', '1px solid #ebebeb');
    $('#whiteLogo').css('display', 'none');
    $('#blackLogo').css('display', 'inline-block');
    $('.cartHeadBtn').css('background', this.colorCode);
    $('.cartHeadBtn').css('color', '#ffffff');
    $('.topName').css('color', this.colorCode);
    $('.username').css('color', '#000000');
    $('.business-logo').css('display', 'none');
    $('nav').css('display', 'none');
    $('footer').css('display', 'none');
    $('.navbar-header').css('margin-top', '0px');
  }

}

