import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import {CountryService} from '../country.service';
import {EventsService} from 'angular4-events';
import {DataService} from '../data.service';
import {Router} from '@angular/router';
import { Constants } from '../constants';

declare var firebase: any;
declare var Nanobar: any;
declare var context: any;
declare var toastr: any;
declare var jQuery: any;
declare var google: any;
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  //  @ViewChild('mapCanvas2') mapElement2: ElementRef;
  // nao = new Nanobar();
  // firstName: any;
  // lastName: any;
  // dob: any;
  // areaData: any;
  // cityId: any;
  // validateSuccessTrue: any;
  // place: any;
  // title = 'app';
  // number = '';
  // numberToSend = '';
  // street = '';
  // streetDelivery = '';
  // pushReceive: any;
  // address_line1: any;
  // cartPush: any;
  // cityname = 'Pleas Select your location';
  // emptyValue = '';
  // getCartItems: any;
  // carBtnShowHide = false;
  // cartCount = 0;
  // customArray = new Array();
  // cartDataFromStorage = new Array();
  // chkOutTotal = 0;
  // clearTimeout: any;
  // placeSuggestionsData: any;
  // placeSuggestionsDataDelivery: any;
  // placeDetailData: any;
  // placeDetailDataDelivery: any;
  // placeDataFound = false;
  // addressLoad = false;
  // addressLoadDelivery = false;
  // latitude: any;
  // longitude: any;
  // latitudeDelivery: any;
  // longitudeDelivery: any;
  // zip = '';
  // cityList: any;
  // validateSuccess: any;
  // showCrossIcon = false;
  // loadingData = true;
  // maxYear: any;
  // loginBtn = true;
  // loginData: any;
  // code: any;
  // userToken: any;
  // userName: any;
  // userData: any;
  // userLogoutSuccess: any;
  // show_marker: any;
  // cityDelivery: any;
  // stateDelivery: any;
  // zipDelivery: any;
  // error_count = 0;
  // deliveryAddressObj: any;
  // areaDelivery: any;
  // checkoutData: any;
  // checkoutErrorMessage = '';
  // checkoutSuccess: any;
  // numberToDisplay: any;
  // intervalSet: any;
  // reSendCount = 60;
  // orderNotes = '';
  // apartDelivery = '';
  // resendButtonShow = false;
  // codeError = '';
  // paymentMethodOptionValue = -1;
  // get globalAddress(): any {
  //   return localStorage.getItem('delivery_address');
  // }
  // get authChk(): any {
  //   return localStorage.getItem('isauthenticated');
  // }
  // get getUserName(): any {
  //   return localStorage.getItem('displayName');
  // }

  colorCode: string;

  constructor(private authService: AuthenticationService, private dataService: DataService, private events: EventsService,
              private changeRef: ChangeDetectorRef, private router: Router, private cs: CountryService) {
    this.colorCode = Constants.colorCode;
    // console.log('authChk', this.authChk);
    // console.log('latitudeDelivery', this.latitudeDelivery);
    // console.log('deliveryAddressObj', this.deliveryAddressObj);
    // this.getCartItems = JSON.parse(localStorage.getItem('cartItems'));
    // if (this.globalAddress === null) {
    //   window.localStorage.setItem('delivery_address', 'Please Select your location');
    // }
    // if (this.getCartItems === null) {
    //       this.carBtnShowHide = false;
    //       this.cartCount = 0;
    //     } else {
    //   if (this.getCartItems.length > 0) {
    //     this.cartCount = this.getCartItems.length;
    //     this.carBtnShowHide = true;
    //     }
    //     }
    // console.log('this.getCartItems', this.getCartItems);
    // // this.pushReceive = this.events.subscribe('dataChange').subscribe((data) => {
    // //   this.cityname = data;
    // //   console.log('push receive data', data);
    // // });
    // this.cartPush = this.events.subscribe('cartUpdate').subscribe((data) => {
    //   window.localStorage.setItem('cartItems', JSON.stringify(data));
    //   setTimeout(() => {
    //     this.getCartItems = JSON.parse(localStorage.getItem('cartItems'));
    //     console.log('this.getCartItems first item asakd,sahd', this.getCartItems);
    //     if (this.getCartItems.length > 0) {
    //       this.carBtnShowHide = true;
    //       this.cartCount = this.getCartItems.length;
    //       //this.changeRef.detectChanges();
    //       console.log('carBtnShowHide', this.carBtnShowHide);
    //        if (this.dataService.pageName === 'businesses') {
    //          $('.cartHeadBtn').css('background', '#2E2E54');
    //             $('.cartHeadBtn').css('color', '#ffffff');
    //        }
    //     } else {
    //       this.carBtnShowHide = false;
    //       console.log('carBtnShowHideelse', this.carBtnShowHide);
    //       //this.changeRef.detectChanges();
    //     }
    //     }, 500);
    //   // console.log('cart updated', data);
    // });
  }
//   GetUnique(inputArray) {
//     var outputArray = [];
//     for (var i = 0; i < inputArray.length; i++) {
//         if ((jQuery.inArray(inputArray[i].business_id, outputArray)) === -1) {
//             outputArray.push(inputArray[i].business_id);
//         }
//     }
//     return outputArray;
// }
//   openFinalCartPop() {
//     this.customArray = [];
//     this.chkOutTotal = 0;
//     this.orderNotes = '';
//     this.getCartItems = JSON.parse(localStorage.getItem('cartItems'));
//     console.log('this.getCartItems', this.getCartItems);
//     const outputData = this.GetUnique(this.getCartItems);
//     const array = [];
//     let obj;
//     let orders = [];
//     let store_index;
//     console.log('outputData', outputData);
//     for (let i = 0; i < outputData.length; i++) {
//       orders = [];
//       for (let j = 0; j < this.getCartItems.length; j++) {
//         if (outputData[i] === this.getCartItems[j].business_id) {
//           store_index = j;
//           orders.push({
//               product_id: this.getCartItems[j].product_id,
//               product_name: this.getCartItems[j].product_name,
//               product_image: this.getCartItems[j].product_image,
//               variant_id: this.getCartItems[j].variant_id,
//               variant_name: this.getCartItems[j].variant_name,
//               variant_value: this.getCartItems[j].variant_value,
//               price: this.getCartItems[j].price,
//               quantity: this.getCartItems[j].quantity
//           });
//         console.log('orders', orders);
//         }
//       }
//       let sum = 0;
//               for (let a = 0; a < orders.length; a++) {
//                 sum = sum + (orders[a].quantity * orders[a].price);
//               }
//       obj = {
//             'dispensary_id': this.getCartItems[store_index].business_id,
//             'business_image': this.getCartItems[store_index].business_image,
//             'business_name': this.getCartItems[store_index].business_name,
//             'outlet_address': this.getCartItems[store_index].outlet_address,
//             'discount': 0,
//             'products': orders,
//             'sub_total': sum,
//             'delivery_charges': 4.99,
//             'tax_charges': sum * 0.1,
//             'total_price': sum + 4.99 + (sum * 0.1)
//
//           };
//       this.customArray.push(obj);
//     }
//     for (let t = 0; t < this.customArray.length; t++) {
//       this.chkOutTotal = this.chkOutTotal + this.customArray[t].total_price;
//     }
//     console.log('customArray', this.customArray);
//     $('#chkoutPop').modal('show');
//   }
//   quantityPlus(parentIndex, index, quantity, obj) {
//     this.chkOutTotal = 0;
//     console.log('obj chk for plus', obj, parentIndex, index, quantity );
//     this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
//     this.customArray[parentIndex].products[index].quantity = quantity + 1;
//     // console.log('obj price', obj.price);
//     // console.log('this.customArray[parentIndex].sub_total', this.customArray[parentIndex].sub_total);
//     this.customArray[parentIndex].sub_total = this.customArray[parentIndex].sub_total + obj.price;
//     this.customArray[parentIndex].tax_charges = this.customArray[parentIndex].sub_total * 0.1;
//     this.customArray[parentIndex].total_price = this.customArray[parentIndex].sub_total + this.customArray[parentIndex].delivery_charges + (this.customArray[parentIndex].sub_total * 0.1);
//     for (let i = 0; i < this.cartDataFromStorage.length; i++) {
//       if (this.cartDataFromStorage[i].variant_id === obj.variant_id) {
//       this.cartDataFromStorage[i].quantity = quantity + 1;
//       this.events.publish('cartUpdate', this.cartDataFromStorage);
//       }
//     }
//     for (let t = 0; t < this.customArray.length; t++) {
//       this.chkOutTotal = this.chkOutTotal + this.customArray[t].total_price;
//     }
//     console.log('this.customArray', this.customArray);
//   }
//   quantityMinus(parentIndex, index, quantity, obj) {
//     this.chkOutTotal = 0;
//     console.log('obj chk for plus', obj, parentIndex, index, quantity);
//     this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
//     this.customArray[parentIndex].products[index].quantity = quantity - 1;
//     this.customArray[parentIndex].sub_total = this.customArray[parentIndex].sub_total - obj.price;
//     this.customArray[parentIndex].tax_charges = this.customArray[parentIndex].sub_total * 0.1;
//     this.customArray[parentIndex].total_price = this.customArray[parentIndex].sub_total + this.customArray[parentIndex].delivery_charges + (this.customArray[parentIndex].sub_total * 0.1);
//     for (let i = 0; i < this.cartDataFromStorage.length; i++) {
//       if (this.cartDataFromStorage[i].variant_id === obj.variant_id) {
//         this.cartDataFromStorage[i].quantity = quantity - 1;
//         this.events.publish('cartUpdate', this.cartDataFromStorage);
//       }
//     }
//     for (let t = 0; t < this.customArray.length; t++) {
//       this.chkOutTotal = this.chkOutTotal + this.customArray[t].total_price;
//     }
//     console.log('this.customArray[parentIndex].orders.length', this.customArray[parentIndex].products.length);
//     // if (this.customArray[parentIndex].orders.length ===  0) {
//     //     this.customArray.splice(parentIndex, 1);
//     //     this.cartDataFromStorage.splice(index, 1);
//     if (this.customArray[parentIndex].products[index].quantity < 1) {
//       console.log('in less than 1', this.customArray[parentIndex].products[index].quantity);
//       console.log('in less than index', index);
//       this.customArray[parentIndex].products.splice(index, 1);
//       for (let a = 0; a < this.cartDataFromStorage.length; a++) {
//       if (this.cartDataFromStorage[a].variant_id === obj.variant_id) {
//         this.cartDataFromStorage.splice(a, 1);
//       }
//     }
//       this.chkOutTotal = this.chkOutTotal - this.customArray[parentIndex].delivery_charges;
//     }
//     if (this.customArray[parentIndex].products.length ===  0) {
//       console.log('in EQUAL TO 0', this.customArray[parentIndex].products.length);
//         this.customArray.splice(parentIndex, 1);
//         // this.cartDataFromStorage.splice(index, 1);
//     }
//     this.events.publish('cartUpdate', this.cartDataFromStorage);
//     if (this.customArray.length === 0) {
//       $('#chkoutPop').modal('hide');
//     }
//     console.log('this.customArray', this.customArray);
//   }
//   checkoutFunction() {
//     this.checkoutErrorMessage = '';
//     if (this.deliveryAddressObj === undefined) {
//       this.checkoutErrorMessage = 'please enter your delivery address';
//       return;
//     }
//     console.log('this.deliveryAddressObj', this.deliveryAddressObj);
//     console.log('this.customArray', this.customArray);
//      this.dataService.finalCheckout(window.localStorage.getItem('token'), this.customArray, this.deliveryAddressObj, this.paymentMethodOptionValue).subscribe(
//       (data) => {
//         this.checkoutData = data;
//         this.checkoutSuccess = this.checkoutData.success;
//         if (this.checkoutData.rest_error_code === 2) {
//           $('#signIn').modal('show');
//         }
//         if (this.checkoutSuccess === true) {
//           $('#chkoutPop').modal('hide');
//           this.orderNotes = '';
//           this.events.publish('cartUpdate', null);
//           this.carBtnShowHide = false;
//         }
//       },
//       (error) => {
//       }
//     );
//   }
//   openAddressPopDeaher () {
//     this.address_line1 = '';
//     this.zip = '';
//     $('#headAddress').modal('show');
//   }
//   functionTogetPlace () {
//     this.placeDataFound = false;
//     this.placeSuggestionsData = [];
//     console.log()
//     clearTimeout(this.clearTimeout);
//     this.clearTimeout = setTimeout(() => {
//       // this.addressLoad = true;
//      this.dataService.googleAddress(this.address_line1).subscribe(
//       (data) => {
//         this.placeSuggestionsData = data;
//         this.placeSuggestionsData = this.placeSuggestionsData.predictions;
//         // console.log('place google data', this.placeSuggestionsData);
//         this.placeDataFound = true;
//         if ($('.myDrop').hasClass('open')) {
//
//         } else {
//           $('.myDrop').addClass('open');
//         }
//         // this.addressLoad = false;
//         // $('#searchTextField').click();
//         // $('#searchTextField').focus();
//       },
//       (error) => {
//       }
//     );
//      }, 1000);
//   }
//   functionTogetPlaceDetail (name, id) {
//     this.addressLoad = true;
//     this.address_line1 = name;
//     this.cityname = name;
//     clearTimeout(this.clearTimeout);
//     this.clearTimeout = setTimeout(() => {
//      this.dataService.googleAddressDetail(id).subscribe(
//       (data) => {
//         // console.log('place google detail data', data);
//         this.placeDetailData = data;
//         this.placeDetailData = this.placeDetailData.result;
//         this.latitude = this.placeDetailData.geometry.location.lat;
//         this.longitude = this.placeDetailData.geometry.location.lng;
//         // console.log('this.latitude', this.latitude);
//         // console.log('this.longitude', this.longitude);
//         this.placeDetailData = this.placeDetailData.address_components;
//         setTimeout(() => {
//           this.addressDetail();
//           this.addressLoad = false;
//         }, 1000);
//       },
//       (error) => {
//       }
//     );
//      }, 1000);
//   }
//   addressDetail() {
//     this.street = '';
//     // console.log('chk address detail', this.place);
//      for(var i = 0; i < this.placeDetailData.length; i++) {
//       for(var j = 0; j < this.placeDetailData[i].types.length; j++) {
//         // if (this.place.address_components[i].types[j] === 'administrative_area_level_2') {
//         //   // this.city = this.place.address_components[i].long_name;
//         //   // // console.log('city', this.city);
//         //    $('#city').val(this.place.address_components[i].long_name);
//         // }
//         if (this.placeDetailData[i].types[j] === 'locality') {
//           // this.city = this.place.address_components[i].long_name;
//           // // console.log('city', this.city);
//            $('#city').val(this.placeDetailData[i].long_name);
//         }
//          if (this.placeDetailData[i].types[j] === 'street_number') {
//           this.street = this.placeDetailData[i].long_name;
//           // console.log('street number', this.street);
//         }
//         if (this.placeDetailData[i].types[j] === 'route') {
//           this.street = this.street + ' ' + this.placeDetailData[i].long_name;
//           // console.log('route', this.street);
//         }
//         if (this.placeDetailData[i].types[j] === 'sublocality_level_2') {
//           this.street = this.street + ' ' + this.placeDetailData[i].long_name;
//           // console.log('sublocality 2', this.street);
//         }
//         if (this.placeDetailData[i].types[j] === 'sublocality_level_1') {
//           this.street = this.street + ' ' + this.placeDetailData[i].long_name;
//           // console.log('sublocality 1', this.street);
//         }
//         //   this.street = this.place.name;
//         //   // console.log('this.street', this.street);
//
//         if (this.placeDetailData[i].types[j] === 'administrative_area_level_1') {
//           $('#state').val(this.placeDetailData[i].short_name);
//         }
//
//         if (this.placeDetailData[i].types[j] === 'country') {
//           // this.area = this.place.address_components[i].long_name;
//           $('#area').val(this.placeDetailData[i].long_name);
//         }
//
//         if (this.placeDetailData[i].types[j] === 'postal_code') {
//           this.zip = this.placeDetailData[i].long_name;
//           // console.log('postal; code', this.placeDetailData[i].long_name);
//           $('#zip').val(this.placeDetailData[i].long_name);
//         } else {
//             $('#zip').val('');
//         }
//         }
//       }
//       if (this.zip === '' || this.zip === undefined) {
//             this.zip = '';
//             $('#showZipPop').modal('show');
//           } else {
//             this.validateCityFunction(this.zip);
//         }
//     }
//      validateCityFunction(zip) {
//       this.address_line1 = '';
//       //this.changeRef.detectChanges();
//     $('.c-label label').css('color', '#3d3e3d');
//     $('.c-label input').css('border-color', '#ccc');
//     if (zip === '' || zip === undefined) {
//       $('#c-zip label').css('color', '#a10a0d');
//       $('#c-zip input').css('border-color', '#a10a0d');
//       return;
//     }
//     this.nao.go(30);
//     this.dataService.validateCity(zip).subscribe(
//       (data) => {
//         this.nao.go(100);
//         this.cityList = data;
//         this.cityList = this.cityList.city_list;
//             //this.changeRef.detectChanges();
//         console.log('this.cityList header', this.cityList);
//
//         this.validateSuccess = data;
//         if (this.validateSuccess.rest_error_code === 4) {
//           console.log('this.citylist', this.cityList);
//           $('#availableCityPop2').modal('show');
//           // toastr.error('We don not provide services in this area');
//         }
//         //this.changeRef.detectChanges();
//         this.cityId = this.validateSuccess.city_list[0].id;
//         this.validateSuccessTrue = this.validateSuccess.success;
//         // console.log('this.validateSuccessTrue', this.validateSuccessTrue);
//         if (this.validateSuccessTrue === true) {
//           //this.changeRef.detectChanges();
//           window.localStorage.setItem('delivery_address', this.cityname);
//           this.events.publish('dataChange', this.cityId);
//           $('#showZipPop').modal('hide');
//           $('#headAddress').modal('hide');
//           // setTimeout(() => {
//           // // this.router.navigate(['/businesses', this.cityId]);
//           // this.router.navigate(['/businesses', this.validateSuccess.city_list[0].city_short_name]);
//           // }, 1000);
//         }
//       },
//       (error) => {
//         this.nao.go(100);
//       }
//     );
//   }
//   signInPop() {
//     this.number = '';
//     this.firstName = '';
//     this.lastName = '';
//     this.code = '';
//     $('#datepicker').val('');
//     $('#signIn').modal('show');
//     // $('#codepop').modal('show');
//   }
//   restrictUserInput($event) {
//     $event.preventDefault();
//   }
//   countryValidation() {
//     var code = $('#phone').intlTelInput('getSelectedCountryData').dialCode;
//     code = '+' + code;
//     var val = $('#phoneValue').val();
//     for (var i = 0; i < this.cs.country.length; i++) {
//       if (code === this.cs.country[i].countryCode) {
//         // console.log('val.length', val.length, this.cs.country[i].length);
//         if (val.length === this.cs.country[i].length) {
//           console.log('valid');
//           this.loginBtn = false;
//           // $('#phoneValue').css('border-color', '#ccc');
//         } else {
//           // console.log('invalid');
//           this.loginBtn = true;
//           // $('#phoneValue').css('border-color', '#eb3b3b');
//         }
//       }
//     }
//   }
//   prevent($event) {
//    console.log('prevent default', $event.keyCode);
//    if ($event.keyCode === 8 || $event.keyCode === 46) {
//       return;
//     }
//    if (!($event.keyCode >= 48 && $event.keyCode <= 57)) {
//       $event.preventDefault();
//       return;
//     }
//  }
//  isNumberKey(evt) {
//     var charCode = (evt.which) ? evt.which : evt.keyCode;
// 				if (charCode != 46 && charCode > 31
//           && (charCode < 48 || charCode > 57))
// 				  return false;
// 				  return true;
//   }
//   signInFunction() {
//     this.loginBtn = true;
//     this.codeError = '';
//     clearInterval(this.intervalSet);
//     this.reSendCount = 20;
//     this.resendButtonShow = false;
//     let getCode = $('#phone').intlTelInput('getSelectedCountryData').dialCode;
//     this.numberToSend = '+' + getCode + this.number;
//     this.nao.go(30);
//     console.log('getCode', getCode);
//     // if (getCode === '1') {
//     this.numberToDisplay = this.numberToSend;
//           this.numberToDisplay = format(this.numberToDisplay, 'International');
//     // }
//     this.authService.login(this.numberToSend).subscribe(
//       (data) => {
//         this.nao.go(100);
//         this.loginData = data;
//         if (this.loginData.rest_error_code === 4) {
//           $('#signIn').modal('hide');
//           setTimeout(() => {
//           $('#signUp').modal('show');
//           }, 1000);
//           // clearInterval(this.intervalSet);
//         } else {
//           $('#signIn').modal('hide');
//           setTimeout(() => {
//           $('#codePop').modal('show');
//           }, 1000);
//         }
//         this.intervalSet = setInterval(() => {
//            this.reSendCount = this.reSendCount - 1;
//            if (this.reSendCount === 0) {
//              // this.signInFunction();
//              // this.reSendCount = 20;
//              clearInterval(this.intervalSet);
//              this.resendButtonShow = true;
//            }
//            }, 1000);
//       },
//       (error) => {
//         this.nao.go(100);
//       }
//     );
//   }
//   clearIntervel() {
//     clearInterval(this.intervalSet);
//   }
//   registerFunction() {
//     this.codeError = '';
//     this.dob = $('#datepicker').val();
//     this.nao.go(30);
//     this.authService.register(this.numberToSend, this.code, true, this.firstName, this.lastName, this.dob).subscribe(
//       (data) => {
//         this.nao.go(100);
//         this.userData = data;
//         console.log('this.userData', this.userData);
//         if (this.userData.rest_error_code === 4) {
//           this.codeError = 'Please enter a valid code';
//           return;
//         }
//         console.log('this.userData', this.userData);
//         window.localStorage.setItem('isauthenticated', 'true');
//         window.localStorage.setItem('token', this.userData.auth_token);
//         this.userData = this.userData.profile;
//         console.log('this.userData fior profile', this.userData);
//         window.localStorage.setItem('displayName', this.userData.first_name + ' ' + this.userData.last_name);
//         setTimeout(() => {
//         if (this.dataService.pageName === 'home') {
//           console.log('in home page section if');
//          if (window.pageYOffset === 0 || window.pageYOffset < 69) {
//            $('.username').css('color', '#ffffff');
//            console.log('in home page section if offset scroll', window.pageYOffset);
//          }
//         }
//         }, 1500);
//         $('#signUp').modal('hide');
//         $('#signIn').modal('hide');
//         $('#codePop').modal('hide');
//         clearInterval(this.intervalSet);
//       },
//       (error) => {
//         this.nao.go(100);
//       }
//     );
//   }
//   codeSendFunction() {
//     this.dob = $('#datepicker').val();
//     this.codeError = '';
//     this.nao.go(30);
//     this.authService.register(this.numberToSend, this.code, false, this.firstName, this.lastName, this.dob).subscribe(
//       (data) => {
//         this.nao.go(100);
//         this.userData = data;
//         console.log('this.userData', this.userData);
//         if (this.userData.rest_error_code === 4) {
//           this.codeError = 'Please enter a valid code';
//           return;
//         }
//         window.localStorage.setItem('isauthenticated', 'true');
//         window.localStorage.setItem('token', this.userData.auth_token);
//         this.userData = this.userData.profile;
//         console.log('this.userData fior profile', this.userData);
//         window.localStorage.setItem('displayName', this.userData.first_name + ' ' + this.userData.last_name);
//         window.localStorage.setItem('userImage', this.userData.img_url);
//         this.events.publish('inlogin', 'true');
//         setTimeout(() => {
//         if (this.dataService.pageName === 'home') {
//           console.log('in home page section if');
//          if (window.pageYOffset === 0 || window.pageYOffset < 69) {
//            console.log('in home page section if offset scroll', window.pageYOffset);
//            $('.username').css('color', '#ffffff');
//          }
//         }
//         }, 1500);
//         $('#signUp').modal('hide');
//         $('#signIn').modal('hide');
//         $('#codePop').modal('hide');
//         clearInterval(this.intervalSet);
//       },
//       (error) => {
//         this.nao.go(100);
//       }
//     );
//   }
//   logoutFunction() {
//     this.nao.go(30);
//     this.authService.logout(window.localStorage.getItem('token')).subscribe(
//       (data) => {
//         console.log('login data', data);
//         this.nao.go(100);
//         this.userLogoutSuccess = data;
//         this.userLogoutSuccess = this.userLogoutSuccess.success;
//         // console.log('logout chk', this.userSuccess);
//         if (this.userLogoutSuccess === true) {
//               window.localStorage.setItem('isauthenticated', 'false');
//               window.localStorage.setItem('token', '');
//               window.localStorage.setItem('displayName', '');
//               window.localStorage.setItem('notificationAllow', '');
//               this.events.publish('inlogin', 'false');
//               // this.router.navigate(['']);
//           setTimeout(() => {
//             var width = $(window).width();
//             if (width >= 769) {
//               if (this.dataService.pageName === 'home') {
//                 console.log('in home page section if');
//                 if (window.pageYOffset === 0 || window.pageYOffset < 69) {
//                   console.log('in home page section if offset scroll', window.pageYOffset);
//                   $('.logInbtn').css('color', '#ffffff');
//                 } else {
//                   $('.logInbtn').css('color', '#2E2E54');
//                 }
//               }
//             } else {
//               $('.logInbtn').css('color', '#2E2E54');
//             }
//         if (this.dataService.pageName === 'businesses') {
//              $('.logInbtn').css('color', '#2E2E54');
//         }
//         }, 1500);
//         }
//       },
//       (error) => {
//         this.nao.go(100);
//         console.log('error', error);
//       }
//     );
//   }
//   openAddressPop() {
//     // this.getcurrentLocation();
//     $('#deliveryAddressPop').modal('show');
//   }
//   initMap() {
//     var context = this;
//     var map;
//     var marker;
//     var adr;
//     var myLatlng = new google.maps.LatLng( this.latitudeDelivery , this.longitudeDelivery);
//     var geocoder = new google.maps.Geocoder();
//     var infowindow = new google.maps.InfoWindow();
//     var mapOptions = {
//       zoom: 14,
//       center: myLatlng,
//       mapTypeId: google.maps.MapTypeId.ROADMAP,
//       navigationControl: false,
//       // mapTypeControl: false,
//       // disableDefaultUI: true
//     };
//     var endImage = {
//       url: 'assets/pin.png', // url
//       scaledSize: new google.maps.Size(50, 56), // scaled size
//       origin: new google.maps.Point(0, 0), // origin
//       anchor: new google.maps.Point(16, 16) // anchor
//     };
//     map = new google.maps.Map(document.getElementById('myMap'), mapOptions);
// // if ( context.show_marker !== true ) {
//     marker = new google.maps.Marker({
//       map: map,
//       position: myLatlng,
//       draggable: true,
//       icon: endImage,
//     });
// // }
//     // google.maps.event.addListener(marker, 'dragend', function () {
//     //   map.setCenter(this.getPosition());
//     //   updatePosition(this.getPosition().lat(), this.getPosition().lng());
//     // });
//     //
//     // google.maps.event.addListener(map, 'drag', function () {
//     //   marker.setPosition(this.getCenter());
//     //   updatePosition(this.getCenter().lat(), this.getCenter().lng());
//     // });
//     //
//     // google.maps.event.addListener(map, 'dragend', function () {
//     //   marker.setPosition(this.getCenter());
//     //   updatePosition(this.getCenter().lat(), this.getCenter().lng());
//     // });
//     // function updatePosition(lat, lng) {
//     //   context.latitude = lat;
//     //   context.longitude = lng;
//     // }
//
//
//
//     geocoder.geocode({'latLng': myLatlng }, function(results, status) {
//       if (status === google.maps.GeocoderStatus.OK) {
//         if (results[0]) {
//           // $('#address').val(results[0].formatted_address);
//           // $('#latitude').val(marker.getPosition().lat());
//           // $('#longitude').val(marker.getPosition().lng());
//           infowindow.setContent(results[0].formatted_address);
//           infowindow.open(map, marker);
//           context.latitudeDelivery = marker.getPosition().lat();
//           context.longitudeDelivery = marker.getPosition().lng();
//           console.log( context.latitudeDelivery, context.longitudeDelivery);
//           console.log('results', results);
//           context.getDeliveryAddressDetail(results[0]);
//
//         }
//       }
//     });
//
//
//     google.maps.event.addListener(marker, 'dragend', function() {
//       geocoder.geocode({'latLng': marker.getPosition()}, function(results, status) {
//         if (status === google.maps.GeocoderStatus.OK) {
//           if (results[0]) {
//             // $('#address').val(results[0].formatted_address);
//             // $('#latitude').val(marker.getPosition().lat());
//             // $('#longitude').val(marker.getPosition().lng());
//             // infowindow.setContent(results[0].formatted_address);
//             // infowindow.open(map, marker);
//             context.latitudeDelivery = marker.getPosition().lat();
//             context.longitudeDelivery = marker.getPosition().lng();
//             // context.addressNew =  results[0].formatted_address;
//             console.log( context.latitudeDelivery, context.longitudeDelivery);
//             console.log('results', results);
//             // //this.changeRef.detectChanges();
//             // context.getDeliveryAddressDetail(results[0]);
//           }
//         }
//       });
//     });
//   }
//  async getDeliveryAddressDetail (result) {
//     console.log('in address detail', result);
//     this.streetDelivery = '';
//     this.cityDelivery = '';
//     this.stateDelivery = '';
//     this.zipDelivery = '';
//     this.zip = '';
//     //this.changeRef.detectChanges();
//     for(var i = 0; i < result.address_components.length; i++) {
//       for(var j = 0; j < result.address_components[i].types.length; j++) {
//         // if (this.place.address_components[i].types[j] === 'administrative_area_level_2') {
//         //   // this.city = this.place.address_components[i].long_name;
//         //   // // console.log('city', this.city);
//         //    $('#city').val(this.place.address_components[i].long_name);
//         // }
//         if (result.address_components[i].types[j] === 'locality') {
//           // this.city = this.place.address_components[i].long_name;
//           // // console.log('city', this.city);
//            this.cityDelivery = result.address_components[i].long_name;
//            //this.changeRef.detectChanges();
//         }
//          if (result.address_components[i].types[j] === 'street_number') {
//           this.streetDelivery = result.address_components[i].long_name;
//           //this.changeRef.detectChanges();
//           // console.log('street number', this.street);
//         }
//         if (result.address_components[i].types[j] === 'route') {
//           this.streetDelivery = this.streetDelivery + ' ' + result.address_components[i].long_name;
//           //this.changeRef.detectChanges();
//           // console.log('route', this.street);
//         }
//         if (result.address_components[i].types[j] === 'sublocality_level_2') {
//           this.streetDelivery = this.streetDelivery + ' ' + result.address_components[i].long_name;
//           //this.changeRef.detectChanges();
//           // console.log('sublocality 2', this.street);
//         }
//         if (result.address_components[i].types[j] === 'sublocality_level_1') {
//           this.streetDelivery = this.streetDelivery + ' ' + result.address_components[i].long_name;
//           //this.changeRef.detectChanges();
//           // console.log('sublocality 1', this.street);
//         }
//         //   this.street = this.place.name;
//         //   // console.log('this.street', this.street);
//
//         if (result.address_components[i].types[j] === 'administrative_area_level_1') {
//           this.stateDelivery = result.address_components[i].short_name;
//           //this.changeRef.detectChanges();
//         }
//
//         if (result.address_components[i].types[j] === 'country') {
//           // this.area = this.place.address_components[i].long_name;
//           this.areaDelivery = result.address_components[i].long_name;
//         }
//
//         if (result.address_components[i].types[j] === 'postal_code') {
//           this.zipDelivery = result.address_components[i].long_name;
//           //this.changeRef.detectChanges();
//           // console.log('postal; code', this.placeDetailData[i].long_name);
//           this.zipDelivery = result.address_components[i].long_name;
//           //this.changeRef.detectChanges();
//         }
//         // if (this.zipDelivery === '' || this.zipDelivery === undefined) {
//         //     this.zipDelivery = '';
//         //     $('#showZipPop').modal('show');
//         //   } else {
//         //     this.validateCityFunction(this.zipDelivery);
//         // }
//         // } else {
//         //     this.zipDelivery = '';
//         // }
//         }
//       }
//       this.initMap();
//   }
//    functionTogetPlaceDelivery () {
//     this.placeDataFound = false;
//     this.placeSuggestionsDataDelivery = [];
//     clearTimeout(this.clearTimeout);
//     this.clearTimeout = setTimeout(() => {
//       this.addressLoadDelivery = true;
//      this.dataService.googleAddress(this.streetDelivery).subscribe(
//       (data) => {
//         this.placeSuggestionsDataDelivery = data;
//         this.placeSuggestionsDataDelivery = this.placeSuggestionsDataDelivery.predictions;
//         // console.log('place google data', this.placeSuggestionsData);
//         this.placeDataFound = true;
//         if ($('.myDrop').hasClass('open')) {
//
//         } else {
//           $('.myDrop').addClass('open');
//         }
//         this.addressLoad = false;
//         // $('#searchTextField').click();
//         // $('#searchTextField').focus();
//       },
//       (error) => {
//       }
//     );
//      }, 1000);
//   }
//   functionTogetPlaceDetailDelivery (name, id) {
//     this.addressLoadDelivery = true;
//     // this.address_line1 = name;
//     // this.cityname = name;
//     clearTimeout(this.clearTimeout);
//     this.clearTimeout = setTimeout(() => {
//      this.dataService.googleAddressDetail(id).subscribe(
//       (data) => {
//         // console.log('place google detail data', data);
//         this.placeDetailDataDelivery = data;
//         console.log('this.placeDetailDataDelivery', this.placeDetailDataDelivery);
//         this.placeDetailDataDelivery = this.placeDetailDataDelivery.result;
//         this.latitudeDelivery = this.placeDetailDataDelivery.geometry.location.lat;
//         this.longitudeDelivery = this.placeDetailDataDelivery.geometry.location.lng;
//         console.log('latitudeDelivery', this.latitudeDelivery);
//         console.log('longitudeDelivery', this.longitudeDelivery);
//         // console.log('this.latitude', this.latitude);
//         // console.log('this.longitude', this.longitude);
//         // this.placeDetailData = this.placeDetailData;
//         setTimeout(() => {
//           this.getDeliveryAddressDetail(this.placeDetailDataDelivery);
//           this.addressLoadDelivery = false;
//         }, 1000);
//       },
//       (error) => {
//       }
//     );
//      }, 1000);
//   }
//   getcurrentLocation() {
//     // this.nao.go(30);
//     const contex = this;
//     console.log(' enter getcurrentLocation function');
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(showPosition);
//       console.log(' Get lat long');
//     } else {
//       // this.setlocation_error = "Geolocation is not supported by this browser.";
//     }
//       function showPosition(position) {
//       // contex.nao.go(100);
//       // if (contex.latitudeDelivery === undefined || contex.longitudeDelivery === undefined) {
//         contex.latitudeDelivery = position.coords.latitude;
//         contex.longitudeDelivery = position.coords.longitude;
//         // console.log(contex.latitudeDelivery, contex.longitudeDelivery);
//         // contex.show_marker = false;
//         contex.initMap();
//         // contex.nao.go(100);
//       // }
//     }
//   }
//   submitDeliveryAddress() {
//     this.error_count = 0;
//     $('.c-label label').css('color', '#3d3e3d');
//      $('.uploadText').css('color', '#3d3e3d');
//      $('.c-label input').css('border-color', '#ccc');
//      $('.c-label textarea').css('border-color', '#ccc');
//     if (this.streetDelivery === '' || this.streetDelivery === undefined) {
//         $('#c-deliveryStreet label').css('color', '#a10a0d');
//         $('#c-deliveryStreet input').css('border-color', '#a10a0d');
//         this.error_count ++;
//     }
//     if (this.cityDelivery === '' || this.cityDelivery === undefined) {
//         $('#c-city label').css('color', '#a10a0d');
//         $('#c-city input').css('border-color', '#a10a0d');
//         this.error_count ++;
//     }
//     if (this.stateDelivery === '' || this.stateDelivery === undefined) {
//         $('#c-state label').css('color', '#a10a0d');
//         $('#c-state input').css('border-color', '#a10a0d');
//         this.error_count ++;
//     }
//     if (this.zipDelivery === '' || this.zipDelivery === undefined) {
//         $('#c-zipChk label').css('color', '#a10a0d');
//         $('#c-zipChk input').css('border-color', '#a10a0d');
//         this.error_count ++;
//     }
//     if (this.error_count > 0) {
//       return;
//     }
//     this.validateCityFunction(this.zipDelivery);
//     this.deliveryAddressObj = {
//       street: this.streetDelivery,
//       zip: this.zipDelivery,
//       area: this.streetDelivery,
//       city: this.cityDelivery,
//       country: this.areaDelivery,
//       state: this.stateDelivery,
//       latitude: this.latitudeDelivery,
//       longitude: this.longitudeDelivery,
//       apart_no: this.apartDelivery,
//     };
//     console.log('this.deliveryAddressObj', this.deliveryAddressObj.apart_no);
//   $('#deliveryAddressPop').modal('hide');
//   }

  ngOnInit() {
  }
  //      const context = this;
  //   // $('#phone').on('countrychange', function(e, countryData) {
  //   // });
  //   $(function () {
  //       this.maxYear =new Date().getFullYear();
  //       this.maxYear = parseInt(this.maxYear) - 21;
  //     $('#datepicker').datepicker({
  //       format: 'mm/dd/yyyy', // Notice the Extra space at the beginning
  //       defaultDate:'-10d',
  //       endDate: new Date('12/31/'+ this.maxYear)
  //     });
  //   $('#phone').intlTelInput({
  //       // autoFormat: true,
  //       utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.9/js/utils.jss',
  //       autoPlaceholder: false,
  //       preferredCountries: ['us'],
  //       preventInvalidNumbers: true,
  //       // stop the user from typing invalid dial codes
  //       preventInvalidDialCodes: true,
  //       formatOnDisplay: true,
  //       separateDialCode: false,
  //     });
  //     var countryCode;
  //      setTimeout(() => {
  //       countryCode = $('#phone').intlTelInput('getSelectedCountryData').dialCode;
  //       $('#phone').val('+' + countryCode);
  //      }, 1000);
  //       $('#phone').change(
  //               function () {
  //                    countryCode = $('#phone').intlTelInput('getSelectedCountryData').dialCode;
  //                   $('#phone').val('+' + countryCode);
  //
  //               }
  //           );
  //       });
  // }

}

