import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { EventsService } from 'angular4-events';
import { Meta, Title } from '@angular/platform-browser';
import { Constants } from '../constants';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DataControlService } from '../data-control.service';
import { fb_events_consts } from '../fbq-constants'
import { FbEventsService } from '../fb-events.service';

declare var $: any;
declare var Nanobar: any;
declare var toastr: any;
declare var google: any;
declare var require: any;
declare var fbq: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  customOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 1000,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1,
        stagePadding: 50
      },
      400: {
        items: 1,
        stagePadding: 150
      },
      500: {
        items: 1,
        stagePadding: 150
      },
      740: {
        items: 2,
        stagePadding: 150
      },
      940: {
        items: 3,
        stagePadding: 150
      }
    },
    nav: false,
    autoplay: true,
    smartSpeed: 800,
    autoplaySpeed: 1000,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    stagePadding: 150
  }

  sponserSectionOptions: any = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 1000,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: false,
    autoplay: true,
    smartSpeed: 800,
    autoplaySpeed: 1000,
    autoplayTimeout: 2000,
    autoplayHoverPause: false
  }

  secondSectionOptions: any = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 750,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      }
    },
    nav: false
  }


  serviceAreaSectionOptions: any = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 750,
    stagePadding: 30,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      500: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      }
    },
    nav: false,
  }

  storiesSectionOptions: any = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 750,
    stagePadding: 30,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      500: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      }
    },
    nav: false,
  }

  nao = new Nanobar();
  areaData: any = null;
  storyData: any;
  storyDataNew: any;
  storyPage = 0;
  storylimit = 25;
  storylastPage = false;
  cityId: any;
  validateSuccessTrue: any;
  address_line1: any;
  place: any;
  city: any;
  state: any;
  zip = '';
  area: any;
  street = '';
  clearTimeout: any;
  placeSuggestionsData: any;
  placeDetailData: any;
  placeDataFound = false;
  addressLoad = false;
  latitude: any;
  longitude: any;
  cityList: any;
  validateSuccess: any;
  showCrossIcon = false;
  loadingData = true;
  cityname: any;
  showloder = 'pin.svg';
  showHideTextLoader = true;

  pendingRating: any;
  ratingData: any;
  dispensdispensaryName: any;
  driverData: any;
  rating_id: any;
  // dispensary_rating_id: any;
  driverFirstName: any;
  driverLastName: any;
  feedBack = '';
  // rating_id: any;
  dispensary_rating_id: any;
  driverRatingValue = 0;
  businessRatingValue = 0;
  // feedBack: any;
  sendRatingData: any;
  sendRatingSuccess: any;
  // cancelRatingValue: any
  cancelRatingData: any;
  cancelRatingSuccess: any;
  cancelRatingValue = 0;
  businessRaiting = 5;
  businessRaitingArray = [];
  disabledBtn = false;
  deliveryAddressSearchLoader: boolean = false;
  placeSuggestionsDataDelivery: any;
  addressLoadDelivery: boolean;
  checkoutAddress: boolean = false;
  placeDetailDataDelivery: any;
  latitudeDelivery: any;
  longitudeDelivery: any;
  apartDelivery: string;
  countryDelivery: string;
  cityDelivery: string;
  stateDelivery: string;
  zipDelivery: string = '';
  orderNotesNew: string;
  ProfileApart: string;
  userCurrentAddress: any;

  get isUserToken(): any {
    return localStorage.getItem('token');
  }
  dropzone: any;
  customProductImages = new Array();
  customOrderText = '<ul><li></li></ul>';
  customOrderSuccess: any;
  customOrderImageSuccess: any;
  addCustomOrderBtn = false;
  customProductImagesCount = 0;
  customOrderImageSuccessUrl = new Array();

  streetDeliveryHome: any = '';
  placeLabel: any = '';

  locationForm: FormGroup;
  colorCode: string;
  productLogoList:string[]=[
    "../../assets/products/Barclays-Logo.svg",
    "../../assets/products/Cadbury-Logo.svg",
    "../../assets/products/kit-kat-logo.svg",
    "../../assets/products/maltesers_logo.svg",
    "../../assets/products/mcdonalds.svg",
    "../../assets/products/nike.svg",
    "../../assets/products/shell-3.svg",
    "../../assets/products/Tesco-Logo.svg",
    "../../assets/products/Vodafone-Logo.svg"
  ]

  constructor(
      private router: Router,
      private dataService: DataService,
      private events: EventsService,
      private ref: ChangeDetectorRef,
      private meta: Meta,
      private title: Title,
      private formBuilder: FormBuilder,
      private fbEventsService: FbEventsService,
      private dataControlService: DataControlService) {

    this.colorCode = Constants.colorCode;

    $(window).on('popstate', function () {
      $('.modal').modal('hide');
      $('.modal-backdrop').remove();
      $('.in').remove();
    });

    this.dataService.businessIdForIndependentBusinessData = -1;
    if (window.location.hostname.includes('businessweb.bestbuymall.co') || window.location.hostname.includes(Constants.domainNmae)) {
      this.router.navigate(['/x---bbm-route-config-1@#$#^@$!---YY234215--X--saffg-12454jh-4b5jsj'], { skipLocationChange: true });
      return;
    } else if (window.location.hostname.includes('bestbuymall.co') || window.location.hostname.includes('bestbuymall.co') || window.location.hostname.includes('dev.bestbuymall.co') || window.location.hostname.includes('dev.bestbuymall.co') || window.location.hostname.includes('localhost') || window.location.hostname.includes('bbm.com.pk') ) {
      this.dataService.pageName = 'home';
      // localStorage.setItem('delivery_address', 'Please Select your location');
    } else {
      this.router.navigate(['/x---bbm-route-config-1@#$#^@$!---YY234215--X--saffg-12454jh-4b5jsj'], { skipLocationChange: true });
      return;
    }
    this.dataService.userFrom = 'web';
    this.getAreaList();
    this.getStoryList();

  }

  // Facebook Find Location Event Call
  fbFindLocation() {
    this.fbEventsService.findLocation();
  }

  myProfileAddress: any;
  setAddressOnAddressInput() {
    if (localStorage.getItem('profileAddress') !== 'null' && localStorage.getItem('profileAddress') !== null && localStorage.getItem('myDeliveryAddress') !== 'Please Select your location' && localStorage.getItem('myDeliveryAddress') !== null && localStorage.getItem('myDeliveryAddress') !== '') {
      this.streetDeliveryHome = localStorage.getItem('myDeliveryAddress');
      this.isLocationFinalized = true;
      this.shopnowState = 'enable';
    }
    else{
      this.isLocationFinalized = false;
      this.shopnowState = 'disable';
    }

    this.myProfileAddress = JSON.parse(localStorage.getItem('profileAddress'));
    this.isSuggestionAddressShown = false;
    this.isSearchAddressShown = false;

    if(this.myProfileAddress != "null" && this.myProfileAddress != null){
      this.addressId = this.myProfileAddress.id;
      this.locationForm.controls.cityLocation.setValue(this.myProfileAddress.city);
      this.locationForm.controls.postalLocation.setValue(this.myProfileAddress.zip);
      this.locationForm.controls.stateLocation.setValue(this.myProfileAddress.state);
      this.locationForm.controls.countryLocation.setValue(this.myProfileAddress.country);
      this.locationForm.controls.areaLocation.setValue(this.myProfileAddress.address_line1);
      this.locationForm.controls.aptLocation.setValue(this.myProfileAddress.apart_no);
      this.locationForm.controls.houseNoLocation.setValue(this.myProfileAddress.house_building_no);
      this.locationForm.controls.placeLabelLocation.setValue(this.myProfileAddress.place_label);
      this.latitudeDelivery = this.myProfileAddress.latitude;
      this.longitudeDelivery = this.myProfileAddress.longitude;
      this.orderNotesNew = this.myProfileAddress.notes;
      // this.router.navigate(['/businesses', this.myProfileAddress.city]);

    }
    else{
      this.addressId = "";
    }
    if(this.myProfileAddress === "null" || this.myProfileAddress === null){
      if(localStorage.getItem('myDeliveryAddress') !== 'Please Select your location' && localStorage.getItem('myDeliveryAddress') !== null && localStorage.getItem('myDeliveryAddress') !== ''){
        // navigate

        // if(localStorage.getItem('isFirstTimeHomeOpen') == "true"){
          this.router.navigate(['/businesses', localStorage.getItem('myDeliveryAddress')]);
        // }
      }
      else{
        // no navigate

      }
    }
    else{
      // if(localStorage.getItem('isFirstTimeHomeOpen') == "true"){
        this.shopNow();
      // }
    }
    // localStorage.setItem("isFirstTimeHomeOpen", "false");

  }
  myDealProducts: any = '';
  getDealProducts() {
    this.dataService.getDealProducts().subscribe(
      (data) => {
        this.myDealProducts = data;
      },
      (error) => {
      }
    );
  }
  mytoken: any;


  windowCurrentWidth: any;
  coverImageload:boolean = true;
  ngOnInit() {
    this.userCurrentAddress = JSON.parse(window.localStorage.getItem('profileAddressCheckout'));
    this.get_home_seo();
    setTimeout(() => {
      this.coverImageload = false;
      setTimeout(() => {
        $('#myAddressLabel').click(function() {
          $('#streetDeliveryHome').focus();
        });

        $('.dropdown.keep-open').on({
          "shown.bs.dropdown": function () { this.closable = false; },
          "click": function () { this.closable = false; },
          "change": function () { this.closable = true; },
          "hide.bs.dropdown": function () { return this.closable; }
        });
      }, 100);
    }, 2000);



    this.locationForm = this.formBuilder.group({
      premisLocation: '',
      placeLabelLocation: '',
      areaLocation: '',
      houseNoLocation: '',
      aptLocation: '',
      streetLocation: '',
      cityLocation: '',
      stateLocation: '',
      postalLocation: '',
      countryLocation: ''
    });
    this.isLocationFinalized = false;
    this.dataControlService.setAddressOnAddressInput.next(true);
    this.shopnowState = 'disable';

    if (localStorage.getItem('profileAddress') == null || localStorage.getItem('profileAddress') == "null" || localStorage.getItem('myDeliveryAddress') == 'Please Select your location') {
      this.streetDeliveryHome = '';
      this.isLocationFinalized = false;
      this.shopnowState = 'disable';

      if(this.locationForm != undefined){
        this.locationForm.reset();
      }
    }
    else {
      this.setAddressOnAddressInput();
    }

    this.dataControlService.setAddressOnHomePage.subscribe(data => {
      if(data != ''){
        this.setAddressOnHomePage(data);
      }
    });

    this.windowCurrentWidth = $(window).width();
    window.addEventListener('resize', () => {
      this.windowCurrentWidth = $(window).width();
    });
    this.getDealProducts();
    this.dataControlService.mytokenVal.subscribe(data => {
      this.mytoken = data;
    });

    this.dataControlService.getAddressesOnHome.subscribe(data => {
      this.getAddressListing();
    });

    // this.setPageMetaData();


    this.dataService.userFrom = 'web';
    for (let z = 0; z < this.businessRaiting; z++) {
      this.businessRaitingArray.push({
        value: 'true'
      });
    }
    this.get_pending_rating();
    window.scrollTo(0, 0);
    var width = $(window).width();
    $('.storyBackBtn').css('display', 'none');
    $('.ds-logo').css('margin-left', '0px');
    if (width >= 769) {
      $('.cartHeadBtn').css('background', this.colorCode);
      $('.cartHeadBtn').css('color', '#FFFFFF');
      $('.topName').css('color', this.colorCode);
      $('.username').css('color', '#000000');
      $('#whiteLogo').css('display', 'inline-block');
      $('#blackLogo').css('display', 'inline-block');
      $('.business-logo').css('display', 'none');
      $('.navbar-header').css('margin-top', '0px');
    } else {

      $('#whiteLogo').css('display', 'none');
      $('#blackLogo').css('display', 'inline-block');
      $('.cartHeadBtn').css('background', this.colorCode);
      $('.cartHeadBtn').css('color', '#ffffff');
      $('.username').css('color', '#000000');
      $('.topName').css('color', this.colorCode);
      $('.business-logo').css('display', 'none');
      $('.navbar-header').css('margin-top', '0px');
    }

    $(window).resize(function () {
      var width = $(window).width();
      if (width <= 769) {

        $('#whiteLogo').css('display', 'none');
        $('#blackLogo').css('display', 'inline-block');
        $('.cartHeadBtn').css('background', this.colorCode);
        $('.cartHeadBtn').css('color', '#ffffff');
        $('.username').css('color', '#000000');
        $('.topName').css('color', this.colorCode);
      } else {
        if (window.pageYOffset >= 69) {

          $('#whiteLogo').css('display', 'inline-block');
          $('#blackLogo').css('display', 'block');
          $('.cartHeadBtn').css('background', this.colorCode);
          $('.cartHeadBtn').css('color', '#ffffff');
          $('.topName').css('color', this.colorCode);
          $('.username').css('color', this.colorCode);
        } else {

          $('#whiteLogo').css('display', 'inline-block');
          $('#blackLogo').css('display', 'inline-block');
          $('.cartHeadBtn').css('background', this.colorCode);
          $('.cartHeadBtn').css('color', '#FFFFFF');
          $('.username').css('color', '#000000');
          $('.topName').css('color', this.colorCode);
        }
      }
    });
    $('.navbar').css('border-bottom', '0px');
    $('.ds-logo').css('margin-left', '0px');




    $(document).ready(function () {
      window.scrollTo(0, 0);
    });
    this.dataControlService.setAddressOnAddressInput.subscribe(data => {

      if (data == true) {
        this.setAddressOnAddressInput();
      }
      else {
        this.streetDeliveryHome = '';
        this.isLocationFinalized = false;
        this.shopnowState = 'disable';

        this.locationForm.reset();
      }
    });
  }
  ngOnDestroy() {
    $(window).unbind('scroll');

  }

  // openDetail(title, id){
  //   // var str_title = title;
  //   // str_title = str_title.replace(/\s+/g, '-').toLowerCase();
  //
  //   const objJsonStr = id;
  //   const objJsonB64 = Buffer.from(objJsonStr).toString('base64');
  //   this.router.navigate(['/blog', id]);
  // }
  showCross() {
    if (this.address_line1 === '' || this.address_line1 === undefined) {
      this.showCrossIcon = false;
    } else {
      this.showCrossIcon = true;
    }
  }

  // citiesOnPhone(city:'LHR'|'BHP'){
  //   $(`.overlay-img-${city}`).css('visibility', 'visible');

  //   setTimeout(() => {
  //     if (city == 'LHR') {
  //       this.router.navigate(['/businesses/Lahore'])
  //     } else{
  //       this.router.navigate(['/businesses/Bahawalpur'])
  //     }
  //   }, 100);
  // }


  toggleDisableFields() {
    if (this.deliveryAddressSearchLoader) {
      this.locationForm.controls['premisLocation'].disable();
      // this.locationForm.controls['placeLabelLocation'].disable();
      this.locationForm.controls['areaLocation'].disable();
      this.locationForm.controls['houseNoLocation'].disable();
      this.locationForm.controls['aptLocation'].disable();
      // this.locationForm.controls['streetLocation'].disable();
      this.locationForm.controls['cityLocation'].disable();
      this.locationForm.controls['stateLocation'].disable();
      this.locationForm.controls['postalLocation'].disable();
      this.locationForm.controls['countryLocation'].disable();
    }
    else {

      this.locationForm.controls['premisLocation'].enable();
      // this.locationForm.controls['placeLabelLocation'].enable();
      this.locationForm.controls['areaLocation'].enable();
      this.locationForm.controls['houseNoLocation'].enable();
      this.locationForm.controls['aptLocation'].enable();
      // this.locationForm.controls['streetLocation'].enable();
      // this.locationForm.controls['cityLocation'].enable();
      // this.locationForm.controls['stateLocation'].enable();
      this.locationForm.controls['postalLocation'].enable();
      this.locationForm.controls['countryLocation'].enable();
    }
  }
  isCustomPlaceLabel: boolean = false;

  openAfterPlaceSelectPopup(name, id) {
    this.myPinCity = '';

    $("#streetDeliveryHome").dropdown("toggle");
    this.currentLocationCount = 0;
    this.locationForm.reset();
    this.deliveryAddressSearchLoader = true;
    this.toggleDisableFields();
    this.setPlaceLabel("Home");
    this.locationForm.controls['cityLocation'].disable();
    this.locationForm.controls['stateLocation'].disable();
    // this.areaLocation = name;
    // this.locationForm.controls.areaLocation.setValue(name);
    this.dataService.googleAddressDetail(id).subscribe(
      (data) => {
        this.placeDetailDataDelivery = data;
        // this.checkoutAddress = true;
        $('.myMapDiv').css('display', 'block');
        this.placeDetailDataDelivery = this.placeDetailDataDelivery.result;
        this.latitudeDelivery = this.placeDetailDataDelivery.geometry.location.lat;
        this.longitudeDelivery = this.placeDetailDataDelivery.geometry.location.lng;
        setTimeout(() => {
          this.locationForm.controls.areaLocation.setValue('');
          this.getDeliveryAddressDetailFromSearchLocation(this.placeDetailDataDelivery);
          this.addressLoadDelivery = false;
        }, 1000);
      },
      (error) => {
      }
    );
  }

  // setAddressTextOnHomePage(val){
  //   if(val != ''){
  //     this.streetDeliveryHome = val;
  //     this.isLocationFinalized = true;
  //   }
  // }
  setAddressOnHomePage(id) {
    if (id !== '') {

      this.isLocationFinalized = true;
      this.shopnowState = 'enable';

      // $("#dropdown.keep-open").attr("aria-expanded","false");
      this.streetDeliveryHome = '';
      let index;
      if(this.customerProfileList != undefined){

        for (let i = 0; i < this.customerProfileList.address_dictionary.length; i++) {
          if (this.customerProfileList.address_dictionary[i].id == id) {
            index = i;
          }
        }
      }

      this.mySuggestedAddressSelectedIndex = this.customerProfileList.address_dictionary[index].id;
      var mySelectedaddress = '';

      // mySelectedaddress = mySelectedaddress + this.customerProfileList.address_dictionary[index].address_line1 + ', ';

      if (this.customerProfileList.address_dictionary[index].zip == null || this.customerProfileList.address_dictionary[index].zip == '') {

        if (this.customerProfileList.address_dictionary[index].house_building_no !== "") {
          mySelectedaddress = mySelectedaddress + this.customerProfileList.address_dictionary[index].house_building_no + ', ';
        }
        if (this.customerProfileList.address_dictionary[index].apart_no !== "") {
          mySelectedaddress = mySelectedaddress + this.customerProfileList.address_dictionary[index].apart_no + ', ';
        }
        mySelectedaddress = mySelectedaddress + this.customerProfileList.address_dictionary[index].address_line1 + ', ';

        mySelectedaddress = mySelectedaddress + this.customerProfileList.address_dictionary[index].city;
        //  + ", " + this.customerProfileList.address_dictionary[index].state + ', ' + this.customerProfileList.address_dictionary[index].country;

      }
      else {
        if (this.customerProfileList.address_dictionary[index].house_building_no !== "") {
          mySelectedaddress = mySelectedaddress + this.customerProfileList.address_dictionary[index].house_building_no + ', ';
        }
        if (this.customerProfileList.address_dictionary[index].apart_no !== "") {
          mySelectedaddress = mySelectedaddress + this.customerProfileList.address_dictionary[index].apart_no + ', ';
        }
        mySelectedaddress = mySelectedaddress + this.customerProfileList.address_dictionary[index].address_line1 + ', ';
        mySelectedaddress = mySelectedaddress + this.customerProfileList.address_dictionary[index].city;
        //  + ", " + this.customerProfileList.address_dictionary[index].state + " " + this.customerProfileList.address_dictionary[index].zip + ', ' + this.customerProfileList.address_dictionary[index].country;
      }


      this.streetDeliveryHome = mySelectedaddress;

      this.isSuggestionAddressShown = false;
      this.isSearchAddressShown = false;
      this.addressId = this.customerProfileList.address_dictionary[index].id;
      this.locationForm.controls.cityLocation.setValue(this.customerProfileList.address_dictionary[index].city);
      this.locationForm.controls.postalLocation.setValue(this.customerProfileList.address_dictionary[index].zip);
      this.locationForm.controls.stateLocation.setValue(this.customerProfileList.address_dictionary[index].state);
      this.locationForm.controls.countryLocation.setValue(this.customerProfileList.address_dictionary[index].country);
      this.locationForm.controls.aptLocation.setValue(this.customerProfileList.address_dictionary[index].apart_no);
      this.locationForm.controls.houseNoLocation.setValue(this.customerProfileList.address_dictionary[index].house_building_no);
      this.locationForm.controls.areaLocation.setValue(this.customerProfileList.address_dictionary[index].address_line1);
      this.locationForm.controls.placeLabelLocation.setValue(this.customerProfileList.address_dictionary[index].place_label);
      this.latitudeDelivery = this.customerProfileList.address_dictionary[index].latitude;
      this.longitudeDelivery = this.customerProfileList.address_dictionary[index].longitude;
      this.orderNotesNew = this.customerProfileList.address_dictionary[index].notes;


    }
    else{
      this.isLocationFinalized = false;
      this.shopnowState = 'disable';

      this.streetDeliveryHome = '';
      if(this.locationForm != undefined){
        this.locationForm.reset();
      }
    }
  }

  isDismissTryAgain: boolean = false;

  clearSearch() {
    this.address_line1 = '';
    this.showCrossIcon = false;
  }
  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   let width = $(window).width();
  //   if( width >= 769 && window.pageYOffset >= 69) {
  //         // $('.navbar').addClass('sticky-header');
  //         // $('.navbar').css('background-color', '#FFF');
  //         $('.navbar').css('position', 'fixed');
  //         $('.navbar').css('border-bottom', '1px solid transparent');
  //         $('.cartHeadBtn').css('background', '#fff');
  //         $('.cartHeadBtn').css('color', this.colorCode);
  //         $('.topName').css('color', 'red');
  //         $('.username').css('color', '#ffffff');
  //         $('.logInbtn').css('color', 'red');
  //         $('#whiteLogo').css('display', 'none');
  //         $('#blackLogo').css('display', 'inline-block');
  //         $('.business-logo').css('display', 'none');
  //         $('.navbar-header').css('margin-top', '0px');
  //     // alert('resize angular event');
  //
  //         // $('.navbar').removeClass('sticky-header');
  //   }
  // }
  @HostListener('window:scroll', ['$event'])
  scrollEvent(event) {
    // console.debug("Scroll Event", document.body.scrollTop);
    // see András Szepesházi's comment below
    var width = $(window).width();
    if (width >= 769) {
      if (window.pageYOffset >= 69) {
        // $('.navbar').addClass('sticky-header');
        // $('.navbar').css('border-bottom', '0px');
        // $('.logInbtn').css('color', this.colorCode);
        $('#whiteLogo').css('display', 'inline-block');
        $('#blackLogo').css('display', 'inline-block');
        $('.cartHeadBtn').css('background', this.colorCode);
        $('.cartHeadBtn').css('color', '#ffffff');
        $('.username').css('color', '#000000');
        $('.topName').css('color', this.colorCode);
      } else {
        // $('.navbar').removeClass('sticky-header');
        // $('.logInbtn').css('color', '#fff');
        $('#whiteLogo').css('display', 'block');
        $('#blackLogo').css('display', 'inline-block');
        $('.cartHeadBtn').css('background', this.colorCode);
        $('.cartHeadBtn').css('color', '#FFFFFF');
        $('.topName').css('color', this.colorCode);
        $('.username').css('color', '#000000');
      }
    }
  }
  serviceAreaGet(obj) {
    localStorage.setItem('profileAddress', null);
    localStorage.setItem('profileAddressCheckout', null);
    this.streetDeliveryHome = '';

    $('#selectedAddressPopUp').modal('hide');
    $('#headAddressSelection').modal('hide');
    $('#wrongCityPopUp').modal('hide');
    localStorage.setItem('delivery_address', obj.city);
    localStorage.setItem('myDeliveryAddress', obj.city);

  }
  validateCityFunction() {
    $('.c-label label').css('color', '#3d3e3d');
    $('.c-label input').css('border-color', '#ccc');
    if (this.zip === '' || this.zip === undefined) {
      $('#c-zip label').css('color', '#a10a0d');
      $('#c-zip input').css('border-color', '#a10a0d');
      return;
    }
    this.nao.go(30);
    this.dataService.validateCity(this.zip).subscribe(
      (data) => {
        this.nao.go(100);
        this.cityList = data;
        this.cityList = this.cityList.city_list;
        this.ref.detectChanges();

        this.validateSuccess = data;
        if (this.validateSuccess.rest_error_code === 4) {
          $('#availableCityPop').modal('show');
          // toastr.error('We don not provide services in this area');
        }
        this.cityId = this.validateSuccess.city_list[0].id;
        this.validateSuccessTrue = this.validateSuccess.success;
        if (this.validateSuccessTrue === true) {
          this.ref.detectChanges();
          localStorage.setItem('delivery_address', this.cityname);
          $('#showZipPop').modal('hide');
          $('#headAddress').modal('hide');
          setTimeout(() => {
            // this.router.navigate(['/businesses', this.cityId]);
            this.router.navigate(['/businesses', this.validateSuccess.city_list[0].city_short_name]);
          }, 1000);
        }
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }
  openNav() {
    $('#businessAll').modal({
      backdrop: 'static',
      keyboard: false,
    }, 'show');
  }

  /* Set the width of the side navigation to 0 and the left margin of the page content to 0 */
  closeNav() {
    // document.getElementById('main').style.marginRight = '0';
  }
  openWipPop() {
    $('#wipPop').modal('show');
  }
  functionTogetPlace() {
    this.showloder = 'loader.gif';
    this.placeDataFound = false;
    this.placeSuggestionsData = [];
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      // this.addressLoad = true;
      this.dataService.googleAddress(this.address_line1).subscribe(
        (data) => {
          this.placeSuggestionsData = data;
          this.placeSuggestionsData = this.placeSuggestionsData.predictions;
          this.showloder = 'pin.svg';
          this.placeDataFound = true;
          if ($('.myDrop').hasClass('open')) {

          } else {
            $('.myDrop').addClass('open');
          }
          // this.addressLoad = false;
          // $('#searchTextField').click();
          // $('#searchTextField').focus();
        },
        (error) => {
        }
      );
    }, 1000);
  }
  functionTogetPlaceDetail(name, id) {
    this.showHideTextLoader = false;
    var addressData = localStorage.getItem('delivery_address');
    localStorage.setItem('delivery_address', 'Updating Location...');
    localStorage.setItem('myDeliveryAddress', 'Updating Location...');

    this.addressLoad = true;
    this.address_line1 = name;
    this.cityname = name;
    // localStorage.setItem('delivery_address', this.cityname);
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      this.dataService.googleAddressDetail(id).subscribe(
        (data) => {
          this.placeDetailData = data;
          this.placeDetailData = this.placeDetailData.result;
          this.latitude = this.placeDetailData.geometry.location.lat;
          this.longitude = this.placeDetailData.geometry.location.lng;
          this.placeDetailData = this.placeDetailData.address_components;
          setTimeout(() => {
            this.showHideTextLoader = true;
            localStorage.setItem('delivery_address', addressData);
            localStorage.setItem('myDeliveryAddress', addressData);

            this.addressDetail();
            this.addressLoad = false;
          }, 1000);
        },
        (error) => {
        }
      );
    }, 1000);
  }
  addressDetail() {
    this.street = '';
    for (var i = 0; i < this.placeDetailData.length; i++) {
      for (var j = 0; j < this.placeDetailData[i].types.length; j++) {
        if (this.placeDetailData[i].types[j] === 'locality') {
          $('#city').val(this.placeDetailData[i].long_name);
        }
        if (this.placeDetailData[i].types[j] === 'street_number') {
          this.street = this.placeDetailData[i].long_name;
        }
        if (this.placeDetailData[i].types[j] === 'route') {
          this.street = this.street + ' ' + this.placeDetailData[i].long_name;
        }
        if (this.placeDetailData[i].types[j] === 'sublocality_level_2') {
          this.street = this.street + ' ' + this.placeDetailData[i].long_name;
        }
        if (this.placeDetailData[i].types[j] === 'sublocality_level_1') {
          this.street = this.street + ' ' + this.placeDetailData[i].long_name;
        }
        if (this.placeDetailData[i].types[j] === 'administrative_area_level_1') {
          $('#state').val(this.placeDetailData[i].short_name);
        }

        if (this.placeDetailData[i].types[j] === 'country') {
          $('#area').val(this.placeDetailData[i].long_name);
        }

        if (this.placeDetailData[i].types[j] === 'postal_code') {
          this.zip = this.placeDetailData[i].long_name;
          $('#zip').val(this.placeDetailData[i].long_name);
        } else {
          $('#zip').val('');
        }
      }
    }
    if (this.zip === '' || this.zip === undefined) {
      this.zip = '';
      $('#showZipPop').modal('show');
    } else {
      this.validateCityFunction();
    }
  }
  // inputAddress1() {
  //   var context = this;
  //   const input = document.getElementById('searchTextField');
  //   const searchBox = new google.maps.places.Autocomplete(input);
  //   searchBox.addListener('place_changed', function() {
  //     context.place = searchBox.getPlace();
  //     context.addressDetail();
  //   });
  // }
  // addressDetail() {
  //   this.street = '';
  //   for(var i = 0; i < this.place.address_components.length; i++) {
  //     for(var j = 0; j < this.place.address_components[i].types.length; j++) {
  //       if (this.place.address_components[i].types[j] === 'administrative_area_level_2') {
  //         this.city = this.place.address_components[i].long_name;
  //         // $('#city').val(this.place.address_components[i].long_name);
  //       }
  //       if (this.place.address_components[i].types[j] === 'administrative_area_level_1') {
  //         this.state = this.place.address_components[i].long_name;
  //         // $('#state').val(this.place.address_components[i].long_name);
  //       }
  //       if (this.place.address_components[i].types[j] === 'street_number') {
  //         this.street = this.place.address_components[i].long_name;
  //       }
  //       if (this.place.address_components[i].types[j] === 'route') {
  //         this.street = this.street + ' ' + this.place.address_components[i].long_name;
  //       }
  //       if (this.place.address_components[i].types[j] === 'sublocality_level_2') {
  //         this.street = this.street + ' ' + this.place.address_components[i].long_name;
  //       }
  //       if (this.place.address_components[i].types[j] === 'sublocality_level_1') {
  //         this.street = this.street + ' ' + this.place.address_components[i].long_name;
  //       }
  //       // this.street = this.place.name;
  //
  //       if (this.place.address_components[i].types[j] === 'country') {
  //         this.area = this.place.address_components[i].long_name;
  //         // $('#area').val(this.place.address_components[i].long_name);
  //       }
  //
  //       if (this.place.address_components[i].types[j] === 'postal_code') {
  //         this.zip = this.place.address_components[i].long_name;
  //       }
  //       this.latitude = this.place.geometry.location.lat();
  //       this.longitude = this.place.geometry.location.lng();
  //     }
  //   }
  //     if (this.zip === '' || this.zip === undefined) {
  //       $('#showZipPop').modal('show');
  //     } else {
  //       this.validateCityFunction();
  //     }
  // }

  getAreaList() {
    this.nao.go(30);
    this.dataService.validateCity(this.zip).subscribe(
      (data) => {
        this.nao.go(100);
        this.areaData = data;
        setTimeout(() => {
          this.loadingData = false;
        }, 500);
        this.areaData = this.areaData.city_list;
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }
  myStoryCount:any;
  getStoryList() {
    this.storyPage = 0;
    this.dataService.getStory(3).subscribe(
      (data) => {
        this.storyData = data;
        this.storylastPage = this.storyData.last_page;
        this.myStoryCount = this.storyData.total;
        this.storyData = this.storyData.items;
        this.storyPage++;
      },
      (error) => {
      }
    );
  }
  getStoryListByPage() {
    this.disabledBtn = true;
    this.nao.go(30);
    this.dataService.getStoryByPage(this.storyPage, this.storylimit).subscribe(
      (data) => {
        this.nao.go(100);
        this.storyPage++;
        this.storyDataNew = data;
        this.disabledBtn = false;
        this.storylastPage = this.storyDataNew.last_page;
        this.storyDataNew = this.storyDataNew.items;
        for (var i = 0; i < this.storyDataNew.length; i++) {
          this.storyData.push(this.storyDataNew[i]);
        }
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }


  get_pending_rating() {
    if (localStorage.getItem('token') === '' || localStorage.getItem('token') === undefined || localStorage.getItem('token') === null) {
      return;
    }
    this.dataService.get_pending_rating(localStorage.getItem('token'))
      .subscribe(
        data => {
          this.ratingData = data;
          if  (this.ratingData.pending_payment.is_pending){
            this.events.publish('confirmPaymentMethod',this.ratingData.pending_payment);
          }else if (this.ratingData.status === 5) {
            this.events.publish('ratingPopUpData', this.ratingData);
          }
          else {
          }
        });
  }

  openBuisnessPage(name) {
    localStorage.setItem('delivery_address', name);
    localStorage.setItem('myDeliveryAddress', name);

    this.router.navigate(['/businesses', this.place.city_short_name]);
  }

  openCustomOrderOpoup() {

    this.customOrderText = '<ul><li></li></ul>';
    this.addCustomOrderBtn = false;
    $('#customOrderPopup').modal('show');
    $('.dz-default').css('display', 'block');
    setTimeout(() => {
      $('.dz-preview').remove();
    }, 500);
    const Dropzone = require('dropzone');
    const context = this;
    try {
      new Dropzone('#my-awesome-dropzone', {
        maxFiles: 10, addRemoveLinks: true, dictDefaultMessage: 'Upload Images', autoProcessQueue: false, paramName: 'image',
        acceptedMimeTypes: 'image/jpeg, image/jpg, image/png', progress: false, init: function () {
          context.dropzone = this;

          this.on('addedfile', function (file) {
            if (!file.type.match(/image.*/)) {
              // This is not an image, so Dropzone doesn't create a thumbnail.
              // Set a default thumbnail:
              // this.emit('thumbnail', file, '../app/assets/images/interface.png');

              // You could of course generate another image yourself here,
              // and set it as a data url.
            }
            context.customProductImages.push(file);
          });

          this.on('error', function (file, response) {
            // $(file.previewElement).find('.dz-error-message').text(response.file_name);
            // context.error_count++;
          });

          this.on('queuecomplete', function () {
            // $('#myloader').removeClass('cssload-container');
            // $('#loader_container').removeClass('floatingBarsG');
            // $('.blockG').addClass('myopc');


            // if(context.error_count>0){
            //     toastr.error('Error uploading images.');
            // }
            // else{
            //      toastr.success('Property added successfully.');
            //      context.navigate();
            // }

          });

          this.on('removedfile', function (file) {



            for (var i = 0; i < context.customProductImages.length; i++) {
              if (context.customProductImages[i].name === file.name) {
                context.customProductImages.splice(i, 1);
              }
            }


          });

          this.on('processing', function () {
            this.options.autoProcessQueue = false;
          });

          context.dropzone = this;
        }
      });
    }
    catch (err) {
    }
  }


  addCustomOrder() {
    if ((this.customOrderText === '' || this.customOrderText === '<ul><li></li></ul>') && this.customProductImages.length === 0) {
      toastr.error('Please add text or upload image');
      return;
    }
    if (localStorage.getItem('token') === '' || localStorage.getItem('token') === undefined || localStorage.getItem('token') === null) {
      this.addBlur();
      this.events.publish('openLoginFunction', 'true');
      return;
    }
    this.clearBlur();
    this.customProductImagesCount = 0;
    this.customOrderImageSuccessUrl = new Array();

    this.addCustomOrderBtn = true;
    if (this.customProductImages.length > 0) {
      var file = this.customProductImages[0];
      this.uploadCustomeOrderImage(file);
    } else {
      this.uploadCustomeOrderText();
    }
  }
  uploadCustomeOrderImage(file) {
    this.dataService.uploadOrderImage(localStorage.getItem('token'), -1, file, this.userCurrentAddress).subscribe(
      (data) => {
        this.customOrderImageSuccess = data;
        if (this.customOrderImageSuccess.success = true) {
          this.customOrderImageSuccessUrl.push(this.customOrderImageSuccess.url);
          this.customProductImagesCount++;
          if (this.customProductImages.length > this.customProductImagesCount) {
            var file = this.customProductImages[this.customProductImagesCount];
            this.uploadCustomeOrderImage(file);
          } else {
            this.uploadCustomeOrderText();
          }
        } else {
          this.addCustomOrderBtn = false;
          toastr.error('Something went wrong');
        }
      },
      (error) => {
      }
    );
  }

  convertHTMLtoText(html) {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  uploadCustomeOrderText() {
    var d = this.convertHTMLtoText(this.customOrderText);
    this.dataService.uploadCustomeOrderText(localStorage.getItem('token'), -1, d, this.customOrderImageSuccessUrl, this.userCurrentAddress).subscribe(
      (data) => {
        this.customOrderSuccess = data;
        if (this.customOrderSuccess.success === true) {
          toastr.success('Your request has been successfuly sent. Our team will contact you soon.');
          $('#customOrderPopup').modal('hide');
          this.addCustomOrderBtn = false;
          this.customOrderImageSuccessUrl = new Array();
          this.customProductImages = new Array();
        }
      },
      (error) => {
      }
    );
  }

  clearBlur() {
    $('#customOrderPopup').css('-webkit-filter', 'blur(0px)');
    $('#customOrderPopup').css('-moz-filter', 'blur(0px)');
    $('#customOrderPopup').css('-o-filter', 'blur(0px)');
    $('#customOrderPopup').css('-ms-filter', 'blur(0px)');
    $('#customOrderPopup').css('filter', 'blur(0px)');
  }
  addBlur() {
    $('#customOrderPopup').css('-webkit-filter', 'blur(3px)');
    $('#customOrderPopup').css('-moz-filter', 'blur(3px)');
    $('#customOrderPopup').css('-o-filter', 'blur(3px)');
    $('#customOrderPopup').css('-ms-filter', 'blur(3px)');
    $('#customOrderPopup').css('filter', 'blur(3px)');
  }

  disableBackground() {
    $('html').css('overflow-y', 'hidden');
  }

  enableBackground() {
    $('html').css('overflow-y', 'auto');
  }

  setPageMetaData() {
    this.meta.updateTag({ property: 'og:title', content: 'Best Buy Mall' });
    this.meta.updateTag({ property: 'og:image', content: 'https://cdn.bestbuymall.co/images/MDCLoVQptlSxjYLncii.png' });
    //this.meta.updateTag({ property: 'og:url', content: 'https://s3.amazonaws.com/bestbuymall/images/app_icons/icon.png' });
    this.meta.updateTag({ property: 'description', content: 'Best Buy Mall is an Online Grocery shopping store in United Kingdom. You can order online for groceries, fruits, vegetables, meat and commodities from your favorite local stores. We deliver under 1 hour' });
    this.meta.updateTag({ property: 'og:description', content: 'Best Buy Mall is an Online Grocery shopping store in United Kingdom. You can order online for groceries, fruits, vegetables, meat and commodities from your favorite local stores. We deliver under 1 hour' });
    this.meta.updateTag({ property: 'og:site_name', content: 'bestbuymall.co' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'keywords', content: ' best buy mall bestbuymall best buymall bestbuy mall shopping online purchase grocery items food medicine delivery order online free home delivery quick track system United Kingdom online' });
    this.title.setTitle('Best Buy Mall');



    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ name: 'twitter:site', content: 'bestbuymall.co' });
    this.meta.updateTag({ name: 'twitter:title', content: 'Best Buy Mall' });
    this.meta.updateTag({ name: 'twitter:description', content: 'Best Buy Mall is an Online Grocery shopping store in United Kingdom. You can order online for groceries, fruits, vegetables, meat and commodities from your favorite local stores. We deliver under 1 hour' });
    this.meta.updateTag({ name: 'twitter:image', content: 'https://cdn.bestbuymall.co/images/MDCLoVQptlSxjYLncii.png' });
  }

  shopnowState:any = '';
  functionTogetPlaceDelivery() {
    $('#myAddressLabel').css('color', 'unset');
    this.fbFindLocation();

    this.isLocationFinalized = false;
    this.shopnowState = 'disable';

    this.deliveryAddressSearchLoader = true;
    this.isSuggestionAddressShown = false;
    this.isSearchAddressShown = true;
    this.placeDataFound = false;
    this.placeSuggestionsDataDelivery = [];
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      this.addressLoadDelivery = true;
      this.dataService.googleAddress(this.streetDeliveryHome).subscribe(
        (data) => {
          this.placeSuggestionsDataDelivery = data;
          this.placeSuggestionsDataDelivery = this.placeSuggestionsDataDelivery.predictions;

          if (this.placeSuggestionsDataDelivery.length > 0) {
            this.placeDataFound = true;
            document.getElementById('streetDeliveryHome').setAttribute('aria-expanded', 'true');

          }
          this.deliveryAddressSearchLoader = false;
          this.toggleDisableFields();

          if ($('.myDrop').hasClass('open')) {

          } else {
            $('.myDrop').addClass('open');
          }
          this.addressLoad = false;
        },
        (error) => {
        }
      );
    }, 1000);
  }

  functionTogetPlaceDetailDelivery(name, id) {
    this.deliveryAddressSearchLoader = true;
    this.addressLoadDelivery = true;
    this.streetDeliveryHome = name;
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      this.dataService.googleAddressDetail(id).subscribe(
        (data) => {
          this.placeDetailDataDelivery = data;
          $('.myMapDiv').css('display', 'block');
          this.placeDetailDataDelivery = this.placeDetailDataDelivery.result;
          this.latitudeDelivery = this.placeDetailDataDelivery.geometry.location.lat;
          this.longitudeDelivery = this.placeDetailDataDelivery.geometry.location.lng;
          setTimeout(() => {
            this.locationForm.controls.areaLocation.setValue('');

            this.getDeliveryAddressDetailFromSearchLocation(this.placeDetailDataDelivery);
            this.addressLoadDelivery = false;
          }, 1000);
        },
        (error) => {
        }
      );
    }, 1000);
  }
  selectedCity: any;
  isCityEmpty:any = false;
  async getDeliveryAddressDetailFromSearchLocation(result) {
    this.apartDelivery = '';
    this.countryDelivery = '';
    this.cityDelivery = '';
    this.stateDelivery = '';
    this.zipDelivery = '';
    this.zip = '';
    this.locationForm.controls.areaLocation.setValue('');
    console.log(result);

    for (var i = 0; i < result.address_components.length; i++) {
      for (var j = 0; j < result.address_components[i].types.length; j++) {
        if (result.address_components[i].types[j] === 'administrative_area_level_2') {
          this.cityDelivery = result.address_components[i].long_name;
          this.locationForm.controls.cityLocation.setValue(result.address_components[i].long_name);
        } else if(result.address_components[i].types[j] === 'locality') {
          this.cityDelivery = result.address_components[i].long_name;
          this.locationForm.controls.cityLocation.setValue(result.address_components[i].long_name);
          // console.log(this.locationForm.controls.cityLocation.value);
          // console.log(this.cityDelivery );
        }

        if (result.address_components[i].types[j] === 'administrative_area_level_1') {
          this.stateDelivery = result.address_components[i].short_name;
          this.locationForm.controls.stateLocation.setValue(result.address_components[i].short_name);
        }
        if (result.address_components[i].types[j] === 'route') {
          if(result.address_components[i].long_name !== null && result.address_components[i].long_name !== 'null'){
            this.locationForm.controls.areaLocation.setValue(result.address_components[i].long_name);
          }
        }
        if (result.address_components[i].types[j] === 'sublocality_level_2') {
          if(result.address_components[i].long_name !== null && result.address_components[i].long_name !== 'null'){
            if(this.locationForm.controls.areaLocation.value !== "" && this.locationForm.controls.areaLocation.value !== null){
              this.locationForm.controls.areaLocation.setValue(this.locationForm.controls.areaLocation.value + " " +result.address_components[i].long_name);
            }
            else{
              this.locationForm.controls.areaLocation.setValue(result.address_components[i].long_name);
            }
          }

        }
        if (result.address_components[i].types[j] === 'sublocality_level_1') {
          if(result.address_components[i].long_name !== null && result.address_components[i].long_name !== 'null'){
            if(this.locationForm.controls.areaLocation.value !== "" && this.locationForm.controls.areaLocation.value !== null){
              this.locationForm.controls.areaLocation.setValue(this.locationForm.controls.areaLocation.value+ " " +result.address_components[i].long_name);
            }
            else{
              this.locationForm.controls.areaLocation.setValue(result.address_components[i].long_name);
            }
          }
        }

        if (result.address_components[i].types[j] === 'country') {
          this.countryDelivery = result.address_components[i].long_name;
          this.locationForm.controls.countryLocation.setValue(result.address_components[i].long_name);

        }

        if (result.address_components[i].types[j] === 'postal_code') {
          this.zipDelivery = result.address_components[i].long_name;

          this.zipDelivery = result.address_components[i].long_name;
          this.locationForm.controls.postalLocation.setValue(result.address_components[i].short_name);

        }
      }
    }


    let isEnteredCityCorrect = false;
    if(this.locationForm.controls.cityLocation.value == '' || this.locationForm.controls.cityLocation.value == null || this.locationForm.controls.cityLocation.value == undefined){
      this.isCityEmpty = true;
    }
    else{
      this.isCityEmpty = false;
      for (i = 0; i < this.areaData.length; i++) {
        if (this.areaData[i].city.toLowerCase() == this.locationForm.controls.cityLocation.value.toLowerCase()) {
          isEnteredCityCorrect = true;
          break;
        }
      }
    }


    if (isEnteredCityCorrect == false) {
      // wrong modal here
      this.selectedCity = this.locationForm.controls.cityLocation.value;
      this.isDismissTryAgain = false;
      $('#selectedAddressPopUp').modal('hide');
      $('#wrongCityPopUp').modal('show');
    }
    else {
      $('#selectedAddressPopUp').modal('show');
      $('#wrongCityPopUp').modal('hide');
      this.toggleDisableFields();

      this.initMapFromSearchLocation();
    }
    this.deliveryAddressSearchLoader = false;
  }

  myPlaceLabelDropDownVal: any;

  initMapFromSearchLocation() {
    var context = this;
    var map;
    var marker;
    var adr;
    var myLatlng = new google.maps.LatLng(this.latitudeDelivery, this.longitudeDelivery);
    var geocoder = new google.maps.Geocoder();
    var infowindow = new google.maps.InfoWindow();
    var mapOptions = {
      zoom: 18,
      center: myLatlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      navigationControl: false,
    };
    var endImage = {
      url: 'assets/pin.png', // url
      scaledSize: new google.maps.Size(30, 42), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(16, 41) // anchor
    };
    map = new google.maps.Map(document.getElementById('myMap'), mapOptions);

    marker = new google.maps.Marker({
      map: map,
      position: myLatlng,
      draggable: true,
      icon: endImage,
    });


    geocoder.geocode({ 'latLng': myLatlng }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          infowindow.setContent(results[0].formatted_address);
          infowindow.open(map, marker);
          context.latitudeDelivery = marker.getPosition().lat();
          context.longitudeDelivery = marker.getPosition().lng();
          context.currentLocationCount = context.currentLocationCount + 1;
          if(context.currentLocationCount <= 2){
            context.getDeliveryAddressDetailFromSearchLocation(results[0]);
          }
        }
      }
    });


    google.maps.event.addListener(marker, 'dragend', function () {
      geocoder.geocode({ 'latLng': marker.getPosition() }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            context.myPinCity = results[0];
            infowindow.setContent(results[0].formatted_address);
            infowindow.open(map, marker);
            context.latitudeDelivery = marker.getPosition().lat();
            context.longitudeDelivery = marker.getPosition().lng();
            // context.addressNew =  results[0].formatted_address;
          }
        }
      });
    });
    this.deliveryAddressSearchLoader = false;
    this.toggleDisableFields();

  }
  clearCheckoutAddress() {
    $('.myMapDiv').css('display', 'none');
    this.streetDeliveryHome = '';
    this.cityDelivery = '';
    this.stateDelivery = '';
    this.zipDelivery = '';
    this.apartDelivery = '';
    this.orderNotesNew = '';
    this.checkoutAddress = false;
  }


  myDropDownPlaceLabel: any;

  getcurrentLocationFromHome() {
    this.getcurrentLocation();
    this.setPlaceLabel("Home");

  }
  currentLocationCount:any = 0;

  getcurrentLocation() {
    this.myPinCity = '';
    this.currentLocationCount = 0;
    this.isLocationFinalized = false;
    this.shopnowState = 'disable';

    let tempplaceLabel = this.locationForm.controls.placeLabelLocation.value;
    this.locationForm.reset();
    this.locationForm.controls.placeLabelLocation.setValue(tempplaceLabel);


    this.streetDeliveryHome = '';
    this.cityDelivery = '';
    this.countryDelivery = '';
    this.stateDelivery = '';
    this.zipDelivery = '';
    this.apartDelivery = '';
    this.orderNotesNew = '';
    this.deliveryAddressSearchLoader = true;
    this.toggleDisableFields();

    // this.checkoutAddress = true;
    $('.myMapDiv').css('display', 'block');
    const contex = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    }
    function showError(error) {
      const contex1 = this;
      toastr.error('Allow location service from your browser');
      contex.deliveryAddressSearchLoader = false;
      contex.checkoutAddress = false;
      $('.myMapDiv').css('display', 'none');
    }

    function showPosition(position) {
      contex.deliveryAddressSearchLoader = true;
      contex.latitudeDelivery = position.coords.latitude;
      contex.longitudeDelivery = position.coords.longitude;
      contex.initMapFromCurrentLocation();
    }
  }

  initMapFromCurrentLocation() {

    var context = this;
    var map;
    var marker;
    var adr;
    var myLatlng = new google.maps.LatLng(this.latitudeDelivery, this.longitudeDelivery);
    var geocoder = new google.maps.Geocoder();
    var infowindow = new google.maps.InfoWindow();
    var mapOptions = {
      zoom: 18,
      center: myLatlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      navigationControl: false,
      // mapTypeControl: false,
      // disableDefaultUI: true
    };
    var endImage = {
      url: 'assets/pin.png', // url
      scaledSize: new google.maps.Size(30, 42), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(16, 41) // anchor
    };
    map = new google.maps.Map(document.getElementById('myMap'), mapOptions);
    marker = new google.maps.Marker({
      map: map,
      position: myLatlng,
      draggable: true,
      icon: endImage,
    });


    geocoder.geocode({ 'latLng': myLatlng }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          infowindow.setContent(results[0].formatted_address);
          infowindow.open(map, marker);
          context.latitudeDelivery = marker.getPosition().lat();
          context.longitudeDelivery = marker.getPosition().lng();
          context.currentLocationCount = context.currentLocationCount + 1;
          if(context.currentLocationCount <= 2){
            context.getDeliveryAddressDetailFromCurrentLocation(results[0]);
          }

        }
      }
    });


    google.maps.event.addListener(marker, 'dragend', function () {
      geocoder.geocode({ 'latLng': marker.getPosition() }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            context.myPinCity = results[0];
            infowindow.setContent(results[0].formatted_address);
            infowindow.open(map, marker);
            context.latitudeDelivery = marker.getPosition().lat();
            context.longitudeDelivery = marker.getPosition().lng();
            // context.addressNew =  results[0].formatted_address;
          }
        }
      });
    });
    this.deliveryAddressSearchLoader = false;
    this.toggleDisableFields();


  }
  myPinCity:any = '';
  async getDeliveryAddressDetailFromCurrentLocation(result) {
    this.streetDeliveryHome = '';
    this.ProfileApart = '';
    this.cityDelivery = '';
    this.stateDelivery = '';
    this.zipDelivery = '';
    this.zip = '';
    this.apartDelivery = '';
    //this.changeRef.detectChanges();
    this.locationForm.controls.areaLocation.setValue('');
    // this.streetDeliveryHome = result.formatted_address;
    for (var i = 0; i < result.address_components.length; i++) {
      for (var j = 0; j < result.address_components[i].types.length; j++) {

        if (result.address_components[i].types[j] === 'administrative_area_level_2') {
          this.locationForm.controls.cityLocation.setValue(result.address_components[i].long_name);
          console.log(this.locationForm.controls.cityLocation.value);
        }
        if (result.address_components[i].types[j] === "premise") {
          this.locationForm.controls.premisLocation.setValue(result.address_components[i].long_name);
        }

        // if (result.address_components[i].types[j] === 'route') {
        //   this.locationForm.controls.areaLocation.setValue(result.address_components[i].long_name);
        // }
        // if (result.address_components[i].types[j] === 'sublocality_level_1') {
        //   this.locationForm.controls.areaLocation.setValue(this.locationForm.controls.areaLocation.value + ' ' + result.address_components[i].long_name);
        // }
        // if (result.address_components[i].types[j] === 'sublocality_level_2') {

        //   this.locationForm.controls.areaLocation.setValue(this.locationForm.controls.areaLocation.value + ' ' + result.address_components[i].long_name);
        // }
        if (result.address_components[i].types[j] === 'route') {
          if(result.address_components[i].long_name !== null && result.address_components[i].long_name !== 'null'){
            this.locationForm.controls.areaLocation.setValue(result.address_components[i].long_name);
          }
        }
        if (result.address_components[i].types[j] === 'sublocality_level_2') {
          if(result.address_components[i].long_name !== null && result.address_components[i].long_name !== 'null'){
            if(this.locationForm.controls.areaLocation.value !== "" && this.locationForm.controls.areaLocation.value !== null){
              this.locationForm.controls.areaLocation.setValue(this.locationForm.controls.areaLocation.value + " " +result.address_components[i].long_name);
            }
            else{
              this.locationForm.controls.areaLocation.setValue(result.address_components[i].long_name);
            }
          }

        }
        if (result.address_components[i].types[j] === 'sublocality_level_1') {
          if(result.address_components[i].long_name !== null && result.address_components[i].long_name !== 'null'){
            if(this.locationForm.controls.areaLocation.value !== "" && this.locationForm.controls.areaLocation.value !== null){
              this.locationForm.controls.areaLocation.setValue(this.locationForm.controls.areaLocation.value+ " " +result.address_components[i].long_name);
            }
            else{
              this.locationForm.controls.areaLocation.setValue(result.address_components[i].long_name);
            }
          }
        }
        if (result.address_components[i].types[j] === 'administrative_area_level_1') {
          this.locationForm.controls.stateLocation.setValue(result.address_components[i].short_name);
        }

        if (result.address_components[i].types[j] === 'country') {
          this.locationForm.controls.countryLocation.setValue(result.address_components[i].long_name);

        }
        if (result.address_components[i].types[j] === 'postal_code') {
          this.locationForm.controls.postalLocation.setValue(result.address_components[i].long_name);
        }
      }
    }

    let isEnteredCityCorrect = false;
    for (i = 0; i < this.areaData.length; i++) {
      if (this.areaData[i].city.toLowerCase() == this.locationForm.controls.cityLocation.value.toLowerCase()) {
        isEnteredCityCorrect = true;
        break;
      }
    }

    if (isEnteredCityCorrect == false) {
      // wrong modal here
      this.selectedCity = this.locationForm.controls.cityLocation.value;
      this.isDismissTryAgain = false;

      $('#selectedAddressPopUp').modal('hide');
      $('#wrongCityPopUp').modal('show');
    }
    else {
      $('#selectedAddressPopUp').modal('show');
      $('#wrongCityPopUp').modal('hide');
    }

    this.deliveryAddressSearchLoader = false;
    this.toggleDisableFields();
    // $('#selectedAddressPopUp').modal('show');


    this.initMapFromCurrentLocation();
  }

  placeLabelLocation: any;
  areaLocation: any;
  houseNoLocation: any;
  aptLocation: any;
  streetLocation: any;
  cityLocation: any;
  stateLocation: any;
  postalLocation: any;
  disableMultiClick = false;
  mySelectedPinCity:any;
  deliverHere() {

    this.orderNotesNew = '';
    this.isDismissTryAgain = false;
    if (this.locationForm.controls.placeLabelLocation.value === "" || this.locationForm.controls.placeLabelLocation.value === undefined) {
      toastr.error('Kindly Select Place Label!');
      return;
    }
    if (this.locationForm.controls.areaLocation.value === "" || this.locationForm.controls.areaLocation.value === undefined) {
      toastr.error('Tell us your area or block!');
      return;
    }
    if (this.locationForm.controls.cityLocation.value === "" || this.locationForm.controls.cityLocation.value === null) {
      toastr.error('Kindly Enter City!');
      return;
    }

    if(this.myPinCity != undefined && this.myPinCity != null && this.myPinCity != ''){
      for (let p = 0; p < this.myPinCity.address_components.length; p++) {
        for (var j = 0; j < this.myPinCity.address_components[p].types.length; j++) {
          if (this.myPinCity.address_components[p].types[j] === 'administrative_area_level_2') {
            this.mySelectedPinCity = this.myPinCity.address_components[p].long_name;
          }
        }
        console.log('administrative level 2 ', this.myPinCity.address_components[p]);
      }

      console.log(this.mySelectedPinCity);
      console.log(this.locationForm.controls.cityLocation.value);
      if(this.mySelectedPinCity.toLowerCase() !== this.locationForm.controls.cityLocation.value.toLowerCase()){
        toastr.error('Your Pin Location is not in ' + this.locationForm.controls.cityLocation.value + '!');
        return;
      }

    }



    var mycity = this.locationForm.controls.cityLocation.value.toLowerCase();
    let i = 0;
    for (i = 0; i < this.areaData.length; i++) {
      if (this.areaData[i].city.toLowerCase() == mycity) {
        this.streetDeliveryHome = '';

        if (this.locationForm.controls.houseNoLocation.value != '' && this.locationForm.controls.houseNoLocation.value != null) {
          if (this.streetDeliveryHome != ', ' && this.streetDeliveryHome != '') {
            this.streetDeliveryHome = this.streetDeliveryHome + ", " + this.locationForm.controls.houseNoLocation.value;
          }
          else {
            this.streetDeliveryHome = this.locationForm.controls.houseNoLocation.value;
          }
        }
        if (this.locationForm.controls.aptLocation.value != '' && this.locationForm.controls.aptLocation.value != null) {
          if (this.streetDeliveryHome != ', ' && this.streetDeliveryHome != '') {
            this.streetDeliveryHome = this.streetDeliveryHome + ", " + this.locationForm.controls.aptLocation.value;
          }
          else {
            this.streetDeliveryHome = this.locationForm.controls.aptLocation.value;
          }
        }

        if (this.locationForm.controls.areaLocation.value != '' && this.locationForm.controls.areaLocation.value != null) {
          if (this.streetDeliveryHome != ', ' && this.streetDeliveryHome != '') {
            this.streetDeliveryHome = this.streetDeliveryHome + ", " + this.locationForm.controls.areaLocation.value;
          }
          else {
            this.streetDeliveryHome = this.locationForm.controls.areaLocation.value;
          }
        }
        if (this.locationForm.controls.cityLocation.value != '' && this.locationForm.controls.cityLocation.value != null) {

          if (this.streetDeliveryHome != ', ' && this.streetDeliveryHome != '') {
            this.streetDeliveryHome = this.streetDeliveryHome + ", " + this.locationForm.controls.cityLocation.value;
          }
          else {
            this.streetDeliveryHome = this.locationForm.controls.cityLocation.value;
          }
        }
        // if (this.locationForm.controls.stateLocation.value != '' && this.locationForm.controls.stateLocation.value != null) {
        //   if (this.streetDeliveryHome != ', ' && this.streetDeliveryHome != '') {
        //     this.streetDeliveryHome = this.streetDeliveryHome + ", " + this.locationForm.controls.stateLocation.value;
        //   }
        //   else {
        //     this.streetDeliveryHome = this.locationForm.controls.stateLocation.value;
        //   }
        // }
        // if (this.locationForm.controls.postalLocation.value != '' && this.locationForm.controls.postalLocation.value != null) {
        //   if (this.streetDeliveryHome != ', ' && this.streetDeliveryHome != '') {
        //     this.streetDeliveryHome = this.streetDeliveryHome + " " + this.locationForm.controls.postalLocation.value;
        //   }
        //   else {
        //     this.streetDeliveryHome = this.locationForm.controls.postalLocation.value;
        //   }
        // }
        // if (this.locationForm.controls.countryLocation.value != '' && this.locationForm.controls.countryLocation.value != null) {
        //   if (this.streetDeliveryHome != ', ' && this.streetDeliveryHome != '') {
        //     this.streetDeliveryHome = this.streetDeliveryHome + ", " + this.locationForm.controls.countryLocation.value;
        //   }
        //   else {
        //     this.streetDeliveryHome = this.locationForm.controls.countryLocation.value;
        //   }
        // }

        break;
      }
      if (i == this.areaData.length - 1) {
        this.selectedCity = mycity;
        this.isDismissTryAgain = false;

        $('#selectedAddressPopUp').modal('hide');
        $('#wrongCityPopUp').modal('show');
      }
    }


    if (i < this.areaData.length) {
      $('#myAddressLabel').css('color', 'unset');

      //check if city same or not if not empty cart.
      this.myProfileAddress = JSON.parse(localStorage.getItem('profileAddress'));
      let cartItems = localStorage.getItem('cartItems');


      if (this.myProfileAddress !== 'null' && this.myProfileAddress !== null) {
        if (this.myProfileAddress.city !== this.locationForm.controls.cityLocation.value && cartItems != '[]' && cartItems != null) {
          // show empty cart
          $('#onChangingCityOnHome').modal('show');
          return;
        }

      }

      this.orderNotesNew = '';

      $('#selectedAddressPopUp').modal('hide');
      localStorage.setItem('delivery_address', this.streetDeliveryHome);
      localStorage.setItem('myDeliveryAddress', this.streetDeliveryHome);

      this.deliveryAddressObj = {
        id: '',
        street: this.locationForm.controls.areaLocation.value,
        address_line1: this.locationForm.controls.areaLocation.value,
        zip: this.locationForm.controls.postalLocation.value,
        city: this.locationForm.controls.cityLocation.value,
        country: this.locationForm.controls.countryLocation.value,
        state: this.locationForm.controls.stateLocation.value,
        latitude: this.latitudeDelivery,
        longitude: this.longitudeDelivery,
        apart_no: this.locationForm.controls.aptLocation.value,
        house_building_no: this.locationForm.controls.houseNoLocation.value,
        place_label: this.locationForm.controls.placeLabelLocation.value,
        notes: this.orderNotesNew
      };
      localStorage.setItem('profileAddress', JSON.stringify(this.deliveryAddressObj));
      localStorage.setItem('profileAddressCheckout', JSON.stringify(this.deliveryAddressObj));

      this.router.navigate(['/businesses', this.locationForm.controls.cityLocation.value]);

    }

  }
  emptyCartOnDeliverHere() {
    $('#myAddressLabel').css('color', 'unset');

    // change city so empty cart here
    this.dataControlService.emptyCartOnAppComponent.next(true);
    this.disableMultiClick = true;
    this.events.publish('cartUpdate', []);
    localStorage.removeItem('cartItems');
    $('#onChangingCityOnHome').modal('hide');
    this.disableMultiClick = false;

    $('#selectedAddressPopUp').modal('hide');
    localStorage.setItem('delivery_address', this.streetDeliveryHome);
    localStorage.setItem('myDeliveryAddress', this.streetDeliveryHome);

    this.deliveryAddressObj = {
      id: '',
      street: this.locationForm.controls.areaLocation.value,
      address_line1: this.locationForm.controls.areaLocation.value,
      zip: this.locationForm.controls.postalLocation.value,
      city: this.locationForm.controls.cityLocation.value,
      country: this.locationForm.controls.countryLocation.value,
      state: this.locationForm.controls.stateLocation.value,
      latitude: this.latitudeDelivery,
      longitude: this.longitudeDelivery,
      apart_no: this.locationForm.controls.aptLocation.value,
      house_building_no: this.locationForm.controls.houseNoLocation.value,
      place_label: this.locationForm.controls.placeLabelLocation.value,
      notes: this.orderNotesNew
    };
    localStorage.setItem('profileAddress', JSON.stringify(this.deliveryAddressObj));
    localStorage.setItem('profileAddressCheckout', JSON.stringify(this.deliveryAddressObj));

    this.router.navigate(['/businesses', this.locationForm.controls.cityLocation.value]);
  }
  emptyCartOnSuggestion() {
    // change city so empty cart here
    $('#myAddressLabel').css('color', 'unset');
    this.dataControlService.emptyCartOnAppComponent.next(true);
    this.disableMultiClick = true;
    this.events.publish('cartUpdate', []);
    localStorage.removeItem('cartItems');
    $('#onChangingCityOnSuggestion').modal('hide');
    this.disableMultiClick = false;
    this.isSuggestionAddressShown = false;
    this.isSearchAddressShown = false;
    this.isLocationFinalized = true;
    this.shopnowState = 'enable';
    this.streetDeliveryHome = this.mySelectedaddress;
    $('#selectedAddressPopUp').modal('hide');
    localStorage.setItem('delivery_address', this.streetDeliveryHome);
    localStorage.setItem('myDeliveryAddress', this.streetDeliveryHome);

    // this.deliveryAddressObj = {
    //   id: this.addressId,
    //   street: this.locationForm.controls.areaLocation.value,
    //   address_line1: this.locationForm.controls.areaLocation.value,
    //   zip: this.locationForm.controls.postalLocation.value,
    //   city: this.locationForm.controls.cityLocation.value,
    //   country: this.locationForm.controls.countryLocation.value,
    //   state: this.locationForm.controls.stateLocation.value,
    //   latitude: this.latitudeDelivery,
    //   longitude: this.longitudeDelivery,
    //   apart_no: this.locationForm.controls.aptLocation.value,
    //   house_building_no: this.locationForm.controls.houseNoLocation.value,
    //   place_label: this.locationForm.controls.placeLabelLocation.value,
    //   notes: this.orderNotesNew
    // };
    // localStorage.setItem('profileAddress', JSON.stringify(this.deliveryAddressObj));
    // this.router.navigate(['/businesses', this.locationForm.controls.cityLocation.value]);
  }


  startSavingClicked() {
    var myTopSection = document.getElementById("myTopSection");
    myTopSection.scrollIntoView();
    document.getElementById('streetDeliveryHome').focus();
    document.getElementById('streetDeliveryHome').click();

  }
  getStartedClicked() {
    var myTopSection = document.getElementById("myTopSection");
    myTopSection.scrollIntoView();
    document.getElementById('streetDeliveryHome').focus();
    document.getElementById('streetDeliveryHome').click();

  }


  citiesWeOperateInModal() {
    $('#wrongCityPopUp').modal('hide');
    $('#operatedCitiesPopUp').modal('hide');
    $('#selectedAddressPopUp').modal('hide');
    var serviceAreaSection = document.getElementById("myServiceAreas");
    serviceAreaSection.scrollIntoView();
  }
  goToNotFoundCityModal() {
    this.isDismissTryAgain = false;

    $('#wrongCityPopUp').modal('show');
    $('#operatedCitiesPopUp').modal('hide');
    $('#selectedAddressPopUp').modal('hide');

  }

  goToLocationSelectionModal() {
    $('#selectedAddressPopUp').modal('hide');
    $('#wrongCityPopUp').modal('hide');
    $('#operatedCitiesPopUp').modal('hide');
    $('#myAddressLabel').css('color', 'unset');

    setTimeout(() => {
      this.isSuggestionAddressShown = false;
      this.isSearchAddressShown= false;
      this.streetDeliveryHome = '';
      document.getElementById('streetDeliveryHome').setAttribute('aria-expanded', 'true');
      // document.getElementById('streetDeliveryHome').focus();

    }, 100);
  }
  customerProfileList: any;
  deliveryAddressObj: any;
  opencheckoutButemptyAddress: any;


  setPlaceLabel(value) {
    if (value == 'Home') {
      this.locationForm.controls['placeLabelLocation'].disable();
      this.locationForm.controls.placeLabelLocation.setValue('Home');
      this.myDropDownPlaceLabel = 'Home';
      this.isCustomPlaceLabel = false;

    }
    else if (value == 'Work') {
      this.locationForm.controls['placeLabelLocation'].disable();
      this.locationForm.controls.placeLabelLocation.setValue('Work');
      this.myDropDownPlaceLabel = 'Work';
      this.isCustomPlaceLabel = false;

    }
    else {
      this.locationForm.controls['placeLabelLocation'].enable();
      this.locationForm.controls.placeLabelLocation.setValue('');
      this.myDropDownPlaceLabel = 'Custom';
      this.isCustomPlaceLabel = true;
      this.clearTimeout = setTimeout(() => {
        document.getElementById('myCustomPlaceLabel').focus();
        clearTimeout(this.clearTimeout);

      }, 100);
    }
  }

  isLocationFinalized: boolean = false;

  shopNow() {


    // if(this.shopnowState == 'disable'){
    //   // focus field, show error messege and make label red

    //   toastr.error("Please select a valid address");
    //   $('#streetDeliveryHome').focus();
    //   $('#myAddressLabel').css('color', '#B80D38');

    //   return;
    // }


    this.deliveryAddressObj = {
      id: this.addressId,
      street: this.locationForm.controls.areaLocation.value,
      address_line1: this.locationForm.controls.areaLocation.value,
      zip: this.locationForm.controls.postalLocation.value,
      city: this.locationForm.controls.cityLocation.value,
      country: this.locationForm.controls.countryLocation.value,
      state: this.locationForm.controls.stateLocation.value,
      latitude: this.latitudeDelivery,
      longitude: this.longitudeDelivery,
      apart_no: this.locationForm.controls.aptLocation.value,
      house_building_no: this.locationForm.controls.houseNoLocation.value,
      place_label: this.locationForm.controls.placeLabelLocation.value,
      notes: this.orderNotesNew
    };
    localStorage.setItem('profileAddress', JSON.stringify(this.deliveryAddressObj));
    localStorage.setItem('profileAddressCheckout', JSON.stringify(this.deliveryAddressObj));

    this.dataControlService.makePrimaryAddress.next(this.deliveryAddressObj);

    localStorage.setItem('delivery_address', this.streetDeliveryHome);
    localStorage.setItem('myDeliveryAddress', this.streetDeliveryHome);
    this.router.navigate(['/businesses', this.locationForm.controls.cityLocation.value]);

    // localStorage.setItem("isFirstTimeHomeOpen", "false");
  }

  showLoderAtCheckoutAddress: boolean;
  getAddressListing() {

    this.dataService.getAddressList(localStorage.getItem('token')).subscribe(
      (data) => {
        this.customerProfileList = data;
      },
      (error) => {
      }
    );
  }

  mySuggestedAddressSelectedIndex: any;
  mySelectedaddress: any;
  suggestedAddressSelected(index) {
    let i = 0;
    this.mySelectedaddress = '';
    this.isDismissTryAgain = false;

    for (i = 0; i < this.areaData.length; i++) {
      if (this.areaData[i].city.toLowerCase() == this.customerProfileList.address_dictionary[index].city.toLowerCase()) {
        // this.mySelectedaddress = this.mySelectedaddress + this.customerProfileList.address_dictionary[index].address_line1 + ', ';

        if (this.customerProfileList.address_dictionary[index].zip == null || this.customerProfileList.address_dictionary[index].zip == '') {

          if (this.customerProfileList.address_dictionary[index].house_building_no !== "") {
            this.mySelectedaddress = this.mySelectedaddress + this.customerProfileList.address_dictionary[index].house_building_no + ', ';
          }
          if (this.customerProfileList.address_dictionary[index].apart_no !== "") {
            this.mySelectedaddress = this.mySelectedaddress + this.customerProfileList.address_dictionary[index].apart_no + ', ';
          }
          this.mySelectedaddress = this.mySelectedaddress + this.customerProfileList.address_dictionary[index].address_line1 + ', ';
          this.mySelectedaddress = this.mySelectedaddress + this.customerProfileList.address_dictionary[index].city;
          //  + ", " + this.customerProfileList.address_dictionary[index].state + ', ' + this.customerProfileList.address_dictionary[index].country;

        }
        else {
          if (this.customerProfileList.address_dictionary[index].house_building_no !== "") {
            this.mySelectedaddress = this.mySelectedaddress + this.customerProfileList.address_dictionary[index].house_building_no + ', ';
          }
          if (this.customerProfileList.address_dictionary[index].apart_no !== "") {
            this.mySelectedaddress = this.mySelectedaddress + this.customerProfileList.address_dictionary[index].apart_no + ', ';
          }
          this.mySelectedaddress = this.mySelectedaddress + this.customerProfileList.address_dictionary[index].address_line1 + ', ';
          this.mySelectedaddress = this.mySelectedaddress + this.customerProfileList.address_dictionary[index].city;
          //  + ", " + this.customerProfileList.address_dictionary[index].state + " " + this.customerProfileList.address_dictionary[index].zip + ', ' + this.customerProfileList.address_dictionary[index].country;
        }

        break;
      }
      if (i == this.areaData.length - 1) {
        this.selectedCity = this.customerProfileList.address_dictionary[index].city;
        $('#selectedAddressPopUp').modal('hide');
        $('#wrongCityPopUp').modal('show');

        if(this.streetDeliveryHome == ''){
          $('#myAddressLabel').css('color', 'unset');
        }

        // this.isLocationFinalized = false;
      }
    }
    if (i < this.areaData.length) {
      this.addressId = this.customerProfileList.address_dictionary[index].id;
      this.locationForm.controls.cityLocation.setValue(this.customerProfileList.address_dictionary[index].city);
      this.locationForm.controls.postalLocation.setValue(this.customerProfileList.address_dictionary[index].zip);
      this.locationForm.controls.stateLocation.setValue(this.customerProfileList.address_dictionary[index].state);
      this.locationForm.controls.countryLocation.setValue(this.customerProfileList.address_dictionary[index].country);
      this.locationForm.controls.aptLocation.setValue(this.customerProfileList.address_dictionary[index].apart_no);
      this.locationForm.controls.houseNoLocation.setValue(this.customerProfileList.address_dictionary[index].house_building_no);
      this.locationForm.controls.areaLocation.setValue(this.customerProfileList.address_dictionary[index].address_line1);
      this.locationForm.controls.placeLabelLocation.setValue(this.customerProfileList.address_dictionary[index].place_label);
      this.latitudeDelivery = this.customerProfileList.address_dictionary[index].latitude;
      this.longitudeDelivery = this.customerProfileList.address_dictionary[index].longitude;
      this.orderNotesNew = this.customerProfileList.address_dictionary[index].notes;


      //check if city same or not if not empty cart.
      this.myProfileAddress = JSON.parse(localStorage.getItem('profileAddress'));
      let cartItems = localStorage.getItem('cartItems');


      if (this.myProfileAddress !== 'null' && this.myProfileAddress !== null) {
        if (this.myProfileAddress.city !== this.locationForm.controls.cityLocation.value && cartItems != '[]' && cartItems != null) {
          // show empty cart
          $('#onChangingCityOnSuggestion').modal('show');
          this.isLocationFinalized = false;
          this.shopnowState = 'disable';

          return;
        }

      }
      $('#myAddressLabel').css('color', 'unset');

      this.shopnowState = 'enable';

      this.isLocationFinalized = true;
      this.streetDeliveryHome = '';
      this.mySuggestedAddressSelectedIndex = this.customerProfileList.address_dictionary[index].id;

      this.streetDeliveryHome = this.mySelectedaddress;
      this.isSuggestionAddressShown = false;
      this.isSearchAddressShown = false;

      $('#selectedAddressPopUp').modal('hide');
      this.addressId = this.customerProfileList.address_dictionary[index].id;

      this.deliveryAddressObj = {
        id: this.addressId,
        street: this.locationForm.controls.areaLocation.value,
        address_line1: this.locationForm.controls.areaLocation.value,
        zip: this.locationForm.controls.postalLocation.value,
        city: this.locationForm.controls.cityLocation.value,
        country: this.locationForm.controls.countryLocation.value,
        state: this.locationForm.controls.stateLocation.value,
        latitude: this.latitudeDelivery,
        longitude: this.longitudeDelivery,
        apart_no: this.locationForm.controls.aptLocation.value,
        house_building_no: this.locationForm.controls.houseNoLocation.value,
        place_label: this.locationForm.controls.placeLabelLocation.value,
        notes: this.orderNotesNew
      };
      localStorage.setItem('profileAddress', JSON.stringify(this.deliveryAddressObj));
      localStorage.setItem('profileAddressCheckout', JSON.stringify(this.deliveryAddressObj));

      this.dataControlService.makePrimaryAddress.next(this.deliveryAddressObj);

      localStorage.setItem('delivery_address', this.streetDeliveryHome);
      localStorage.setItem('myDeliveryAddress', this.streetDeliveryHome);
      this.router.navigate(['/businesses', this.locationForm.controls.cityLocation.value]);

      // localStorage.setItem("isFirstTimeHomeOpen", "false");


    }
  }
  addressId: any;
  isSuggestionAddressShown: boolean = false;
  isSearchAddressShown: boolean = false;
  showAddressInput:boolean = true;

  addressLabelClicked(){
    $('#streetDeliveryHome').focus();
  }
  closeModal(){
    this.showAddressInput = false;
    $('#myAddressLabel').css('color', 'unset');
    this.shopnowState = 'disable';
    this.streetDeliveryHome = '';
    this.isSuggestionAddressShown = false;
    this.isSearchAddressShown= false;
    this.locationForm.reset();
    setTimeout(() => {
      this.showAddressInput = true;
      var streetDeliveryId = document.getElementById('streetDeliveryHome');

      if(streetDeliveryId != undefined && streetDeliveryId != null){
        document.getElementById('streetDeliveryHome').setAttribute('aria-expanded', 'true');
      }

    }, 100);

  }
  toggleSuggestedFields() {
    if (this.isSuggestionAddressShown) {
      this.isSuggestionAddressShown = false;
      // this.isSearchAddressShown = true;
    }
    else {
      this.isSuggestionAddressShown = true;
      this.isSearchAddressShown = false;

    }

    // this.isSuggestionAddressShown = !this.isSuggestionAddressShown;
    document.getElementById('streetDeliveryHome').focus();
    document.getElementById('streetDeliveryHome').setAttribute('aria-expanded', 'true');

    // let el: HTMLElement = this.myLocationSearch.nativeElement;
    // el.click();
  }

  addAddressSuccess: any;
  myAddressObj: any;
  addProfileAddress() {
    this.orderNotesNew = '';

    this.dataService.addNewProfileAddreess(localStorage.getItem('token'),
      this.locationForm.controls.areaLocation.value,
      this.locationForm.controls.cityLocation.value,
      this.locationForm.controls.stateLocation.value,
      this.locationForm.controls.countryLocation.value,
      this.locationForm.controls.postalLocation.value,
      this.latitudeDelivery,
      this.longitudeDelivery,
      this.locationForm.controls.aptLocation.value,
      this.locationForm.controls.placeLabelLocation.value,
      this.locationForm.controls.houseNoLocation.value,
      this.locationForm.controls.streetLocation.value,
      this.orderNotesNew)
      .subscribe(
        (data) => {
          this.addAddressSuccess = data;
          if (this.addAddressSuccess.success === true) {
            this.myAddressObj = {
              address_id: this.addAddressSuccess.address_id,
              isAddedAddress: false,
              areaLocation: this.locationForm.controls.areaLocation.value,
              cityLocation: this.locationForm.controls.cityLocation.value,
              stateLocation: this.locationForm.controls.stateLocation.value,
              countryLocation: this.locationForm.controls.countryLocation.value,
              postalLocation: this.locationForm.controls.postalLocation.value,
              latitudeDelivery: this.latitudeDelivery,
              longitudeDelivery: this.longitudeDelivery,
              aptLocation: this.locationForm.controls.aptLocation.value,
              placeLabelLocation: this.locationForm.controls.placeLabelLocation.value,
              houseNoLocation: this.locationForm.controls.houseNoLocation.value,
              streetLocation: this.locationForm.controls.streetLocation.value,
              orderNotesNew: this.orderNotesNew
            }
            this.dataControlService.makePrimaryAddress.next(this.myAddressObj);

            $('#selectedAddressPopUp').modal('hide');
            localStorage.setItem('delivery_address', this.streetDeliveryHome);
            localStorage.setItem('myDeliveryAddress', this.streetDeliveryHome);
            this.router.navigate(['/businesses', this.locationForm.controls.cityLocation.value]);
          }
          else {
            toastr.error(this.addAddressSuccess.message);
          }

        },
        (error) => {
          console.log(error);
        }
      );
  }

  readMore(){
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("readMoreBtn");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less";
      moreText.style.display = "inline";
    }
  }
  setMetaTags(title, image, description, keyword) {
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:image', content: image });
    this.meta.updateTag({ property: 'og:site_name', content: 'bestbuymall.co' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ property: 'description', content: description });
    this.meta.updateTag({ property: 'keywords', content: keyword });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ name: 'twitter:title', content: title });
    this.meta.updateTag({ name: 'twitter:description', content: description });
    this.meta.updateTag({ name: 'twitter:image', content: image });
    this.meta.updateTag({ name: 'twitter:site', content: 'bestbuymall.co' });
    this.title.setTitle(title);

  }
  home_seo_data:any;
  get_home_seo(){
    this.dataService.get_home_seo().subscribe(
      (data) => {
        this.home_seo_data = data;
        this.setMetaTags(this.home_seo_data.seo_title, "https://cdn.bestbuymall.co/images/homepage/15742323.png", this.home_seo_data.seo_description, this.home_seo_data.seo_keyword);
      },
      (error) => {
        console.log(error);
      }
    );
  }

}

