import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventsService } from 'angular4-events';

import { DataService } from '../data.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  sidebarCategory: any;
  pushReceive: any;
  dispensaryId: any = '';
  themeStructureId: any;
  parentCatName: any = '';
  subCatName: any = '';
  childCatName: any = '';
  id: any;
  loadingData: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pubsub: EventsService,
    private dataService: DataService,
    private events: EventsService,
  ) {
    this.events.subscribe('businessAliasId').subscribe((data) => {
      this.dispensaryId = data;
      console.log(this.dispensaryId);
      this.dedicatedCategoryListing(this.dispensaryId);
    });
    this.events.subscribe('idForNavigate').subscribe((data) => {
      this.id = data;
    });
    console.log(this.dispensaryId);

    this.events.subscribe('businessThemeStructure').subscribe((data) => {
      this.themeStructureId = data;
    });
  }

  ngOnInit() {
    this.loadingData = true;
  }

  dedicatedCategoryListing(dispensaryId) {
    this.dataService.dedicatedCategoryListing(dispensaryId).subscribe(
      data => {
        this.sidebarCategory = data;
        this.loadingData = false;
        console.log(this.sidebarCategory);
      },
      err => {
        console.log(err);
      }
    );
  }

  parentCatFunction(id, name) {
    this.parentCatName = name;
    this.subCatName = '';
    this.childCatName = '';
    localStorage.setItem('parentCatName', this.parentCatName);
    localStorage.setItem('subCatName', this.subCatName);
    localStorage.setItem('childCatName', this.childCatName);
    this.router.navigate([this.id, id]);
    // this.businessCategoryProducts = [];
    // this.getBusinessDetail();
  }

  subCatFunction(parentId, subcatId, parentName, name) {
    this.parentCatName = parentName;
    this.subCatName = name;
    this.childCatName = '';
    localStorage.setItem('parentCatName', this.parentCatName);
    localStorage.setItem('subCatName', this.subCatName);
    localStorage.setItem('childCatName', this.childCatName);
    this.router.navigate([this.id, parentId, subcatId]);
    // this.businessCategoryProducts = [];
    // this.getBusinessDetail();
  }

  childCatFunction(parentId, subcatId, childcatId, parentName, subCatName, name) {
    this.parentCatName = parentName;
    this.subCatName = subCatName;
    this.childCatName = name;
    localStorage.setItem('parentCatName', this.parentCatName);
    localStorage.setItem('subCatName', this.subCatName);
    localStorage.setItem('childCatName', this.childCatName);
    this.router.navigate([this.id, parentId, subcatId, childcatId]);
    // this.businessCategoryProducts = [];
    // this.getBusinessDetail();
  }

}
