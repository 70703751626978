import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from './constants';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';


// const httpOptions = {
//   headers: new HttpHeaders({ 'Content-Type': 'application/json' })
// };
// const version_number = 1.9;
const version_number = 3.0;

const headers = new HttpHeaders({});

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  tokePush: any;
  cartData = new Array();
  constructor(private http: HttpClient) {
  }

  login(phone_number) {
    return this.http.post(Constants.apiClient + '/login', JSON.stringify({ phone_number, version_number}),
      { headers: headers });
  }
  register(phone_number, verification_code, register_new, first_name, last_name, date_of_birth, gender) {
    return this.http.post(Constants.apiClient + '/validate_phone', JSON.stringify({ phone_number, verification_code, register_new, first_name, last_name, date_of_birth, gender, version_number}),
      { headers: headers });
  }
  getInfoFromToken(auth_token) {
    return this.http.post(Constants.apiAddress + '/get_client_information', JSON.stringify({ auth_token, version_number}),
      { headers: headers });
  }
  pushNotify(auth_token, fcm_token) {
    return this.http.post(Constants.apiClient + '/register_fcm_token', JSON.stringify({ auth_token, fcm_token, version_number}),
      { headers: headers });
  }
  // register(alias, username, business_name, password, key) {
  //   // 'Content-Type': 'application/json'
  //   const version_number = 1.0;
  //   const headers = new HttpHeaders({});
  //   return this.http.post(Constants.apiAddress + '/create_password',
  //     JSON.stringify({alias, username, business_name, password, key, version_number}),
  //     { headers: headers });
  // }
  logout(auth_token) {
    // 'Content-Type': 'application/json'
    return this.http.post(Constants.apiClient + '/logout',
      JSON.stringify({auth_token, version_number}),
      { headers: headers });
  }
}
