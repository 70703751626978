import { Component, OnInit, HostListener } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { EventsService } from "angular4-events";
import { Constants } from "../constants";
import { DataControlService } from "../data-control.service";
import { DataService } from "../data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { fb_events_consts } from "../fbq-constants";
import { FbEventsService } from "../fb-events.service";
// import { AsyncLocalStorage } from 'angular-async-local-storage';
// import { MetaService } from 'ng2-meta';

declare var $: any;
declare var Nanobar: any;
declare var toastr: any;
declare var require: any;
declare var fbq: any;

@Component({
  selector: "app-searched-business-products-view",
  templateUrl: "./searched-business-products-view.component.html",
  styleUrls: ["./searched-business-products-view.component.css"],
})
export class SearchedBusinessProductsViewComponent implements OnInit {
  priceIconForSingle = Constants.iconForSinglePrice;
  priceIconForDouble = Constants.iconForDoublePrice;

  loaderCondition: boolean;
  SeeAllLoader: boolean = false;
  businessDetailProductSearchString: any = "";
  businessStatus: string;
  openOrCloseIn: string;
  businessRaitingArray: any;
  businessId: any;
  todayData1 = new Date();
  todayDateVal = this.todayData1.getDay();
  businessDetailData: any;
  businessDetalSuccess: any;
  businessRaiting: any;
  finalDisplayTime: any;
  dateObjOpen: Date;
  dateObjClose: Date;
  currentSystemTime: number;
  businessName: any;
  business_image: any;
  business_delivery_charges: any;
  business_platform_fee: any;
  business_total_charges: any;
  taxOfBusiness: any;
  cartDataFromStorage: any;
  payment_through_card: any;
  payment_through_cash: any;
  payment_over_due: any;
  fullDayStatus: any;
  timeObj: any;
  detailDataLoad: boolean = true;
  outletAddress: {
    area: any;
    city: any;
    state: any;
    zip: any;
    country: any;
    longitude: any;
    latitude: any;
  };
  id: any;
  subCatid: number;
  searchItemCat: boolean = false;
  PagnationPage: number;
  serchstring1: string;
  reviewBusinessId: any;
  ReviewData: any;
  reviewLastPage: any;
  reviewLastPageCount: number;
  customOrderText: string;
  addCustomOrderBtn: boolean;
  dropzone: any;
  customProductImages: any;
  conditionToCheck = true;
  conditionToCheckforfun = true;
  businessCatLoding: boolean;
  businessProductData: boolean;
  inputSearch: boolean = true;
  searchProductByCategoryByID: any = [];
  loadPreviousProductsData: any;
  showBusinessProductsliderLoading: boolean;
  businessDetailProducts: any = undefined;
  loadPreviousProductsDataCount: number;
  lastPage: any;
  loadPreviousProductsDataLastPage: any;
  searchProductByCategoryByIDByPage: any;
  widthOfWindow: any;
  searchStringCat: string = "";
  cat_id: any;
  cat_name: any = "";
  loadingDataCat: boolean = true;
  subCatData: any;
  subCatLength: any = 0;
  subcatLoadingShimmer: boolean = false;
  businessCategoryProductslength: boolean = false;
  PaginationPageByCategory = 0;
  businessCategoryProducts: any;
  lastPageOfCategory: any = true;
  detailProductSuccess: any;
  Name: any;
  nao = new Nanobar();
  productDetailData: any;
  productSuccess: any;
  productDetailDataImagesLength: any;
  cartPopData: any;
  variantData: any;
  addWishlistSuccess: any;
  websiteUrl = Constants.websiteUrl;
  searchProductByCategoryByIDLastPage = true;
  myDeliveryAddress: any;
  cityName: any;
  isC1Active: boolean;
  isC2Active: boolean;
  isC3Active: boolean;
  activeTab: any = "products";
  scrolledNav = true;

  filteredDataViewAll = {
    price_range: {
      min_price: "any",
      max_price: "any",
    },
    search: "",
  };
  filteredDataViewOnSeeAll = {
    price_range: {
      min_price: "any",
      max_price: "any",
    },
    search: "",
  };
  DefaultfilteredData = {
    price_range: {
      min_price: "any",
      max_price: "any",
    },
    search: "",
  };
  DefaultfilteredDataForSeeAll = {
    price_range: {
      min_price: "any",
      max_price: "any",
    },
    search: "",
  };
  viewAllSearchResults: any;
  subcatSearchLoadingShimmer: boolean = false;
  variantsData: any;
  variantsDataLength: any;

  productData: any;
  addItValue: any;
  productModifierDataArray = new Array();
  modifiersArr: any[];
  addonsArr: any[];
  noOptionsArr: any[];
  additonalValue: number;
  newCart: any;
  selectedVariant: any;
  uniqueId: any;
  modifierProductList = new Array();
  oldUniqueId: any = "";
  cartUnavailHead: any = "";
  cartUnavailText: any = "";
  hasCustomOrder: any;
  colorCode: string;
  isSingleOrder: number = 0;

  constructor(
    private dataControlService: DataControlService,
    private dataService: DataService,
    private events: EventsService,
    private meta: Meta,
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private fbEventsService: FbEventsService
  ) {
    this.colorCode = Constants.colorCode;
  }

  // Facebook + Button Automatically Detect Event Call
  buttonAutomaticallyDetect() {
    this.fbEventsService.buttonAutomaticallyDetect();
  }

  // Facebook + Button Automatically Detect Event Call
  fbViewContentProduct(product) {
    this.fbEventsService.fbViewContentProduct(product.name);
  }

  // Facebook Add To Wish List Event Call
  fbAddToWishList(product) {
    this.fbEventsService.fbAddToWishList(product.title, this.paramBusinessName);
  }

  // Facebook Add To Cart Event Call
  fbAddToCart() {
    this.fbEventsService.fbAddToCart(this.paramBusinessName);
  }

  // Facebook Unique Add To Cart Event Call
  fbUniqueAddToCart() {
    this.fbEventsService.fbUniqueAddToCart(this.paramBusinessName);
  }

  prevWidth: any;
  paramBusinessName: any;
  businessDetailaddress_line1: any;
  businessDetailCity: any;
  cartChngeFromCheckout: any;
  cartPush: any;
  islogin: any;
  isloginValue: any;
  pushReceive: any;

  productSectionOptions: any = {
    loop: false,
    dots: false,
    mouseDrag: false,
    touchDrag: true,
    navSpeed: 700,
    stagePadding: 20,
    autoWidth: true,
    responsiveClass: true,
    navText: [
      '<img src="../../assets/arrowLeftWhite.png" style="width: 50px">',
      '<img src="../../assets/arrowRightWhite.png" style="width: 50px">',
    ],
    responsive: {
      0: {
        items: 2,
      },
      560: {
        items: 3,
      },
      768: {
        items: 4,
      },
    },
    nav: true,
  };

  ngOnInit() {
    this.dataControlService.isOnBusinessDetailPage.next(true);
    this.dataControlService.isSignInWishlistClicked.next(false);
    this.dataControlService.getMoreproductDetailOnProductPage.subscribe(
      (data) => {
        if (data == true) {
          this.isProductSearchSelect = true;
        }
      }
    );
    this.cartChngeFromCheckout = this.events
      .subscribe("cartUpdate")
      .subscribe((data) => {
        setTimeout(() => {
          this.cartChngeFromCheckoutDone();
          //this.changeRef.detectChanges();
        }, 1000);
      });

    this.cartPush = this.events.subscribe("checkoutDone").subscribe((data) => {
      setTimeout(() => {
        for (var i = 0; i < this.businessDetailProducts.length; i++) {
          for (
            var j = 0;
            j < this.businessDetailProducts[i].products.length;
            j++
          ) {
            for (
              var k = 0;
              k < this.businessDetailProducts[i].products[j].variant.length;
              k++
            ) {
              this.businessDetailProducts[i].products[j].count = 0;
            }
          }
        }
      }, 2000);
    });
    this.isloginValue = this.events.subscribe("inlogin").subscribe((data) => {
      setTimeout(() => {
        this.islogin = data;
        if (this.islogin === "true") {
          this.updateBusinessDetailProducts1();
        }
        if (this.islogin === "false") {
          for (var i = 0; i < this.businessDetailProducts.length; i++) {
            for (
              var j = 0;
              j < this.businessDetailProducts[i].products.length;
              j++
            ) {
              this.businessDetailProducts[i].products[j].is_wish_list = false;
            }
          }
          for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
            this.searchProductByCategoryByID[i].is_wish_list = false;
          }
        }
      }, 500);
    });
    this.pushReceive = this.events.subscribe("dataChange").subscribe((data) => {
      this.id = data;
      if (data !== "" || data !== null || data !== undefined) {
        this.loadingData = true;
        this.getBusinessSeoData();
      }
    });
    $('[data-toggle="tooltip"]').tooltip();
    $(".bigFooterSection").css("top", "0");
    $(".myMobileFooter").css("top", "0");
    this.setCategoryActiveClass("c2");
    this.dataControlService.setHeaderAddressCrossVisiblity.next(false);

    this.route.params.subscribe((params) => {
      this.cityName = params["cityname"];
      this.id = params["cityname"];
      this.paramBusinessName = params["businessName"];

      this.fbEventsService.fbViewContentBusiness({
        alias: this.paramBusinessName,
      });
      localStorage.setItem("businessDetName", this.paramBusinessName);
    });
    this.dataControlService.getproductDetailOnProductPage.subscribe((data) => {
      if (data !== false) {
        this.getproductDetail(data);
      }
    });
    this.dataControlService.setValuesinBusinessDetailPage.subscribe((data) => {
      if (data !== false) {
        this.businessDetailaddress_line1 = data.business_detail.address_line1;
        this.businessDetailCity = data.business_detail.city;
      }
    });

    this.dataControlService.invokeBusinessProducts.subscribe((data) => {
      // if (localStorage.getItem('business_product_id') !== null || localStorage.getItem('business_product_id') !== '') {
      // let id = JSON.parse(localStorage.getItem('business_product_id'));
      // let value = localStorage.getItem('business_product_value');
      // let search = localStorage.getItem('business_product_search');
      this.getBusinessDetail(this.paramBusinessName, "default", "");
      this.getBusinessDetailProductsCallMain(this.paramBusinessName);
      // }
    });
    this.getStoryListing();
    this.prevWidth = $(window).width();
    this.widthOfWindow = $(window).width();
    window.addEventListener("resize", () => {
      this.prevWidth = this.widthOfWindow;
      this.widthOfWindow = $(window).width();
      if (this.widthOfWindow < 768 && this.prevWidth >= 768) {
        setTimeout(() => {
          if (this.selectedTab == "about") {
            $("#aboutTabMobile").addClass("active");
            $("#categoryTabMobile").removeClass("active");
            $("#productsTabMobile").removeClass("active");
          } else if (this.selectedTab == "products") {
            // setTimeout(() => {
            //   this.setProductSliderOptions();
            // }, 1000);
            $("#aboutTabMobile").removeClass("active");
            $("#categoryTabMobile").removeClass("active");
            $("#productsTabMobile").addClass("active");
          } else if (this.selectedTab == "categories") {
            $("#aboutTabMobile").removeClass("active");
            $("#categoryTabMobile").addClass("active");
            $("#productsTabMobile").removeClass("active");
          }
        }, 100);
      }
      if (this.widthOfWindow >= 768 && this.prevWidth < 768) {
        this.isProductSearchSelect = false;

        setTimeout(() => {
          if (this.selectedTab == "about") {
            $("#aboutTab").addClass("active");
            $("#categoryTab").removeClass("active");
            $("#productsTab").removeClass("active");
          } else if (this.selectedTab == "products") {
            // setTimeout(() => {
            //   this.setProductSliderOptions();
            // }, 1000);
            $("#aboutTab").removeClass("active");
            $("#categoryTab").removeClass("active");
            $("#productsTab").addClass("active");
          } else if (this.selectedTab == "categories") {
            $("#aboutTab").removeClass("active");
            $("#categoryTab").addClass("active");
            $("#productsTab").removeClass("active");
          }
        }, 100);
      }
    });
    this.myDeliveryAddress = localStorage.getItem("myDeliveryAddress");
    localStorage.setItem("isFirstTimeHomeOpen", "false");

    $(".CatImg").hover(
      function () {
        $(this).addClass("fadeIn");
      },
      function () {
        $(this).removeClass("fadeIn");
      }
    );

    var contax = this;

    $(window).scroll(function (e) {
      var $secondSecEl = $(".mySecondSectionHeader");
      var $firstSecBeforeEl = $(".myFirstSectionBeforeScroll");
      var $firstSecAfterEl = $(".myFirstSectionAfterScroll");
      if (
        $(window).scrollTop() + $(window).height() >
        $(document).height() - 500
      ) {
        if (
          contax.conditionToCheck === true &&
          contax.businessCatLoding === false &&
          contax.businessDetailProductSearchString === ""
        ) {
          if (contax.lastPage === false) {
            contax.loaderCondition = true;
            contax.lastPage = true;
            setTimeout(() => {
              contax.loadMoreFunction();
            }, 100);
          }
        }
        if (contax.lastPageOfCategory === false) {
          contax.SeeAllLoader = true;
          contax.lastPageOfCategory = true;
          setTimeout(() => {
            contax.searchProductsByCategoryIdByPage();
          }, 100);
        }
      }

      // if(contax.businessDetailProductSearchString !== '') {

      //   if(contax.selectedTab == "categories" && contax.catList.length <= 7 || contax.selectedTab == "about" || contax.catList.length <= 3){
      //     $firstSecBeforeEl.css({ 'display': 'flex' });
      //     $firstSecAfterEl.css({ 'display': 'none' });
      //     $secondSecEl.css({ 'position': 'relative', 'top': '0px', 'border-top': 'none' });
      //   }
      //   else{
      //     if (contax.searchProductByCategoryByID.length < 9 || contax.searchProductByCategoryByID.length === 0) {
      //       $firstSecBeforeEl.css({ 'display': 'flex' });
      //       $firstSecAfterEl.css({ 'display': 'none' });
      //       $secondSecEl.css({ 'position': 'relative', 'top': '0px', 'border-top': 'none' });
      //     }
      //     else {
      //       if ($(this).scrollTop() > 200) {
      //         $firstSecBeforeEl.css({ 'display': 'none' });
      //         $firstSecAfterEl.css({ 'display': 'flex', 'position': 'fixed', 'top': '94px', 'border': 'none' });
      //         $secondSecEl.css({ 'position': 'fixed', 'top': '176px', 'border-top': '2px solid #ECECEC' });

      //       }
      //       if ($(this).scrollTop() <= 200) {
      //         $firstSecBeforeEl.css({ 'display': 'flex' });
      //         $firstSecAfterEl.css({ 'display': 'none' });
      //         $secondSecEl.css({ 'position': 'relative', 'top': '0px', 'border-top': 'none' });

      //       }
      //     }
      //   }
      // }
      // else if (contax.businessDetailProductSearchString === '') {
      if (contax.catList != undefined) {
        if (
          (contax.selectedTab == "categories" && contax.catList.length <= 7) ||
          contax.selectedTab == "about" ||
          contax.catList.length <= 3
        ) {
          $firstSecBeforeEl.css({ display: "flex" });
          $firstSecAfterEl.css({ display: "none" });
          $secondSecEl.css({
            position: "relative",
            top: "0px",
            "border-top": "none",
          });
        } else {
          if (
            contax.catList.length == 0 ||
            contax.businessDetailProducts.length == 0
          ) {
            $firstSecBeforeEl.css({ display: "flex" });
            $firstSecAfterEl.css({ display: "none" });
            $secondSecEl.css({
              position: "relative",
              top: "0px",
              "border-top": "none",
            });
          } else {
            if ($(this).scrollTop() > 240) {
              $("#myProductSection").css({ "margin-top": "51px" });
              $firstSecAfterEl.css({
                display: "flex",
                position: "fixed",
                top: "94px",
                border: "none",
              });
              $secondSecEl.css({
                position: "fixed",
                top: "175px",
                "border-top": "2px solid #ECECEC",
              });
            }
            if ($(this).scrollTop() <= 242) {
              $("#myProductSection").css({ "margin-top": "0px" });
              $firstSecAfterEl.css({ display: "none" });
              $secondSecEl.css({
                position: "relative",
                top: "0px",
                "border-top": "none",
              });
            }
          }
        }
      } else {
        if ($(this).scrollTop() > 200) {
          $firstSecBeforeEl.css({ display: "none" });
          $firstSecAfterEl.css({
            display: "flex",
            position: "fixed",
            top: "94px",
            border: "none",
          });
          $secondSecEl.css({
            position: "fixed",
            top: "176px",
            "border-top": "2px solid #ECECEC",
          });
        }
        if ($(this).scrollTop() <= 200) {
          $firstSecBeforeEl.css({ display: "flex" });
          $firstSecAfterEl.css({ display: "none" });
          $secondSecEl.css({
            position: "relative",
            top: "0px",
            "border-top": "none",
          });
        }
      }

      // }
    });
    $("#SearchProductsByCategory").on("scroll", function () {
      if (contax.lastPageOfCategory === false) {
        contax.SeeAllLoader = true;
        if (
          $(this).scrollTop() + $(this).innerHeight() >=
          $(this)[0].scrollHeight - 20
        ) {
          contax.lastPageOfCategory = true;
          setTimeout(() => {
            contax.loadMoreFunctionByCategory();
          }, 100);
        }
      }
    });
    $("#scrollId").on("scroll", function () {
      if (
        contax.conditionToCheck === true &&
        contax.businessCatLoding === false &&
        contax.businessDetailProductSearchString === ""
      ) {
        if (contax.lastPage === false) {
          contax.loaderCondition = true;
          if (
            $(this).scrollTop() + $(this).innerHeight() >=
            $(this)[0].scrollHeight - 500
          ) {
            contax.lastPage = true;
            setTimeout(() => {
              contax.loadMoreFunction();
            }, 100);
          }
        }
      }

      if (
        contax.businessDetailProductSearchString !== "" &&
        contax.businessCatLoding === false
      ) {
        if (contax.searchProductByCategoryByIDLastPage === false) {
          contax.loaderCondition = true;
          if (
            $(this).scrollTop() + $(this).innerHeight() >=
            $(this)[0].scrollHeight - 20
          ) {
            contax.searchProductByCategoryByIDLastPage = true;
            setTimeout(() => {
              // contax.loadMoreFunctionByCategoryBySearch();
            }, 100);
          }
        }
      }
    });

    $(".addCartShort").css("-webkit-filter", "blur(0px)");
    $(".addCartShort").css("-moz-filter", "blur(0px)");
    $(".addCartShort").css("-o-filter", "blur(0px)");
    $(".addCartShort").css("-ms-filter", "blur(0px)");
    $(".addCartShort").css("filter", "blur(0px)");

    const context = this;
    $("#addCartPop").on("hidden.bs.modal", function () {
      $(".addCartShort").css("-webkit-filter", "blur(0px)");
      $(".addCartShort").css("-moz-filter", "blur(0px)");
      $(".addCartShort").css("-o-filter", "blur(0px)");
      $(".addCartShort").css("-ms-filter", "blur(0px)");
      $(".addCartShort").css("filter", "blur(0px)");
      context.enableBackground();
    });
    $("#weAreClosed").on("hidden.bs.modal", function () {
      $(".addCartShort").css("-webkit-filter", "blur(0px)");
      $(".addCartShort").css("-moz-filter", "blur(0px)");
      $(".addCartShort").css("-o-filter", "blur(0px)");
      $(".addCartShort").css("-ms-filter", "blur(0px)");
      $(".addCartShort").css("filter", "blur(0px)");
      context.enableBackground();
    });
    $("#onOnleBusinessCart").on("hidden.bs.modal", function () {
      $("#addCartPop").css("-webkit-filter", "blur(0px)");
      $("#addCartPop").css("-moz-filter", "blur(0px)");
      $("#addCartPop").css("-o-filter", "blur(0px)");
      $("#addCartPop").css("-ms-filter", "blur(0px)");
      $("#addCartPop").css("filter", "blur(0px)");
      context.enableBackground();
    });
    $("#onOnleBusinessCart").on("show.bs.modal", function () {
      $("#addCartPop").css("-webkit-filter", "blur(5px)");
      $("#addCartPop").css("-moz-filter", "blur(5px)");
      $("#addCartPop").css("-o-filter", "blur(5px)");
      $("#addCartPop").css("-ms-filter", "blur(5px)");
      $("#addCartPop").css("filter", "blur(5px)");
    });

    $(window).resize(function () {
      // $('.bigFooterSection').css('top', '0');
      // $('.myMobileFooter').css('top', '0');

      $(document).ready(function () {
        const width = $(".businessTile").width();
        const height = width * 0.75;
        $(".businessTile").css("height", height);
      });
      setTimeout(() => {
        $(document).ready(function () {
          const width = $(".businessTile").width();
          const height = width * 0.75;
          $(".sdetail").css("-webkit-box-orient", "vertical");
          $(".slick-track").css("margin-left", 0);
          $(".slick-track").css("margin-right", 0);
          $(".businessTile").css("height", height);
        });
      }, 1000);
    });
    $(".sdetail").css("-webkit-box-orient", "vertical");
    $(window).resize(function () {
      const width = $(".businessTile").width();
      const height = width * 0.75;
      $(".businessTile").css("height", height);
    });
    // window.scrollTo(0, 0);
    $(".navbar").addClass("sticky-header");
    $(".storyBackBtn").css("display", "none");
    $(".ds-logo").css("margin-left", "0px");
    $(".navbar").removeClass("sticky-header");
    $(".navbar").css("background-color", "#fff");
    $(".navbar").css("position", "fixed");
    $(".logInbtn").css("color", "#FFFFFF");
    $(".navbar").css("border-bottom", "1px solid #ebebeb");
    $("#whiteLogo").css("display", "none");
    $("#blackLogo").css("display", "inline-block");
    $(".cartHeadBtn").css("background", this.colorCode);
    $(".cartHeadBtn").css("color", "#ffffff");
    $(".topName").css("color", this.colorCode);
    $(".username").css("color", "#000000");
    $(".business-logo").css("display", "none");
    $(".navbar-header").css("margin-top", "0px");

    window.addEventListener("resize", () => {
      this.widthOfWindow = $(window).width();

      $(".navbar").css("background-color", "#fff");
      $(".navbar").css("position", "fixed");
      $(".logInbtn").css("color", "#FFFFFF");
      $(".navbar").css("border-bottom", "1px solid #ebebeb");
      $("#whiteLogo").css("display", "none");
      $("#blackLogo").css("display", "inline-block");
      $(".cartHeadBtn").css("background", this.colorCode);
      $(".cartHeadBtn").css("color", "#ffffff");
      $(".topName").css("color", this.colorCode);
      $(".username").css("color", "#000000");
      $(".business-logo").css("display", "none");
      $(".navbar-header").css("margin-top", "0px");
      $(".storyBackBtn").css("display", "none");
    });

    // var mySearchProductsTop = document.getElementById("mySearchProductsTop");
    // mySearchProductsTop.scrollIntoView();
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
    $(".owl-nav").css("visibility", "hidden");
    $(".owl-prev").addClass("visibility-hidden");
    $(".owl-next").addClass("visibility-hidden");
  }

  intializeProductCarousel(data) {
    $(".owl-nav").css("visibility", "hidden");
  }

  // changeProductCarousel(data){
  //   var position = data.page.size + data.property.value;
  //   if (data.item.count - position === 0 || data.item.count - position === 0.5) {
  //     $('#' + data.currentTarget.id + ' .owl-next').addClass('disabled');
  //     $('#' + data.currentTarget.id + ' .owl-next').addClass('visibility-hidden');
  //   } else {
  //     $('#' + data.currentTarget.id + ' .owl-next').removeClass('disabled');
  //     $('#' + data.currentTarget.id + ' .owl-next').removeClass('visibility-hidden');
  //   }

  //   if (data.item.index > 0) {
  //     $('#' + data.currentTarget.id + ' .owl-prev').removeClass('visibility-hidden');
  //   }
  //   if (data.item.index === 0) {
  //     $('#' + data.currentTarget.id + ' .owl-prev').addClass('visibility-hidden');
  //   }
  // }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    $(".bigFooterSection").css("top", "0");
    $(".myMobileFooter").css("top", "0");
  }
  ngOnDestroy() {
    this.dataControlService.setHeaderAddressCrossVisiblity.next(true);
    this.dataControlService.setDefaultValuesInFilterProductComp.next(false);
    this.dataControlService.isOnBusinessDetailPage.next(false);
    this.dataControlService.filterSearchString.next("");
    this.dataControlService.filterMaxVal.next(undefined);
    this.dataControlService.filterMinVal.next(undefined);
    this.businessDetailProductSearchString = "";
    this.dataControlService.isSignInWishlistClicked.next(false);

    this.pushReceive.unsubscribe();
    this.isloginValue.unsubscribe();
    this.cartPush.unsubscribe();
    this.cartChngeFromCheckout.unsubscribe();

    let subCatSlider = $(".subCategorySlider");
    subCatSlider.trigger("destroy.owl.carousel");
    let productSlider = $(".owlProductsSlider");
    productSlider.trigger("destroy.owl.carousel");

    $(window).unbind("scroll");

    $(".bigFooterSection").css("top", "35rem");
    $(".myMobileFooter").css("top", "15rem");
  }
  businessSeoData: any;

  getBusinessSeoData() {
    this.dataService.getBusinessSeo(this.id).subscribe(
      (data) => {
        this.businessSeoData = data;
        if (this.businessSeoData.seo_title !== "") {
          this.setMetaTags(
            this.businessSeoData.seo_title,
            this.businessSeoData.image,
            this.businessSeoData.seo_description,
            this.businessSeoData.seo_keyword
          );
        }
      },
      (error) => {}
    );
  }
  async cartChngeFromCheckoutDone() {
    const cartData = JSON.parse(localStorage.getItem("cartItems"));
    this.modifierProductList = [];
    let foundModifier = false;
    if (
      this.variantData != undefined &&
      this.variantData != "null" &&
      cartData != undefined
    ) {
      for (let i = 0; i < cartData.length; i++) {
        for (let j = 0; j < this.variantData.length; j++) {
          if (cartData[i].variant_id == this.variantData[j].id) {
            if (cartData[i].hasModifier == true && cartData[i].uniqueId != "") {
              this.modifierProductList.push(cartData[i]);
              foundModifier = true;
            }
          }
        }
      }
    }

    if (foundModifier) {
      return;
    }

    if (cartData !== null) {
      if (
        this.businessCatLoding == false &&
        this.businessDetailProductSearchString == ""
      ) {
        for (var i = 0; i < this.businessDetailProducts.length; i++) {
          for (
            var j = 0;
            j < this.businessDetailProducts[i].products.length;
            j++
          ) {
            this.businessDetailProducts[i].products[j].count = 0;
            for (
              var k = 0;
              k < this.businessDetailProducts[i].products[j].variant.length;
              k++
            ) {
              for (var l = 0; l < cartData.length; l++) {
                if (
                  cartData[l].variant_id ===
                  this.businessDetailProducts[i].products[j].variant[k].id
                ) {
                  this.businessDetailProducts[i].products[j].count +=
                    cartData[l].quantity;
                }
              }
            }
          }
        }
      }
      if (
        this.businessCatLoding == false &&
        this.businessDetailProductSearchString != ""
      ) {
        for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
          this.searchProductByCategoryByID[i].count = 0;
          for (
            var k = 0;
            k < this.searchProductByCategoryByID[i].variant.length;
            k++
          ) {
            for (var l = 0; l < cartData.length; l++) {
              if (
                cartData[l].variant_id ===
                this.searchProductByCategoryByID[i].variant[k].id
              ) {
                this.searchProductByCategoryByID[i].count +=
                  cartData[l].quantity;
              }
            }
          }
        }
      }

      if (this.searchItemCat === true && this.loadingDataCat === false) {
        for (var i = 0; i < this.businessCategoryProducts.length; i++) {
          this.businessCategoryProducts[i].count = 0;
          for (
            var k = 0;
            k < this.businessCategoryProducts[i].variant.length;
            k++
          ) {
            for (var l = 0; l < cartData.length; l++) {
              if (
                cartData[l].variant_id ===
                this.businessCategoryProducts[i].variant[k].id
              ) {
                this.businessCategoryProducts[i].count += cartData[l].quantity;
              }
            }
          }
        }
      }

      if (this.searchItemCat === false && this.loadingDataCat === false) {
        if (this.businessCategoryProducts !== undefined) {
          for (var i = 0; i < this.businessCategoryProducts.length; i++) {
            this.businessCategoryProducts[i].count = 0;
            for (
              var k = 0;
              k < this.businessCategoryProducts[i].variant.length;
              k++
            ) {
              for (var l = 0; l < cartData.length; l++) {
                if (
                  cartData[l].variant_id ===
                  this.businessCategoryProducts[i].variant[k].id
                ) {
                  this.businessCategoryProducts[i].count +=
                    cartData[l].quantity;
                }
              }
            }
          }
        }
      }
    }

    // if ($('#businessAll').is(':visible')) {

    this.cartFinal = [];
    this.cartDataFromStorage = JSON.parse(localStorage.getItem("cartItems"));

    if (this.variantData !== undefined) {
      for (let i = 0; i < this.variantData.length; i++) {
        if (
          this.cartDataFromStorage !== null &&
          this.checkObject(this.cartDataFromStorage, this.variantData[i]) ===
            true
        ) {
          for (let j = 0; j < this.cartDataFromStorage.length; j++) {
            if (
              this.businessId === this.cartDataFromStorage[j].business_id &&
              this.cartPopData.id === this.cartDataFromStorage[j].product_id &&
              this.variantData[i].id === this.cartDataFromStorage[j].variant_id
            ) {
              window.localStorage.setItem("businessName", this.businessName);
              this.cartFinal.push({
                business_image: this.business_image,
                business_name: this.businessName,
                business_id: this.businessId,
                business_delivery_charges: Math.round(
                  this.business_delivery_charges
                ),
                business_platform_fee: Math.round(this.business_platform_fee),
                business_total_charges: Math.round(this.business_total_charges),
                business_tax: this.taxOfBusiness,
                product_id: this.cartPopData.id,
                product_name: this.variantData[i].product_name,
                product_image: this.variantData[i].image,
                variant_id: this.variantData[i].id,
                variant_name: this.variantData[i].option_name,
                variant_value: this.variantData[i].option_value,
                price: Math.round(this.variantData[i].price),
                compare_price: Math.round(this.variantData[i].compare_price),
                sale_price: Math.round(this.variantData[i].sale_price),
                is_on_sale: this.variantData[i].is_on_sale,
                quantity: this.cartDataFromStorage[j].quantity,
                addCartBtnShow: false,
                outlet_address: this.outletAddress,
                hasModifier: this.variantData[i].has_modifier,
                uniqueId: "",
                modifiers: [],
                addons: [],
                noOption: [],
                card_tax_price: this.variantData[i].card_tax_price,
                cash_tax_price: this.variantData[i].cash_tax_price,
                card_tax_percentage: this.variantData[i].card_tax_percentage,
                cash_tax_percentage: this.variantData[i].cash_tax_percentage,
              });
            }
          }
        } else {
          this.cartFinal.push({
            business_image: this.business_image,
            business_name: this.businessName,
            business_id: this.businessId,
            business_delivery_charges: Math.round(
              this.business_delivery_charges
            ),
            business_platform_fee: Math.round(this.business_platform_fee),
            business_total_charges: Math.round(this.business_total_charges),
            business_tax: this.taxOfBusiness,
            product_id: this.cartPopData.id,
            product_name: this.variantData[i].product_name,
            product_image: this.variantData[i].image,
            variant_id: this.variantData[i].id,
            variant_name: this.variantData[i].option_name,
            variant_value: this.variantData[i].option_value,
            price: Math.round(this.variantData[i].price),
            compare_price: Math.round(this.variantData[i].compare_price),
            sale_price: Math.round(this.variantData[i].sale_price),
            is_on_sale: this.variantData[i].is_on_sale,
            quantity: 1,
            addCartBtnShow: true,
            outlet_address: this.outletAddress,
            hasModifier: this.variantData[i].has_modifier,
            uniqueId: "",
            modifiers: [],
            addons: [],
            noOption: [],
            card_tax_price: this.variantData[i].card_tax_price,
            cash_tax_price: this.variantData[i].cash_tax_price,
            card_tax_percentage: this.variantData[i].card_tax_percentage,
            cash_tax_percentage: this.variantData[i].cash_tax_percentage,
          });
        }
      }
    }
  }

  loadMoreFunctionByCategory() {
    this.getBusinessCategoryProductsByPage();
  }
  getActiveTab(val) {
    if (val == this.activeTab) {
      return "active";
    } else {
      return "";
    }
  }
  setCategoryActiveClass(elem) {
    this.isC1Active = false;
    this.isC2Active = false;
    this.isC3Active = false;

    switch (elem) {
      case "c1": {
        this.isC1Active = true;
        break;
      }
      case "c2": {
        this.isC2Active = true;
        break;
      }
      case "c3": {
        this.isC3Active = true;
        break;
      }
    }
  }
  expandPrefClicked() {
    // open filter dropdown
    this.dataControlService.expandPrefClicked.next(true);
  }

  openOrCloseTooltip: any = "";
  businessCategoryId: any;
  businessCategoryName: any;
  businessCategoryNameDisplay: any = "Stores";

  getBusinessDetail(id, value, search) {
    // this.disableBackground();
    this.loaderCondition = false;
    this.SeeAllLoader = false;
    this.businessDetailProductSearchString = search;
    $(".ProductTab").removeClass("active");
    $(".AboutTab").removeClass("active");
    $(".CategoriesTab").removeClass("active");
    $("#products").removeClass("active");
    $("#about").removeClass("active");
    $("#categories").removeClass("active");

    this.businessStatus = "";
    this.openOrCloseIn = "";
    this.businessRaitingArray = new Array();
    this.businessId = id;
    localStorage.setItem("businessId", this.businessId);
    // this.nao.go(30);

    var todayDateVal = this.todayDateVal;
    if (this.todayDateVal === 0) {
      todayDateVal = 7;
    }
    let selectedLat = "";
    let selectedLong = "";
    const profileData = JSON.parse(localStorage.getItem("profileAddress"));
    if (profileData != null) {
      if (profileData.latitude != undefined) {
        selectedLat = profileData.latitude;
      }
      if (profileData.longitude != undefined) {
        selectedLong = profileData.longitude;
      }
    }

    this.dataService
      .businessDetailWithServiceArea(
        id,
        this.id,
        todayDateVal,
        selectedLat,
        selectedLong
      )
      .subscribe(
        (data) => {
          // this.nao.go(100);

          this.businessDetailData = data;
          this.events.publish("businessDetailData", this.businessDetailData);
          console.log("businessDetailData");
          localStorage.setItem(
            "businessDetailData",
            JSON.stringify(this.businessDetailData)
          );

          if (
            this.businessDetailData.rest_error_code === 4 ||
            this.businessDetailData.rest_error_code === 1
          ) {
            this.router.navigate(["/notfound"]);
            return;
          }
          if (this.businessDetailData.success == false) {
            if (this.businessDetailData.message == "Business not available") {
              this.router.navigate(["/notfound"]);
              return;
            }
          }
          if (
            this.businessDetailData.business_detail.show_cart_on_web == false
          ) {
            console.log(
              this.businessDetailData.business_detail.show_cart_on_web
            );
            this.cartUnavailHead = this.businessDetailData.business_detail.cart_unavail_head;
            this.cartUnavailText = this.businessDetailData.business_detail.cart_unavail_text;
            $("#cartAvailablePopUp").modal("show");
          }
          this.businessDetailaddress_line1 = this.businessDetailData.business_detail.address_line1;
          this.hasCustomOrder = this.businessDetailData.business_detail.has_custom_order_form;

          this.businessCategoryId = this.businessDetailData.business_detail.business_category_id;
          this.businessCategoryName = this.businessDetailData.business_detail.business_category_name;
          // localStorage.setItem("businessId", this.businessDetailData.business_detail.city_id);
          // localStorage.setItem('businessEmail', this.businessDetailData.business_detail.manager_email);
          // localStorage.setItem('businessCode', this.businessDetailData.business_detail.country_code);
          // localStorage.setItem('businessPhone', this.businessDetailData.business_detail.phone_number);
          // localStorage.setItem('businessID', this.businessDetailData.business_detail.id);
          console.log("search business products view");

          if (
            this.businessDetailData.business_detail
              .business_category_store_display_name &&
            this.businessDetailData.business_detail
              .business_category_store_display_name != ""
          ) {
            this.businessCategoryNameDisplay = this.businessDetailData.business_detail.business_category_store_display_name;
          }

          this.businessDetailCity = this.businessDetailData.business_detail.city;
          if (
            this.businessDetailData.rest_error_code === 4 ||
            this.businessDetailData.rest_error_code === 1
          ) {
            this.router.navigate(["/notfound"]);
            return;
          }
          // this.disableBackground();
          this.businessDetalSuccess = this.businessDetailData.success;
          this.businessDetailData = this.businessDetailData.business_detail;
          this.businessRaiting = this.businessDetailData.rating;

          this.setMetaTags(
            this.businessDetailData.seo_title,
            this.businessDetailData.square_logo_image,
            this.businessDetailData.seo_description,
            this.businessDetailData.seo_keyword
          );
          // this.businessRaiting = Math.round(this.businessRaiting);
          for (let z = 0; z < this.businessRaiting; z++) {
            this.businessRaitingArray.push({
              value: "true",
            });
          }
          let businessRaitingArraylen = this.businessRaitingArray.length;
          for (let x = 5; x > businessRaitingArraylen; x--) {
            this.businessRaitingArray.push({
              value: "false",
            });
          }

          // Time Work Start
          if (this.businessDetailData.full_day === true) {
            this.businessStatus = "Open";
            this.openOrCloseIn = "";
            this.finalDisplayTime = "";
          } else {
            var cDate = new Date().getDate();
            var cYear = new Date().getFullYear();
            var cMonth = new Date().getUTCMonth() + 1;
            var openTime = this.businessDetailData.open_time;
            var tOpen =
              cYear + "/" + cMonth + "/" + cDate + " " + openTime + " " + "UTC";
            this.dateObjOpen = new Date(tOpen);
            var closeTime = this.businessDetailData.close_time;
            var tClose =
              cYear +
              "/" +
              cMonth +
              "/" +
              cDate +
              " " +
              closeTime +
              " " +
              "UTC";
            this.dateObjClose = new Date(tClose);
            this.currentSystemTime = new Date().getTime();
            var convertedTimeWithZone = new Date(this.dateObjOpen).getTime();
            var convertedTimeWithZone2 = new Date(this.dateObjClose).getTime();
            // var oneHourBeforeOpening = new Date(this.dateObjOpen).getTime();
            var oneHourBeforeOpening =
              convertedTimeWithZone - 1 * 60 * 60 * 1000;
            if (convertedTimeWithZone2 < convertedTimeWithZone) {
              convertedTimeWithZone2 = convertedTimeWithZone2 + 86400000;
            }
            if (convertedTimeWithZone2 === convertedTimeWithZone) {
              convertedTimeWithZone2 =
                convertedTimeWithZone2 + 86400000 + 86400000;
            }
            if (
              this.currentSystemTime >= oneHourBeforeOpening &&
              this.currentSystemTime < convertedTimeWithZone
            ) {
              this.businessStatus = "Opening soon";
              this.openOrCloseIn = "Will open at";
              this.finalDisplayTime = this.dateObjOpen;
            } else if (
              this.currentSystemTime >= convertedTimeWithZone &&
              this.currentSystemTime <= convertedTimeWithZone2
            ) {
              this.businessStatus = "Open";
              this.openOrCloseIn = "Will close at";
              this.finalDisplayTime = this.dateObjClose;
            } else {
              this.businessStatus = "Closed";
              this.openOrCloseIn = "Will reopen at";
              var currentDate = new Date();
              var todayDateIndex = this.todayDateVal;
              if (
                this.businessDetailData.time_list[todayDateIndex].fullday ===
                true
              ) {
                this.finalDisplayTime = null;
              }
              if (
                this.businessDetailData.time_list[todayDateIndex].fullday ===
                false
              ) {
                var cDate1 = new Date().getDate();
                var cYear1 = new Date().getFullYear();
                var cMonth1 = new Date().getUTCMonth() + 1;
                var openTime1 = this.businessDetailData.time_list[
                  todayDateIndex
                ].open;
                var tOpen1 =
                  cYear1 +
                  "/" +
                  cMonth1 +
                  "/" +
                  cDate1 +
                  " " +
                  openTime1 +
                  " " +
                  "UTC";
                this.dateObjOpen = new Date(tOpen1);
                this.finalDisplayTime = this.dateObjOpen;
              }
            }
          }
          this.openOrCloseTooltip = "";

          if (
            this.businessStatus == "Open" ||
            this.businessStatus == "Closed"
          ) {
            this.openOrCloseTooltip = this.businessStatus;
          } else if (this.businessStatus == "Opening soon") {
            this.openOrCloseTooltip =
              this.businessStatus + " - " + this.openOrCloseIn + " ";
          }
          // Time Work End
          this.businessName = this.businessDetailData.name;
          this.business_image = this.businessDetailData.square_image;
          this.business_delivery_charges = this.businessDetailData.delivery_charges;
          this.business_platform_fee = this.businessDetailData.platform_fee;
          this.business_total_charges = this.businessDetailData.total_charges;
          this.taxOfBusiness = this.businessDetailData.tax_percentage;
          this.cartDataFromStorage = JSON.parse(
            localStorage.getItem("cartItems")
          );
          this.payment_through_card = this.businessDetailData.payment_through_card;
          this.payment_through_cash = this.businessDetailData.payment_through_cash;
          this.payment_over_due = this.businessDetailData.payment_over_due;
          this.fullDayStatus = this.businessDetailData.full_day;
          this.timeObj = this.businessDetailData.time_list;
          if (this.businessDetalSuccess === true) {
            this.detailDataLoad = true;
            this.outletAddress = {
              area: this.businessDetailData.street,
              city: this.businessDetailData.city,
              state: this.businessDetailData.state,
              zip: this.businessDetailData.zip,
              country: this.businessDetailData.area,
              longitude: this.businessDetailData.longitude,
              latitude: this.businessDetailData.latitude,
            };
            for (var j = 0; j < this.timeObj.length; j++) {
              if (this.timeObj[j].fullday === false) {
                var timeData = this.convertTimeFunction(
                  this.timeObj[j].open,
                  this.timeObj[j].close
                );
                this.timeObj[j].open = timeData[0];
                this.timeObj[j].close = timeData[1];
              }
            }
            if (value === "default") {
              this.openNav();
            }
          }
          this.FunToAddId("categories");
        },
        (error) => {
          // this.nao.go(100);
        }
      );
  }

  setMetaTags(title, image, description, keyword) {
    this.meta.updateTag({ property: "og:title", content: title });
    this.meta.updateTag({ property: "og:image", content: image });
    //this.meta.updateTag({ property: 'og:url', content: this.websiteUrl + this.router.url });
    this.meta.updateTag({ property: "description", content: description });
    this.meta.updateTag({ property: "og:description", content: description });
    this.meta.updateTag({ property: "keywords", content: keyword });
    this.meta.updateTag({ property: "og:type", content: "website" });
    this.title.setTitle(title);

    this.meta.updateTag({
      name: "twitter:card",
      content: "summary_large_image",
    });
    this.meta.updateTag({ name: "twitter:title", content: title });
    this.meta.updateTag({ name: "twitter:description", content: description });
    this.meta.updateTag({ name: "twitter:image", content: image });
  }

  hideShow(status) {
    this.scrolledNav = !this.scrolledNav;
  }

  convertTimeFunction(open_time, close_time) {
    // Open Time
    var cDate = new Date().getDate();
    var cYear = new Date().getFullYear();
    var cMonth = new Date().getUTCMonth() + 1;
    var openTime = open_time;
    var tOpen =
      cYear + "/" + cMonth + "/" + cDate + " " + openTime + " " + "UTC";
    var dateObjOpen = new Date(tOpen);
    // Close Time
    var cDateClose = new Date().getDate();
    var cYearClose = new Date().getFullYear();
    var cMonthClose = new Date().getUTCMonth() + 1;
    var closeTime = close_time;
    var tClose =
      cYearClose +
      "/" +
      cMonthClose +
      "/" +
      cDateClose +
      " " +
      closeTime +
      " " +
      "UTC";
    var dateObjClose = new Date(tClose);

    var returnedArray = [];
    returnedArray.push(dateObjOpen);
    returnedArray.push(dateObjClose);
    return returnedArray;
  }
  openNav() {
    this.initSlider();
    this.iniatlizeSlider();
    // $('#businessDetail').modal({
    //   backdrop: 'static',
    //   keyboard: false,
    // }, 'show');

    // this.router.navigate(['/businesses', this.mySelectedCityName, this.businessDetailData.name ]);
    this.detailDataLoad = false;
    setTimeout(() => {
      $("#productsTab").addClass("active");
      $("#productsTabMobile").addClass("active");
    }, 100);
  }

  closeNav() {
    this.businessCategoryProductslength = false;
    this.dataControlService.isSeeAllProducts.next(false);
    this.dataControlService.expandPrefClicked.next(false);
    // $('#catID').css('visibility', 'hidden');
    this.enableBackground();
    this.SeeAllLoader = false;
    this.subCatid = -1;
    this.searchItemCat = false;
    $(".star-icon:before").remove();
    $("head").append(
      "<style>.star-icon:before {color:white !important;}</style>"
    );
  }
  setProductSliderOptions() {
    // var owl = $(".owl-carousel");
    // $('.owl-carousel').trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
    // $('.owl-carousel').find('.owl-stage-outer').children().unwrap();
    // $('.owl-carousel').owlCarousel(this.productSectionOptions);
    // $('.owl-carousel').trigger('refresh.owl.carousel');
    // owl.trigger("destroy.owl.carousel");
    // this.productSectionOptions = {
    //   loop: false,
    //   dots: false,
    //   mouseDrag: false,
    //   touchDrag: true,
    //   navSpeed: 700,
    //   stagePadding: 20,
    //   autoWidth: true,
    //   responsiveClass:true,
    //   navText: ['<img src="../../assets/arrowLeft.png" style="width: 50px">', '<img src="../../assets/arrowRight.png" style="width: 50px">'],
    //   responsive: {
    //     0: {
    //       items: 2
    //     },
    //     560: {
    //       items: 3
    //     },
    //     768: {
    //       items: 4
    //     },
    //   },
    //   nav: true
    // }
  }
  closeNavForSeeAll() {
    // setTimeout(() => {
    //   this.setProductSliderOptions();
    // }, 1000);
    this.dataControlService.isSeeAllProducts.next(false);
    this.businessCategoryProductslength = false;
    this.dataControlService.expandPrefClicked.next(false);
    // $('#catID').css('visibility', 'hidden');
    this.SeeAllLoader = false;
    this.enableBackground();
    this.subCatid = -1;
    this.searchItemCat = false;
    $(".star-icon:before").remove();
    $("head").append(
      "<style>.star-icon:before {color:white !important;}</style>"
    );
    this.dataControlService.filterSearchString.next("");
    this.dataControlService.filterMaxVal.next(undefined);
    this.dataControlService.filterMinVal.next(undefined);
  }
  closeBusinessModal() {
    // $('#catID').css('visibility', 'hidden');
    this.loaderCondition = false;
    this.SeeAllLoader = false;
    this.enableBackground();
    this.PagnationPage = 0;
    // this.businessDetailProductSearchString = '';
    this.businessDetailProductSearchString = "";
    this.subCatid = -1;
  }

  closeBusinessPopupDeyail() {
    this.businessId = -1;
    this.subCatid = -1;
  }
  disableBackground() {
    $("html").css("overflow-y", "hidden");
  }

  enableBackground() {
    $("html").css("overflow-y", "auto");
  }
  openReviewPopup(id) {
    this.reviewBusinessId = id;
    this.dataService.getReviewList(this.reviewBusinessId).subscribe((data) => {
      this.ReviewData = data;
      this.reviewLastPage = this.ReviewData.last_page;
      this.ReviewData = this.ReviewData.items;
      $("#reviewDetailPopup").modal("show");
      this.disableBackground();
      $(".star-icon:before").remove();
      $("head").append(
        "<style>.star-icon:before {color:#d77229 !important;}</style>"
      );

      this.reviewLastPageCount = 0;
    });
  }
  ReviewDataByPage: any;

  openReviewPopupBypage() {
    this.reviewLastPageCount = this.reviewLastPageCount + 1;
    this.dataService
      .getReviewListPage(this.reviewBusinessId, this.reviewLastPageCount)
      .subscribe((data) => {
        this.ReviewDataByPage = data;
        this.reviewLastPage = this.ReviewDataByPage.last_page;
        this.ReviewDataByPage = this.ReviewDataByPage.items;
        for (var i = 0; i < this.ReviewDataByPage.length; i++) {
          this.ReviewData.push(this.ReviewDataByPage[i]);
        }
      });
  }
  openCustomOrderOpoup() {
    this.customOrderText = "<ul><li></li></ul>";
    this.addCustomOrderBtn = false;
    $("#customOrderPopup").modal("show");
    $(".dz-default").css("display", "block");
    setTimeout(() => {
      $(".dz-preview").remove();
    }, 500);
    const Dropzone = require("dropzone");
    const context = this;
    try {
      new Dropzone("#my-awesome-dropzone", {
        maxFiles: 10,
        addRemoveLinks: true,
        dictDefaultMessage: "Upload Images",
        autoProcessQueue: false,
        paramName: "image",
        acceptedMimeTypes: "image/jpeg, image/jpg, image/png",
        progress: false,
        init: function () {
          context.dropzone = this;

          this.on("addedfile", function (file) {
            if (!file.type.match(/image.*/)) {
              // This is not an image, so Dropzone doesn't create a thumbnail.
              // Set a default thumbnail:
              // this.emit('thumbnail', file, '../app/assets/images/interface.png');
              // You could of course generate another image yourself here,
              // and set it as a data url.
            }
            context.customProductImages.push(file);
          });

          this.on("error", function (file, response) {
            // $(file.previewElement).find('.dz-error-message').text(response.file_name);
            // context.error_count++;
          });

          this.on("queuecomplete", function () {
            // $('#myloader').removeClass('cssload-container');
            // $('#loader_container').removeClass('floatingBarsG');
            // $('.blockG').addClass('myopc');
            // if(context.error_count>0){
            //     toastr.error('Error uploading images.');
            // }
            // else{
            //      toastr.success('Property added successfully.');
            //      context.navigate();
            // }
          });

          this.on("removedfile", function (file) {
            for (var i = 0; i < context.customProductImages.length; i++) {
              if (context.customProductImages[i].name === file.name) {
                context.customProductImages.splice(i, 1);
              }
            }
          });

          this.on("processing", function () {
            this.options.autoProcessQueue = false;
          });

          context.dropzone = this;
        },
      });
    } catch (err) {}
  }
  selectedTab: any = "products";
  FunToAddId(val) {
    this.activeTab = val;
    // var mytabPane = document.getElementById(val);
    // mytabPane.scrollIntoView();
    window.scrollTo(0, 0);

    this.initSlider();
    this.iniatlizeSlider();
    if (val === "products") {
      this.conditionToCheck = true;
      this.showSearchBtnOnMobile = true;
      this.selectedTab = "products";

      setTimeout(() => {
        // setTimeout(() => {
        //   this.setProductSliderOptions();
        // }, 1000);
        $("#aboutTab").removeClass("active");
        $("#categoryTab").removeClass("active");
        $("#productsTab").addClass("active");

        $("#aboutTabMobile").removeClass("active");
        $("#categoryTabMobile").removeClass("active");
        $("#productsTabMobile").addClass("active");
      }, 100);
    }
    if (val === "about") {
      this.conditionToCheck = false;
      this.showSearchBtnOnMobile = false;
      this.selectedTab = "about";
      setTimeout(() => {
        $("#aboutTab").addClass("active");
        $("#categoryTab").removeClass("active");
        $("#productsTab").removeClass("active");

        $("#aboutTabMobile").addClass("active");
        $("#categoryTabMobile").removeClass("active");
        $("#productsTabMobile").removeClass("active");
      }, 100);
    }
    if (val === "categories") {
      this.conditionToCheck = false;
      this.showSearchBtnOnMobile = false;
      this.selectedTab = "categories";
      setTimeout(() => {
        $("#aboutTab").removeClass("active");
        $("#productsTab").removeClass("active");
        $("#aboutTabMobile").removeClass("active");
        $("#productsTabMobile").removeClass("active");

        $("#categoryTab").addClass("active");
        $("#categoryTabMobile").addClass("active");
        $("#categories").addClass("active");
        $(".CategoriesTab").addClass("active");
        $("#categories").addClass("in");
      }, 100);
    }
  }
  showSearchBtnOnMobile = true;
  initSlider() {
    setTimeout(() => {
      $(document).ready(function () {
        const width = $(".businessTile").width();
        const height = width * 0.75;
        $(".sdetail").css("-webkit-box-orient", "vertical");
        $(".slick-track").css("margin-left", 0);
        $(".slick-track").css("margin-right", 0);
        $(".businessTile").css("height", height);
      });
    }, 1000);
  }
  iniatlizeSlider() {
    setTimeout(() => {
      var owl = $(".owlProductsSlider");
      owl.owlCarousel({
        loop: false,
        dots: false,
        navSpeed: 700,
        navText: [
          '<img src="../../assets/arrowLeftWhite.png" style="width: 50px">',
          '<img src="../../assets/arrowRightWhite.png" style="width: 50px">',
        ],
        responsive: {
          0: {
            items: 2,
            stagePadding: 20,
          },
          330: {
            items: 2,
            stagePadding: 30,
          },
          560: {
            items: 3,
            stagePadding: 50,
          },
          768: {
            items: 4,
            stagePadding: 60,
          },
          900: {
            items: 4,
            stagePadding: 80,
          },
        },
        nav: true,
      });
      $("#catID").css("visibility", "visible");
      owl.on("changed.owl.carousel", function (data) {
        var position = data.page.size + data.property.value;
        if (data.item.count - position === 0) {
          $("#" + data.currentTarget.id + " .owl-next").addClass("disabled");
          $("#" + data.currentTarget.id + " .owl-next").addClass(
            "visibility-hidden"
          );
        } else {
          $("#" + data.currentTarget.id + " .owl-next").removeClass("disabled");
          $("#" + data.currentTarget.id + " .owl-next").removeClass(
            "visibility-hidden"
          );
        }

        if (data.item.index > 0) {
          $("#" + data.currentTarget.id + " .owl-prev").removeClass(
            "visibility-hidden"
          );
        }
        if (data.item.index === 0) {
          $("#" + data.currentTarget.id + " .owl-prev").addClass(
            "visibility-hidden"
          );
        }
      });
    }, 100);
    setTimeout(() => {
      // $('.owl-nav').css('visibility', 'hidden');
      // $('.owl-prev').addClass('visibility-hidden');
    }, 100);
  }

  clearTimeoutProductWhenEmpty: any;
  checkSave: any;
  // isFilterSearchHaveValue:any = false;
  activeProductTab() {
    $('#productsTab a[href="#products"]').tab("show");
  }

  searchBusinessDetlProductsCall(val) {
    this.businessCatLoding = true;
    this.businessProductData = false;
    this.serchstring1 = val.search;
    this.businessDetailProductSearchString = val.search;
    this.viewAllSearchResults = val;
    this.checkSave = val;
    this.isFilterCloseClicked = false;
    this.inputSearch = false;

    if (this.businessDetailProductSearchString === "") {
      this.businessCatLoding = true;
      this.searchProductByCategoryByID = [];
      this.loaderCondition = false;
      if (this.loadPreviousProductsData === undefined) {
        this.businessProductData = true;
        this.getBusinessDetailProducts(this.id);
      } else {
        clearTimeout(this.clearTimeoutProductWhenEmpty);
        this.showBusinessProductsliderLoading = true;
        this.businessProductData = true;
        this.clearTimeoutProductWhenEmpty = setTimeout(() => {
          this.businessCatLoding = true;
          this.businessDetailProducts = this.loadPreviousProductsData;
          this.PagnationPage = this.loadPreviousProductsDataCount;
          this.lastPage = this.loadPreviousProductsDataLastPage;
          this.searchProductByCategoryByIDByPage = [];
          this.iniatlizeSlider();
          this.businessCatLoding = false;
        }, 1000);
      }
      const cartData = JSON.parse(localStorage.getItem("cartItems"));
      if (cartData !== null) {
        for (var i = 0; i < this.businessDetailProducts.length; i++) {
          for (
            var j = 0;
            j < this.businessDetailProducts[i].products.length;
            j++
          ) {
            this.businessDetailProducts[i].products[j].count = 0;
            for (
              var k = 0;
              k < this.businessDetailProducts[i].products[j].variant.length;
              k++
            ) {
              for (var l = 0; l < cartData.length; l++) {
                if (
                  cartData[l].variant_id ===
                  this.businessDetailProducts[i].products[j].variant[k].id
                ) {
                  this.businessDetailProducts[i].products[j].count +=
                    cartData[l].quantity;
                }
              }
            }
          }
        }
      }
      this.businessCatLoding = false;
    } else {
      this.businessCatLoding = true;
      this.PaginationPageForSearch = 0;

      this.searchProductsByCategoryId();
    }
  }
  cat_ID: any;
  isBusinessDetailDataLoad: boolean = false;
  getBusinessDetailProducts(id) {
    this.businessProductData = true;
    this.loaderCondition = false;
    this.cat_ID = id;
    this.PagnationPage = 0;
    if (
      window.localStorage.getItem("token") === "" ||
      window.localStorage.getItem("token") === undefined
    ) {
      var token = "";
    } else {
      token = window.localStorage.getItem("token");
    }
    this.businessCatLoding = true;
    this.businessDetailProducts = [];
    this.businessProductData = true;
    this.isBusinessDetailDataLoad = false;
    // $('.sliderList').slick('unslick');
    const cartData = JSON.parse(localStorage.getItem("cartItems"));
    // this.nao.go(30);

    if (
      this.checkappToken !== undefined &&
      this.checkappToken !== null &&
      this.checkappToken !== ""
    ) {
      setTimeout(() => {
        var elmnt = document.getElementById("aboutScroll");
        elmnt.scrollIntoView();
      }, 3000);
    }
    this.dataService
      .businessSearchProducts(
        this.businessId,
        "",
        this.limit,
        this.PagnationPage,
        token
      )
      .subscribe(
        (data) => {
          this.businessDetailProducts = data;
          this.lastPage = this.businessDetailProducts.last_page;
          this.detailProductSuccess = this.businessDetailProducts.success;
          this.businessDetailProducts = this.businessDetailProducts.items;
          this.loaderCondition = false;
          this.businessCatLoding = false;
          if (cartData !== null) {
            for (var i = 0; i < this.businessDetailProducts.length; i++) {
              for (
                var j = 0;
                j < this.businessDetailProducts[i].products.length;
                j++
              ) {
                for (
                  var k = 0;
                  k < this.businessDetailProducts[i].products[j].variant.length;
                  k++
                ) {
                  for (var l = 0; l < cartData.length; l++) {
                    if (
                      cartData[l].variant_id ===
                      this.businessDetailProducts[i].products[j].variant[k].id
                    ) {
                      this.businessDetailProducts[i].products[j].count +=
                        cartData[l].quantity;
                    }
                  }
                }
              }
            }
          }
          this.iniatlizeSlider();
          if (this.businessDetailProductSearchString === "") {
            this.loadPreviousProductsData = this.businessDetailProducts;
            this.loadPreviousProductsDataCount = this.PagnationPage;
            this.loadPreviousProductsDataLastPage = this.lastPage;
          }
          this.loaderCondition = false;
          this.isBusinessDetailDataLoad = true;
        },
        (error) => {
          // this.nao.go(100);
          // clearInterval(this.clearTimeoutProduct);
        }
      );
  }
  showProductSlider: boolean = true;
  limitForSearch = 25;
  PaginationPageForSearch = 0;
  setHideProductSlider() {
    this.showProductSlider = false;
    setTimeout(() => {
      this.showProductSlider = true;
    }, 3000);
  }
  setShowProductSlider() {
    this.showProductSlider = true;
  }
  searchProductsByCategoryId() {
    this.loaderCondition = false;
    // clearTimeout(this.clearTimeoutProduct);
    clearInterval(this.clearTimeout1);

    // this.clearTimeoutProduct = setTimeout(() => {
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === undefined
    ) {
      var token = "";
    } else {
      token = localStorage.getItem("token");
    }
    const cartData = JSON.parse(localStorage.getItem("cartItems"));
    this.inputSearch = false;

    let maxVal;
    let minVal;
    if (this.viewAllSearchResults !== undefined) {
      if (this.viewAllSearchResults.price_range.min_price == "any") {
        this.viewAllSearchResults.price_range.min_price = -1;
      } else {
        this.viewAllSearchResults.price_range.min_price = this.viewAllSearchResults.price_range.min_price;
      }

      if (this.viewAllSearchResults.price_range.max_price == "any") {
        this.viewAllSearchResults.price_range.max_price = -1;
      } else {
        this.viewAllSearchResults.price_range.max_price = this.viewAllSearchResults.price_range.max_price;
      }

      maxVal = this.viewAllSearchResults.price_range.max_price;
      minVal = this.viewAllSearchResults.price_range.min_price;
    } else {
      maxVal = -1;
      minVal = -1;
    }
    this.PaginationPageForSearch = 0;

    this.dataService
      .searchProductsByCategory(
        this.businessId,
        this.limitForSearch,
        this.PaginationPageForSearch,
        this.businessDetailProductSearchString,
        token,
        maxVal,
        minVal
      )
      .subscribe(
        (data) => {
          this.searchProductByCategoryByID = data;
          this.searchProductByCategoryByIDLastPage = this.searchProductByCategoryByID.last_page;
          this.lastPageOfCategory = this.searchProductByCategoryByID.last_page;
          this.searchProductByCategoryByID = this.searchProductByCategoryByID.items;

          if (cartData !== null) {
            for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
              this.searchProductByCategoryByID[i].count = 0;
              for (
                var k = 0;
                k < this.searchProductByCategoryByID[i].variant.length;
                k++
              ) {
                for (var l = 0; l < cartData.length; l++) {
                  if (
                    cartData[l].variant_id ===
                    this.searchProductByCategoryByID[i].variant[k].id
                  ) {
                    this.searchProductByCategoryByID[i].count +=
                      cartData[l].quantity;
                  }
                }
              }
            }
          }

          this.inputSearch = true;
          this.businessCatLoding = false;
          this.dataControlService.filterSearchString.next(
            this.businessDetailProductSearchString
          );
        },
        (error) => {
          // this.nao.go(100);
        }
      );
    // }, 1000);
  }

  clearSearchBusinessDetailProductsCall() {
    clearInterval(this.clearTimeout1);

    this.businessCatLoding = true;
    this.loaderCondition = false;
    this.inputSearch = false;
    this.businessProductData = false;
    this.searchProductByCategoryByID = [];
    this.businessDetailProductSearchString = "";
    if (this.loadPreviousProductsData === undefined) {
      this.businessProductData = true;
      this.getBusinessDetailProducts(this.id);
    } else {
      clearTimeout(this.clearTimeoutProductWhenEmpty);
      this.businessCatLoding = true;
      this.showBusinessProductsliderLoading = true;
      this.businessProductData = true;
      this.clearTimeoutProductWhenEmpty = setTimeout(() => {
        this.businessDetailProducts = this.loadPreviousProductsData;
        this.PagnationPage = this.loadPreviousProductsDataCount;
        this.lastPage = this.loadPreviousProductsDataLastPage;
        this.searchProductByCategoryByIDByPage = [];
        this.iniatlizeSlider();

        const cartData = JSON.parse(localStorage.getItem("cartItems"));
        if (cartData !== null) {
          for (var i = 0; i < this.businessDetailProducts.length; i++) {
            for (
              var j = 0;
              j < this.businessDetailProducts[i].products.length;
              j++
            ) {
              this.businessDetailProducts[i].products[j].count = 0;
              for (
                var k = 0;
                k < this.businessDetailProducts[i].products[j].variant.length;
                k++
              ) {
                for (var l = 0; l < cartData.length; l++) {
                  if (
                    cartData[l].variant_id ===
                    this.businessDetailProducts[i].products[j].variant[k].id
                  ) {
                    this.businessDetailProducts[i].products[j].count +=
                      cartData[l].quantity;
                  }
                }
              }
            }
          }
        }
        this.businessCatLoding = false;
      }, 1000);
    }
  }
  viewAllSearchResultsOnSeeAll: any;
  getSeeMprePopup(cat_id, catname) {
    this.dataControlService.isSeeAllProducts.next(true);
    this.disableBackground();
    this.subCatLength = 0;
    this.isSubCategoriesLoaded = false;
    this.searchStringCat = "";
    this.cat_id = cat_id;
    this.cat_name = catname;
    $("#SeeMorePopup").modal("show");
    this.loadingDataCat = true;
    this.dataControlService.expandPrefClicked.next(false);
    this.dataControlService.setDefaultValuesInFilterProductComp.next(true);
    this.dataControlService.filterSearchString.next("");
    this.dataControlService.filterMaxVal.next(undefined);
    this.dataControlService.filterMinVal.next(undefined);

    this.filteredDataViewOnSeeAll = this.DefaultfilteredData;
    this.searchStringCat = this.DefaultfilteredDataForSeeAll.search;
    this.viewAllSearchResults = this.DefaultfilteredDataForSeeAll;

    let obj = {
      price_range: {
        max_price: "any",
        min_price: "any",
      },
      search: "",
    };

    setTimeout(() => {
      this.subCategoryListing();
    }, 100);
    // reset results on search prodcuts
    this.searchBusinessDetlProductsCall(obj);
  }
  isSubCategoriesLoaded: boolean = false;
  subCategoryListing() {
    this.isSubCategoriesLoaded = false;
    this.subCatData = [];
    this.dataService
      .getSubCategoryList(
        localStorage.getItem("token"),
        this.businessId,
        this.cat_id
      )
      .subscribe(
        (data) => {
          this.subCatData = data;
          this.subCatLength = this.subCatData.length;

          this.iniatlizeSubCatSlider();
          setTimeout(() => {
            $("#subCategorySlider").css("visibility", "visible");
            this.subcatLoadingShimmer = true;
            // this.getBusinessCategoryProducts();
            this.mySearchProductsByCategoryId();
          }, 1000);
        },
        (error) => {}
      );
  }

  owl2: any;

  iniatlizeSubCatSlider() {
    // this.subCatslider = false;
    var context = this;

    setTimeout(() => {
      context.owl2 = undefined;
      $(function () {
        context.owl2 = $(".subCategorySlider");
        context.owl2.trigger("initialized");
        context.owl2.owlCarousel({
          loop: false,
          dots: false,
          navSpeed: 700,
          navText: [
            '<img src="../../assets/arrowLeftWhite.png" style="width: 35px">',
            '<img src="../../assets/arrowRightWhite.png" style="width: 35px">',
          ],
          responsive: {
            0: {
              items: 3,
            },
            420: {
              items: 4,
            },
            560: {
              items: 4,
            },
            950: {
              items: 5,
            },
          },
          nav: true,
        });
        $(".subCategorySlider .owl-prev").addClass("visibility-hidden");
        context.owl2.on("changed.owl.carousel", function (data) {
          var position = data.page.size + data.property.value;
          if (data.item.count - position === 0) {
            $(".subCategorySlider .owl-next").addClass("disabled");
            $(".subCategorySlider .owl-next").addClass("visibility-hidden");
          } else {
            $(".subCategorySlider .owl-next").removeClass("disabled");
            $(".subCategorySlider .owl-next").removeClass("visibility-hidden");
          }

          if (data.item.index > 0) {
            $(".subCategorySlider .owl-prev").removeClass("visibility-hidden");
          }
          if (data.item.index === 0) {
            $(".subCategorySlider .owl-prev").addClass("visibility-hidden");
          }
        });
      });
      this.isSubCategoriesLoaded = true;
    }, 100);
    // setTimeout(() => {
    //   this.subCatslider = false;
    // }, 500);
  }

  mouseEnterOnSlider(index) {
    var length = window.innerWidth;
    var products = this.businessDetailProducts[index].products.length;
    if (length >= 1025 && products <= 4) {
    } else if (length >= 768 && products <= 4) {
    } else if (length >= 550 && products <= 3) {
    } else if (length >= 250 && products <= 2) {
    } else {
      $("#carouselSlider" + index + " .owl-nav").css("visibility", "visible");
    }
  }

  mouseLeaveOnSlider(index) {
    $("#carouselSlider" + index + " .owl-nav").css("visibility", "hidden");
  }
  clearTimeout1: any;
  getProductsViaCategoryLimit = 50;
  businessCategoryProductsBypage: any;

  getBusinessCategoryProductsByPage() {
    clearInterval(this.clearTimeout1);
    // $('.sliderList').slick('unslick');
    this.businessCategoryProductsBypage = [];
    const cartData = JSON.parse(localStorage.getItem("cartItems"));
    if (
      window.localStorage.getItem("token") === "" ||
      window.localStorage.getItem("token") === undefined
    ) {
      var token = "";
    } else {
      token = window.localStorage.getItem("token");
    }
    this.SeeAllLoader = false;
    this.PaginationPageByCategory++;
    this.dataService
      .businessCategorySearchProduct(
        this.businessId,
        this.getProductsViaCategoryLimit,
        this.PaginationPageByCategory,
        this.cat_id,
        this.searchStringCat,
        this.subCatid,
        token,
        -1,
        -1
      )
      .subscribe(
        (data) => {
          this.SeeAllLoader = false;
          this.businessCategoryProductsBypage = data;
          this.lastPageOfCategory = this.businessCategoryProductsBypage.last_page;
          // this.nao.go(100);
          this.loadingDataCat = false;
          this.detailProductSuccess = this.businessCategoryProductsBypage.success;
          this.businessCategoryProductsBypage = this.businessCategoryProductsBypage.items;
          if (cartData !== null) {
            for (var i = 0; i < this.businessCategoryProducts.length; i++) {
              this.businessCategoryProducts[i].count = 0;
              for (
                var k = 0;
                k < this.businessCategoryProducts[i].variant.length;
                k++
              ) {
                for (var l = 0; l < cartData.length; l++) {
                  if (
                    cartData[l].variant_id ===
                    this.businessCategoryProducts[i].variant[k].id
                  ) {
                    this.businessCategoryProducts[i].count +=
                      cartData[l].quantity;
                  }
                }
              }
            }
          }
          for (var k = 0; k < this.businessCategoryProductsBypage.length; k++) {
            this.businessCategoryProducts.push(
              this.businessCategoryProductsBypage[k]
            );
          }
        },
        (error) => {
          this.nao.go(100);
        }
      );
  }

  getBusinessCategoryProducts() {
    this.businessCategoryProductslength = false;
    this.PaginationPageByCategory = 0;
    clearInterval(this.clearTimeout1);
    this.businessCategoryProducts = [];
    const cartData = JSON.parse(localStorage.getItem("cartItems"));
    if (
      window.localStorage.getItem("token") === "" ||
      window.localStorage.getItem("token") === undefined
    ) {
      var token = "";
    } else {
      token = window.localStorage.getItem("token");
    }
    if (this.viewAllSearchResults !== undefined) {
      if (this.viewAllSearchResults.price_range.min_price == "any") {
        this.viewAllSearchResults.price_range.min_price = -1;
      } else {
        this.viewAllSearchResults.price_range.min_price = this.viewAllSearchResults.price_range.min_price;
      }

      if (this.viewAllSearchResults.price_range.max_price == "any") {
        this.viewAllSearchResults.price_range.max_price = -1;
      } else {
        this.viewAllSearchResults.price_range.max_price = this.viewAllSearchResults.price_range.max_price;
      }
      this.dataService
        .businessCategorySearchProduct(
          this.businessId,
          this.getProductsViaCategoryLimit,
          this.PaginationPageByCategory,
          this.cat_id,
          this.searchStringCat,
          this.subCatid,
          token,
          this.viewAllSearchResults.price_range.min_price,
          this.viewAllSearchResults.price_range.max_price
        )
        .subscribe(
          (data) => {
            this.SeeAllLoader = false;
            this.subcatLoadingShimmer = false;
            this.subcatSearchLoadingShimmer = false;
            this.businessCategoryProducts = data;
            this.lastPageOfCategory = this.businessCategoryProducts.last_page;
            this.detailProductSuccess = this.businessCategoryProducts.success;
            this.businessCategoryProducts = this.businessCategoryProducts.items;
            if (this.businessCategoryProducts !== undefined) {
              if (this.businessCategoryProducts.length === 0) {
                this.businessCategoryProductslength = true;
              }
            }
            this.initSlider();
            if (cartData !== null) {
              for (var i = 0; i < this.businessCategoryProducts.length; i++) {
                this.businessCategoryProducts[i].count = 0;
                for (
                  var k = 0;
                  k < this.businessCategoryProducts[i].variant.length;
                  k++
                ) {
                  for (var l = 0; l < cartData.length; l++) {
                    if (
                      cartData[l].variant_id ===
                      this.businessCategoryProducts[i].variant[k].id
                    ) {
                      this.businessCategoryProducts[i].count +=
                        cartData[l].quantity;
                    }
                  }
                }
              }
            }
            this.loadingDataCat = false;
          },
          (error) => {
            // this.nao.go(100);
          }
        );
    } else {
      this.dataService
        .businessCategorySearchProduct(
          this.businessId,
          this.getProductsViaCategoryLimit,
          this.PaginationPageByCategory,
          this.cat_id,
          this.searchStringCat,
          this.subCatid,
          token,
          -1,
          -1
        )
        .subscribe(
          (data) => {
            this.SeeAllLoader = false;
            this.subcatLoadingShimmer = false;
            this.subcatSearchLoadingShimmer = false;
            this.businessCategoryProducts = data;
            this.lastPageOfCategory = this.businessCategoryProducts.last_page;
            this.detailProductSuccess = this.businessCategoryProducts.success;
            this.businessCategoryProducts = this.businessCategoryProducts.items;
            if (this.businessCategoryProducts !== undefined) {
              if (this.businessCategoryProducts.length === 0) {
                this.businessCategoryProductslength = true;
              }
            }
            this.initSlider();
            if (cartData !== null) {
              for (var i = 0; i < this.businessCategoryProducts.length; i++) {
                this.businessCategoryProducts[i].count = 0;
                for (
                  var k = 0;
                  k < this.businessCategoryProducts[i].variant.length;
                  k++
                ) {
                  for (var l = 0; l < cartData.length; l++) {
                    if (
                      cartData[l].variant_id ===
                      this.businessCategoryProducts[i].variant[k].id
                    ) {
                      this.businessCategoryProducts[i].count +=
                        cartData[l].quantity;
                    }
                  }
                }
              }
            }
          },
          (error) => {
            // this.nao.go(100);
          }
        );
    }
  }
  getproductDetail(id) {
    var token;
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === undefined
    ) {
      token = "";
    } else {
      token = localStorage.getItem("token");
    }
    this.nao.go(30);
    this.dataService.productDetail(id, token).subscribe(
      (data) => {
        this.nao.go(100);

        this.productDetailData = data;
        this.productSuccess = this.productDetailData.success;
        this.productDetailData = this.productDetailData.product;
        this.variantData = this.productDetailData.variant;
        this.variantsDataLength = this.productDetailData.variant.length;
        this.setMetaTags(
          this.productDetailData.title,
          this.productDetailData.image[0],
          this.productDetailData.description,
          this.productDetailData.description
        );
        this.productDetailDataImagesLength = this.productDetailData.image.length;
        if (this.productDetailDataImagesLength <= 1) {
          $(".carousel-indicators").removeClass("display-block");
          $(".carousel-indicators").addClass("display-none");
        }
        if (this.productDetailDataImagesLength > 1) {
          $(".carousel-indicators").removeClass("display-none");
          $(".carousel-indicators").addClass("display-block");
        }
        this.productDetailData.description = this.productDetailData.description.replace(
          /(?:\r\n|\r|\n)/g,
          "<br>"
        );
        if (this.productSuccess === true) {
          this.addCartPopOpen(this.productDetailData, 2);
          this.openNavAll();
        }
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }
  cartFinal: any;
  swipe() {
    $(".carousel").on("touchstart", function (event) {
      var xClick = event.originalEvent.touches[0].pageX;
      $(this).one("touchmove", function (event) {
        var xMove = event.originalEvent.touches[0].pageX;
        if (Math.floor(xClick - xMove) > 5) {
          $(this).carousel("next");
        } else if (Math.floor(xClick - xMove) < -5) {
          $(this).carousel("prev");
        }
      });
      $(".carousel").on("touchend", function () {
        $(this).off("touchmove");
      });
    });
  }

  addCartPopOpen(obj, val) {
    this.swipe();
    this.cartFinal = [];
    this.cartDataFromStorage = JSON.parse(localStorage.getItem("cartItems"));
    this.cartPopData = obj;
    console.log(this.cartPopData);
    this.modifierProductList = [];
    this.variantData = this.cartPopData.variant;
    // if (this.cartDataFromStorage === null) {
    //   this.cartDataFromStorage = [];
    // }
    for (let i = 0; i < this.variantData.length; i++) {
      let foundModifier = false;
      if (this.cartDataFromStorage != undefined) {
        for (let l = 0; l < this.cartDataFromStorage.length; l++) {
          if (
            this.cartDataFromStorage[l].variant_id == this.variantData[i].id
          ) {
            if (
              this.cartDataFromStorage[l].hasModifier == true &&
              this.cartDataFromStorage[l].uniqueId != ""
            ) {
              this.modifierProductList.push(this.cartDataFromStorage[l]);
              foundModifier = true;
            }
          }
        }
      }

      if (foundModifier) {
        this.cartFinal.push({
          business_image: this.business_image,
          business_name: this.businessName,
          business_id: this.businessId,
          business_delivery_charges: this.business_delivery_charges,
          business_platform_fee: this.business_platform_fee,
          business_total_charges: this.business_total_charges,
          business_tax: this.taxOfBusiness,
          product_id: this.cartPopData.id,
          product_name: this.variantData[i].product_name,
          product_image: this.variantData[i].image,
          variant_id: this.variantData[i].id,
          variant_name: this.variantData[i].option_name,
          variant_value: this.variantData[i].option_value,
          price: Math.round(this.variantData[i].price),
          compare_price: Math.round(this.variantData[i].compare_price),
          sale_price: this.variantData[i].sale_price,
          is_on_sale: this.variantData[i].is_on_sale,
          quantity: 1,
          addCartBtnShow: true,
          outlet_address: this.outletAddress,
          hasModifier: this.variantData[i].has_modifier,
          uniqueId: "",
          modifiers: [],
          addons: [],
          noOption: [],
          card_tax_price: this.variantData[i].card_tax_price,
          cash_tax_price: this.variantData[i].cash_tax_price,
          card_tax_percentage: this.variantData[i].card_tax_percentage,
          cash_tax_percentage: this.variantData[i].cash_tax_percentage,
        });
        continue;
      }

      if (
        this.cartDataFromStorage !== null &&
        this.checkObject(this.cartDataFromStorage, this.variantData[i]) === true
      ) {
        for (let j = 0; j < this.cartDataFromStorage.length; j++) {
          if (
            this.businessId === this.cartDataFromStorage[j].business_id &&
            this.cartPopData.id === this.cartDataFromStorage[j].product_id &&
            this.variantData[i].id === this.cartDataFromStorage[j].variant_id
          ) {
            localStorage.setItem("businessName", this.businessName);
            this.cartFinal.push({
              business_image: this.business_image,
              business_name: this.businessName,
              business_id: this.businessId,
              business_delivery_charges: this.business_delivery_charges,
              business_platform_fee: this.business_platform_fee,
              business_total_charges: this.business_total_charges,
              business_tax: this.taxOfBusiness,
              product_id: this.cartPopData.id,
              product_name: this.variantData[i].product_name,
              product_image: this.variantData[i].image,
              variant_id: this.variantData[i].id,
              variant_name: this.variantData[i].option_name,
              variant_value: this.variantData[i].option_value,
              price: Math.round(this.variantData[i].price),
              compare_price: Math.round(this.variantData[i].compare_price),
              sale_price: this.variantData[i].sale_price,
              is_on_sale: this.variantData[i].is_on_sale,
              quantity: this.cartDataFromStorage[j].quantity,
              addCartBtnShow: false,
              outlet_address: this.outletAddress,
              hasModifier: this.variantData[i].has_modifier,
              uniqueId: "",
              modifiers: [],
              addons: [],
              noOption: [],
              card_tax_price: this.variantData[i].card_tax_price,
              cash_tax_price: this.variantData[i].cash_tax_price,
              card_tax_percentage: this.variantData[i].card_tax_percentage,
              cash_tax_percentage: this.variantData[i].cash_tax_percentage,
            });
          }
        }
      } else {
        this.cartFinal.push({
          business_image: this.business_image,
          business_name: this.businessName,
          business_id: this.businessId,
          business_delivery_charges: this.business_delivery_charges,
          business_platform_fee: this.business_platform_fee,
          business_total_charges: this.business_total_charges,
          business_tax: this.taxOfBusiness,
          product_id: this.cartPopData.id,
          product_name: this.variantData[i].product_name,
          product_image: this.variantData[i].image,
          variant_id: this.variantData[i].id,
          variant_name: this.variantData[i].option_name,
          variant_value: this.variantData[i].option_value,
          price: Math.round(this.variantData[i].price),
          compare_price: Math.round(this.variantData[i].compare_price),
          sale_price: this.variantData[i].sale_price,
          is_on_sale: this.variantData[i].is_on_sale,
          quantity: 1,
          addCartBtnShow: true,
          outlet_address: this.outletAddress,
          hasModifier: this.variantData[i].has_modifier,
          uniqueId: "",
          modifiers: [],
          addons: [],
          noOption: [],
          card_tax_price: this.variantData[i].card_tax_price,
          cash_tax_price: this.variantData[i].cash_tax_price,
          card_tax_percentage: this.variantData[i].card_tax_percentage,
          cash_tax_percentage: this.variantData[i].cash_tax_percentage,
        });
      }
    }
    if (val === 1) {
      $("#addCartPop").modal("show");
      $("#addCartPop").css("overflow-y", "auto");
      // this.disableBackground();
    } else if (val == 3) {
      this.isSingleOrder == 0
        ? this.addToCart(this.cartFinal[0], 0)
        : $("#addCartPop").modal("show");
          $("#addCartPop").css("overflow-y", "auto");
    } else {
      localStorage.setItem("paymentMethod", this.payment_through_card);
      localStorage.setItem("paymentThroughCard", this.payment_through_card);
      localStorage.setItem("paymentThroughCash", this.payment_through_cash);
      localStorage.setItem("paymentOverDue", this.payment_over_due);
    }
  }
  // Cart Flow
  checkObject(array, obj) {
    var value = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].variant_id === obj.id) {
        value = true;
        break;
      }
    }
    return value;
  }
  openNavAll() {
    $("#businessAll").modal({}, "show");
    this.disableBackground();
  }
  addFadeInAnimation(animation, targetClass) {
    if (this.widthOfWindow > 420) {
      if ($("#" + targetClass).hasClass(animation) === false) {
        $("#" + targetClass).addClass(animation);
      } else {
      }
      setTimeout(() => {
        $("#" + targetClass).removeClass(animation);
      }, 1000);
    }
  }
  removeToWishlist(obj) {
    if ($("#businessAll").is(":visible")) {
      this.productDetailData.is_wish_list = false;
    }
    for (var i = 0; i < this.businessDetailProducts.length; i++) {
      for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
        if (obj.id === this.businessDetailProducts[i].products[j].id) {
          this.businessDetailProducts[i].products[j].is_wish_list = false;
        }
      }
    }

    if ($("#SearchProductsByCategory").is(":visible")) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        if (obj.id === this.businessCategoryProducts[i].id) {
          this.businessCategoryProducts[i].is_wish_list = false;
        }
      }
    }

    if (
      this.businessCatLoding == false &&
      this.businessDetailProductSearchString != ""
    ) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        if (obj.id === this.searchProductByCategoryByID[i].id) {
          this.searchProductByCategoryByID[i].is_wish_list = false;
        }
      }
    }

    this.dataService
      .removeProductInWishList(localStorage.getItem("token"), obj.id)
      .subscribe(
        (data) => {
          this.addWishlistSuccess = data;
          this.addWishlistSuccess = this.addWishlistSuccess.success;
          if (this.addWishlistSuccess === true) {
          } else {
            toastr.error("Something went wrong");
          }
        },
        (error) => {}
      );
  }

  clearBlurbusinessAll() {
    $("#businessAll").css("-webkit-filter", "blur(0px)");
    $("#businessAll").css("-moz-filter", "blur(0px)");
    $("#businessAll").css("-o-filter", "blur(0px)");
    $("#businessAll").css("-ms-filter", "blur(0px)");
    $("#businessAll").css("filter", "blur(0px)");
  }

  addBlurbusinessAll() {
    $("#businessAll").css("-webkit-filter", "blur(3px)");
    $("#businessAll").css("-moz-filter", "blur(3px)");
    $("#businessAll").css("-o-filter", "blur(3px)");
    $("#businessAll").css("-ms-filter", "blur(3px)");
    $("#businessAll").css("filter", "blur(3px)");
  }
  clearBlur() {
    $("#customOrderPopup").css("-webkit-filter", "blur(0px)");
    $("#customOrderPopup").css("-moz-filter", "blur(0px)");
    $("#customOrderPopup").css("-o-filter", "blur(0px)");
    $("#customOrderPopup").css("-ms-filter", "blur(0px)");
    $("#customOrderPopup").css("filter", "blur(0px)");
  }

  addBlur() {
    $("#customOrderPopup").css("-webkit-filter", "blur(3px)");
    $("#customOrderPopup").css("-moz-filter", "blur(3px)");
    $("#customOrderPopup").css("-o-filter", "blur(3px)");
    $("#customOrderPopup").css("-ms-filter", "blur(3px)");
    $("#customOrderPopup").css("filter", "blur(3px)");
  }

  clearBlurbusinessDetail() {
    $("#businessDetail").css("-webkit-filter", "blur(0px)");
    $("#businessDetail").css("-moz-filter", "blur(0px)");
    $("#businessDetail").css("-o-filter", "blur(0px)");
    $("#businessDetail").css("-ms-filter", "blur(0px)");
    $("#businessDetail").css("filter", "blur(0px)");
  }

  addBlurbusinessDetail() {
    $("#businessDetail").css("-webkit-filter", "blur(3px)");
    $("#businessDetail").css("-moz-filter", "blur(3px)");
    $("#businessDetail").css("-o-filter", "blur(3px)");
    $("#businessDetail").css("-ms-filter", "blur(3px)");
    $("#businessDetail").css("filter", "blur(3px)");
  }
  addToWishlist(obj) {
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === undefined ||
      localStorage.getItem("token") === null
    ) {
      this.addBlurbusinessAll();
      this.addBlurbusinessDetail();
      this.dataControlService.isSignInWishlistClicked.next(true);
      this.events.publish("openLoginFunction", "true");
      return;
    }
    this.clearBlurbusinessAll();
    this.clearBlurbusinessDetail();
    if ($("#businessAll").is(":visible")) {
      this.productDetailData.is_wish_list = true;
    }

    // if ( $('#scrollId').is(':visible') )  {
    for (var i = 0; i < this.businessDetailProducts.length; i++) {
      for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
        if (obj.id === this.businessDetailProducts[i].products[j].id) {
          this.businessDetailProducts[i].products[j].is_wish_list = true;
        }
      }
    }
    // }

    if ($("#SearchProductsByCategory").is(":visible")) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        if (obj.id === this.businessCategoryProducts[i].id) {
          this.businessCategoryProducts[i].is_wish_list = true;
        }
      }
    }

    if (
      this.businessCatLoding == false &&
      this.businessDetailProductSearchString != ""
    ) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        if (obj.id === this.searchProductByCategoryByID[i].id) {
          this.searchProductByCategoryByID[i].is_wish_list = true;
        }
      }
    }

    this.dataService
      .addProductInWishList(
        localStorage.getItem("token"),
        obj.id,
        this.businessId
      )
      .subscribe(
        (data) => {
          this.addWishlistSuccess = data;
          this.addWishlistSuccess = this.addWishlistSuccess.success;
          if (this.addWishlistSuccess === true) {
          } else {
            toastr.error("Something went wrong");
          }
        },
        (error) => {}
      );
  }
  addCartfromshortPop(obj, val) {
    this.addCartPopOpen(obj, val);
    if(val==1){
    $(".addCartShort").css("-webkit-filter", "blur(3px)");
    $(".addCartShort").css("-moz-filter", "blur(3px)");
    $(".addCartShort").css("-o-filter", "blur(3px)");
    $(".addCartShort").css("-ms-filter", "blur(3px)");
    $(".addCartShort").css("filter", "blur(3px)");}
  }
  removeToWishlistsearch(obj) {
    if ($("#businessAll").is(":visible")) {
      this.productDetailData.is_wish_list = false;
    }

    if (
      this.businessCatLoding == false &&
      this.businessDetailProductSearchString != ""
    ) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        if (obj.id === this.searchProductByCategoryByID[i].id) {
          this.searchProductByCategoryByID[i].is_wish_list = false;
        }
      }
    }

    for (var i = 0; i < this.loadPreviousProductsData.length; i++) {
      for (
        var j = 0;
        j < this.loadPreviousProductsData[i].products.length;
        j++
      ) {
        if (obj.id === this.loadPreviousProductsData[i].products[j].id) {
          this.loadPreviousProductsData[i].products[j].is_wish_list = false;
        }
      }
    }

    if ($("#SearchProductsByCategory").is(":visible")) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        if (obj.id === this.businessCategoryProducts[i].id) {
          this.businessCategoryProducts[i].is_wish_list = false;
        }
      }
    }
    this.dataService
      .removeProductInWishList(localStorage.getItem("token"), obj.id)
      .subscribe(
        (data) => {
          this.addWishlistSuccess = data;
          this.addWishlistSuccess = this.addWishlistSuccess.success;
          if (this.addWishlistSuccess === true) {
          } else {
            toastr.error("Something went wrong");
          }
        },
        (error) => {}
      );
  }
  addToWishlistsearch(obj) {
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === undefined ||
      localStorage.getItem("token") === null
    ) {
      this.addBlurbusinessDetail();
      this.dataControlService.isSignInWishlistClicked.next(true);
      this.events.publish("openLoginFunction", "true");
      return;
    }
    this.clearBlurbusinessDetail();
    if ($("#businessAll").is(":visible")) {
      this.productDetailData.is_wish_list = true;
    }

    if (
      this.businessCatLoding == false &&
      this.businessDetailProductSearchString != ""
    ) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        if (obj.id === this.searchProductByCategoryByID[i].id) {
          this.searchProductByCategoryByID[i].is_wish_list = true;
        }
      }
    }

    for (var i = 0; i < this.loadPreviousProductsData.length; i++) {
      for (
        var j = 0;
        j < this.loadPreviousProductsData[i].products.length;
        j++
      ) {
        if (obj.id === this.loadPreviousProductsData[i].products[j].id) {
          this.loadPreviousProductsData[i].products[j].is_wish_list = true;
        }
      }
    }

    if ($("#SearchProductsByCategory").is(":visible")) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        if (obj.id === this.businessCategoryProducts[i].id) {
          this.businessCategoryProducts[i].is_wish_list = true;
        }
      }
    }

    this.dataService
      .addProductInWishList(
        localStorage.getItem("token"),
        obj.id,
        this.businessId
      )
      .subscribe(
        (data) => {
          this.addWishlistSuccess = data;
          this.addWishlistSuccess = this.addWishlistSuccess.success;
          if (this.addWishlistSuccess === true) {
          } else {
            toastr.error("Something went wrong");
          }
        },
        (error) => {}
      );
  }
  catList: any;
  CategoryListing() {
    this.dataService
      .getCategoryList(localStorage.getItem("token"), this.businessId)
      .subscribe(
        (data) => {
          this.catList = data;
        },
        (error) => {}
      );
  }
  closeBackdrop() {
    $("#businessDetail").modal("hide");
    this.enableBackground();
  }
  storylimit = 3;
  storyData: any;
  getStoryListing() {
    this.dataService
      .getStoryByBusiness(this.storylimit, this.paramBusinessName)
      .subscribe(
        (data) => {
          this.storyData = data;
          this.storyData = this.storyData.items;
        },
        (error) => {
          this.nao.go(100);
        }
      );
  }

  loadMoreFunction() {
    this.getBusinessDetailProductsByPage();
  }
  businessDetailProductsPaginate: any;
  clearTimeoutProduct: any;
  limit = 6;

  getBusinessDetailProductsByPage() {
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === undefined
    ) {
      var token = "";
    } else {
      token = localStorage.getItem("token");
    }
    this.businessDetailProductsPaginate = [];
    const cartData = JSON.parse(localStorage.getItem("cartItems"));
    this.PagnationPage++;
    this.dataService
      .businessSearchProducts(
        this.businessId,
        this.businessDetailProductSearchString,
        this.limit,
        this.PagnationPage,
        token
      )
      .subscribe(
        (data) => {
          this.loaderCondition = false;
          this.businessDetailProductsPaginate = data;
          this.lastPage = this.businessDetailProductsPaginate.last_page;
          if (this.businessDetailProductSearchString === "") {
            this.loadPreviousProductsDataCount = this.PagnationPage;
            this.loadPreviousProductsDataLastPage = this.lastPage;
          }
          this.detailProductSuccess = this.businessDetailProductsPaginate.success;
          this.businessDetailProductsPaginate = this.businessDetailProductsPaginate.items;
          if (cartData !== null) {
            for (
              var i = 0;
              i < this.businessDetailProductsPaginate.length;
              i++
            ) {
              for (
                var j = 0;
                j < this.businessDetailProductsPaginate[i].products.length;
                j++
              ) {
                for (
                  var k = 0;
                  k <
                  this.businessDetailProductsPaginate[i].products[j].variant
                    .length;
                  k++
                ) {
                  for (var l = 0; l < cartData.length; l++) {
                    if (
                      cartData[l].variant_id ===
                      this.businessDetailProductsPaginate[i].products[j]
                        .variant[k].id
                    ) {
                      this.businessDetailProductsPaginate[i].products[
                        j
                      ].count += cartData[l].quantity;
                    }
                  }
                }
              }
            }
          }
          this.iniatlizeSlider();
          for (var k = 0; k < this.businessDetailProductsPaginate.length; k++) {
            this.businessDetailProducts.push(
              this.businessDetailProductsPaginate[k]
            );
          }
          this.loaderCondition = false;
        },
        (error) => {
          this.nao.go(100);
          // clearInterval(this.clearTimeoutProduct);
        }
      );
  }
  checkappToken: any;

  getBusinessDetailProductsCallMain(id) {
    this.loaderCondition = false;
    this.SeeAllLoader = false;
    var elmnt = document.getElementById("businessDetailHead");
    this.checkappToken = "";
    this.businessProductData = true;
    this.getBusinessDetailProducts(id);
    this.CategoryListing();
  }

  async quantityPlus(index, quantity, obj) {
    this.cartDataFromStorage = JSON.parse(localStorage.getItem("cartItems"));
    this.cartFinal[index].quantity = quantity + 1;
    for (let i = 0; i < this.cartDataFromStorage.length; i++) {
      if (
        this.businessId === this.cartDataFromStorage[i].business_id &&
        this.cartPopData.id === this.cartDataFromStorage[i].product_id &&
        obj.variant_id === this.cartDataFromStorage[i].variant_id
      ) {
        this.cartDataFromStorage[i].quantity = this.cartFinal[index].quantity;
        this.events.publish("cartUpdate", this.cartDataFromStorage);
      }
    }
    const cartData = JSON.parse(localStorage.getItem("cartItems"));

    if (this.searchProductByCategoryByID) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        this.searchProductByCategoryByID[i].count = 0;
        for (
          var k = 0;
          k < this.searchProductByCategoryByID[i].variant.length;
          k++
        ) {
          for (var l = 0; l < cartData.length; l++) {
            if (
              cartData[l].variant_id ===
              this.searchProductByCategoryByID[i].variant[k].id
            ) {
              this.searchProductByCategoryByID[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    if (this.businessCategoryProducts) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        this.businessCategoryProducts[i].count = 0;
        for (
          var k = 0;
          k < this.businessCategoryProducts[i].variant.length;
          k++
        ) {
          for (var l = 0; l < cartData.length; l++) {
            if (
              cartData[l].variant_id ===
              this.businessCategoryProducts[i].variant[k].id
            ) {
              this.businessCategoryProducts[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    // if ($('#scrollId').is('visible')) {
    for (var i = 0; i < this.businessDetailProducts.length; i++) {
      for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
        this.businessDetailProducts[i].products[j].count = 0;
        for (
          var k = 0;
          k < this.businessDetailProducts[i].products[j].variant.length;
          k++
        ) {
          for (var l = 0; l < cartData.length; l++) {
            if (
              cartData[l].variant_id ===
              this.businessDetailProducts[i].products[j].variant[k].id
            ) {
              this.businessDetailProducts[i].products[j].count +=
                cartData[l].quantity;
            }
          }
        }
      }
    }
    // }
  }

  async quantityMinus(index, quantity, obj) {
    this.cartDataFromStorage = JSON.parse(localStorage.getItem("cartItems"));
    this.cartFinal[index].quantity = quantity - 1;
    for (let i = 0; i < this.cartDataFromStorage.length; i++) {
      if (
        this.businessId === this.cartDataFromStorage[i].business_id &&
        this.cartPopData.id === this.cartDataFromStorage[i].product_id &&
        obj.variant_id === this.cartDataFromStorage[i].variant_id
      ) {
        if (this.cartFinal[index].quantity < 1) {
          this.cartDataFromStorage.splice(i, 1);
          this.events.publish("cartUpdate", this.cartDataFromStorage);
        } else {
          this.cartDataFromStorage[i].quantity = this.cartFinal[index].quantity;
          this.events.publish("cartUpdate", this.cartDataFromStorage);
        }
      }
    }
    if (this.cartFinal[index].quantity < 1) {
      this.cartFinal[index].quantity = 1;
      this.cartFinal[index].addCartBtnShow = true;
    }

    const cartData = JSON.parse(localStorage.getItem("cartItems"));

    if (this.searchProductByCategoryByID) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        this.searchProductByCategoryByID[i].count = 0;
        for (
          var k = 0;
          k < this.searchProductByCategoryByID[i].variant.length;
          k++
        ) {
          for (var l = 0; l < cartData.length; l++) {
            if (
              cartData[l].variant_id ===
              this.searchProductByCategoryByID[i].variant[k].id
            ) {
              this.searchProductByCategoryByID[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    if (this.businessCategoryProducts) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        this.businessCategoryProducts[i].count = 0;
        for (
          var k = 0;
          k < this.businessCategoryProducts[i].variant.length;
          k++
        ) {
          for (var l = 0; l < cartData.length; l++) {
            if (
              cartData[l].variant_id ===
              this.businessCategoryProducts[i].variant[k].id
            ) {
              this.businessCategoryProducts[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    // if ($('#scrollId').is('visible')) {
    for (var i = 0; i < this.businessDetailProducts.length; i++) {
      for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
        this.businessDetailProducts[i].products[j].count = 0;
        for (
          var k = 0;
          k < this.businessDetailProducts[i].products[j].variant.length;
          k++
        ) {
          for (var l = 0; l < cartData.length; l++) {
            if (
              cartData[l].variant_id ===
              this.businessDetailProducts[i].products[j].variant[k].id
            ) {
              this.businessDetailProducts[i].products[j].count +=
                cartData[l].quantity;
            }
          }
        }
      }
    }
    // }
  }
  businessName2: any;
  indexToOveride: any;
  objToOveride: any;

  async addToCart(obj, index) {
    localStorage.setItem("paymentMethod", this.payment_through_card);
    localStorage.setItem("paymentThroughCard", this.payment_through_card);
    localStorage.setItem("paymentThroughCash", this.payment_through_cash);
    localStorage.setItem("paymentOverDue", this.payment_over_due);
    this.businessName2 = localStorage.getItem("businessName");
    if (obj.hasModifier == true) {
      this.objToOveride = obj;
      this.indexToOveride = index;
      let cartDataNewChk = JSON.parse(localStorage.getItem("cartItems"));
      if (cartDataNewChk !== null) {
        if (cartDataNewChk.length > 0) {
          if (this.businessId !== cartDataNewChk[0].business_id) {
            $("#onOnleBusinessCart").modal("show");
            // this.disableBackground();

            return;
          }
        }
      }
      this.selectedVariant = index;
      this.dataService.getVariantDetail(obj.variant_id).subscribe(
        (res) => {
          console.log(res);
          let modifierObj = {
            quantityTotal: 1,
            name: obj.product_name,
            image: obj.product_image,
            variantName: obj.variant_name,
            optionValue: obj.variant_value,
            price: Math.round(obj.price),
            compare_price: Math.round(obj.compare_price),
            is_on_sale: obj.is_on_sale,
            variant_id: obj.variant_id,
            modifiers: res["modifiers"],
            addons: res["addons"],
            noOption: res["no_options"],
            error_msg: "",
          };
          this.productModifierDataArray = new Array();
          this.productModifierDataArray.push(modifierObj);
          this.newCart = this.cartFinal;
          this.newCart[this.selectedVariant].modifiers = [];
          this.modModifiers = [];
          for (
            let i = 0;
            i < this.productModifierDataArray[0].modifiers.length;
            i++
          ) {
            // for(let j = 0; j < this.productModifierDataArray[0].modifiers[i].options.length; j++) {
            this.productModifierDataArray[0].modifiers[
              i
            ].options[0].selected = true;
            let objSelected = {};
            objSelected = {
              id: this.productModifierDataArray[0].modifiers[i].id,
              title: this.productModifierDataArray[0].modifiers[i].title,
              options: this.productModifierDataArray[0].modifiers[i].options[0],
            };
            this.modModifiers.push(objSelected);
            // this.newCart[this.selectedVariant].modifiers.push(objSelected);
            // }
          }
          if (
            this.productModifierDataArray[0].noOption != undefined &&
            this.productModifierDataArray[0].noOption != null &&
            this.productModifierDataArray[0].noOption.options != null
          ) {
            for (
              let i = 0;
              i < this.productModifierDataArray[0].noOption.options.length;
              i++
            ) {
              this.productModifierDataArray[0].noOption.options[
                i
              ].selected = true;
            }
          }
          this.totalPriceCalculation();
        },
        (err) => console.log(err)
      );
      $("#addVariantsModifiersPop").modal("show");
      $("#addVariantsModifiersPop").css("overflow-y", "auto");
    } else {
      this.fbAddToCart();
      if (this.payment_over_due === true) {
        $("#addCartPop").modal("hide");
        $("#onOnleOverDue").modal("show");
        this.disableBackground();

        return;
      }

      this.objToOveride = obj;
      this.indexToOveride = index;
      let cartDataNewChk = JSON.parse(localStorage.getItem("cartItems"));
      if (cartDataNewChk !== null) {
        if (cartDataNewChk.length > 0) {
          if (this.businessId !== cartDataNewChk[0].business_id) {
            $("#onOnleBusinessCart").modal("show");
            // this.disableBackground();

            return;
          }
        }
      }
      const objToPass = obj;
      this.cartFinal[index].addCartBtnShow
        ? (this.cartFinal[index].addCartBtnShow = false)
        : "";
      if (
        this.cartDataFromStorage === null ||
        this.cartDataFromStorage === undefined
      ) {
        this.cartDataFromStorage = [];
      }
      this.cartDataFromStorage.push(objToPass);
      this.events.publish("cartUpdate", this.cartDataFromStorage);
      const cartData = JSON.parse(localStorage.getItem("cartItems"));

      if (this.searchProductByCategoryByID != undefined) {
        for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
          this.searchProductByCategoryByID[i].count = 0;
          for (
            var k = 0;
            k < this.searchProductByCategoryByID[i].variant.length;
            k++
          ) {
            for (var l = 0; l < cartData.length; l++) {
              if (
                cartData[l].variant_id ===
                this.searchProductByCategoryByID[i].variant[k].id
              ) {
                this.searchProductByCategoryByID[i].count +=
                  cartData[l].quantity;
              }
            }
          }
        }
      }
      if (this.businessCategoryProducts != undefined) {
        for (var i = 0; i < this.businessCategoryProducts.length; i++) {
          this.businessCategoryProducts[i].count = 0;
          for (
            var k = 0;
            k < this.businessCategoryProducts[i].variant.length;
            k++
          ) {
            for (var l = 0; l < cartData.length; l++) {
              if (
                cartData[l].variant_id ===
                this.businessCategoryProducts[i].variant[k].id
              ) {
                this.businessCategoryProducts[i].count += cartData[l].quantity;
              }
            }
          }
        }
      }
      // if ($('#scrollId').is('visible')) {
      for (var i = 0; i < this.businessDetailProducts.length; i++) {
        for (
          var j = 0;
          j < this.businessDetailProducts[i].products.length;
          j++
        ) {
          this.businessDetailProducts[i].products[j].count = 0;
          for (
            var k = 0;
            k < this.businessDetailProducts[i].products[j].variant.length;
            k++
          ) {
            for (var l = 0; l < cartData.length; l++) {
              if (
                cartData[l].variant_id ===
                this.businessDetailProducts[i].products[j].variant[k].id
              ) {
                this.businessDetailProducts[i].products[j].count +=
                  cartData[l].quantity;
                this.isSingleOrder += this.isSingleOrder;
              }
            }
          }
        }
      }
      // }
    }
    localStorage.setItem("paymentMethod", this.payment_through_card);
    localStorage.setItem("paymentThroughCard", this.payment_through_card);
    localStorage.setItem("paymentThroughCash", this.payment_through_cash);
    localStorage.setItem("paymentOverDue", this.payment_over_due);
    localStorage.setItem(
      "cartBusinessData",
      JSON.stringify(this.businessDetailData)
    );
    $("#addCartPop").modal("hide");
    // localStorage.setItem('isDineinAvailable', this.businessDetailData.is_dine_in_available);
    // localStorage.setItem('isPickupAvailable', this.businessDetailData.is_pickup_available);
    // localStorage.setItem('deliveryPaymentOptions', JSON.stringify(this.businessDetailData.delivery_payment_options));
    // localStorage.setItem('dineInPaymentOptions', JSON.stringify(this.businessDetailData.dine_in_payment_options));
    // localStorage.setItem('pickupPaymentOptions', JSON.stringify(this.businessDetailData.pickup_payment_options));
  }
  catidSend(id) {
    this.subCatid = id;
    this.getBusinessCategoryProducts();
  }
  activeCrossFunc(index) {
    $(".animateRemoveClass").addClass("animateImg");
    this.subcatLoadingShimmer = true;
    this.subCatid = -1;
    this.getBusinessCategoryProducts();
    // $('.textOpc').removeClass('subCatimgMaxOpacity');
    // $('.textOpc').addClass('subCatimgMinOpacity');
    $("#cross" + index).removeClass("subCatTextdis");
    $("#cross" + index).addClass("none");
    $(".opac").removeClass("subCatimgMaxOpacity");
    $(".opac").addClass("subCatimgMinOpacity");
  }
  activeClassFunc(index) {
    $(".animateRemoveClass").removeClass("animateImg");
    this.subcatLoadingShimmer = true;
    setTimeout(() => {
      $(".subCatTile").removeClass("subCatTextdis");
      $(".subCatTile").addClass("none");
      $(".subCatImg").removeClass("subCatimgMinOpacity");
      $(".subCatImg").addClass("subCatimgMaxOpacity");

      $("#cross" + index).removeClass("none");
      $("#cross" + index).addClass("subCatTextdis");
      $("#subCatImg" + index).addClass("subCatimgMinOpacity");
      $("#subCatText" + index).addClass("subCatimgMinOpacity");
    }, 100);
  }
  closeNav1() {
    this.businessCategoryProductslength = false;
    this.dataControlService.isSeeAllProducts.next(false);
    this.dataControlService.expandPrefClicked.next(false);
    this.enableBackground();
    $("#subCategorySlider").css("visibility", "hidden");
    this.loaderCondition = false;
    this.SeeAllLoader = false;
    var dSlider = $(".subCategorySlider");
    dSlider.trigger("destroy.owl.carousel");
    this.subCatid = -1;
    this.searchItemCat = false;
    this.dataControlService.filterSearchString.next("");
    this.dataControlService.filterMaxVal.next(undefined);
    this.dataControlService.filterMinVal.next(undefined);
  }
  async openFinalCart() {
    this.events.publish("openCartPopup", "true");
  }

  searchValCat(val) {
    clearInterval(this.clearTimeout1);
    this.clearTimeout1 = setInterval(() => {
      this.searchStringCat = val.search;
      this.viewAllSearchResults = val;
      if (val === "" || this.searchStringCat == "") {
        this.SeeAllLoader = false;
        this.searchStringCat = "";
        this.loadingDataCat = false;
        this.subcatLoadingShimmer = true;
        this.subcatSearchLoadingShimmer = true;
        // this.getBusinessCategoryProducts();
        this.mySearchProductsByCategoryId();

        this.searchItemCat = false;
        // this.seeAllImageNotToShow = false;
      } else {
        this.SeeAllLoader = false;
        this.loadingDataCat = false;
        this.subcatLoadingShimmer = true;
        this.subcatSearchLoadingShimmer = true;
        this.mySearchProductsByCategoryId();

        // this.businessCategorySearchProduct();
        // this.getBusinessCategoryProducts();
        this.searchItemCat = true;

        // this.seeAllImageNotToShow = true;
      }
    }, 1000);
  }
  mySearchProductsByCategoryId() {
    this.businessCategoryProductslength = false;
    this.PaginationPageByCategory = 0;
    clearInterval(this.clearTimeout1);

    this.SeeAllLoader = false;
    this.subcatLoadingShimmer = true;
    this.searchItemCat = false;
    // $('.sliderList').slick('unslick');
    this.businessCategoryProducts = [];
    const cartData = JSON.parse(localStorage.getItem("cartItems"));
    if (
      window.localStorage.getItem("token") === "" ||
      window.localStorage.getItem("token") === undefined
    ) {
      var token = "";
    } else {
      token = window.localStorage.getItem("token");
    }

    let maxVal;
    let minVal;
    if (this.viewAllSearchResults !== undefined) {
      if (this.viewAllSearchResults.price_range.min_price == "any") {
        this.viewAllSearchResults.price_range.min_price = -1;
      } else {
        this.viewAllSearchResults.price_range.min_price = this.viewAllSearchResults.price_range.min_price;
      }

      if (this.viewAllSearchResults.price_range.max_price == "any") {
        this.viewAllSearchResults.price_range.max_price = -1;
      } else {
        this.viewAllSearchResults.price_range.max_price = this.viewAllSearchResults.price_range.max_price;
      }

      maxVal = this.viewAllSearchResults.price_range.max_price;
      minVal = this.viewAllSearchResults.price_range.min_price;
    } else {
      maxVal = -1;
      minVal = -1;
    }

    this.dataService
      .businessCategorySearchProduct(
        this.businessId,
        this.getProductsViaCategoryLimit,
        this.PaginationPageByCategory,
        this.cat_id,
        this.searchStringCat,
        this.subCatid,
        token,
        minVal,
        maxVal
      )
      .subscribe(
        (data) => {
          this.SeeAllLoader = false;
          this.subcatLoadingShimmer = false;
          this.subcatSearchLoadingShimmer = false;
          this.businessCategoryProducts = data;
          this.lastPageOfCategory = this.businessCategoryProducts.last_page;
          this.detailProductSuccess = this.businessCategoryProducts.success;
          this.businessCategoryProducts = this.businessCategoryProducts.items;
          if (this.businessCategoryProducts !== undefined) {
            if (this.businessCategoryProducts.length === 0) {
              this.businessCategoryProductslength = true;
            }
          }

          this.initSlider();
          if (cartData !== null) {
            for (var i = 0; i < this.businessCategoryProducts.length; i++) {
              this.businessCategoryProducts[i].count = 0;
              for (
                var k = 0;
                k < this.businessCategoryProducts[i].variant.length;
                k++
              ) {
                for (var l = 0; l < cartData.length; l++) {
                  if (
                    cartData[l].variant_id ===
                    this.businessCategoryProducts[i].variant[k].id
                  ) {
                    this.businessCategoryProducts[i].count +=
                      cartData[l].quantity;
                  }
                }
              }
            }
          }
          this.loadingDataCat = false;
        },
        (error) => {
          // this.nao.go(100);
        }
      );
  }
  clearSearchCat() {
    this.SeeAllLoader = false;
    this.searchStringCat = "";
    this.subcatLoadingShimmer = true;
    this.getBusinessCategoryProducts();
    this.searchItemCat = false;
  }
  removeToWishlistCatsearch(obj) {
    for (var i = 0; i < this.businessCategoryProducts.length; i++) {
      if (obj.id === this.businessCategoryProducts[i].id) {
        this.businessCategoryProducts[i].is_wish_list = false;
      }
    }

    this.dataService
      .removeProductInWishList(window.localStorage.getItem("token"), obj.id)
      .subscribe(
        (data) => {
          this.addWishlistSuccess = data;
          this.addWishlistSuccess = this.addWishlistSuccess.success;
          if (this.addWishlistSuccess === true) {
          } else {
            toastr.error("Something went wrong");
          }
          this.updateBusinessDetailProducts1();
        },
        (error) => {}
      );
  }
  businessDetailProductsUpdate: any;
  updateBusinessDetailProducts1() {
    if (
      window.localStorage.getItem("token") === "" ||
      window.localStorage.getItem("token") === undefined
    ) {
      var token = "";
    } else {
      token = window.localStorage.getItem("token");
    }

    this.dataService.businessDetailProducts1(this.businessId, token).subscribe(
      (data) => {
        this.businessDetailProductsUpdate = data;
        this.businessDetailProductsUpdate = this.businessDetailProductsUpdate.items;
        if (
          this.businessDetailProductsUpdate !== undefined &&
          this.businessDetailProducts !== undefined
        ) {
          for (var i = 0; i < this.businessDetailProductsUpdate.length; i++) {
            for (
              var j = 0;
              j < this.businessDetailProductsUpdate[i].products.length;
              j++
            ) {
              this.businessDetailProducts[i].products[
                j
              ].is_wish_list = this.businessDetailProductsUpdate[i].products[
                j
              ].is_wish_list;
            }
          }
        }

        if ($("#businessAll").is(":visible")) {
          for (var i = 0; i < this.businessDetailProductsUpdate.length; i++) {
            for (
              var j = 0;
              j < this.businessDetailProductsUpdate[i].products.length;
              j++
            ) {
              if (
                this.businessDetailProductsUpdate[i].items.products[j].id ===
                this.productDetailData.id
              ) {
                this.productDetailData.is_wish_list = this.businessDetailProductsUpdate[
                  i
                ].products[j].is_wish_list;
              }
            }
          }
        }
      },
      (error) => {}
    );
  }

  addToWishlistCatsearch(obj) {
    if (
      window.localStorage.getItem("token") === "" ||
      window.localStorage.getItem("token") === undefined ||
      window.localStorage.getItem("token") === null
    ) {
      this.addBlurSeeMorePopup();
      this.dataControlService.isSignInWishlistClicked.next(true);
      this.events.publish("openLoginFunction", "true");
      return;
    }
    this.clearBlurSeeMorePopup();
    for (var i = 0; i < this.businessCategoryProducts.length; i++) {
      if (obj.id === this.businessCategoryProducts[i].id) {
        this.businessCategoryProducts[i].is_wish_list = true;
      }
    }

    this.dataService
      .addProductInWishList(
        window.localStorage.getItem("token"),
        obj.id,
        this.businessId
      )
      .subscribe(
        (data) => {
          this.addWishlistSuccess = data;
          this.addWishlistSuccess = this.addWishlistSuccess.success;
          if (this.addWishlistSuccess === true) {
          } else {
            toastr.error("Something went wrong");
          }
          this.updateBusinessDetailProducts1();
        },
        (error) => {}
      );
  }
  clearBlurSeeMorePopup() {
    $("#SeeMorePopup").css("-webkit-filter", "blur(0px)");
    $("#SeeMorePopup").css("-moz-filter", "blur(0px)");
    $("#SeeMorePopup").css("-o-filter", "blur(0px)");
    $("#SeeMorePopup").css("-ms-filter", "blur(0px)");
    $("#SeeMorePopup").css("filter", "blur(0px)");
  }

  addBlurSeeMorePopup() {
    $("#SeeMorePopup").css("-webkit-filter", "blur(3px)");
    $("#SeeMorePopup").css("-moz-filter", "blur(3px)");
    $("#SeeMorePopup").css("-o-filter", "blur(3px)");
    $("#SeeMorePopup").css("-ms-filter", "blur(3px)");
    $("#SeeMorePopup").css("filter", "blur(3px)");
  }

  goToBusinessPage() {
    // localStorage.setItem('business_store_category', this.businessCategoryId);
    // localStorage.setItem('business_store_category_name', this.businessCategoryName);
    let data = [];
    data["business_detail"] = {
      has_custom_order_form: false,
      custom_order_title: "",
      id: -1,
    };

    this.events.publish("businessDetailData", data);
    localStorage.setItem(
      "businessDetailData",
      JSON.stringify(this.businessDetailData)
    );
    this.router.navigate(["/businesses", this.cityName], { replaceUrl: true });
  }
  disableMultiClick = false;

  addToCartOnExisting() {
    this.disableMultiClick = true;
    this.cartDataFromStorage = [];
    this.events.publish("cartUpdate", this.cartDataFromStorage);
    // window.localStorage.setItem('cartItems', '');
    localStorage.removeItem("cartItems");
    setTimeout(() => {
      this.addToCart(this.objToOveride, this.indexToOveride);
      $("#onOnleBusinessCart").modal("hide");
      $("#addVariantsModifiersPop").css("overflow-y", "auto");
      this.enableBackground();
      this.disableMultiClick = false;
    }, 1000);
  }
  isProductSearchSelect = false;
  openProductSearch() {
    this.isProductSearchSelect = true;
    // setTimeout(() => {
    //   $(".searchform").toggleClass("opened");
    // }, 100);
  }
  isFilterCloseClicked: boolean = false;
  closeProductSearch() {
    this.isProductSearchSelect = false;
    this.isFilterCloseClicked = true;
    let obj = {
      price_range: {
        min_price: -1,
        max_price: -1,
      },
      search: "",
    };
    this.dataControlService.filterSearchString.next("");

    this.searchBusinessDetlProductsCall(obj);

    if (this.selectedTab === "products") {
      setTimeout(() => {
        // setTimeout(() => {
        //   this.setProductSliderOptions();
        // }, 1000);
        $("#aboutTab").removeClass("active");
        $("#categoryTab").removeClass("active");
        $("#productsTab").addClass("active");

        $("#aboutTabMobile").removeClass("active");
        $("#categoryTabMobile").removeClass("active");
        $("#productsTabMobile").addClass("active");
      }, 100);
    }
    if (this.selectedTab === "about") {
      setTimeout(() => {
        $("#aboutTab").addClass("active");
        $("#categoryTab").removeClass("active");
        $("#productsTab").removeClass("active");

        $("#aboutTabMobile").addClass("active");
        $("#categoryTabMobile").removeClass("active");
        $("#productsTabMobile").removeClass("active");
      }, 100);
    }
    if (this.selectedTab === "categories") {
      setTimeout(() => {
        $("#aboutTab").removeClass("active");
        $("#categoryTab").addClass("active");
        $("#productsTab").removeClass("active");

        $("#aboutTabMobile").removeClass("active");
        $("#categoryTabMobile").addClass("active");
        $("#productsTabMobile").removeClass("active");
      }, 100);
    }
  }
  loadMoreFunctionByCategoryBySearch() {
    this.searchProductsByCategoryIdByPage();
  }

  // let maxVal;
  // let minVal;
  // if (this.viewAllSearchResults !== undefined) {
  //   if (this.viewAllSearchResults.price_range.min_price == 'any') {
  //     this.viewAllSearchResults.price_range.min_price = -1;
  //   } else {
  //     this.viewAllSearchResults.price_range.min_price = this.viewAllSearchResults.price_range.min_price;
  //   }

  //   if (this.viewAllSearchResults.price_range.max_price == 'any') {
  //     this.viewAllSearchResults.price_range.max_price = -1;
  //   } else {
  //     this.viewAllSearchResults.price_range.max_price = this.viewAllSearchResults.price_range.max_price;
  //   }

  //   maxVal = this.viewAllSearchResults.price_range.max_price;
  //   minVal = this.viewAllSearchResults.price_range.min_price;

  // }
  // else{
  //   maxVal = -1;
  //   minVal = -1;
  // }

  searchProductsByCategoryIdByPage() {
    this.PaginationPageForSearch++;
    // clearTimeout(this.clearTimeoutProduct);
    this.searchProductByCategoryByIDByPage = [];
    this.loaderCondition = true;

    // this.clearTimeoutProduct = setTimeout(() => {
    if (
      window.localStorage.getItem("token") === "" ||
      window.localStorage.getItem("token") === undefined
    ) {
      var token = "";
    } else {
      token = window.localStorage.getItem("token");
    }
    const cartData = JSON.parse(localStorage.getItem("cartItems"));
    this.inputSearch = false;

    let maxVal;
    let minVal;
    if (this.viewAllSearchResults !== undefined) {
      if (this.viewAllSearchResults.price_range.min_price == "any") {
        this.viewAllSearchResults.price_range.min_price = -1;
      } else {
        this.viewAllSearchResults.price_range.min_price = this.viewAllSearchResults.price_range.min_price;
      }

      if (this.viewAllSearchResults.price_range.max_price == "any") {
        this.viewAllSearchResults.price_range.max_price = -1;
      } else {
        this.viewAllSearchResults.price_range.max_price = this.viewAllSearchResults.price_range.max_price;
      }

      maxVal = this.viewAllSearchResults.price_range.max_price;
      minVal = this.viewAllSearchResults.price_range.min_price;
    } else {
      maxVal = -1;
      minVal = -1;
    }

    this.dataService
      .searchProductsByCategory(
        this.businessId,
        this.limitForSearch,
        this.PaginationPageForSearch,
        this.businessDetailProductSearchString,
        token,
        maxVal,
        minVal
      )
      .subscribe(
        (data) => {
          this.businessCatLoding = false;
          this.loaderCondition = false;
          this.searchProductByCategoryByIDByPage = data;
          this.searchProductByCategoryByIDLastPage = this.searchProductByCategoryByIDByPage.last_page;
          this.lastPageOfCategory = this.searchProductByCategoryByIDByPage.last_page;
          this.searchProductByCategoryByIDByPage = this.searchProductByCategoryByIDByPage.items;
          this.inputSearch = true;
          if (cartData !== null) {
            for (
              var i = 0;
              i < this.searchProductByCategoryByIDByPage.length;
              i++
            ) {
              this.searchProductByCategoryByIDByPage[i].count = 0;
              for (
                var k = 0;
                k < this.searchProductByCategoryByIDByPage[i].variant.length;
                k++
              ) {
                for (var l = 0; l < cartData.length; l++) {
                  if (
                    cartData[l].variant_id ===
                    this.searchProductByCategoryByIDByPage[i].variant[k].id
                  ) {
                    this.searchProductByCategoryByIDByPage[i].count +=
                      cartData[l].quantity;
                  }
                }
              }
            }
          }
          for (
            var k = 0;
            k < this.searchProductByCategoryByIDByPage.length;
            k++
          ) {
            this.searchProductByCategoryByID.push(
              this.searchProductByCategoryByIDByPage[k]
            );
          }
        },
        (error) => {
          // this.nao.go(100);
        }
      );
    // }, 300);
  }

  myCurrentCategoryId: any;
  loadingData = true;
  searchItem = false;
  business_category_list: any = [];
  lastPageOfBusinessCategory: boolean = true;
  myCategoryBusinesses: any;
  profileAddress: any;
  businessCategoryPageNo: any = 0;
  businessCategoryPageLimit: any = 40;
  searchedData1: any = [];

  sendToApi(event) {
    this.loadingData = true;
    // this.searchString = "";
    this.businessCategoryPageNo = 0;
    this.lastPageOfBusinessCategory = true;

    this.businessCategoryProductslength = false;
    this.PaginationPageByCategory = 0;
    clearInterval(this.clearTimeout1);
    // $('.sliderList').slick('unslick');
    this.businessCategoryProducts = [];
    const cartData = JSON.parse(localStorage.getItem("cartItems"));
    if (
      window.localStorage.getItem("token") === "" ||
      window.localStorage.getItem("token") === undefined
    ) {
      var token = "";
    } else {
      token = window.localStorage.getItem("token");
    }
    this.searchStringCat = event.search;
    if (this.searchStringCat !== "") {
      this.nao.go(30);
    }

    let sendfilteredData = {
      price_range_min: -1,
      price_range_max: -1,
      filter_type: -1,
      free_delivery: false,
      online_payment: false,
    };
    if (event.price_range.min_price == "any") {
      sendfilteredData["price_range_min"] = -1;
    } else {
      sendfilteredData["price_range_min"] = event.price_range.min_price;
    }

    if (event.price_range.max_price == "any") {
      sendfilteredData["price_range_max"] = -1;
    } else {
      sendfilteredData["price_range_max"] = event.price_range.max_price;
    }

    sendfilteredData["filter_type"] = -1;
    sendfilteredData["free_delivery"] = event.offers.free_delivery;
    sendfilteredData["online_payment"] = event.offers.online_payment;

    let lat;
    let long;
    let selectedLat;
    let selectedLong;
    if (event.sort.near_by) {
      sendfilteredData["filter_type"] = 0;
    }

    if (event.sort.name_A_to_Z) {
      sendfilteredData["filter_type"] = 1;
    }

    if (event.sort.recent_updated) {
      sendfilteredData["filter_type"] = 2;
    }

    if (event.sort.most_popular) {
      sendfilteredData["filter_type"] = 3;
    }

    if (this.profileAddress === null) {
      lat = "";
      long = "";
    }
    const profileData = JSON.parse(localStorage.getItem("profileAddress"));
    selectedLat = profileData.latitude;
    selectedLong = profileData.longitude;
    lat = profileData.latitude;
    long = profileData.longitude;

    this.dataService
      .getBusinessCategoryOutlet(
        localStorage.getItem("token"),
        this.businessCategoryPageNo,
        this.businessCategoryPageLimit,
        this.id,
        this.myCurrentCategoryId,
        this.searchStringCat,
        sendfilteredData.filter_type,
        lat,
        long,
        selectedLat,
        selectedLong,
        sendfilteredData.price_range_min,
        sendfilteredData.price_range_max,
        sendfilteredData.free_delivery,
        sendfilteredData.online_payment
      )
      .subscribe(
        (data: any) => {
          this.nao.go(100);
          this.loadingData = false;
          this.myCategoryBusinesses = data;
          this.lastPageOfBusinessCategory = this.myCategoryBusinesses.last_page;
          this.business_category_list = this.myCategoryBusinesses.items;
          this.searchItem = true;
          this.searchedData1 = data.items;
          this.loadingData = false;
        },
        (error) => {
          console.log(error);
          this.loadingData = false;
        }
      );
  }
  scrollToProducts() {
    // var myProductSection = document.getElementById("myProductSection");
    // myProductSection.scrollIntoView();
  }

  closeModal() {
    this.productData = new Array();
    this.addItValue = 0;
  }
  modModifiers = [];
  modAddons = [];
  modNoOptions = [];
  totalPriceCalculation() {
    let total = this.productModifierDataArray[0].price;
    let addOnPrice = 0;
    this.modifiersArr = [];
    // this.newCart[this.selectedVariant].modifiers = [];
    // this.modModifiers = [];
    this.modAddons = [];
    this.modNoOptions = [];
    this.addonsArr = [];
    // this.newCart[this.selectedVariant].addons = [];
    this.noOptionsArr = [];
    // this.newCart[this.selectedVariant].noOption = [];
    // this.varImage = this.productModifierDataArray[0].image;
    // this.varName = this.productModifierDataArray[0].variantNme;
    this.productModifierDataArray[0].modifiers.forEach((element) => {
      for (let i = 0; i < element.options.length; i++) {
        if (
          element.options[i].selected &&
          element.options[i].selected === true
        ) {
          total += element.options[i].price;
          addOnPrice += element.options[i].price;
          this.modifiersArr.push({
            title: element.title,
            options: element.options[i],
            id: element.id,
          });
        }
      }
    });

    if (
      this.productModifierDataArray[0].addons != undefined &&
      this.productModifierDataArray[0].addons != null &&
      this.productModifierDataArray[0].addons.options != null
    ) {
      this.productModifierDataArray[0].addons.options.forEach((element) => {
        if (element.selected && element.selected === true) {
          this.addonsArr.push(element);
          this.modAddons.push(element);
          // this.newCart[this.selectedVariant].addons.push(element);
          total += element.price;
          addOnPrice += element.price;
        }
      });
    }

    if (
      this.productModifierDataArray[0].noOption != undefined &&
      this.productModifierDataArray[0].noOption != null &&
      this.productModifierDataArray[0].noOption.options != null
    ) {
      this.productModifierDataArray[0].noOption.options.forEach((element) => {
        if (element.selected && element.selected === true) {
          total += element.price;
        }
        if (element.selected === false) {
          this.noOptionsArr.push(element);
          this.modNoOptions.push(element);
          // this.newCart[this.selectedVariant].noOption.push(element);
        }
      });
    }
    // this.newCart[this.selectedVariant].modifierPrice = total;
    // this.newCart[this.selectedVariant].additionalPrice = addOnPrice;
    this.addItValue = total;
    this.additonalValue = addOnPrice;
  }

  plusModifierValue(groupId, optionId) {
    // this.newCart = this.cartFinal;
    this.modifiersArr = [];
    this.modModifiers = [];
    // this.newCart[this.selectedVariant].modifiers = [];
    for (
      let i = 0;
      i < this.productModifierDataArray[0].modifiers.length;
      i++
    ) {
      if (this.productModifierDataArray[0].modifiers[i].id == groupId) {
        for (
          let j = 0;
          j < this.productModifierDataArray[0].modifiers[i].options.length;
          j++
        ) {
          if (
            this.productModifierDataArray[0].modifiers[i].options[j].id ==
            optionId
          ) {
            this.productModifierDataArray[0].modifiers[i].options[
              j
            ].selected = true;
            if (
              this.productModifierDataArray[0].modifiers[i].options[j]
                .selected == true
            ) {
              let obj = {};
              obj = {
                id: this.productModifierDataArray[0].modifiers[i].id,
                title: this.productModifierDataArray[0].modifiers[i].title,
                options: this.productModifierDataArray[0].modifiers[i].options[
                  j
                ],
              };
              this.modModifiers.push(obj);
              // this.newCart[this.selectedVariant].modifiers.push(obj);
            }
          } else {
            this.productModifierDataArray[0].modifiers[i].options[
              j
            ].selected = false;
          }
        }
      }
    }
    this.totalPriceCalculation();
  }

  plusAddonsValue(optionId, event) {
    this.addonsArr = [];
    this.modAddons = [];
    // this.newCart[this.selectedVariant].addons = [];
    for (
      let i = 0;
      i < this.productModifierDataArray[0].addons.options.length;
      i++
    ) {
      if (optionId === this.productModifierDataArray[0].addons.options[i].id) {
        if (event.target.checked === true) {
          this.productModifierDataArray[0].addons.options[i].selected = true;
        } else if (event.target.checked === false) {
          this.productModifierDataArray[0].addons.options[i].selected = false;
        }
      }
    }
    this.totalPriceCalculation();
  }

  subNoOptionValue(optionId, event) {
    this.noOptionsArr = [];
    this.modNoOptions = [];
    // this.newCart[this.selectedVariant].noOption = [];
    for (
      let i = 0;
      i < this.productModifierDataArray[0].noOption.options.length;
      i++
    ) {
      if (
        optionId === this.productModifierDataArray[0].noOption.options[i].id
      ) {
        if (event.target.checked === false) {
          this.productModifierDataArray[0].noOption.options[i].selected = false;
        } else if (event.target.checked === true) {
          this.productModifierDataArray[0].noOption.options[i].selected = true;
        }
      }
    }
    this.totalPriceCalculation();
  }

  addItFunction(price, id) {
    this.newCart[this.selectedVariant].modifiers = this.modModifiers;
    this.newCart[this.selectedVariant].addons = this.modAddons;
    this.newCart[this.selectedVariant].noOption = this.modNoOptions;
    this.newCart[this.selectedVariant].modifierPrice = this.addItValue;
    this.newCart[this.selectedVariant].additionalPrice = this.additonalValue;
    this.uniqueId = "";
    this.uniqueId = this.newCart[this.selectedVariant].variant_id;
    console.log(this.newCart[this.selectedVariant]);
    for (
      let i = 0;
      i < this.newCart[this.selectedVariant].modifiers.length;
      i++
    ) {
      this.uniqueId =
        this.uniqueId +
        "_" +
        this.newCart[this.selectedVariant].modifiers[i].options.id;
    }
    this.newCart[this.selectedVariant].addons.forEach((element) => {
      this.uniqueId = this.uniqueId + "_" + element.id;
    });
    this.newCart[this.selectedVariant].noOption.forEach((element) => {
      this.uniqueId = this.uniqueId + "_" + element.id;
    });
    if (this.oldUniqueId == "") {
      let result = this.existingModifierCheck(
        this.newCart[this.selectedVariant].variant_id,
        this.uniqueId
      );
      if (result == false) {
        this.newCart[this.selectedVariant].uniqueId = this.uniqueId;
        this.cartDataFromStorage = JSON.parse(
          localStorage.getItem("cartItems")
        );
        if (this.cartDataFromStorage == null) {
          this.cartDataFromStorage = [];
        }
        this.cartDataFromStorage.push(this.newCart[this.selectedVariant]);
        this.events.publish("cartUpdate", this.cartDataFromStorage);
      }
    } else {
      if (this.uniqueId == this.oldUniqueId) {
        console.log("here");
        this.oldUniqueId = "";
      } else {
        this.cartDataFromStorage = JSON.parse(
          localStorage.getItem("cartItems")
        );
        this.newCart[this.selectedVariant].uniqueId = this.uniqueId;
        var foundAtIndex = null;
        var matchedWithIndex = null;

        for (let i = 0; i < this.cartDataFromStorage.length; i++) {
          if (
            this.cartDataFromStorage[i].uniqueId != "" &&
            this.cartDataFromStorage[i].uniqueId == this.oldUniqueId
          ) {
            foundAtIndex = i;
            console.log(this.cartDataFromStorage);
          }
          if (
            this.newCart[this.selectedVariant].uniqueId ==
            this.cartDataFromStorage[i].uniqueId
          ) {
            matchedWithIndex = i;
          }
        }
        if (foundAtIndex != null && matchedWithIndex != null) {
          this.cartDataFromStorage[matchedWithIndex].quantity += 1;
          // this.cartDataFromStorage[matchedWithIndex].price += this.newCart[this.selectedVariant].price;
          this.cartDataFromStorage.splice(foundAtIndex, 1);
        } else if (foundAtIndex != null) {
          this.newCart[this.selectedVariant].uniqueId = this.uniqueId;
          this.cartDataFromStorage[foundAtIndex] = this.newCart[
            this.selectedVariant
          ];
        }
        this.oldUniqueId = "";
        foundAtIndex = null;
        matchedWithIndex = null;
        this.events.publish("cartUpdate", this.cartDataFromStorage);
      }
    }

    $("#addVariantsModifiersPop").modal("hide");
    $("#addCartPop").css("overflow-y", "auto");
    const cartData = JSON.parse(localStorage.getItem("cartItems"));
    if (this.searchProductByCategoryByID != undefined) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        this.searchProductByCategoryByID[i].count = 0;
        for (
          var k = 0;
          k < this.searchProductByCategoryByID[i].variant.length;
          k++
        ) {
          for (var l = 0; l < cartData.length; l++) {
            if (
              cartData[l].variant_id ===
              this.searchProductByCategoryByID[i].variant[k].id
            ) {
              this.searchProductByCategoryByID[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }
    if (this.businessCategoryProducts != undefined) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        this.businessCategoryProducts[i].count = 0;
        for (
          var k = 0;
          k < this.businessCategoryProducts[i].variant.length;
          k++
        ) {
          for (var l = 0; l < cartData.length; l++) {
            if (
              cartData[l].variant_id ===
              this.businessCategoryProducts[i].variant[k].id
            ) {
              this.businessCategoryProducts[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }
    // if ($('#scrollId').is('visible')) {
    for (var i = 0; i < this.businessDetailProducts.length; i++) {
      for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
        this.businessDetailProducts[i].products[j].count = 0;
        for (
          var k = 0;
          k < this.businessDetailProducts[i].products[j].variant.length;
          k++
        ) {
          for (var l = 0; l < cartData.length; l++) {
            if (
              cartData[l].variant_id ===
              this.businessDetailProducts[i].products[j].variant[k].id
            ) {
              this.businessDetailProducts[i].products[j].count +=
                cartData[l].quantity;
            }
          }
        }
      }
    }
    // }
  }
  existingModifierCheck(variantId, uniqueId) {
    const cartItem = JSON.parse(localStorage.getItem("cartItems"));
    let check = false;
    if (cartItem != undefined) {
      for (let i = 0; i < cartItem.length; i++) {
        if (
          cartItem[i].variant_id == variantId &&
          cartItem[i].uniqueId == uniqueId
        ) {
          cartItem[i].quantity = cartItem[i].quantity + 1;
          check = true;
        }
      }
    }
    this.events.publish("cartUpdate", cartItem);
    return check;
  }
  updateModifierPorduct(orderObj, variantId, index) {
    this.selectedVariant = index;
    if (orderObj.uniqueId != "") {
      this.oldUniqueId = orderObj.uniqueId;
    }
    this.dataService.getVariantDetail(orderObj.variant_id).subscribe(
      (res) => {
        // console.log(res);
        let modifierObj = {
          quantityTotal: 1,
          name: orderObj.product_name,
          image: orderObj.product_image,
          variantName: orderObj.variant_name,
          optionValue: orderObj.variant_value,
          price: Math.round(orderObj.price),
          compare_price: Math.round(orderObj.compare_price),
          is_on_sale: orderObj.is_on_sale,
          variant_id: orderObj.variant_id,
          modifiers: res["modifiers"],
          addons: res["addons"],
          noOption: res["no_options"],
          error_msg: "",
        };
        this.productModifierDataArray = new Array();
        this.productModifierDataArray.push(modifierObj);
        this.newCart = this.modifierProductList;
        for (
          let i = 0;
          i < this.productModifierDataArray[0].modifiers.length;
          i++
        ) {
          for (
            let j = 0;
            j < this.productModifierDataArray[0].modifiers[i].options.length;
            j++
          ) {
            orderObj["modifiers"].forEach((orderElement) => {
              if (
                this.productModifierDataArray[0].modifiers[i].options[j].id ==
                orderElement.options.id
              ) {
                this.productModifierDataArray[0].modifiers[i].options[
                  j
                ].selected = true;
              }
            });
          }
        }

        if (
          orderObj["addons"].length > 0 &&
          orderObj["addons"] != undefined &&
          orderObj["addons"] != null
        ) {
          for (
            let i = 0;
            i < this.productModifierDataArray[0].addons.options.length;
            i++
          ) {
            orderObj["addons"].forEach((orderElement) => {
              if (
                this.productModifierDataArray[0].addons.options[i].id ==
                orderElement.id
              ) {
                this.productModifierDataArray[0].addons.options[
                  i
                ].selected = true;
              }
            });
          }
        }

        if (
          this.productModifierDataArray[0].noOption != null &&
          this.productModifierDataArray[0].noOption != undefined &&
          this.productModifierDataArray[0].noOption.options != undefined &&
          this.productModifierDataArray[0].noOption.options.length > 0
        ) {
          for (
            let i = 0;
            i < this.productModifierDataArray[0].noOption.options.length;
            i++
          ) {
            this.productModifierDataArray[0].noOption.options[
              i
            ].selected = true;
          }
          console.log(this.productModifierDataArray[0].noOption);
        }

        if (
          orderObj["noOption"].length > 0 &&
          orderObj["noOption"] != undefined &&
          orderObj["noOption"] != null
        ) {
          for (let j = 0; j < orderObj["noOption"].length; j++) {
            for (
              let i = 0;
              i < this.productModifierDataArray[0].noOption.options.length;
              i++
            ) {
              if (
                this.productModifierDataArray[0].noOption.options[i].id ==
                orderObj["noOption"][j].id
              ) {
                this.productModifierDataArray[0].noOption.options[
                  i
                ].selected = false;
                break;
              }
            }
          }
        }
        console.log(this.productModifierDataArray);
        console.log(this.modifierProductList);
        this.totalPriceCalculation();
        $("#addVariantsModifiersPop").modal("show");
        $("#addVariantsModifiersPop").css("overflow-y", "auto");
      },
      (err) => {
        console.log(err);
      }
    );
  }
  async modifierQuantityPlus(index, quantity, obj) {
    this.cartDataFromStorage = JSON.parse(localStorage.getItem("cartItems"));
    // this.cartFinal[index].quantity = quantity + 1;
    this.modifierProductList[index].quantity = quantity + 1;
    for (let i = 0; i < this.cartDataFromStorage.length; i++) {
      if (
        this.businessId === this.cartDataFromStorage[i].business_id &&
        this.cartPopData.id === this.cartDataFromStorage[i].product_id &&
        obj.variant_id === this.cartDataFromStorage[i].variant_id &&
        obj.uniqueId == this.cartDataFromStorage[i].uniqueId
      ) {
        this.cartDataFromStorage[i].quantity = this.modifierProductList[
          index
        ].quantity;
        this.events.publish("cartUpdate", this.cartDataFromStorage);
      }
    }
    const cartData = JSON.parse(localStorage.getItem("cartItems"));

    if (this.searchProductByCategoryByID) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        this.searchProductByCategoryByID[i].count = 0;
        for (
          var k = 0;
          k < this.searchProductByCategoryByID[i].variant.length;
          k++
        ) {
          for (var l = 0; l < cartData.length; l++) {
            if (
              cartData[l].variant_id ===
              this.searchProductByCategoryByID[i].variant[k].id
            ) {
              this.searchProductByCategoryByID[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    if (this.businessCategoryProducts) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        this.businessCategoryProducts[i].count = 0;
        for (
          var k = 0;
          k < this.businessCategoryProducts[i].variant.length;
          k++
        ) {
          for (var l = 0; l < cartData.length; l++) {
            if (
              cartData[l].variant_id ===
              this.businessCategoryProducts[i].variant[k].id
            ) {
              this.businessCategoryProducts[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    // if ($('#scrollId').is('visible')) {
    for (var i = 0; i < this.businessDetailProducts.length; i++) {
      for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
        this.businessDetailProducts[i].products[j].count = 0;
        for (
          var k = 0;
          k < this.businessDetailProducts[i].products[j].variant.length;
          k++
        ) {
          for (var l = 0; l < cartData.length; l++) {
            if (
              cartData[l].variant_id ===
              this.businessDetailProducts[i].products[j].variant[k].id
            ) {
              this.businessDetailProducts[i].products[j].count +=
                cartData[l].quantity;
            }
          }
        }
      }
    }
    // }
  }
  async modifierQuantityMinus(index, quantity, obj) {
    this.cartDataFromStorage = JSON.parse(localStorage.getItem("cartItems"));
    // this.cartFinal[index].quantity = quantity - 1;
    this.modifierProductList[index].quantity = quantity - 1;
    for (let i = 0; i < this.cartDataFromStorage.length; i++) {
      if (
        this.businessId === this.cartDataFromStorage[i].business_id &&
        this.cartPopData.id === this.cartDataFromStorage[i].product_id &&
        obj.variant_id === this.cartDataFromStorage[i].variant_id &&
        obj.uniqueId === this.cartDataFromStorage[i].uniqueId
      ) {
        if (this.modifierProductList[index].quantity < 1) {
          this.cartDataFromStorage.splice(i, 1);
        } else {
          this.cartDataFromStorage[i].quantity = this.modifierProductList[
            index
          ].quantity;
          // this.events.publish('cartUpdate', this.cartDataFromStorage);
        }
        this.events.publish("cartUpdate", this.cartDataFromStorage);
      }
    }
    if (this.modifierProductList[index].quantity < 1) {
      this.modifierProductList[index].quantity = 1;
      this.modifierProductList[index].addCartBtnShow = true;
    }

    const cartData = JSON.parse(localStorage.getItem("cartItems"));

    if (this.searchProductByCategoryByID) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        this.searchProductByCategoryByID[i].count = 0;
        for (
          var k = 0;
          k < this.searchProductByCategoryByID[i].variant.length;
          k++
        ) {
          for (var l = 0; l < cartData.length; l++) {
            if (
              cartData[l].variant_id ===
              this.searchProductByCategoryByID[i].variant[k].id
            ) {
              this.searchProductByCategoryByID[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    if (this.businessCategoryProducts) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        this.businessCategoryProducts[i].count = 0;
        for (
          var k = 0;
          k < this.businessCategoryProducts[i].variant.length;
          k++
        ) {
          for (var l = 0; l < cartData.length; l++) {
            if (
              cartData[l].variant_id ===
              this.businessCategoryProducts[i].variant[k].id
            ) {
              this.businessCategoryProducts[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    // if ($('#scrollId').is('visible')) {
    for (var i = 0; i < this.businessDetailProducts.length; i++) {
      for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
        this.businessDetailProducts[i].products[j].count = 0;
        for (
          var k = 0;
          k < this.businessDetailProducts[i].products[j].variant.length;
          k++
        ) {
          for (var l = 0; l < cartData.length; l++) {
            if (
              cartData[l].variant_id ===
              this.businessDetailProducts[i].products[j].variant[k].id
            ) {
              this.businessDetailProducts[i].products[j].count +=
                cartData[l].quantity;
            }
          }
        }
      }
    }
    // }
  }

  textUsOrderFunction() {
    setTimeout(() => {
      if (this.hasCustomOrder == false) {
        this.events.publish("textUsYourOrder", true);
      } else {
        this.events.publish("customOrder", true);
      }
    }, 500);
    $("#cartAvailablePopUp").modal("hide");
  }
}
