import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AsyncLocalStorageModule } from 'angular-async-local-storage';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { EventsModule } from 'angular4-events';
import { TimezonePickerModule } from 'ng2-timezone-selector';
import { MyDatePickerModule } from 'mydatepicker';
import { ShareButtonsModule } from 'ngx-sharebuttons';
import { ReCaptchaModule } from 'angular5-recaptcha';
import { CardModule } from 'ngx-card';
import { RatingModule } from 'ngx-rating';
import { EmojifyModule } from 'angular-emojify';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PhonePipe } from './phone-number.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule} from 'ng2-ckeditor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BusinessLandingComponent } from './business-landing/business-landing.component';
import { BusinessStoryComponent } from './business-story/business-story.component';
import { CartComponent } from './cart/cart.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DownloadQrComponent } from './download-qr/download-qr.component';
import { HeaderComponent } from './header/header.component';
import { HeaderLandingComponent } from './header-landing/header-landing.component';
import { HomeComponent } from './home/home.component';
import { NotefoundComponent } from './notefound/notefound.component';
import { PolicyComponent } from './policy/policy.component';
import { PolicyBusinessComponent } from './policy-business/policy-business.component';
import { SearchedBusinessesViewComponent } from './searched-businesses-view/searched-businesses-view.component';
import { SigninComponent } from './signin/signin.component';
import { StoryComponent } from './story/story.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { FooterLandingComponent } from './footer-landing/footer-landing.component';
import { FaqsComponent } from './faqs/faqs.component';
import { FaqsBusinessComponent } from './faqs-business/faqs-business.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TextUsOrderComponent } from './text-us-order/text-us-order.component';
import { FilterComponent } from './components/filter/filter.component';
import { CustomTitleCasePipe } from './pipes/custom-title-case.pipe';
import { AboutUsComponent } from './about-us/about-us.component';
import { SearchedBusinessProductsViewComponent } from './searched-business-products-view/searched-business-products-view.component';
import { MatTabsModule, MatTooltipModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { StoryListingComponent } from './story-listing/story-listing.component';
import { RecipeListingComponent } from './recipe-listing/recipe-listing.component';
import { RecipeDetailComponent } from './recipe-detail/recipe-detail.component';
import { RecipeDetailsComponent } from './recipe-details/recipe-details.component';
import { ReturnPolicyComponent } from './return-policy/return-policy.component';
import { DedicatedCategoryProductsComponent } from './dedicated-category-products/dedicated-category-products.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ContactUsBusinessComponent } from './contact-us-business/contact-us-business.component';
import { AboutUsBusinessComponent } from './about-us-business/about-us-business.component';
import { CareersComponent } from './careers/careers.component';
import { StoreLocationsComponent } from './store-locations/store-locations.component';
import { NgQrScannerModule } from 'angular2-qrscanner';
import { HoverBackgroundChangeDirective } from './directives/hover-background-change.directive';
import {MatNativeDateModule,MatRippleModule} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
@NgModule({
  declarations: [
    AppComponent,
    PhonePipe,
    BusinessLandingComponent,
    BusinessStoryComponent,
    CartComponent,
    ContactUsComponent,
    DownloadQrComponent,
    SearchedBusinessProductsViewComponent,
    HeaderComponent,
    HeaderLandingComponent,
    HomeComponent,
    NotefoundComponent,
    PolicyComponent,
    PolicyBusinessComponent,
    SearchedBusinessesViewComponent,
    SigninComponent,
    StoryComponent,
    TermsConditionsComponent,
    FooterLandingComponent,
    FaqsComponent,
    FaqsBusinessComponent,
    TextUsOrderComponent,
    FilterComponent,
    CustomTitleCasePipe,
    AboutUsComponent,
    StoryListingComponent,
    RecipeListingComponent,
    RecipeDetailComponent,
    RecipeDetailsComponent,
    ReturnPolicyComponent,
    DedicatedCategoryProductsComponent,
    SidebarComponent,
    ContactUsBusinessComponent,
    AboutUsBusinessComponent,
    CareersComponent,
    StoreLocationsComponent,
    HoverBackgroundChangeDirective,
  ],
  imports: [
    MatTabsModule,
    MatTooltipModule,
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    CarouselModule,
    ReactiveFormsModule,
    HttpClientModule,
    AsyncLocalStorageModule,
    // RouterModule.forRoot(appRoute),
    // SortablejsModule.forRoot({ animation: 150 }),
    AmazingTimePickerModule,
    EventsModule.forRoot(),
    TimezonePickerModule,
    MyDatePickerModule,
    // MetaModule.forRoot(metaConfig),
    ShareButtonsModule.forRoot(),
    ReCaptchaModule,
    CardModule,
    RatingModule,
    EmojifyModule,
    ImageCropperModule,
    CKEditorModule,
    BrowserAnimationsModule,
    NgQrScannerModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
  ],
  providers: [HoverBackgroundChangeDirective],
  bootstrap: [AppComponent]
})
export class AppModule { }
