import { Directive, ElementRef, Input, HostListener, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appHoverBackgroundChange]'
})
export class HoverBackgroundChangeDirective implements OnInit , OnChanges{

  @Input() backgroundColor: string = '#fff';
  @Input() outlineColor: string = '#fff';
  @Input() active: any = 'false';

  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {
    if(this.active == 'true') {
      this.onMouseOver();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.active = changes.active.currentValue;
    if(this.active == 'true') {
      this.onMouseOver();
    } else {
      this.onMouseOut()
    }
  }

  @HostListener('mouseover')
  onMouseOver() {
    this.elRef.nativeElement.style.backgroundColor = this.backgroundColor.includes('#') ? this.backgroundColor : "#" + this.backgroundColor;
    this.elRef.nativeElement.style.border = this.outlineColor.includes('#') ? "1px solid " + this.outlineColor : "1px solid #" + this.outlineColor
    this.elRef.nativeElement.style.fontWeight = "400";
    this.elRef.nativeElement.style.boxShadow = "0px 2px 3px #d3d3d3";
  }
  
  @HostListener('mouseout')
  onMouseOut() {
    if(this.active == 'false') {
      this.elRef.nativeElement.style.backgroundColor = "white";
      this.elRef.nativeElement.style.border = "1px solid lightgrey";
      this.elRef.nativeElement.style.fontWeight = "inherit";
      this.elRef.nativeElement.style.boxShadow = "unset";
    }
  }

}
