import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataControlService {

  mytokenVal = new BehaviorSubject<string>(localStorage.getItem('token'));
  getAddresses = new BehaviorSubject<boolean>(false);
  makePrimaryAddress = new BehaviorSubject<any>({sd:"ds"});
  setAddressOnHomePage = new BehaviorSubject<any>('');
  textUsOrderInvoke = new BehaviorSubject<boolean>(false);
  setAddressOnAddressInput = new BehaviorSubject<boolean>(true);
  getAddressesOnHome = new BehaviorSubject<boolean>(false);
  emptyCartOnAppComponent = new BehaviorSubject<boolean>(false);
  setHeaderAddressVisiblity = new BehaviorSubject<boolean>(true);
  setHeaderAddressCrossVisiblity = new BehaviorSubject<boolean>(true);
  invokeBusinessProducts = new BehaviorSubject<any>({id: '', value: '', search: ''});
  setDefaultValuesInFilterComp = new BehaviorSubject<boolean>(false);
  setDefaultValuesInFilterProductComp = new BehaviorSubject<boolean>(false);
  setDefaultValuesInFilterProductCompOnMoreProducts = new BehaviorSubject<boolean>(false);
  getproductDetailOnProductPage = new BehaviorSubject<any>(false);
  getMoreproductDetailOnProductPage = new BehaviorSubject<any>(false);
  submitFilteredData = new BehaviorSubject<any>(false);
  filterSearchString = new BehaviorSubject<any>('');
  filterMinVal = new BehaviorSubject<any>(undefined);
  filterMaxVal = new BehaviorSubject<any>(undefined);
  isOnBusinessDetailPage = new BehaviorSubject<boolean>(false);
  setValuesinBusinessDetailPage = new BehaviorSubject<any>(false);
  inBusinessesPage = new BehaviorSubject<any>(false);
  closeFilterSearch = new BehaviorSubject<any>(false);
  emitFilterValues = new BehaviorSubject<any>(false);
  expandPrefClicked = new BehaviorSubject<any>(false);
  isSignInWishlistClicked = new BehaviorSubject<any>(false);
  resetFilterWithoutAPI = new BehaviorSubject<any>(false);
  getAppBtnOnApp = new BehaviorSubject<boolean>(true);
  isSeeAllProducts = new BehaviorSubject<boolean>(false);
  isBusinessLanding = new BehaviorSubject<boolean>(false);
  isBusinessLandingPage = new BehaviorSubject<boolean>(true);
  headerSignInBtn = new BehaviorSubject<boolean>(false);
  showBanner$ = new BehaviorSubject<boolean>(false);
  prescriptionReport$ = new BehaviorSubject<any>({});
  constructor() { }
}
