
import { Component, OnInit, HostListener,ViewChild } from '@angular/core';
import { DataService } from '../data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { EventsService } from 'angular4-events';
import { Constants } from '../constants';
import { Meta, Title } from '@angular/platform-browser';
import { DataControlService } from '../data-control.service';

import { MatTabGroup } from '@angular/material';


declare var $: any;
declare var Nanobar: any;
declare var toastr: any;
declare var moment: any;
declare var scrollEvent: any;
declare var require: any;

@Component({
  selector: 'app-searched-businesses-view',
  templateUrl: './searched-businesses-view.component.html',
  styleUrls: ['./searched-businesses-view.component.css']
})
export class SearchedBusinessesViewComponent implements OnInit {

  mytabActiveColor = Constants.colorCode;
  mytabBackColor = 'white';
  @ViewChild(MatTabGroup,{static: false}) tabGroup: MatTabGroup;
  categorySectionOptions: any = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 750,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 2,
        stagePadding: 20
      },
      300: {
        items: 2,
        stagePadding: 20
      },
      400: {
        items: 3,
        stagePadding: 40
      },
      500: {
        items: 3,
        stagePadding: 50
      },
      740: {
        items: 5,
        stagePadding: 100
      },
      940: {
        items: 5,
        stagePadding: 150
      }
    },
    nav: false,
  }
  details: any;
  owl2: any;
  priceIconForSingle = Constants.iconForSinglePrice;
  priceIconForDouble = Constants.iconForDoublePrice;
  nao = new Nanobar();
  id: any;
  private sub: any;
  businessData: any;
  business_category_list: any=[];
  businessDataMore: any;
  businessDetailData: any;
  businessDetalSuccess: any;
  cityName: any;
  businessDetailProducts: any;
  businessDetailProductsPaginate: any;
  businessDetailProductsPaginateitems: any;
  detailProductSuccess: any;
  businessDetailProductsitems: any;
  loadingData = true;
  loadingDataMore = true;
  detailDataLoad = false;
  productDetailData: any;
  productSuccess: any;
  cartPopData: any;
  variantData: any;
  businessId: any;
  cartFinal = new Array();
  cartDataFromStorage = new Array();
  businessName: any;
  business_image: any;
  business_delivery_charges: any;
  business_platform_fee: any;
  business_total_charges: any;
  searchString = '';
  clearTimeout: any;
  searchedData1: any = [];
  searchedData: any = [];
  searchItem = false;
  pushReceive: any;
  gmt_date: any;
  gmt_date2: any;
  staticDate = '2018/05/16';
  dateObjOpen: any;
  dateObjClose: any;
  zoneOperator: any;
  milliseconds: any;
  seconds: any;
  minutes: any;
  hours: any;
  currentSystemTime: any;
  businessStatus: any;
  openOrCloseIn: any;
  outletAddress: any;
  key: any;
  businessRaiting: any;
  cartPush: any;
  getCartItems1: any;
  appToken: any;
  checkappToken: any;
  businessRaitingArray = new Array();
  businessCatLoding = true;
  clearTimeout1: any;
  clearTimeoutSearchBusiness: any;
  cartChngeFromCheckout: any;
  addressUpdate: any;
  payment_through_card: any;
  payment_through_cash: any;
  payment_over_due: any;
  finalDisplayTime: any;
  objToOveride: any;
  indexToOveride: any;
  disableMultiClick = false;
  fullDayStatus: any;
  businessName2: any;
  ReviewData: any;
  ReviewDataByPage: any;
  pendingRating: any;
  ratingData: any;
  dispensdispensaryName: any;
  driverData: any;
  driverFirstName: any;
  driverLastName: any;
  feedBack = '';
  rating_id: any;
  dispensary_rating_id: any;
  driverRatingValue = 0;
  businessRatingValue = 0;

  sendRatingData: any;
  sendRatingSuccess: any;

  cancelRatingData: any;
  cancelRatingSuccess: any;
  cancelRatingValue = 0;
  businessRaiting2 = 5;
  businessRaitingArray2 = [];

  taxOfBusiness: any;
  priceUpdateFromCheckoutVal: any;
  priceUpdateFromCheckout: any;
  reviewLastPage = true;
  reviewBusinessId: any;
  reviewLastPageCount = 0;
  storyData: any;
  storylimit = 3;
  addWishlistSuccess: any;
  islogin: any;
  isloginValue: any;
  businessDetailProductsUpdate: any;
  websiteUrl = Constants.websiteUrl;
  searchStringMore = '';
  searchStringMoreOld = '';
  businessDetailProductSearchString = '';
  businessDataMoreName: any;
  clearTimeoutProduct: any;
  clearTimeoutProductWhenEmpty: any;
  todayData1 = new Date();
  todayDateVal = this.todayData1.getDay();
  timeObj = [];
  loadingDataCat = true;
  cat_id: any;
  cat_name = '';
  // cat_name: any;
  businessCategoryProducts: any;
  businessCategoryProductsBypage: any;
  searchItemCat = false;
  searchStringCat = '';
  windowWidth: any;
  widthOfWindow: any;
  lastPage = true;
  lastPageOfCategory = true;
  PagnationPage = 0;
  PaginationPageByCategory = 0;
  limit = 6;
  loadPreviousProductsData: any;
  loadPreviousProductsDataCount = 0;
  loadPreviousProductsDataLastPage = true;
  scrolTop: any;
  scrollPosition: any;
  scrollPositionOfCategory: any;
  scrollHeightOfCategory: any;
  getProductsViaCategoryLimit = 50;
  img: any;
  serchstring1: any;
  cat_ID: any;
  searchProductByCategoryByID: any;
  inputSearch = true;
  conditionToCheck = true;
  conditionToCheckforfun = true;
  seeAllImageNotToShow = false;
  scrollHeight: any;
  limitForSearch = 25;
  PaginationPageForSearch = 0;
  searchProductByCategoryByIDLastPage = true;
  searchProductByCategoryByIDByPage: any;
  loaderCondition = false;
  SeeAllLoader = false;
  dropzone: any;
  customProductImages = new Array();
  customOrderText = '<ul><li></li></ul>';
  customOrderSuccess: any;
  customOrderImageSuccess: any;
  addCustomOrderBtn = false;
  customProductImagesCount = 0;
  customOrderImageSuccessUrl = new Array();
  showBusinessProductsliderLoading = false;
  catList: any;
  subCatData: any;
  subCatLength = 0;
  subCatid = -1;
  // subCatslider = false;
  hell = false;
  productDetailDataImagesLength: any;
  businessDetailSearch: any;
  businessProductData = false;
  func = false;
  Name: any;
  subcatLoadingShimmer = false;
  businessCategoryProductslength = false;
  businessAbout = '';
  businessSeoData: any;
  businessRouterAlias = '';
  businessRouterProduct = '';


  filteredData = {
    price_range: {
      min_price: 'any',
      max_price: 'any',
    },
    offers:{
      free_delivery: false,
      online_payment: false
    },
    sort: {
      near_by: false,
      name_A_to_Z: false,
      recent_updated: false,
      most_popular: false
    },
    search: ''
  }

  // filteredDataViewAll = {
  //   price_range: {
  //     min_price: 'any',
  //     max_price: [10, 20, 50, 100,500, 1000, 2000, 3000, 4000, 5000, 10000, 50000, 100000],
  //   },
  //   search: ''
  // }
  viewAllSearchResults: any;
  mySelectedCityName: any;
  noticeMessage: any;
  webNotice: any;
  showNoticeWeb: any;
  noticeSuccess: any;
  showBanner: Boolean = false;
  catId: any;
  userCurrentAddress: any;

  constructor(
    private dataControlService: DataControlService,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private events: EventsService,
    private activatedRoute: ActivatedRoute,
    private meta: Meta,
    private title: Title) {
    // $(window).on('popstate', function () {
      if(window.innerWidth>768 ){
        $('.modal').modal('hide');
        $('.modal-backdrop').remove();
        $('.in').remove();
      }

    // });
    let data = [];
    data['business_detail'] = {
      'has_custom_order_form': false,
      'custom_order_title': '',
      'id': -1
    };

    this.events.publish('businessDetailData', data);
    localStorage.setItem('businessDetailData', JSON.stringify(this.businessDetailData));

    this.dataService.pageName = 'businesses';
    this.myCurrentCategoryId = 1;
    this.sub = this.route.params.subscribe(params => {
      this.id = params['cityname'];
      this.searchItem = false;
      this.dataControlService.resetFilterWithoutAPI.next(true);
      this.dataControlService.expandPrefClicked.next(false);

      this.mySelectedCityName = params['cityname'];
      if(this.id !== ''){
        // this.getBusinessWithCatId();
        this.getBusinessCategories();
      }
      else{
        localStorage.setItem('myDeliveryAddress', "Please Select your location");
        localStorage.setItem('delivery_address', "Please Select your location");
        localStorage.setItem('profileAddress', JSON.stringify(null));
        localStorage.setItem('profileAddressCheckout', JSON.stringify(null));
      }
      this.getBusinessSeoData();

    });
    this.sub = this.route.params.subscribe(params => {
      var business = params['business'];
      this.businessRouterAlias = '';
      if (business != undefined) {
        if (business.length > 0) {
          this.businessRouterAlias = business;
          // this.dataService.BusinessPageActiveRouter = 'business';
          this.getBusinessDetail(business, 'default', '');
          this.getBusinessDetailProducts(business);
          this.CategoryListing();
          this.disableBackground();
        }
      }
    });

    this.sub = this.route.params.subscribe(params => {
      var product = params['product'];
      this.businessRouterProduct = '';
      if (product != undefined) {

        if (product.length > 0) {
          product = product.split('-', 1);
          product = product[0];
          this.businessRouterProduct = product;
          // this.dataService.BusinessPageActiveRouter = 'product';
          this.getproductDetail(product, 'working');
        }
      }
    });

    // if ( this.dataService.BusinessPageActiveRouter === 'businesses' ) {
    this.getBusinessSeoData();
    // }
    this.dataService.userFrom = 'web';
    this.dataService.businessIdForIndependentBusinessData = -1;
    this.isloginValue = this.events.subscribe('inlogin').subscribe((data) => {
      setTimeout(() => {
        this.islogin = data;
        if (this.islogin === 'true') {
          // this.updateBusinessDetailProducts1();
          // if ( $('#SeeMorePopup').is(':visible') ) {
          //   this.updateBusinessCategoryProducts();
          // }
        }
        if (this.islogin === 'false') {
          if(this.businessDetailProducts != undefined){
            for (var i = 0; i < this.businessDetailProducts.length; i++) {
              for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
                this.businessDetailProducts[i].products[j].is_wish_list = false;
              }
            }
          }

        }
      }, 500);
    });
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.key = params['key'];
      this.appToken = params['s'];
      this.checkappToken = this.appToken;
      if (this.key !== undefined) {
        setTimeout(() => {
          // this.getBusinessWithCatId();
          this.getBusinessSeoData();
          this.getBusinessDetail(this.key, 'default', '');
          this.getBusinessDetailProducts(this.key);
          if (this.appToken !== undefined && this.appToken !== '') {
            window.localStorage.setItem('token', this.appToken);
            this.events.publish('userFromMob', 'true');
          }
        }, 2);
      }
    });
    this.pushReceive = this.events.subscribe('dataChange').subscribe((data) => {
      this.id = data;
      if (data !== '' || data !== null || data !== undefined) {
        this.loadingData = true;
        // this.getBusinessWithCatId();
        this.getBusinessSeoData();
      }
    });


    this.cartPush = this.events.subscribe('checkoutDone').subscribe((data) => {
      setTimeout(() => {
        for (var i = 0; i < this.businessDetailProducts.length; i++) {
          for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
            for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
              this.businessDetailProducts[i].products[j].count = 0;
            }
          }
        }
      }, 2000);
    });

    this.cartChngeFromCheckout = this.events.subscribe('cartUpdate').subscribe((data) => {
      setTimeout(() => {
        this.cartChngeFromCheckoutDone();
      }, 1000);
    });

    this.addressUpdate = this.events.subscribe('addressUpdate').subscribe((data) => {
      if(data == 'true') {
        this.getBusinessCategories();
      }
    });

    // this.priceUpdateFromCheckoutVal = this.events.subscribe('priceUpdateFromCheckout').subscribe((data) => {
    //   setTimeout(() => {
    //     this.priceUpdateFromCheckout = data;
    //     this.updateBusinessData();
    //   }, 500);
    // });

  }

  isFilterShown:any = true;
  reInit() {
    this.isFilterShown = false;
    setTimeout(() => {
      this.isFilterShown = true;
    }, 10);
  }
  updateBusinessData() {
    if ($('#businessDetail').is(':visible')) {
      this.getBusinessDetail(this.businessId, 'default', '');
      this.getBusinessDetailProductsCall(this.businessId);
    }
  }

  businessRating(index) {

    this.businessRatingValue = index + 1;
    for (let i = 0; i < this.businessRaiting2; i++) {
      $('#rating' + i).addClass('zero-rating');
    }
    for (let i = 0; i <= index; i++) {
      $('#businessrating' + i).removeClass('zero-rating');
      $('#businessrating' + i).addClass('full-rating');
    }
  }

  driverRating(index) {
    this.driverRatingValue = index + 1;
    for (let i = 0; i < this.businessRaiting2; i++) {
      $('#rating' + i).addClass('zero-rating');
    }
    for (let i = 0; i <= index; i++) {
      $('#driverrating' + i).removeClass('zero-rating');
      $('#driverrating' + i).addClass('full-rating');
    }
  }

  async cartChngeFromCheckoutDone() {
    const cartData = JSON.parse(localStorage.getItem('cartItems'));

    if (cartData !== null) {
      if (this.businessCatLoding == false && this.businessDetailProductSearchString == '') {
        for (var i = 0; i < this.businessDetailProducts.length; i++) {
          for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
            this.businessDetailProducts[i].products[j].count = 0;
            for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
              for (var l = 0; l < cartData.length; l++) {
                if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                  this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                }
              }
            }
          }
        }
      }
    }

    if (this.businessCatLoding == false && this.businessDetailProductSearchString != '') {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        this.searchProductByCategoryByID[i].count = 0;
        for (var k = 0; k < this.searchProductByCategoryByID[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.searchProductByCategoryByID[i].variant[k].id) {
              this.searchProductByCategoryByID[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    if (this.searchItemCat === true && this.loadingDataCat === false) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        this.businessCategoryProducts[i].count = 0;
        for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
              this.businessCategoryProducts[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    if (this.searchItemCat === false && this.loadingDataCat === false) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        this.businessCategoryProducts[i].count = 0;
        for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
              this.businessCategoryProducts[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }


    if ($('#businessAll').is(':visible')) {

      this.cartFinal = [];
      this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
      for (let i = 0; i < this.variantData.length; i++) {
        if (this.cartDataFromStorage !== null && this.checkObject(this.cartDataFromStorage, this.variantData[i]) === true) {
          for (let j = 0; j < this.cartDataFromStorage.length; j++) {
            if (this.businessId === this.cartDataFromStorage[j].business_id && this.cartPopData.id === this.cartDataFromStorage[j].product_id && this.variantData[i].id === this.cartDataFromStorage[j].variant_id) {
              window.localStorage.setItem('businessName', this.businessName);
              this.cartFinal.push({
                business_image: this.business_image,
                business_name: this.businessName,
                business_id: this.businessId,
                business_delivery_charges: Math.round(this.business_delivery_charges),
                business_platform_fee: Math.round(this.business_platform_fee),
                business_total_charges: Math.round(this.business_total_charges),
                business_tax: this.taxOfBusiness,
                product_id: this.cartPopData.id,
                product_name: this.variantData[i].product_name,
                product_image: this.variantData[i].image,
                variant_id: this.variantData[i].id,
                variant_name: this.variantData[i].option_name,
                variant_value: this.variantData[i].option_value,
                price: Math.round(this.variantData[i].price),
                sale_price: Math.round(this.variantData[i].sale_price),
                is_on_sale: this.variantData[i].is_on_sale,
                quantity: this.cartDataFromStorage[j].quantity,
                addCartBtnShow: false,
                outlet_address: this.outletAddress,
              });
            }
          }
        } else {
          this.cartFinal.push({
            business_image: this.business_image,
            business_name: this.businessName,
            business_id: this.businessId,
            business_delivery_charges: Math.round(this.business_delivery_charges),
            business_platform_fee: Math.round(this.business_platform_fee),
            business_total_charges: Math.round(this.business_total_charges),
            business_tax: this.taxOfBusiness,
            product_id: this.cartPopData.id,
            product_name: this.variantData[i].product_name,
            product_image: this.variantData[i].image,
            variant_id: this.variantData[i].id,
            variant_name: this.variantData[i].option_name,
            variant_value: this.variantData[i].option_value,
            price: Math.round(this.variantData[i].price),
            sale_price: Math.round(this.variantData[i].sale_price),
            is_on_sale: this.variantData[i].is_on_sale,
            quantity: 1,
            addCartBtnShow: true,
            outlet_address: this.outletAddress,
          });
        }
      }
    }


  }
  tempDeliveryAddr: string;
  profileAddress:any;


  getBusinessSeoData() {
    this.dataService.getBusinessSeo(this.id).subscribe(
      (data) => {
        this.businessSeoData = data;
        if (this.businessSeoData.seo_title !== '') {
          this.setMetaTags(this.businessSeoData.seo_title, this.businessSeoData.image, this.businessSeoData.seo_description, this.businessSeoData.seo_keyword);
        }
      },
      (error) => {
      }
    );
  }

  setMetaTags(title, image, description, keyword) {
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:image', content: image });
    //this.meta.updateTag({ property: 'og:url', content: this.websiteUrl + this.router.url });
    this.meta.updateTag({ property: 'description', content: description });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ property: 'keywords', content: keyword });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.title.setTitle(title);

    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ name: 'twitter:title', content: title });
    this.meta.updateTag({ name: 'twitter:description', content: description });
    this.meta.updateTag({ name: 'twitter:image', content: image });
  }
  getAllBusinessCategory(name) {
    this.businessDataMoreName = name;
    this.searchStringMore = '';
    this.openAllBusinessPopup();
    $('#businessSeeAll').modal('show');
  }

  openAllBusinessPopup() {
    this.businessDataMore = [];
    this.loadingDataMore = true;
    this.nao.go(30);
    this.searchStringMore = this.searchStringMore.trim();
    this.searchStringMoreOld = this.searchStringMore;
    this.dataService.getBusinessesWithCategoryId(this.id, this.businessDataMoreName, this.searchStringMore).subscribe(
      (data) => {
        this.nao.go(100);
        this.businessDataMore = data;
        clearInterval(this.clearTimeoutSearchBusiness);
        this.businessDataMore = this.businessDataMore.business_category_list;
        if (this.businessDataMore.length > 0) {
          this.businessDataMore = this.businessDataMore[0].items;
        }
        this.loadingDataMore = false;
      },
      (error) => {
        clearInterval(this.clearTimeoutSearchBusiness);
        this.nao.go(100);
      }
    );
  }

  // all products on category base
  getSeeMprePopup(cat_id, catname) {
    this.searchStringCat = '';
    this.cat_id = cat_id;
    this.cat_name = catname;
    $('#SeeMorePopup').modal('show');
    this.loadingDataCat = true;
    // this.subCatslider = true;
    $('#subCategorySlider').css('visibility', 'hidden');
    this.subCategoryListing();
  }


  getBusinessCategoryProductsByPage() {
    clearInterval(this.clearTimeout1);
    // $('.sliderList').slick('unslick');
    this.businessCategoryProductsBypage = [];
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    this.SeeAllLoader = false;
    this.PaginationPageByCategory++;
    this.dataService.businessCategorySearchProduct(this.businessId, this.getProductsViaCategoryLimit, this.PaginationPageByCategory, this.cat_id, this.searchStringCat, this.subCatid, token, -1, -1).subscribe(
      (data) => {
        this.SeeAllLoader = false;
        this.businessCategoryProductsBypage = data;
        this.lastPageOfCategory = this.businessCategoryProductsBypage.last_page;
        // this.nao.go(100);
        this.loadingDataCat = false;
        this.detailProductSuccess = this.businessCategoryProductsBypage.success;
        this.businessCategoryProductsBypage = this.businessCategoryProductsBypage.items;
        if (cartData !== null) {
          for (var i = 0; i < this.businessCategoryProducts.length; i++) {
            this.businessCategoryProducts[i].count = 0;
            for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
              for (var l = 0; l < cartData.length; l++) {
                if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
                  this.businessCategoryProducts[i].count += cartData[l].quantity;
                }
              }
            }
          }
        }
        for (var k = 0; k < this.businessCategoryProductsBypage.length; k++) {
          this.businessCategoryProducts.push(this.businessCategoryProductsBypage[k]);
        }
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }


  expandPrefClicked(){
    this.dataControlService.expandPrefClicked.next(true);
  }


  longitudeForFilter:any;
  latitudeForFilter:any;
  sendToApi(event) {
    this.loadingData = true;
    this.searchString = "";
    this.businessCategoryPageNo = 0;
    this.lastPageOfBusinessCategory = true;

    this.businessCategoryProductslength = false;
    this.PaginationPageByCategory = 0;
    clearInterval(this.clearTimeout1);
    // $('.sliderList').slick('unslick');
    this.businessCategoryProducts = [];
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    this.searchStringCat = event.search;
    if(this.searchStringCat !== ''){
    this.nao.go(30);
    }

    let sendfilteredData = {
      price_range_min: -1,
      price_range_max: -1,
      filter_type: -1,
      free_delivery: false,
      online_payment: false
    };
    if(event.price_range.min_price == 'any') {
      sendfilteredData['price_range_min'] = -1;
    } else {
      sendfilteredData['price_range_min'] = event.price_range.min_price;
    }

    if(event.price_range.max_price == 'any') {
      sendfilteredData['price_range_max'] = -1;
    } else {
      sendfilteredData['price_range_max'] = event.price_range.max_price;
    }

    sendfilteredData['filter_type'] = -1;
    sendfilteredData['free_delivery'] = event.offers.free_delivery;
    sendfilteredData['online_payment'] = event.offers.online_payment;
    this.prevSearchString = this.searchStringCat;


    let lat = '';
    let long = '';
    let selectedLat = '';
    let selectedLong = '';
    if(event.sort.near_by) {
      sendfilteredData['filter_type'] = 0;
    }

    if(event.sort.name_A_to_Z) {
      sendfilteredData['filter_type'] = 1;
    }

    if(event.sort.recent_updated) {
      sendfilteredData['filter_type'] = 2;
    }

    if(event.sort.most_popular) {
      sendfilteredData['filter_type'] = 3;
    }

    if(sendfilteredData['filter_type'] == 0) {
      const contex = this;
      const profileData = JSON.parse(localStorage.getItem('profileAddress'));
      console.log(profileData);
      lat = this.latitudeForFilter;
      long = this.longitudeForFilter;
      selectedLat = profileData.latitude;
      selectedLong = profileData.longitude;
      if(profileData == null) {
        this.dataService.getBusinessCategoryOutlet(localStorage.getItem('token'), this.businessCategoryPageNo, this.businessCategoryPageLimit, this.id, this.myCurrentCategoryId,this.searchString , -1, lat, long, selectedLat, selectedLong, -1, -1, false, false).subscribe(
          (data: any) => {
            this.nao.go(100);
            this.myCategoryBusinesses = data;
            this.lastPageOfBusinessCategory = this.myCategoryBusinesses.last_page;
            this.business_category_list = this.myCategoryBusinesses.items;
            this.searchItem = true;
            this.searchedData1 = data.items;
            this.loadingData = false;
          },
          (error) => {
            this.loadingData = false;
          }
        );
      } else {
        this.longitudeForFilter = profileData.longitude;
        this.latitudeForFilter = profileData.latitude;;
        lat = this.latitudeForFilter;
        long = this.longitudeForFilter;
        selectedLat = profileData.latitude;
        selectedLong = profileData.longitude;
        this.dataService.getBusinessCategoryOutlet(localStorage.getItem('token'), this.businessCategoryPageNo, this.businessCategoryPageLimit, this.id, this.myCurrentCategoryId, this.searchStringCat , sendfilteredData.filter_type, lat, long, selectedLat, selectedLong, sendfilteredData.price_range_min, sendfilteredData.price_range_max, sendfilteredData.free_delivery, sendfilteredData.online_payment).subscribe(
          (data: any) => {
            this.nao.go(100);
            this.myCategoryBusinesses = data;
            this.lastPageOfBusinessCategory = this.myCategoryBusinesses.last_page;
            this.business_category_list = this.myCategoryBusinesses.items;
            this.searchItem = true;
            this.searchedData1 = data.items;
            this.loadingData = false;
          },
          (error) => {
            this.loadingData = false;
          }
        );
      }
    }
    else{
      lat = undefined;
      long = undefined;
      selectedLong = undefined;
      selectedLat = undefined;
    }
    if(lat == undefined && long == undefined){
      const profileData = JSON.parse(localStorage.getItem('profileAddress'));
      if(profileData != null) {
        if(profileData.latitude != undefined) {
          selectedLat = profileData.latitude;
        }
        if(profileData.longitude != undefined) {
          selectedLong = profileData.longitude;
        }
        if(profileData.latitude != undefined) {
          lat = profileData.latitude;
        }
        if(profileData.longitude != undefined) {
          long = profileData.longitude;
        }
      }
      this.dataService.getBusinessCategoryOutlet(localStorage.getItem('token'), this.businessCategoryPageNo, this.businessCategoryPageLimit, this.id, this.myCurrentCategoryId, this.searchStringCat , sendfilteredData.filter_type, lat, long, selectedLat, selectedLong, sendfilteredData.price_range_min, sendfilteredData.price_range_max, sendfilteredData.free_delivery, sendfilteredData.online_payment).subscribe(
      (data: any) => {

        this.nao.go(100);
        this.myCategoryBusinesses = data;
        this.lastPageOfBusinessCategory = this.myCategoryBusinesses.last_page;
        this.business_category_list = this.myCategoryBusinesses.items;

        if(this.searchStringCat == '' && sendfilteredData.filter_type == -1 && sendfilteredData.price_range_min == -1 && sendfilteredData.price_range_max == -1 && sendfilteredData.free_delivery == false && sendfilteredData.online_payment == false){
          if(this.business_category_list.length == 0){
            // show onboard
            this.searchItem = false;
            this.notFoundMessageType = "on_board";
          }
          else{
            this.searchItem = true;

            this.notFoundMessageType = "productNotFound";
          }
        }
        else if(this.searchStringCat !== ''){
          this.notFoundMessageType = "productNotFound";
          this.searchItem = true;

        }
        else if(this.searchStringCat == '' && (sendfilteredData.filter_type !== -1 || sendfilteredData.price_range_min !== -1 || sendfilteredData.price_range_max !== -1 || sendfilteredData.free_delivery !== false || sendfilteredData.online_payment !== false)){
          if(this.business_category_list.length == 0){
            // show message "No business matched the selected criteria"
            this.notFoundMessageType = "No_business_matched";
            this.searchItem = true;

          }

        }


        this.searchedData1 = data.items;
        this.loadingData = false;
      },
      (error) => {
        this.loadingData = false;
      });
    }
  }

  notFoundMessageType:any='';

  getBusinessCategoryProducts() {
    this.businessCategoryProductslength = false;
    this.PaginationPageByCategory = 0;
    clearInterval(this.clearTimeout1);
    this.businessCategoryProducts = [];
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    if(this.viewAllSearchResults !== undefined) {
      if(this.viewAllSearchResults.price_range.min_price == 'any') {
        this.viewAllSearchResults.price_range.min_price = -1;
      } else {
        this.viewAllSearchResults.price_range.min_price = this.viewAllSearchResults.price_range.min_price;
      }

      if(this.viewAllSearchResults.price_range.max_price == 'any') {
        this.viewAllSearchResults.price_range.max_price = -1;
      } else {
        this.viewAllSearchResults.price_range.max_price = this.viewAllSearchResults.price_range.max_price;
      }
      this.dataService.businessCategorySearchProduct(this.businessId, this.getProductsViaCategoryLimit, this.PaginationPageByCategory, this.cat_id, this.searchStringCat, this.subCatid, token, this.viewAllSearchResults.price_range.min_price, this.viewAllSearchResults.price_range.max_price ).subscribe(
        (data) => {
          this.SeeAllLoader = false;
          this.subcatLoadingShimmer = false;
          this.businessCategoryProducts = data;
          this.lastPageOfCategory = this.businessCategoryProducts.last_page;
          this.detailProductSuccess = this.businessCategoryProducts.success;
          this.businessCategoryProducts = this.businessCategoryProducts.items;
          if (this.businessCategoryProducts.length === 0) {
            this.businessCategoryProductslength = true;
          }
          this.initSlider();
          if (cartData !== null) {
            for (var i = 0; i < this.businessCategoryProducts.length; i++) {
              this.businessCategoryProducts[i].count = 0;
              for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
                for (var l = 0; l < cartData.length; l++) {
                  if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
                    this.businessCategoryProducts[i].count += cartData[l].quantity;
                  }
                }
              }
            }
          }
        },
        (error) => {
          // this.nao.go(100);
        }
      );
    } else {
      this.dataService.businessCategorySearchProduct(this.businessId, this.getProductsViaCategoryLimit, this.PaginationPageByCategory, this.cat_id, this.searchStringCat, this.subCatid, token, -1, -1).subscribe(
        (data) => {
          this.SeeAllLoader = false;
          this.subcatLoadingShimmer = false;
          this.businessCategoryProducts = data;
          this.lastPageOfCategory = this.businessCategoryProducts.last_page;
          this.detailProductSuccess = this.businessCategoryProducts.success;
          this.businessCategoryProducts = this.businessCategoryProducts.items;
          if (this.businessCategoryProducts.length === 0) {
            this.businessCategoryProductslength = true;
          }
          this.initSlider();
          if (cartData !== null) {
            for (var i = 0; i < this.businessCategoryProducts.length; i++) {
              this.businessCategoryProducts[i].count = 0;
              for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
                for (var l = 0; l < cartData.length; l++) {
                  if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
                    this.businessCategoryProducts[i].count += cartData[l].quantity;
                  }
                }
              }
            }
          }
        },
        (error) => {
          // this.nao.go(100);
        }
      );
    }
  }


  searchValCat(val) {
    clearInterval(this.clearTimeout1);
    this.clearTimeout1 = setInterval(() => {
      this.searchStringCat = val.search;
      this.viewAllSearchResults = val;
      if (val === '') {
        this.SeeAllLoader = false;
        this.searchStringCat = '';
        this.loadingDataCat = true;
        this.subcatLoadingShimmer = true;
        this.getBusinessCategoryProducts();
        this.searchItemCat = false;
        // this.seeAllImageNotToShow = false;
      } else {
        this.SeeAllLoader = false;
        this.loadingDataCat = true;
        this.subcatLoadingShimmer = true;
        // this.businessCategorySearchProduct();
        this.getBusinessCategoryProducts();
        this.searchItemCat = true;

        // this.seeAllImageNotToShow = true;
      }
    }, 2000);
  }

  clearSearchCat() {
    this.SeeAllLoader = false;
    this.searchStringCat = '';
    this.subcatLoadingShimmer = true;
    this.getBusinessCategoryProducts();
    this.searchItemCat = false;
  }

  getBusinessCategorySearchProducts() {
    clearInterval(this.clearTimeout1);
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      // $('.sliderList').slick('unslick');
      const cartData = JSON.parse(localStorage.getItem('cartItems'));
      if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
        var token = '';
      } else {
        token = window.localStorage.getItem('token');
      }
      this.dataService.businessCategorySearchProducts(this.businessId, this.cat_id, this.searchStringCat, token).subscribe(
        (data) => {
          this.businessCategoryProducts = data;
          this.loadingDataCat = false;
          this.detailProductSuccess = this.businessCategoryProducts.success;
          this.businessCategoryProducts = this.businessCategoryProducts.products;
          for (var i = 0; i < this.businessCategoryProducts.length; i++) {
            for (var j = 0; j < this.businessCategoryProducts[i].products.length; j++) {
              for (var k = 0; k < this.businessCategoryProducts[i].products[j].variant.length; k++) {
                for (var l = 0; l < cartData.length; l++) {
                  if (cartData[l].variant_id === this.businessCategoryProducts[i].products[j].variant[k].id) {
                    this.businessCategoryProducts[i].products[j].count += cartData[l].quantity;
                  }
                }
              }
            }
          }
        },
        (error) => {
        }
      );
    }, 3000);
  }

  removeToWishlistCat(obj) {
    for (var i = 0; i < this.businessCategoryProducts.length; i++) {
      for (var j = 0; j < this.businessCategoryProducts[i].products.length; j++) {
        if (obj.id === this.businessCategoryProducts[i].products[j].id) {
          this.businessCategoryProducts[i].products[j].is_wish_list = false;
        }
      }
    }
    this.dataService.removeProductInWishList(window.localStorage.getItem('token'), obj.id).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }
        this.updateBusinessDetailProducts1();

      },
      (error) => {
      }
    );
  }

  addToWishlistCat(obj) {
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined || window.localStorage.getItem('token') === null) {
      this.events.publish('openLoginFunction', 'true');
      return;
    }
    for (var i = 0; i < this.businessCategoryProducts.length; i++) {
      for (var j = 0; j < this.businessCategoryProducts[i].products.length; j++) {
        if (obj.id === this.businessCategoryProducts[i].products[j].id) {
          this.businessCategoryProducts[i].products[j].is_wish_list = true;
        }
      }
    }
    this.dataService.addProductInWishList(window.localStorage.getItem('token'), obj.id, this.businessId).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }
        this.updateBusinessDetailProducts1();

      },
      (error) => {
      }
    );
  }

  removeToWishlistCatsearch(obj) {
    for (var i = 0; i < this.businessCategoryProducts.length; i++) {
      if (obj.id === this.businessCategoryProducts[i].id) {
        this.businessCategoryProducts[i].is_wish_list = false;
      }
    }

    this.dataService.removeProductInWishList(window.localStorage.getItem('token'), obj.id).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }
        this.updateBusinessDetailProducts1();

      },
      (error) => {
      }
    );
  }

  addToWishlistCatsearch(obj) {
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined || window.localStorage.getItem('token') === null) {
      this.addBlurSeeMorePopup();
      this.events.publish('openLoginFunction', 'true');
      return;
    }
    this.clearBlurSeeMorePopup();
    for (var i = 0; i < this.businessCategoryProducts.length; i++) {
      if (obj.id === this.businessCategoryProducts[i].id) {
        this.businessCategoryProducts[i].is_wish_list = true;
      }
    }

    this.dataService.addProductInWishList(window.localStorage.getItem('token'), obj.id, this.businessId).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }
        this.updateBusinessDetailProducts1();
      },
      (error) => {
      }
    );
  }

  // all products on category base End

  throttle(f, ) {
    var timer = null;
    return function () {
      var context = this, args = arguments;
      clearTimeout(timer);
      timer = window.setTimeout(function () {
        f.apply(context, args);
      }, 1000);
    };
  }

  clearSearchMoreusiness() {
    this.searchStringMore = '';
    this.loadingDataMore = true;
    clearInterval(this.clearTimeoutSearchBusiness);
    this.openAllBusinessPopup();
  }

  searchVal(val) {
    this.serchstring1 = val;
    clearInterval(this.clearTimeout1);
    this.clearTimeout1 = setInterval(() => {
      if (val === '' && this.searchItem === true) {
        this.loadingData = true;
        // this.getBusinessWithCatId();
        this.getCategoryBusiness(this.myCurrentCategoryId, this.myCurrentCategoryName);
      } else {
        this.loadingData = true;
        // $('.sliderList').slick('unslick');
        // this.getbusinessSearchFunction1();
      }
    }, 1000);
  }
  prevSearchString:any='';

  getbusinessSearchFunction() {
    clearInterval(this.clearTimeout1);
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      this.nao.go(30);
      this.searchString = this.searchString.trim();
      this.dataService.getBusinessesSearch(this.id, this.searchString, window.localStorage.getItem('token')).subscribe(
        (data) => {
          this.nao.go(100);
          this.searchedData = data;
          this.searchedData = this.searchedData.business_list;
          this.loadingData = false;

          setTimeout(() => {
            const width = $('.businessTile').width();
            const height = width * 0.75;
            $('.businessTile').css('height', height);
          }, 500);
        },
        (error) => {
          this.nao.go(100);
        }
      );
    }, 1000);
  }

  clearSearch() {
    this.searchString = '';
    this.loadingData = true;
    // this.getBusinessWithCatId();
    this.getCategoryBusiness(this.myCurrentCategoryId, this.myCurrentCategoryName);
    this.searchItem = false;
  }

  tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  goToBusinessDetail(id, value, search){
    localStorage.setItem('business_product_id', JSON.stringify(id));
    localStorage.setItem('business_product_value', value);
    localStorage.setItem('business_product_search', search);
    let businessName = '';
    for(let i = 0; i < this.myCategoryBusinesses.items.length; i++){
      if(id == this.myCategoryBusinesses.items[i].id){
        businessName = this.myCategoryBusinesses.items[i].alias;
        break;
      }
    }

    localStorage.setItem('business_store_category', this.myCurrentCategoryId);
    localStorage.setItem('business_store_category_name', this.myCurrentCategoryName);

    this.router.navigate(['/businesses', this.mySelectedCityName, businessName]);
    this.detailDataLoad = false;
  }

  getBusinessDetail(id, value, search) {
    this.dataControlService.getproductDetailOnProductPage.next(false);

    this.loaderCondition = false;
    this.SeeAllLoader = false;
    this.businessDetailProductSearchString = search;
    if(search == '') {
      this.businessDetailProductSearchString = this.filteredData.search;
      this.serchstring1 = this.filteredData.search;
    }
    $('.ProductTab').removeClass('active');
    $('.AboutTab').removeClass('active');
    $('.CategoriesTab').removeClass('active');
    $('#products').removeClass('active');
    $('#about').removeClass('active');
    $('#categories').removeClass('active');
    $('#products').addClass('active');
    $('.ProductTab').addClass('active');
    $('#products').addClass('in');

    this.businessStatus = '';
    this.openOrCloseIn = '';
    this.businessRaitingArray = new Array();
    this.businessId = id;
    window.localStorage.setItem('businessId', this.businessId);
    // this.nao.go(30);

    var todayDateVal = this.todayDateVal;
    if (this.todayDateVal === 0) {
      todayDateVal = 7;
    }

    let selectedLat = '';
    let selectedLong = '';
    const profileData = JSON.parse(localStorage.getItem('profileAddress'));
    if(profileData != null) {
      if(profileData.latitude != undefined) {
        selectedLat = profileData.latitude;
      }
      if(profileData.longitude != undefined) {
        selectedLong = profileData.longitude;
      }
    }

    this.dataService.businessDetailWithServiceArea(id, this.id, todayDateVal, selectedLat, selectedLong).subscribe(
      (data) => {
        // this.nao.go(100);
        this.businessDetailData = data;
        // console.log(this.businessDetailData.custom_order_title);
        this.events.publish('businessDetailData', this.businessDetailData);
        console.log('businessDetailData');
        localStorage.setItem('businessDetailData', JSON.stringify(this.businessDetailData));
        // if ( this.businessDetailData.rest_error_code === 4 || this.businessDetailData.rest_error_code === 1 ) {
        //   return;
        // }
        // this.disableBackground();

        this.businessDetalSuccess = this.businessDetailData.success;
        this.businessDetailData = this.businessDetailData.business_detail;
        this.businessRaiting = this.businessDetailData.rating;

        this.setMetaTags(this.businessDetailData.name, this.businessDetailData.image, this.businessDetailData.about, this.businessDetailData.about);
        // this.businessRaiting = Math.round(this.businessRaiting);
        for (let z = 0; z < this.businessRaiting; z++) {
          this.businessRaitingArray.push({
            value: 'true'
          });
        }
        let businessRaitingArraylen = this.businessRaitingArray.length;
        for (let x = 5; x > businessRaitingArraylen; x--) {
          this.businessRaitingArray.push({
            value: 'false'
          });
        }


        // Time Work Start
        if (this.businessDetailData.full_day === true) {
          this.businessStatus = 'Open';
          this.openOrCloseIn = '';
          this.finalDisplayTime = '';
        } else {
          var cDate = new Date().getDate();
          var cYear = new Date().getFullYear();
          var cMonth = new Date().getUTCMonth() + 1;
          var openTime = this.businessDetailData.open_time;
          var tOpen = cYear + '/' + cMonth + '/' + cDate + ' ' + openTime + ' ' + 'UTC';
          this.dateObjOpen = new Date(tOpen);
          var closeTime = this.businessDetailData.close_time;
          var tClose = cYear + '/' + cMonth + '/' + cDate + ' ' + closeTime + ' ' + 'UTC';
          this.dateObjClose = new Date(tClose);
          this.currentSystemTime = new Date().getTime();
          var convertedTimeWithZone = new Date(this.dateObjOpen).getTime();
          var convertedTimeWithZone2 = new Date(this.dateObjClose).getTime();
          if (convertedTimeWithZone2 < convertedTimeWithZone) {
            convertedTimeWithZone2 = convertedTimeWithZone2 + 86400000;
          }
          if (convertedTimeWithZone2 === convertedTimeWithZone) {
            convertedTimeWithZone2 = convertedTimeWithZone2 + 86400000 + 86400000;
          }
          if ((this.currentSystemTime >= convertedTimeWithZone) && (this.currentSystemTime <= convertedTimeWithZone2)) {
            this.businessStatus = 'Open';
            this.openOrCloseIn = 'Will close ';
            this.finalDisplayTime = this.dateObjClose;
          } else {
            this.businessStatus = 'Closed';
            this.openOrCloseIn = 'Will reopen';
            var currentDate = new Date();
            var todayDateIndex = this.todayDateVal;
            if (this.businessDetailData.time_list[todayDateIndex].fullday === true) {
              this.finalDisplayTime = null;
            }
            if (this.businessDetailData.time_list[todayDateIndex].fullday === false) {
              var cDate1 = new Date().getDate();
              var cYear1 = new Date().getFullYear();
              var cMonth1 = new Date().getUTCMonth() + 1;
              var openTime1 = this.businessDetailData.time_list[todayDateIndex].open;
              var tOpen1 = cYear1 + '/' + cMonth1 + '/' + cDate1 + ' ' + openTime1 + ' ' + 'UTC';
              this.dateObjOpen = new Date(tOpen1);
              this.finalDisplayTime = this.dateObjOpen;
            }

          }
        }
        // Time Work End
        this.businessName = this.businessDetailData.name;
        this.business_image = this.businessDetailData.square_image;
        this.business_delivery_charges = this.businessDetailData.delivery_charges;
        this.business_platform_fee = this.businessDetailData.platform_fee;
        this.business_total_charges = this.businessDetailData.total_charges;
        this.taxOfBusiness = this.businessDetailData.tax_percentage;
        this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
        this.payment_through_card = this.businessDetailData.payment_through_card;
        this.payment_through_cash = this.businessDetailData.payment_through_cash;
        this.payment_over_due = this.businessDetailData.payment_over_due;
        this.fullDayStatus = this.businessDetailData.full_day;
        this.timeObj = this.businessDetailData.time_list;
        if (this.businessDetalSuccess === true) {
          this.detailDataLoad = true;
          this.outletAddress = {
            area: this.businessDetailData.street,
            city: this.businessDetailData.city,
            state: this.businessDetailData.state,
            zip: this.businessDetailData.zip,
            country: this.businessDetailData.area,
            longitude: this.businessDetailData.longitude,
            latitude: this.businessDetailData.latitude
          };
          for (var j = 0; j < this.timeObj.length; j++) {
            if (this.timeObj[j].fullday === false) {
              var timeData = this.convertTimeFunction(this.timeObj[j].open, this.timeObj[j].close);
              this.timeObj[j].open = timeData[0];
              this.timeObj[j].close = timeData[1];
            }
          }
          if (value === 'default') {
            this.openNav();
          }
          if(value == 'search'){
            this.openNavForSearch();
          }
          if(value == 'moreSearch'){
            this.openNavForMoreSearch();
          }

        }

      },

      (error) => {
        // this.nao.go(100);
      }
    );
  }


  convertTimeFunction(open_time, close_time) {
    // Open Time
    var cDate = new Date().getDate();
    var cYear = new Date().getFullYear();
    var cMonth = new Date().getUTCMonth() + 1;
    var openTime = open_time;
    var tOpen = cYear + '/' + cMonth + '/' + cDate + ' ' + openTime + ' ' + 'UTC';
    var dateObjOpen = new Date(tOpen);
    // Close Time
    var cDateClose = new Date().getDate();
    var cYearClose = new Date().getFullYear();
    var cMonthClose = new Date().getUTCMonth() + 1;
    var closeTime = close_time;
    var tClose = cYearClose + '/' + cMonthClose + '/' + cDateClose + ' ' + closeTime + ' ' + 'UTC';
    var dateObjClose = new Date(tClose);

    var returnedArray = [];
    returnedArray.push(dateObjOpen);
    returnedArray.push(dateObjClose);
    return returnedArray;
  }

  getBusinessDetailProductsCallMain(id, catID) {
    this.catId = catID;
    localStorage.setItem('business_store_category_id', this.myCurrentCategoryId);
    this.loaderCondition = false;
    this.SeeAllLoader = false;
    var elmnt = document.getElementById('businessDetailHead');
    // elmnt.scrollIntoView();
    this.checkappToken = '';
    // this.businessProductData = true;
    this.businessProductData = true;
    this.getBusinessDetailProducts(id);
    this.CategoryListing();
  }

  getBusinessDetailProductsCall(id) {

    var elmnt = document.getElementById('businessDetailHead');
    // elmnt.scrollIntoView();
    this.checkappToken = '';
    // alert('caught ' + this.searchString);
    this.serchstring1 = this.filteredData.search;
    this.businessProductData = true;
    this.getBusinessDetailProducts(id);
    this.searchProductsByCategoryId();
    this.CategoryListing();
  }

  searchProductsByCategoryIdByPage() {
    this.PaginationPageForSearch++;
    clearTimeout(this.clearTimeoutProduct);
    this.searchProductByCategoryByIDByPage = [];
    this.clearTimeoutProduct = setTimeout(() => {
      if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
        var token = '';
      } else {
        token = window.localStorage.getItem('token');
      }
      const cartData = JSON.parse(localStorage.getItem('cartItems'));
      this.inputSearch = false;
      this.dataService.searchProductsByCategory(this.businessId, this.limitForSearch, this.PaginationPageForSearch, this.serchstring1, token, -1, -1).subscribe(
        (data) => {
          this.businessCatLoding = false;
          this.loaderCondition = false;
          this.searchProductByCategoryByIDByPage = data;
          this.searchProductByCategoryByIDLastPage = this.searchProductByCategoryByIDByPage.last_page;
          this.searchProductByCategoryByIDByPage = this.searchProductByCategoryByIDByPage.items;
          this.inputSearch = true;
          if (cartData !== null) {
            for (var i = 0; i < this.searchProductByCategoryByIDByPage.length; i++) {
              this.searchProductByCategoryByIDByPage[i].count = 0;
              for (var k = 0; k < this.searchProductByCategoryByIDByPage[i].variant.length; k++) {
                for (var l = 0; l < cartData.length; l++) {
                  if (cartData[l].variant_id === this.searchProductByCategoryByIDByPage[i].variant[k].id) {
                    this.searchProductByCategoryByIDByPage[i].count += cartData[l].quantity;
                  }
                }
              }
            }
          }
          for (var k = 0; k < this.searchProductByCategoryByIDByPage.length; k++) {
            this.searchProductByCategoryByID.push(this.searchProductByCategoryByIDByPage[k]);
          }
        },
        (error) => {
          // this.nao.go(100);
        }
      );
    }, 1000);
  }

  searchProductsByCategoryId() {
    this.loaderCondition = false;
    this.PaginationPageForSearch = 0;
    clearTimeout(this.clearTimeoutProduct);
    this.searchProductByCategoryByID = [];
    this.clearTimeoutProduct = setTimeout(() => {
      if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
        var token = '';
      } else {
        token = window.localStorage.getItem('token');
      }
      const cartData = JSON.parse(localStorage.getItem('cartItems'));
      this.inputSearch = false;
      this.dataService.searchProductsByCategory(this.businessId, this.limitForSearch, this.PaginationPageForSearch, this.serchstring1, token, -1, -1).subscribe(
        (data) => {
          this.searchProductByCategoryByID = data;
          this.searchProductByCategoryByIDLastPage = this.searchProductByCategoryByID.last_page;
          this.searchProductByCategoryByID = this.searchProductByCategoryByID.items;
          this.inputSearch = true;
          this.businessCatLoding = false;
          if (cartData !== null) {
            for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
              this.searchProductByCategoryByID[i].count = 0;
              for (var k = 0; k < this.searchProductByCategoryByID[i].variant.length; k++) {
                for (var l = 0; l < cartData.length; l++) {
                  if (cartData[l].variant_id === this.searchProductByCategoryByID[i].variant[k].id) {
                    this.searchProductByCategoryByID[i].count += cartData[l].quantity;
                  }
                }
              }
            }
          }
        },
        (error) => {
          // this.nao.go(100);
        }
      );
    }, 1000);
  }


  getBusinessDetailProductsByPage() {
    console.log(localStorage.getItem('business_store_category'));
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    this.businessDetailProductsPaginate = [];
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    this.PagnationPage++;
    this.dataService.businessSearchProducts(this.businessId, this.businessDetailProductSearchString, this.limit, this.PagnationPage, token).subscribe(
      (data) => {
        this.loaderCondition = false;
        this.businessDetailProductsPaginate = data;
        this.lastPage = this.businessDetailProductsPaginate.last_page;
        if (this.businessDetailProductSearchString === '') {
          this.loadPreviousProductsDataCount = this.PagnationPage;
          this.loadPreviousProductsDataLastPage = this.lastPage;
        }
        this.detailProductSuccess = this.businessDetailProductsPaginate.success;
        this.businessDetailProductsPaginate = this.businessDetailProductsPaginate.items;
        if (cartData !== null) {
          for (var i = 0; i < this.businessDetailProductsPaginate.length; i++) {
            for (var j = 0; j < this.businessDetailProductsPaginate[i].products.length; j++) {
              for (var k = 0; k < this.businessDetailProductsPaginate[i].products[j].variant.length; k++) {
                for (var l = 0; l < cartData.length; l++) {
                  if (cartData[l].variant_id === this.businessDetailProductsPaginate[i].products[j].variant[k].id) {
                    this.businessDetailProductsPaginate[i].products[j].count += cartData[l].quantity;
                  }
                }
              }
            }
          }
        }
        this.iniatlizeSlider();
        for (var k = 0; k < this.businessDetailProductsPaginate.length; k++) {
          this.businessDetailProducts.push(this.businessDetailProductsPaginate[k]);
        }
        this.loaderCondition = false;
      },
      (error) => {
        this.nao.go(100);
        clearInterval(this.clearTimeoutProduct);
      }
    );
  }

  getBusinessDetailProducts(id) {
    this.businessProductData = true;
    this.loaderCondition = false;
    this.cat_ID = id;
    this.PagnationPage = 0;
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    this.businessCatLoding = true;
    this.businessDetailProducts = [];
    this.businessProductData = true;
    const cartData = JSON.parse(localStorage.getItem('cartItems'));

    if (this.checkappToken !== undefined && this.checkappToken !== null && this.checkappToken !== '') {
      setTimeout(() => {
        var elmnt = document.getElementById('aboutScroll');
        elmnt.scrollIntoView();
      }, 3000);
    }
    console.log(localStorage.getItem('business_store_category_id'));
    this.dataService.businessSearchProducts(this.businessId, '', this.limit, this.PagnationPage, token).subscribe(
      (data) => {
        this.businessDetailProducts = data;
        this.lastPage = this.businessDetailProducts.last_page;
        this.detailProductSuccess = this.businessDetailProducts.success;
        this.businessDetailProducts = this.businessDetailProducts.items;
        this.loaderCondition = false;
        this.businessCatLoding = false;
        if (cartData !== null) {
          for (var i = 0; i < this.businessDetailProducts.length; i++) {
            for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
              for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
                for (var l = 0; l < cartData.length; l++) {
                  if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                    this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                  }
                }
              }
            }
          }
        }
        this.iniatlizeSlider();
        if (this.businessDetailProductSearchString === '') {
          this.loadPreviousProductsData = this.businessDetailProducts;
          this.loadPreviousProductsDataCount = this.PagnationPage;
          this.loadPreviousProductsDataLastPage = this.lastPage;
        }
        this.loaderCondition = false;
      },
      (error) => {
        clearInterval(this.clearTimeoutProduct);
      }
    );

  }

  loadMoreFunction() {
    this.getBusinessDetailProductsByPage();
  }

  searchBusinessDetlProductsCall(val) {
    this.businessCatLoding = true;
    this.businessProductData = false;
    this.serchstring1 = val;
    this.inputSearch = false;
    if (this.businessDetailProductSearchString === '') {
      this.businessCatLoding = true;
      this.searchProductByCategoryByID = [];
      this.loaderCondition = false;
      if (this.loadPreviousProductsData === undefined) {
        this.businessProductData = true;
        this.getBusinessDetailProducts(this.id);
      } else {
        clearTimeout(this.clearTimeoutProductWhenEmpty);
        this.showBusinessProductsliderLoading = true;
        this.businessProductData = true;
        this.clearTimeoutProductWhenEmpty = setTimeout(() => {
          this.businessCatLoding = true;
          this.businessDetailProducts = this.loadPreviousProductsData;
          this.PagnationPage = this.loadPreviousProductsDataCount;
          this.lastPage = this.loadPreviousProductsDataLastPage;
          this.searchProductByCategoryByIDByPage = [];
          this.iniatlizeSlider();
          this.businessCatLoding = false;
        }, 1000);
      }
      const cartData = JSON.parse(localStorage.getItem('cartItems'));
      if (cartData !== null) {
        for (var i = 0; i < this.businessDetailProducts.length; i++) {
          for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
            this.businessDetailProducts[i].products[j].count = 0;
            for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
              for (var l = 0; l < cartData.length; l++) {
                if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                  this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                }
              }
            }
          }
        }
      }
      this.businessCatLoding = false;
    }
    else {
      this.businessCatLoding = true;
      this.searchProductsByCategoryId();
    }
  }

  clearSearchBusinessDetailProductsCall() {
    this.businessCatLoding = true;
    this.loaderCondition = false;
    this.inputSearch = false;
    this.businessProductData = false;
    this.searchProductByCategoryByID = [];
    this.businessDetailProductSearchString = '';
    this.serchstring1 = '';
    if (this.loadPreviousProductsData === undefined) {
      this.businessProductData = true;
      this.getBusinessDetailProducts(this.id);
    } else {
      clearTimeout(this.clearTimeoutProductWhenEmpty);
      this.businessCatLoding = true;
      this.showBusinessProductsliderLoading = true;
      this.businessProductData = true;
      this.clearTimeoutProductWhenEmpty = setTimeout(() => {
        this.businessDetailProducts = this.loadPreviousProductsData;
        this.PagnationPage = this.loadPreviousProductsDataCount;
        this.lastPage = this.loadPreviousProductsDataLastPage;
        this.searchProductByCategoryByIDByPage = [];
        this.iniatlizeSlider();

        const cartData = JSON.parse(localStorage.getItem('cartItems'));
        if (cartData !== null) {
          for (var i = 0; i < this.businessDetailProducts.length; i++) {
            for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
              this.businessDetailProducts[i].products[j].count = 0;
              for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
                for (var l = 0; l < cartData.length; l++) {
                  if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                    this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                  }
                }
              }
            }
          }
        }
        this.businessCatLoding = false;
      }, 1000);
    }


  }

  load() {
    this.businessProductData = true;
    this.getBusinessDetailProducts(this.id);
  }

  closeBusinessModal() {
    $('#catID').css('visibility', 'hidden');
    this.loaderCondition = false;
    this.SeeAllLoader = false;
    this.enableBackground();
    this.PagnationPage = 0;
    this.serchstring1 = '';
    this.subCatid = -1;
  }
  mySelectedProductId = '';
  getproductDetailOnProductPage(sDataId, productId){
    this.mySelectedProductId =  productId;
    this.getBusinessDetail(sDataId, 'search', '');
  }
  getproductDetailOnProductPageForMore(sDataId){
    this.getBusinessDetail(sDataId, 'moreSearch', '');
  }
  getproductDetail(id, name) {
    this.Name = name;
    var token;
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    this.nao.go(30);
    this.dataService.productDetail(id, token).subscribe(
      (data) => {
        this.nao.go(100);
        this.productDetailData = data;
        this.productSuccess = this.productDetailData.success;
        this.productDetailData = this.productDetailData.product;
        this.setMetaTags(this.productDetailData.title, this.productDetailData.image[0], this.productDetailData.description, this.productDetailData.description);
        this.productDetailDataImagesLength = this.productDetailData.image.length;
        if (this.productDetailDataImagesLength <= 1) {
          $('.carousel-indicators').removeClass('display-block');
          $('.carousel-indicators').addClass('display-none');
        }
        if (this.productDetailDataImagesLength > 1) {
          $('.carousel-indicators').removeClass('display-none');
          $('.carousel-indicators').addClass('display-block');
        }
        this.productDetailData.description = this.productDetailData.description.replace(/(?:\r\n|\r|\n)/g, '<br>');
        if (this.productSuccess === true) {
          this.addCartPopOpen(this.productDetailData, 2);
          this.openNavAll();
        }
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }


  initSlider() {
    setTimeout(() => {

      $(document).ready(function () {
        const width = $('.businessTile').width();
        const height = width * 0.75;
        $('.sdetail').css('-webkit-box-orient', 'vertical');
        $('.slick-track').css('margin-left', 0);
        $('.slick-track').css('margin-right', 0);
        $('.businessTile').css('height', height);
      });
    }, 1000);
  }

  openNav() {

    this.dataControlService.filterSearchString.next('');
    this.dataControlService.filterMaxVal.next(undefined);
    this.dataControlService.filterMinVal.next(undefined);

    localStorage.setItem('business_store_category', this.myCurrentCategoryId);
    localStorage.setItem('business_store_category_name', this.myCurrentCategoryName);

    this.router.navigate(['/businesses', this.mySelectedCityName, this.businessDetailData.alias ]);
    this.detailDataLoad = false;
  }
  openNavForSearch(){

    this.getproductDetail(this.mySelectedProductId, undefined);
    this.detailDataLoad = false;

  }
  openNavForMoreSearch(){

    localStorage.setItem('business_store_category', this.myCurrentCategoryId);
    localStorage.setItem('business_store_category_name', this.myCurrentCategoryName);

    this.router.navigate(['/businesses', this.mySelectedCityName, this.businessDetailData.alias ]);
    this.detailDataLoad = false;
    setTimeout(() => {
      this.dataControlService.getMoreproductDetailOnProductPage.next(true);
    }, 1000);
  }

  openNavAll() {
    $('#businessAll').modal({}, 'show');
    this.disableBackground();
  }

  closeBusinessPopupDeyail() {
    this.businessId = -1;
    this.subCatid = -1;
  }

  closeNav() {
    this.SeeAllLoader = false;
    this.subCatid = -1;
    this.searchItemCat = false;

  }
  closeNav1() {
    $('#subCategorySlider').css('visibility', 'hidden');
    this.loaderCondition = false;
    this.SeeAllLoader = false;
    var dSlider = $('.subCategorySlider');
    dSlider.trigger('destroy.owl.carousel');
    this.subCatid = -1;
    this.searchItemCat = false;
  }

  closeNavAll() {
  }


  inProgressPop() {
    $('#wipPop').modal('show');
  }

  // Cart Flow
  checkObject(array, obj) {
    var value = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].variant_id === obj.id) {
        value = true;
        break;
      }
    }
    return value;
  }

  swipe() {
    $(".carousel").on("touchstart", function (event) {
      var xClick = event.originalEvent.touches[0].pageX;
      $(this).one("touchmove", function (event) {
        var xMove = event.originalEvent.touches[0].pageX;
        if (Math.floor(xClick - xMove) > 5) {
          $(this).carousel('next');
        } else if (Math.floor(xClick - xMove) < -5) {
          $(this).carousel('prev');
        }
      });
      $(".carousel").on("touchend", function () {
        $(this).off("touchmove");
      });
    });
  }


  addCartPopOpen(obj, val) {

    this.swipe();

    this.cartFinal = [];
    this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
    this.cartPopData = obj;
    this.variantData = this.cartPopData.variant;

    for (let i = 0; i < this.variantData.length; i++) {
      if (this.cartDataFromStorage !== null && this.checkObject(this.cartDataFromStorage, this.variantData[i]) === true) {
        for (let j = 0; j < this.cartDataFromStorage.length; j++) {
          if (this.businessId === this.cartDataFromStorage[j].business_id && this.cartPopData.id === this.cartDataFromStorage[j].product_id && this.variantData[i].id === this.cartDataFromStorage[j].variant_id) {
            window.localStorage.setItem('businessName', this.businessName);
            this.cartFinal.push({
              business_image: this.business_image,
              business_name: this.businessName,
              business_id: this.businessId,
              business_delivery_charges: this.business_delivery_charges,
              business_platform_fee: this.business_platform_fee,
              business_total_charges: this.business_total_charges,
              business_tax: this.taxOfBusiness,
              product_id: this.cartPopData.id,
              product_name: this.variantData[i].product_name,
              product_image: this.variantData[i].image,
              variant_id: this.variantData[i].id,
              variant_name: this.variantData[i].option_name,
              variant_value: this.variantData[i].option_value,
              price: Math.round(this.variantData[i].price),
              sale_price: this.variantData[i].sale_price,
              is_on_sale: this.variantData[i].is_on_sale,
              quantity: this.cartDataFromStorage[j].quantity,
              addCartBtnShow: false,
              outlet_address: this.outletAddress,
            });
          }
        }
      } else {
        this.cartFinal.push({
          business_image: this.business_image,
          business_name: this.businessName,
          business_id: this.businessId,
          business_delivery_charges: this.business_delivery_charges,
          business_platform_fee: this.business_platform_fee,
          business_total_charges: this.business_total_charges,
          business_tax: this.taxOfBusiness,
          product_id: this.cartPopData.id,
          product_name: this.variantData[i].product_name,
          product_image: this.variantData[i].image,
          variant_id: this.variantData[i].id,
          variant_name: this.variantData[i].option_name,
          variant_value: this.variantData[i].option_value,
          price: Math.round(this.variantData[i].price),
          sale_price: this.variantData[i].sale_price,
          is_on_sale: this.variantData[i].is_on_sale,
          quantity: 1,
          addCartBtnShow: true,
          outlet_address: this.outletAddress,
        });
      }
    }

    if (val === 1) {
      $('#addCartPop').modal('show');
    } else {
      window.localStorage.setItem('paymentMethod', this.payment_through_card);
      window.localStorage.setItem('paymentThroughCard', this.payment_through_card);
      window.localStorage.setItem('paymentThroughCash', this.payment_through_cash);
      window.localStorage.setItem('paymentOverDue', this.payment_over_due);
    }

  }

  addToCartOnExisting() {
    this.disableMultiClick = true;
    this.cartDataFromStorage = [];
    this.events.publish('cartUpdate', this.cartDataFromStorage);
    localStorage.removeItem('cartItems');
    setTimeout(() => {
      this.addToCart(this.objToOveride, this.indexToOveride);
      $('#onOnleBusinessCart').modal('hide');
      this.disableMultiClick = false;
    }, 1000);
  }

  async addToCart(obj, index) {
    window.localStorage.setItem('paymentMethod', this.payment_through_card);
    window.localStorage.setItem('paymentThroughCard', this.payment_through_card);
    window.localStorage.setItem('paymentThroughCash', this.payment_through_cash);
    window.localStorage.setItem('paymentOverDue', this.payment_over_due);
    this.businessName2 = window.localStorage.getItem('businessName');
    if (this.payment_over_due === true) {
      $('#addCartPop').modal('hide');
      $('#onOnleOverDue').modal('show');

      return;
    }

    this.objToOveride = obj;
    this.indexToOveride = index;
    let cartDataNewChk = JSON.parse(localStorage.getItem('cartItems'));
    if (cartDataNewChk !== null) {
      if (cartDataNewChk.length > 0) {
        if (this.businessId !== cartDataNewChk[0].business_id) {
          $('#onOnleBusinessCart').modal('show');
          return;
        }
      }
    }
    const objToPass = obj;
    this.cartFinal[index].addCartBtnShow = false;
    if (this.cartDataFromStorage === null || this.cartDataFromStorage === undefined) {
      this.cartDataFromStorage = [];
    }
    this.cartDataFromStorage.push(objToPass);
    this.events.publish('cartUpdate', this.cartDataFromStorage);
    const cartData = JSON.parse(localStorage.getItem('cartItems'));


    if (this.searchProductByCategoryByID != undefined) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        this.searchProductByCategoryByID[i].count = 0;
        for (var k = 0; k < this.searchProductByCategoryByID[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.searchProductByCategoryByID[i].variant[k].id) {
              this.searchProductByCategoryByID[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }
    if (this.businessCategoryProducts != undefined) {

      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        this.businessCategoryProducts[i].count = 0;
        for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
              this.businessCategoryProducts[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }
    if ($('#scrollId').is('visible')) {
      for (var i = 0; i < this.businessDetailProducts.length; i++) {
        for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
          this.businessDetailProducts[i].products[j].count = 0;
          for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
            for (var l = 0; l < cartData.length; l++) {
              if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
              }
            }
          }
        }
      }
    }


    window.localStorage.setItem('paymentMethod', this.payment_through_card);
    window.localStorage.setItem('paymentThroughCard', this.payment_through_card);
    window.localStorage.setItem('paymentThroughCash', this.payment_through_cash);
    window.localStorage.setItem('paymentOverDue', this.payment_over_due);

  }

  async openFinalCart() {
    this.events.publish('openCartPopup', 'true');
  }

  async quantityPlus(index, quantity, obj) {
    this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
    this.cartFinal[index].quantity = quantity + 1;
    for (let i = 0; i < this.cartDataFromStorage.length; i++) {
      if (this.businessId === this.cartDataFromStorage[i].business_id && this.cartPopData.id === this.cartDataFromStorage[i].product_id && obj.variant_id === this.cartDataFromStorage[i].variant_id) {
        this.cartDataFromStorage[i].quantity = this.cartFinal[index].quantity;
        this.events.publish('cartUpdate', this.cartDataFromStorage);
      }
    }
    const cartData = JSON.parse(localStorage.getItem('cartItems'));

    if (this.searchProductByCategoryByID) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        this.searchProductByCategoryByID[i].count = 0;
        for (var k = 0; k < this.searchProductByCategoryByID[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.searchProductByCategoryByID[i].variant[k].id) {
              this.searchProductByCategoryByID[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    if (this.businessCategoryProducts) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        this.businessCategoryProducts[i].count = 0;
        for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
              this.businessCategoryProducts[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    if ($('#scrollId').is('visible')) {
      for (var i = 0; i < this.businessDetailProducts.length; i++) {
        for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
          this.businessDetailProducts[i].products[j].count = 0;
          for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
            for (var l = 0; l < cartData.length; l++) {
              if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
              }
            }
          }
        }
      }
    }


  }

  async quantityMinus(index, quantity, obj) {
    this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
    this.cartFinal[index].quantity = quantity - 1;
    for (let i = 0; i < this.cartDataFromStorage.length; i++) {
       if (this.businessId === this.cartDataFromStorage[i].business_id && this.cartPopData.id === this.cartDataFromStorage[i].product_id && obj.variant_id === this.cartDataFromStorage[i].variant_id) {
        if (this.cartFinal[index].quantity < 1) {
          this.cartDataFromStorage.splice(i, 1);
          this.events.publish('cartUpdate', this.cartDataFromStorage);
        } else {
          this.cartDataFromStorage[i].quantity = this.cartFinal[index].quantity;
          this.events.publish('cartUpdate', this.cartDataFromStorage);
        }
      }
    }
    if (this.cartFinal[index].quantity < 1) {
      this.cartFinal[index].quantity = 1;
      this.cartFinal[index].addCartBtnShow = true;
    }

    const cartData = JSON.parse(localStorage.getItem('cartItems'));

    if (this.searchProductByCategoryByID) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        this.searchProductByCategoryByID[i].count = 0;
        for (var k = 0; k < this.searchProductByCategoryByID[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.searchProductByCategoryByID[i].variant[k].id) {
              this.searchProductByCategoryByID[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    if (this.businessCategoryProducts) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        this.businessCategoryProducts[i].count = 0;
        for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
              this.businessCategoryProducts[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    if ($('#scrollId').is('visible')) {
      for (var i = 0; i < this.businessDetailProducts.length; i++) {
        for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
          this.businessDetailProducts[i].products[j].count = 0;
          for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
            for (var l = 0; l < cartData.length; l++) {
              if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
              }
            }
          }
        }
      }
    }

  }

  addCartfromshortPop(obj, val) {
    this.addCartPopOpen(obj, val);
    $('.addCartShort').css('-webkit-filter', 'blur(3px)');
    $('.addCartShort').css('-moz-filter', 'blur(3px)');
    $('.addCartShort').css('-o-filter', 'blur(3px)');
    $('.addCartShort').css('-ms-filter', 'blur(3px)');
    $('.addCartShort').css('filter', 'blur(3px)');
  }

  get_pending_rating() {
    // this.pendingRating  = setInterval( () => {
    this.dataService.get_pending_rating(window.localStorage.getItem('token'))
      .subscribe(
        data => {
          this.ratingData = data;
        if  (this.ratingData.pending_payment.is_pending){
          this.events.publish('confirmPaymentMethod',this.ratingData.pending_payment);
        } else if (this.ratingData.status === 5) {
            this.events.publish('ratingPopUpData', this.ratingData);
          } else {
            // this.events.publish('ratingPopUpData',null);
          }
        });
  }

  openReviewPopup(id) {
    this.reviewBusinessId = id;
    this.dataService.getReviewList(this.reviewBusinessId)
      .subscribe(
        data => {
          this.ReviewData = data;
          this.reviewLastPage = this.ReviewData.last_page;
          this.ReviewData = this.ReviewData.items;
          $('#reviewDetailPopup').modal('show');
          this.reviewLastPageCount = 0;
        });
  }

  openReviewPopupBypage() {
    this.reviewLastPageCount = this.reviewLastPageCount + 1;
    this.dataService.getReviewListPage(this.reviewBusinessId, this.reviewLastPageCount)
      .subscribe(
        data => {
          this.ReviewDataByPage = data;
          this.reviewLastPage = this.ReviewDataByPage.last_page;
          this.ReviewDataByPage = this.ReviewDataByPage.items;
          for (var i = 0; i < this.ReviewDataByPage.length; i++) {
            this.ReviewData.push(this.ReviewDataByPage[i]);
          }
        });
  }

  getStoryListing() {
    this.dataService.getStory(this.storylimit).subscribe(
      (data) => {
        this.storyData = data;
        this.storyData = this.storyData.items;
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }

  closeBackdrop() {
    $('#businessDetail').modal('hide');
  }

  removeToWishlist(obj) {
    if ($('#businessAll').is(':visible')) {
      this.productDetailData.is_wish_list = false;
    }

    // if ( $('#scrollId').is(':visible') )  {
    for (var i = 0; i < this.businessDetailProducts.length; i++) {
      for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
        if (obj.id === this.businessDetailProducts[i].products[j].id) {
          this.businessDetailProducts[i].products[j].is_wish_list = false;
        }
      }
    }

    // }

    if ($('#SearchProductsByCategory').is(':visible')) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        if (obj.id === this.businessCategoryProducts[i].id) {
          this.businessCategoryProducts[i].is_wish_list = false;
        }
      }
    }

    if (this.businessCatLoding == false && this.businessDetailProductSearchString != '') {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        if (obj.id === this.searchProductByCategoryByID[i].id) {
          this.searchProductByCategoryByID[i].is_wish_list = false;
        }
      }
    }

    this.dataService.removeProductInWishList(window.localStorage.getItem('token'), obj.id).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }

      },
      (error) => {
      }
    );
  }

  addToWishlist(obj) {
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined || window.localStorage.getItem('token') === null) {
      this.addBlurbusinessAll();
      this.addBlurbusinessDetail();
      this.events.publish('openLoginFunction', 'true');
      return;
    }
    this.clearBlurbusinessAll();
    this.clearBlurbusinessDetail();
    if ($('#businessAll').is(':visible')) {
      this.productDetailData.is_wish_list = true;
    }

    // if ( $('#scrollId').is(':visible') )  {
      if(this.businessDetailProducts !== undefined){
        for (var i = 0; i < this.businessDetailProducts.length; i++) {
          for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
            if (obj.id === this.businessDetailProducts[i].products[j].id) {
              this.businessDetailProducts[i].products[j].is_wish_list = true;
            }
          }
        }
      }

    // }

    if ($('#SearchProductsByCategory').is(':visible')) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        if (obj.id === this.businessCategoryProducts[i].id) {
          this.businessCategoryProducts[i].is_wish_list = true;
        }
      }
    }

    if (this.businessCatLoding == false && this.businessDetailProductSearchString != '') {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        if (obj.id === this.searchProductByCategoryByID[i].id) {
          this.searchProductByCategoryByID[i].is_wish_list = true;
        }
      }
    }

    this.dataService.addProductInWishList(window.localStorage.getItem('token'), obj.id, this.businessId).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }

      },
      (error) => {
      }
    );
  }

  removeToWishlistsearch(obj) {
    if ($('#businessAll').is(':visible')) {
      this.productDetailData.is_wish_list = false;
    }



    if (this.businessCatLoding == false && this.businessDetailProductSearchString != '') {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        if (obj.id === this.searchProductByCategoryByID[i].id) {
          this.searchProductByCategoryByID[i].is_wish_list = false;
        }
      }
    }

    for (var i = 0; i < this.loadPreviousProductsData.length; i++) {
      for (var j = 0; j < this.loadPreviousProductsData[i].products.length; j++) {
        if (obj.id === this.loadPreviousProductsData[i].products[j].id) {
          this.loadPreviousProductsData[i].products[j].is_wish_list = false;
        }
      }
    }

    if ($('#SearchProductsByCategory').is(':visible')) {

      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        if (obj.id === this.businessCategoryProducts[i].id) {
          this.businessCategoryProducts[i].is_wish_list = false;
        }
      }
    }
    this.dataService.removeProductInWishList(window.localStorage.getItem('token'), obj.id).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }

      },
      (error) => {
      }
    );
  }

  addToWishlistsearch(obj) {
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined || window.localStorage.getItem('token') === null) {
      this.addBlurbusinessDetail();
      this.events.publish('openLoginFunction', 'true');
      return;
    }
    this.clearBlurbusinessDetail();
    if ($('#businessAll').is(':visible')) {
      this.productDetailData.is_wish_list = true;
    }


    if (this.businessCatLoding == false && this.businessDetailProductSearchString != '') {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        if (obj.id === this.searchProductByCategoryByID[i].id) {
          this.searchProductByCategoryByID[i].is_wish_list = true;
        }
      }
    }

    for (var i = 0; i < this.loadPreviousProductsData.length; i++) {
      for (var j = 0; j < this.loadPreviousProductsData[i].products.length; j++) {
        if (obj.id === this.loadPreviousProductsData[i].products[j].id) {
          this.loadPreviousProductsData[i].products[j].is_wish_list = true;
        }
      }
    }

    if ($('#SearchProductsByCategory').is(':visible')) {

      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        if (obj.id === this.businessCategoryProducts[i].id) {
          this.businessCategoryProducts[i].is_wish_list = true;
        }
      }
    }

    this.dataService.addProductInWishList(window.localStorage.getItem('token'), obj.id, this.businessId).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }

      },
      (error) => {
      }
    );
  }

  updateBusinessDetailProducts1() {
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }

    this.dataService.businessDetailProducts1(this.businessId, token).subscribe(
      (data) => {
        this.businessDetailProductsUpdate = data;
        this.businessDetailProductsUpdate = this.businessDetailProductsUpdate.items;
        if(this.businessDetailProductsUpdate != undefined && this.businessDetailProducts != undefined){
          for (var i = 0; i < this.businessDetailProductsUpdate.length; i++) {
            for (var j = 0; j < this.businessDetailProductsUpdate[i].products.length; j++) {
              this.businessDetailProducts[i].products[j].is_wish_list = this.businessDetailProductsUpdate[i].products[j].is_wish_list;
            }
          }
        }

        if ($('#businessAll').is(':visible')) {
          for (var i = 0; i < this.businessDetailProductsUpdate.length; i++) {
            for (var j = 0; j < this.businessDetailProductsUpdate[i].products.length; j++) {
              if (this.businessDetailProductsUpdate[i].items.products[j].id === this.productDetailData.id) {
                this.productDetailData.is_wish_list = this.businessDetailProductsUpdate[i].products[j].is_wish_list;
              }
            }
          }
        }
      },
      (error) => {
      }
    );
  }

  loadMoreFunctionByCategory() {
    this.getBusinessCategoryProductsByPage();
  }

  loadMoreFunctionByCategoryBySearch() {
    this.searchProductsByCategoryIdByPage();
  }

  // FunToRemoveId(){
  //   this.conditionToCheck = false;
  // }

  FunToAddId(val) {
    this.initSlider();
    this.iniatlizeSlider();
    if (val === 'products') {
      this.conditionToCheck = true;
    }
    if (val === 'about') {
      this.conditionToCheck = false;
    }
    if (val === 'categories') {
      this.conditionToCheck = false;
    }
  }

  // functionFordis(val){
  //   if (val === 'main')  {
  //     this.conditionToCheckforfun = true;
  //   }
  //   if (val === 'search') {
  //     this.conditionToCheckforfun = false;
  //   }
  // }


  openCustomOrderOpoup() {
    this.customOrderText = '<ul><li></li></ul>';
    this.addCustomOrderBtn = false;
    $('#customOrderPopup').modal('show');
    $('.dz-default').css('display', 'block');
    setTimeout(() => {
      $('.dz-preview').remove();
    }, 500);
    const Dropzone = require('dropzone');
    const context = this;
    try {
      new Dropzone('#my-awesome-dropzone', {
        maxFiles: 10,
        addRemoveLinks: true,
        dictDefaultMessage: 'Upload Images',
        autoProcessQueue: false,
        paramName: 'image',
        acceptedMimeTypes: 'image/jpeg, image/jpg, image/png',
        progress: false,
        init: function () {
          context.dropzone = this;

          this.on('addedfile', function (file) {
            if (!file.type.match(/image.*/)) {
              // This is not an image, so Dropzone doesn't create a thumbnail.
              // Set a default thumbnail:
              // this.emit('thumbnail', file, '../app/assets/images/interface.png');

              // You could of course generate another image yourself here,
              // and set it as a data url.
            }
            context.customProductImages.push(file);
          });

          this.on('error', function (file, response) {
            // $(file.previewElement).find('.dz-error-message').text(response.file_name);
            // context.error_count++;
          });

          this.on('queuecomplete', function () {
            // $('#myloader').removeClass('cssload-container');
            // $('#loader_container').removeClass('floatingBarsG');
            // $('.blockG').addClass('myopc');


            // if(context.error_count>0){
            //     toastr.error('Error uploading images.');
            // }
            // else{
            //      toastr.success('Property added successfully.');
            //      context.navigate();
            // }

          });

          this.on('removedfile', function (file) {

            for (var i = 0; i < context.customProductImages.length; i++) {
              if (context.customProductImages[i].name === file.name) {
                context.customProductImages.splice(i, 1);
              }
            }


          });

          this.on('processing', function () {
            this.options.autoProcessQueue = false;
          });

          context.dropzone = this;
        }
      });
    } catch (err) {
    }
  }

  addCustomOrder() {
    if ((this.customOrderText === '' || this.customOrderText === '<ul><li></li></ul>') && this.customProductImages.length === 0) {
      toastr.error('Please add text or upload image');
      return;
    }
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined || window.localStorage.getItem('token') === null) {
      this.addBlur();
      this.events.publish('openLoginFunction', 'true');
      return;
    }
    this.clearBlur();
    this.customProductImagesCount = 0;
    this.customOrderImageSuccessUrl = new Array();

    this.addCustomOrderBtn = true;
    if (this.customProductImages.length > 0) {
      var file = this.customProductImages[0];
      this.uploadCustomeOrderImage(file);
    } else {
      this.uploadCustomeOrderText();
    }
  }

  uploadCustomeOrderImage(file) {
    this.dataService.uploadOrderImage(window.localStorage.getItem('token'), this.businessId, file, this.userCurrentAddress).subscribe(
      (data) => {
        this.customOrderImageSuccess = data;
        if (this.customOrderImageSuccess.success = true) {
          this.customOrderImageSuccessUrl.push(this.customOrderImageSuccess.url);
          this.customProductImagesCount++;
          if (this.customProductImages.length > this.customProductImagesCount) {
            var file = this.customProductImages[this.customProductImagesCount];
            this.uploadCustomeOrderImage(file);
          } else {
            this.uploadCustomeOrderText();
          }
        } else {
          this.addCustomOrderBtn = false;
          toastr.error('Something went wrong');
        }
      },
      (error) => {
      }
    );
  }

  convertHTMLtoText(html) {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  uploadCustomeOrderText() {
    var d = this.convertHTMLtoText(this.customOrderText);
    this.dataService.uploadCustomeOrderText(window.localStorage.getItem('token'), this.businessId, d, this.customOrderImageSuccessUrl, this.userCurrentAddress).subscribe(
      (data) => {
        this.customOrderSuccess = data;
        if (this.customOrderSuccess.success === true) {
          toastr.success('Your request has been successfuly sent. Our team will contact you soon.');
          $('#customOrderPopup').modal('hide');
          this.addCustomOrderBtn = false;
          this.customOrderImageSuccessUrl = new Array();
          this.customProductImages = new Array();
        }
      },
      (error) => {
      }
    );
  }

  clearBlur() {
    $('#customOrderPopup').css('-webkit-filter', 'blur(0px)');
    $('#customOrderPopup').css('-moz-filter', 'blur(0px)');
    $('#customOrderPopup').css('-o-filter', 'blur(0px)');
    $('#customOrderPopup').css('-ms-filter', 'blur(0px)');
    $('#customOrderPopup').css('filter', 'blur(0px)');
  }

  addBlur() {
    $('#customOrderPopup').css('-webkit-filter', 'blur(3px)');
    $('#customOrderPopup').css('-moz-filter', 'blur(3px)');
    $('#customOrderPopup').css('-o-filter', 'blur(3px)');
    $('#customOrderPopup').css('-ms-filter', 'blur(3px)');
    $('#customOrderPopup').css('filter', 'blur(3px)');
  }

  clearBlurbusinessDetail() {
    $('#businessDetail').css('-webkit-filter', 'blur(0px)');
    $('#businessDetail').css('-moz-filter', 'blur(0px)');
    $('#businessDetail').css('-o-filter', 'blur(0px)');
    $('#businessDetail').css('-ms-filter', 'blur(0px)');
    $('#businessDetail').css('filter', 'blur(0px)');
  }

  addBlurbusinessDetail() {
    $('#businessDetail').css('-webkit-filter', 'blur(3px)');
    $('#businessDetail').css('-moz-filter', 'blur(3px)');
    $('#businessDetail').css('-o-filter', 'blur(3px)');
    $('#businessDetail').css('-ms-filter', 'blur(3px)');
    $('#businessDetail').css('filter', 'blur(3px)');
  }

  clearBlurSeeMorePopup() {
    $('#SeeMorePopup').css('-webkit-filter', 'blur(0px)');
    $('#SeeMorePopup').css('-moz-filter', 'blur(0px)');
    $('#SeeMorePopup').css('-o-filter', 'blur(0px)');
    $('#SeeMorePopup').css('-ms-filter', 'blur(0px)');
    $('#SeeMorePopup').css('filter', 'blur(0px)');
  }

  addBlurSeeMorePopup() {
    $('#SeeMorePopup').css('-webkit-filter', 'blur(3px)');
    $('#SeeMorePopup').css('-moz-filter', 'blur(3px)');
    $('#SeeMorePopup').css('-o-filter', 'blur(3px)');
    $('#SeeMorePopup').css('-ms-filter', 'blur(3px)');
    $('#SeeMorePopup').css('filter', 'blur(3px)');
  }

  clearBlurbusinessAll() {
    $('#businessAll').css('-webkit-filter', 'blur(0px)');
    $('#businessAll').css('-moz-filter', 'blur(0px)');
    $('#businessAll').css('-o-filter', 'blur(0px)');
    $('#businessAll').css('-ms-filter', 'blur(0px)');
    $('#businessAll').css('filter', 'blur(0px)');
  }

  addBlurbusinessAll() {
    $('#businessAll').css('-webkit-filter', 'blur(3px)');
    $('#businessAll').css('-moz-filter', 'blur(3px)');
    $('#businessAll').css('-o-filter', 'blur(3px)');
    $('#businessAll').css('-ms-filter', 'blur(3px)');
    $('#businessAll').css('filter', 'blur(3px)');
  }
  getSelectedTabDetail(event){
    this.getCategoryBusiness(this.myBusinessCategories.business_list[event.index].id, this.myBusinessCategories.business_list[event.index].name);
  }
  // getCategoryBusiness(category.id, category.name);
  iniatlizeSlider() {
    // this.showBusinessProductsliderLoading = true;
    // setTimeout(() => {
    //   var owl = $('.owlProductsSlider');
    //   owl.owlCarousel({
    //     loop: false,
    //     mouseDrag: false,
    //     touchDrag: false,
    //     pullDrag: false,
    //     dots: false,
    //     navSpeed: 700,
    //     navText: ['<img src="../../assets/arrowLeft.png" style="width: 50px">', '<img src="../../assets/arrowRight.png" style="width: 50px">'],
    //     responsive: {
    //       0: {
    //         items: 2.5
    //       },
    //       560: {
    //         items: 3.5
    //       },
    //       768: {
    //         items: 4.5
    //       },
    //     },
    //     nav: true
    //   });
    //   $('#catID').css('visibility', 'visible');
    //   owl.on('changed.owl.carousel', function (data) {

    //     var position = data.page.size + data.property.value;
    //     if (data.item.count - position === 0 || data.item.count - position === 0.5) {
    //       $('#' + data.currentTarget.id + ' .owl-next').addClass('disabled');
    //       $('#' + data.currentTarget.id + ' .owl-next').addClass('visibility-hidden');
    //     } else {
    //       $('#' + data.currentTarget.id + ' .owl-next').removeClass('disabled');
    //       $('#' + data.currentTarget.id + ' .owl-next').removeClass('visibility-hidden');
    //     }

    //     if (data.item.index > 0) {
    //       $('#' + data.currentTarget.id + ' .owl-prev').removeClass('visibility-hidden');
    //     }
    //     if (data.item.index === 0) {
    //       $('#' + data.currentTarget.id + ' .owl-prev').addClass('visibility-hidden');
    //     }


    //     // if (data.page.size === 2.5 && data.item.index === 4) {
    //     //   $('#' + data.currentTarget.id + ' .owl-next').addClass('disabled');
    //     //   $('#' + data.currentTarget.id + ' .owl-next').addClass('visibility-hidden');
    //     // }
    //     // if (data.page.size === 2.5 && data.item.index === 3) {
    //     //   $('#' + data.currentTarget.id + ' .owl-next').removeClass('disabled');
    //     //   $('#' + data.currentTarget.id + ' .owl-next').removeClass('visibility-hidden');
    //     // }
    //     // if (data.page.size === 3.5 && data.item.index === 3) {
    //     //   $('#' + data.currentTarget.id + ' .owl-next').addClass('disabled');
    //     //   $('#' + data.currentTarget.id + ' .owl-next').addClass('visibility-hidden');
    //     // }
    //     // if (data.page.size === 3.5 && data.item.index === 2) {
    //     //   $('#' + data.currentTarget.id + ' .owl-next').removeClass('disabled');
    //     //   $('#' + data.currentTarget.id + ' .owl-next').removeClass('visibility-hidden');
    //     // }
    //     // if (data.page.size === 4.5 && data.item.index === 2) {
    //     //   $('#' + data.currentTarget.id + ' .owl-next').addClass('disabled');
    //     //   $('#' + data.currentTarget.id + ' .owl-next').addClass('visibility-hidden');
    //     // }
    //     // if (data.page.size === 4.5 && data.item.index === 1) {
    //     //   $('#' + data.currentTarget.id + ' .owl-next').removeClass('disabled');
    //     //   $('#' + data.currentTarget.id + ' .owl-next').removeClass('visibility-hidden');
    //     // }
    //     // if (data.item.index > 0) {
    //     //   $('#' + data.currentTarget.id + ' .owl-prev').removeClass('visibility-hidden');
    //     // }
    //     // if (data.item.index === 0) {
    //     //   $('#' + data.currentTarget.id + ' .owl-prev').addClass('visibility-hidden');
    //     // }
    //   });
    // }, 500);
    // setTimeout(() => {
    //   // this.showBusinessProductsliderLoading = false;
    //   $('.owl-nav').css('visibility', 'hidden');
    //   $('.owl-prev').addClass('visibility-hidden');
    // }, 500);
  }

  mouseEnterOnSlider(index) {
    $('#carouselSlider' + index + ' .owl-nav').css('visibility', 'visible');
  }

  mouseLeaveOnSlider(index) {
    $('#carouselSlider' + index + ' .owl-nav').css('visibility', 'hidden');
  }

  CategoryListing() {
    this.dataService.getCategoryList(window.localStorage.getItem('token'), this.businessId).subscribe(
      (data) => {
        this.catList = data;
      },
      (error) => {
      }
    );
  }

  subCategoryListing() {
    this.subCatData = [];
    this.dataService.getSubCategoryList(window.localStorage.getItem('token'), this.businessId, this.cat_id).subscribe(
      (data) => {
        this.subCatData = data;
        this.iniatlizeSubCatSlider();
        this.subCatLength = this.subCatData.length;
        setTimeout(() => {
          $('#subCategorySlider').css('visibility', 'visible');
          this.loadingDataCat = false;
          this.subcatLoadingShimmer = true;
          this.getBusinessCategoryProducts();
        }, 1000);
      },
      (error) => {
      }
    );
  }

  catidSend(id) {
    this.subCatid = id;
    this.getBusinessCategoryProducts();
  }

  iniatlizeSubCatSlider() {
    // this.subCatslider = false;
    var context = this;


    // setTimeout(() => {
    //   $(function () {
    //     context.owl2 = $('.subCategorySlider');
    //     context.owl2.trigger('initialized');
    //     context.owl2.owlCarousel({
    //       loop: false,
    //       mouseDrag: false,
    //       touchDrag: false,
    //       pullDrag: false,
    //       dots: false,
    //       navSpeed: 700,
    //       navText: ['<img src="../../assets/arrowLeft.png" style="width: 35px">', '<img src="../../assets/arrowRight.png" style="width: 35px">'],
    //       responsive: {
    //         0: {
    //           items: 3
    //         },
    //         420: {
    //           items: 4
    //         },
    //         560: {
    //           items: 4
    //         },
    //         950: {
    //           items: 5
    //         },
    //       },
    //       nav: true,
    //     });
    //     $('.subCategorySlider .owl-prev').addClass('visibility-hidden');
    //     context.owl2.on('changed.owl.carousel', function (data) {
    //       var position = data.page.size + data.property.value;
    //       if (data.item.count - position === 0) {
    //         $('.subCategorySlider .owl-next').addClass('disabled');
    //         $('.subCategorySlider .owl-next').addClass('visibility-hidden');
    //       } else {
    //         $('.subCategorySlider .owl-next').removeClass('disabled');
    //         $('.subCategorySlider .owl-next').removeClass('visibility-hidden');
    //       }

    //       if (data.item.index > 0) {
    //         $('.subCategorySlider .owl-prev').removeClass('visibility-hidden');
    //       }
    //       if (data.item.index === 0) {
    //         $('.subCategorySlider .owl-prev').addClass('visibility-hidden');
    //       }
    //     });
    //   });
    // }, 500);
    // setTimeout(() => {
    //   this.subCatslider = false;
    // }, 500);
  }

  InsubCategorySlider() {
    if (this.widthOfWindow > 420) {
      $('.subCategorySlider .owl-nav').css('visibility', 'visible');
    }
  }


  LeavesubCategorySlider() {

    if (this.widthOfWindow > 420) {
      $('.subCategorySlider .owl-nav').css('visibility', 'hidden');
    }
  }


  activeClassFunc(index) {
    $('.animateRemoveClass').removeClass('animateImg');
    this.subcatLoadingShimmer = true;
    setTimeout(() => {
      $('.subCatTile').removeClass('subCatTextdis');
      $('.subCatTile').addClass('none');
      $('.subCatImg').removeClass('subCatimgMinOpacity');
      $('.subCatImg').addClass('subCatimgMaxOpacity');

      $('#cross' + index).removeClass('none');
      $('#cross' + index).addClass('subCatTextdis');
      $('#subCatImg' + index).addClass('subCatimgMinOpacity');
      $('#subCatText' + index).addClass('subCatimgMinOpacity');
    }, 100);
  }

  activeCrossFunc(index) {
    $('.animateRemoveClass').addClass('animateImg');
    this.subcatLoadingShimmer = true;
    this.subCatid = -1;
    this.getBusinessCategoryProducts();
    $('#cross' + index).removeClass('subCatTextdis');
    $('#cross' + index).addClass('none');
    $('.opac').removeClass('subCatimgMaxOpacity');
    $('.opac').addClass('subCatimgMinOpacity');
  }

  addFadeInAnimation(animation, targetClass) {
    if (this.widthOfWindow > 420) {
      if ($('#' + targetClass).hasClass(animation) === false) {
        $('#' + targetClass).addClass(animation);
      } else {
      }
      setTimeout(() => {
        $('#' + targetClass).removeClass(animation);
      }, 1000);
    }

  }

  disableBackground() {
    $('html').css('overflow-y', 'hidden');
  }

  enableBackground() {
    $('html').css('overflow-y', 'auto');
  }
  myDeliveryAddress: any;


  myBusinessCategories:any;
  myCurrentCategoryId:any;
  myCategoryBusinesses:any;
  myCurrentCategoryName:any;
  businessCategoryPageLimit:any = 40;
  getBusinessCategories() {
    this.isCategoryLoad = false;
    this.isBusinessesLoad = false;
    this.searchString = "";
    this.businessCategoryPageNo = 0;
    this.lastPageOfBusinessCategory = true;
    this.loadingData = true;
    this.dataService.getBusinessCategories(this.id).subscribe(
      (data) => {
        this.myBusinessCategories = data;
        let isMyBusinessPage = this.router.url.startsWith("/businesses/");
        if(isMyBusinessPage){
          this.myBusinessCategories = data;

          // if(this.myBusinessCategories.rest_error_code === 1){
          //   this.router.navigate(['/notfound']);

          //   localStorage.setItem('myDeliveryAddress', "Please Select your location");
          //   localStorage.setItem('delivery_address', "Please Select your location");
          //   localStorage.setItem('profileAddress', JSON.stringify(null));
          //   localStorage.setItem('profileAddressCheckout', JSON.stringify(null));
          //   return;
          // }

          if(this.myBusinessCategories.status !== undefined) {
            if(this.myBusinessCategories.status == 'notfound'){

              localStorage.setItem('myDeliveryAddress', "Please Select your location");
              localStorage.setItem('delivery_address', "Please Select your location");
              localStorage.setItem('profileAddress', JSON.stringify(null));
              localStorage.setItem('profileAddressCheckout', JSON.stringify(null));
              this.router.navigate(['/notfound']);

              return;
            }

            if(this.myBusinessCategories.status == 'onboarding'){

              localStorage.setItem('myDeliveryAddress', this.id);
              localStorage.setItem('delivery_address', this.id);
              localStorage.setItem('profileAddress', JSON.stringify(null));
              localStorage.setItem('profileAddressCheckout', JSON.stringify(null));
              this.myCurrentCategoryName = '';
              this.myBusinessCategories = '';
              this.cityName = this.id;
              this.business_category_list = [];
              this.businessAbout = '';
              setTimeout(() => {
                if (this.businessAbout != undefined) {
                  document.getElementById('businessAbout').innerHTML = "";
                }
              }, 10);
              this.searchItem = false;
              this.isBusinessesLoad = true;
              $('#showZipPop').modal('hide');
              $('#headAddress').modal('hide');
              this.loadingData = false;
              return;
            }
          }
          else{
            if(this.myBusinessCategories.business_list.length > 0){
              // call first business outlets
              this.profileAddress = JSON.parse(localStorage.getItem('profileAddress'));
              if(this.profileAddress != 'null' && this.profileAddress != null){
                if(this.profileAddress.city.toLowerCase() !== this.id.toLowerCase()){
                  localStorage.setItem('myDeliveryAddress', this.id);
                  localStorage.setItem('delivery_address', this.id);
                  localStorage.setItem('profileAddress', JSON.stringify(null));
                  localStorage.setItem('profileAddressCheckout', JSON.stringify(null));
                }

              }
              if(this.profileAddress == 'null' || this.profileAddress == null){
                localStorage.setItem('myDeliveryAddress', this.id);
                localStorage.setItem('delivery_address', this.id);
                localStorage.setItem('profileAddress', JSON.stringify(null));
                localStorage.setItem('profileAddressCheckout', JSON.stringify(null));

              }


              let myaddr = localStorage.getItem('delivery_address');
              if(myaddr == "Please Select your location"){
                localStorage.setItem('myDeliveryAddress', this.id);
                localStorage.setItem('delivery_address', this.id);
                localStorage.setItem('profileAddress', JSON.stringify(null));
                localStorage.setItem('profileAddressCheckout', JSON.stringify(null));
              }
              // this.businessAbout = this.myBusinessCategories.city_description;



              if (this.business_store_category != null)
              {
                this.myCurrentCategoryId = +this.business_store_category;
                this.myCurrentCategoryName = this.business_store_category_name;

                this.myBusinessCategories.business_list.forEach( (element,index) => {
                  if (element.id == this.myCurrentCategoryId)
                  {
                    this.selectedIndexMobile = index;
                  }
              });


              }
              else
              {
                this.myCurrentCategoryId = this.myBusinessCategories.business_list[0].id;
                this.myCurrentCategoryName = this.myBusinessCategories.business_list[0].name;
              }

              // this.business_category_list = this.myBusinessCategories.business_list;
              this.cityName = this.myBusinessCategories.city_name;
              // this.myBusinessCategories = this.myBusinessCategories.business_category_list;

              // this.loadingData = false;
              let selectedLat = '';
              let selectedLong = '';
              let lat = '';
              let long = '';
              const profileData = JSON.parse(localStorage.getItem('profileAddress'));
              if(profileData != null) {
                if(profileData.latitude != undefined) {
                  selectedLat = profileData.latitude;
                }
                if(profileData.longitude != undefined) {
                  selectedLong = profileData.longitude;
                }
                if(profileData.latitude != undefined) {
                  lat = profileData.latitude;
                }
                if(profileData.longitude != undefined) {
                  long = profileData.longitude;
                }
              }
              this.dataService.getBusinessCategoryOutlet(localStorage.getItem('token'), this.businessCategoryPageNo, this.businessCategoryPageLimit, this.id, this.myCurrentCategoryId,this.searchString , -1, lat, long, selectedLat, selectedLong, -1, -1, false, false).subscribe(
                (data) => {



                  this.myCategoryBusinesses = data;
                  this.lastPageOfBusinessCategory = this.myCategoryBusinesses.last_page;
                  this.business_category_list = this.myCategoryBusinesses.items;
                  setTimeout(() => {
                    document.getElementById('businessAbout').innerHTML = this.myBusinessCategories.city_description;
                  }, 10);

                  $('#showZipPop').modal('hide');
                  $('#headAddress').modal('hide');
                  this.loadingData = false;
                  this.isBusinessesLoad = true;
                  this.isCategoryLoad = true;
                  if(this.business_category_list.length == 0){
                    // show onboard
                    this.notFoundMessageType = "on_board";
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
            }

          }

        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  isCategoryLoad:boolean = false;
  isBusinessesLoad :any = false;
  lastPageOfBusiness:any = true;

  getCategoryBusiness(categoryId, categoryName){

    this.myCurrentCategoryId = categoryId;
    this.myCurrentCategoryName = categoryName;

    this.dataControlService.emitFilterValues.next(true);

    // this.loadingData = true;
    // this.searchString = "";
    // this.businessCategoryPageNo = 0;
    // this.lastPageOfBusinessCategory = true;

    // clearInterval(this.clearTimeout1);
    // clearTimeout(this.clearTimeout);
    // // auth_token, page, limit, city_id, business_category_id, search_string, filter_type, latitude, longitude
    // this.dataService.getBusinessCategoryOutlet(localStorage.getItem('token'), 0, this.businessCategoryPageLimit, this.id, this.myCurrentCategoryId, this.searchString, -1, "","", -1, -1, false, false).subscribe(
    //   (data) => {
    //     this.dataControlService.filterSearchString.next('');

    //     this.loadingData = false;
    //     this.myCategoryBusinesses = data;
    //     this.lastPageOfBusinessCategory = this.myCategoryBusinesses.last_page;
    //     this.business_category_list = this.myCategoryBusinesses.items;
    //     this.searchItem = false;

    //   },
    //   (error) => {
    //   }
    // );


  }
  businessCategoryBypage:any = [];
  businessCategoryPageNo:any = 0;
  lastPageOfBusinessCategory:boolean = true;
  loadMoreBusinessesByCategory(){
    clearInterval(this.clearTimeout1);
    this.businessCategoryBypage = [];
    // const cartData = JSON.parse(localStorage.getItem('cartItems'));
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    // this.SeeAllLoader = false;
    this.businessCategoryPageNo++;
    // this.dataService.businessCategorySearchProduct(this.businessId, this.getProductsViaCategoryLimit, this.businessCategoryPageNo, this.cat_id, this.searchStringCat, this.subCatid, token)
    const profileData = JSON.parse(localStorage.getItem('profileAddress'));
    let selectedLat = profileData.latitude;
    let selectedLong = profileData.longitude;
    let lat = profileData.latitude;
    let long = profileData.longitude;
    this.dataService.getBusinessCategoryOutlet(token, this.businessCategoryPageNo, this.businessCategoryPageLimit, this.id, this.myCurrentCategoryId, this.searchString, -1, lat, long, selectedLat, selectedLong, -1, -1, false, false)
    .subscribe(
      (data) => {
        this.SeeAllLoader = false;
        this.businessCategoryBypage = data;
        this.lastPageOfBusinessCategory = this.businessCategoryBypage.last_page;
        this.loadingDataCat = false;
        // this.detailProductSuccess = this.businessCategoryBypage.success;
        this.businessCategoryBypage = this.businessCategoryBypage.items;

        for (var k = 0; k < this.businessCategoryBypage.length; k++) {
          this.myCategoryBusinesses.items.push(this.businessCategoryBypage[k]);
        }
      },
      (error) => {
        this.nao.go(100);
      }
    );

  }
  //=================================
  BannerNoticeMessageFunction(){
    this.dataService.BannerNoticeMessage(this.id)
    .subscribe(
      (data) => {
        this.noticeMessage= data;

        this.noticeSuccess = this.noticeMessage.success;
        this.showNoticeWeb = this.noticeMessage.show_on_web
        if (this.noticeSuccess == true)
        {
          if( this.showNoticeWeb == true)
          {
            this.showBanner = true;
            this.webNotice = this.noticeMessage.web_notice;
          }
          else {
            this.showBanner = false;
          }


        }
        else {
          this.showBanner = false;
          this.webNotice = null;
        }

       },
      (error) => {
        this.nao.go(100);
      }
    );
    }
  //=================================
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll() {
    if($(window).scrollTop() + $(window).height() > $(document).height() - 200) {
      if (this.lastPageOfBusinessCategory === false) {
        this.SeeAllLoader = true;
        var scrollHeight = $('#myCategoryBusiness').height();
        this.scrollPosition = $('#myCategoryBusiness').height() + $('#myCategoryBusiness').scrollTop();

        this.lastPageOfBusinessCategory = true;
        this.loadingDataCat = true;
        setTimeout(() =>{
          this.loadMoreBusinessesByCategory();
        }, 100)
      }

    }

  }

  business_store_category:any = -1;
  business_store_category_name:any = -1;
  selectedIndexMobile:any = 0;

  ngOnInit() {
    // this.getBusinessCategories();
    this.userCurrentAddress = JSON.parse(window.localStorage.getItem('profileAddressCheckout'));
    this.BannerNoticeMessageFunction();
    this.business_store_category = localStorage.getItem('business_store_category');
    this.business_store_category_name = localStorage.getItem('business_store_category_name');
    localStorage.removeItem('business_store_category');
    localStorage.removeItem('business_store_category_name');

    $('.bigFooterSection').css('top', '0');
    $('.myMobileFooter').css('top', '0');

    this.myDeliveryAddress = localStorage.getItem("myDeliveryAddress");
    localStorage.setItem('isFirstTimeHomeOpen', "false");

    $('.CatImg').hover(
      function () { $(this).addClass('fadeIn') },
      function () { $(this).removeClass('fadeIn') }
    )

    var contax = this;


    $('#scrollId').on('scroll', function () {
      if (contax.conditionToCheck === true && contax.businessCatLoding === false && contax.businessDetailProductSearchString === '') {
        if (contax.lastPage === false) {
          contax.loaderCondition = true;
          if (($(this).scrollTop() + $(this).innerHeight()) >= ($(this)[0].scrollHeight - 500)) {
            contax.lastPage = true;
            setTimeout(() => {
              contax.loadMoreFunction();
            }, 100);
          }
        }
      }

      if (contax.businessDetailProductSearchString !== '' && contax.businessCatLoding === false) {
        if (contax.searchProductByCategoryByIDLastPage === false) {
          contax.loaderCondition = true;
          if (($(this).scrollTop() + $(this).innerHeight()) >= ($(this)[0].scrollHeight - 20)) {
            contax.searchProductByCategoryByIDLastPage = true;
            setTimeout(() => {
              contax.loadMoreFunctionByCategoryBySearch();
            }, 100);
          }
        }
      }

    });

    $('#SearchProductsByCategory').on('scroll', function () {
      if (contax.lastPageOfCategory === false) {
        contax.SeeAllLoader = true;
        if (($(this).scrollTop() + $(this).innerHeight()) >= ($(this)[0].scrollHeight - 20)) {
          contax.lastPageOfCategory = true;
          setTimeout(() => {
            contax.loadMoreFunctionByCategory();
          }, 100);
        }
      }
    });

    this.widthOfWindow = $(window).width();

    $('#businessCatSearch').keyup(this.throttle(function () {
      contax.loadingDataMore = true;
      contax.openAllBusinessPopup();
    }));
    this.getStoryListing();
    for (let z = 0; z < this.businessRaiting2; z++) {
      this.businessRaitingArray2.push({
        value: 'true'
      });
    }
    this.get_pending_rating();

    $('.addCartShort').css('-webkit-filter', 'blur(0px)');
    $('.addCartShort').css('-moz-filter', 'blur(0px)');
    $('.addCartShort').css('-o-filter', 'blur(0px)');
    $('.addCartShort').css('-ms-filter', 'blur(0px)');
    $('.addCartShort').css('filter', 'blur(0px)');
    const context = this;

    $('#addCartPop').on('hidden.bs.modal', function () {
      $('.addCartShort').css('-webkit-filter', 'blur(0px)');
      $('.addCartShort').css('-moz-filter', 'blur(0px)');
      $('.addCartShort').css('-o-filter', 'blur(0px)');
      $('.addCartShort').css('-ms-filter', 'blur(0px)');
      $('.addCartShort').css('filter', 'blur(0px)');
      context.enableBackground();

    });
    $('#weAreClosed').on('hidden.bs.modal', function () {
      $('.addCartShort').css('-webkit-filter', 'blur(0px)');
      $('.addCartShort').css('-moz-filter', 'blur(0px)');
      $('.addCartShort').css('-o-filter', 'blur(0px)');
      $('.addCartShort').css('-ms-filter', 'blur(0px)');
      $('.addCartShort').css('filter', 'blur(0px)');
      context.enableBackground();

    });
    $('#businessAll').on('hidden.bs.modal', function () {
      context.enableBackground();
    });
    $('#onOnleBusinessCart').on('hidden.bs.modal', function () {
      $('#addCartPop').css('-webkit-filter', 'blur(0px)');
      $('#addCartPop').css('-moz-filter', 'blur(0px)');
      $('#addCartPop').css('-o-filter', 'blur(0px)');
      $('#addCartPop').css('-ms-filter', 'blur(0px)');
      $('#addCartPop').css('filter', 'blur(0px)');
      context.enableBackground();

    });
    $('#onOnleBusinessCart').on('show.bs.modal', function () {
      $('#addCartPop').css('-webkit-filter', 'blur(5px)');
      $('#addCartPop').css('-moz-filter', 'blur(5px)');
      $('#addCartPop').css('-o-filter', 'blur(5px)');
      $('#addCartPop').css('-ms-filter', 'blur(5px)');
      $('#addCartPop').css('filter', 'blur(5px)');
    });

    $(window).resize(function () {
      $(document).ready(function () {
        const width = $('.businessTile').width();
        const height = width * 0.75;
        // $('.sdetail').css('-webkit-box-orient', 'vertical');
        // $('.slick-track').css('margin-left', 0);
        // $('.slick-track').css('margin-right', 0);
        $('.businessTile').css('height', height);
      });
      setTimeout(() => {
        // $(document).ready(function () {
        //   $('.sliderList').slick({
        //
        //     // normal options...
        //     infinite: false,
        //     autoplay: false,
        //     slidesToShow: 4.5,
        //     arrows: true,
        //     slidesToScroll: 4,
        //     accessibility: false,
        //     setPosition: 0,
        //     centerMode: false,
        //     // ltr: true,
        //     // initialSlide: 0,
        //     dots: false,
        //     zIndex: 3,
        //     // the magic
        //     responsive: [{
        //
        //       breakpoint: 1024,
        //       settings: {
        //         slidesToShow: 3.5,
        //         slidesToScroll: 3,
        //         infinite: false
        //       }
        //     }, {
        //       breakpoint: 768,
        //       settings: {
        //         slidesToShow: 3.5,
        //         slidesToScroll: 2,
        //         dots: false
        //       }
        //     }, {
        //       breakpoint: 560,
        //       settings: {
        //         slidesToShow: 2.5,
        //         slidesToScroll: 2,
        //         dots: false
        //       }
        //     }, {
        //       breakpoint: 300,
        //       settings: 'unslick' // destroys slick
        //     }]
        //   });
        //   $('.slick-list').css('overflow', 'visible');
        // });
        $(document).ready(function () {
          const width = $('.businessTile').width();
          const height = width * 0.75;
          $('.sdetail').css('-webkit-box-orient', 'vertical');
          $('.slick-track').css('margin-left', 0);
          $('.slick-track').css('margin-right', 0);
          $('.businessTile').css('height', height);
        });
      }, 1000);
    });
    $('.sdetail').css('-webkit-box-orient', 'vertical');
    $(window).resize(function () {
      const width = $('.businessTile').width();
      const height = width * 0.75;
      $('.businessTile').css('height', height);
    });
    // this.getBusinessWithCatId();
    window.scrollTo(0, 0);
    $('.navbar').addClass('sticky-header');
    $('.storyBackBtn').css('display', 'none');
    $('.ds-logo').css('margin-left', '0px');
    $('.navbar').removeClass('sticky-header');
    $('.navbar').css('background-color', '#fff');
    $('.navbar').css('position', 'fixed');
    $('.logInbtn').css('color', '#FFFFFF');
    $('.navbar').css('border-bottom', '1px solid #ebebeb');
    $('#whiteLogo').css('display', 'none');
    $('#blackLogo').css('display', 'inline-block');
    $('.cartHeadBtn').css('background', this.mytabActiveColor);
    $('.cartHeadBtn').css('color', '#ffffff');
    $('.topName').css('color', this.mytabActiveColor);
    $('.username').css('color', '#000000');
    $('.business-logo').css('display', 'none');
    $('.navbar-header').css('margin-top', '0px');


    window.addEventListener('resize', () => {
      this.widthOfWindow = $(window).width();
      $('.navbar').css('background-color', '#fff');
      $('.navbar').css('position', 'fixed');
      $('.logInbtn').css('color', '#FFFFFF');
      $('.navbar').css('border-bottom', '1px solid #ebebeb');
      $('#whiteLogo').css('display', 'none');
      $('#blackLogo').css('display', 'inline-block');
      $('.cartHeadBtn').css('background', this.mytabActiveColor);
      $('.cartHeadBtn').css('color', '#ffffff');
      $('.topName').css('color', this.mytabActiveColor);
      $('.username').css('color', '#000000');
      $('.business-logo').css('display', 'none');
      $('.navbar-header').css('margin-top', '0px');
      $('.storyBackBtn').css('display', 'none');
    });
    $(window).resize(function () {
      // var width = $(window).width();

      // $('.bigFooterSection').css('top', '0');
      // $('.myMobileFooter').css('top', '0');
    });


    // var width = $(window).width();
    // if ( window.pageYOffset <= 69 && width < 769) {
    //   $('.navbar').addClass('sticky-header');
    //   $('.storyBackBtn').css('display', 'none');
    //   $('.ds-logo').css('margin-left', '0px');
    //   // $('.navbar').removeClass('sticky-header');
    //   $('.navbar').css('background-color', '#fff');
    //   $('.navbar').css('position', 'fixed');
    //   $('.logInbtn').css('color', this.mytabActiveColor);
    //   $('.navbar').css('border-bottom', '1px solid #ebebeb');
    //   $('#whiteLogo').css('display', 'none');
    //   $('#blackLogo').css('display', 'inline-block');
    //   $('.cartHeadBtn').css('background', this.mytabActiveColor);
    //   $('.cartHeadBtn').css('color', '#ffffff');
    //   $('.topName').css('color', this.mytabActiveColor);
    //   $('.username').css('color', '#000000');
    //   $('.business-logo').css('display', 'none');
    //   $('.navbar-header').css('margin-top', '0px');
    // }

    this.dataControlService.filterSearchString.next('');
    this.dataControlService.filterMaxVal.next(undefined);
    this.dataControlService.filterMinVal.next(undefined);
    this.dataControlService.inBusinessesPage.next(true);

    // this.dataControlService.setDefaultValuesInFilterComp.next(true);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event){
    $('.bigFooterSection').css('top', '0');
    $('.myMobileFooter').css('top', '0');
  }


  ngOnDestroy() {
    this.dataControlService.inBusinessesPage.next(false);
    this.dataControlService.expandPrefClicked.next(false);
    this.dataControlService.submitFilteredData.next(false);
    this.dataControlService.resetFilterWithoutAPI.next(false);
    this.pushReceive.unsubscribe();
    this.isloginValue.unsubscribe();
    this.cartPush.unsubscribe();
    this.cartChngeFromCheckout.unsubscribe();
    this.events.publish('dataChange', '');

    // $('.bigFooterSection').removeClass('addFooterTop');
    // $('.myMobileFooter').removeClass('addFooterTop');
    $('.bigFooterSection').css('top', '35rem');
    $('.myMobileFooter').css('top', '15rem');
  }

  // resetFilter() {
  //   this.filteredData = {
  //     price_range: {
  //       min_price: [500, 1000, 2000, 3000, 4000, 5000, 10000, 50000, 100000],
  //       max_price: [500, 1000, 2000, 3000, 4000, 5000, 10000, 50000, 100000],
  //     },
  //     offers:{
  //       free_delivery: false,
  //       online_payment: false
  //     }
  //     ,
  //     sort: {
  //       near_by: false,
  //       name_A_to_Z: false,
  //       recent_updated: false,
  //       most_popular: false
  //     },
  //     search: ''
  //   }
  // }
}
