import { Component, OnInit } from '@angular/core';
import {DataService} from '../data.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { AsyncLocalStorage } from 'angular-async-local-storage';
import {EventsService} from 'angular4-events';
import {format} from 'libphonenumber-js';
import { Constants } from '../constants';
import { DataControlService } from '../data-control.service';

declare var $: any;
declare var Nanobar: any;
declare var toastr: any;

@Component({
  selector: 'app-footer-landing',
  templateUrl: './footer-landing.component.html',
  styleUrls: ['./footer-landing.component.css']
})
export class FooterLandingComponent implements OnInit {

  priceIconForSingle = Constants.iconForSinglePrice;
  priceIconForDouble = Constants.iconForDoublePrice;
  nao = new Nanobar();
  id: any;
  private sub: any;
  businessDetailData1: any;
  facebooklen: any;
  twitterlen: any;
  linkdinlen: any;
  instagramlen: any;
  contactNo: any;
  managerEmail: any;
  todayData1 = new Date();
  todayData = this.todayData1.getDay();
  businessLandingalias: any;
  businessLandingaliasName: any;
  constructor(private dataControlService: DataControlService, private route: ActivatedRoute, private router: Router, private dataService: DataService,
              private localStorage: AsyncLocalStorage, private events: EventsService, private activatedRoute: ActivatedRoute) {
    // this.sub = this.route.params.subscribe(params => {
    //   this.id = params['id']; // (+) converts string 'id' to a number
    //   
    // });
    this.businessLandingalias = this.events.subscribe('businessLandingAlisa').subscribe((data) => {
      this.businessDetailData1 = data;
      if (this.businessDetailData1.country_code === '+1') {
        this.businessDetailData1.phone_number = format(this.businessDetailData1.phone_number, 'US', 'National');
      }
      if (this.businessDetailData1.country_code === '+92') {
        this.businessDetailData1.phone_number = format(this.businessDetailData1.phone_number, 'PK', 'National');
      }


      // var alias = data;
      // if( alias === '' || alias === undefined || alias === null  ) {
      //   return;
      // } else {
      //   this.id = alias;
      //   this.getBusinessDetailFooter();
      // }
    });
  }


  getBusinessDetailFooter() {
    // this.nao.go(30);
    this.dataService.businessDetailFooter(this.id, this.todayData).subscribe(
      (data) => {
        this.businessDetailData1 = data;
        this.businessDetailData1 = this.businessDetailData1.business_detail;
        if (this.businessDetailData1.country_code === '+1') {
          this.businessDetailData1.phone_number = format(this.businessDetailData1.phone_number, 'US', 'National');
        }
        if (this.businessDetailData1.country_code === '+92') {
          this.businessDetailData1.phone_number = format(this.businessDetailData1.phone_number, 'PK', 'National');
        }
      },
      (error) => {
        // this.nao.go(100);
      }
    );
  }

  getWindowWidthFooter() {
    var windowWidth =  $(window).width();
    return windowWidth;
  }
  ngOnInit() {
    localStorage.setItem('isFirstTimeHomeOpen', "false");
    //this.dataControlService.setHeaderAddressVisiblity.next(true);

  }

}

