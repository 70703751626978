import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {DataService} from '../data.service';
import {Router, ActivatedRoute} from '@angular/router';
import { AsyncLocalStorage } from 'angular-async-local-storage';
import {EventsService} from 'angular4-events';
import {MetaService} from 'ng2-meta';
import { Constants } from '../constants';
import { Location } from '@angular/common';
import {Meta, Title} from '@angular/platform-browser';
import { DataControlService } from '../data-control.service';


declare var $: any;
declare var Nanobar: any;
declare var toastr: any;
declare var url: any;

@Component({
  selector: 'app-recipe-detail',
  templateUrl: './recipe-detail.component.html',
  styleUrls: ['./recipe-detail.component.css']
})
export class RecipeDetailComponent implements OnInit {

  websiteUrl = Constants.websiteUrl;
  nao = new Nanobar();
  id: any;
  private sub: any;
  storyData: any;
  storyDataList: any;
  storyDataListNew: any;
  storyHtml: any;
  loadingData = true;
  storylimit = 3;
  storyTitle: any;
  Location: any;
  windowCurrentWidth: any;
  titleUrl: any;
  businessId: any;
  colorCode: string;
  showMoreBtn = false;
  constructor(private dataControlService: DataControlService, private router: Router, private dataService: DataService, private localStorage: AsyncLocalStorage,
              private events: EventsService, private ref: ChangeDetectorRef, private route: ActivatedRoute, private metaService: MetaService, private _location: Location,  private meta: Meta, private title: Title) {
    this.colorCode = Constants.colorCode;
    $('html').css('overflow-y' , 'auto');
    this.sub = this.route.params.subscribe(params => {
      this.titleUrl = params['id'];

      this.businessId = localStorage.getItem('businessId');
      this.showMoreBtn = false;
      this.getRecipeDetail();

    });
    this.dataService.pageName = 'businesses';
    $('.navbar').css('background-color', '#fff');
    $('.navbar').css('position', 'fixed');
    $('.logInbtn').css('color', '#FFFFFF');
    // $('.navbar').css('border-bottom', '1px solid #ebebeb');
    $('#whiteLogo').css('display', 'none');
    $('#blackLogo').css('display', 'inline-block');
    $('.cartHeadBtn').css('background', this.colorCode);
    $('.cartHeadBtn').css('color', '#ffffff');
    $('.topName').css('color', this.colorCode);
    $('.username').css('color', '#000000');
    $('.business-logo').css('display', 'none');
    $('.navbar-header').css('margin-top', '0px');
  }
  getWords(str) {
    return str.split(/\s+/).slice(0,8).join(" ");
  }
  getRecipeDetail(){
    var context = this;
    this.loadingData = true;
    this.nao.go(30);
    this.dataService.getRecipeDetail(this.titleUrl).subscribe(
      (data) => {
        window.scrollTo(0, 0);
        this.loadingData = false;
        this.nao.go(100);
        this.storyData = data;
        if ( this.storyData.rest_error_code === 4 || this.storyData.rest_error_code === 1 ) {
          this.router.navigate(['']);
        }
        this.id = this.storyData.id;
        this.titleUrl = this.storyData.title_url;
        this.getRecipeListing();
        this.storyHtml = this.storyData.html;
        this.showMoreBtn = this.storyData.is_business;
        setTimeout(() => {
          document.getElementById('previewHtml').innerHTML = this.storyHtml;
        }, 1000);
        var width = $(window).width();
        if ( width < 780 ) {
          context.storyTitle = context.storyData.title;
          context.storyTitle = context.getWords(context.storyTitle);
          context.storyTitle = context.storyTitle + '...';
        }
        if ( width > 780 ) {
          context.storyTitle = context.storyData.title;
        }
        $(window).resize(function() {
          var width = $(window).width();
          if ( width < 780 ) {
            context.storyTitle = context.storyData.title;
            context.storyTitle = context.getWords(context.storyTitle);
            context.storyTitle = context.storyTitle + '...';
          }
          if ( width > 780 ) {
            context.storyTitle = context.storyData.title;
          }
        });
         
        this.metaService.setTitle(this.storyData.title + ' | Best Buy Mall');
        this.metaService.setTag('og:image', this.storyData.images[0].url);
        this.metaService.setTag('description', this.storyData.short_desc);
        this.metaService.setTag('og:description', this.storyData.short_desc);
        this.metaService.setTag('og:type', 'website' );

        this.metaService.setTag('twitter:title', this.storyData.title + ' | Best Buy Mall');
        this.metaService.setTag('twitter:description', this.storyData.short_desc);
        this.metaService.setTag('twitter:image', this.storyData.images[0].url);
        this.metaService.setTag('twitter:card', 'summary_large_image');



        // this.meta.updateTag({ property: 'og:title', content: this.storyData.title + ' | Best Buy Mall' });
        // this.meta.updateTag({ property: 'og:image', content: this.storyData.images[0].url });
        // this.meta.updateTag({ property: 'og:url', content: this.storyData.images[0].url });
        // this.meta.updateTag({ property: 'description', content: this.storyData.short_desc });
        // this.meta.updateTag({ property: 'og:description', content: this.storyData.short_desc });
        // this.meta.updateTag({ property: 'og:site_name', content: 'bestbuymall' });
        // this.meta.updateTag({ property: 'og:type', content: 'article' });
        // this.title.setTitle(this.storyData.title + ' | Best Buy Mall');
        //
        //
        //
        // this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
        // this.meta.updateTag({ name: 'twitter:site', content: 'bestbuymall' });
        // this.meta.updateTag({ name: 'twitter:title', content: this.storyData.title + ' | Best Buy Mall' });
        // this.meta.updateTag({ name: 'twitter:description', content: this.storyData.short_desc });
        // this.meta.updateTag({ name: 'twitter:image', content: this.storyData.images[0].url });

      },
      (error) => {
        this.nao.go(100);
      }
    );
  }
  getRecipeListing(){
    var context = this;
    this.dataService.getRecipe(this.storylimit).subscribe(
      (data) => {
        this.storyDataList = data;
        this.storyDataList = this.storyDataList.items;
        this.storyDataListNew = this.storyDataList;
        for ( var i = 0; i < this.storyDataList.length; i++ ) {
          var idStory = this.id;
          idStory =  parseInt(idStory, 10);
          if ( this.storyDataList[i].id === idStory ) {
            this.storyDataListNew.splice(i, 1);
          }
        }
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }
  callPreviourPage(){
    // this._location.back();
  }
  getRecipe(id){
    window.scrollTo(0, 0);
    // this.id = id;
    // this.getStoryDetail();
    // this.getStoryListing();
  }
  ngOnInit() {
    localStorage.setItem('isFirstTimeHomeOpen', "false");
    //this.dataControlService.setHeaderAddressVisiblity.next(true);

    // this.windowCurrentWidth = $(window).width();
    // if ( this.windowCurrentWidth > 768 ) {
    //   $('.imgbox').css('margin-top', '45px');
    // }
    // window.addEventListener('resize', () => {
    //   this.windowCurrentWidth = $(window).width();
    // });

    // this.getStoryListing();
    // this.getStoryDetail();
    $('.storyBackBtn').css('display', 'inline');
    window.scrollTo(0, 0);
    // $('.navbar').removeClass('sticky-header');
    $('.navbar').css('background-color', '#fff');
    $('.navbar-brand').css('display', 'inline-block');
    $('.footer').css('display', 'inline-block');
    $('.ds-logo').css('display', 'inline-block');
    $('.ds-logo').css('margin-left', '0px');
    $('.navbar').css('position', 'fixed');
    $('.logInbtn').css('color', '#FFFFFF');
    $('.navbar').css('border-bottom', '1px solid #ebebeb');
    $('#whiteLogo').css('display', 'none');
    $('#blackLogo').css('display', 'inline-block');
    $('.cartHeadBtn').css('background', this.colorCode);
    $('.cartHeadBtn').css('color', '#ffffff');
    $('.topName').css('color', this.colorCode);
    $('.username').css('color', '#000000');
    $('.business-logo').css('display', 'none');
    $('.navbar-header').css('margin-top', '0px');

    var width = $(window).width();
    if ( width < 730 ) {
      $('.ds-logo').css('margin-left', '0px');
    }
    $(window).resize(function() {
      var width1 = $(window).width();
      if ( width1 < 730 ) {
        $('.ds-logo').css('margin-left', '0px');
      } else {
        $('.ds-logo').css('margin-left', '0px');
      }
      $('.storyBackBtn').css('display', 'inline');
      // $('.navbar').removeClass('sticky-header');
      $('.navbar').css('background-color', '#fff');
      $('.navbar-brand').css('display', 'inline-block');
      $('.footer').css('display', 'inline-block');
      $('.ds-logo').css('display', 'inline-block');
      $('.navbar').css('position', 'fixed');
      $('.logInbtn').css('color', '#FFFFFF');
      $('.navbar').css('border-bottom', '1px solid #ebebeb');
      $('#whiteLogo').css('display', 'none');
      $('#blackLogo').css('display', 'inline-block');
      $('.cartHeadBtn').css('background', this.colorCode);
      $('.cartHeadBtn').css('color', '#ffffff');
      $('.topName').css('color', this.colorCode);
      $('.username').css('color', '#000000');
      $('.business-logo').css('display', 'none');
      $('.navbar-header').css('margin-top', '0px');
    });
  }

}


