import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {DataService} from '../data.service';
import {Router, ActivatedRoute} from '@angular/router';
import { AsyncLocalStorage } from 'angular-async-local-storage';
import {EventsService} from 'angular4-events';
import {MetaService} from 'ng2-meta';
import {Constants} from '../constants';
import { DataControlService } from '../data-control.service';

declare var $: any;
declare var Nanobar: any;
declare var toastr: any;
declare var grecaptcha: any;
declare var verifyCallback: any;

@Component({
  selector: 'app-contact-us-business',
  templateUrl: './contact-us-business.component.html',
  styleUrls: ['./contact-us-business.component.css']
})
export class ContactUsBusinessComponent implements OnInit {

  nao = new Nanobar();
  name: any;
  email: any;
  subject: any;
  message: any;
  phone: any;
  error_count: any;
  emailError = '';
  is_client = true;
  contactData: any;
  successMessage: any;
  recaptchaChk = false;
  widthOfWindow: any;
  loadingData = true;
  id: any;
  policyLink: any;
  private sub: any;
  businessDetailData: any;
  businessDetalSuccess: any;
  todayData1 = new Date();
  todayData = this.todayData1.getDay();
  fromDomain = false;
  colorCode: string;

  constructor(private dataService: DataService,private events: EventsService, private route: ActivatedRoute, private router: Router, private metaService: MetaService, private dataControlService: DataControlService) {
    this.colorCode = Constants.colorCode;
    this.dataService.pageName = 'businesses';
    this.policyLink = window.location.pathname;
    this.policyLink = this.policyLink.split("/");
    this.policyLink = this.policyLink[1];
    this.events.publish('policyLink', this.policyLink);

    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
    });

    if ( window.location.hostname.includes('businessweb.bestbuymall.co') || window.location.hostname.includes(Constants.domainNmae) ) {
      this.fromDomain = true;
    } else if (window.location.hostname.includes('bestbuymall.co') || window.location.hostname.includes('bestbuymall.co') || window.location.hostname.includes('dev.bestbuymall.co') || window.location.hostname.includes('dev.bestbuymall.co') || window.location.hostname.includes('localhost') || window.location.hostname.includes('bbm.com.pk') ){
      this.fromDomain = false;
    } else {
      this.fromDomain = true;
    }
  }
  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  sendMessage() {
    this.recaptchaChk = false;
    var captcha = $( '#recaptcha' ),
      response = grecaptcha.getResponse();

    if (response.length === 0) {
      $( '.msg-error').text( 'reCAPTCHA is mandatory' );
      if( !captcha.hasClass( 'error' ) ){
        captcha.addClass( 'error' );
      }
    } else {
      $( '.msg-error' ).text('');
      captcha.removeClass( 'error' );
      // alert( 'reCAPTCHA marked' );
      this.recaptchaChk = true;
    }
    this.emailError = '';
    this.error_count = 0;
    $('.c-label label').css('color', '#3d3e3d');
    $('.c-label input').css('border-color', '#ccc');
    $('.c-label textarea').css('border-color', '#ccc');
    if (this.name === '' || this.name === undefined) {
      $('#name label').css('color', '#a10a0d');
      $('#name input').css('border-color', '#a10a0d');
      this.error_count ++;
    }
    if (this.email === '' || this.email === undefined) {
      $('#email label').css('color', '#a10a0d');
      $('#email input').css('border-color', '#a10a0d');
      this.error_count ++;
    }
    // if (this.subject === '' || this.subject === undefined) {
    //   $('#subject label').css('color', '#a10a0d');
    //   $('#subject input').css('border-color', '#a10a0d');
    //   this.error_count ++;
    // }
    if (this.message === '' || this.message === undefined) {
      $('#message label').css('color', '#a10a0d');
      $('#message textarea').css('border-color', '#a10a0d');
      this.error_count ++;
    }
    // if (this.phone === '' || this.phone === undefined || this.phone === null ) {
    //   $('#phoneno label').css('color', '#a10a0d');
    //   $('#phoneno input').css('border-color', '#a10a0d');
    //   this.error_count ++;
    // }
    if (this.error_count > 0) {
      toastr.error('Please fill all the required fields');
      return;
    }
    var mail = this.validateEmail(this.email);
    if ( mail === false ) {
      this.emailError = 'Please enter valid email address';
    } else {
      if (this.recaptchaChk === false) {
        toastr.error('Recaptcha not varified');
        return;
      } else {
        this.dataService.sendEmailToBusiness(this.name, this.email, this.phone, this.message, this.subject, this.is_client, this.id).subscribe(
          (data) => {
            this.nao.go(100);
            this.contactData = data;
            grecaptcha.reset();
            if ( this.contactData.success === true ){
              $('#showmessage').modal('show');
            }
            if ( this.contactData.success === false ){
              toastr.error('Something went wrong');
            }
            this.successMessage = this.contactData.message;
            this.name = '';
            this.email = '';
            this.message = '';
            this.subject = '';
            this.phone = '';
          },
          (error) => {
            this.nao.go(100);
          }
        );
      }
    }
  }
  chkRecapturaVal(){

  }

  getBusinessDetail() {
    // this.nao.go(30);
    this.loadingData = true;
    this.dataService.businessDetail(this.id, this.todayData).subscribe(
      (data) => {
        this.businessDetailData = data;
        if ( this.businessDetailData.rest_error_code === 4 || this.businessDetailData.rest_error_code === 1 ) {
          this.router.navigate(['notfound']);
        }
        this.loadingData = false;
        this.businessDetalSuccess = this.businessDetailData.success;
        this.businessDetailData = this.businessDetailData.business_detail;

        this.metaService.setTitle(this.businessDetailData.name + ' | Best Buy Mall');
        this.metaService.setTag('og:image', this.businessDetailData.cover_image);
        this.metaService.setTag('description', this.businessDetailData.about);
        this.metaService.setTag('og:description', this.businessDetailData.about);
        this.metaService.setTag('og:type', 'website');


        this.metaService.setTag('twitter:title', this.businessDetailData.name + ' | Best Buy Mall');
        this.metaService.setTag('twitter:description', this.businessDetailData.about);
        this.metaService.setTag('twitter:image', this.businessDetailData.cover_image);
        this.metaService.setTag('twitter:card', 'summary_large_image');


        // this.privacyPolicy = this.businessDetailData.privacy_policy;
        // if ( this.privacyPolicy === '') {
        //   this.router.navigate(['/', this.businessDetailData.alias]);
        // }
        // setTimeout(() => {
        //   document.getElementById('privacyPolicy').innerHTML = this.privacyPolicy;
        // }, 500);
        // window.localStorage.setItem('businessLogoUpdate', this.businessDetailData.business_logo_image);
        this.events.publish('businessLogoUpdate', this.businessDetailData.business_logo_image);
        this.events.publish('businessLandingAlisa', this.businessDetailData);
      },
      (error) => {
        // this.nao.go(100);
      }
    );
  }

  ngOnInit() {
    this.widthOfWindow = $(window).width();
    localStorage.setItem('isFirstTimeHomeOpen', "false");
    //this.dataControlService.setHeaderAddressVisiblity.next(true);
    this.dataControlService.headerSignInBtn.next(true);

    setTimeout(() => {
      // grecaptcha.reset();
      grecaptcha.render('recaptura12');
    }, 2000);
    window.scrollTo(0, 0);
    this.getBusinessDetail();
    $('.storyBackBtn').css('display', 'none');
    $('.ds-logo').css('margin-left', '0px');
    $('.navbar').removeClass('sticky-header');
    $('.navbar').css('background-color', '#fff');
    $('.navbar').css('position', 'fixed');
    $('.logInbtn').css('color', '#FFFFFF');
    $('.navbar').css('border-bottom', '1px solid #ebebeb');
    $('#whiteLogo').css('display', 'none');
    $('#blackLogo').css('display', 'inline-block');
    $('.cartHeadBtn').css('background', this.colorCode);
    $('.cartHeadBtn').css('color', '#ffffff');
    $('.topName').css('color', this.colorCode);
    $('.username').css('color', '#000000');
    // $('.business-logo').css('display', 'none');
    $('.navbar-header').css('margin-top', '0px');
  }

}
