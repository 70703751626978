import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customTitleCase'
})
export class CustomTitleCasePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return value? value.replace(/_/g, " ") : value;
  }

}
