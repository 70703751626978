import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from 'angular4-events';
import { MetaService } from 'ng2-meta';
import { Constants } from '../constants';
import { Location } from '@angular/common';
import { format } from 'libphonenumber-js';
import { DataControlService } from '../data-control.service';


declare var $: any;
declare var Nanobar: any;
// declare var toastr: any;
// declare var url: any;

@Component({
  selector: 'app-business-story',
  templateUrl: './business-story.component.html',
  styleUrls: ['./business-story.component.css']
})
export class BusinessStoryComponent implements OnInit {
  websiteUrl = Constants.websiteUrl;
  nao = new Nanobar();
  id: any;
  private sub: any;
  storyData: any;
  storyDataList: any;
  storyDataListNew: any;
  storyHtml: any;
  loadingData = true;
  storylimit = 3;
  storyTitle: any;
  Location: any;
  alisaName: any;
  businessDetailData1: any;
  todayData1 = new Date();
  todayData = this.todayData1.getDay();
  widthOfWindow: any;
  titleUrl: any;
  colorCode: string;

  constructor( private dataService: DataService, private events: EventsService, private route: ActivatedRoute, private metaService: MetaService, private _location: Location, private dataControlService: DataControlService) {
    this.colorCode = Constants.colorCode;
    $('html').css('overflow-y' , 'auto');
    this.sub = this.route.params.subscribe(params => {
      this.titleUrl = params['id']; // (+) converts string 'id' to a number
      this.titleUrl = this.titleUrl.split('&', 2);

      this.alisaName = this.titleUrl[1];
      this.titleUrl = this.titleUrl[0];
      this.dataService.userFrom = 'web';

      this.getStoryDetail();
    });
    this.dataService.pageName = 'businesses';
    $('.navbar').css('background-color', '#fff');
    $('.navbar').css('position', 'fixed');
    $('.logInbtn').css('color', '#FFFFFF');
    // $('.navbar').css('border-bottom', '1px solid #ebebeb');
    $('#whiteLogo').css('display', 'none');
    $('#blackLogo').css('display', 'inline-block');
    $('.cartHeadBtn').css('background', this.colorCode);
    $('.cartHeadBtn').css('color', '#ffffff');
    $('.topName').css('color', this.colorCode);
    $('.username').css('color', '#000000');
    $('.business-logo').css('display', 'none');
    $('.navbar-header').css('margin-top', '0px');
  }
  getWords(str) {
    return str.split(/\s+/).slice(0,8).join(" ");
  }
  getStoryDetail(){
    var context = this;
    this.loadingData = true;
    this.nao.go(30);
    this.dataService.getStoryDetail(this.titleUrl).subscribe(
      (data) => {
        window.scrollTo(0, 0);
        this.loadingData = false;
        this.nao.go(100);
        this.storyData = data;
        if ( this.storyData.rest_error_code === 4 || this.storyData.rest_error_code === 1 ) {
          this._location.back();
          // this.router.navigate(['']);
        }
        this.id = this.storyData.id;
        this.titleUrl = this.storyData.title_url;
        this.storyHtml = this.storyData.html;
        this.getStoryListing();
        setTimeout(() => {
          document.getElementById('previewHtml').innerHTML = this.storyHtml;
        }, 1000);
        var width = $(window).width();
        if ( width < 780 ) {
          context.storyTitle = context.storyData.title;
          context.storyTitle = context.getWords(context.storyTitle);
          context.storyTitle = context.storyTitle + '...';
        }
        if ( width > 780 ) {
          context.storyTitle = context.storyData.title;
        }
        $(window).resize(function() {
          var width = $(window).width();
          if ( width < 780 ) {
            context.storyTitle = context.storyData.title;
            context.storyTitle = context.getWords(context.storyTitle);
            context.storyTitle = context.storyTitle + '...';
          }
          if ( width > 780 ) {
            context.storyTitle = context.storyData.title;
          }
        });

        this.metaService.setTitle(this.storyData.title + ' | Best Buy Mall');
        this.metaService.setTag('og:image', this.storyData.images[0].url);
        this.metaService.setTag('description', this.storyData.short_desc);
        this.metaService.setTag('og:description', this.storyData.short_desc);

        this.metaService.setTag('twitter:title', this.storyData.title + ' | Best Buy Mall');
        this.metaService.setTag('twitter:description', this.storyData.short_desc);
        this.metaService.setTag('twitter:image', this.storyData.images[0].url);
        this.metaService.setTag('twitter:card', 'summary_large_image');


        // this.meta.updateTag({ property: 'og:title', content: this.storyData.title + ' | Best Buy Mall' });
        // this.meta.updateTag({ property: 'og:image', content: this.storyData.images[0].url });
        // this.meta.updateTag({ property: 'og:url', content: this.storyData.images[0].url });
        // this.meta.updateTag({ property: 'description', content: this.storyData.short_desc });
        // this.meta.updateTag({ property: 'og:description', content: this.storyData.short_desc });
        // this.meta.updateTag({ property: 'og:site_name', content: 'bestbuymall' });
        // this.meta.updateTag({ property: 'og:type', content: 'article' });
        // this.title.setTitle(this.storyData.title + ' | Best Buy Mall');
        //
        //
        //
        // this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
        // this.meta.updateTag({ name: 'twitter:site', content: 'bestbuymall' });
        // this.meta.updateTag({ name: 'twitter:title', content: this.storyData.title + ' | Best Buy Mall' });
        // this.meta.updateTag({ name: 'twitter:description', content: this.storyData.short_desc });
        // this.meta.updateTag({ name: 'twitter:image', content: this.storyData.images[0].url });


      },
      (error) => {
        this.nao.go(100);
      }
    );
  }
  getStoryListing(){
    var context = this;
    this.dataService.getStory(this.storylimit).subscribe(
      (data) => {
        this.storyDataList = data;
        this.storyDataList = this.storyDataList.items;
        this.storyDataListNew = this.storyDataList;
        for ( var i = 0; i < this.storyDataList.length; i++ ) {
          var idStory = this.id;
          idStory =  parseInt(idStory, 10);
          if ( this.storyDataList[i].id === idStory ) {
            this.storyDataListNew.splice(i, 1);
          }
        }
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }
  callPreviourPage(){
    // this._location.back();
  }
  getStory(id){
    window.scrollTo(0, 0);
  }
  getBusinessDetailFooter() {

    // this.nao.go(30);
    this.dataService.businessDetailFooter(this.alisaName, this.todayData).subscribe(
      (data) => {
        this.businessDetailData1 = data;
        this.businessDetailData1 = this.businessDetailData1.business_detail;
        this.events.publish('businessLogoUpdate', this.businessDetailData1.business_logo_image);
        this.events.publish('businessLandingAlisa', this.businessDetailData1);

        if (this.businessDetailData1.country_code === '+1') {
          this.businessDetailData1.phone_number = format(this.businessDetailData1.phone_number, 'US', 'National');
        }
        if (this.businessDetailData1.country_code === '+92') {
          this.businessDetailData1.phone_number = format(this.businessDetailData1.phone_number, 'PK', 'National');
        }

      },
      (error) => {
        // this.nao.go(100);
      }
    );
  }
  ngOnDestroy() {
    $('.footer-main').css('display', 'block');

  }
  ngOnInit() {
    localStorage.setItem('isFirstTimeHomeOpen', "false");
    //this.dataControlService.setHeaderAddressVisiblity.next(true);
    this.dataControlService.headerSignInBtn.next(true);

    this.dataService.userFrom = 'store';
    this.widthOfWindow = $(window).width();
    this.getBusinessDetailFooter();
    $('.storyBackBtn').css('display', 'inline');
    window.scrollTo(0, 0);
    $('.storyBackBtn').css('display', 'none');
    $('.ds-logo').css('margin-left', '0px');
    // $('.navbar').addClass('sticky-header');
    $('.navbar').css('background-color', '#fff');
    // $('.navbar').css('position', 'relative');
    $('.logInbtn').css('color', '#FFFFFF');
    $('.navbar').css('border-bottom', '1px solid #ebebeb');
    $('#whiteLogo').css('display', 'none');
    $('#blackLogo').css('display', 'inline-block');
    $('.cartHeadBtn').css('background', this.colorCode);
    $('.cartHeadBtn').css('color', '#ffffff');
    $('.topName').css('color', this.colorCode);
    $('.username').css('color', '#000000');
    $('.ds-logo').css('display', 'none');
    $('.footer-main').css('display', 'none');
    // $('.business-logo').css('display', 'inline');

    $( window ).resize(function() {
      this.widthOfWindow = $(window).width();
      $('.storyBackBtn').css('display', 'inline');
      $('.ds-logo').css('margin-left', '0px');
      // $('.navbar').removeClass('sticky-header');
      $('.navbar').css('background-color', '#fff');
      // $('.navbar').css('position', 'relative');
      $('.logInbtn').css('color', '#FFFFFF');
      $('.navbar').css('border-bottom', '1px solid #ebebeb');
      $('#whiteLogo').css('display', 'none');
      $('#blackLogo').css('display', 'inline-block');
      $('.cartHeadBtn').css('background', this.colorCode);
      $('.cartHeadBtn').css('color', '#ffffff');
      $('.topName').css('color', this.colorCode);
      $('.username').css('color', '#000000');
      $('.ds-logo').css('display', 'inline-block');
      $('.footer-main').css('display', 'none');
      // $('.business-logo').css('display', 'inline');
      $('.business-logo').css('width', '100px');

      $('.business-logo').trigger('click');
    });

    window.addEventListener('resize', () => {
      this.widthOfWindow = $(window).width();
      this.events.publish('businessLandingAlisa', this.businessDetailData1);
    });





    // $('.navbar').removeClass('sticky-header');
    // $('.navbar').css('background-color', '#fff');
    // $('.navbar-brand').css('display', 'inline-block');
    // $('.footer').css('display', 'inline-block');
    // $('.ds-logo').css('display', 'inline-block');
    // $('.ds-logo').css('margin-left', '45px');
    // $('.navbar').css('position', 'fixed');
    // $('.logInbtn').css('color', this.colorCode);
    // $('.navbar').css('border-bottom', '1px solid #ebebeb');
    // $('#whiteLogo').css('display', 'none');
    // $('#blackLogo').css('display', 'inline-block');
    // $('.cartHeadBtn').css('background', this.colorCode);
    // $('.cartHeadBtn').css('color', '#ffffff');
    // $('.topName').css('color', this.colorCode);
    // $('.username').css('color', '#000000');
    // $('.business-logo').css('display', 'none');
    // $('.navbar-header').css('margin-top', '0px');
    //
    // var width = $(window).width();
    // if ( width < 730 ) {
    //   $('.ds-logo').css('margin-left', '0px');
    // }
    // $(window).resize(function() {
    //   var width1 = $(window).width();
    //   if ( width1 < 730 ) {
    //     $('.ds-logo').css('margin-left', '0px');
    //   } else {
    //     $('.ds-logo').css('margin-left', '45px');
    //   }
    //   // $('.navbar').removeClass('sticky-header');
    //   $('.navbar').css('background-color', '#fff');
    //   $('.navbar-brand').css('display', 'inline-block');
    //   $('.footer').css('display', 'inline-block');
    //   $('.ds-logo').css('display', 'inline-block');
    //   $('.navbar').css('position', 'fixed');
    //   $('.logInbtn').css('color', this.colorCode);
    //   $('.navbar').css('border-bottom', '1px solid #ebebeb');
    //   $('#whiteLogo').css('display', 'none');
    //   $('#blackLogo').css('display', 'inline-block');
    //   $('.cartHeadBtn').css('background', this.colorCode);
    //   $('.cartHeadBtn').css('color', '#ffffff');
    //   $('.topName').css('color', this.colorCode);
    //   $('.username').css('color', '#000000');
    //   $('.business-logo').css('display', 'none');
    //   $('.navbar-header').css('margin-top', '0px');
    // });
  }

}

