import {
  Component,
  ViewChild,
  ElementRef,
  HostListener,
  ViewEncapsulation,
  OnInit,
  Input,
} from "@angular/core";
import { AuthenticationService } from "./authentication.service";
import { CountryService } from "./country.service";
import { EventsService } from "angular4-events";
import { DataService } from "./data.service";
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  ActivationEnd,
} from "@angular/router";
import { format } from "libphonenumber-js";
import { IMyDpOptions } from "mydatepicker";
import { Constants } from "./constants";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { DataControlService } from "./data-control.service";
import { FormGroup, FormBuilder } from "@angular/forms";

import { QrScannerComponent } from "angular2-qrscanner";
import { FbEventsService } from "./fb-events.service";
import { stringify, parse } from "querystring";

declare var firebase: any;
declare var x: any;
declare var Nanobar: any;
declare var context: any;
declare var toastr: any;
declare var jQuery: any;
declare var google: any;
declare var $: any;
declare var href: any;
declare var permission: any;
declare var require: any;
declare var branch: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  priceIconForSingle = Constants.iconForSinglePrice;
  priceIconForDouble = Constants.iconForDoublePrice;
  infowindowTrackPrevious: any;
  markerTrackPrevious: any;
  mapTrackPrevious: any;
  businessUrl = Constants.businessUrl;
  partnerUrl = Constants.partnerUrl;
  // @ViewChild('mapCanvas2') mapElement2: ElementRef;
  @ViewChild("mapCanvasTrack", { static: false }) mapElement3: ElementRef;
  nao = new Nanobar();
  firstName: any;
  lastName: any;
  gender: any = -1;
  dob: any;
  areaData: any;
  cityId: any;
  validateSuccessTrue: any;
  place: any;
  title = "app";
  number = "";
  numberToSend = "";
  numberToSendProfile = "";
  street = "";
  streetDelivery = "";
  houseNoDelivery = "";
  streetNoDelivery = "";
  pushReceive: any;
  address_line1: any;
  cartPush: any;
  cityname = "Pleas Select your location";
  emptyValue = "";
  getCartItems: any;
  getBusinessLogo: any;
  carBtnShowHide = false;
  cartCount = 0;
  customArray = new Array();
  cartDataFromStorage = new Array();
  cartDataFromStorageWishlist = new Array();
  chkOutTotal = 0;
  clearTimeout: any;
  placeSuggestionsData: any;
  placeSuggestionsDataDeliveryModal: any;
  placeSuggestionsDataProfile: any;
  placeDetailData: any;
  placeDetailDataDelivery: any;
  placeDetailDataProfile: any;
  placeDataFound = false;
  addressLoad = false;
  addressLoadDelivery = false;
  latitude: any;
  longitude: any;
  latitudeDelivery: any;
  longitudeDelivery: any;
  latitudeProfile: any;
  longitudeProfile: any;
  zip = "";
  cityList: any;
  validateSuccess: any;
  showCrossIcon = false;
  loadingData = true;
  maxYear: any;
  loginBtn = true;
  loginData: any;
  code: any;
  codeProfile: any;
  userToken: any;
  userName: any;
  userData: any;
  userLogoutSuccess: any;
  show_marker: any;
  cityDelivery: any;
  stateDelivery: any;
  zipDelivery = "";
  error_count = 0;
  stars = [1, 2, 3, 4, 5];
  // deliveryAddressObj:any;
  deliveryAddressObj = {
    id: "",
    street: "",
    address_line1: "",
    zip: "",
    city: "",
    country: "",
    state: "",
    latitude: "",
    longitude: "",
    apart_no: "",
    notes: "",
    house_building_no: "",
  };
  deliveryAddressObjAll: any;
  uAdress: any;
  ProfileAddressObj: any;
  areaDelivery: any;
  countryDelivery: any;
  labelDelivery = "";
  CustomelabelDelivery = "";
  CustomelabelDelivery1 = "";
  CustomelabelDeliveryError = "";
  deleteAddressSucess: any;
  checkoutData: any;
  checkoutErrorMessage = "";
  checkoutSuccess: any;
  checkoutMessage: any;
  numberToDisplay: any;
  numberToDisplayProfile: any;
  intervalSet: any;
  intervalSetTrack: any;
  reSendCount = 60;
  orderNotes = "";
  orderNotesNew = "";
  apartDelivery = "";
  resendButtonShow = false;
  codeError = "";
  infoData: any;
  status: any;
  profileData: any;
  ProfileCountry_code: any;
  ProfileDate_of_birth: any;
  ProfileEmail: any;
  ProfileApart: any;
  ProfileFirst_name: any;
  ProfileImg_url: any;
  ProfileLast_name: any;
  ProfilePhone_number: any;
  // ProfileStreet: any;
  showFname = false;
  showLname = false;
  showAddress = false;
  showDob = false;
  showEmail = false;
  emailError = "";
  codeProfileEmail: any;
  emailSendData: any;
  emailUpdateFinalData: any;
  profileImgFinal: any;
  orderListData: any = [];
  pendingOrderList: any;
  orderListDataByPage: any;
  orderLastPage = true;
  orderLastPageNo = 0;
  orderLastPageLemit = 10;
  trackData: any;
  mapTrack: any;
  directionsService: any;
  directionsDisplay: any;
  infowindowTrack: any;
  markerDrop = new Array();
  markerDrivers = new Array();
  markerTrack: any;
  orderTrackId: any;
  driverlocationData: any;
  startDriverLatLng: any;
  numDeltas = 10;
  delay = 100; // milliseconds
  r: any;
  deltaLat: any;
  deltaLng: any;
  driverMarker: any;
  previousDriverLatLng: any;
  driverPinLat: any;
  driverPinLong: any;
  driverPin: any;
  numberOfDriverLocationChecks = 0;
  currentDriverLocation = 0;
  orderDelivery_charges: any;
  orderDelivery_off_price: any;
  orderShipping_cost: any;
  DeliveredTitle: any;
  DeliveredSubTitle: any;
  cartopenPopup: any;
  userFromMob1: any;
  displayName1: any;
  checkoutDone = false;
  paymentMethod: any;
  paymentThroughCash: any;
  paymentThroughBankTransfer: any;
  paymentThroughMpos: any;
  cardNum: any;
  expiryDate: any;
  card_cvv: any;
  cardName: any;
  AddNewCardBtn = true;
  AddNewCardData: any;
  removeCardData: any;
  paymentCardData: any;
  RemovePaymentCardId: any;
  primaryDataSuccess: any;
  primaryCardNo = "";
  expiryDateError = 0;
  messages: any;
  placeholders: any;
  masks: any;
  showOrderData: boolean = false;
  phone_number: any;
  showloder = "pin.svg";
  cancelOrderSuccess: any;
  showHideTextLoader: any;
  cancelOrderId: any;
  showHideImageSlider = false;
  paymentOverDue: any;
  paymentThroughCard: any;
  paymentMethodOptionValue = -1;
  paymentMethodOptionValue2nd = -1;
  paymentMethodOptionBtn = true;
  profileNoChangeCountryCode: any;
  deliveryAddressSubmit = false;
  deliveryAddressSearchLoader = false;
  imageCroperShowHide: any;
  apart_no: any;
  primaryCardValue: any;
  businessId: any = "-1";
  businessDetailData: any;
  businessDisclaimer: any;
  cardType: any;
  businessName: any;
  businessLandingalias: any;
  businessLandingaliasName: any;
  businessLandingData: any;
  cashImg = true;
  businessRaitingArray = [];
  businessRaiting = 5;
  pendingRating: any;
  ratingData: any;
  dispensdispensaryName: any;
  driverData: any;
  driverFirstName: any;
  driverLastName: any;
  driverRatingValue = 0;
  businessRatingValue = 0;
  feedBack = "";
  rating_id: any;
  dispensary_rating_id: any;
  cancelRatingData: any;
  cancelRatingSuccess: any;
  sendRatingData: any;
  sendRatingSuccess: any;
  cancelRatingValue = 0;
  businessTax: any;
  checkoutFinalErrorMessageOutOfStock = "";
  checkoutFinalErrorMessagePriceUpdate = "";
  checkoutFinalErrorMessageDeliveryUpdate = "";
  ratingPopUpData: any;
  driver_img: any;
  is_third_party: boolean;
  cancelRatingId: any;
  checkoutFinalErrorCount = 0;
  pushRecived: any;
  pushRecivedVal: any;
  ProfileAddress: any;
  locationError: any;
  openLoginFunction: any;
  openLoginFunctionVal: any;
  wishlistData: any;
  wishlistDataByPage: any;
  wishlistDataLastPage = true;
  limitWishList = 28;
  wishlistSearchString: "";
  wishlistSearchItem = true;
  wishlistItemTimeOut: any;
  addWishlistSuccess: any;
  wishlistDetailData: any;
  wishlistSuccess: any;
  cartFinalWishlist = new Array();
  cartPopDataWishlist: any;
  cartPopDataAll: any;
  variantData: any;
  outletAddress: any;
  businessStatus = "";
  openOrCloseIn = "";
  finalDisplayTime = "";
  dateObjOpen: any;
  dateObjClose: any;
  currentSystemTime: any;
  objToOveride: any;
  indexToOveride: any;
  disableMultiClick = false;
  detailProductIndex = false;
  showWishlistSearch = false;
  detailProductData: any;
  removeToWishlistObj: any;
  removeToWishlistIndex: any;
  customerProfileListApp: any;
  editAddress = false;
  editAddressId: any;
  nearAddressObj: any;
  checkoutPopupOpen = false;
  checkoutPopupOpenForAddress = false;
  showLoderAtCheckoutAddress = false;
  registerBtnDisable = false;
  opencheckoutButemptyAddress = false;
  userFrom: any;
  hostName: any;
  count: any;
  ProfileDateDay: any;
  ProfileDateMonth: any;
  ProfileDateYear: any;
  profileImageLoading = false;
  ImageCroppedEvent: any;
  updateProfileData: any;
  OrderDetailPopup = false;
  customerProfileListAddressDictionary: any;
  addressDictionary: any;
  dateRester = false;

  timeSlotDetail: any;
  timeSlotDetailOrignal: any;
  timeSlotDetail_delivery: any;
  timeSlotDetail_delivery_show = new Array();
  outletIdToSendInCheckout: any;
  timeSlotDetail_delivery_charges = 0;
  dayOfGivenTime = "";
  dayIndex: any;
  time: any;
  overnight = "";
  charges: any;

  over_night_charges: any;
  timeOverNightCharges: any;
  days_charges: any;
  third_party_string: any;
  disabledBtnForLoadingSlotData = false;
  outOfStockChkData: any;
  slotsChkStockError = "";
  businessTypeFromSlotRequest: any;
  slotSuccess: any;
  slotSuccessMessage = "";
  showAddressInFrontOfWagonIcon = false;
  current_day: any;
  addressPopupValue = "";
  wishlistDetailDataImagesLength: any;
  timeSlotDetail_delivery_charges_actual = 0;
  themeStructureId: any = -1;
  widthOfWindow: any;

  should_load_business = false;
  policyLink: any;
  showGender: any;
  allProd: any;
  objProd: any;
  valProd: any;
  updateCart: boolean = false;
  fromWishlist: boolean = false;
  fromCart: boolean = false;
  custom_order_form: any;
  business_id_customerOrder: any;
  orderCustomizeDetail: any;
  listing: any;
  customProductImages: any;
  groupOptions: string;
  finalOrderText: string = "";
  userCurrentAddress: any;
  addressUpdate: any;
  customeTitle: any;
  customTitle: any;
  customImage: any;
  qrCode: any = "false";
  landedFromQR: boolean = false;
  tableNumber: any = -1;
  type: string;
  dineIn: any = true;
  selfPickup: any = true;
  delivery: any = true;
  dineinPayment: any = "-1";
  taxInfoProd: any;
  dineinOutletId: any;
  outlets: any;
  pickupAddress: any;
  pickupOutlet: boolean;
  pickupAddressId: any;
  pickupAddressString: any;
  dineinAddressString: any;
  isDineinAvailable: any;
  isPickupAvailable: any;
  scanQR: any = true;
  pickupNotes: any;
  checkOutState: number;
  appData: string;
  checkQrStatus: number;
  customSigninBtn: boolean;
  loadNavBar: boolean = false;
  orderMediumType: any;
  isFromHistory: boolean = false;
  cameraDevice: number = 0;
  showOrderMediumNav: boolean = true;
  showDownloadBanner: boolean = true;
  selectedLatitude: any;
  selectedLongitude: any;
  checkoutOpenCheck: boolean = false;
  imageForPopup: any[] = [];
  pendingPayment: any;
  paymentConfirmOrder: any;
  reConfirmPayment: string = "";
  get globalAddress(): any {
    return localStorage.getItem("delivery_address");
  }

  get authChk(): any {
    return localStorage.getItem("isauthenticated");
  }

  get getUserName(): any {
    return localStorage.getItem("displayName");
  }
  get getUserFreeCount(): any {
    return localStorage.getItem("freeDeliveryCount");
  }

  get DisplayuserImage(): any {
    return localStorage.getItem("userImage");
  }

  private today = new Date();
  selDate: any;
  ProfileselDate: any;
  orderType = 1;
  Location: any;
  public currentPage = "";
  selectAddress = false;
  checkoutAddress = false;

  googleNoScriptURL = "";
  facebookNoScriptURL = "";

  myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: "mm/dd/yyyy",
    showTodayBtn: true,
    todayBtnTxt: "Today",
    showClearDateBtn: false,
    indicateInvalidDate: true,
    editableDateField: false,
    openSelectorOnInputClick: true,
  };
  todayData1 = new Date();
  todayData = this.todayData1.getDay();
  windowCurrentWidth: any;
  imageChangedEvent: any;
  OrderSubmitted = false;
  deliverStartDate: any;
  deliverEndDate: any;
  addAddressSuccess: any;
  checkoutButtonDisable = false;
  previousSelectedPackage = 0;
  deliveryPackageData: any;
  deliveryPackageDataLimit = 1000;
  subscriptionPurchaseData: any;
  deliveryPlanId: any;
  deliveryPlanmethod = -1;
  purchaseDeliveryPlanBtn = false;
  deliveryPlanPurchase = false;
  deliveryPlanSuccessData: any;

  breakdownCharges = 0;
  breakdownChargesDis = 0;
  totalPurchasePackageCount = "0";
  finalCart: any;
  paginationPageWishList = 0;
  scrollPosition: any;
  wishListLoadMore = false;
  showHeaderAddress: boolean; // = true;
  showHeaderAddressCross: boolean; // = true;
  businessContactInfo: any;
  businessCode: any;
  businessMail: any;
  businessPhone: any;
  businessLandingContactInfo: any;
  businessID: any;
  businessFacebook: any;
  businessTwitter: any;
  businessLinkedIn: any;
  businessYoutube: any;
  businessSnapchat: any;
  businessInstagram: any;

  colorCodeVar: string;
  isBusinessLandingPage: boolean = false;
  url: any;
  fromDomain = false;
  idForNavigate: any;
  businessNameId: any;

  productData: any;
  addItValue: any;
  productModifierDataArray = new Array();
  modifiersArr: any[];
  addonsArr: any[];
  noOptionsArr: any[];
  additonalValue: number;
  newCart: any;
  selectedVariant: any;
  uniqueId: any;
  modifierProductList = new Array();
  oldUniqueId: any = "";
  wishlistModifierProductList = new Array();
  orderbtn: boolean = false;
  orderText: any = "";
  dropzone: any;
  customProductImage = new Array();
  customOrderImageSuccess: any;
  showSearchResults: boolean = false;
  searchBusinessDetailforOrder: any;
  customProductImagesCount = 0;
  customOrderImageSuccessUrl = new Array();
  businessIdForOrders: any;
  orderCreated: any;
  orderBooked: any;
  deliveryPaymentOptions: any = "";
  dineInPaymentOptions: any = "";
  pickupPaymentOptions: any = "";
  outletName: string;
  showTimeSlots: boolean = true;
  disableCheckout: boolean = false;
  addressUsedForOrder: string = "";
  isForBusiness: boolean;
  orderPlacedFrom: string;

  constructor(
    private pubsub: EventsService,
    private authService: AuthenticationService,
    private dataService: DataService,
    private events: EventsService,
    private router: Router,
    private route: ActivatedRoute,
    private cs: CountryService,
    public dataControlService: DataControlService,
    private formBuilder: FormBuilder,
    private businessInfo: EventsService,
    private fbEventsService: FbEventsService // private businessFlag: EventsService,
  ) {
    document.addEventListener("visibilitychange", (event) => {
      if (document.visibilityState == "visible") {
        this.getPayementCard();
      }
    });

    console.log("Color Code of this app is = ", Constants.colorCode);
    this.colorCodeVar = Constants.colorCode;
    this.pubsub.subscribe("policyLink").subscribe((data) => {
      this.policyLink = data;
      console.log(this.policyLink);
    });
    this.widthOfWindow = $(window).width();

    if (
      window.location.hostname.includes("businessweb.bestbuymall.co") ||
      window.location.hostname.includes(Constants.domainNmae)
    ) {
      console.log("if");
      this.fromDomain = true;
    } else if (
      window.location.hostname.includes("bestbuymall.co") ||
      window.location.hostname.includes("bestbuymall.co") ||
      window.location.hostname.includes("dev.bestbuymall.co") ||
      window.location.hostname.includes("dev.bestbuymall.co") ||
      window.location.hostname.includes("localhost") ||
      window.location.hostname.includes("bbm.com.co")
    ) {
      this.fromDomain = false;
      this.router.events.subscribe((navEnd: NavigationEnd) => {
        if (
          navEnd.url.includes("policy") ||
          navEnd.url.includes("terms-conditions")
        ) {
          this.dataControlService.showBanner$.next(false);
        } else {
          this.dataControlService.showBanner$.next(true);
        }
      });
      console.log("elseif");
      router.events.subscribe((event) => {
        if (event instanceof ActivationEnd) {
          if (event.snapshot) {
            this.checkPath(
              event.snapshot.routeConfig.path,
              event.snapshot.params.id
            );
          }
        }
      });
    } else {
      console.log("else");
      this.fromDomain = true;
      localStorage.setItem("businessId", window.location.hostname);
      this.getBusinessData();
    }

    this.userCurrentAddress = JSON.parse(
      window.localStorage.getItem("profileAddressCheckout")
    );
    this.addressUpdate = this.events
      .subscribe("addressUpdate")
      .subscribe((data) => {
        if (data == "true") {
          this.userCurrentAddress = JSON.parse(
            window.localStorage.getItem("profileAddressCheckout")
          );
        }
      });
    // this.showHeaderAddress = true;
    // this.showHeaderAddressCross = true;
    this.getBusinessMailAndPhone();

    localStorage.setItem("isFirstTimeHomeOpen", "true");
    // this.colorCodeVar = window.localStorage.getItem('colorChangeCode');
    if (this.colorCodeVar === "undefined" || this.colorCodeVar === null) {
      this.colorCodeVar = Constants.colorCode;
    }

    document.documentElement.style.setProperty(
      "--colorCodeVar",
      this.colorCodeVar
    );

    document.documentElement.style.setProperty(
      "--colorCodeVar",
      this.colorCodeVar
    );

    this.pushReceive = this.pubsub
      .subscribe("businessLogoUpdate")
      .subscribe((data) => {
        this.getBusinessLogo = data;
        this.showHeaderAddress = false;
        this.showHeaderAddressCross = false;
      });
    this.pubsub.subscribe("businessThemeStructure").subscribe((data) => {
      this.themeStructureId = data;
    });

    // this.getAddressListingFunction(null);

    $(".footer-main").css("display", "block");
    $(".business-logo").css("display", "none");

    setTimeout(() => {
      this.dataControlService.setHeaderAddressVisiblity.subscribe((data) => {
        console.log("check isBusinessLandingPage", data);
        if (data == true) {
          this.showHeaderAddress = true;
          this.isBusinessLandingPage = false;
          $(".footer-main").css("display", "block");
          // $('.business-logo').css('display', 'none');
        } else {
          this.isBusinessLandingPage = true;
        }
      });
    }, 500);

    this.dataControlService.headerSignInBtn.subscribe((data) => {
      if (data == true) {
        this.customSigninBtn = true;
      } else {
        this.customSigninBtn = false;
      }
    });

    this.dataControlService.setHeaderAddressCrossVisiblity.subscribe((data) => {
      if (data == true) {
        this.showHeaderAddressCross = true;
      } else {
        this.showHeaderAddressCross = false;
      }
    });
    // this.dataControlService.getAddresses.subscribe(data => {
    //   console.log('In get address subscriber: =====> ', data)
    //   console.log('yolo',this.isForBusiness, data);
    //   if(data) {
    //     if(this.isForBusiness) {
    //     } else {
    //       this.initialCartData();ƒ
    //     }
    //   }
    // });
    this.getAddressListingFunction(null);
    this.dataControlService.makePrimaryAddress.subscribe((data) => {
      if (data.isAddedAddress == true) {
        this.makePrimeryAddress(data.address_id, null);
      }
      if (data.isAddedAddress == false) {
        this.makePrimeryAddress(data.address_id, data);
      }
    });

    this.userFrom = this.dataService.userFrom;
    this.hostName = window.location.hostname;
    setTimeout(() => {
      // var date = new Date();
      // var year = date.getFullYear();
      this.selDate = new Date();
      // this.dob = '01/01/1997';
    }, 1500);
    this.getCartItems = JSON.parse(localStorage.getItem("cartItems"));
    // this.getBusinessLogo = JSON.parse(localStorage.getItem('businessLogo'));

    if (this.globalAddress === null) {
      localStorage.setItem("delivery_address", "Please Select your location");
      localStorage.setItem("myDeliveryAddress", "Please Select your location");
    }
    if (this.getCartItems === null) {
      this.carBtnShowHide = false;
      this.cartCount = 0;
      this.setCheckoutAddressMainAddress();
    } else {
      if (this.getCartItems.length > 0) {
        this.cartCount = this.getCartItems.length;
        this.carBtnShowHide = true;
      }
    }
    // this.pushReceive = this.events.subscribe('dataChange').subscribe((data) => {
    //   this.cityname = data;
    // });
    this.cartPush = this.events.subscribe("cartUpdate").subscribe((data) => {
      localStorage.setItem("cartItems", JSON.stringify(data));
      setTimeout(() => {
        this.getCartItems = JSON.parse(localStorage.getItem("cartItems"));

        if (this.getCartItems !== null) {
          if (this.getCartItems.length > 0) {
            this.carBtnShowHide = true;
            this.cartCount = this.getCartItems.length;
            //this.changeRef.detectChanges();
            if (this.dataService.pageName === "businesses") {
              $(".cartHeadBtn").css("background", this.colorCodeVar);
              $(".cartHeadBtn").css("color", "#ffffff");
            }

            // this.colorCodeVar = window.localStorage.getItem('colorChangeCode');
            if (
              this.colorCodeVar === "undefined" ||
              this.colorCodeVar === null
            ) {
              this.colorCodeVar = Constants.colorCode;
            }

            document.documentElement.style.setProperty(
              "--colorCodeVar",
              this.colorCodeVar
            );
          } else {
            this.carBtnShowHide = false;
            this.setCheckoutAddressMainAddress();
            //this.changeRef.detectChanges();
          }
        }
      }, 500);
      if (this.fromWishlist == true) {
        setTimeout(() => {
          this.updateCart = false;
          this.fromWishlist = false;
          this.addCartPopOpenWishList(this.allProd, this.objProd, this.valProd);
        }, 1000);
      }
    });

    this.cartopenPopup = this.events
      .subscribe("openCartPopup")
      .subscribe((data) => {
        this.openFinalCartPop("");
      });
    this.userFromMob1 = this.events
      .subscribe("userFromMob")
      .subscribe((data) => {
        this.getInfoUsingToken();
      });
    this.businessLandingalias = this.events
      .subscribe("businessLandingAlisa")
      .subscribe((data) => {
        this.businessLandingData = data;
        this.businessLandingaliasName = this.businessLandingData.alias;
      });

    this.pushRecivedVal = this.events
      .subscribe("pushRecived")
      .subscribe((data) => {
        this.pushRecived = data;
        // if popup open
        if ($("#orderPopup").is(":visible")) {
          this.callOrderFunction();
          if ($("#TrackPopup").is(":visible")) {
            this.CallTrackFun(this.orderTrackId, "");
          }
        }
      });
    this.ratingPopUpData = this.events
      .subscribe("ratingPopUpData")
      .subscribe((data) => {
        this.ratingData = data;
        if (this.ratingData.status === 5) {
          this.responsePopUp();
        }
      });
    this.events.subscribe("confirmPaymentMethod").subscribe((data) => {
      this.pendingPayment = data;
      $("#pendingOnlinePayment").modal({
        backdrop: "static",
        keyboard: false,
      });

      $("#pendingOnlinePayment").modal("show");
    });

    this.openLoginFunctionVal = this.events
      .subscribe("openLoginFunction")
      .subscribe((data) => {
        this.openLoginFunction = data;
        $("#signIn").modal("show");
        this.fbRegLogin();
        this.callSigninIntlInput();
      });
    $("#chkoutPop").modal("hide");
  }

  showTimeSlotsFunction(value: boolean) {
    this.showTimeSlots = value;
    console.log(
      "can show time slots ? ===>",
      this.dineIn == false &&
        (this.selfPickup == true || this.delivery == true) &&
        (this.type != "pickup" || this.showTimeSlots == true)
    );
  }

  injectGoogleAnalyticsandFacebook() {
    //Should be dynamic based on selected alias ::: ONLY A TEMPORARY CHECK
    if (window.location.hostname.includes("malmo.pk")) {
      let node = document.createElement("script"); // creates the script tag
      //node.src = ''; // sets the source (insert url in between quotes)
      node.type = "text/javascript"; // set the script type
      node.text =
        "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NKQ9RP6');";
      // node.async = true; // makes script run asynchronously
      node.charset = "utf-8";
      // append to head of document
      document.getElementsByTagName("head")[0].appendChild(node);

      let node1 = document.createElement("script"); // creates the script tag
      node1.src = "https://www.googletagmanager.com/gtag/js?id=UA-195920192-1"; // sets the source (insert url in between quotes)
      node1.type = "text/javascript"; // set the script type
      node1.async = true; // makes script run asynchronously
      node1.charset = "utf-8";
      // append to head of document
      document.getElementsByTagName("head")[0].appendChild(node1);

      node1 = document.createElement("script"); // creates the script tag
      node1.type = "text/javascript"; // set the script type
      node1.async = true; // makes script run asynchronously
      node1.text =
        "window.dataLayer = window.dataLayer || [];function gtag() { dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'UA-195920192-1');";
      node1.charset = "utf-8";
      document.getElementsByTagName("body")[0].appendChild(node1);

      node1 = document.createElement("script"); // creates the script tag
      node1.type = "text/javascript"; // set the script type
      node1.async = true; // makes script run asynchronously
      node1.text =
        "!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '3775713205848842'); fbq('track', 'PageView');";
      node1.charset = "utf-8";
      document.getElementsByTagName("body")[0].appendChild(node1);
    } else {
      let node = document.createElement("script"); // creates the script tag
      //node.src = ''; // sets the source (insert url in between quotes)
      node.type = "text/javascript"; // set the script type
      node.text =
        "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-TLL9LVS');";
      // node.async = true; // makes script run asynchronously
      node.charset = "utf-8";
      // append to head of document
      document.getElementsByTagName("head")[0].appendChild(node);

      let node1 = document.createElement("script"); // creates the script tag
      node1.src = "https://www.googletagmanager.com/gtag/js?id=UA-140149934-1"; // sets the source (insert url in between quotes)
      node1.type = "text/javascript"; // set the script type
      node1.async = true; // makes script run asynchronously
      node1.charset = "utf-8";
      // append to head of document
      document.getElementsByTagName("head")[0].appendChild(node1);

      node1 = document.createElement("script"); // creates the script tag
      node1.type = "text/javascript"; // set the script type
      node1.async = true; // makes script run asynchronously
      node1.text =
        "window.dataLayer = window.dataLayer || [];function gtag() { dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'UA-140149934-1');";
      node1.charset = "utf-8";
      document.getElementsByTagName("body")[0].appendChild(node1);

      node1 = document.createElement("script"); // creates the script tag
      node1.type = "text/javascript"; // set the script type
      node1.async = true; // makes script run asynchronously
      node1.text =
        "!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '494630147971523'); fbq('track', 'PageView');";
      node1.charset = "utf-8";
      document.getElementsByTagName("body")[0].appendChild(node1);
    }
  }

  fbCheckout1(customArray) {
    this.fbEventsService.fbCheckout1(customArray[0].dispensary_id);
  }

  fbCheckout2(customArray) {
    this.fbEventsService.fbCheckout2(customArray[0].dispensary_id);
  }

  fbOrderPlaced() {
    this.fbEventsService.fbOrderPlaced(this.customArray[0].dispensary_id);
  }

  fbRegLogin() {
    this.fbEventsService.fbRegLogin();
  }

  fbRegistration() {
    this.fbEventsService.fbRegistration();
  }

  fbDownloadIOSApp() {
    this.fbEventsService.fbDownloadIOSApp();
  }

  fbDownloadAndroidApp() {
    this.fbEventsService.fbDownloadAndroidApp();
  }

  checkPath(path, businessId) {
    if (path.startsWith(":id")) {
      this.isForBusiness = true;
      if (this.should_load_business === false) {
        this.isBusinessLandingPage = true;
        this.should_load_business = true;
        this.themeStructureId = -1;
        this.businessId = businessId;
        if (localStorage.getItem("businessId") != this.businessId) {
          localStorage.removeItem("cartItems");
          this.carBtnShowHide = false;
        }
        localStorage.setItem("businessId", this.businessId);

        this.getBusinessData();

        setTimeout(() => {
          if (
            this.businessDetailData != null &&
            this.businessDetailData != undefined
          ) {
            let dispensaryAlias = this.businessDetailData.alias;
            this.should_load_business = true;
            this.events.publish("businessAliasId", dispensaryAlias);
          }
        }, 2000);
        this.dataControlService.getAddresses.next(true);
      }
      return true;
    } else {
      localStorage.removeItem("businessId");
      this.should_load_business = false;
      this.isForBusiness = false;
      this.isBusinessLandingPage = false;
      this.themeStructureId = 0;
      this.dataControlService.getAddresses.next(true);
      this.getBusinessData();
      this.initialCartData();
      return false;
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    $(".footer-main").css("display", "block");
    $(".business-logo").css("display", "none");
  }
  responsePopUp() {
    this.dispensdispensaryName = this.ratingData.dispensary_name;
    this.feedBack = "";
    this.dispensary_rating_id = this.ratingData.dispensary_rating_id;
    this.is_third_party = this.ratingData.is_third_party;
    this.cancelRatingId = this.ratingData.rating_id;
    if (this.is_third_party === false) {
      this.rating_id = this.ratingData.rating_id;
      this.driverData = this.ratingData.driver_profile;
      this.driverFirstName = this.driverData.first_name;
      this.driverLastName = this.driverData.last_name;
      this.driver_img = this.driverData.img_url;
    }
    if (this.is_third_party === true) {
      this.rating_id = -1;
    }
    this.businessRaitingArray = [];
    for (let z = 0; z < this.businessRaiting; z++) {
      this.businessRaitingArray.push({
        value: "true",
      });
    }
    this.driverRatingValue = 0;
    this.businessRatingValue = 0;
    $("#onOnleResponsePopup").modal("show");
  }

  trackOnline(url) {
    if (
      url.slice(0, 4) === "http" ||
      url.slice(0, 4) === "Http" ||
      url.slice(0, 4) === "HTTP" ||
      url.slice(0, 5) === "https" ||
      url.slice(0, 5) === "Https" ||
      url.slice(0, 5) === "HTTPS"
    ) {
      window.open(url, "_blank");
    } else {
      window.open("https://" + url, "_blank");
    }
  }
  getBusinessMailAndPhone() {
    this.businessContactInfo = this.businessInfo
      .subscribe("businessContInfo")
      .subscribe((data) => {
        this.businessLandingContactInfo = data;
        this.businessCode = this.businessLandingContactInfo.country_code;
        this.businessPhone = this.businessLandingContactInfo.phone_number;
        this.businessMail = this.businessLandingContactInfo.manager_email;
        this.businessID = this.businessLandingContactInfo.id;
        this.businessFacebook = this.businessLandingContactInfo.facebook;
        this.businessTwitter = this.businessLandingContactInfo.twitter;
        this.businessLinkedIn = this.businessLandingContactInfo.linkdin;
        this.businessYoutube = this.businessLandingContactInfo.youtube;
        this.businessSnapchat = this.businessLandingContactInfo.snapchat;
        this.businessInstagram = this.businessLandingContactInfo.instagram;
      });
  }

  GetUnique(inputArray) {
    var outputArray = [];
    if (inputArray != null) {
      for (var i = 0; i < inputArray.length; i++) {
        if (jQuery.inArray(inputArray[i].business_id, outputArray) === -1) {
          outputArray.push(inputArray[i].business_id);
        }
      }
    }

    return outputArray;
  }

  openPayemntPop() {
    if (
      localStorage.getItem("token") !== "" &&
      localStorage.getItem("token") !== undefined &&
      localStorage.getItem("token") !== null
    ) {
      this.getPayementCard();
      this.closeNav();
      $("#paymentPopup").modal("show");
      // $('#paymentMethodOption').modal('show');
    } else {
      this.number = "";
      $("#signIn").modal("show");
      this.fbRegLogin();
      this.callSigninIntlInput();
    }
  }

  addnewCard() {
    let paymentUrl = `${Constants.paymentUrl}/online/${localStorage.getItem(
      "token"
    )}`;
    window.open(paymentUrl, "_blank");
    // this.cardNum = '';
    // this.cardName = '';
    // this.expiryDate = '';
    // this.card_cvv = '';
    // this.AddNewCardBtn = true;
    // $('.jp-card-number').empty();
    // $('.jp-card-name').empty();
    // $('.jp-card-expiry').empty();
    // $('.jp-card-cvc').empty();
    // $('.jp-card-number').text('•••• •••• •••• ••••');
    // $('.jp-card-name').text('Full Name');
    // $('.jp-card-expiry').text('••/••');
    // $('#addNewPaymentPopup').modal('show');
  }

  checkCardValidate() {
    // $('#number').validateCreditCard(function(result) {
    //   $('.log').html('Card type: ' + (result.card_type == null ? '-' : result.card_type.name)
    //     + '<br>Valid: ' + result.valid
    //     + '<br>Length valid: ' + result.length_valid
    //     + '<br>Luhn valid: ' + result.luhn_valid);
    // });

    if (
      this.cardNum === "" ||
      this.cardNum === null ||
      this.cardNum === undefined
    ) {
      this.AddNewCardBtn = true;
    } else if (
      this.cardName === "" ||
      this.cardName === null ||
      this.cardName === undefined
    ) {
      this.AddNewCardBtn = true;
    } else if (
      this.expiryDate === "" ||
      this.expiryDate === null ||
      this.expiryDate === undefined
    ) {
      this.AddNewCardBtn = true;
    } else if (
      this.card_cvv === "" ||
      this.card_cvv === null ||
      this.card_cvv === undefined
    ) {
      this.AddNewCardBtn = true;
    } else {
      this.AddNewCardBtn = false;
    }
  }

  textUsModal(record) {
    this.dataControlService.prescriptionReport$.next(record);
    this.textUs();
  }

  addPaymentMethodFunction() {
    this.AddNewCardBtn = true;
    this.expiryDateError = 0;
    var splitMonth = this.expiryDate.split("/")[0];
    var splitLastWord = this.expiryDate.split("/").splice(-2)[1];
    splitLastWord = this.expiryDate.split(" ").splice(-2)[1];
    var year = new Date().getFullYear();
    // year = splitLastWord;
    var yearFinal = year.toString();
    if (splitLastWord.length === undefined) {
      this.expiryDateError++;
      this.AddNewCardBtn = false;
      return;
    }
    if (parseInt(splitMonth, 10) > 12) {
      this.expiryDateError++;
    }
    if (splitLastWord.length !== undefined) {
      if (splitLastWord.length === 2) {
        var last = yearFinal[yearFinal.length - 1];
        var second = yearFinal[yearFinal.length - 2];
        var final = second + last;
        if (splitLastWord >= final) {
          $("#expiry").css("border-color", "#ccc");
        } else {
          // $('#expiry').css('border-color', '#eb3b3b');
          this.expiryDateError++;
        }
      } else if (splitLastWord.length === 4) {
        if (splitLastWord >= yearFinal) {
          // $('#expiry').css('border-color', '#ccc');
        } else {
          // $('#expiry').css('border-color', '#eb3b3b');
          this.expiryDateError++;
        }
      } else {
        // $('#expiry').css('border-color', '#eb3b3b');
        this.expiryDateError++;
      }
    } else {
      // $('#expiry').css('border-color', '#eb3b3b');
      this.expiryDateError++;
    }
    if (this.expiryDateError > 0) {
      $("#expiry").css("border-color", "#eb3b3b");
      this.AddNewCardBtn = false;
      return;
    } else {
      $("#expiry").css("border-color", "#ccc");
    }

    this.cardType = this.checkCardType(this.cardNum);
    this.AddNewCardBtn = true;
    this.dataService
      .addNewPaymentCard(
        localStorage.getItem("token"),
        this.cardNum,
        this.cardName,
        this.expiryDate,
        this.card_cvv,
        this.cardType
      )
      .subscribe(
        (data) => {
          this.AddNewCardData = data;
          if (this.AddNewCardData.success === false) {
            toastr.error(this.AddNewCardData.message);
          }
          if (this.AddNewCardData.success === true) {
            toastr.success(this.AddNewCardData.message);
            $("#addNewPaymentPopup").modal("hide");
            this.checkoutErrorMessage = "";
          }
          this.getPayementCard();
          this.AddNewCardBtn = false;
        },
        (error) => {}
      );
  }

  checkCardType(number) {
    // visa
    var re = new RegExp("^4");
    if (number.match(re) != null) {
      // return 'Visa';
      return "4";
    }

    // Mastercard
    // Updated for Mastercard 2017 BINs expansion
    if (
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
        number
      )
    ) {
      // return 'Mastercard';
      return "5";
    }

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null) {
      // return 'AMEX';
      return "2";
    }

    // Discover
    re = new RegExp(
      "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
    );
    if (number.match(re) != null) {
      // return 'Discover';
      return "6";
    }

    // Diners
    re = new RegExp("^36");
    if (number.match(re) != null) {
      // return 'Diners';
      return "7";
    }
    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.match(re) != null) {
      // return 'Diners - Carte Blanche';
      return "7";
    }

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null) {
      // return 'JCB';
      return "3";
    }

    // Visa Electron
    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) != null) {
      // return 'Visa Electron';
      return "4";
    }

    return "0";
  }

  conformRemovefun(id) {
    this.RemovePaymentCardId = id;
    // $('#removeCardConformPop').modal('show');
    this.dataService
      .removePaymentMethod(
        localStorage.getItem("token"),
        this.RemovePaymentCardId
      )
      .subscribe(
        (data) => {
          this.removeCardData = data;
          if (this.removeCardData.success === false) {
            toastr.error(this.removeCardData.message);
          }
          if (this.removeCardData.success === true) {
            toastr.success(this.removeCardData.message);
          }
          this.getPayementCard();
        },
        (error) => {}
      );
  }

  getPayementCard() {
    this.nao.go(30);
    this.dataService
      .getPaymentMethodlist(localStorage.getItem("token"))
      .subscribe(
        (data: any) => {
          this.nao.go(100);
          this.paymentCardData = data.Dictionary_Payment_List;
          for (let i = 0; i < this.paymentCardData.length; i++) {
            if (this.paymentCardData[i].primary === true) {
              this.primaryCardNo = this.paymentCardData[i].cardNumber;
            }
          }
          $("#planWishCash").css("display", "none");
          this.deliveryPlanmethod = 1;
          const orderPlaced = localStorage.getItem("orderPlaced");
          if (!data.pending_payment.is_pending && orderPlaced) {
            $("#orderPlacedSucessFull").modal({
              backdrop: "static",
              keyboard: false,
            });
            $("#orderPlacedSucessFull").modal("show") || "";
          } else if (data.pending_payment.is_pending && orderPlaced) {
            $("#orderFailed").modal({
              backdrop: "static",
              keyboard: false,
            });
            $("#orderFailed").modal("show");
          }
        },
        (error) => {
          this.nao.go(100);
        }
      );
  }

  openImgPopup(image) {
    this.imageForPopup = image;
    console.log("image", image);
    $("#openImgModal").modal("show");
  }
  makePaymentMethodPrimaryFunction(
    id,
    cardNumber,
    index,
    showToastr: boolean = true
  ) {
    this.primaryCardNo = cardNumber;
    for (let i = 0; i <= this.paymentCardData.length; i++) {
      $("#primary" + i).prop("checked", false);
    }
    this.nao.go(30);
    this.dataService
      .makePaymentMethodPrimary(localStorage.getItem("token"), id)
      .subscribe(
        (data) => {
          this.nao.go(100);
          this.primaryDataSuccess = data;
          if (this.primaryDataSuccess.success === true) {
            for (let i = 0; i < this.paymentCardData.length; i++) {
              this.paymentCardData[i].primary = false;
            }
            this.paymentCardData[index].primary = true;

            if (showToastr) toastr.success(this.primaryDataSuccess.message);
            $("#primary" + index).prop("checked", true);
          }
        },
        (error) => {
          this.nao.go(100);
          toastr.error("Some thing went wrong");
        }
      );
  }

  selectPaymentOption() {
    if (
      localStorage.getItem("token") !== "" &&
      localStorage.getItem("token") !== undefined &&
      localStorage.getItem("token") !== null
    ) {
      $("#paymentMethodOption").modal("show");
      $("#paymentCashCheckbox").prop("checked", false);
      $("#paymentCardCheckbox").prop("checked", false);
      $("#paymentCardAddNew").prop("checked", false);
      //below loop hide tick icons which are on select payment method popup
      // $('#paymentNoCheck-1').css('display','none');
      // for (let i = 0; i< this.paymentCardData.length; i++ ) {
      //   $('#paymentNoCheck'+ i).css('display','none');
      //   $('#primaryCard'+ i).css('display','none');
      // }
      // for (let i = 0; i< this.paymentCardData.length; i++ ) {
      //
      //   if(this.paymentCardData[i].primary === true) {
      //     $('#primaryCard' + i).css('display', 'block');
      //   }
      //
      // }
      if (this.paymentMethodOptionValue === 0) {
        $("#paymentCashCheckbox").prop("checked", true);
        this.paymentMethodOptionBtn = false;
      } else if (this.paymentMethodOptionValue === 1) {
        $("#paymentCardCheckbox").prop("checked", true);
        this.paymentMethodOptionBtn = false;
      } else if (this.paymentMethodOptionValue === -1) {
        this.paymentMethodOptionBtn = true;
      }
    } else {
      this.number = "";
      $("#signIn").modal("show");
      this.fbRegLogin();
      this.callSigninIntlInput();
    }
  }

  //   selectPaymentOption() {
  //
  //     $('#paymentMethodOption').modal('show');
  //     $('#paymentCashCheckbox').prop('checked', false);
  //     $('#paymentCardCheckbox').prop('checked', false);
  //     $('#paymentCardAddNew').prop('checked', false);
  //     //below loop hide tick icons which are on select payment method popup
  //     // $('#paymentNoCheck-1').css('display','none');
  //     // for (let i = 0; i< this.paymentCardData.length; i++ ) {
  //     //   $('#paymentNoCheck'+ i).css('display','none');
  //     //   $('#primaryCard'+ i).css('display','none');
  //     // }
  //     // for (let i = 0; i< this.paymentCardData.length; i++ ) {
  //     //
  //     //   if(this.paymentCardData[i].primary === true) {
  //     //     $('#primaryCard' + i).css('display', 'block');
  //     //   }
  //     //
  //     // }
  //     if ( this.paymentMethodOptionValue === 0 ) {
  //       $('#paymentCashCheckbox').prop('checked', true);
  //       this.paymentMethodOptionBtn = false;
  //     } else if ( this.paymentMethodOptionValue === 1 ) {
  //       $('#paymentCardCheckbox').prop('checked', true);
  //       this.paymentMethodOptionBtn = false;
  //     } else if ( this.paymentMethodOptionValue === -1 ) {
  //       this.paymentMethodOptionBtn = true;
  //     }
  //   }
  openAddCartFromCheckOut() {
    if (
      localStorage.getItem("token") !== "" &&
      localStorage.getItem("token") !== undefined &&
      localStorage.getItem("token") !== null
    ) {
      $("#paymentMethodOption").modal("show");
    } else {
      this.number = "";
      $("#signIn").modal("show");
      this.fbRegLogin();
      this.callSigninIntlInput();
      // this.signInPop();
    }
  }

  selectDineinPaymentOption() {
    if (!localStorage.getItem("token") || localStorage.getItem("token") == "") {
      this.number = "";
      this.isPlaceOrderSignin = true;
      $("#signIn").modal("show");
      this.fbRegLogin();
      this.callSigninIntlInput();
      $(".addCartShort").css("-webkit-filter", "blur(3px)");
      $(".addCartShort").css("-moz-filter", "blur(3px)");
      $(".addCartShort").css("-o-filter", "blur(3px)");
      $(".addCartShort").css("-ms-filter", "blur(3px)");
      $(".addCartShort").css("filter", "blur(3px)");
      return;
    }
    $("#paymentMethodOption").modal("show");
  }

  dineinPaymentMethod(val, item?) {
    toastr.success("Payment method has been updated");
    this.dineinPayment = val;
    if (this.dineinPayment == "cash") {
      this.customArray[0].gst = this.customArray[0].gstCashPrice;
    }
    if (this.dineinPayment == "card") {
      this.customArray[0].gst = this.customArray[0].gstCardPrice;
    }
    console.log("Custom array", this.customArray);

    console.log(
      "%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% Dine in Payment Method %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%",
      this.deliveryAddressObj
    );
    this.getSlotDataForOutlet();
    // this.paymentMethodOptionValue = -10;
    $("#paymentMethodOption").modal("hide");
  }

  selectPaymentMethodOption(val: number, item?, showToastr: boolean = true) {
    // $('#paymentCashCheckbox').prop('checked', false);
    // $('#paymentCardCheckbox').prop('checked', false);
    // $('#paymentCardAddNew').prop('checked', false);
    this.primaryCardValue = val;
    // this.makePaymentMethodPrimaryFunction(item.paymentMethodProfileId, item.cardNumber, val );

    $("#paymentNoCheck-1").css("display", "none");
    $("#paymentNoCheck-2").css("display", "none");
    $("#paymentNoCheck-3").css("display", "none");
    for (let i = 0; i < this.paymentCardData.length; i++) {
      $("#paymentNoCheck" + i).css("display", "none");
      $("#primaryCard" + i).css("display", "none");
    }

    $("#paymentMethodOption").modal("hide");
    if (val === -1) {
      console.log("insomniac: selecting cash bawa g");
      if (showToastr) {
        toastr.success("Payment method has been updated");
      }
      $("#primaryCard" + val).css("display", "inline-block");
      $("#paymentNoCheck-1").css("display", "inline-block");
      $("#paymentCashLabel").css("font-weight", "bold");

      this.paymentMethodOptionValue = 0;
      this.dineinPayment = "cash";
      // this.customArray[0].gst = this.customArray[0].gstCashPrice;
      this.paymentMethodOptionBtn = false;
      this.reConfirmPayment = "COD";
      this.paymentThroughCard = false;
    } else if (val === -2) {
      this.paymentMethodOptionValue = -1;
      this.openPayemntPop();

      this.paymentMethodOptionBtn = false;
      $("#paymentMethodOption").modal("hide");
    } else if (val === -3) {
      if (showToastr) {
        toastr.success("Payment method has been updated");
      }
      $("#primaryCard" + val).css("display", "inline-block");
      $("#paymentNoCheck-2").css("display", "inline-block");

      $("#paymentBankLabel").css("font-weight", "bold");

      this.paymentMethodOptionValue = 2;
      this.dineinPayment = "Bank Transfer";
      this.customArray[0].gst = this.customArray[0].gstCashPrice;
      this.paymentMethodOptionBtn = false;
      this.paymentThroughCard = false;
    } else if (val === -4) {
      if (showToastr) {
        toastr.success("Payment method has been updated");
      }
      $("#primaryCard" + val).css("display", "inline-block");
      $("#paymentNoCheck-3").css("display", "inline-block");

      $("#paymentMposLabel").css("font-weight", "bold");

      this.paymentMethodOptionValue = 3;
      this.dineinPayment = "card";
      this.customArray[0].gst = this.customArray[0].gstCardPrice;
      this.paymentMethodOptionBtn = false;
      this.paymentThroughCard = false;
    } else {
      this.reConfirmPayment = "";
      this.paymentThroughCard = true;
      console.log("insomniac: index", "#paymentNoCheck" + val);
      $("#paymentNoCheck" + val).css("display", "inline-block");
      $("#primaryCard" + val).css("display", "inline-block");
      this.makePaymentMethodPrimaryFunction(
        item.paymentMethodProfileId,
        item.cardNumber,
        val,
        showToastr
      );
      if (
        this.primaryCardNo === "" ||
        this.primaryCardNo === undefined ||
        this.primaryCardNo === null
      ) {
        this.openPayemntPop();
      }
      $("#paymentCardCheckbox").css("display", "inline-block");
      $("#paymentCardLabel").css("font-weight", "bold");

      this.paymentMethodOptionValue = 1;
      this.dineinPayment = "card";
      this.customArray[0].gst = this.customArray[0].gstCardPrice;
      this.paymentMethodOptionBtn = false;
    }

    if (
      this.paymentMethodOptionValue == 0 ||
      this.paymentMethodOptionValue == 2
    ) {
      this.customArray[0].gst = this.customArray[0].gstCashPrice;
    } else {
      this.customArray[0].gst = this.customArray[0].gstCardPrice;
    }
    console.log(this.customArray);
    console.log("payment methid", this.paymentMethodOptionValue);
    // this.dineinPayment = '-1';

    console.log(
      "%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% Select Payment Method Option %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%"
    );
    this.getSlotDataForOutlet();
    console.log(
      "############################### Select Payment Option #################################"
    );
    this.updateFinalCartPop();
    console.log("payment methid", this.paymentMethodOptionValue);
    // if ( val === '1' ) {
    //   // $('#paymentCardCheckbox').prop('checked', true);
    //   if ( this.primaryCardNo === '' || this.primaryCardNo === undefined || this.primaryCardNo === null) {
    //           this.openPayemntPop();
    //         }
    //   $('#paymentCardCheckbox').css('display','inline-block')
    //   $('#paymentCardLabel').css('font-weight','bold')
    //
    //   this.paymentMethodOptionValue = 1;
    //   this.paymentMethodOptionBtn = false;
    //   $('#paymentMethodOption').modal('hide');
    // }
  }

  // selectPaymentMethodOptionDone() {
  //   this.paymentMethodOptionValue = this.paymentMethodOptionValue2nd;
  //   $('#paymentMethodOption').modal('hide');
  //   if ( this.paymentMethodOptionValue === 1 ){
  //     if ( this.primaryCardNo === '' || this.primaryCardNo === undefined || this.primaryCardNo === null) {
  //       this.openPayemntPop();
  //     }
  //   }
  //   if ( this.paymentMethodOptionValue === -1 ){
  //     this.openPayemntPop();
  //   }
  // }

  openFinalCartPop(value) {
    // this.setCartOption();
    console.log(
      ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Open Cart >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>"
    );

    console.log(
      "%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% Open Final Cart %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%"
    );

    this.finalCart = value;
    for (let t = 0; t < this.customArray.length; t++) {
      this.chkOutTotal = this.chkOutTotal + this.customArray[t].total_price;
    }
    this.customArray.length > 0
      ? (this.businessId = this.customArray[0].dispensary_id)
      : this.businessId;
    this.getBusinessData();
    this.initialCartData();
    this.getSlotDataForOutlet();

    if (
      localStorage.getItem("profileAddressCheckout") !== "null" &&
      localStorage.getItem("profileAddressCheckout") !== null
    ) {
      this.clear_values();
      this.showAddressInFrontOfWagonIcon = false;
    } else {
      this.showAddressInFrontOfWagonIcon = true;
    }

    console.log(this.customArray);

    $("#chkoutPop").modal("show");
  }

  initialCartData() {
    this.clearBlur();
    this.totalPurchasePackageCount = localStorage.getItem("packageCount");
    this.clear_values();
    this.showLoderAtCheckoutAddress = false;
    this.checkoutPopupOpen = true;
    this.checkoutPopupOpenForAddress = true;
    this.checkoutDone = false;
    this.showOrderMediumNav = true;
    // this.deliveryAddressObjAll = JSON.parse(localStorage.getItem('profileAddress'));
    this.deliveryAddressObjAll = JSON.parse(
      localStorage.getItem("profileAddressCheckout")
    );
    console.log(
      "this address>>>>>>>>>>>>>>>>>>>>>>>>>>",
      this.deliveryAddressObj
    );

    let myProfileAddress = JSON.parse(localStorage.getItem("profileAddress"));
    if (
      myProfileAddress == null ||
      this.deliveryAddressObjAll === null ||
      this.deliveryAddressObjAll === undefined
    ) {
      this.deliveryAddressObj = {
        id: "",
        street: "",
        address_line1: "",
        zip: "",
        city: "",
        country: "",
        state: "",
        latitude: "",
        longitude: "",
        apart_no: "",
        notes: "",
        house_building_no: "",
      };
    }
    if (
      this.deliveryAddressObjAll !== null &&
      this.deliveryAddressObjAll !== undefined
    ) {
      if (this.deliveryAddressObjAll.country !== "") {
        this.deliveryAddressObj = {
          id: this.deliveryAddressObjAll.id,
          street: this.deliveryAddressObjAll.address_line1,
          address_line1: this.deliveryAddressObjAll.address_line1,
          zip: this.deliveryAddressObjAll.zip,
          city: this.deliveryAddressObjAll.city,
          country: this.deliveryAddressObjAll.country,
          state: this.deliveryAddressObjAll.state,
          latitude: this.deliveryAddressObjAll.latitude,
          longitude: this.deliveryAddressObjAll.longitude,
          apart_no: this.deliveryAddressObjAll.apart_no,
          notes: this.deliveryAddressObjAll.notes,
          house_building_no: this.deliveryAddressObjAll.house_building_no,
        };
      }
    }
    this.clearBlur();
    if (
      localStorage.getItem("token") !== "" &&
      localStorage.getItem("token") !== undefined &&
      localStorage.getItem("token") !== null
    ) {
      this.getPayementCard();
    }

    this.checkoutOpenCheck = true;
    this.getBusinessData();

    this.customArray = [];
    this.chkOutTotal = 0;
    this.checkoutErrorMessage = "";

    if (
      this.paymentThroughCash &&
      !this.paymentThroughBankTransfer &&
      !this.paymentThroughMpos &&
      !this.paymentThroughCard
    ) {
      this.paymentMethodOptionValue = 0;
      this.dineinPayment = "cash";
    } else if (
      !this.paymentThroughCash &&
      this.paymentThroughBankTransfer &&
      !this.paymentThroughMpos &&
      !this.paymentThroughCard
    ) {
      this.paymentMethodOptionValue = 2;
      this.dineinPayment = "cash";
    } else if (
      !this.paymentThroughCash &&
      !this.paymentThroughBankTransfer &&
      this.paymentThroughMpos &&
      !this.paymentThroughCard
    ) {
      this.paymentMethodOptionValue = 3;
      this.dineinPayment = "card";
    } else if (
      !this.paymentThroughCash &&
      !this.paymentThroughBankTransfer &&
      !this.paymentThroughMpos &&
      this.paymentThroughCard
    ) {
      this.paymentMethodOptionValue = 1;
      this.dineinPayment = "card";
    } else {
      if (this.paymentThroughCard) {
        this.paymentMethodOptionValue = 1;
      } else {
        this.paymentMethodOptionValue = -1;
        this.dineinPayment = "-1";
      }
    }

    this.paymentOverDue = JSON.parse(
      localStorage.getItem("cartBusinessData")
    ).payment_over_due;

    this.getCartItems = JSON.parse(localStorage.getItem("cartItems"));
    if (this.getCartItems.length !== 0 && this.getCartItems != undefined) {
      this.businessName = this.getCartItems[0].business_name;
      this.businessTax = this.getCartItems[0].business_tax;
    }

    const outputData = this.GetUnique(this.getCartItems);
    const array = [];
    let obj;
    let orders = [];
    let store_index;
    for (let i = 0; i < outputData.length; i++) {
      orders = [];
      for (let j = 0; j < this.getCartItems.length; j++) {
        if (outputData[i] === this.getCartItems[j].business_id) {
          store_index = j;
          orders.push({
            product_id: this.getCartItems[j].product_id,
            product_name: this.getCartItems[j].product_name,
            sale_price: this.getCartItems[j].sale_price,
            is_on_sale: this.getCartItems[j].is_on_sale,
            product_image: this.getCartItems[j].product_image,
            variant_id: this.getCartItems[j].variant_id,
            variant_name: this.getCartItems[j].variant_name,
            variant_value: this.getCartItems[j].variant_value,
            inventory_count: this.getCartItems[j].inventory_count,
            price: Math.round(this.getCartItems[j].price),
            quantity: this.getCartItems[j].quantity,
            modifiers: this.getCartItems[j].modifiers,
            addons: this.getCartItems[j].addons,
            no_options: this.getCartItems[j].noOption,
            priceWithAddons: this.getCartItems[j].modifierPrice,
            additionalPrice: this.getCartItems[j].additionalPrice,
            uniqueId: this.getCartItems[j].uniqueId,
            hasModifier: this.getCartItems[j].hasModifier,
            cardTaxPrice: this.getCartItems[j].card_tax_price,
            cashTaxPrice: this.getCartItems[j].cash_tax_price,
            cardTaxPercentage: this.getCartItems[j].card_tax_percentage,
            cashTaxPercentage: this.getCartItems[j].cash_tax_percentage,
          });
        }
      }
      let sum = 0;
      let gstCard = 0;
      let gstCash = 0;
      // for (let a = 0; a < orders.length; a++) {
      //   sum = sum + (orders[a].quantity * orders[a].price);
      // }
      for (let a = 0; a < orders.length; a++) {
        let productSum = 0;
        if (
          orders[a].priceWithAddons != null ||
          orders[a].priceWithAddons > 0
        ) {
          sum = Math.round(
            sum + orders[a].quantity * orders[a].priceWithAddons
          );
          productSum = orders[a].quantity * orders[a].priceWithAddons;
        } else {
          sum = Math.round(sum + orders[a].quantity * orders[a].price);
          productSum = orders[a].quantity * orders[a].price;
        }

        gstCard = Math.round(
          gstCard + (orders[a].cardTaxPercentage / 100) * productSum
        );
        gstCash = Math.round(
          gstCash + (orders[a].cashTaxPercentage / 100) * productSum
        );

        console.log(
          "gst",
          gstCard,
          gstCash,
          orders[a].cashTaxPercentage,
          orders[a].cardTaxPercentage
        );
      }

      obj = {
        dispensary_id: this.getCartItems[store_index].business_id,
        business_image: this.getCartItems[store_index].business_image,
        sale_price: Math.round(this.getCartItems[store_index].sale_price),
        is_on_sale: this.getCartItems[store_index].is_on_sale,
        business_name: this.getCartItems[store_index].business_name,
        outlet_address: this.getCartItems[store_index].outlet_address,
        business_delivery_charges: Math.round(
          this.getCartItems[store_index].business_delivery_charges
        ),
        business_platform_fee: Math.round(
          this.getCartItems[store_index].business_platform_fee
        ),
        business_total_charges: Math.round(
          this.getCartItems[store_index].business_total_charges
        ),
        discount: 0,
        products: orders,
        sub_total: sum,
        delivery_charges: Math.round(this.timeSlotDetail_delivery_charges),
        delivery_charges_actual: Math.round(
          this.timeSlotDetail_delivery_charges_actual
        ),
        tax_charges: Math.round(
          sum * (this.getCartItems[store_index].business_tax / 100)
        ),
        fix_tax_charges: this.getCartItems[store_index].business_tax / 100,
        total_price: Math.round(sum + this.timeSlotDetail_delivery_charges),
        total_price_duplicate: Math.round(
          sum + this.timeSlotDetail_delivery_charges
        ),
        notes: this.orderNotes,
        platform_fee: Math.round(
          this.getCartItems[store_index].business_platform_fee
        ),
        gstCardPrice: gstCard,
        gstCashPrice: gstCash,
        gst: gstCash,
      };
      console.log("total price", obj.total_price);
      if (
        (this.dineinPayment == "cash" ||
          this.paymentMethodOptionValue == 0 ||
          this.paymentMethodOptionValue == 2) &&
        obj.gstCashPrice !== 0
      ) {
        obj.total_price += obj.gstCashPrice;
      } else if (
        (this.dineinPayment == "card" ||
          this.paymentMethodOptionValue == 1 ||
          this.paymentMethodOptionValue == 3) &&
        obj.gstCardPrice !== 0
      ) {
        obj.total_price += obj.gstCardPrice;
      } else if (this.paymentMethodOptionValue !== -1) {
        obj.total_price += obj.tax_charges;
      }
      console.log("total price", obj.total_price);
      this.customArray.push(obj);
    }
    this.updateFinalCartPop();
  }

  updateFinalCartPop() {
    console.log(
      "insomniac: current payment method value",
      this.paymentMethodOptionValue
    );
    setTimeout(() => {
      if (this.paymentMethodOptionValue == -1) {
        console.log("insomniac: setting payment option");
        if (this.delivery) {
          console.log("insomniac: for delivery", this.deliveryPaymentOptions);
          this.autoSelectPaymentOption(this.deliveryPaymentOptions);
        } else if (this.selfPickup) {
          console.log("insomniac: for pickup", this.pickupPaymentOptions);
          this.autoSelectPaymentOption(this.pickupPaymentOptions);
        } else if (this.dineIn) {
          console.log("insomniac: for dinein", this.dineInPaymentOptions);
          this.autoSelectPaymentOption(this.dineInPaymentOptions);
        } else {
          console.log(
            "insomniac: for delivery backup",
            this.deliveryPaymentOptions
          );
          this.autoSelectPaymentOption(this.deliveryPaymentOptions);
        }
      }
    }, 500);
    this.customArray = [];
    this.chkOutTotal = 0;
    this.checkoutErrorMessage = "";

    this.deliveryAddressObjAll = JSON.parse(
      localStorage.getItem("profileAddressCheckout")
    );

    let myProfileAddress = JSON.parse(
      localStorage.getItem("profileAddressCheckout")
    );
    console.log(
      "Address",
      this.deliveryAddressObj,
      myProfileAddress,
      this.deliveryAddressObjAll,
      myProfileAddress == null ||
        this.deliveryAddressObjAll === null ||
        this.deliveryAddressObjAll === undefined
    );
    if (
      this.deliveryAddressObj.id === "" &&
      (this.deliveryAddressObjAll === null ||
        this.deliveryAddressObjAll === undefined)
    ) {
      this.deliveryAddressObj = {
        id: "",
        street: "",
        address_line1: "",
        zip: "",
        city: "",
        country: "",
        state: "",
        latitude: "",
        longitude: "",
        apart_no: "",
        notes: "",
        house_building_no: "",
      };
    }
    if (
      this.deliveryAddressObjAll !== null &&
      this.deliveryAddressObjAll !== undefined
    ) {
      if (this.deliveryAddressObjAll.country !== "") {
        this.deliveryAddressObj = {
          id: this.deliveryAddressObjAll.id,
          street: this.deliveryAddressObjAll.address_line1,
          address_line1: this.deliveryAddressObjAll.address_line1,
          zip: this.deliveryAddressObjAll.zip,
          city: this.deliveryAddressObjAll.city,
          country: this.deliveryAddressObjAll.country,
          state: this.deliveryAddressObjAll.state,
          latitude: this.deliveryAddressObjAll.latitude,
          longitude: this.deliveryAddressObjAll.longitude,
          apart_no: this.deliveryAddressObjAll.apart_no,
          notes: this.deliveryAddressObjAll.notes,
          house_building_no: this.deliveryAddressObjAll.house_building_no,
        };
      }
    }

    console.log(
      "Address$%^$%^$%^$%^$%^$%^$%^$%^$%^$%^$%^$%^$%^$%^",
      this.deliveryAddressObj
    );

    this.getCartItems = JSON.parse(localStorage.getItem("cartItems"));
    if (this.getCartItems !== null) {
      if (this.getCartItems.length !== 0) {
        this.businessName = this.getCartItems[0].business_name;
        this.businessTax = this.getCartItems[0].business_tax;
      }
    }
    const outputData = this.GetUnique(this.getCartItems);
    const array = [];
    let obj;
    let orders = [];
    let store_index;
    for (let i = 0; i < outputData.length; i++) {
      orders = [];
      for (let j = 0; j < this.getCartItems.length; j++) {
        if (outputData[i] === this.getCartItems[j].business_id) {
          store_index = j;
          orders.push({
            product_id: this.getCartItems[j].product_id,
            product_name: this.getCartItems[j].product_name,
            sale_price: Math.round(this.getCartItems[j].sale_price),
            is_on_sale: this.getCartItems[j].is_on_sale,
            product_image: this.getCartItems[j].product_image,
            variant_id: this.getCartItems[j].variant_id,
            variant_name: this.getCartItems[j].variant_name,
            variant_value: this.getCartItems[j].variant_value,
            inventory_count: this.getCartItems[j].inventory_count,
            price: Math.round(this.getCartItems[j].price),
            quantity: this.getCartItems[j].quantity,
            modifiers: this.getCartItems[j].modifiers,
            addons: this.getCartItems[j].addons,
            no_options: this.getCartItems[j].noOption,
            priceWithAddons: this.getCartItems[j].modifierPrice,
            additionalPrice: this.getCartItems[j].additionalPrice,
            uniqueId: this.getCartItems[j].uniqueId,
            hasModifier: this.getCartItems[j].hasModifier,
            cardTaxPrice: this.getCartItems[j].card_tax_price,
            cashTaxPrice: this.getCartItems[j].cash_tax_price,
            cardTaxPercentage: this.getCartItems[j].card_tax_percentage,
            cashTaxPercentage: this.getCartItems[j].cash_tax_percentage,
          });
        }
      }
      let sum = 0;
      let gstCard = 0;
      let gstCash = 0;
      // for (let a = 0; a < orders.length; a++) {
      //   sum = Math.round(sum + (orders[a].quantity * orders[a].price));
      // }
      console.log("Sum", sum);
      for (let a = 0; a < orders.length; a++) {
        let productSum = 0;
        if (
          orders[a].priceWithAddons != null ||
          orders[a].priceWithAddons > 0
        ) {
          sum = Math.round(
            sum + orders[a].quantity * orders[a].priceWithAddons
          );
          productSum = orders[a].quantity * orders[a].priceWithAddons;
          console.log("Sum", sum);
        } else {
          sum = Math.round(sum + orders[a].quantity * orders[a].price);
          productSum = orders[a].quantity * orders[a].price;
          console.log("Sum", sum);
        }
        gstCard = Math.round(
          gstCard + (orders[a].cardTaxPercentage / 100) * productSum
        );
        gstCash = Math.round(
          gstCash + (orders[a].cashTaxPercentage / 100) * productSum
        );
      }
      console.log("Sum", sum, gstCard, gstCash);

      obj = {
        dispensary_id: this.getCartItems[store_index].business_id,
        business_image: this.getCartItems[store_index].business_image,
        sale_price: Math.round(this.getCartItems[store_index].sale_price),
        is_on_sale: this.getCartItems[store_index].is_on_sale,
        business_name: this.getCartItems[store_index].business_name,
        outlet_address: this.getCartItems[store_index].outlet_address,
        business_delivery_charges: Math.round(
          this.getCartItems[store_index].business_delivery_charges
        ),
        business_platform_fee: Math.round(
          this.getCartItems[store_index].business_platform_fee
        ),
        business_total_charges: Math.round(
          this.getCartItems[store_index].business_total_charges
        ),
        discount: 0,
        products: orders,
        sub_total: sum,
        delivery_charges: Math.round(this.timeSlotDetail_delivery_charges),
        delivery_charges_actual: Math.round(
          this.timeSlotDetail_delivery_charges_actual
        ),
        // 'tax_charges': Math.round(sum * (this.getCartItems[store_index].business_tax / 100)),
        fix_tax_charges: this.getCartItems[store_index].business_tax / 100,
        total_price: Math.round(sum + this.timeSlotDetail_delivery_charges),
        total_price_duplicate: Math.round(
          sum + this.timeSlotDetail_delivery_charges
        ),
        notes: this.orderNotes,
        platform_fee: Math.round(
          this.getCartItems[store_index].business_platform_fee
        ),
        gstCardPrice: gstCard,
        gstCashPrice: gstCash,
        gst: gstCash,
      };

      console.log(
        "Custom Array",
        obj,
        obj.total_price,
        Math.round(sum + this.timeSlotDetail_delivery_charges),
        sum,
        this.timeSlotDetail_delivery_charges
      );
      console.log(
        "tax_charges",
        this.paymentMethodOptionValue,
        this.dineinPayment
      );
      if (
        (this.dineinPayment == "cash" ||
          this.paymentMethodOptionValue == 0 ||
          this.paymentMethodOptionValue == 2) &&
        obj.gstCashPrice !== 0
      ) {
        obj.total_price += obj.gstCashPrice;
      } else if (
        (this.dineinPayment == "card" ||
          this.paymentMethodOptionValue == 1 ||
          this.paymentMethodOptionValue == 3) &&
        obj.gstCardPrice !== 0
      ) {
        obj.total_price += obj.gstCardPrice;
      } else if (this.paymentMethodOptionValue !== -1) {
        // obj.total_price += obj.tax_charges
      }

      console.log("total price", obj.total_price);
      this.customArray.push(obj);
    }
    for (let t = 0; t < this.customArray.length; t++) {
      console.log(
        "product total",
        this.customArray[t].total_price,
        this.chkOutTotal
      );
      this.chkOutTotal = this.chkOutTotal + this.customArray[t].total_price;
    }
  }

  autoSelectPaymentOption(paymentOptions: any = {}) {
    console.log("insomniac: ", paymentOptions);
    if (paymentOptions.is_cod == true) {
      console.log("insomniac: selecting cash");
      this.selectPaymentMethodOption(-1, 0, false);
    } else if (
      this.paymentCardData.length &&
      paymentOptions.is_online_payment == true
    ) {
      console.log("insomniac: selecting card");
      var cardSelected: boolean = false;
      this.paymentCardData.forEach((card, index) => {
        if (card.primary === true) {
          console.log("insomniac: selecting primary card => ", card, index);
          this.selectPaymentMethodOption(index, card, false);
          cardSelected = true;
        }
      });
      if (!cardSelected) {
        console.log(
          "insomniac: selecting first card => ",
          this.paymentCardData[0]
        );
        this.selectPaymentMethodOption(0, this.paymentCardData[0], false);
      }
    } else if (paymentOptions.is_bank_transfer == true) {
      console.log("insomniac: selecting bacnk transfer");
      this.selectPaymentMethodOption(-3, 0, false);
    } else if (paymentOptions.is_mpos == true) {
      console.log("insomniac: selecting mPOS");
      this.selectPaymentMethodOption(-4, 0, false);
    }
    console.log(
      "insomniac: payment method set to,",
      this.paymentMethodOptionValue
    );
  }

  quantityPlus(parentIndex, index, quantity, obj) {
    this.chkOutTotal = 0;
    this.cartDataFromStorage = JSON.parse(localStorage.getItem("cartItems"));
    this.customArray[parentIndex].products[index].quantity = quantity + 1;
    this.customArray[parentIndex].sub_total = Math.round(
      this.customArray[parentIndex].sub_total + obj.price
    );
    this.customArray[parentIndex].tax_charges = Math.round(
      this.customArray[parentIndex].sub_total *
        this.customArray[parentIndex].fix_tax_charges
    );
    this.customArray[parentIndex].total_price = Math.round(
      this.customArray[parentIndex].sub_total +
        this.customArray[parentIndex].delivery_charges +
        this.customArray[parentIndex].sub_total *
          this.customArray[parentIndex].fix_tax_charges
    );
    for (let i = 0; i < this.cartDataFromStorage.length; i++) {
      if (obj.hasModifier == true) {
        if (
          this.cartDataFromStorage[i].variant_id === obj.variant_id &&
          this.cartDataFromStorage[i].uniqueId == obj.uniqueId
        ) {
          this.cartDataFromStorage[i].quantity = quantity + 1;
          this.events.publish("cartUpdate", this.cartDataFromStorage);
        }
      } else if (obj.hasModifier == false) {
        if (this.cartDataFromStorage[i].variant_id === obj.variant_id) {
          this.cartDataFromStorage[i].quantity = quantity + 1;
          console.log(this.cartDataFromStorage[i]);
          this.events.publish("cartUpdate", this.cartDataFromStorage);
        }
      }
    }
    for (let t = 0; t < this.customArray.length; t++) {
      this.chkOutTotal = this.chkOutTotal + this.customArray[t].total_price;
    }

    console.log(
      "############################### Quantity Plus #################################"
    );
    this.updateFinalCartPop();
    this.clear_values();
    setTimeout(() => {
      console.log(
        "%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% Quantity Plus %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%"
      );
      this.getSlotDataForOutlet();
    }, 1000);
  }

  quantityMinus(parentIndex, index, quantity, obj) {
    console.log(obj);
    this.disabledBtnForLoadingSlotData = true;
    this.chkOutTotal = 0;
    this.cartDataFromStorage = JSON.parse(localStorage.getItem("cartItems"));
    this.customArray[parentIndex].products[index].quantity = quantity - 1;
    this.customArray[parentIndex].sub_total = Math.round(
      this.customArray[parentIndex].sub_total - obj.price
    );
    this.customArray[parentIndex].tax_charges = Math.round(
      this.customArray[parentIndex].sub_total *
        this.customArray[parentIndex].fix_tax_charges
    );
    this.customArray[parentIndex].total_price = Math.round(
      this.customArray[parentIndex].sub_total +
        this.customArray[parentIndex].delivery_charges +
        this.customArray[parentIndex].sub_total *
          this.customArray[parentIndex].fix_tax_charges
    );
    if (obj.hasModifier == true) {
      for (let i = 0; i < this.cartDataFromStorage.length; i++) {
        if (
          this.cartDataFromStorage[i].variant_id === obj.variant_id &&
          this.cartDataFromStorage[i].uniqueId === obj.uniqueId
        ) {
          this.cartDataFromStorage[i].quantity = quantity - 1;
          // this.events.publish('cartUpdate', this.cartDataFromStorage);
        }
      }
      for (let t = 0; t < this.customArray.length; t++) {
        this.chkOutTotal = this.chkOutTotal + this.customArray[t].total_price;
      }
      if (this.customArray[parentIndex].products[index].quantity < 1) {
        this.customArray[parentIndex].products.splice(index, 1);
        for (let a = 0; a < this.cartDataFromStorage.length; a++) {
          if (
            this.cartDataFromStorage[a].variant_id === obj.variant_id &&
            this.cartDataFromStorage[a].uniqueId === obj.uniqueId
          ) {
            this.cartDataFromStorage.splice(a, 1);
          }
        }
      }
      if (this.customArray[parentIndex].products.length === 0) {
        this.customArray.splice(parentIndex, 1);
      }
    }
    if (obj.hasModifier == false) {
      for (let i = 0; i < this.cartDataFromStorage.length; i++) {
        if (this.cartDataFromStorage[i].variant_id === obj.variant_id) {
          this.cartDataFromStorage[i].quantity = quantity - 1;
          // this.events.publish('cartUpdate', this.cartDataFromStorage);
        }
      }
      for (let t = 0; t < this.customArray.length; t++) {
        this.chkOutTotal = this.chkOutTotal + this.customArray[t].total_price;
      }
      if (this.customArray[parentIndex].products[index].quantity < 1) {
        this.customArray[parentIndex].products.splice(index, 1);
        for (let a = 0; a < this.cartDataFromStorage.length; a++) {
          if (this.cartDataFromStorage[a].variant_id === obj.variant_id) {
            this.cartDataFromStorage.splice(a, 1);
          }
        }
      }
      if (this.customArray[parentIndex].products.length === 0) {
        this.customArray.splice(parentIndex, 1);
      }
    }
    // for (let t = 0; t < this.customArray.length; t++) {
    //   this.chkOutTotal = this.chkOutTotal + this.customArray[t].total_price;
    // }
    // if (this.customArray[parentIndex].products[index].quantity < 1) {
    //   this.customArray[parentIndex].products.splice(index, 1);
    //   for (let a = 0; a < this.cartDataFromStorage.length; a++) {
    //     if (this.cartDataFromStorage[a].variant_id === obj.variant_id) {
    //       this.cartDataFromStorage.splice(a, 1);
    //     }
    //   }
    // }
    // if (this.customArray[parentIndex].products.length === 0) {
    //   this.customArray.splice(parentIndex, 1);
    // }
    this.events.publish("cartUpdate", this.cartDataFromStorage);
    if (this.customArray.length === 0) {
      this.enableBackground();
      $("#chkoutPop").modal("hide");
    }

    console.log(
      "############################### Quantity Minus #################################"
    );
    this.updateFinalCartPop();

    this.clear_values();
    setTimeout(() => {
      console.log(
        "%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% Quantity Minus %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%"
      );
      this.getSlotDataForOutlet();
    }, 1000);
  }

  scrollToTop() {
    var elmnt = document.getElementById("chkoutPopTop");
    elmnt.scrollIntoView({ block: "start", behavior: "smooth" });
  }

  callSigninIntlInput() {
    this.number = "";
    setTimeout(() => {
      $("#phone").intlTelInput({
        // autoFormat: true,
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.9/js/utils.js",
        autoPlaceholder: false,
        preferredCountries: ["GB"],
        preventInvalidNumbers: true,
        // stop the user from typing invalid dial codes
        preventInvalidDialCodes: true,
        formatOnDisplay: true,
        separateDialCode: false,
      });
      var countryCode;
      setTimeout(() => {
        countryCode = $("#phone").intlTelInput(
          "getSelectedCountryData"
        ).dialCode;
        $("#phone").val("+" + countryCode);
      }, 1000);
      $("#phone").change(function () {
        countryCode = $("#phone").intlTelInput(
          "getSelectedCountryData"
        ).dialCode;
        $("#phone").val("+" + countryCode);
      });
    }, 1000);
  }

  orderID: string = "";
  helplineNumber: string = "";
  PlaceOrderFinally() {
    this.checkoutDone = true;
    var dayForTime = new Date();
    var currentSystemTime = new Date(dayForTime).toISOString();

    var todayData = this.todayData;
    if (this.todayData === 0) {
      todayData = 7;
    }
    this.dataService
      .finalCheckout(
        localStorage.getItem("token"),
        this.customArray,
        this.deliveryAddressObj,
        this.paymentMethodOptionValue,
        this.dataService.businessIdForIndependentBusinessData,
        todayData,
        this.timeSlotDetail,
        this.time.open_time,
        this.time.close_time,
        this.dayIndex,
        this.outletIdToSendInCheckout,
        this.third_party_string,
        currentSystemTime
      )
      .subscribe(
        (data) => {
          this.disableCheckout = false;
          this.checkoutData = data;
          this.orderID = this.checkoutData.tracking_id;
          this.helplineNumber = this.checkoutData.helpline_number;
          this.checkoutMessage = this.checkoutData.message;
          this.checkoutSuccess = this.checkoutData.success;
          if (this.checkoutData.rest_error_code === 5) {
            toastr.error(this.checkoutData.message);
          }
          if (this.checkoutData.rest_error_code === 1) {
            toastr.error("Unable to placed the order");
          }
          if (this.checkoutData.rest_error_code === 2) {
            this.number = "";
            $("#signIn").modal("show");
            this.fbRegLogin();
            this.callSigninIntlInput();
            $(".addCartShort").css("-webkit-filter", "blur(3px)");
            $(".addCartShort").css("-moz-filter", "blur(3px)");
            $(".addCartShort").css("-o-filter", "blur(3px)");
            $(".addCartShort").css("-ms-filter", "blur(3px)");
            $(".addCartShort").css("filter", "blur(3px)");
            localStorage.setItem("isauthenticated", "false");
            localStorage.setItem("token", "");
            localStorage.setItem("displayName", "");
            localStorage.setItem("freeDeliveryCount", null);
            localStorage.setItem("userImage", "");
            this.setTokenToService();

            // this.router.navigate(['']);
            setTimeout(() => {
              var width = $(window).width();
              if (width >= 769) {
                if (this.dataService.pageName === "home") {
                  if (window.pageYOffset === 0 || window.pageYOffset < 69) {
                    // $('.logInbtn').css('color', '#ffffff');
                  } else {
                    // $('.logInbtn').css('color', this.colorCodeVar);
                  }
                }
              } else {
                // $('.logInbtn').css('color', this.colorCodeVar);
                $(".logInbtn").css("color", "#FFFFFF");
              }
              if (this.dataService.pageName === "businesses") {
                // $('.logInbtn').css('color', this.colorCodeVar);
                $(".logInbtn").css("color", "#FFFFFF");
              }
            }, 1500);
          }
          if (this.checkoutSuccess === true) {
            this.orderPlacedFrom = JSON.parse(
              localStorage.getItem("cartBusinessData")
            ).name;
            // save address if not
            let myProfileAddress = JSON.parse(
              localStorage.getItem("profileAddress")
            );
            if (myProfileAddress != "null" && myProfileAddress != null) {
              if (myProfileAddress.id === "") {
                // save address
                this.labelDelivery = myProfileAddress.place_label;
                this.editAddressId = myProfileAddress.id;
                this.streetDelivery = myProfileAddress.address_line1;
                this.zipDelivery = myProfileAddress.zip;
                this.cityDelivery = myProfileAddress.city;
                this.countryDelivery = myProfileAddress.country;
                this.stateDelivery = myProfileAddress.state;
                this.latitudeDelivery = myProfileAddress.latitude;
                this.longitudeDelivery = myProfileAddress.longitude;
                this.apartDelivery = myProfileAddress.apart_no;
                this.orderNotesNew = myProfileAddress.notes;
                this.houseNoDelivery = myProfileAddress.house_building_no;
                this.streetNoDelivery = myProfileAddress.street;
                this.addProfileAddress();
              }
            }
            this.addressUsedForOrder = "Delivery Address:";
            this.OrderSubmitted = true;
            this.fbOrderPlaced();
            $("#FinalCheckOutPopup").modal("hide");
            this.clearBlur();
            toastr.success("Your order has been placed");
            this.orderNotes = "";
            this.events.publish("cartUpdate", null);
            this.events.publish("checkoutDone", null);
            localStorage.setItem(
              "freeDeliveryCount",
              this.checkoutData.free_delivery_count
            );
            this.carBtnShowHide = false;
            this.setCheckoutAddressMainAddress();
            $("#orderDeliveredSuccess").modal("show");
            this.orderNotes = "";
            this.orderNotesNew = "";
            setTimeout(() => {
              this.checkoutDone = false;
            }, 2000);
          }
          if (this.checkoutSuccess === false) {
            if (
              this.checkoutSuccess === false &&
              this.checkoutData.over_due === true
            ) {
              $("#onOnleOverDue2").modal("show");
            }
            if (this.checkoutData.status === 0) {
              this.getBusinessData();
              $("#paymentMethodChangePopUp").modal("show");
            }
            if (this.checkoutData.status === 3) {
              toastr.error(this.checkoutMessage);
            }
            if (this.checkoutData.status === 1) {
              var getCartItems = JSON.parse(localStorage.getItem("cartItems"));
              if (this.checkoutData.out_of_stock === true) {
                for (
                  let a = 0;
                  a < this.checkoutData.out_of_stock_list.length;
                  a++
                ) {
                  for (let b = 0; b < this.customArray.length; b++) {
                    if (
                      this.customArray[b].dispensary_id ===
                      this.checkoutData.out_of_stock_list[a].dispensary_id
                    ) {
                      for (
                        let c = 0;
                        c <
                        this.checkoutData.out_of_stock_list[a].product.length;
                        c++
                      ) {
                        for (
                          let d = 0;
                          d < this.customArray[b].products.length;
                          d++
                        ) {
                          if (
                            this.customArray[b].products[d].product_id ===
                            this.checkoutData.out_of_stock_list[a].product[c]
                              .product_id
                          ) {
                            if (
                              this.checkoutData.out_of_stock_list[a].product[c]
                                .available_quantity === 0
                            ) {
                              for (var s = 0; s < getCartItems.length; s++) {
                                if (
                                  this.customArray[b].products[d].product_id ===
                                    this.checkoutData.out_of_stock_list[a]
                                      .product[c].product_id &&
                                  this.customArray[b].products[d].variant_id ===
                                    this.checkoutData.out_of_stock_list[a]
                                      .product[c].variant_id
                                ) {
                                  if (
                                    getCartItems[s].product_id ===
                                      this.customArray[b].products[d]
                                        .product_id &&
                                    getCartItems[s].variant_id ===
                                      this.customArray[b].products[d].variant_id
                                  ) {
                                    getCartItems.splice(s, 1);
                                  }
                                }
                              }
                            } else {
                              if (
                                this.checkoutData.out_of_stock_list[a].product[
                                  c
                                ].available_quantity <
                                this.customArray[b].products[d].quantity
                              ) {
                                this.customArray[b].products[d].quantity =
                                  this.checkoutData.out_of_stock_list[
                                    a
                                  ].product[c].available_quantity;
                                for (var l = 0; l < getCartItems.length; l++) {
                                  if (
                                    getCartItems[l].product_id ===
                                      this.customArray[b].products[d]
                                        .product_id &&
                                    getCartItems[l].variant_id ===
                                      this.customArray[b].products[b].variant_id
                                  ) {
                                    getCartItems[l].quantity =
                                      this.checkoutData.out_of_stock_list[
                                        a
                                      ].product[c].available_quantity;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                setTimeout(() => {
                  console.log(
                    "############################### Place Order Finally 1 #################################"
                  );
                  this.updateFinalCartPop();
                }, 1000);
                this.events.publish("cartUpdate", getCartItems);
                if (
                  this.checkoutData.out_of_stock_list[0].product.length === 1
                ) {
                  console.log(
                    "############################### Place Order Finally 2 #################################"
                  );
                  this.updateFinalCartPop();
                  this.checkoutFinalErrorMessageOutOfStock =
                    "We have run out of " +
                    this.checkoutData.out_of_stock_list[0].product[0]
                      .product_variant_name +
                    ". Hence, we have removed that item from your cart.";
                } else {
                  console.log(
                    "############################### Place Order Finally 3 #################################"
                  );
                  this.updateFinalCartPop();
                  this.checkoutFinalErrorMessageOutOfStock =
                    "We have run out of few items. Hence, we have removed those items from your cart.";
                }
              }
              getCartItems = JSON.parse(localStorage.getItem("cartItems"));
              if (this.checkoutData.platform_fee_updated === true) {
                for (var l = 0; l < getCartItems.length; l++) {
                  getCartItems[l].business_platform_fee =
                    this.checkoutData.updated_platform_fee;
                  this.customArray[0].business_platform_fee =
                    this.checkoutData.updated_platform_fee;
                  this.customArray[0].delivery_charges =
                    this.customArray[0].business_platform_fee +
                    this.customArray[0].business_delivery_charges;
                  getCartItems[l].business_total_charges =
                    this.customArray[0].delivery_charges;
                  this.events.publish("cartUpdate", getCartItems);
                  setTimeout(() => {
                    console.log(
                      "############################### Place Order Finally 4 #################################"
                    );
                    this.updateFinalCartPop();
                  }, 1000);
                }
              }
              getCartItems = JSON.parse(localStorage.getItem("cartItems"));
              if (this.checkoutData.business_charges_updated === true) {
                for (var l = 0; l < getCartItems.length; l++) {
                  getCartItems[l].business_delivery_charges =
                    this.checkoutData.updated_business_charges;
                  this.customArray[0].business_delivery_charges =
                    this.checkoutData.updated_business_charges;
                  this.customArray[0].delivery_charges =
                    this.customArray[0].business_platform_fee +
                    this.customArray[0].business_delivery_charges;
                  getCartItems[l].business_total_charges =
                    this.customArray[0].delivery_charges;
                  this.events.publish("cartUpdate", getCartItems);
                  setTimeout(() => {
                    console.log(
                      "############################### Place Order Finally 5 #################################"
                    );
                    this.updateFinalCartPop();
                  }, 1000);
                }
              }
              getCartItems = JSON.parse(localStorage.getItem("cartItems"));
              if (this.checkoutData.tax_charges_updated === true) {
                for (var l = 0; l < getCartItems.length; l++) {
                  getCartItems[l].business_tax =
                    this.checkoutData.updated_tax_charges;
                  this.events.publish("cartUpdate", getCartItems);
                  setTimeout(() => {
                    console.log(
                      "############################### Place Order Finally 6 #################################"
                    );
                    this.updateFinalCartPop();
                  }, 1000);
                }
              }
              getCartItems = JSON.parse(localStorage.getItem("cartItems"));
              if (this.checkoutData.product_variant_price_update === true) {
                for (
                  let a = 0;
                  a < this.checkoutData.out_of_stock_list.length;
                  a++
                ) {
                  for (
                    let c = 0;
                    c < this.checkoutData.out_of_stock_list[a].product.length;
                    c++
                  ) {
                    if (
                      this.checkoutData.out_of_stock_list[a].product[c]
                        .price_updated === true
                    ) {
                      for (var l = 0; l < getCartItems.length; l++) {
                        if (
                          getCartItems[l].product_id ===
                          this.checkoutData.out_of_stock_list[a].product[c]
                            .product_id
                        ) {
                          getCartItems[l].price =
                            this.checkoutData.out_of_stock_list[a].product[
                              c
                            ].updated_price;
                          getCartItems[l].sale_price =
                            this.checkoutData.out_of_stock_list[a].product[
                              c
                            ].compare_price;
                          getCartItems[l].is_on_sale =
                            this.checkoutData.out_of_stock_list[a].product[
                              c
                            ].is_on_sale;
                          this.events.publish("cartUpdate", getCartItems);
                        }
                      }
                    }
                  }
                }
                setTimeout(() => {
                  console.log(
                    "############################### Place Order Finally 7 #################################"
                  );
                  this.updateFinalCartPop();
                }, 1000);
              }
              if (this.checkoutData.out_of_stock === true) {
              }
              if (this.checkoutData.platform_fee_updated === true) {
                this.checkoutFinalErrorCount = this.checkoutFinalErrorCount + 1;
              }
              if (this.checkoutData.business_charges_updated === true) {
                this.checkoutFinalErrorCount = this.checkoutFinalErrorCount + 1;
              }
              if (this.checkoutData.tax_charges_updated === true) {
                this.checkoutFinalErrorCount = this.checkoutFinalErrorCount + 1;
              }
              if (this.checkoutData.product_variant_price_update === true) {
                this.checkoutFinalErrorCount = this.checkoutFinalErrorCount + 1;
              }

              if (this.checkoutFinalErrorCount > 0) {
                if (this.checkoutFinalErrorMessageOutOfStock !== "") {
                  if (this.checkoutFinalErrorCount === 1) {
                    this.checkoutFinalErrorMessagePriceUpdate =
                      "There is also a price updates.";
                  } else {
                    this.checkoutFinalErrorMessagePriceUpdate =
                      "There are alse price updates.";
                  }
                } else {
                  if (this.checkoutFinalErrorCount === 1) {
                    this.checkoutFinalErrorMessagePriceUpdate =
                      "There is a price update.";
                  } else {
                    this.checkoutFinalErrorMessagePriceUpdate =
                      "There are some price update.";
                  }
                }
              }

              getCartItems = JSON.parse(localStorage.getItem("cartItems"));
              if (getCartItems.length === 0) {
                $("#chkoutPop").modal("hide");
                $("#FinalCheckOutPopup").modal("hide");
                this.clearBlur();
              }
              // this.events.publish('priceUpdateFromCheckout', true);
              $("#updatedCartPop").modal("show");
            }
          }
          this.showOrderMediumNav = false;
          setTimeout(() => {
            this.checkoutDone = false;
          }, 2000);
        },
        (error) => {
          this.disableCheckout = false;
        }
      );
  }

  isPlaceOrderSignin: boolean = false;
  orderTimerStart: boolean = false;
  orderTimer: any;
  checkoutFunction() {
    $("#changeLocation").modal("hide");
    // this.paymentMethodOptionValue = 0;
    this.checkoutErrorMessage = "";
    this.checkoutFinalErrorMessagePriceUpdate = "";
    this.checkoutFinalErrorMessageOutOfStock = "";
    this.checkoutFinalErrorCount = 0;
    if (localStorage.getItem("token") === null) {
      this.number = "";
      this.isPlaceOrderSignin = true;
      $("#signIn").modal("show");
      this.fbRegLogin();
      $(".addCartShort").css("-webkit-filter", "blur(3px)");
      $(".addCartShort").css("-moz-filter", "blur(3px)");
      $(".addCartShort").css("-o-filter", "blur(3px)");
      $(".addCartShort").css("-ms-filter", "blur(3px)");
      $(".addCartShort").css("filter", "blur(3px)");
      this.callSigninIntlInput();
      return;
    }
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === undefined
    ) {
      this.number = "";
      this.isPlaceOrderSignin = true;
      $("#signIn").modal("show");
      this.fbRegLogin();
      this.callSigninIntlInput();
      $(".addCartShort").css("-webkit-filter", "blur(3px)");
      $(".addCartShort").css("-moz-filter", "blur(3px)");
      $(".addCartShort").css("-o-filter", "blur(3px)");
      $(".addCartShort").css("-ms-filter", "blur(3px)");
      $(".addCartShort").css("filter", "blur(3px)");
      return;
    }
    // if (this.deliveryAddressObj === null || this.deliveryAddressObj === undefined || this.deliveryAddressObj.zip === '' || this.deliveryAddressObj.street === '') {
    if (
      this.deliveryAddressObj === null ||
      this.deliveryAddressObj === undefined
    ) {
      this.checkoutErrorMessage = "please enter your delivery address";
      return;
    }

    if (this.timeSlotDetail === 0) {
      if (this.third_party_string === "") {
        toastr.error("Please select your delivery way");
        return;
      }
    }
    if (this.timeSlotDetail === 1) {
      if (this.time === undefined || this.time === null) {
        toastr.error("Please select a time slot");
        return;
      }
    }
    if (
      (!this.primaryCardNo || this.paymentCardData.length == 0) &&
      this.paymentMethodOptionValue == 1
    ) {
      toastr.error("Please select a payment method");
      return;
    }
    if (this.time === null) {
      this.time = {};
    }

    // For cash on delivery and card paymeny

    // if (this.paymentMethodOptionValue === -1) {
    //   this.checkoutErrorMessage = 'Please select your Payment method';
    //   return;
    // }
    // if (this.paymentMethodOptionValue === 1) {
    //   if (this.primaryCardNo === '' || this.primaryCardNo === undefined || this.primaryCardNo === null) {
    //     this.checkoutErrorMessage = 'Please add your Payment method';
    //     return;
    //   }
    // }

    this.startOrderTimer();

    /**
     * FinalCheckOutPopup has been taken out of the flow.
     * now, we show cancel order text on order button.
     */
    // $('#FinalCheckOutPopup').modal({
    //   backdrop: 'static',
    //   keyboard: false
    // });
    // $('#FinalCheckOutPopup').modal('show');
    // this.addBlur();
  }

  startOrderTimer() {
    console.log("order timer started!");
    this.orderTimerStart = true;
    this.orderTimer = setTimeout(() => {
      console.log("placing order!");
      this.orderTimerStart = false;
      this.disableCheckout = true;
      this.PlaceOrderFinally();
      this.fbCheckout2(this.customArray);
    }, 3000);
  }

  stopOrder() {
    console.log("stopping order");
    this.orderTimerStart = false;
    clearTimeout(this.orderTimer);
  }

  getBusinessData() {
    if (
      localStorage.getItem("businessId") != null &&
      localStorage.getItem("businessId") != "" &&
      localStorage.getItem("businessId") != undefined
    ) {
      this.businessId = localStorage.getItem("businessId");
    } else {
      console.log(this.businessId);
      let currentUrl = window.location.pathname;
      let currentUrlArr = currentUrl.split("/");
      if (
        currentUrlArr.indexOf("business-contact-us") > -1 ||
        currentUrlArr.indexOf("business-about-us") > -1 ||
        currentUrlArr.indexOf("business-store-locations") > -1 ||
        currentUrlArr.indexOf("business-careers") > -1 ||
        currentUrlArr.indexOf("business-policy") > -1
      ) {
        this.themeStructureId = 1;
      } else {
        this.themeStructureId = 0;
      }
    }
    var todayData = this.todayData;
    if (this.todayData === 0) {
      todayData = 7;
    }
    this.dataService
      .businessDetail(this.businessId, todayData)
      .subscribe((data) => {
        this.loadNavBar = true;
        this.businessDetailData = data;
        this.businessDetailData = this.businessDetailData.business_detail;
        if (this.checkoutOpenCheck == false) {
          this.themeStructureId = this.businessDetailData.theme_structure_id;
        }
        this.custom_order_form = this.businessDetailData.has_custom_order_form;
        this.customTitle = this.businessDetailData.custom_order_title;
        this.customImage = this.businessDetailData.custom_title_image;
        this.business_id_customerOrder = this.businessDetailData.id;
        var cartBusinessData = JSON.parse(
          localStorage.getItem("cartBusinessData")
        );
        if (cartBusinessData) {
          this.isDineinAvailable = cartBusinessData.is_dine_in_available;
          this.isPickupAvailable = cartBusinessData.is_pickup_available;
          this.businessDisclaimer = cartBusinessData.disclaimer_note;
          this.deliveryPaymentOptions =
            cartBusinessData.delivery_payment_options;
          this.dineInPaymentOptions = cartBusinessData.dine_in_payment_options;
          this.pickupPaymentOptions = cartBusinessData.pickup_payment_options;
          this.paymentThroughCash = cartBusinessData.payment_through_cash;
          this.paymentThroughCard = cartBusinessData.payment_through_card;
          this.paymentThroughBankTransfer =
            cartBusinessData.payment_through_bank_transfer;
          this.paymentThroughMpos = cartBusinessData.payment_through_mpos;
          if (this.paymentThroughCard) {
            this.paymentMethodOptionValue = 1;
            this.dineinPayment = "card";
          }
        }
        console.log("insomniac: payment options", this.deliveryPaymentOptions);
        // this.autoSelectPaymentOption(this.deliveryPaymentOptions)
        this.qrCode = "false";
        let table;
        this.route.queryParams.subscribe((params) => {
          if (params["qrcode"] != undefined || params["qrcode"] != null) {
            this.qrCode = params["qrcode"];
            this.landedFromQR = true;
            this.showDownloadBanner = true;
            this.dataControlService.showBanner$.next(true);
          }
          if (
            params["tablenumber"] != undefined ||
            params["tablenumber"] != null
          ) {
            this.tableNumber = params["tablenumber"];
            table = this.tableNumber;
          }
          if (params["outlet_id"] != undefined || params["outlet_id"] != null) {
            this.dineinOutletId = params["outlet_id"];
          }
          if (
            params["outletname"] != undefined ||
            params["outletname"] != null
          ) {
            this.outletName = params["outletname"];
          }
          if (params["type"] != undefined || params["type"] != null) {
            this.type = params["type"];
            this.type = this.type.toLowerCase();
            if (this.type == "dinein") {
              this.dineIn = true;
              this.selfPickup = false;
              this.delivery = false;
              this.dineinAddressString =
                this.businessDetailData.name + " - " + this.outletName;
            } else if (this.type == "pickup") {
              this.dineinAddressString =
                this.businessDetailData.name + " - " + this.outletName;
              this.dineIn = false;
              this.selfPickup = true;
              this.delivery = false;
            } else {
              this.dineinAddressString =
                this.businessDetailData.name + " - " + this.outletName;
              this.dineIn = false;
              this.selfPickup = false;
              this.delivery = true;
            }
          }
        });
        setTimeout(() => {
          $("#dine-in").click(() => {
            // this.startQRScanner();
            $("#dine-in").addClass("checkout-tags");
            $("#delivery").removeClass("checkout-tags");
            $("#self-pickup").removeClass("checkout-tags");
            this.dineIn = true;
            this.selfPickup = false;
            this.delivery = false;
            this.tableNumber = table;
            this.qrCode = "true";
            this.checkOutState = 0;
            // this.dineinPayment = '-1';

            if (this.paymentThroughCard) {
              this.paymentMethodOptionValue = 1;
              this.dineinPayment = "card";
            } else {
              this.paymentMethodOptionValue = this.paymentMethodOptionValue;
              this.dineinPayment = this.dineinPayment;
            }
            console.log(
              "%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% Get Business Data 1 %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%"
            );
            this.getSlotDataForOutlet();
            this.updateFinalCartPop();
          });
          $("#self-pickup").click(() => {
            /**
             * if landed through QR code, set selected outlet to the outlet ID from params.
             * else show outlet pop
             */
            this.openOutletAddressPop(this.landedFromQR ? false : true);
            this.scanQR = false;
            $("#self-pickup").addClass("checkout-tags");
            $("#dine-in").removeClass("checkout-tags");
            $("#delivery").removeClass("checkout-tags");
            $(".qr-scanner").css("display", "none");
            this.dineIn = false;
            this.selfPickup = true;
            this.delivery = false;
            this.tableNumber = -1;
            this.qrCode = "false";
            this.checkQrStatus = 2;
            // this.dineinPayment = '-1';
            if (this.paymentThroughCard) {
              this.paymentMethodOptionValue = 1;
              this.dineinPayment = "card";
            } else {
              this.paymentMethodOptionValue = this.paymentMethodOptionValue;
            }

            this.getSlotDataForOutlet();
            this.updateFinalCartPop();
            setTimeout(() => {
              $("#pickup-notes").focus();
            }, 500);
          });
          $("#delivery").click(() => {
            this.scanQR = false;
            $("#delivery").addClass("checkout-tags");
            $("#dine-in").removeClass("checkout-tags");
            $("#self-pickup").removeClass("checkout-tags");
            $(".qr-scanner").css("display", "none");
            this.dineIn = false;
            this.selfPickup = false;
            this.delivery = true;
            this.tableNumber = -1;
            this.qrCode = "false";
            this.checkOutState = 1;
            this.checkQrStatus = 1;
            this.timeSlotDetail = null;
            this.timeSlotDetail_delivery_charges = 0;
            // this.dineinPayment = '-1';
            if (this.paymentThroughCard) {
              this.paymentMethodOptionValue = 1;
              this.dineinPayment = "card";
            } else {
              this.paymentMethodOptionValue = this.paymentMethodOptionValue;
            }

            this.getSlotDataForOutlet();
            this.updateFinalCartPop();
          });
        }, 500);

        if (
          this.isDineinAvailable == true &&
          this.isPickupAvailable == true &&
          this.qrCode == "true" &&
          this.widthOfWindow < 768
        ) {
          console.log("ethay");
          setTimeout(() => {
            $("#dine-in").addClass("checkout-tags");
            this.dineIn = true;
            this.selfPickup = false;
            this.delivery = false;
            this.tableNumber = table;
            this.qrCode = "true";
            this.checkOutState = 0;
            // this.deliveryAddressObj = {
            //   id: this.dineinOutletId,
            //   street: this.businessDetailData.street,
            //   address_line1: this.businessDetailData.address_line1,
            //   zip: this.businessDetailData.zip,
            //   city: this.businessDetailData.city,
            //   country: this.businessDetailData.area,
            //   state: this.businessDetailData.state,
            //   latitude: this.businessDetailData.latitude,
            //   longitude: this.businessDetailData.longitude,
            //   apart_no: this.businessDetailData.apart_no,
            //   notes: '',
            //   house_building_no: ''
            // };
            this.setCartOption();
          }, 500);
        } else if (
          this.isDineinAvailable == true &&
          this.isPickupAvailable == false &&
          this.qrCode == "true" &&
          this.widthOfWindow < 768
        ) {
          setTimeout(() => {
            $("#dine-in").addClass("checkout-tags");
            this.dineIn = true;
            this.selfPickup = false;
            this.delivery = false;
            this.tableNumber = table;
            this.qrCode = "true";
            this.checkOutState = 0;
            this.deliveryAddressObj = {
              id: this.dineinOutletId,
              street: this.businessDetailData.street,
              address_line1: this.businessDetailData.address_line1,
              zip: this.businessDetailData.zip,
              city: this.businessDetailData.city,
              country: this.businessDetailData.area,
              state: this.businessDetailData.state,
              latitude: this.businessDetailData.latitude,
              longitude: this.businessDetailData.longitude,
              apart_no: this.businessDetailData.apart_no,
              notes: "",
              house_building_no: "",
            };
            this.setCartOption();
          }, 500);
        } else if (
          this.isDineinAvailable == true &&
          this.isPickupAvailable == true &&
          this.widthOfWindow > 768
        ) {
          setTimeout(() => {
            this.scanQR = false;
            $("#delivery").addClass("checkout-tags");
            $("#dine-in").removeClass("checkout-tags");
            $("#self-pickup").removeClass("checkout-tags");
            $(".qr-scanner").css("display", "none");
            this.dineIn = false;
            this.selfPickup = false;
            this.delivery = true;
            this.tableNumber = -1;
            this.qrCode = "false";
            this.checkOutState = 2;
            this.checkQrStatus = 1;
            this.setCartOption();
          }, 500);
        } else if (
          this.isDineinAvailable == false &&
          this.isPickupAvailable == true &&
          this.widthOfWindow > 768
        ) {
          $("#delivery").addClass("checkout-tags");
          $("#self-pickup").removeClass("checkout-tags");
          $("#dine-in").removeClass("checkout-tags");
          this.dineIn = false;
          this.selfPickup = false;
          this.delivery = true;
          this.tableNumber = -1;
          this.qrCode = "false";
          this.checkOutState = 1;
          this.checkQrStatus = 1;
          this.setCartOption();
        } else {
          setTimeout(() => {
            $("#delivery").addClass("checkout-tags");
            $("#self-pickup").removeClass("checkout-tags");
            $("#dine-in").removeClass("checkout-tags");
            this.dineIn = false;
            this.selfPickup = false;
            this.delivery = true;
            // this.tableNumber = -1;
            this.qrCode = "false";
            this.checkQrStatus = 1;
            this.checkOutState = 1;
            this.setCartOption();
          }, 500);
        }
        console.log(this.custom_order_form);
      });
  }

  setCartOption() {
    console.log("insomniac: setting cart option", this.type);
    setTimeout(() => {
      if (this.type == "pickup") {
        $("#self-pickup").click();
      }
      if (this.type == "dinein") {
        $("#dine-in").click();
      }
      if (this.type == "delivery") {
        $("#delivery").click();
      }
    }, 100);
  }

  cancleGlobleAddress() {
    localStorage.setItem("delivery_address", "Please Select your location");
    localStorage.setItem("myDeliveryAddress", "Please Select your location");
    localStorage.setItem("profileAddressCheckout", JSON.stringify(null));
    localStorage.setItem("profileAddress", JSON.stringify(null));
    this.dataControlService.setAddressOnAddressInput.next(false);
    this.streetDelivery = "";
    this.isLocationFinalized = false;
    this.shopnowState = "disable";
    this.emptyCartCancelAddress();

    this.router.navigate(["/"]);
  }

  emptyCartCancelAddress() {
    // change city so empty cart here
    this.disableMultiClick = true;
    this.cartDataFromStorage = [];
    this.events.publish("cartUpdate", this.cartDataFromStorage);
    localStorage.removeItem("cartItems");
    $("#onChangingCityBeforeCheckout").modal("hide");
    this.disableMultiClick = false;
    this.carBtnShowHide = false;
    this.setCheckoutAddressMainAddress();
  }

  headerLogoClicked() {
    if (
      localStorage.getItem("profileAddress") == null ||
      localStorage.getItem("profileAddress") == "null" ||
      localStorage.getItem("myDeliveryAddress") == "Please Select your location"
    ) {
      this.dataControlService.setAddressOnAddressInput.next(false);
    } else {
      this.dataControlService.setAddressOnAddressInput.next(true);
      this.streetDelivery = "";
    }
    this.router.navigate(["/"]);

    $(".bigFooterSection").css("top", "35rem");
    $(".myMobileFooter").css("top", "15rem");
  }

  openAddressPopDeaher2() {
    this.address_line1 = "";
    this.zip = "";
    this.showloder = "pin.svg";
    this.myDeliveryObj = JSON.parse(localStorage.getItem("profileAddress"));

    if (this.myDeliveryObj != "null" && this.myDeliveryObj !== null) {
      (this.addressId = this.myDeliveryObj.id),
        this.locationFormModal.controls.streetLocationModal.setValue(
          this.myDeliveryObj.address_line1
        );
      this.locationFormModal.controls.areaLocationModal.setValue(
        this.myDeliveryObj.address_line1
      );
      this.locationFormModal.controls.postalLocationModal.setValue(
        this.myDeliveryObj.zip
      );
      this.locationFormModal.controls.cityLocationModal.setValue(
        this.myDeliveryObj.city
      );
      this.locationFormModal.controls.countryLocationModal.setValue(
        this.myDeliveryObj.country
      );
      this.locationFormModal.controls.stateLocationModal.setValue(
        this.myDeliveryObj.state
      );
      this.locationFormModal.controls.aptLocationModal.setValue(
        this.myDeliveryObj.apart_no
      );
      this.locationFormModal.controls.houseNoLocationModal.setValue(
        this.myDeliveryObj.house_building_no
      );
      this.locationFormModal.controls.placeLabelLocationModal.setValue(
        this.myDeliveryObj.place_label
      );
      this.latitudeDelivery = this.myDeliveryObj.latitude;
      this.longitudeDelivery = this.myDeliveryObj.longitude;
      this.orderNotesNew = this.myDeliveryObj.notes;
    }
    $("#myAddressLabelApp").css("color", "unset");

    $("#headAddressSelection").modal("show");

    document.getElementById("streetDeliveryApp").focus();
    document.getElementById("streetDeliveryApp").click();

    this.isSuggestionAddressShownModal = false;
    this.isSearchAddressShownModal = false;
    // this.streetDelivery = localStorage.getItem('delivery_address');
    if (
      localStorage.getItem("profileAddress") !== "null" &&
      localStorage.getItem("profileAddress") !== null &&
      localStorage.getItem("myDeliveryAddress") !==
        "Please Select your location" &&
      localStorage.getItem("myDeliveryAddress") !== null &&
      localStorage.getItem("myDeliveryAddress") !== ""
    ) {
      this.streetDelivery = localStorage.getItem("myDeliveryAddress");
      this.isLocationFinalized = true;
      this.shopnowState = "enable";
    } else {
      this.streetDelivery = "";
      this.isLocationFinalized = false;
      this.shopnowState = "disable";
    }
  }

  functionTogetPlace2() {
    this.showloder = "loader.gif";
    this.placeDataFound = false;
    this.placeSuggestionsData = [];
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      this.dataService.googleAddress(this.address_line1).subscribe(
        (data) => {
          this.showloder = "pin.svg";
          this.placeSuggestionsData = data;
          this.placeSuggestionsData = this.placeSuggestionsData.predictions;
          this.placeDataFound = true;
          if ($(".myDrop").hasClass("open")) {
          } else {
            $(".myDrop").addClass("open");
          }
        },
        (error) => {}
      );
    }, 1000);
  }

  functionTogetPlaceDetail2(name, id) {
    this.addressLoad = true;
    this.address_line1 = name;
    this.cityname = name;
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      this.dataService.googleAddressDetail(id).subscribe(
        (data) => {
          this.placeDetailData = data;
          this.showHideTextLoader = true;
          this.placeDetailData = this.placeDetailData.result;
          this.status = data;
          this.status = this.status.status;
          if (this.status === "NOT_FOUND") {
            toastr.error("location not found");
          }
          this.latitude = this.placeDetailData.geometry.location.lat;
          this.longitude = this.placeDetailData.geometry.location.lng;
          this.placeDetailData = this.placeDetailData.address_components;
          setTimeout(() => {
            this.addressDetail2();
            this.addressLoad = false;
          }, 1000);
        },
        (error) => {}
      );
    }, 1000);
  }

  addressDetail2() {
    this.showHideTextLoader = false;
    this.street = "";
    for (var i = 0; i < this.placeDetailData.length; i++) {
      for (var j = 0; j < this.placeDetailData[i].types.length; j++) {
        if (
          this.placeDetailData[i].types[j] === "administrative_area_level_2"
        ) {
          $("#city").val(this.placeDetailData[i].long_name);
        }
        if (this.placeDetailData[i].types[j] === "street_number") {
          this.street = this.placeDetailData[i].long_name;
        }
        if (this.placeDetailData[i].types[j] === "route") {
          this.street = this.street + " " + this.placeDetailData[i].long_name;
        }
        if (this.placeDetailData[i].types[j] === "sublocality_level_2") {
          this.street = this.street + " " + this.placeDetailData[i].long_name;
        }
        if (this.placeDetailData[i].types[j] === "sublocality_level_1") {
          this.street = this.street + " " + this.placeDetailData[i].long_name;
        }

        if (
          this.placeDetailData[i].types[j] === "administrative_area_level_1"
        ) {
          $("#state").val(this.placeDetailData[i].short_name);
        }

        if (this.placeDetailData[i].types[j] === "country") {
          $("#area").val(this.placeDetailData[i].long_name);
        }

        if (this.placeDetailData[i].types[j] === "postal_code") {
          this.zip = this.placeDetailData[i].long_name;
          $("#zip").val(this.placeDetailData[i].long_name);
        } else {
          $("#zip").val("");
        }
      }
    }
    if (this.zip === "" || this.zip === undefined) {
      this.zip = "";
      $("#showZipPopHead").modal("show");
    } else {
      this.validateCityFunction2(this.zip);
    }
  }

  validateCityFunction2(zip) {
    this.address_line1 = "";
    $(".c-label label").css("color", "#3d3e3d");
    $(".c-label input").css("border-color", "#ccc");
    if (zip === "" || zip === undefined) {
      $("#c-zip label").css("color", "#a10a0d");
      $("#c-zip input").css("border-color", "#a10a0d");
      return;
    }
    this.nao.go(30);
    this.dataService.validateCity(zip).subscribe(
      (data) => {
        this.nao.go(100);
        this.cityList = data;
        this.cityList = this.cityList.city_list;

        this.validateSuccess = data;
        if (this.validateSuccess.rest_error_code === 4) {
          $("#availableCityPop2").modal("show");
        }
        this.cityId = this.validateSuccess.city_list[0].id;
        this.validateSuccessTrue = this.validateSuccess.success;
        if (this.validateSuccessTrue === true) {
          localStorage.setItem("delivery_address", this.cityname);
          localStorage.setItem("myDeliveryAddress", this.cityname);

          this.events.publish("dataChange", this.cityId);
          $("#showZipPopHead").modal("hide");
          $("#headAddress").modal("hide");
          setTimeout(() => {
            // Select your location popup in header
            this.router.navigate([
              "/businesses",
              this.validateSuccess.city_list[0].city_short_name,
            ]);
          }, 1000);
        }
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  validateCityFunction2FromCheckout(zip) {
    this.address_line1 = "";
    $(".c-label label").css("color", "#3d3e3d");
    $(".c-label input").css("border-color", "#ccc");
    if (zip === "" || zip === undefined) {
      $("#c-zip label").css("color", "#a10a0d");
      $("#c-zip input").css("border-color", "#a10a0d");
      return;
    }
    this.nao.go(30);
    this.dataService.validateCity(zip).subscribe(
      (data) => {
        this.nao.go(100);
        this.cityList = data;
        this.cityList = this.cityList.city_list;
        //this.changeRef.detectChanges();

        this.validateSuccess = data;
        if (this.validateSuccess.rest_error_code === 4) {
          $("#availableCityPop2").modal("show");
        }
        //this.changeRef.detectChanges();
        this.cityId = this.validateSuccess.city_list[0].id;
        this.validateSuccessTrue = this.validateSuccess.success;
        if (this.validateSuccessTrue === true) {
          //this.changeRef.detectChanges();
          $("#showZipPopHead").modal("hide");
          $("#headAddress").modal("hide");
        }
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }

  isMainSigninClicked: boolean = false;
  signInPop() {
    this.isMainSigninClicked = true;
    this.closeNav();

    this.number = "";
    this.firstName = "";
    this.lastName = "";
    this.code = "";
    $("#datepicker").val("");
    this.number = "";
    $("#signIn").modal("show");
    this.fbRegLogin();
    this.callSigninIntlInput();
    $(".addCartShort").css("-webkit-filter", "blur(3px)");
    $(".addCartShort").css("-moz-filter", "blur(3px)");
    $(".addCartShort").css("-o-filter", "blur(3px)");
    $(".addCartShort").css("-ms-filter", "blur(3px)");
    $(".addCartShort").css("filter", "blur(3px)");

    setTimeout(() => {
      $("#phone").intlTelInput({
        // autoFormat: true,
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.9/js/utils.js",
        autoPlaceholder: false,
        preferredCountries: ["GB"],
        preventInvalidNumbers: true,
        // stop the user from typing invalid dial codes
        preventInvalidDialCodes: true,
        formatOnDisplay: true,
        separateDialCode: false,
      });
      var countryCode;
      setTimeout(() => {
        countryCode = $("#phone").intlTelInput(
          "getSelectedCountryData"
        ).dialCode;
        $("#phone").val("+" + countryCode);
      }, 1000);
      $("#phone").change(function () {
        countryCode = $("#phone").intlTelInput(
          "getSelectedCountryData"
        ).dialCode;
        $("#phone").val("+" + countryCode);
      });
    }, 2000);
  }

  restrictUserInput($event) {
    $event.preventDefault();
  }

  countryValidation() {
    var code = $("#phone").intlTelInput("getSelectedCountryData").dialCode;
    code = "+" + code;
    var val = $("#phoneValue").val();
    for (var i = 0; i < this.cs.country.length; i++) {
      if (code === this.cs.country[i].countryCode) {
        if (
          val.length === this.cs.country[i].length ||
          val.length === this.cs.country[i].length + 1
        ) {
          this.loginBtn = false;
          // $('#phoneValue').css('border-color', '#ccc');
        } else {
          this.loginBtn = true;
          // $('#phoneValue').css('border-color', '#eb3b3b');
        }
      }
    }
  }

  countryValidationProfile() {
    var code = $("#profilePhone").intlTelInput(
      "getSelectedCountryData"
    ).dialCode;
    code = "+" + code;
    var val = $("#ProfilePhoneValue").val();
    for (var i = 0; i < this.cs.country.length; i++) {
      if (code === this.cs.country[i].countryCode) {
        if (
          val.length === this.cs.country[i].length ||
          val.length === this.cs.country[i].length + 1
        ) {
          this.loginBtn = false;
          // $('#phoneValue').css('border-color', '#ccc');
        } else {
          this.loginBtn = true;
          // $('#phoneValue').css('border-color', '#eb3b3b');
        }
      }
    }
  }

  prevent($event) {
    if ($event.keyCode === 8 || $event.keyCode === 46) {
      return;
    }
    if (!($event.keyCode >= 48 && $event.keyCode <= 57)) {
      $event.preventDefault();
      return;
    }
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  LoginCheck(val) {
    if (val === "continue") {
      this.loginBtn = true;
      this.signInFunction();
    }
    if (val === "resendCode") {
      this.loginBtn = false;
      this.signInFunction();
    }
  }

  signInFunction() {
    this.codeError = "";
    clearInterval(this.intervalSet);
    this.reSendCount = 20;
    this.resendButtonShow = false;
    let getCode = $("#phone").intlTelInput("getSelectedCountryData").dialCode;
    if (this.number.startsWith("0")) {
      this.number = this.number.substring(1);
    }
    this.numberToSend = "+" + getCode + this.number;
    this.nao.go(30);
    this.numberToDisplay = this.numberToSend;
    this.numberToDisplay = format(this.numberToDisplay, "International");
    this.authService.login(this.numberToSend).subscribe(
      (data) => {
        this.nao.go(100);
        this.loginData = data;
        if (this.loginData.rest_error_code === 4) {
          $("#signIn").modal("hide");
          if (this.loginBtn === true) {
            setTimeout(() => {
              this.firstName = "";
              this.lastName = "";
              this.code = "";
              $("#signUp").modal("show");
              $("#registerdobday").empty();
              $("#registerdobmonth").empty();
              $("#registerdobyear").empty();
              var dobPicker = {
                daySelector: "#registerdobday",
                monthSelector: "#registerdobmonth",
                yearSelector: "#registerdobyear",
                dayDefault: "Day",
                monthDefault: "Month",
                yearDefault: "Year",
                minimumAge: 21,
                maximumAge: 80,
              };
              this.datePicker(dobPicker);
            }, 1000);
          }
          // clearInterval(this.intervalSet);
        } else {
          this.cashImg = false;
          $("#signIn").modal("hide");
          setTimeout(() => {
            $("#codePop").modal("show");
            $(".addCartShort").css("-webkit-filter", "blur(3px)");
            $(".addCartShort").css("-moz-filter", "blur(3px)");
            $(".addCartShort").css("-o-filter", "blur(3px)");
            $(".addCartShort").css("-ms-filter", "blur(3px)");
            $(".addCartShort").css("filter", "blur(3px)");
          }, 1000);
        }
        this.intervalSet = setInterval(() => {
          this.reSendCount = this.reSendCount - 1;
          if (this.reSendCount === 0) {
            clearInterval(this.intervalSet);
            this.resendButtonShow = true;
          }
        }, 1000);
        // this.openOutletAddressPop(false);
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }

  clearIntervel() {
    this.dataControlService.isSignInWishlistClicked.next(false);

    this.loginBtn = true;
    clearInterval(this.intervalSet);
    $(".addCartShort").css("-webkit-filter", "blur(0px)");
    $(".addCartShort").css("-moz-filter", "blur(0px)");
    $(".addCartShort").css("-o-filter", "blur(0px)");
    $(".addCartShort").css("-ms-filter", "blur(0px)");
    $(".addCartShort").css("filter", "blur(0px)");
  }

  profileNoChangeFunction() {
    this.codeError = "";
    clearInterval(this.intervalSet);
    this.reSendCount = 20;
    this.resendButtonShow = false;
    let getCode = $("#profilePhone").intlTelInput(
      "getSelectedCountryData"
    ).dialCode;
    this.numberToSendProfile = "+" + getCode + this.number;
    this.nao.go(30);
    this.numberToDisplayProfile = this.numberToSendProfile;
    this.numberToDisplayProfile = format(
      this.numberToDisplayProfile,
      "International"
    );
    this.dataService
      .changeProfileNo(localStorage.getItem("token"), this.numberToSendProfile)
      .subscribe(
        (data) => {
          this.nao.go(100);
          this.loginData = data;
          if (this.loginData.rest_error_code === 3) {
            toastr.error("Please enter your change phone no.");
          } else {
            $("#profileNoUpdate").modal("hide");
            this.clearBlur();
            setTimeout(() => {
              $("#codePopProfile").modal("show");
              $(".addCartShort").css("-webkit-filter", "blur(3px)");
              $(".addCartShort").css("-moz-filter", "blur(3px)");
              $(".addCartShort").css("-o-filter", "blur(3px)");
              $(".addCartShort").css("-ms-filter", "blur(3px)");
              $(".addCartShort").css("filter", "blur(3px)");
            }, 1000);
          }
          this.intervalSet = setInterval(() => {
            this.reSendCount = this.reSendCount - 1;
            if (this.reSendCount === 0) {
              // this.signInFunction();
              // this.reSendCount = 20;
              clearInterval(this.intervalSet);
              this.resendButtonShow = true;
            }
          }, 1000);
        },
        (error) => {
          this.nao.go(100);
        }
      );
  }

  getAddressListing() {
    this.dataService.getAddressList(localStorage.getItem("token")).subscribe(
      (data) => {
        this.customerProfileListApp = data;
      },
      (error) => {}
    );
  }

  setProfileAddress(myProfileAddress) {
    if (myProfileAddress !== null) {
      this.deliveryAddressObj = {
        id: myProfileAddress.id,
        street: myProfileAddress.address_line1,
        address_line1: myProfileAddress.address_line1,
        zip: myProfileAddress.zip,
        city: myProfileAddress.city,
        country: myProfileAddress.country,
        state: myProfileAddress.state,
        latitude: myProfileAddress.latitude,
        longitude: myProfileAddress.longitude,
        apart_no: myProfileAddress.apart_no,
        notes: myProfileAddress.notes,
        house_building_no: myProfileAddress.house_building_no,
      };
    }
  }

  isCheckoutSignup: boolean = false;

  selectGender(val) {
    if (this.gender != val) {
      this.showGender = true;
    }
    this.gender = val;
  }

  registerFunction() {
    this.codeError = "";
    this.nao.go(30);
    this.code = this.code.toString();
    this.firstName = this.firstName.trim();
    this.lastName = this.lastName.trim();

    var day = $("#registerdobday").val();
    var month = $("#registerdobmonth").val();
    var year = $("#registerdobyear").val();
    if (this.gender == -1) {
      toastr.error("Please select gender");
      return;
    }
    if (
      day === "Day" ||
      day === undefined ||
      day === null ||
      month === "Month" ||
      month === undefined ||
      month === null ||
      year === "Year" ||
      year === undefined ||
      year === null
    ) {
      toastr.error("Please select Date of Birth");
      return;
    }

    this.registerBtnDisable = true;
    this.nao.go(30);

    this.dob = month + "/" + day + "/" + year;
    this.authService
      .register(
        this.numberToSend,
        this.code,
        true,
        this.firstName,
        this.lastName,
        this.dob,
        this.gender
      )
      .subscribe(
        (data) => {
          this.nao.go(100);
          this.userData = data;
          if (this.userData.rest_error_code === 4) {
            this.codeError = "Please enter a valid code";
            this.registerBtnDisable = false;
            return;
          }
          localStorage.setItem("isauthenticated", "true");
          localStorage.setItem("token", this.userData.auth_token);

          this.userData = this.userData.profile;
          localStorage.setItem(
            "displayName",
            this.userData.first_name + " " + this.userData.last_name
          );
          localStorage.setItem(
            "freeDeliveryCount",
            this.userData.free_delivery_count
          );
          localStorage.setItem(
            "purchaseSubscription",
            this.userData.is_package
          );
          localStorage.setItem("packageCount", this.userData.package_count);
          localStorage.setItem("userImage", this.userData.img_url);
          this.events.publish("inlogin", "true");

          $("#signUp").modal("hide");
          $("#signIn").modal("hide");
          $("#codePop").modal("hide");
          this.fbRegistration();
          this.setTokenToService();

          this.getPayementCard();

          let myProfileAddress = JSON.parse(
            localStorage.getItem("profileAddress")
          );
          this.setNullIdinCheckoutAddress();

          this.dataControlService.getAddressesOnHome.next(true);

          this.setProfileAddress(myProfileAddress);
          console.log(
            "%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% Register Function %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%"
          );
          this.getSlotDataForOutlet();

          if (myProfileAddress.id === "") {
            this.isCheckoutSignup = true;
            this.setAddNotesAddressValuesFromCheckout(myProfileAddress);
          }
          this.dataControlService.isSignInWishlistClicked.next(false);

          this.getAddressListing();
          this.pushNotificationFunction();
          setTimeout(() => {
            if (this.dataService.pageName === "home") {
              if (window.pageYOffset === 0 || window.pageYOffset < 69) {
                $(".username").css("color", "#000000");
              }
            }
          }, 1500);
          this.registerBtnDisable = false;
          this.callPushNotificationFunction();
          this.clearBlur();
          clearInterval(this.intervalSet);
        },
        (error) => {
          this.nao.go(100);
        }
      );
  }

  setNullIdinCheckoutAddress() {
    let myProfileAddressCheckout = JSON.parse(
      localStorage.getItem("profileAddressCheckout")
    );
    if (
      myProfileAddressCheckout != null &&
      myProfileAddressCheckout != "null"
    ) {
      let obj = {
        id: "",
        street: myProfileAddressCheckout.address_line1,
        address_line1: myProfileAddressCheckout.address_line1,
        zip: myProfileAddressCheckout.zip,
        city: myProfileAddressCheckout.city,
        country: myProfileAddressCheckout.country,
        state: myProfileAddressCheckout.state,
        latitude: myProfileAddressCheckout.latitude,
        longitude: myProfileAddressCheckout.longitude,
        apart_no: myProfileAddressCheckout.apart_no,
        notes: myProfileAddressCheckout.notes,
        house_building_no: myProfileAddressCheckout.house_building_no,
        place_label: myProfileAddressCheckout.place_label,
      };
      localStorage.setItem("profileAddressCheckout", JSON.stringify(obj));
    }

    let myProfileAddress = JSON.parse(localStorage.getItem("profileAddress"));
    if (myProfileAddress != null && myProfileAddress != "null") {
      let obj1 = {
        id: "",
        street: myProfileAddress.address_line1,
        address_line1: myProfileAddress.address_line1,
        zip: myProfileAddress.zip,
        city: myProfileAddress.city,
        country: myProfileAddress.country,
        state: myProfileAddress.state,
        latitude: myProfileAddress.latitude,
        longitude: myProfileAddress.longitude,
        apart_no: myProfileAddress.apart_no,
        notes: myProfileAddress.notes,
        house_building_no: myProfileAddress.house_building_no,
        place_label: myProfileAddress.place_label,
      };
      localStorage.setItem("profileAddress", JSON.stringify(obj1));
    }
  }

  getAppBtnClicked() {
    if (this.windowCurrentWidth >= 768) {
      var myFooter = document.getElementById("myBigFooter");
      myFooter.scrollIntoView();
    } else {
      var myMobileFooter = document.getElementById("myMobileFooter");
      myMobileFooter.scrollIntoView();
    }
  }

  getInfoUsingToken() {
    this.authService.getInfoFromToken(localStorage.getItem("token")).subscribe(
      (data) => {
        this.userData = data;
        if (this.userData.rest_error_code === 2) {
          localStorage.setItem("isauthenticated", "false");
          localStorage.setItem("token", "");
          localStorage.setItem("displayName", "");
          localStorage.setItem("freeDeliveryCount", null);
          localStorage.setItem("purchaseSubscription", "false");
          localStorage.setItem("packageCount", "0");
          localStorage.setItem("userImage", "");
          this.setTokenToService();

          // this.router.navigate(['']);
          setTimeout(() => {
            var width = $(window).width();
            if (width >= 769) {
              if (this.dataService.pageName === "home") {
                if (window.pageYOffset === 0 || window.pageYOffset < 69) {
                  // $('.logInbtn').css('color', '#ffffff');
                } else {
                  // $('.logInbtn').css('color', this.colorCodeVar);
                }
              }
            } else {
              // $('.logInbtn').css('color', this.colorCodeVar);
              $(".logInbtn").css("color", "#FFFFFF");
            }
            if (this.dataService.pageName === "businesses") {
              // $('.logInbtn').css('color', this.colorCodeVar);
              $(".logInbtn").css("color", "#FFFFFF");
            }
          }, 1500);
        } else {
          localStorage.setItem("isauthenticated", "true");
          localStorage.setItem("token", this.userData.auth_token);
          this.userData = this.userData.profile;
          localStorage.setItem(
            "displayName",
            this.userData.first_name + " " + this.userData.last_name
          );
          localStorage.setItem(
            "freeDeliveryCount",
            this.userData.free_delivery_count
          );
          localStorage.setItem(
            "purchaseSubscription",
            this.userData.is_package
          );
          localStorage.setItem("userImage", this.userData.img_url);
          this.setTokenToService();

          setTimeout(() => {
            if (this.dataService.pageName === "home") {
              if (window.pageYOffset === 0 || window.pageYOffset < 69) {
                $(".username").css("color", "#000000");
              }
            }
          }, 1500);
          $("#signUp").modal("hide");
          $("#signIn").modal("hide");
          $("#codePop").modal("hide");
          this.fbRegistration();
          this.clearBlur();
        }
        clearInterval(this.intervalSet);
      },
      (error) => {}
    );
  }

  setTokenToService() {
    this.dataControlService.mytokenVal.next(localStorage.getItem("token"));
  }

  codeSendFunction() {
    this.dob = $("#datepicker").val();
    this.codeError = "";

    this.nao.go(30);
    this.code = this.code.toString();
    this.authService
      .register(
        this.numberToSend,
        this.code,
        false,
        this.firstName,
        this.lastName,
        this.dob,
        this.gender
      )
      .subscribe(
        (data) => {
          this.nao.go(100);
          this.userData = data;
          if (this.userData.rest_error_code === 4) {
            this.codeError = "Please enter a valid code";
            return;
          }
          if (this.userData.rest_error_code === 6) {
            this.codeError = this.userData.message;
            return;
          }
          this.code = null;
          localStorage.setItem("isauthenticated", "true");
          localStorage.setItem("token", this.userData.auth_token);
          this.userData = this.userData.profile;
          localStorage.setItem(
            "displayName",
            this.userData.first_name + " " + this.userData.last_name
          );
          localStorage.setItem(
            "freeDeliveryCount",
            this.userData.free_delivery_count
          );
          localStorage.setItem(
            "purchaseSubscription",
            this.userData.is_package
          );
          localStorage.setItem("userImage", this.userData.img_url);
          this.events.publish("inlogin", "true");

          $("#signUp").modal("hide");
          $("#signIn").modal("hide");
          $("#codePop").modal("hide");
          this.fbRegLogin();
          var filterBlur = "blur(0px)";

          $(".addCartShort").css("-webkit-filter", filterBlur);
          $(".addCartShort").css("-moz-filter", filterBlur);
          $(".addCartShort").css("-o-filter", filterBlur);
          $(".addCartShort").css("-ms-filter", filterBlur);
          $(".addCartShort").css("filter", filterBlur);
          this.setTokenToService();

          this.getPayementCard();

          this.openOutletAddressPop(false);

          let myProfileAddress = JSON.parse(
            localStorage.getItem("profileAddress")
          );
          this.setNullIdinCheckoutAddress();

          this.dataControlService.getAddressesOnHome.next(true);
          this.getAddressListingFunction(null);

          if (this.isWishlistClicked == false) {
            if (myProfileAddress != "null" && myProfileAddress != null) {
              if (myProfileAddress.id === "") {
                // this.addNewAddress();

                this.isPlaceOrderSignin = true; //we use to make it true becasue when we click customise order the location slider open so avoid that slider we make this variable true here
                this.isCheckoutSignup = false;
                this.setAddNotesAddressValuesFromCheckout(myProfileAddress);
                // return;
              }
            }
          }
          this.dataControlService.isSignInWishlistClicked.next(false);

          if (this.isMenuAddressClicked == false) {
            console.log(
              "%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% Code Send Function %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%"
            );
            this.getSlotDataForOutlet();
          }

          setTimeout(() => {
            if (this.dataService.pageName === "home") {
              if (window.pageYOffset === 0 || window.pageYOffset < 69) {
                $(".username").css("color", "#000000");
              }
            }
          }, 1500);
          this.callPushNotificationFunction();
          this.clearBlur();
          clearInterval(this.intervalSet);
        },
        (error) => {
          this.nao.go(100);
        }
      );
  }

  myProfileAddressObj: any;
  myAddressObj: any;
  isComingFromCheckOut: boolean = false;
  addressSaveToUserAfterLogin() {
    this.myProfileAddressObj = JSON.parse(
      localStorage.getItem("profileAddress")
    );

    this.dataService
      .addNewProfileAddreess(
        localStorage.getItem("token"),
        this.myProfileAddressObj.address_line1,
        this.myProfileAddressObj.city,
        this.myProfileAddressObj.state,
        this.myProfileAddressObj.country,
        this.myProfileAddressObj.zip,
        this.myProfileAddressObj.latitude,
        this.myProfileAddressObj.longitude,
        this.myProfileAddressObj.apart_no,
        this.myProfileAddressObj.place_label,
        this.myProfileAddressObj.house_building_no,
        this.myProfileAddressObj.street,
        this.myProfileAddressObj.notes
      )
      .subscribe(
        (data) => {
          this.addAddressSuccess = data;
          if (this.addAddressSuccess.success === true) {
            this.myAddressObj = {
              address_id: this.addAddressSuccess.address_id,
              isAddedAddress: false,
              areaLocation: this.myProfileAddressObj.address_line1,
              cityLocation: this.myProfileAddressObj.city,
              stateLocation: this.myProfileAddressObj.state,
              countryLocation: this.myProfileAddressObj.country,
              postalLocation: this.myProfileAddressObj.zip,
              latitudeDelivery: this.myProfileAddressObj.latitude,
              longitudeDelivery: this.myProfileAddressObj.longitude,
              aptLocation: this.myProfileAddressObj.apart_no,
              placeLabelLocation: this.myProfileAddressObj.place_label,
              houseNoLocation: this.myProfileAddressObj.house_building_no,
              streetLocation: this.myProfileAddressObj.street,
              orderNotesNew: this.myProfileAddressObj.notes,
            };

            this.isComingFromCheckOut = true;
            this.makePrimeryAddress(
              this.myAddressObj.address_id,
              this.myAddressObj
            );

            $("#customerAddresslisting").modal("show");
          } else {
            toastr.error(this.addAddressSuccess.message);
          }
        },
        (error) => {}
      );
  }

  pushNotificationFunction() {
    this.authService
      .pushNotify(localStorage.getItem("token"), this.authService.tokePush)
      .subscribe(
        (data) => {},
        (error) => {
          this.nao.go(100);
        }
      );
  }

  codeSendFunctionProfile() {
    this.codeError = "";
    this.nao.go(30);
    this.codeProfile = this.codeProfile.toString();
    this.dataService
      .validateCodeProfile(
        localStorage.getItem("token"),
        this.numberToSendProfile,
        this.codeProfile
      )
      .subscribe(
        (data) => {
          this.nao.go(100);
          this.userData = data;
          if (this.userData.success === false) {
            this.codeError = "Please enter a valid code";
            return;
          }
          this.codeProfile = null;
          this.numberToSendProfile = "";
          $("#codePopProfile").modal("hide");
          this.fbRegistration();
          this.clearBlur();
          this.profileFunction();
          clearInterval(this.intervalSet);
        },
        (error) => {
          this.nao.go(100);
        }
      );
  }

  emailCodeSendFunctionProfile() {
    this.codeError = "";
    this.codeProfileEmail = this.codeProfileEmail.toString();
    this.dataService
      .validateEmailProfile(
        localStorage.getItem("token"),
        this.ProfileEmail,
        this.codeProfileEmail
      )
      .subscribe(
        (data) => {
          this.emailUpdateFinalData = data;
          if (this.emailUpdateFinalData.success === false) {
            this.codeError = "Please enter a valid code";
            return;
          }
          this.codeProfileEmail = null;
          $("#ProfileEmailPopup").modal("hide");
          this.clearBlur();
          this.profileFunction();
          clearInterval(this.intervalSet);
        },
        (error) => {}
      );
  }

  profileFunction() {
    this.emailError = "";
    this.showFname = false;
    this.showLname = false;
    this.showEmail = false;
    this.showDob = false;
    this.showAddress = false;
    this.showGender = false;
    this.nao.go(30);
    this.dataService.getProfile(localStorage.getItem("token")).subscribe(
      (data) => {
        this.nao.go(100);
        this.profileData = data;
        $("#dobday").empty();
        $("#dobmonth").empty();
        $("#dobyear").empty();
        var dobPicker = {
          daySelector: "#dobday",
          monthSelector: "#dobmonth",
          yearSelector: "#dobyear",
          dayDefault: "Day",
          monthDefault: "Month",
          yearDefault: "Year",
          minimumAge: 21,
          maximumAge: 100,
        };
        this.datePicker(dobPicker);

        this.ProfileCountry_code = this.profileData.country_code;
        this.ProfileDate_of_birth = this.profileData.date_of_birth;
        this.ProfileselDate = this.profileData.date_of_birth;
        this.ProfileEmail = this.profileData.email;
        this.ProfileFirst_name = this.profileData.first_name;
        this.ProfileImg_url = this.profileData.img_url;
        this.ProfileAddress = this.profileData.address;
        this.ProfileLast_name = this.profileData.last_name;
        this.ProfilePhone_number =
          this.profileData.country_code + this.profileData.phone_number;
        this.count = this.profileData.free_delivery_count;
        this.gender = this.profileData.gender;
        localStorage.setItem(
          "displayName",
          this.profileData.first_name + " " + this.profileData.last_name
        );
        localStorage.setItem(
          "freeDeliveryCount",
          this.profileData.free_delivery_count
        );
        localStorage.setItem(
          "purchaseSubscription",
          this.profileData.is_package
        );
        localStorage.setItem("packageCount", this.profileData.package_count);
        localStorage.setItem("userImage", this.profileData.img_url);
        if (this.ProfileselDate !== "") {
          this.ProfileDateYear = this.ProfileselDate.split("/", 3);
          this.ProfileDateDay = this.ProfileDateYear[1];
          this.ProfileDateMonth = this.ProfileDateYear[0];
          this.ProfileDateYear = this.ProfileDateYear[2];
          $("#dobday").val(this.ProfileDateDay);
          $("#dobmonth").val(this.ProfileDateMonth);
          $("#dobyear").val(this.ProfileDateYear);
        }
      },
      (error) => {
        this.nao.go(100);
      }
    );
    $("#profilePopup").modal("show");
    this.disableBackground();
    this.closeNav();
  }

  getclientProfile() {
    if (
      localStorage.getItem("token") !== "" &&
      localStorage.getItem("token") !== undefined &&
      localStorage.getItem("token") !== null
    ) {
      this.dataService.getProfile(localStorage.getItem("token")).subscribe(
        (data) => {
          this.profileData = data;
          this.activeHealthProfile = this.profileData.default_health_profile;
          this.activeHealthProfileId = this.activeHealthProfile.id;
          localStorage.setItem(
            "purchaseSubscription",
            this.profileData.is_package
          );
          localStorage.setItem("packageCount", this.profileData.package_count);
          this.totalPurchasePackageCount = localStorage.getItem("packageCount");
        },
        (error) => {}
      );
    }
  }

  datePicker(params) {
    // set the defaults
    if (typeof params.dayDefault === "undefined") params.dayDefault = "Day";
    if (typeof params.monthDefault === "undefined")
      params.monthDefault = "Month";
    if (typeof params.yearDefault === "undefined") params.yearDefault = "Year";
    if (typeof params.minimumAge === "undefined") params.minimumAge = 12;
    if (typeof params.maximumAge === "undefined") params.maximumAge = 80;

    // set the default messages
    $(params.daySelector).append(
      '<option value="' +
        params.dayDefault +
        '">' +
        params.dayDefault +
        "</option>"
    );
    $(params.monthSelector).append(
      '<option value="' +
        params.monthDefault +
        '">' +
        params.monthDefault +
        "</option>"
    );
    $(params.yearSelector).append(
      '<option value="' +
        params.yearDefault +
        '">' +
        params.yearDefault +
        "</option>"
    );

    // populate the day select
    for (var i = 1; i <= 31; i++) {
      if (i <= 9) {
        var val = "0" + i;
        $(params.daySelector).append(
          '<option value="' + val + '">' + i + "</option>"
        );
      } else {
        var val2 = i;
        $(params.daySelector).append(
          '<option value="' + val2 + '">' + i + "</option>"
        );
      }
      // $(params.daySelector).append('<option value="' + val + '">' + i + '</option>');
    }

    // populate the month select
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    for (var i = 1; i <= 12; i++) {
      if (i <= 9) {
        var val = "0" + i;
        $(params.monthSelector).append(
          '<option value="' + val + '">' + months[i - 1] + "</option>"
        );
      } else {
        var val2 = i;
        $(params.monthSelector).append(
          '<option value="' + val2 + '">' + months[i - 1] + "</option>"
        );
      }
      // $(params.monthSelector).append('<option value="' + val + '">' + months[i - 1] + '</option>');
    }

    // populate the year select
    var date = new Date();
    var year = date.getFullYear();
    var start = year - params.minimumAge;
    var count = start - params.maximumAge;

    for (i = start; i >= count; i--) {
      $(params.yearSelector).append(
        '<option value="' + i + '">' + i + "</option>"
      );
    }

    // do the logic for the day select
    $(params.daySelector).change(function () {
      $(params.monthSelector)[0].selectedIndex = 0;
      $(params.yearSelector)[0].selectedIndex = 0;
      $(params.yearSelector + " option").removeAttr("disabled");

      if (
        $(params.daySelector).val() >= 1 &&
        $(params.daySelector).val() <= 29
      ) {
        $(params.monthSelector + " option").removeAttr("disabled");
      } else if ($(params.daySelector).val() == 30) {
        $(params.monthSelector + " option").removeAttr("disabled");
        $(params.monthSelector + ' option[value="02"]').attr(
          "disabled",
          "disabled"
        );
      } else if ($(params.daySelector).val() == 31) {
        $(params.monthSelector + " option").removeAttr("disabled");
        $(params.monthSelector + ' option[value="02"]').attr(
          "disabled",
          "disabled"
        );
        $(params.monthSelector + ' option[value="04"]').attr(
          "disabled",
          "disabled"
        );
        $(params.monthSelector + ' option[value="06"]').attr(
          "disabled",
          "disabled"
        );
        $(params.monthSelector + ' option[value="09"]').attr(
          "disabled",
          "disabled"
        );
        $(params.monthSelector + ' option[value="11"]').attr(
          "disabled",
          "disabled"
        );
      }
    });

    // do the logic for the month select
    $(params.monthSelector).change(function () {
      $(params.yearSelector)[0].selectedIndex = 0;
      $(params.yearSelector + " option").removeAttr("disabled");

      if (
        $(params.daySelector).val() == 29 &&
        $(params.monthSelector).val() == "02"
      ) {
        $(params.yearSelector + " option").each(function (index) {
          if (index !== 0) {
            var year = $(this).attr("value");
            var leap = !(year % 4 || (!(year % 100) && year % 400));
            if (leap === false) {
              $(this).attr("disabled", "disabled");
            }
          }
        });
      }
    });
  }

  OpeneditPhonePopup() {
    var context = this;
    this.profileNoChangeCountryCode = this.profileData.country_code;
    this.number = this.profileData.phone_number;
    setTimeout(() => {
      $("#profilePhone").intlTelInput({
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.9/js/utils.js",
        autoPlaceholder: false,
        preferredCountries: context.profileNoChangeCountryCode,
        preventInvalidNumbers: true,
        preventInvalidDialCodes: true,
        formatOnDisplay: true,
        separateDialCode: false,
      });
    }, 2000);
    var countryCode2;
    var countryCode;
    setTimeout(() => {
      $("#profilePhone").val(context.profileNoChangeCountryCode);
    }, 1000);
    $("#profilePhone").change(function () {
      countryCode = $("#profilePhone").intlTelInput(
        "getSelectedCountryData"
      ).dialCode;
      $("#profilePhone").val("+" + countryCode);
    });
    $("#profileNoUpdate").modal("show");
    this.addBlur();
  }

  Checkvalue() {
    // if (this.ProfileDate_of_birth.length === undefined) {
    //   this.ProfileDate_of_birth = this.ProfileDate_of_birth.formatted;
    // }
    if (this.ProfileEmail !== this.profileData.email) {
      this.showEmail = true;
    }
    if (this.ProfileEmail === this.profileData.email) {
      this.showEmail = false;
      this.emailError = "";
    }
    if (this.ProfileFirst_name !== this.profileData.first_name) {
      this.showFname = true;
    }
    if (this.ProfileFirst_name === this.profileData.first_name) {
      this.showFname = false;
    }
    if (this.ProfileLast_name !== this.profileData.last_name) {
      this.showLname = true;
    }
    if (this.ProfileLast_name === this.profileData.last_name) {
      this.showLname = false;
    }
    // if (this.ProfileDate_of_birth !== this.profileData.date_of_birth || this.ProfileDate_of_birth === undefined) {
    //   this.showDob = true;
    // }
    // if (this.ProfileDate_of_birth === this.profileData.date_of_birth) {
    //   this.showDob = false;
    // }
    else {
      this.showAddress = false;
    }
  }

  CancleProfileFun() {
    $("#cropit-image-input-tile").val("");
    $("#cropperTileImgPop").modal("hide");
  }

  UpdateProfileFun() {
    this.profileImageLoading = true;
    this.ProfileImg_url = this.profileImgFinal;
    $("#cropperTileImgPop").modal("hide");
    this.profileImgFinal = this.profileImgFinal.split(",")[1];
    this.dataService
      .updateProfileImage(localStorage.getItem("token"), this.profileImgFinal)
      .subscribe(
        (data) => {
          this.updateProfileData = data;
          localStorage.setItem("userImage", this.updateProfileData.img_url);
          // this.profileFunction();
          this.profileImageLoading = false;
          $("#cropit-image-input-tile").val("");
          // $('#cropperTileImgPop').modal('hide');
        },
        (error) => {}
      );
  }

  openSelectProfileImage() {
    $("#cropit-image-input-tile").click();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.profileImgFinal = event.base64;
  }

  imageLoaded() {
    // show cropper
    // $('#image-croper-top').css('display', 'none');
  }

  cropperReady() {
    // cropper ready
    $("#image-croper-top").css("display", "none");
  }

  loadImageFailed() {
    // show message
  }

  fileChangeEvent(event: any): void {
    $("#image-croper-top").css("display", "block");
    $("#cropperTileImgPop").modal("show");
    setTimeout(() => {
      this.imageChangedEvent = event;
      $("#image-croper-top").css("display", "none");
      //this.changeRef.detectChanges();
    }, 500);
  }

  EditProfileFnmae() {
    this.dataService
      .updateProfileFname(localStorage.getItem("token"), this.ProfileFirst_name)
      .subscribe(
        (data) => {
          // this.nao.go(100);
          this.showFname = false;
          this.profileFunction();
        },
        (error) => {
          // this.nao.go(100);
        }
      );
  }

  EditProfileLnmae() {
    this.dataService
      .updateProfileLname(localStorage.getItem("token"), this.ProfileLast_name)
      .subscribe(
        (data) => {
          // this.nao.go(100);
          this.showLname = false;
          this.profileFunction();
        },
        (error) => {
          // this.nao.go(100);
        }
      );
  }

  EditProfileGender() {
    this.dataService
      .updateProfileGender(localStorage.getItem("token"), this.gender)
      .subscribe(
        (data) => {
          // this.nao.go(100);
          this.showGender = false;
          this.profileFunction();
        },
        (error) => {
          // this.nao.go(100);
        }
      );
  }

  checkDobUpdatedat(value) {
    this.showDob = false;
  }

  checkDobUpate(value) {
    var day = $("#dobday").val();
    var month = $("#dobmonth").val();
    var year = $("#dobyear").val();
    if (
      day !== "Day" &&
      day !== undefined &&
      day !== null &&
      month !== "Month" &&
      month !== undefined &&
      month !== null &&
      year !== "Year" &&
      year !== undefined &&
      year !== null
    ) {
      this.showDob = true;
    } else {
      this.showDob = false;
    }
  }

  EditProfileDob() {
    var day = $("#dobday").val();
    var month = $("#dobmonth").val();
    var year = $("#dobyear").val();
    if (
      day === "Day" ||
      day === undefined ||
      day === null ||
      month === "Month" ||
      month === undefined ||
      month === null ||
      year === "Year" ||
      year === undefined ||
      year === null
    ) {
      return;
    }
    var date = month + "/" + day + "/" + year;
    this.dataService
      .updateProfileDob(localStorage.getItem("token"), date)
      .subscribe(
        (data) => {
          this.showDob = false;
          this.profileFunction();
        },
        (error) => {}
      );
  }

  validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  EditProfileEmail() {
    this.emailError = "";
    var mail = this.validateEmail(this.ProfileEmail);
    if (mail === false) {
      this.emailError = "Please enter valid email address";
    } else {
      this.emailError = "";
      this.sendEmailCode();
      $("#ProfileEmailPopup").modal("show");
      this.addBlur();
    }
  }

  sendEmailCode() {
    this.codeError = "";
    clearInterval(this.intervalSet);
    this.reSendCount = 20;
    this.resendButtonShow = false;
    this.dataService
      .changeProfileEmail(localStorage.getItem("token"), this.ProfileEmail)
      .subscribe(
        (data) => {
          this.emailSendData = data;
          if (this.emailSendData.success === true) {
            this.showEmail = false;
            this.intervalSet = setInterval(() => {
              this.reSendCount = this.reSendCount - 1;
              if (this.reSendCount === 0) {
                // this.signInFunction();
                // this.reSendCount = 20;
                clearInterval(this.intervalSet);
                this.resendButtonShow = true;
              }
            }, 1000);
          }
          // this.profileFunction();
        },
        (error) => {
          // this.nao.go(100);
        }
      );
  }

  logoutFunction() {
    this.closeNav();
    this.nao.go(30);
    this.authService.logout(localStorage.getItem("token")).subscribe(
      (data) => {
        this.nao.go(100);
        this.userLogoutSuccess = data;
        this.userLogoutSuccess = this.userLogoutSuccess.success;
        if (this.userLogoutSuccess === true) {
          localStorage.setItem("isauthenticated", "false");
          localStorage.setItem("token", "");
          localStorage.setItem("displayName", "");
          localStorage.setItem("freeDeliveryCount", null);
          localStorage.setItem("purchaseSubscription", "false");
          localStorage.setItem("packageCount", "0");
          localStorage.setItem("userImage", "");
          this.emptyCartCancelAddress();
          this.events.publish("inlogin", "false");
          this.setTokenToService();

          this.orderNotes = "";
          this.orderNotesNew = "";
          this.primaryCardNo = "";
          this.cashImg = true;
          this.paymentCardData = [];

          setTimeout(() => {
            var width = $(window).width();
            if (width >= 769) {
              if (this.dataService.pageName === "home") {
                if (window.pageYOffset === 0 || window.pageYOffset < 69) {
                  // $('.logInbtn').css('color', '#ffffff');
                } else {
                  // $('.logInbtn').css('color', this.colorCodeVar);
                }
              }
            } else {
              // $('.logInbtn').css('color', this.colorCodeVar);
              $(".logInbtn").css("color", "#FFFFFF");
            }
            if (this.dataService.pageName === "businesses") {
              // $('.logInbtn').css('color', this.colorCodeVar);
              $(".logInbtn").css("color", "#FFFFFF");
            }
          }, 1500);
        }
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }

  initMapFromSearchLocation() {
    var context = this;
    var map;
    var marker;
    var adr;
    var myLatlng = new google.maps.LatLng(
      this.latitudeDelivery,
      this.longitudeDelivery
    );
    var geocoder = new google.maps.Geocoder();
    var infowindow = new google.maps.InfoWindow();
    var mapOptions = {
      zoom: 14,
      center: myLatlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      navigationControl: false,
    };
    var endImage = {
      url: "assets/pin.png", // url
      scaledSize: new google.maps.Size(30, 42), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(16, 41), // anchor
    };
    if (this.isBusinessLandingPage == true) {
      this.url = "assets/pinBlack.png";
    }

    map = new google.maps.Map(document.getElementById("myMaps"), mapOptions);

    marker = new google.maps.Marker({
      map: map,
      position: myLatlng,
      draggable: true,
      icon: endImage,
    });

    geocoder.geocode({ latLng: myLatlng }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          infowindow.setContent(results[0].formatted_address);
          infowindow.open(map, marker);
          context.latitudeDelivery = marker.getPosition().lat();
          context.longitudeDelivery = marker.getPosition().lng();

          context.currentLocationCount = context.currentLocationCount + 1;
          if (context.currentLocationCount <= 2) {
            context.getDeliveryAddressDetailFromSearchLocation(results[0]);
          }
        }
      }
    });

    google.maps.event.addListener(marker, "dragend", function () {
      geocoder.geocode(
        { latLng: marker.getPosition() },
        function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              context.myPinCity = results[0];
              infowindow.setContent(results[0].formatted_address);
              infowindow.open(map, marker);
              context.latitudeDelivery = marker.getPosition().lat();
              context.longitudeDelivery = marker.getPosition().lng();
              // context.addressNew =  results[0].formatted_address;
            }
          }
        }
      );
    });
  }

  initMapFromCurrentLocation() {
    var context = this;
    var map;
    var marker;
    var adr;
    var myLatlng = new google.maps.LatLng(
      this.latitudeDelivery,
      this.longitudeDelivery
    );
    var geocoder = new google.maps.Geocoder();
    var infowindow = new google.maps.InfoWindow();
    var mapOptions = {
      zoom: 14,
      center: myLatlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      navigationControl: false,
      // mapTypeControl: false,
      // disableDefaultUI: true
    };
    var endImage = {
      url: "assets/pin.png", // url
      scaledSize: new google.maps.Size(30, 42), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(16, 41), // anchor
    };
    if (this.isBusinessLandingPage == true) {
      this.url = "assets/pinBlack.png";
    }
    map = new google.maps.Map(document.getElementById("myMaps"), mapOptions);

    marker = new google.maps.Marker({
      map: map,
      position: myLatlng,
      draggable: true,
      icon: endImage,
    });

    geocoder.geocode({ latLng: myLatlng }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          infowindow.setContent(results[0].formatted_address);
          infowindow.open(map, marker);
          context.latitudeDelivery = marker.getPosition().lat();
          context.longitudeDelivery = marker.getPosition().lng();
          context.getDeliveryAddressDetailFromCurrentLocation(results[0]);
        }
      }
    });

    google.maps.event.addListener(marker, "dragend", function () {
      geocoder.geocode(
        { latLng: marker.getPosition() },
        function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              context.myPinCity = results[0];
              infowindow.setContent(results[0].formatted_address);
              infowindow.open(map, marker);
              context.latitudeDelivery = marker.getPosition().lat();
              context.longitudeDelivery = marker.getPosition().lng();
              // context.addressNew =  results[0].formatted_address;
            }
          }
        }
      );
    });
  }

  async getDeliveryAddressDetailFromSearchLocation(result) {
    this.apartDelivery = "";
    this.countryDelivery = "";
    this.cityDelivery = "";
    this.stateDelivery = "";
    this.zipDelivery = "";
    this.houseNoDelivery = "";
    this.streetNoDelivery = "";

    this.zip = "";
    for (var i = 0; i < result.address_components.length; i++) {
      for (var j = 0; j < result.address_components[i].types.length; j++) {
        if (
          result.address_components[i].types[j] ===
          "administrative_area_level_2"
        ) {
          this.cityDelivery = result.address_components[i].long_name;
          // console.log(this.cityDelivery);
        } else if (result.address_components[i].types[j] === "locality") {
          this.cityDelivery = result.address_components[i].long_name;
          // console.log(this.cityDelivery);
        }
        if (
          result.address_components[i].types[j] ===
          "administrative_area_level_1"
        ) {
          this.stateDelivery = result.address_components[i].short_name;
        }
        if (result.address_components[i].types[j] === "country") {
          this.countryDelivery = result.address_components[i].long_name;
        }
        if (result.address_components[i].types[j] === "postal_code") {
          this.zipDelivery = result.address_components[i].long_name;
        }
      }
    }
    this.deliveryAddressSearchLoader = false;
    this.initMapFromSearchLocation();
  }

  async getDeliveryAddressDetailFromCurrentLocation(result) {
    this.streetDelivery = "";
    this.ProfileApart = "";
    this.cityDelivery = "";
    this.stateDelivery = "";
    this.zipDelivery = "";
    this.zip = "";
    this.apartDelivery = "";
    this.houseNoDelivery = "";
    this.streetNoDelivery = "";
    for (var i = 0; i < result.address_components.length; i++) {
      for (var j = 0; j < result.address_components[i].types.length; j++) {
        if (
          result.address_components[i].types[j] ===
          "administrative_area_level_2"
        ) {
          this.cityDelivery = result.address_components[i].long_name;
        }
        if (result.address_components[i].types[j] === "street_number") {
          this.streetDelivery = result.address_components[i].long_name;
        }
        if (result.address_components[i].types[j] === "sublocality_level_2") {
          this.streetDelivery = "";
          this.streetDelivery = result.address_components[i].long_name;
        }
        if (result.address_components[i].types[j] === "sublocality_level_1") {
          this.streetDelivery =
            this.streetDelivery + " " + result.address_components[i].long_name;
        }
        if (
          result.address_components[i].types[j] ===
          "administrative_area_level_1"
        ) {
          this.stateDelivery = result.address_components[i].short_name;
        }
        if (result.address_components[i].types[j] === "country") {
          this.countryDelivery = result.address_components[i].long_name;
        }

        if (result.address_components[i].types[j] === "postal_code") {
          this.zipDelivery = result.address_components[i].long_name;
        }
      }
    }
    this.deliveryAddressSearchLoader = false;
    this.currentLocationCount = this.currentLocationCount + 1;
    if (this.currentLocationCount <= 2) {
      this.initMapFromCurrentLocation();
    }
  }

  functionTogetPlaceDelivery() {
    if (this.checkoutAddress === true) {
      return;
    }
    this.deliveryAddressSearchLoader = true;
    this.placeDataFound = false;
    this.placeSuggestionsDataDeliveryModal = [];
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      this.addressLoadDelivery = true;
      this.dataService.googleAddress(this.streetDelivery).subscribe(
        (data) => {
          this.placeSuggestionsDataDeliveryModal = data;
          this.placeSuggestionsDataDeliveryModal =
            this.placeSuggestionsDataDeliveryModal.predictions;
          if (this.placeSuggestionsDataDeliveryModal.length > 0) {
            this.placeDataFound = true;
          }
          this.deliveryAddressSearchLoader = false;
          if ($(".myDrop").hasClass("open")) {
          } else {
            $(".myDrop").addClass("open");
          }
          this.addressLoad = false;
        },
        (error) => {}
      );
    }, 1000);
  }

  functionTogetPlaceDetailDelivery(name, id) {
    this.deliveryAddressSearchLoader = true;
    this.addressLoadDelivery = true;
    this.streetDelivery = name;
    this.currentLocationCount = 0;
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      this.dataService.googleAddressDetail(id).subscribe(
        (data) => {
          this.placeDetailDataDelivery = data;
          this.checkoutAddress = true;
          $(".myMapDiv").css("display", "block");
          this.placeDetailDataDelivery = this.placeDetailDataDelivery.result;
          this.latitudeDelivery =
            this.placeDetailDataDelivery.geometry.location.lat;
          this.longitudeDelivery =
            this.placeDetailDataDelivery.geometry.location.lng;
          setTimeout(() => {
            this.locationFormModal.controls.areaLocationModal.setValue("");
            this.getDeliveryAddressDetailFromSearchLocation(
              this.placeDetailDataDelivery
            );
            this.addressLoadDelivery = false;
          }, 1000);
        },
        (error) => {}
      );
    }, 1000);
  }

  currentLocationCount: any = 0;
  getcurrentLocation() {
    this.myPinCity = "";

    this.streetDelivery = "";
    this.cityDelivery = "";
    this.countryDelivery = "";
    this.stateDelivery = "";
    this.zipDelivery = "";
    this.apartDelivery = "";
    this.orderNotesNew = "";
    this.houseNoDelivery = "";
    this.streetNoDelivery = "";
    this.deliveryAddressSearchLoader = true;
    this.checkoutAddress = true;
    this.currentLocationCount = 0;

    $(".myMapDiv").css("display", "block");
    const contex = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
    }

    function showError(error) {
      const contex1 = this;
      toastr.error("Allow location service from your browser");
      contex.deliveryAddressSearchLoader = false;
      contex.checkoutAddress = false;
      $(".myMapDiv").css("display", "none");
    }

    function showPosition(position) {
      contex.deliveryAddressSearchLoader = true;
      contex.latitudeDelivery = position.coords.latitude;
      contex.longitudeDelivery = position.coords.longitude;
      contex.initMapFromCurrentLocation();
    }
  }

  clearCheckoutAddress() {
    $(".myMapDiv").css("display", "none");
    this.streetDelivery = "";
    this.cityDelivery = "";
    this.stateDelivery = "";
    this.zipDelivery = "";
    this.apartDelivery = "";
    this.houseNoDelivery = "";
    this.streetNoDelivery = "";
    this.orderNotesNew = "";
    this.checkoutAddress = false;
  }

  submitDeliveryAddress() {
    this.error_count = 0;
    $(".c-label label").css("color", "#3d3e3d");
    $(".uploadText").css("color", "#3d3e3d");
    $(".c-label input").css("border-color", "#ccc");
    $(".c-label textarea").css("border-color", "#ccc");
    if (this.streetDelivery === "" || this.streetDelivery === undefined) {
      $("#c-deliveryStreet label").css("color", "#a10a0d");
      $("#c-deliveryStreet input").css("border-color", "#a10a0d");
      this.error_count++;
    }
    if (this.cityDelivery === "" || this.cityDelivery === undefined) {
      $("#c-city label").css("color", "#a10a0d");
      $("#c-city input").css("border-color", "#a10a0d");
      this.error_count++;
    }
    // if (this.stateDelivery === '' || this.stateDelivery === undefined) {
    //   $('#c-state label').css('color', '#a10a0d');
    //   $('#c-state input').css('border-color', '#a10a0d');
    //   this.error_count++;
    // }
    if (this.error_count > 0) {
      return;
    }
    this.orderNotes = this.orderNotesNew;

    this.deliveryAddressSubmit = true;
    this.checkoutErrorMessage = "";
    if (
      this.labelDelivery === "" ||
      this.labelDelivery === undefined ||
      this.labelDelivery === null
    ) {
      toastr.error("Kindly Select Place Label!");
      return;
    }
    if (this.cityDelivery === "" || this.cityDelivery === null) {
      toastr.error("Kindly Enter City!");
      return;
    }
    if (this.editAddress === false) {
      this.addProfileAddress();
    }
    if (this.editAddress === true) {
      this.editProfileAddress();
    }
  }

  setAddressIdInLocalProfileAddress(newAddressId) {
    let newAddressGenerate = "";
    // newAddressGenerate = newAddressGenerate + this.streetDelivery + ', ';

    if (this.zipDelivery == null || this.zipDelivery == "") {
      if (this.houseNoDelivery !== "" && this.houseNoDelivery !== null) {
        newAddressGenerate = newAddressGenerate + this.houseNoDelivery + ", ";
      }
      if (this.apartDelivery !== "" && this.apartDelivery !== null) {
        newAddressGenerate = newAddressGenerate + this.apartDelivery + ", ";
      }
      newAddressGenerate = newAddressGenerate + this.streetDelivery + ", ";
      newAddressGenerate = newAddressGenerate + this.cityDelivery;
      // + ", " + this.stateDelivery + ', ' + this.countryDelivery;
    } else {
      if (this.houseNoDelivery !== "" && this.houseNoDelivery !== null) {
        newAddressGenerate = newAddressGenerate + this.houseNoDelivery + ", ";
      }
      if (this.apartDelivery !== "" && this.apartDelivery !== null) {
        newAddressGenerate = newAddressGenerate + this.apartDelivery + ", ";
      }
      newAddressGenerate = newAddressGenerate + this.streetDelivery + ", ";
      newAddressGenerate = newAddressGenerate + this.cityDelivery;
      // + ", " + this.stateDelivery + " " + this.zipDelivery + ', ' + this.countryDelivery;
    }

    let myProfileAddress = JSON.parse(localStorage.getItem("profileAddress"));

    let obj = {
      address_line1: myProfileAddress.address_line1,
      apart_no: myProfileAddress.apart_no,
      city: myProfileAddress.city,
      country: myProfileAddress.country,
      house_building_no: myProfileAddress.house_building_no,
      id: newAddressId,
      latitude: myProfileAddress.latitude,
      longitude: myProfileAddress.longitude,
      notes: this.orderNotesNew,
      place_label: myProfileAddress.place_label,
      state: myProfileAddress.state,
      street: myProfileAddress.street,
      zip: myProfileAddress.zip,
    };
    localStorage.setItem("profileAddress", JSON.stringify(obj));
    localStorage.setItem("profileAddressCheckout", JSON.stringify(obj));
    localStorage.setItem("delivery_address", newAddressGenerate);
    localStorage.setItem("myDeliveryAddress", newAddressGenerate);
    let myProfileAddress1 = JSON.parse(localStorage.getItem("profileAddress"));

    this.makePrimeryAddress(newAddressId, null);

    if (
      myProfileAddress1 === null ||
      myProfileAddress1 === "null" ||
      myProfileAddress1 === undefined
    ) {
      this.deliveryAddressObj = {
        id: "",
        street: "",
        address_line1: "",
        zip: "",
        city: "",
        country: "",
        state: "",
        latitude: "",
        longitude: "",
        apart_no: "",
        notes: "",
        house_building_no: "",
      };
    }
    if (
      myProfileAddress1 !== null &&
      myProfileAddress1 !== undefined &&
      myProfileAddress1 !== "null"
    ) {
      if (myProfileAddress1.country !== "") {
        this.deliveryAddressObj = {
          id: myProfileAddress1.id,
          street: myProfileAddress1.address_line1,
          address_line1: myProfileAddress1.address_line1,
          zip: myProfileAddress1.zip,
          city: myProfileAddress1.city,
          country: myProfileAddress1.country,
          state: myProfileAddress1.state,
          latitude: myProfileAddress1.latitude,
          longitude: myProfileAddress1.longitude,
          apart_no: myProfileAddress1.apart_no,
          notes: myProfileAddress1.notes,
          house_building_no: myProfileAddress1.house_building_no,
        };
      }
    }
  }

  setAddressIdInLocalProfileAddressCheckout(newAddressId) {
    let myProfileAddress = JSON.parse(
      localStorage.getItem("profileAddressCheckout")
    );

    let obj = {
      address_line1: myProfileAddress.address_line1,
      apart_no: myProfileAddress.apart_no,
      city: myProfileAddress.city,
      country: myProfileAddress.country,
      house_building_no: myProfileAddress.house_building_no,
      id: newAddressId,
      latitude: myProfileAddress.latitude,
      longitude: myProfileAddress.longitude,
      notes: this.orderNotesNew,
      place_label: myProfileAddress.place_label,
      state: myProfileAddress.state,
      street: myProfileAddress.street,
      zip: myProfileAddress.zip,
    };
    localStorage.setItem("profileAddressCheckout", JSON.stringify(obj));

    let myProfileAddress1 = JSON.parse(
      localStorage.getItem("profileAddressCheckout")
    );

    this.makePrimeryAddress(newAddressId, null);

    if (
      myProfileAddress1 === null ||
      myProfileAddress1 === "null" ||
      myProfileAddress1 === undefined
    ) {
      this.deliveryAddressObj = {
        id: "",
        street: "",
        address_line1: "",
        zip: "",
        city: "",
        country: "",
        state: "",
        latitude: "",
        longitude: "",
        apart_no: "",
        notes: "",
        house_building_no: "",
      };
    }
    if (
      myProfileAddress1 !== null &&
      myProfileAddress1 !== undefined &&
      myProfileAddress1 !== "null"
    ) {
      if (myProfileAddress1.country !== "") {
        this.deliveryAddressObj = {
          id: myProfileAddress1.id,
          street: myProfileAddress1.address_line1,
          address_line1: myProfileAddress1.address_line1,
          zip: myProfileAddress1.zip,
          city: myProfileAddress1.city,
          country: myProfileAddress1.country,
          state: myProfileAddress1.state,
          latitude: myProfileAddress1.latitude,
          longitude: myProfileAddress1.longitude,
          apart_no: myProfileAddress1.apart_no,
          notes: myProfileAddress1.notes,
          house_building_no: myProfileAddress1.house_building_no,
        };
      }
    }
  }
  disableSubmitBtn: boolean = false;
  myProfileAddressCheckout: any;

  addProfileAddress() {
    this.disableSubmitBtn = true;
    this.dataService
      .addNewProfileAddreess(
        localStorage.getItem("token"),
        this.streetDelivery,
        this.cityDelivery,
        this.stateDelivery,
        this.countryDelivery,
        this.zipDelivery,
        this.latitudeDelivery,
        this.longitudeDelivery,
        this.apartDelivery,
        this.labelDelivery,
        this.houseNoDelivery,
        this.streetNoDelivery,
        this.orderNotesNew
      )
      .subscribe(
        (data) => {
          this.disableSubmitBtn = false;
          this.addAddressSuccess = data;

          if (this.addAddressSuccess.success === true) {
            this.getAddressListing();
            this.dataControlService.getAddressesOnHome.next(true);

            this.myProfileAddress = JSON.parse(
              localStorage.getItem("profileAddress")
            );
            this.myProfileAddressCheckout = JSON.parse(
              localStorage.getItem("profileAddressCheckout")
            );

            if (this.isComesFromCheckout) {
              if (
                this.myProfileAddress !== null &&
                this.myProfileAddress !== "null"
              ) {
                if (this.myProfileAddress.id == "") {
                  this.setAddressIdInLocalProfileAddress(
                    this.addAddressSuccess.address_id
                  );
                }
              }
              if (
                this.myProfileAddressCheckout !== null &&
                this.myProfileAddressCheckout !== "null"
              ) {
                if (this.myProfileAddressCheckout.id == "") {
                  this.setAddressIdInLocalProfileAddressCheckout(
                    this.addAddressSuccess.address_id
                  );
                }
              }
            }
            $("#deliveryAddressPop").modal("hide");
          } else {
            toastr.error(this.addAddressSuccess.message);
          }
        },
        (error) => {
          this.disableSubmitBtn = false;
        }
      );
  }

  isComesFromCheckout: boolean = false;
  hideResetAddress: boolean = false;
  isEditAdressMatch: boolean = true;

  editProfileAddress() {
    this.profileAddressObj = JSON.parse(localStorage.getItem("profileAddress"));
    this.myProfileAddressCheckout = JSON.parse(
      localStorage.getItem("profileAddressCheckout")
    );
    this.disableSubmitBtn = true;

    this.dataService
      .editProfileAddreess(
        localStorage.getItem("token"),
        this.streetDelivery,
        this.cityDelivery,
        this.stateDelivery,
        this.countryDelivery,
        this.zipDelivery,
        this.latitudeDelivery,
        this.longitudeDelivery,
        this.apartDelivery,
        this.labelDelivery,
        this.editAddressId,
        this.houseNoDelivery,
        this.streetNoDelivery,
        this.orderNotesNew
      )
      .subscribe(
        (data) => {
          this.hideResetAddress = false;
          this.disableSubmitBtn = false;
          this.addAddressSuccess = data;
          let newAddressGenerate = "";
          if (
            this.profileAddressObj !== null &&
            this.profileAddressObj !== "null"
          ) {
            if (this.editAddressId == this.profileAddressObj.id) {
              if (this.zipDelivery == null || this.zipDelivery == "") {
                if (
                  this.houseNoDelivery !== "" &&
                  this.houseNoDelivery !== null
                ) {
                  newAddressGenerate =
                    newAddressGenerate + this.houseNoDelivery + ", ";
                }
                if (this.apartDelivery !== "" && this.apartDelivery !== null) {
                  newAddressGenerate =
                    newAddressGenerate + this.apartDelivery + ", ";
                }
                newAddressGenerate =
                  newAddressGenerate + this.streetDelivery + ", ";
                newAddressGenerate = newAddressGenerate + this.cityDelivery;
                // + ", " + this.stateDelivery + ', ' + this.countryDelivery;
              } else {
                if (
                  this.houseNoDelivery !== "" &&
                  this.houseNoDelivery !== null
                ) {
                  newAddressGenerate =
                    newAddressGenerate + this.houseNoDelivery + ", ";
                }
                if (this.apartDelivery !== "" && this.apartDelivery !== null) {
                  newAddressGenerate =
                    newAddressGenerate + this.apartDelivery + ", ";
                }
                newAddressGenerate =
                  newAddressGenerate + this.streetDelivery + ", ";
                newAddressGenerate = newAddressGenerate + this.cityDelivery;
                // + ", " + this.stateDelivery + " " + this.zipDelivery + ', ' + this.countryDelivery;
              }

              if (
                newAddressGenerate == localStorage.getItem("delivery_address")
              ) {
                this.isEditAdressMatch = true;
                if (this.isMenuAddressClicked == false) {
                  let profileAddress = JSON.parse(
                    localStorage.getItem("profileAddress")
                  );
                  let profileAddress1 = {
                    address_line1: profileAddress.address_line1,
                    apart_no: profileAddress.apart_no,
                    city: profileAddress.city,
                    country: profileAddress.country,
                    house_building_no: profileAddress.house_building_no,
                    id: profileAddress.id,
                    is_default: profileAddress.is_default,
                    latitude: profileAddress.latitude,
                    longitude: profileAddress.longitude,
                    notes: this.orderNotesNew,
                    place_label: profileAddress.place_label,
                    route: profileAddress.route,
                    state: profileAddress.state,
                    zip: profileAddress.zip,
                  };
                  localStorage.setItem(
                    "profileAddress",
                    JSON.stringify(profileAddress1)
                  );
                }
              } else {
                this.isEditAdressMatch = false;

                let obj = {
                  address_line1: this.streetDelivery,
                  apart_no: this.apartDelivery,
                  city: this.cityDelivery,
                  country: this.countryDelivery,
                  house_building_no: this.houseNoDelivery,
                  id: this.editAddressId,
                  latitude: this.latitudeDelivery,
                  longitude: this.longitudeDelivery,
                  notes: this.orderNotesNew,
                  place_label: this.labelDelivery,
                  state: this.stateDelivery,
                  street: this.streetDelivery,
                  zip: this.zipDelivery,
                };
                localStorage.setItem("profileAddress", JSON.stringify(obj));
                localStorage.setItem(
                  "profileAddressCheckout",
                  JSON.stringify(obj)
                );
                localStorage.setItem("delivery_address", newAddressGenerate);
                localStorage.setItem("myDeliveryAddress", newAddressGenerate);
              }
            }
          } else if (
            this.isMenuAddressClicked == false &&
            this.editAddressId == this.myProfileAddressCheckout.id
          ) {
            let obj = {
              address_line1: this.streetDelivery,
              apart_no: this.apartDelivery,
              city: this.cityDelivery,
              country: this.countryDelivery,
              house_building_no: this.houseNoDelivery,
              id: this.editAddressId,
              latitude: this.latitudeDelivery,
              longitude: this.longitudeDelivery,
              notes: this.orderNotesNew,
              place_label: this.labelDelivery,
              state: this.stateDelivery,
              street: this.streetDelivery,
              zip: this.zipDelivery,
            };
            localStorage.setItem("profileAddressCheckout", JSON.stringify(obj));
          }

          if (this.addAddressSuccess.success === true) {
            this.getAddressListingFunction(null);
            $("#deliveryAddressPop").modal("hide");
          } else {
            toastr.error(this.addAddressSuccess.message);
          }
        },
        (error) => {
          this.disableSubmitBtn = false;
        }
      );
  }

  myNewSelectedAddressId: any;
  emptyCart() {
    $("#myAddressLabelApp").css("color", "unset");

    // change city so empty cart here
    this.disableMultiClick = true;
    this.cartDataFromStorage = [];
    this.events.publish("cartUpdate", this.cartDataFromStorage);
    localStorage.removeItem("cartItems");
    $("#onChangingCityBeforeCheckout").modal("hide");
    this.disableMultiClick = false;
    this.carBtnShowHide = false;
    this.setCheckoutAddressMainAddress();
    this.isNewAddressSelected = true;
    this.makePrimeryAddress(this.myNewSelectedAddressId, null);
  }

  setCheckoutAddressMainAddress() {
    // localStorage.setItem('profileAddress', JSON.stringify(this.customerProfileListApp.address_dictionary[i]));
    localStorage.setItem(
      "profileAddressCheckout",
      localStorage.getItem("profileAddress")
    );
  }

  emptyCartFromHome() {
    this.disableMultiClick = true;
    this.cartDataFromStorage = [];
    this.events.publish("cartUpdate", this.cartDataFromStorage);
    localStorage.removeItem("cartItems");
    $("#onChangingCityBeforeCheckout").modal("hide");
    this.disableMultiClick = false;
    this.carBtnShowHide = false;
    this.setCheckoutAddressMainAddress();
  }

  myProfileAddress: any;
  isNewAddressSelected: any;

  makePrimeryAddress(id, myAddressData) {
    if (id == null) {
      return;
    }
    this.myNewSelectedAddressId = id;
    if (this.customerProfileListApp.rest_error_code !== 2) {
      for (
        let i = 0;
        i < this.customerProfileListApp.address_dictionary.length;
        i++
      ) {
        if (this.customerProfileListApp.address_dictionary[i].id === id) {
          let j = 0;
          for (j = 0; j < this.areaData.length; j++) {
            if (
              this.areaData[j].city.toLowerCase() ==
              this.customerProfileListApp.address_dictionary[
                i
              ].city.toLowerCase()
            ) {
              break;
            }
          }
          if (j == this.areaData.length) {
            this.selectedCity =
              this.customerProfileListApp.address_dictionary[i].city;
            $("#selectedAddressPopUpModal").modal("hide");
            $("#onChangingCityBeforeCheckout").modal("hide");
            // $('#deliveryAddressPop').modal('hide');
            $("#wrongCityPopUpModal").modal("show");
            this.blurAddressModal();

            return;
          }
          break;
        }
      }
    }
    if (localStorage.getItem("cartItems") !== null) {
      if (
        this.isMenuAddressClicked === true &&
        this.isNewAddressSelected === false
      ) {
        // check city changing
        for (
          let i = 0;
          i < this.customerProfileListApp.address_dictionary.length;
          i++
        ) {
          if (this.customerProfileListApp.address_dictionary[i].id === id) {
            let mySelectedCity =
              this.customerProfileListApp.address_dictionary[i].city;
            this.myProfileAddress = JSON.parse(
              localStorage.getItem("profileAddress")
            );

            if (
              this.myProfileAddress != "null" &&
              this.myProfileAddress != null
            ) {
              if (this.myProfileAddress.city === mySelectedCity) {
                break;
              } else {
                // open modal here
                $("#onChangingCityBeforeCheckout").modal("show");
                this.blurAddressModal();
                return;
              }
            } else {
              break;
            }
          }
        }
      }
    }
    if (this.isMenuAddressClicked === true) {
      this.dataControlService.setAddressOnHomePage.next(id);
    }

    this.dataService
      .makeProfileAddreessPrimery(localStorage.getItem("token"), id)
      .subscribe(
        (data) => {
          this.checkoutPopupOpen = false;
          if (myAddressData !== null) {
            if (myAddressData.isAddedAddress == false) {
              this.deliveryAddressObj = {
                id: myAddressData.address_id,
                street: myAddressData.areaLocation,
                address_line1: myAddressData.areaLocation,
                zip: myAddressData.postalLocation,
                city: myAddressData.cityLocation,
                country: myAddressData.countryLocation,
                state: myAddressData.stateLocation,
                latitude: myAddressData.latitudeDelivery,
                longitude: myAddressData.longitudeDelivery,
                apart_no: myAddressData.aptLocation,
                notes: myAddressData.orderNotesNew,
                house_building_no: myAddressData.houseNoLocation,
              };
              localStorage.setItem(
                "profileAddress",
                JSON.stringify(this.deliveryAddressObj)
              );
              if (this.isMenuAddressClicked === true) {
                this.setDeliveryAddress();
              }
            }
          }
          if (
            this.customerProfileListApp.total_count > 0 &&
            myAddressData == null
          ) {
            for (
              var i = 0;
              i < this.customerProfileListApp.address_dictionary.length;
              i++
            ) {
              if (this.customerProfileListApp.address_dictionary[i].id === id) {
                if (this.isMenuAddressClicked == false) {
                  localStorage.setItem(
                    "profileAddressCheckout",
                    JSON.stringify(
                      this.customerProfileListApp.address_dictionary[i]
                    )
                  );
                } else {
                  localStorage.setItem(
                    "profileAddress",
                    JSON.stringify(
                      this.customerProfileListApp.address_dictionary[i]
                    )
                  );
                  localStorage.setItem(
                    "profileAddressCheckout",
                    JSON.stringify(
                      this.customerProfileListApp.address_dictionary[i]
                    )
                  );
                }

                if (this.isMenuAddressClicked === true) {
                  this.setDeliveryAddress();
                }
                this.deliveryAddressObj = {
                  id: this.customerProfileListApp.address_dictionary[i].id,
                  street:
                    this.customerProfileListApp.address_dictionary[i]
                      .address_line1,
                  address_line1:
                    this.customerProfileListApp.address_dictionary[i]
                      .address_line1,
                  zip: this.customerProfileListApp.address_dictionary[i].zip,
                  city: this.customerProfileListApp.address_dictionary[i].city,
                  country:
                    this.customerProfileListApp.address_dictionary[i].country,
                  state:
                    this.customerProfileListApp.address_dictionary[i].state,
                  latitude:
                    this.customerProfileListApp.address_dictionary[i].latitude,
                  longitude:
                    this.customerProfileListApp.address_dictionary[i].longitude,
                  apart_no:
                    this.customerProfileListApp.address_dictionary[i].apart_no,
                  notes:
                    this.customerProfileListApp.address_dictionary[i].notes,
                  house_building_no:
                    this.customerProfileListApp.address_dictionary[i]
                      .house_building_no,
                };
              }
            }
          } else if (
            this.customerProfileListApp.total_count === 0 &&
            myAddressData === null
          ) {
            localStorage.setItem(
              "profileAddressCheckout",
              JSON.stringify(null)
            );
            localStorage.setItem("profileAddress", JSON.stringify(null));
            if (this.isMenuAddressClicked === true) {
              this.setDeliveryAddress();
            }
            this.deliveryAddressObj = {
              id: "",
              street: "",
              address_line1: "",
              zip: "",
              city: "",
              country: "",
              state: "",
              latitude: "",
              longitude: "",
              apart_no: "",
              notes: "",
              house_building_no: "",
            };
          }
          if (this.isComingFromCheckOut) {
            this.getAddressListingFunctionFromCheckout();
          } else {
            this.getAddressListingFunction(myAddressData);
          }
          this.isComingFromCheckOut = false;
          this.isNewAddressSelected = false;
        },
        (error) => {}
      );
  }

  profileAddressObj: any;
  isDismissTryAgain: boolean = false;

  setDeliveryAddress() {
    this.profileAddressObj = JSON.parse(localStorage.getItem("profileAddress"));
    this.streetDelivery = "";

    if (
      this.profileAddressObj.house_building_no != "" &&
      this.profileAddressObj.house_building_no != null
    ) {
      if (this.streetDelivery != ", " && this.streetDelivery != "") {
        this.streetDelivery =
          this.streetDelivery + ", " + this.profileAddressObj.house_building_no;
      } else {
        this.streetDelivery = this.profileAddressObj.house_building_no;
      }
    }
    if (
      this.profileAddressObj.apart_no != "" &&
      this.profileAddressObj.apart_no != null
    ) {
      if (this.streetDelivery != ", " && this.streetDelivery != "") {
        this.streetDelivery =
          this.streetDelivery + ", " + this.profileAddressObj.apart_no;
      } else {
        this.streetDelivery = this.profileAddressObj.apart_no;
      }
    }
    if (
      this.profileAddressObj.address_line1 != "" &&
      this.profileAddressObj.address_line1 != null
    ) {
      if (this.streetDelivery != ", " && this.streetDelivery != "") {
        this.streetDelivery =
          this.streetDelivery + ", " + this.profileAddressObj.address_line1;
      } else {
        this.streetDelivery = this.profileAddressObj.address_line1;
      }
    }
    if (
      this.profileAddressObj.city != "" &&
      this.profileAddressObj.city != null
    ) {
      if (this.streetDelivery != ", " && this.streetDelivery != "") {
        this.streetDelivery =
          this.streetDelivery + ", " + this.profileAddressObj.city;
      } else {
        this.streetDelivery = this.profileAddressObj.city;
      }
    }

    localStorage.setItem("myDeliveryAddress", this.streetDelivery);
    localStorage.setItem("delivery_address", this.streetDelivery);
    this.streetDelivery = this.streetDelivery;
    this.dataControlService.setAddressOnAddressInput.next(true);
  }

  isMenuAddressClicked: boolean = true;

  callAddressFunction(value) {
    this.isDismissTryAgain = true;
    this.hideResetAddress = false;
    this.isMenuAddressClicked = true;
    this.opencheckoutButemptyAddress = false;
    this.addressPopupValue = value;
    this.checkoutPopupOpenForAddress = false;
    this.disableBackground();
    $("#customerAddresslisting").modal("show");
    this.closeNav();
    this.getAddressListing();
  }

  getAddressListingAtLoginTime() {
    this.showLoderAtCheckoutAddress = true;
    this.dataService.getAddressList(localStorage.getItem("token")).subscribe(
      (data) => {
        this.customerProfileListApp = data;
        if (this.customerProfileListApp.total_count > 0) {
          this.getNearAddressListingFunction();
        }
        if (this.customerProfileListApp.total_count === 0) {
          this.showLoderAtCheckoutAddress = false;

          localStorage.setItem("profileAddress", JSON.stringify(null));
          this.deliveryAddressObj = {
            id: "",
            street: "",
            address_line1: "",
            zip: "",
            city: "",
            country: "",
            state: "",
            latitude: "",
            longitude: "",
            apart_no: "",
            notes: "",
            house_building_no: "",
          };
        }
      },
      (error) => {}
    );
  }

  getNearAddressListingFunction() {
    var contex = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition1, showError1);
    }

    function showError1(error) {
      contex.checkoutPopupOpen = false;
      contex.clearBlur();
      contex.showLoderAtCheckoutAddress = false;
    }

    function showPosition1(position) {
      let lat = position.coords.latitude;
      let long = position.coords.longitude;
      let nearAddresslist = [];
      for (
        var i = 0;
        i < contex.customerProfileListApp.address_dictionary.length;
        i++
      ) {
        var dis = contex.checkDistance(
          lat,
          long,
          contex.customerProfileListApp.address_dictionary[i].latitude,
          contex.customerProfileListApp.address_dictionary[i].longitude
        );
        var obj = {
          id: contex.customerProfileListApp.address_dictionary[i].id,
          distance: dis,
        };
        nearAddresslist.push(obj);
      }
      contex.nearAddressObj = nearAddresslist.reduce(function (res, obj) {
        return obj.distance < res.distance ? obj : res;
      });

      contex.setAddressNear();
    }
  }

  closePickupPointPop() {
    if (this.checkOutState == 1) {
      $("#delivery").removeClass("checkout-tags");
      $("#dine-in").removeClass("checkout-tags");
      $("#self-pickup").addClass("checkout-tags");
      this.dineIn = false;
      this.selfPickup = true;
      this.delivery = false;
      this.tableNumber = -1;
      this.qrCode = "false";
      this.checkOutState = 1;
    } else if (this.checkOutState == 0) {
      let table;
      this.route.queryParams.subscribe((params) => {
        if (params["qrcode"] != undefined || params["qrcode"] != null) {
          this.qrCode = params["qrcode"];
          this.landedFromQR = true;
          this.showDownloadBanner = true;
          this.dataControlService.showBanner$.next(true);
        }
        if (
          params["tablenumber"] != undefined ||
          params["tablenumber"] != null
        ) {
          this.tableNumber = params["tablenumber"];
          table = this.tableNumber;
          console.log("table number", this.tableNumber);
        }
        if (params["outlet_id"] != undefined || params["outlet_id"] != null) {
          this.dineinOutletId = params["outlet_id"];
        }
        if (params["outletname"] != undefined || params["outletname"] != null) {
          this.outletName = params["outletname"];
        }
        if (params["type"] != undefined || params["type"] != null) {
          this.type = params["type"];
          this.type = this.type.toLowerCase();
          if (this.type == "dinein") {
            this.dineIn = true;
            this.selfPickup = false;
            this.delivery = false;
            this.dineinAddressString =
              this.businessDetailData.name + " - " + this.outletName;
          } else if (this.type == "pickup") {
            this.dineIn = false;
            this.selfPickup = true;
            this.delivery = false;
          } else {
            this.dineIn = false;
            this.selfPickup = false;
            this.delivery = true;
          }
        }
      });
      $("#dine-in").addClass("checkout-tags");
      $("#delivery").removeClass("checkout-tags");
      $("#self-pickup").removeClass("checkout-tags");
      this.dineIn = true;
      this.selfPickup = false;
      this.delivery = false;
      this.tableNumber = table;
      this.qrCode = "true";
      this.checkOutState = 0;
    } else {
      $("#self-pickup").addClass("checkout-tags");
      $("#dine-in").removeClass("checkout-tags");
      $("#delivery").removeClass("checkout-tags");
      $(".qr-scanner").css("display", "none");
      this.dineIn = false;
      this.selfPickup = true;
      this.delivery = false;
      this.tableNumber = -1;
      this.qrCode = "false";
    }
    $("#outletAddresslisting").modal("hide");
  }

  closeCheckoutModal() {
    this.dineIn = false;
    this.selfPickup = false;
    this.delivery = false;
    this.checkoutPopupOpen = false;
    this.checkoutPopupOpenForAddress = false;
    this.OrderSubmitted = false;
    $(".qr-scanner").css("display", "none");
  }

  setAddressNear() {
    var objId = parseInt(this.nearAddressObj.id);
    for (
      var i = 0;
      i < this.customerProfileListApp.address_dictionary.length;
      i++
    ) {
      if (this.customerProfileListApp.address_dictionary[i].id === objId) {
        localStorage.setItem(
          "profileAddress",
          JSON.stringify(this.customerProfileListApp.address_dictionary[i])
        );
        this.deliveryAddressObj = {
          id: this.customerProfileListApp.address_dictionary[i].id,
          street:
            this.customerProfileListApp.address_dictionary[i].address_line1,
          address_line1:
            this.customerProfileListApp.address_dictionary[i].address_line1,
          zip: this.customerProfileListApp.address_dictionary[i].zip,
          city: this.customerProfileListApp.address_dictionary[i].city,
          country: this.customerProfileListApp.address_dictionary[i].country,
          state: this.customerProfileListApp.address_dictionary[i].state,
          latitude: this.customerProfileListApp.address_dictionary[i].latitude,
          longitude:
            this.customerProfileListApp.address_dictionary[i].longitude,
          apart_no: this.customerProfileListApp.address_dictionary[i].apart_no,
          notes: this.customerProfileListApp.address_dictionary[i].notes,
          house_building_no:
            this.customerProfileListApp.address_dictionary[i].house_building_no,
        };
      }
    }
    this.clear_values();
    console.log(
      "%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% Set Address Near %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%"
    );
    this.getSlotDataForOutlet();
    this.showAddressInFrontOfWagonIcon = false;
    this.showLoderAtCheckoutAddress = false;
  }

  checkDistance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km (change this constant to get miles)
    var dLat = ((lat2 - lat1) * Math.PI) / 180;
    var dLon = ((lon2 - lon1) * Math.PI) / 180;
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    // if (d>1) return Math.round(d)+"km";
    // else if (d<=1) return Math.round(d*1000)+"m";
    return d;
  }

  getAddressListingFunction(myAddressData) {
    this.nao.go(30);
    console.log(
      "Select Address^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^"
    );

    this.dataService.getAddressList(localStorage.getItem("token")).subscribe(
      (data) => {
        this.customerProfileListApp = data;
        this.nao.go(100);
        if (myAddressData !== null) {
          if (myAddressData.isAddedAddress === false) {
            console.log("Adress check 1>>>>>>>>>>>>>>>>>>>>>>>");
            this.deliveryAddressObj = {
              id: myAddressData.address_id,
              street: myAddressData.areaLocation,
              address_line1: myAddressData.areaLocation,
              zip: myAddressData.postalLocation,
              city: myAddressData.cityLocation,
              country: myAddressData.countryLocation,
              state: myAddressData.stateLocation,
              latitude: myAddressData.latitudeDelivery,
              longitude: myAddressData.longitudeDelivery,
              apart_no: myAddressData.aptLocation,
              notes: myAddressData.orderNotesNew,
              house_building_no: myAddressData.houseNoLocation,
            };
          }
        }

        if (
          this.customerProfileListApp.total_count > 0 &&
          myAddressData === null
        ) {
          console.log(
            "No Address Selected 2 ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^"
          );
          var deliveryobjid = parseInt(this.deliveryAddressObj.id);
          for (
            var i = 0;
            i < this.customerProfileListApp.address_dictionary.length;
            i++
          ) {
            if (
              this.customerProfileListApp.address_dictionary[i].id ===
              deliveryobjid
            ) {
              console.log(
                "No Address Selected 2.1 ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^"
              );
              if (this.isMenuAddressClicked == false) {
                console.log(
                  "No Address Selected 2.1.1 ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^"
                );

                // add notes in main address
                localStorage.setItem(
                  "profileAddressCheckout",
                  JSON.stringify(
                    this.customerProfileListApp.address_dictionary[i]
                  )
                );
              } else {
                console.log(
                  "No Address Selected 2.1.2 ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^"
                );
                localStorage.setItem(
                  "profileAddress",
                  JSON.stringify(
                    this.customerProfileListApp.address_dictionary[i]
                  )
                );
                localStorage.setItem(
                  "profileAddressCheckout",
                  JSON.stringify(
                    this.customerProfileListApp.address_dictionary[i]
                  )
                );
              }

              console.log("Adress check 2>>>>>>>>>>>>>>>>>>>>>>>");
              this.deliveryAddressObj = {
                id: this.customerProfileListApp.address_dictionary[i].id,
                street:
                  this.customerProfileListApp.address_dictionary[i]
                    .address_line1,
                address_line1:
                  this.customerProfileListApp.address_dictionary[i]
                    .address_line1,
                zip: this.customerProfileListApp.address_dictionary[i].zip,
                city: this.customerProfileListApp.address_dictionary[i].city,
                country:
                  this.customerProfileListApp.address_dictionary[i].country,
                state: this.customerProfileListApp.address_dictionary[i].state,
                latitude:
                  this.customerProfileListApp.address_dictionary[i].latitude,
                longitude:
                  this.customerProfileListApp.address_dictionary[i].longitude,
                apart_no:
                  this.customerProfileListApp.address_dictionary[i].apart_no,
                notes: this.customerProfileListApp.address_dictionary[i].notes,
                house_building_no:
                  this.customerProfileListApp.address_dictionary[i]
                    .house_building_no,
              };
            }
          }
        }

        if (
          this.customerProfileListApp.total_count === 1 &&
          this.opencheckoutButemptyAddress === true &&
          myAddressData === null
        ) {
          console.log(
            "No Address Selected 3 ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^"
          );
          localStorage.setItem(
            "profileAddress",
            JSON.stringify(this.customerProfileListApp.address_dictionary[0])
          );
          console.log("Adress check 3>>>>>>>>>>>>>>>>>>>>>>>");
          this.deliveryAddressObj = {
            id: this.customerProfileListApp.address_dictionary[0].id,
            street:
              this.customerProfileListApp.address_dictionary[0].address_line1,
            address_line1:
              this.customerProfileListApp.address_dictionary[0].address_line1,
            zip: this.customerProfileListApp.address_dictionary[0].zip,
            city: this.customerProfileListApp.address_dictionary[0].city,
            country: this.customerProfileListApp.address_dictionary[0].country,
            state: this.customerProfileListApp.address_dictionary[0].state,
            latitude:
              this.customerProfileListApp.address_dictionary[0].latitude,
            longitude:
              this.customerProfileListApp.address_dictionary[0].longitude,
            apart_no:
              this.customerProfileListApp.address_dictionary[0].apart_no,
            notes: this.customerProfileListApp.address_dictionary[0].notes,
            house_building_no:
              this.customerProfileListApp.address_dictionary[0]
                .house_building_no,
          };
          this.opencheckoutButemptyAddress = false;
        }

        if (this.customerProfileListApp.total_count > 0) {
          console.log(
            "No Address Selected 3 ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^"
          );
          this.clear_values();
          console.log(
            "%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% Get Address Listing Function %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%"
          );
          this.getSlotDataForOutlet();
        } else {
          console.log(
            "No Address Selected 4 ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^"
          );
          this.clear_values();
          console.log(
            "No Address Selected^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^"
          );
        }

        if (
          localStorage.getItem("profileAddressCheckout") !== "null" &&
          localStorage.getItem("profileAddressCheckout") !== null
        ) {
          console.log(
            "No Address Selected 5 ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^"
          );
          this.showAddressInFrontOfWagonIcon = false;
        } else {
          console.log(
            "No Address Selected 6 ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^"
          );
          this.showAddressInFrontOfWagonIcon = true;
        }
        this.isEditAdressMatch = true;
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }

  setAddNotesAddressValuesFromCheckout(myProfileAddress) {
    this.labelDelivery = myProfileAddress.place_label;
    this.editAddressId = myProfileAddress.id;
    this.streetDelivery = myProfileAddress.address_line1;
    this.zipDelivery = myProfileAddress.zip;
    this.cityDelivery = myProfileAddress.city;
    this.countryDelivery = myProfileAddress.country;
    this.stateDelivery = myProfileAddress.state;
    this.latitudeDelivery = myProfileAddress.latitude;
    this.longitudeDelivery = myProfileAddress.longitude;
    this.apartDelivery = myProfileAddress.apart_no;
    this.orderNotesNew = myProfileAddress.notes;
    this.houseNoDelivery = myProfileAddress.house_building_no;
    this.streetNoDelivery = myProfileAddress.street;

    $(".myMapDiv").css("display", "block");
    this.checkoutAddress = true;
    if (
      this.isCheckoutSignup == false &&
      this.isPlaceOrderSignin == false &&
      this.isMainSigninClicked === false
    ) {
      $("#customerAddresslisting").modal("show");
      $("#deliveryAddressPop").modal("show");
    }
    this.isCheckoutSignup = false;
    this.isPlaceOrderSignin = false;
    this.isMainSigninClicked = false;

    this.showlocationOnMap();
    this.editAddress = false;
    this.CustomelabelDeliveryError = "";
  }

  openAddressPop() {
    // let myProfileAddress = JSON.parse(localStorage.getItem('profileAddress'));
    let myProfileAddress = JSON.parse(
      localStorage.getItem("profileAddressCheckout")
    );

    this.opencheckoutButemptyAddress = false;
    this.isMenuAddressClicked = false;
    this.isDismissTryAgain = true;
    this.hideResetAddress = true;
    $("#changeLocation").modal("hide");
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === undefined ||
      localStorage.getItem("token") === null
    ) {
      this.number = "";
      $("#signIn").modal("show");
      this.fbRegLogin();
      this.callSigninIntlInput();
      $(".addCartShort").css("-webkit-filter", "blur(3px)");
      $(".addCartShort").css("-moz-filter", "blur(3px)");
      $(".addCartShort").css("-o-filter", "blur(3px)");
      $(".addCartShort").css("-ms-filter", "blur(3px)");
      $(".addCartShort").css("filter", "blur(3px)");
    } else {
      // $('#customerAddresslisting').modal('show');
      this.isComesFromCheckout = true;

      if (myProfileAddress != "null" && myProfileAddress != null) {
        if (myProfileAddress.id === "") {
          this.setAddNotesAddressValuesFromCheckout(myProfileAddress);
          return;
        }
      }
      this.getAddressListingFunctionFromCheckout();
    }
  }

  getAddressListingFunctionFromCheckout() {
    this.nao.go(30);

    this.dataService.getAddressList(localStorage.getItem("token")).subscribe(
      (data) => {
        this.customerProfileListApp = data;
        this.addressDictionary = this.customerProfileListApp.address_dictionary;
        this.nao.go(100);
        if (this.customerProfileListApp.total_count === 0) {
          localStorage.setItem("profileAddress", JSON.stringify(null));
          localStorage.setItem("profileAddressCheckout", JSON.stringify(null));
          this.deliveryAddressObj = {
            id: "",
            street: "",
            address_line1: "",
            zip: "",
            city: "",
            country: "",
            state: "",
            latitude: "",
            longitude: "",
            apart_no: "",
            notes: "",
            house_building_no: "",
          };
          this.addNewAddress();
          this.opencheckoutButemptyAddress = true;
        }
        if (this.customerProfileListApp.total_count > 0) {
          this.addressPopupValue = "";
          $("#customerAddresslisting").modal("show");
          var deliveryobjid = parseInt(this.deliveryAddressObj.id);
          for (
            var i = 0;
            i < this.customerProfileListApp.address_dictionary.length;
            i++
          ) {
            if (
              this.customerProfileListApp.address_dictionary[i].id ===
              deliveryobjid
            ) {
              if (this.isMenuAddressClicked == false) {
                localStorage.setItem(
                  "profileAddressCheckout",
                  JSON.stringify(
                    this.customerProfileListApp.address_dictionary[i]
                  )
                );
              } else {
                localStorage.setItem(
                  "profileAddress",
                  JSON.stringify(
                    this.customerProfileListApp.address_dictionary[i]
                  )
                );
                localStorage.setItem(
                  "profileAddressCheckout",
                  JSON.stringify(
                    this.customerProfileListApp.address_dictionary[i]
                  )
                );
              }
              this.deliveryAddressObj = {
                id: this.customerProfileListApp.address_dictionary[i].id,
                street:
                  this.customerProfileListApp.address_dictionary[i]
                    .address_line1,
                address_line1:
                  this.customerProfileListApp.address_dictionary[i]
                    .address_line1,
                zip: this.customerProfileListApp.address_dictionary[i].zip,
                city: this.customerProfileListApp.address_dictionary[i].city,
                country:
                  this.customerProfileListApp.address_dictionary[i].country,
                state: this.customerProfileListApp.address_dictionary[i].state,
                latitude:
                  this.customerProfileListApp.address_dictionary[i].latitude,
                longitude:
                  this.customerProfileListApp.address_dictionary[i].longitude,
                apart_no:
                  this.customerProfileListApp.address_dictionary[i].apart_no,
                notes: this.customerProfileListApp.address_dictionary[i].notes,
                house_building_no:
                  this.customerProfileListApp.address_dictionary[i]
                    .house_building_no,
              };
            }
          }
        }
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }

  changeAddressField() {
    $("#customerAddresslabel").modal("show");
    if (this.labelDelivery === "Work" || this.labelDelivery === "Work") {
      this.CustomelabelDelivery = "";
      this.CustomelabelDeliveryError = "";
      this.CustomelabelDelivery1 = "Work";
    } else if (this.labelDelivery === "Home" || this.labelDelivery === "home") {
      this.CustomelabelDelivery = "";
      this.CustomelabelDeliveryError = "";
      this.CustomelabelDelivery1 = "Home";
    } else {
      this.CustomelabelDelivery = this.labelDelivery;
      this.CustomelabelDeliveryError = "";
      this.CustomelabelDelivery1 = "";
    }
  }

  setLabel(label) {
    if (label === "Home") {
      this.CustomelabelDelivery1 = "Home";
    }
    if (label === "Work") {
      this.CustomelabelDelivery1 = "Work";
    }
    this.CustomelabelDeliveryError = "";
    this.CustomelabelDelivery = "";
    this.labelDelivery = label;
  }

  changeSetLabel(label) {
    this.CustomelabelDelivery1 = "";
    if (
      label === "Home" ||
      label === "Work" ||
      label === "home" ||
      label === "work"
    ) {
      this.CustomelabelDeliveryError =
        "Please select " + this.CustomelabelDelivery + " From above";
    } else {
      this.CustomelabelDeliveryError = "";
      this.labelDelivery = label;
    }
    if (label === "") {
      this.CustomelabelDelivery1 = "Home";
      this.labelDelivery = "Home";
    }
  }

  addNewAddress() {
    this.hideResetAddress = false;
    this.isComesFromCheckout = false;

    this.streetDelivery = "";
    this.zipDelivery = "";
    this.cityDelivery = "";
    this.countryDelivery = "";
    this.stateDelivery = "";
    this.apartDelivery = "";
    this.orderNotesNew = "";
    this.houseNoDelivery = "";
    this.streetNoDelivery = "";
    this.latitudeDelivery = 0;
    this.longitudeDelivery = 0;
    this.checkoutAddress = false;
    $(".myMapDiv").css("display", "none");
    $("#deliveryAddressPop").modal("show");
    this.editAddress = false;
    this.labelDelivery = "Home";
    this.CustomelabelDelivery = "";
    this.CustomelabelDeliveryError = "";
    this.CustomelabelDelivery1 = "Home";
  }

  deleteAddress(id) {
    if (id === this.deliveryAddressObj.id) {
      let profileAddress = JSON.parse(localStorage.getItem("profileAddress"));
      let profileAddressCheckout = JSON.parse(
        localStorage.getItem("profileAddressCheckout")
      );

      if (profileAddress.id == id) {
        let profileAddress1 = {
          address_line1: profileAddress.address_line1,
          apart_no: profileAddress.apart_no,
          city: profileAddress.city,
          country: profileAddress.country,
          house_building_no: profileAddress.house_building_no,
          id: "",
          is_default: profileAddress.is_default,
          latitude: profileAddress.latitude,
          longitude: profileAddress.longitude,
          notes: profileAddress.notes,
          place_label: profileAddress.place_label,
          route: profileAddress.route,
          state: profileAddress.state,
          zip: profileAddress.zip,
        };
        localStorage.setItem("profileAddress", JSON.stringify(profileAddress1));
      }
      if (profileAddressCheckout.id == id) {
        let profileAddress2 = {
          address_line1: profileAddressCheckout.address_line1,
          apart_no: profileAddressCheckout.apart_no,
          city: profileAddressCheckout.city,
          country: profileAddressCheckout.country,
          house_building_no: profileAddressCheckout.house_building_no,
          id: "",
          is_default: profileAddressCheckout.is_default,
          latitude: profileAddressCheckout.latitude,
          longitude: profileAddressCheckout.longitude,
          notes: profileAddressCheckout.notes,
          place_label: profileAddressCheckout.place_label,
          route: profileAddressCheckout.route,
          state: profileAddressCheckout.state,
          zip: profileAddressCheckout.zip,
        };
        localStorage.setItem(
          "profileAddressCheckout",
          JSON.stringify(profileAddress2)
        );
      }
    }
    this.dataService
      .deleteAddressList(localStorage.getItem("token"), id)
      .subscribe(
        (data) => {
          this.deleteAddressSucess = data;
          this.deleteAddressSucess = this.deleteAddressSucess;
          if (this.deleteAddressSucess.success === true) {
            toastr.success("Address deleted successfully");
          } else {
            toastr.error(this.deleteAddressSucess.message);
          }
          this.getAddressListingFunction(null);
        },
        (error) => {}
      );
  }

  editEdress(address) {
    this.labelDelivery = address.place_label;
    this.editAddressId = address.id;
    this.streetDelivery = address.address_line1;
    this.zipDelivery = address.zip;
    this.cityDelivery = address.city;
    this.countryDelivery = address.country;
    this.stateDelivery = address.state;
    this.latitudeDelivery = address.latitude;
    this.longitudeDelivery = address.longitude;
    this.apartDelivery = address.apart_no;
    this.orderNotesNew = address.notes;
    this.houseNoDelivery = address.house_building_no;
    this.streetNoDelivery = address.route;

    let i;
    for (
      i = 0;
      i < this.customerProfileListApp.address_dictionary.length;
      i++
    ) {
      if (
        this.customerProfileListApp.address_dictionary[i].id ==
        this.editAddressId
      ) {
        if (
          this.customerProfileListApp.address_dictionary[i].is_default ==
            true &&
          this.isMenuAddressClicked == false
        ) {
          this.hideResetAddress = true;
          break;
        }
      }
    }
    if (this.customerProfileListApp.address_dictionary.length == i) {
      this.hideResetAddress = false;
    }

    $(".myMapDiv").css("display", "block");
    this.checkoutAddress = true;
    $("#deliveryAddressPop").modal("show");
    this.showlocationOnMap();
    this.editAddress = true;
    this.CustomelabelDeliveryError = "";
  }

  showlocationOnMap() {
    setTimeout(() => {
      var context = this;
      var map;
      var marker;
      var adr;
      var myLatlng = new google.maps.LatLng(
        this.latitudeDelivery,
        this.longitudeDelivery
      );
      var geocoder = new google.maps.Geocoder();
      var infowindow = new google.maps.InfoWindow();
      var mapOptions = {
        zoom: 14,
        center: myLatlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        navigationControl: false,
      };
      var endImage = {
        url: "assets/pin.png", // url
        scaledSize: new google.maps.Size(30, 42), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(16, 41), // anchor
      };
      if (this.isBusinessLandingPage == true) {
        this.url = "assets/pinBlack.png";
      }
      map = new google.maps.Map(document.getElementById("myMaps"), mapOptions);

      marker = new google.maps.Marker({
        map: map,
        position: myLatlng,
        draggable: true,
        icon: endImage,
      });
      geocoder.geocode({ latLng: myLatlng }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            infowindow.setContent(results[0].formatted_address);
            infowindow.open(map, marker);
            context.latitudeDelivery = marker.getPosition().lat();
            context.longitudeDelivery = marker.getPosition().lng();
          }
        }
      });

      google.maps.event.addListener(marker, "dragend", function () {
        geocoder.geocode(
          { latLng: marker.getPosition() },
          function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                context.myPinCity = results[0];
                infowindow.setContent(results[0].formatted_address);
                infowindow.open(map, marker);
                context.latitudeDelivery = marker.getPosition().lat();
                context.longitudeDelivery = marker.getPosition().lng();
              }
            }
          }
        );
      });
    }, 500);
  }

  capitlizeFirstName() {
    this.firstName = this.firstName.replace(/\b\w/g, function (l) {
      return l.toUpperCase();
    });
  }
  capitlizeAddressLabel() {
    this.CustomelabelDelivery = this.CustomelabelDelivery.replace(
      /\b\w/g,
      function (l) {
        return l.toUpperCase();
      }
    );
  }
  capitlizeLastName() {
    this.lastName = this.lastName.replace(/\b\w/g, function (l) {
      return l.toUpperCase();
    });
  }
  capitlizeProfileFrstName() {
    this.ProfileFirst_name = this.ProfileFirst_name.replace(
      /\b\w/g,
      function (l) {
        return l.toUpperCase();
      }
    );
  }
  capitlizeProfileLastName() {
    this.ProfileLast_name = this.ProfileLast_name.replace(
      /\b\w/g,
      function (l) {
        return l.toUpperCase();
      }
    );
  }
  infoPop(obj) {
    this.infoData = obj;
    $("#infoPopOpen").modal("show");
  }
  breakdownPop(delivery_charges_calculated, off_price, shipping_cost) {
    this.orderDelivery_charges = delivery_charges_calculated;
    this.orderDelivery_off_price = off_price;
    this.orderShipping_cost = shipping_cost;
    $("#breakdownPop").modal("show");
  }
  historyFunction() {
    this.pushNotificationFunction();
    $(".clear").removeClass("active");
    $("#running").removeClass("active");
    $("#Past").removeClass("active");
    $("#running").removeClass("active");
    $("#Past").addClass("active");
    $("#2").addClass("active");
    $("#Past").addClass("in");
    this.disableBackground();
    $("#orderPopup").modal("show");
    this.closeNav();
    this.orderType = 2;
    this.callOrderFunction();
  }
  orderFunction() {
    this.pushNotificationFunction();
    $(".clear").removeClass("active");
    $("#running").removeClass("active");
    $("#Past").removeClass("active");
    $("#running").addClass("active");
    $("#1").addClass("active");
    // $('#running').addClass('in');
    this.orderType = 0;
    this.closeNav();
    this.disableBackground();
    $("#orderPopup").modal("show");
    this.callOrderFunction();
  }
  orderFilterType: string = "All";
  getOrderListbytype(id, type) {
    this.orderFilterType = type;
    // this.orderListData = [];
    this.orderType = id;
    this.callOrderFunction();
  }
  callOrderFunction() {
    this.showOrderData = false;
    this.orderLastPage = true;
    this.orderLastPageNo = 0;
    this.nao.go(30);
    // this.orderListData = [];
    this.dataService
      .getOrderListing(
        localStorage.getItem("token"),
        this.orderType,
        this.orderLastPageLemit,
        this.dataService.businessIdForIndependentBusinessData
      )
      .subscribe(
        (data) => {
          this.nao.go(100);
          this.orderListData = data;
          this.orderLastPage = this.orderListData.last_page;
          this.orderListData = this.orderListData.items;
          this.showOrderData = true;
        },
        (error) => {
          this.nao.go(100);
        }
      );
  }
  callOrderFunctionByPage() {
    this.orderLastPageNo++;
    this.nao.go(30);
    this.dataService
      .getOrderListingBypage(
        localStorage.getItem("token"),
        this.orderType,
        this.orderLastPageLemit,
        this.orderLastPageNo,
        this.dataService.businessIdForIndependentBusinessData
      )
      .subscribe(
        (data) => {
          this.nao.go(100);
          this.orderListDataByPage = data;
          this.orderLastPage = this.orderListDataByPage.last_page;
          this.orderListDataByPage = this.orderListDataByPage.items;
          console.log(this.orderListDataByPage);
          for (var i = 0; i < this.orderListDataByPage.length; i++) {
            this.orderListData.push(this.orderListDataByPage[i]);
          }
        },
        (error) => {
          this.nao.go(100);
        }
      );
  }
  house_building_no: any;
  CallTrackFun(id, orderType) {
    this.orderMediumType = orderType;
    this.orderTrackId = id;
    this.nao.go(30);
    this.dataService
      .getTrackDetail(localStorage.getItem("token"), this.orderTrackId)
      .subscribe(
        (data) => {
          this.nao.go(100);
          this.trackData = data;
          this.apart_no = this.trackData.drop_off_address.apart_no;
          this.house_building_no =
            this.trackData.drop_off_address.house_building_no;
          if (
            this.trackData.order.status === 5 ||
            this.trackData.order.status === 6 ||
            this.trackData.order.status === 7
          ) {
            $("#TrackPopup").modal("hide");
            return;
          }
          if (
            this.trackData.order.status === 0 ||
            this.trackData.order.status === 1 ||
            this.trackData.order.status === 2
          ) {
            this.DeliveredTitle = this.trackData.order.dispensary_name;
            this.DeliveredSubTitle = "Processing";
          }
          if (this.trackData.delivery_man_type === 3) {
            if (this.trackData.third_party.deliver_date !== "") {
              if (this.trackData.order.status > 2) {
                this.DeliveredTitle = "Estimated Delivery Date";
                this.DeliveredSubTitle =
                  this.trackData.third_party.deliver_date;
              }
            }
          } else {
            if (
              this.trackData.order.status === 3 ||
              this.trackData.order.status === 8
            ) {
              this.DeliveredTitle = this.trackData.order.dispensary_name;
              this.DeliveredSubTitle = "Ready for delivery";
            }
            if (
              this.trackData.order.status === 4 ||
              this.trackData.order.status === 9 ||
              this.trackData.order.status === 10
            ) {
              this.DeliveredTitle = this.trackData.order.dispensary_name;
              this.DeliveredSubTitle = "";
              this.getDriverLocation();
            }
          }
          setTimeout(() => {
            this.tractMapFunction();
          }, 1000);

          var cDate = new Date().getDate();
          var cYear = new Date().getFullYear();
          var cMonth = new Date().getUTCMonth() + 1;
          var openTime = this.trackData.order.open_time;
          var tOpen =
            cYear + "/" + cMonth + "/" + cDate + " " + openTime + " " + "UTC";
          var dateObjOpen = new Date(tOpen);
          this.deliverStartDate = dateObjOpen;

          var closeTime = this.trackData.order.close_time;
          var tClose =
            cYear + "/" + cMonth + "/" + cDate + " " + closeTime + " " + "UTC";
          var dateObjClose = new Date(tClose);
          this.deliverEndDate = dateObjClose;
        },
        (error) => {
          this.nao.go(100);
        }
      );
  }
  tractMapFunction() {
    if (this.infowindowTrack) {
      this.infowindowTrack.close();
    }
    let mapEleTrack = this.mapElement3.nativeElement;
    this.directionsService = new google.maps.DirectionsService();
    this.directionsDisplay = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
    });
    this.directionsDisplay.setMap(this.mapTrack);
    if (
      this.trackData.order.status === 0 ||
      this.trackData.order.status === 1 ||
      this.trackData.order.status === 2 ||
      this.trackData.order.status === 3
    ) {
      if (this.trackData.delivery_man_type === 3) {
        this.mapTrack = new google.maps.Map(mapEleTrack, {
          zoom: 10,
          center: {
            lat: this.trackData.drop_off_address.latitude,
            lng: this.trackData.drop_off_address.longitude,
          },
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          disableDefaultUI: true,
          zoomControl: true,
          scaleControl: true,
        });
      } else {
        this.mapTrack = new google.maps.Map(mapEleTrack, {
          zoom: 10,
          center: {
            lat: this.trackData.picup_address.latitude,
            lng: this.trackData.picup_address.longitude,
          },
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          disableDefaultUI: true,
          zoomControl: true,
          scaleControl: true,
        });
      }
    }
    if (
      this.trackData.order.status === 4 ||
      this.trackData.order.status === 9 ||
      this.trackData.order.status === 10
    ) {
      this.mapTrack = new google.maps.Map(mapEleTrack, {
        zoom: 10,
        center: {
          lat: this.trackData.drop_off_address.latitude,
          lng: this.trackData.drop_off_address.longitude,
        },
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
        zoomControl: true,
        scaleControl: true,
      });
    }
    google.maps.event.addListenerOnce(this.mapTrack, "idle", () => {
      mapEleTrack.classList.add("show-map");
    });
    var driver_pin_icon = {
      url: "assets/driverpin.png", // url
      scaledSize: new google.maps.Size(30, 50), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(16, 50), // anchor
    };
    var customer_pin_icon = {
      url: "assets/customerpin.png", // url
      scaledSize: new google.maps.Size(30, 47), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(16, 46), // anchor
    };

    var previous_order_icon = {
      url: "assets/Group 275@2x.png", // url
      scaledSize: new google.maps.Size(30, 26), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(16, 9), // anchor
    };

    if (this.trackData.order_list.length > 0) {
      var bounds2 = new google.maps.LatLngBounds();
      var bounds3 = new google.maps.LatLngBounds();

      var location = new google.maps.LatLng(
        this.trackData.drop_off_address.latitude,
        this.trackData.drop_off_address.longitude
      );
      var location2 = [];

      for (let i = 0; i < this.trackData.order_list.length; i++) {
        location2.push(
          new google.maps.LatLng(
            this.trackData.order_list[i].latitude,
            this.trackData.order_list[i].longitude
          )
        );
        bounds3.extend(location2[i]);
      }
      bounds2.extend(location);
      this.markerTrack = new google.maps.Marker({
        title: "<h6>" + this.DeliveredTitle + "</h6>" + this.DeliveredSubTitle,
        position: location,
        map: this.mapTrack,
        icon: customer_pin_icon,
        zoom: 13,
      });
      this.infowindowTrack = new google.maps.InfoWindow();
      this.infowindowTrack.setContent(this.markerTrack.title);
      this.infowindowTrack.open(this.mapTrack, this.markerTrack);

      for (let i = 0; i < location2.length; i++) {
        this.markerTrackPrevious = new google.maps.Marker({
          position: location2[i],
          map: this.mapTrack,
          icon: previous_order_icon,
          zoom: 13,
        });
      }
      this.infowindowTrackPrevious = new google.maps.InfoWindow();
    } else {
      var bounds2 = new google.maps.LatLngBounds();
      if (
        this.trackData.order.status === 0 ||
        this.trackData.order.status === 1
      ) {
        var location = new google.maps.LatLng(
          this.trackData.picup_address.latitude,
          this.trackData.picup_address.longitude
        );
      } else {
        var location = new google.maps.LatLng(
          this.trackData.drop_off_address.latitude,
          this.trackData.drop_off_address.longitude
        );
      }

      bounds2.extend(location);

      this.markerTrack = new google.maps.Marker({
        title: "<h6>" + this.DeliveredTitle + "</h6>" + this.DeliveredSubTitle,
        position: location,
        map: this.mapTrack,
        icon: customer_pin_icon,
        zoom: 13,
      });
      this.infowindowTrack = new google.maps.InfoWindow();
      this.infowindowTrack.setContent(this.markerTrack.title);
      this.infowindowTrack.open(this.mapTrack, this.markerTrack);
    }
    this.getDriverLocation();
    if (this.trackData.delivery_man_type !== 3) {
      if (
        this.trackData.order.status === 3 ||
        this.trackData.order.status === 4 ||
        this.trackData.order.status === 9 ||
        this.trackData.order.status === 10
      ) {
        if (this.infowindowTrack) {
          this.infowindowTrack.close();
        }
        this.markerTrack.setMap(null);
        this.markerTrack = {};
        if (this.trackData.order.status === 3) {
          var end = {
            lat: this.trackData.picup_address.latitude,
            lng: this.trackData.picup_address.longitude,
          };
          var location = new google.maps.LatLng(
            this.trackData.picup_address.latitude,
            this.trackData.picup_address.longitude
          );
        }
        if (
          this.trackData.order.status === 4 ||
          this.trackData.order.status === 9 ||
          this.trackData.order.status === 10
        ) {
          var end = {
            lat: this.trackData.drop_off_address.latitude,
            lng: this.trackData.drop_off_address.longitude,
          };
          var location = new google.maps.LatLng(
            this.trackData.drop_off_address.latitude,
            this.trackData.drop_off_address.longitude
          );
        }
        var start = {
          lat: this.driverlocationData.latitude,
          lng: this.driverlocationData.longitude,
        };
        var bounds2 = new google.maps.LatLngBounds();
        bounds2.extend(location);
        this.markerTrack = new google.maps.Marker({
          title:
            "<h6>" + this.DeliveredTitle + "</h6>" + this.DeliveredSubTitle,
          position: location,
          map: this.mapTrack,
          icon: customer_pin_icon,
          zoom: 13,
        });

        this.infowindowTrack = new google.maps.InfoWindow();
        this.infowindowTrack.setContent(this.markerTrack.title);
        this.infowindowTrack.open(this.mapTrack, this.markerTrack);
        this.startDriverLatLng = start;
        // driver marker initilizer
        var driver_pin_icon = {
          url: "assets/driverpin.png", // url
          scaledSize: new google.maps.Size(30, 50), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(16, 50), // anchor
        };
        var customer_pin_icon = {
          url: "assets/customerpin.png", // url
          scaledSize: new google.maps.Size(30, 47), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(16, 46), // anchor
        };
        var bounds2 = new google.maps.LatLngBounds();
        bounds2.extend(end);
        this.driverPin = new google.maps.Marker({
          title: "driver",
          position: start,
          map: this.mapTrack,
          icon: driver_pin_icon,
          zoom: 13,
        });
        // this.driverMarker = new google.maps.InfoWindow();
        // this.driverMarker.setContent(this.driverPin.title);
        // this.driverMarker.open(this.mapTrack, this.driverPin);
        this.calculateRoute(start, end);
        if (this.trackData.is_track_order === true) {
          this.intervalSetTrack = setInterval(() => {
            this.getDriverLocation();
          }, 10000);
        }
      }
    }

    setTimeout(() => {
      $("#TrackPopup").modal("show");
    }, 100);
  }
  getAppBtnClickedOnBusinessLanding() {
    this.dataControlService.getAppBtnOnApp.next(false);
  }

  getDriverLocation() {
    this.dataService
      .getDriverPosition(localStorage.getItem("token"), this.orderTrackId)
      .subscribe(
        (data) => {
          this.driverlocationData = data;
          if (this.trackData.order.status === 3) {
            var end = {
              lat: this.trackData.picup_address.latitude,
              lng: this.trackData.picup_address.longitude,
            };
          }
          if (
            this.trackData.order.status === 4 ||
            this.trackData.order.status === 9 ||
            this.trackData.order.status === 10
          ) {
            var end = {
              lat: this.trackData.drop_off_address.latitude,
              lng: this.trackData.drop_off_address.longitude,
            };
          }
          this.previousDriverLatLng = this.startDriverLatLng;
          this.startDriverLatLng = {
            lat: this.driverlocationData.latitude,
            lng: this.driverlocationData.longitude,
          };
          this.numberOfDriverLocationChecks =
            this.numberOfDriverLocationChecks + 1;
          this.currentDriverLocation = this.currentDriverLocation + 1;

          this.updateRouteMarkerWithtransition(
            this.driverPin,
            this.previousDriverLatLng,
            this.startDriverLatLng
          );
          this.calculateRoute(this.startDriverLatLng, end);
        },
        (error) => {}
      );
  }

  async updateRouteMarkerWithtransition(marker, position, result) {
    this.r = 0;
    this.deltaLat = (result.lat - position.lat) / this.numDeltas;
    this.deltaLng = (result.lng - position.lng) / this.numDeltas;
    this.moveMarker(marker, position, this.currentDriverLocation);
  }

  moveMarker(marker, position, localDriverLocationNumber) {
    if (localDriverLocationNumber !== this.numberOfDriverLocationChecks) {
      return;
    }

    position.lat += this.deltaLat;
    position.lng += this.deltaLng;
    var latlng = new google.maps.LatLng(position.lat, position.lng);
    marker.setPosition(latlng);
    if (this.r !== this.numDeltas) {
      this.r++;

      setTimeout(() => {
        this.moveMarker(marker, position, this.currentDriverLocation);
      }, this.delay);
    }
  }

  calculateRoute(start_location, end_location) {
    var start = new google.maps.LatLng(start_location.lat, start_location.lng);
    var end = new google.maps.LatLng(end_location.lat, end_location.lng);
    var bounds = new google.maps.LatLngBounds();
    bounds.extend(start);
    bounds.extend(end);

    if (this.trackData.order_list.length > 0) {
      var waypts = [];
      for (let i = 0; i < this.trackData.order_list.length; i++) {
        waypts.push({
          location: new google.maps.LatLng(
            this.trackData.order_list[i].latitude,
            this.trackData.order_list[i].longitude
          ),
          stopover: true,
        });
      }

      let request = {
        origin: start,
        destination: end,
        waypoints: waypts,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      };
      var context = this;
      this.directionsService.route(request, function (response, status) {
        if (status === google.maps.DirectionsStatus.OK) {
          context.directionsDisplay.setDirections(response);
          context.directionsDisplay.setMap(context.mapTrack);
        } else {
        }
      });
    } else {
      var request = {
        origin: start,
        destination: end,
        travelMode: google.maps.TravelMode.DRIVING,
      };
      var context = this;
      this.directionsService.route(request, function (response, status) {
        if (status === google.maps.DirectionsStatus.OK) {
          context.directionsDisplay.setDirections(response);
          context.directionsDisplay.setMap(context.mapTrack);
        } else {
        }
      });
    }
  }
  closeTrackOrderPop() {
    $("#TrackPopup").modal("hide");
    clearInterval(this.intervalSetTrack);
  }

  cancelOrder() {
    this.dataService
      .cancelUserOrder(localStorage.getItem("token"), this.cancelOrderId)
      .subscribe((data) => {
        this.cancelOrderSuccess = data;
        localStorage.setItem(
          "freeDeliveryCount",
          this.cancelOrderSuccess.free_delivery_count
        );
        this.cancelOrderSuccess = this.cancelOrderSuccess.success;
        if (this.cancelOrderSuccess === true) {
          this.callOrderFunction();
          $("#onCancelOrderPopUp").modal("hide");
        }
      });
  }

  cancelorderPopUp(id) {
    this.cancelOrderId = id;
    $("#onCancelOrderPopUp").modal("show");
  }
  orderCall(phone_number) {
    this.phone_number = phone_number;
    $("#onCallOrderPopUp").modal("show");
  }
  isAreasLoaded: boolean = false;
  businessRating(index) {
    this.businessRatingValue = index + 1;
    // for (let i = 0; i < this.businessRaiting; i++) {
    //   $("#businessrating" + i).addClass("zero-rating");
    // }
    // for (let i = 0; i <= index; i++) {
    //   $("#businessrating" + i).removeClass("zero-rating");
    //   $("#businessrating" + i).addClass("full-rating");
    // }
  }

  driverRating(index) {
    this.driverRatingValue = index + 1;
    // for (let i = 0; i < this.businessRaiting; i++) {
    //   $("#driverrating" + i).addClass("zero-rating");
    // }
    // for (let i = 0; i <= index; i++) {
    //   $("#driverrating" + i).removeClass("zero-rating");
    //   $("#driverrating" + i).addClass("full-rating");
    // }
  }

  get_pending_rating() {
    this.dataService
      .get_pending_rating(localStorage.getItem("token"))
      .subscribe((data: any) => {
        this.ratingData = data;
        this.pendingPayment = data.pending_payment;
        if (this.pendingPayment.is_pending) {
          $("#pendingOnlinePayment").modal({
            backdrop: "static",
            keyboard: false,
          });
          $("#pendingOnlinePayment").modal("show");
        } else if (this.ratingData.status === 5) {
          this.events.publish("ratingPopUpData", this.ratingData);
        } else {
          // this.events.publish('ratingPopUpData',null);
        }
      });
  }

  sendRating() {
    this.dataService
      .rate_driver(
        localStorage.getItem("token"),
        this.rating_id,
        this.dispensary_rating_id,
        this.driverRatingValue,
        this.businessRatingValue,
        this.feedBack
      )
      .subscribe((data) => {
        this.sendRatingData = data;
        this.sendRatingSuccess = this.sendRatingData.success;
        if (this.sendRatingSuccess === true) {
          //empty business rating stars
          for (let i = 0; i < this.businessRaiting; i++) {
            $("#businessrating" + i).addClass("zero-rating");
          }
          //empty driver rating stars
          for (let i = 0; i < this.businessRaiting; i++) {
            $("#driverrating" + i).addClass("zero-rating");
          }
          this.cancelRatingValue = 0;
        }
      });
  }

  cancelRating() {
    this.cancelRatingValue = this.cancelRatingValue + 1;
    $("#onOnleResponsePopup").modal("hide");
    for (let i = 0; i < this.businessRaiting; i++) {
      $("#businessrating" + i).addClass("zero-rating");
    }
    if (this.is_third_party === false) {
      for (let i = 0; i < this.businessRaiting; i++) {
        $("#driverrating" + i).addClass("zero-rating");
      }
    }
    if (this.cancelRatingValue === 2) {
      this.dataService
        .cancel_rating(
          localStorage.getItem("token"),
          this.rating_id,
          this.dispensary_rating_id
        )
        .subscribe((data) => {
          this.cancelRatingData = data;
          this.cancelRatingSuccess = this.cancelRatingData.success;
          if (this.cancelRatingSuccess === true) {
            // this.pendingRating  = window.clearInterval(this.pendingRating);
            this.cancelRatingValue = 0;
            // this.get_pending_rating();
          }
        }),
        (err) => console.log(err);
    } else {
      this.get_pending_rating();
    }
  }
  callraitingFunction() {
    this.closeNav();
    this.get_pending_rating();
  }
  callPushNotificationFunction() {
    const scope = this;
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(function () {
        // get token
        messaging
          .getToken()
          .then(function (currentToken) {
            if (currentToken) {
              scope.authService.tokePush = currentToken;
              scope.pushNotificationFunction();
              messaging.onMessage(function (payload) {
                scope.events.publish("pushRecived", payload.data);
                const notificationTitle = payload.notification.title;
                const notificationOptions = {
                  body: payload.notification.body,
                };
                // if (Notification.permission === "granted") {
                // If it's okay let's create a notification
                var notification = new Notification(
                  notificationTitle,
                  notificationOptions
                );
                notification.onclick = function (event) {
                  event.preventDefault(); // prevent the browser from focusing the Notification's tab
                  window.open(payload.notification.click_action, "_blank");
                  notification.close();
                };
                // }
                try {
                  context.notificationObserver.next(payload);
                } catch (error) {}
              });
            } else {
              // Show permission request.
              // Show permission UI.
            }
          })
          .catch(function (err) {});
      })
      .catch(function (err) {});
  }
  callPreviourPage() {
    // this._location.back();
    this.router.navigate(["/"]);
  }

  // ====== wish list fun start =========
  wishlistFunction() {
    this.paginationPageWishList = 0;
    clearInterval(this.wishlistItemTimeOut);
    $(".disableButton").css("display", "none");
    this.wishlistSearchItem = true;
    this.dataService
      .getWishList(
        localStorage.getItem("token"),
        this.limitWishList,
        this.paginationPageWishList,
        this.wishlistSearchString,
        this.dataService.businessIdForIndependentBusinessData
      )
      .subscribe(
        (data) => {
          this.wishListLoadMore = false;
          this.wishlistData = data;
          this.wishlistDataLastPage = this.wishlistData.last_page;
          this.wishlistData = this.wishlistData.items;
          // $('.disableButton').css('display', 'block');
          this.wishlistSearchItem = false;
          if (
            this.wishlistData.length > 0 &&
            this.wishlistSearchString === ""
          ) {
            this.showWishlistSearch = true;
          }
          if (
            this.wishlistData.length === 0 &&
            this.wishlistSearchString === ""
          ) {
            this.showWishlistSearch = false;
          }
          const cartData = JSON.parse(localStorage.getItem("cartItems"));
          for (var i = 0; i < this.wishlistData.length; i++) {
            for (var j = 0; j < this.wishlistData[i].product.length; j++) {
              this.wishlistData[i].product[j].count = 0;
            }
          }
          if (cartData !== null && cartData !== undefined) {
            for (var i = 0; i < this.wishlistData.length; i++) {
              for (var j = 0; j < this.wishlistData[i].product.length; j++) {
                for (
                  var k = 0;
                  k < this.wishlistData[i].product[j].variant.length;
                  k++
                ) {
                  for (var l = 0; l < cartData.length; l++) {
                    if (
                      cartData[l].variant_id ===
                      this.wishlistData[i].product[j].variant[k].id
                    ) {
                      this.wishlistData[i].product[j].count +=
                        cartData[l].quantity;
                    }
                  }
                }
              }
            }
          }
          // setTimeout(() => {
          //   $('#wishlistPopup').modal('show');
          // }, 500 );
        },
        (error) => {}
      );
  }
  removeToWishlistCatConform(obj, index) {
    this.removeToWishlistObj = obj;
    this.removeToWishlistIndex = index;
    $("#removeWishlistConform").modal("show");
  }
  removeToWishlistCat(obj, index) {
    $("#removeWishlistConform").modal("hide");
    var dele = 1;
    this.wishlistData.splice(index, 1);
    if (this.wishlistSearchString === "") {
      if (this.wishlistData.length > 0) {
        this.showWishlistSearch = true;
      } else {
        this.showWishlistSearch = false;
      }
    }
    this.events.publish("inlogin", "true");
    this.dataService
      .removeProductInWishList(localStorage.getItem("token"), obj.id)
      .subscribe(
        (data) => {
          this.addWishlistSuccess = data;
          this.addWishlistSuccess = this.addWishlistSuccess.success;
          if (this.addWishlistSuccess === true) {
          } else {
            toastr.error("Somethimg went wrong");
          }
        },
        (error) => {}
      );
  }
  removeToWishlistCatFromDetail(obj) {
    this.wishlistData.splice(this.detailProductIndex, 1);
    this.wishlistDetailData.is_wish_list = false;
    // if ( this.wishlistData.length > 0 ){
    //   this.showWishlistSearch = true;
    // } else {
    //   this.showWishlistSearch = false;
    // }
    // $('#businessAllWishlist').modal('hide');
    this.events.publish("inlogin", "true");
    this.dataService
      .removeProductInWishList(localStorage.getItem("token"), obj.id)
      .subscribe(
        (data) => {
          this.addWishlistSuccess = data;
          this.addWishlistSuccess = this.addWishlistSuccess.success;
          if (this.addWishlistSuccess === true) {
          } else {
            toastr.error("Somethimg went wrong");
          }
          this.wishlistFunction();
        },
        (error) => {}
      );
  }
  addToWishlistCatFromDetail(obj) {
    this.wishlistDetailData.is_wish_list = true;
    // $('#businessAllWishlist').modal('hide');
    this.events.publish("inlogin", "true");
    this.dataService
      .addProductInWishList(
        localStorage.getItem("token"),
        obj.id,
        this.detailProductData.business_id
      )
      .subscribe(
        (data) => {
          this.addWishlistSuccess = data;
          this.addWishlistSuccess = this.addWishlistSuccess.success;
          if (this.addWishlistSuccess === true) {
          } else {
            toastr.error("Something went wrong");
          }
          this.wishlistFunction();
        },
        (error) => {}
      );
  }
  getproductDetail(all, id, index) {
    this.detailProductIndex = index;
    this.detailProductData = all;
    var token;
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === undefined
    ) {
      token = "";
    } else {
      token = localStorage.getItem("token");
    }
    this.nao.go(30);
    this.dataService.productDetail(id, token).subscribe(
      (data) => {
        this.nao.go(100);
        this.wishlistDetailData = data;
        this.wishlistSuccess = this.wishlistDetailData.success;
        this.wishlistDetailData = this.wishlistDetailData.product;
        this.wishlistDetailData.description =
          this.wishlistDetailData.description.replace(
            /(?:\r\n|\r|\n)/g,
            "<br>"
          );
        this.wishlistDetailDataImagesLength =
          this.wishlistDetailData.image.length;
        if (this.wishlistDetailDataImagesLength <= 1) {
          $(".carousel-indicators").removeClass("display-block");
          $(".carousel-indicators").addClass("display-none");
        }
        if (this.wishlistDetailDataImagesLength > 1) {
          $(".carousel-indicators").removeClass("display-none");
          $(".carousel-indicators").addClass("display-block");
        }
        if (this.wishlistSuccess === true) {
          this.addCartPopOpenWishList(all, this.wishlistDetailData, 2);
          this.openNavAll();
        }
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }
  openNavAll() {
    $("#businessAllWishlist").modal(
      {
        // backdrop: 'static',
        // keyboard: false,
      },
      "show"
    );
    // document.getElementById('main').style.marginRight = '250px';
  }
  checkObject(array, obj) {
    var value = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].variant_id === obj.id) {
        value = true;
        break;
      }
    }
    return value;
  }
  addCartPopOpenWishList(all, obj, val) {
    this.allProd = all;
    this.objProd = obj;
    this.valProd = val;
    this.cartFinalWishlist = [];

    // Time Work Start
    if (all.business_detail_web_dictionary.full_day === true) {
      this.businessStatus = "Open";
      this.openOrCloseIn = "";
      this.finalDisplayTime = "";
    } else {
      var cDate = new Date().getDate();
      var cYear = new Date().getFullYear();
      var cMonth = new Date().getUTCMonth() + 1;
      var openTime = all.business_detail_web_dictionary.open_time;
      var tOpen =
        cYear + "/" + cMonth + "/" + cDate + " " + openTime + " " + "UTC";
      this.dateObjOpen = new Date(tOpen);
      var closeTime = all.business_detail_web_dictionary.close_time;
      var tClose =
        cYear + "/" + cMonth + "/" + cDate + " " + closeTime + " " + "UTC";
      this.dateObjClose = new Date(tClose);
      this.currentSystemTime = new Date().getTime();
      var convertedTimeWithZone = new Date(this.dateObjOpen).getTime();
      var convertedTimeWithZone2 = new Date(this.dateObjClose).getTime();
      if (convertedTimeWithZone2 < convertedTimeWithZone) {
        convertedTimeWithZone2 = convertedTimeWithZone2 + 86400000;
      }
      if (convertedTimeWithZone2 === convertedTimeWithZone) {
        convertedTimeWithZone2 = convertedTimeWithZone2 + 86400000 + 86400000;
      }
      if (
        this.currentSystemTime >= convertedTimeWithZone &&
        this.currentSystemTime <= convertedTimeWithZone2
      ) {
        this.businessStatus = "Open";
        this.openOrCloseIn = "Will close ";
        // this.openOrCloseIn = 'Will close ' + this.businessDetailData.close_time;
        this.finalDisplayTime = this.dateObjClose;
      } else {
        this.businessStatus = "Closed";
        // this.openOrCloseIn = 'Will reopen ' + this.businessDetailData.open_time;
        this.openOrCloseIn = "Will reopen";
        this.finalDisplayTime = this.dateObjOpen;
      }
    }
    // Time Work End

    this.outletAddress = {
      area: all.business_detail_web_dictionary.street,
      city: all.business_detail_web_dictionary.city,
      state: all.business_detail_web_dictionary.state,
      zip: all.business_detail_web_dictionary.zip,
      country: all.business_detail_web_dictionary.area,
      longitude: all.business_detail_web_dictionary.longitude,
      latitude: all.business_detail_web_dictionary.latitude,
    };
    this.cartFinalWishlist = [];
    this.cartDataFromStorage = JSON.parse(localStorage.getItem("cartItems"));
    this.cartPopDataAll = all;
    this.cartPopDataWishlist = obj;
    this.modifierProductList = [];
    this.variantData = this.cartPopDataWishlist.variant;
    for (let i = 0; i < this.variantData.length; i++) {
      let foundModifier = false;
      if (this.cartDataFromStorage != undefined) {
        for (let l = 0; l < this.cartDataFromStorage.length; l++) {
          if (
            this.cartDataFromStorage[l].variant_id == this.variantData[i].id
          ) {
            if (
              this.cartDataFromStorage[l].hasModifier == true &&
              this.cartDataFromStorage[l].uniqueId != ""
            ) {
              this.modifierProductList.push(this.cartDataFromStorage[l]);
              foundModifier = true;
            }
          }
        }
      }

      if (foundModifier) {
        this.cartFinalWishlist.push({
          business_image: all.business_detail_web_dictionary.square_image,
          business_name: all.business_name,
          business_id: all.business_id,
          business_delivery_charges:
            all.business_detail_web_dictionary.delivery_charges,
          business_platform_fee:
            all.business_detail_web_dictionary.platform_fee,
          business_total_charges:
            all.business_detail_web_dictionary.total_charges,
          business_tax: all.business_detail_web_dictionary.tax_percentage,
          product_id: this.cartPopDataWishlist.id,
          product_name: this.variantData[i].product_name,
          product_image: this.variantData[i].image,
          variant_id: this.variantData[i].id,
          variant_name: this.variantData[i].option_name,
          variant_value: this.variantData[i].option_value,
          price: this.variantData[i].price,
          sale_price: this.variantData[i].sale_price,
          is_on_sale: this.variantData[i].is_on_sale,
          quantity: 1,
          addCartBtnShow: true,
          outlet_address: this.outletAddress,
          hasModifier: this.variantData[i].has_modifier,
          uniqueId: "",
          modifiers: [],
          addons: [],
          noOption: [],
        });
        continue;
      }

      if (
        this.cartDataFromStorage !== null &&
        this.checkObject(this.cartDataFromStorage, this.variantData[i]) === true
      ) {
        for (let j = 0; j < this.cartDataFromStorage.length; j++) {
          if (
            all.business_id === this.cartDataFromStorage[j].business_id &&
            this.cartPopDataWishlist.id ===
              this.cartDataFromStorage[j].product_id &&
            this.variantData[i].id === this.cartDataFromStorage[j].variant_id
          ) {
            this.cartFinalWishlist.push({
              business_image: all.business_detail_web_dictionary.square_image,
              business_name: all.business_name,
              business_id: all.business_id,
              business_delivery_charges:
                all.business_detail_web_dictionary.delivery_charges,
              business_platform_fee:
                all.business_detail_web_dictionary.platform_fee,
              business_total_charges:
                all.business_detail_web_dictionary.total_charges,
              business_tax: all.business_detail_web_dictionary.tax_percentage,
              product_id: this.cartPopDataWishlist.id,
              product_name: this.variantData[i].product_name,
              product_image: this.variantData[i].image,
              variant_id: this.variantData[i].id,
              variant_name: this.variantData[i].option_name,
              variant_value: this.variantData[i].option_value,
              price: this.variantData[i].price,
              sale_price: this.variantData[i].sale_price,
              is_on_sale: this.variantData[i].is_on_sale,
              quantity: this.cartDataFromStorage[j].quantity,
              addCartBtnShow: false,
              outlet_address: this.outletAddress,
              hasModifier: this.variantData[i].has_modifier,
              uniqueId: "",
              modifiers: [],
              addons: [],
              noOption: [],
            });
          }
        }
      } else {
        this.cartFinalWishlist.push({
          business_image: all.business_detail_web_dictionary.square_image,
          business_name: all.business_name,
          business_id: all.business_id,
          business_delivery_charges:
            all.business_detail_web_dictionary.delivery_charges,
          business_platform_fee:
            all.business_detail_web_dictionary.platform_fee,
          business_total_charges:
            all.business_detail_web_dictionary.total_charges,
          business_tax: all.business_detail_web_dictionary.tax_percentage,
          product_id: this.cartPopDataWishlist.id,
          product_name: this.variantData[i].product_name,
          product_image: this.variantData[i].image,
          variant_id: this.variantData[i].id,
          variant_name: this.variantData[i].option_name,
          variant_value: this.variantData[i].option_value,
          price: this.variantData[i].price,
          sale_price: this.variantData[i].sale_price,
          is_on_sale: this.variantData[i].is_on_sale,
          quantity: 1,
          addCartBtnShow: true,
          outlet_address: this.outletAddress,
          hasModifier: this.variantData[i].has_modifier,
          uniqueId: "",
          modifiers: [],
          addons: [],
          noOption: [],
        });
      }
    }
    //this.changeRef.detectChanges();
    if (val === 1) {
      // if (this.businessStatus === 'Closed') {
      //   $('#weAreClosedWishlist').modal('show');
      //   return;
      // } else {
      localStorage.setItem(
        "paymentMethod",
        all.business_detail_web_dictionary["payment_through_card"]
      );
      localStorage.setItem(
        "paymentThroughCard",
        all.business_detail_web_dictionary["payment_through_card"]
      );
      localStorage.setItem(
        "paymentThroughCash",
        all.business_detail_web_dictionary["payment_through_cash"]
      );
      localStorage.setItem(
        "paymentThroughBankTransfer",
        all.business_detail_web_dictionary["payment_through_bank_transfer"]
      );
      localStorage.setItem(
        "paymentThroughMpos",
        all.business_detail_web_dictionary["payment_through_mpos"]
      );
      localStorage.setItem(
        "paymentOverDue",
        all.business_detail_web_dictionary["payment_over_due"]
      );
      //this.changeRef.detectChanges();
      setTimeout(() => {
        $("#addCartPopWishlist").modal("show");
      }, 500);
      // }
    }
    //this.changeRef.detectChanges();
  }
  addCartfromshortPopWishList(all, obj, val) {
    this.addCartPopOpenWishList(all, obj, val);
    // $('.addCartShort').css('-webkit-filter', 'blur(3px)');
    // $('.addCartShort').css('-moz-filter', 'blur(3px)');
    // $('.addCartShort').css('-o-filter', 'blur(3px)');
    // $('.addCartShort').css('-ms-filter', 'blur(3px)');
    // $('.addCartShort').css('filter', 'blur(3px)');
  }
  async quantityPlusWishlistItem(index, quantity, obj) {
    this.cartDataFromStorage = JSON.parse(localStorage.getItem("cartItems"));
    this.cartFinalWishlist[index].quantity = quantity + 1;
    for (let i = 0; i < this.cartDataFromStorage.length; i++) {
      if (
        this.cartPopDataAll.business_id ===
          this.cartDataFromStorage[i].business_id &&
        this.cartPopDataWishlist.id ===
          this.cartDataFromStorage[i].product_id &&
        obj.variant_id === this.cartDataFromStorage[i].variant_id
      ) {
        this.cartDataFromStorage[i].quantity =
          this.cartFinalWishlist[index].quantity;
        this.events.publish("cartUpdate", this.cartDataFromStorage);
      }
    }

    const cartData = JSON.parse(localStorage.getItem("cartItems"));
    for (var i = 0; i < this.wishlistData.length; i++) {
      for (var j = 0; j < this.wishlistData[i].product.length; j++) {
        this.wishlistData[i].product[j].count = 0;
        for (
          var k = 0;
          k < this.wishlistData[i].product[j].variant.length;
          k++
        ) {
          for (var l = 0; l < cartData.length; l++) {
            if (
              cartData[l].variant_id ===
              this.wishlistData[i].product[j].variant[k].id
            ) {
              this.wishlistData[i].product[j].count += cartData[l].quantity;
            }
          }
        }
      }
    }
  }

  async quantityMinusWishlistItem(index, quantity, obj) {
    this.cartDataFromStorage = JSON.parse(localStorage.getItem("cartItems"));
    this.cartFinalWishlist[index].quantity = quantity - 1;
    for (let i = 0; i < this.cartDataFromStorage.length; i++) {
      if (
        this.cartPopDataAll.business_id ===
          this.cartDataFromStorage[i].business_id &&
        this.cartPopDataWishlist.id ===
          this.cartDataFromStorage[i].product_id &&
        obj.variant_id === this.cartDataFromStorage[i].variant_id
      ) {
        if (this.cartFinalWishlist[index].quantity < 1) {
          this.cartDataFromStorage.splice(i, 1);
          this.events.publish("cartUpdate", this.cartDataFromStorage);
        } else {
          this.cartDataFromStorage[i].quantity =
            this.cartFinalWishlist[index].quantity;
          this.events.publish("cartUpdate", this.cartDataFromStorage);
        }
      }
    }
    if (this.cartFinalWishlist[index].quantity < 1) {
      this.cartFinalWishlist[index].quantity = 1;
      this.cartFinalWishlist[index].addCartBtnShow = true;
    }

    const cartData = JSON.parse(localStorage.getItem("cartItems"));
    for (var i = 0; i < this.wishlistData.length; i++) {
      for (var j = 0; j < this.wishlistData[i].product.length; j++) {
        this.wishlistData[i].product[j].count = 0;
        for (
          var k = 0;
          k < this.wishlistData[i].product[j].variant.length;
          k++
        ) {
          for (var l = 0; l < cartData.length; l++) {
            if (
              cartData[l].variant_id ===
              this.wishlistData[i].product[j].variant[k].id
            ) {
              this.wishlistData[i].product[j].count += cartData[l].quantity;
            }
          }
        }
      }
    }
  }
  async addToCartWishlist(obj, index) {
    if (obj.hasModifier == true) {
      this.objToOveride = obj;
      this.indexToOveride = index;
      let cartDataNewChk = JSON.parse(localStorage.getItem("cartItems"));
      console.log(cartDataNewChk);
      if (cartDataNewChk !== null) {
        if (cartDataNewChk.length > 0) {
          if (obj.business_id !== cartDataNewChk[0].business_id) {
            $("#onOnleBusinessCartWishlist").modal("show");
            this.disableBackground();

            return;
          }
        }
      }
      this.selectedVariant = index;
      this.dataService.getVariantDetail(obj.variant_id).subscribe(
        (res) => {
          console.log(res);
          let modifierObj = {
            quantityTotal: 1,
            name: obj.product_name,
            image: obj.product_image,
            variantName: obj.variant_name,
            optionValue: obj.variant_value,
            price: Math.round(obj.price),
            compare_price: Math.round(obj.compare_price),
            is_on_sale: obj.is_on_sale,
            variant_id: obj.variant_id,
            modifiers: res["modifiers"],
            addons: res["addons"],
            noOption: res["no_options"],
            error_msg: "",
          };
          this.productModifierDataArray = new Array();
          this.productModifierDataArray.push(modifierObj);
          this.newCart = this.cartFinalWishlist;
          // this.newCart[this.selectedVariant].modifiers = [];
          this.modModifiers = [];
          for (
            let i = 0;
            i < this.productModifierDataArray[0].modifiers.length;
            i++
          ) {
            // for(let j = 0; j < this.productModifierDataArray[0].modifiers[i].options.length; j++) {
            this.productModifierDataArray[0].modifiers[i].options[0].selected =
              true;
            let objSelected = {};
            objSelected = {
              id: this.productModifierDataArray[0].modifiers[i].id,
              title: this.productModifierDataArray[0].modifiers[i].title,
              options: this.productModifierDataArray[0].modifiers[i].options[0],
            };
            // this.newCart[this.selectedVariant].modifiers.push(objSelected);
            this.modModifiers.push(objSelected);
            // }
          }
          if (
            this.productModifierDataArray[0].noOption != undefined &&
            this.productModifierDataArray[0].noOption != null &&
            this.productModifierDataArray[0].noOption.options != null
          ) {
            for (
              let i = 0;
              i < this.productModifierDataArray[0].noOption.options.length;
              i++
            ) {
              this.productModifierDataArray[0].noOption.options[i].selected =
                true;
            }
          }
          this.totalPriceCalculation();
        },
        (err) => console.log(err)
      );
      this.fromWishlist = true;
      $("#addVariantsModifiersPopUp").modal("show");
    } else {
      if (
        this.cartPopDataAll.business_detail_web_dictionary.payment_over_due ===
        true
      ) {
        $("#onOnleOverDueWishlist").modal("show");
        return;
      }
      // if (this.businessStatus === 'Closed') {
      //   $('#weAreClosedWishlist').modal('show');
      //   return;
      // }
      this.objToOveride = obj;
      this.indexToOveride = index;
      let cartDataNewChk = JSON.parse(localStorage.getItem("cartItems"));
      if (cartDataNewChk !== null) {
        if (cartDataNewChk.length > 0) {
          if (obj.business_name !== cartDataNewChk[0].business_id) {
            $("#onOnleBusinessCartWishlist").modal("show");
            return;
          }
        }
      }

      const objToPass = obj;
      this.cartFinalWishlist[index].addCartBtnShow = false;
      if (
        this.cartDataFromStorage === null ||
        this.cartDataFromStorage === undefined
      ) {
        this.cartDataFromStorage = [];
      }
      this.cartDataFromStorage.push(objToPass);
      this.events.publish("cartUpdate", this.cartDataFromStorage);
      const cartData = JSON.parse(localStorage.getItem("cartItems"));
      for (var i = 0; i < this.wishlistData.length; i++) {
        for (var j = 0; j < this.wishlistData[i].product.length; j++) {
          this.wishlistData[i].product[j].count = 0;
          for (
            var k = 0;
            k < this.wishlistData[i].product[j].variant.length;
            k++
          ) {
            for (var l = 0; l < cartData.length; l++) {
              if (
                cartData[l].variant_id ===
                this.wishlistData[i].product[j].variant[k].id
              ) {
                this.wishlistData[i].product[j].count += cartData[l].quantity;
              }
            }
          }
        }
      }
    }
    localStorage.setItem(
      "paymentMethod",
      this.cartPopDataAll.business_detail_web_dictionary.payment_through_card
    );
    localStorage.setItem(
      "paymentThroughCard",
      this.cartPopDataAll.business_detail_web_dictionary.payment_through_card
    );
    localStorage.setItem(
      "paymentThroughCash",
      this.cartPopDataAll.business_detail_web_dictionary.payment_through_cash
    );
    localStorage.setItem(
      "paymentThroughBankTransfer",
      this.businessDetailData.payment_through_bank_transfer
    );
    localStorage.setItem(
      "paymentThroughMpos",
      this.businessDetailData.payment_through_mpos
    );
    localStorage.setItem(
      "paymentOverDue",
      this.cartPopDataAll.business_detail_web_dictionary.payment_over_due
    );
  }
  addToCartOnExistingWishlist() {
    this.disableMultiClick = true;
    this.cartDataFromStorage = [];
    this.events.publish("cartUpdate", this.cartDataFromStorage);
    localStorage.removeItem("cartItems");
    setTimeout(() => {
      this.addToCartWishlist(this.objToOveride, this.indexToOveride);
      $("#onOnleBusinessCartWishlist").modal("hide");
      this.disableMultiClick = false;
    }, 1000);
  }
  searchWishlist() {
    clearInterval(this.wishlistItemTimeOut);
    this.wishlistSearchItem = true;
    $(".disableButton").css("display", "none");
    this.wishlistItemTimeOut = setInterval(() => {
      this.wishlistFunction();
    }, 1000);
  }
  clearWishlistSearch() {
    this.paginationPageWishList = 0;
    this.wishlistSearchString = "";
    this.wishlistSearchItem = true;
    clearInterval(this.wishlistItemTimeOut);
    this.wishlistFunction();
  }

  callwishlistFunction() {
    this.paginationPageWishList = 0;
    this.userFrom = this.dataService.userFrom;
    this.showWishlistSearch = false;
    this.wishlistSearchString = "";
    this.wishlistSearchItem = true;
    clearInterval(this.wishlistItemTimeOut);
    this.wishlistSearchItem = true;
    this.closeNav();
    this.disableBackground();
    $(".disableButton").css("display", "none");
    $("#wishlistPopup").modal("show");
    this.dataService
      .getWishList(
        localStorage.getItem("token"),
        this.limitWishList,
        this.paginationPageWishList,
        this.wishlistSearchString,
        this.dataService.businessIdForIndependentBusinessData
      )
      .subscribe(
        (data) => {
          this.wishlistData = data;
          this.wishlistDataLastPage = this.wishlistData.last_page;
          // this.paginationPageWishList++;
          this.wishlistData = this.wishlistData.items;
          $(".disableButton").css("display", "block");
          this.wishlistSearchItem = false;
          const cartData = JSON.parse(localStorage.getItem("cartItems"));
          if (this.wishlistData.length > 0) {
            this.showWishlistSearch = true;
          } else {
            this.showWishlistSearch = false;
          }
          for (var i = 0; i < this.wishlistData.length; i++) {
            for (var j = 0; j < this.wishlistData[i].product.length; j++) {
              this.wishlistData[i].product[j].count = 0;
            }
          }
          if (cartData !== null && cartData !== undefined) {
            for (var i = 0; i < this.wishlistData.length; i++) {
              for (var j = 0; j < this.wishlistData[i].product.length; j++) {
                for (
                  var k = 0;
                  k < this.wishlistData[i].product[j].variant.length;
                  k++
                ) {
                  for (var l = 0; l < cartData.length; l++) {
                    if (
                      cartData[l].variant_id ===
                      this.wishlistData[i].product[j].variant[k].id
                    ) {
                      this.wishlistData[i].product[j].count +=
                        cartData[l].quantity;
                    }
                  }
                }
              }
            }
          }
          setTimeout(() => {}, 500);
        },
        (error) => {}
      );
  }

  callwishlistFunctionByPage() {
    this.paginationPageWishList++;
    this.userFrom = this.dataService.userFrom;
    this.showWishlistSearch = false;
    clearInterval(this.wishlistItemTimeOut);
    this.closeNav();
    this.disableBackground();
    $("#wishlistPopup").modal("show");
    this.dataService
      .getWishList(
        localStorage.getItem("token"),
        this.limitWishList,
        this.paginationPageWishList,
        this.wishlistSearchString,
        this.dataService.businessIdForIndependentBusinessData
      )
      .subscribe(
        (data) => {
          this.wishListLoadMore = false;
          this.wishlistDataByPage = data;
          this.wishlistDataLastPage = this.wishlistDataByPage.last_page;
          this.wishlistDataByPage = this.wishlistDataByPage.items;
          const cartData = JSON.parse(localStorage.getItem("cartItems"));
          if (this.wishlistData.length > 0) {
            this.showWishlistSearch = true;
          } else {
            this.showWishlistSearch = false;
          }
          for (var i = 0; i < this.wishlistDataByPage.length; i++) {
            for (
              var j = 0;
              j < this.wishlistDataByPage[i].product.length;
              j++
            ) {
              this.wishlistDataByPage[i].product[j].count = 0;
            }
          }
          if (cartData !== null && cartData !== undefined) {
            for (var i = 0; i < this.wishlistDataByPage.length; i++) {
              for (
                var j = 0;
                j < this.wishlistDataByPage[i].product.length;
                j++
              ) {
                for (
                  var k = 0;
                  k < this.wishlistDataByPage[i].product[j].variant.length;
                  k++
                ) {
                  for (var l = 0; l < cartData.length; l++) {
                    if (
                      cartData[l].variant_id ===
                      this.wishlistDataByPage[i].product[j].variant[k].id
                    ) {
                      this.wishlistDataByPage[i].product[j].count +=
                        cartData[l].quantity;
                    }
                  }
                }
              }
            }
          }

          for (var k = 0; k < this.wishlistDataByPage.length; k++) {
            this.wishlistData.push(this.wishlistDataByPage[k]);
          }

          setTimeout(() => {}, 500);
        },
        (error) => {}
      );
  }

  clearBlur() {
    $(".addCartShort").css("-webkit-filter", "blur(0px)");
    $(".addCartShort").css("-moz-filter", "blur(0px)");
    $(".addCartShort").css("-o-filter", "blur(0px)");
    $(".addCartShort").css("-ms-filter", "blur(0px)");
    $(".addCartShort").css("filter", "blur(0px)");
  }
  addBlur() {
    $(".addCartShort").css("-webkit-filter", "blur(3px)");
    $(".addCartShort").css("-moz-filter", "blur(3px)");
    $(".addCartShort").css("-o-filter", "blur(3px)");
    $(".addCartShort").css("-ms-filter", "blur(3px)");
    $(".addCartShort").css("filter", "blur(3px)");
  }

  openNav() {
    this.userFrom = this.dataService.userFrom;
    if (this.userFrom === "store") {
      this.businessLandingaliasName = this.businessLandingData.alias;
    }
    // $('#mySidenav').css('width', '250px');
    $("#mySidenav").css("left", "0");
  }
  closeNav() {
    $("#mySidenav").css("left", "-251px");
  }
  //     getWidth() {
  //     var windowWidth =  $(window).width();
  //     return windowWidth;
  // }
  refrerChk() {
    // var host = window.location.host;
    // var req = new XMLHttpRequest();
    // req.open('GET', document.location, false);
    // req.send(null);
    // var headers = req.getAllResponseHeaders().toLowerCase();
    // alert(headers);
    // var referrer = request.getHeader("referer");
  }
  openBuisnessPage(name, router) {
    $("#headAddress").modal("hide");
    localStorage.setItem("delivery_address", name);
    localStorage.setItem("myDeliveryAddress", name);

    this.router.navigate(["/businesses", router]);
  }

  getAreaList() {
    this.isAreasLoaded = false;
    this.nao.go(30);
    this.dataService.validateCity(this.zip).subscribe(
      (data) => {
        this.nao.go(100);
        this.areaData = data;
        this.areaData = this.areaData.city_list;
        this.isAreasLoaded = true;
        setTimeout(() => {
          this.loadingData = false;
        }, 3000);
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }
  gotoHome() {
    if (
      window.location.hostname.includes("businessweb.bestbuymall.co") ||
      window.location.hostname.includes(Constants.domainNmae)
    ) {
      this.router.navigate([""]);
    } else if (
      window.location.hostname.includes("bestbuymall.co") ||
      window.location.hostname.includes("bestbuymall.co") ||
      window.location.hostname.includes("dev.bestbuymall.co") ||
      window.location.hostname.includes("dev.bestbuymall.co") ||
      window.location.hostname.includes("localhost") ||
      window.location.hostname.includes("bbm.com.pk")
    ) {
      console.log(this.businessLandingData);
      this.businessLandingaliasName = this.businessLandingData.alias;
      if (this.businessLandingaliasName === undefined) {
        return;
      }
      this.router.navigate(["/", this.businessLandingaliasName]);
    } else {
      this.router.navigate([""]);
    }
  }
  closeFinalCheckOutPopup() {
    this.clearBlur();
    $("#FinalCheckOutPopup").modal("hide");
  }

  browseButton() {
    $("#chkoutPop").modal("hide");
    $("#pendingOnlinePayment").modal("hide");
    $("#confirmOnlinePay").modal("hide");
    this.OrderSubmitted = false;
  }

  getSlotDataForOutlet() {
    this.slotSuccess = false;
    this.slotSuccessMessage = "";
    this.time = null;
    this.timeSlotDetail_delivery_show = [];
    this.dayOfGivenTime = "";
    this.overnight = "";
    this.timeSlotDetail = null;
    this.dayIndex = null;
    this.outletIdToSendInCheckout = null;
    this.third_party_string = "";
    this.disabledBtnForLoadingSlotData = false;

    var date = new Date();
    this.current_day = date.getDate();
    this.checkoutErrorMessage = "";
    this.checkoutFinalErrorMessagePriceUpdate = "";
    this.checkoutFinalErrorMessageOutOfStock = "";
    this.checkoutFinalErrorCount = 0;
    // if (localStorage.getItem('token') === null) {
    //   return;
    // }
    if (
      localStorage.getItem("profileAddressCheckout") !== "null" &&
      localStorage.getItem("profileAddressCheckout") !== null
    ) {
      this.showAddressInFrontOfWagonIcon = false;
    } else {
      this.showAddressInFrontOfWagonIcon = true;
    }
    this.breakdownCharges = 0;
    this.breakdownChargesDis = 0;

    var dayForTime = new Date();
    var currentSystemTime = new Date(dayForTime).toISOString();
    var todayData = this.todayData;
    if (this.todayData === 0) {
      todayData = 7;
    }
    
    this.checkoutButtonDisable = true;
    this.timeSlotDetailOrignal = "";
    console.log(
      "getting slots for delivery address: =>",
      this.deliveryAddressObj
    );
    this.dataService
      .get_slot_timing_of_outlet_before_check_out(
        localStorage.getItem("token"),
        this.customArray,
        this.deliveryAddressObj,
        this.dataService.businessIdForIndependentBusinessData,
        todayData,
        currentSystemTime
      )
      .subscribe(
        (data: any) => {
          console.log(data["success"]);
          this.timeSlotDetailOrignal = data;
          if (!data.rest_error_code) {
            this.timeSlotDetail = data;
            this.outOfStockChkData = data;
            this.slotSuccess = this.outOfStockChkData;

            if (this.slotSuccess.success === false) {
              if (
                this.slotSuccess.message ===
                "We cannot deliver in your area yet"
              ) {
                this.slotSuccessMessage =
                  this.slotSuccess.message_with_business;
                this.checkoutButtonDisable = true;
                return;
              }
            }
            this.checkoutButtonDisable = false;

            this.outletIdToSendInCheckout = this.timeSlotDetail.outlet_id;
            this.timeSlotDetail = this.timeSlotDetail.detail_dictionary;
            this.timeSlotDetailOrignal =
              this.timeSlotDetailOrignal.detail_dictionary;
            // this.timeslotIndex = this.timeSlotDetail.delivery_slot_list.index;
            if (this.timeSlotDetail !== undefined) {
              if (this.timeSlotDetail.over_night_charges !== undefined) {
                this.over_night_charges =
                  this.timeSlotDetail.over_night_charges;
              }
              if (this.timeSlotDetail.days_charges !== undefined) {
                this.days_charges = this.timeSlotDetail.days_charges;
              }

              this.timeSlotDetail_delivery =
                this.timeSlotDetail.delivery_slot_list;
              this.businessTypeFromSlotRequest =
                this.timeSlotDetail.business_type;
            }
            this.timeSlotDetail_delivery_show = [];
            if (this.timeSlotDetail_delivery !== undefined) {
              for (var i = 0; i < this.timeSlotDetail_delivery.length; i++) {
                if (this.timeSlotDetail_delivery[i].slots.length > 0) {
                  this.timeSlotDetail_delivery_show.push(
                    this.timeSlotDetail_delivery[i]
                  );
                }
              }
            }
            for (var i = 0; i < this.timeSlotDetail_delivery_show.length; i++) {
              for (
                var j = 0;
                j < this.timeSlotDetail_delivery_show[i].slots.length;
                j++
              ) {
                var cDate = new Date().getDate();
                var cYear = new Date().getFullYear();
                var cMonth = new Date().getUTCMonth() + 1;
                var openTime =
                  this.timeSlotDetail_delivery_show[i].slots[j].open_time;
                var tOpen =
                  cYear +
                  "/" +
                  cMonth +
                  "/" +
                  cDate +
                  " " +
                  openTime +
                  " " +
                  "UTC";
                var dateObjOpen = new Date(tOpen);
                this.timeSlotDetail_delivery_show[i].slots[j].open_time =
                  dateObjOpen;

                var closeTime =
                  this.timeSlotDetail_delivery_show[i].slots[j].close_time;
                var tClose =
                  cYear +
                  "/" +
                  cMonth +
                  "/" +
                  cDate +
                  " " +
                  closeTime +
                  " " +
                  "UTC";
                var dateObjClose = new Date(tClose);
                this.timeSlotDetail_delivery_show[i].slots[j].close_time =
                  dateObjClose;
              }
            }

            var defaultTimeslot = false;

            // Select the first available timeslot
            this.timeSlotDetail_delivery_show.forEach((data, i) => {
              if (defaultTimeslot) return;
              data.slots.forEach((element, j) => {
                if (defaultTimeslot) return;
                if (element.is_available) {
                  this.addDayAndTime(data.day, data.index, element, j + "" + i);
                  defaultTimeslot = true;
                  return;
                }
              });
            });

            var getCartItems = JSON.parse(localStorage.getItem("cartItems"));
            if (getCartItems !== null && getCartItems.length !== 0) {
              if (this.timeSlotDetailOrignal !== undefined) {
                getCartItems[0].business_tax =
                  this.timeSlotDetailOrignal.tax_rate;
              }
              this.businessTax = getCartItems[0].business_tax;
              this.events.publish("cartUpdate", getCartItems);
            }

            console.log(
              "############################### Get Slot Data Outlet 1 #################################",
              data
            );
            // this.updateFinalCartPop();

            if (this.outOfStockChkData.out_of_stock === true) {
              if (getCartItems !== null) {
                for (
                  let a = 0;
                  a < this.outOfStockChkData.out_of_stock_list.length;
                  a++
                ) {
                  for (let b = 0; b < this.customArray.length; b++) {
                    if (
                      this.customArray[b].dispensary_id ===
                      this.outOfStockChkData.out_of_stock_list[a].dispensary_id
                    ) {
                      for (
                        let c = 0;
                        c <
                        this.outOfStockChkData.out_of_stock_list[a].product
                          .length;
                        c++
                      ) {
                        for (
                          let d = 0;
                          d < this.customArray[b].products.length;
                          d++
                        ) {
                          if (
                            this.customArray[b].products[d].product_id ===
                            this.outOfStockChkData.out_of_stock_list[a].product[
                              c
                            ].product_id
                          ) {
                            if (
                              this.outOfStockChkData.out_of_stock_list[a]
                                .product[c].available_quantity === 0
                            ) {
                              for (var s = 0; s < getCartItems.length; s++) {
                                if (
                                  this.customArray[b].products[d].product_id ===
                                    this.outOfStockChkData.out_of_stock_list[a]
                                      .product[c].product_id &&
                                  this.customArray[b].products[d].variant_id ===
                                    this.outOfStockChkData.out_of_stock_list[a]
                                      .product[c].variant_id
                                ) {
                                  if (
                                    getCartItems[s].product_id ===
                                      this.customArray[b].products[d]
                                        .product_id &&
                                    getCartItems[s].variant_id ===
                                      this.customArray[b].products[d].variant_id
                                  ) {
                                    getCartItems.splice(s, 1);
                                  }
                                }
                              }
                            } else {
                              if (
                                this.outOfStockChkData.out_of_stock_list[a]
                                  .product[c].available_quantity <
                                this.customArray[b].products[d].quantity
                              ) {
                                this.customArray[b].products[d].quantity =
                                  this.outOfStockChkData.out_of_stock_list[
                                    a
                                  ].product[c].available_quantity;
                                for (var l = 0; l < getCartItems.length; l++) {
                                  if (
                                    getCartItems[l].product_id ===
                                      this.customArray[b].products[d]
                                        .product_id &&
                                    getCartItems[l].variant_id ===
                                      this.customArray[b].products[b].variant_id
                                  ) {
                                    getCartItems[l].quantity =
                                      this.outOfStockChkData.out_of_stock_list[
                                        a
                                      ].product[c].available_quantity;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              setTimeout(() => {
                console.log(
                  "############################### Get Slot Data Outlet 2 #################################"
                );
                // this.updateFinalCartPop();
              }, 1000);
              this.events.publish("cartUpdate", getCartItems);
              if (
                this.outOfStockChkData.out_of_stock_list[0].product.length === 1
              ) {
                console.log(
                  "############################### Get Slot Data Outlet 3 #################################"
                );
                // this.updateFinalCartPop();
                this.slotsChkStockError =
                  "We have run out of " +
                  this.outOfStockChkData.out_of_stock_list[0].product[0]
                    .product_variant_name +
                  ". Hence, we have removed that item from your cart.";
              } else {
                console.log(
                  "############################### Get Slot Data Outlet 4 #################################"
                );
                // this.updateFinalCartPop();
                this.slotsChkStockError =
                  "We have run out of few items. Hence, we have removed those items from your cart.";
              }
              $("#updateStockPop").modal("show");
            }

            getCartItems = JSON.parse(localStorage.getItem("cartItems"));
            console.log("Custom Array", this.customArray);
            if (this.outOfStockChkData.product_variant_price_update === true) {
              if (getCartItems !== null) {
                for (
                  let a = 0;
                  a < this.outOfStockChkData.out_of_stock_list.length;
                  a++
                ) {
                  for (
                    let c = 0;
                    c <
                    this.outOfStockChkData.out_of_stock_list[a].product.length;
                    c++
                  ) {
                    if (
                      this.outOfStockChkData.out_of_stock_list[a].product[c]
                        .price_updated === true
                    ) {
                      for (var l = 0; l < getCartItems.length; l++) {
                        if (
                          getCartItems[l].product_id ===
                          this.outOfStockChkData.out_of_stock_list[a].product[c]
                            .product_id
                        ) {
                          getCartItems[l].price =
                            this.outOfStockChkData.out_of_stock_list[a].product[
                              c
                            ].updated_price;
                          getCartItems[l].sale_price =
                            this.outOfStockChkData.out_of_stock_list[a].product[
                              c
                            ].compare_price;
                          getCartItems[l].is_on_sale =
                            this.outOfStockChkData.out_of_stock_list[a].product[
                              c
                            ].is_on_sale;
                          this.events.publish("cartUpdate", getCartItems);
                        }
                      }
                    }
                  }
                }
              }

              console.log(
                "############################### Get Slot Data Outlet 5 #################################"
              );
              // this.updateFinalCartPop();
              this.slotsChkStockError = "There are some price update.";

              $("#updateStockPop").modal("show");
            }
            if (
              this.outOfStockChkData.product_variant_price_update === true &&
              this.outOfStockChkData.out_of_stock === true
            ) {
              this.slotsChkStockError =
                "We have run out of few items. Hence, we have removed those items from your cart. There is alse price update";
            }

            getCartItems = JSON.parse(localStorage.getItem("cartItems"));
            if (getCartItems !== null) {
              if (getCartItems.length === 0) {
                $("#chkoutPop").modal("hide");
              }
            }
            if (this.businessTypeFromSlotRequest === 1) {
              this.timeSlotDetail_delivery_charges = 0;

              if (this.timeSlotDetail !== undefined) {
                this.breakdownCharges =
                  this.timeSlotDetail.delivery_rate_charges_calculated;
                this.breakdownChargesDis =
                  this.timeSlotDetail.off_price_subscribed;
                this.timeSlotDetail_delivery_charges =
                  this.timeSlotDetail.delivery_rate_charges;
                this.timeSlotDetail_delivery_charges_actual =
                  this.timeSlotDetail.delivery_rate_charges_actual;
              }

              // console.log('@#%#$%#$%#$%#$%$%#$%#$%#$%#$%#$@#%#$%#$%#$%', this.delivery == true && (this.paymentMethodOptionValue == 1 || this.paymentMethodOptionValue == 2 || this.paymentMethodOptionValue == 3));

              if (
                this.customArray !== undefined &&
                this.customArray.length !== 0
              ) {
                if (
                  this.delivery == true &&
                  this.paymentMethodOptionValue == 0
                ) {
                  this.customArray[0].delivery_charges =
                    this.timeSlotDetail_delivery_charges;
                  this.customArray[0].total_price = Math.round(
                    this.customArray[0].sub_total +
                      this.customArray[0].sub_total *
                        this.customArray[0].fix_tax_charges +
                      this.timeSlotDetail_delivery_charges +
                      this.customArray[0].platform_fee +
                      this.customArray[0].gstCashPrice
                  );
                } else if (
                  this.delivery == true &&
                  (this.paymentMethodOptionValue == 1 ||
                    this.paymentMethodOptionValue == 2 ||
                    this.paymentMethodOptionValue == 3)
                ) {
                  this.customArray[0].delivery_charges =
                    this.timeSlotDetail_delivery_charges;
                  this.customArray[0].total_price = Math.round(
                    this.customArray[0].sub_total +
                      this.customArray[0].sub_total *
                        this.customArray[0].fix_tax_charges +
                      this.timeSlotDetail_delivery_charges +
                      this.customArray[0].platform_fee +
                      this.customArray[0].gstCardPrice
                  );
                } else if (this.dineIn == true || this.selfPickup == true) {
                  this.customArray[0].delivery_charges = 0;
                  this.timeSlotDetail_delivery_charges = 0;

                  if (this.dineinPayment == "cash") {
                    this.customArray[0].total_price = Math.round(
                      this.customArray[0].sub_total +
                        this.customArray[0].gstCashPrice
                    );
                  } else if (this.dineinPayment == "card") {
                    this.customArray[0].total_price = Math.round(
                      this.customArray[0].sub_total +
                        this.customArray[0].gstCardPrice
                    );
                  }
                }
              }
            } else {
              if (this.timeSlotDetail !== undefined) {
                this.breakdownChargesDis =
                  this.timeSlotDetail.off_price_subscribed;
              }
              this.timeSlotDetail_delivery_charges = 0;
              // if (this.customArray !== undefined && this.customArray.length !== 0) {
              //   this.customArray[0].delivery_charges = this.timeSlotDetail_delivery_charges;
              //   this.customArray[0].total_price = Math.round(this.customArray[0].sub_total + (this.customArray[0].sub_total * this.customArray[0].fix_tax_charges) + this.timeSlotDetail_delivery_charges + this.customArray[0].platform_fee);
              // }
              // console.log('asdasdasdadasdasdasdasdasdasdasdasdasdd', this.delivery, this.dineIn, this.selfPickup)
              if (this.delivery == true) {
                this.customArray[0].delivery_charges =
                  this.timeSlotDetail_delivery_charges;
                this.customArray[0].total_price = Math.round(
                  this.customArray[0].sub_total +
                    this.customArray[0].sub_total *
                      this.customArray[0].fix_tax_charges +
                    this.timeSlotDetail_delivery_charges +
                    this.customArray[0].platform_fee +
                    this.customArray[0].gstCashPrice
                );
              } else if (this.dineIn == true || this.selfPickup == true) {
                this.customArray[0].delivery_charges = 0;
                this.timeSlotDetail_delivery_charges = 0;

                if (this.dineinPayment == "cash") {
                  this.customArray[0].total_price = Math.round(
                    this.customArray[0].sub_total +
                      this.customArray[0].gstCashPrice
                  );
                } else if (this.dineinPayment == "card") {
                  this.customArray[0].total_price = Math.round(
                    this.customArray[0].sub_total +
                      this.customArray[0].gstCardPrice
                  );
                }
              }
            }
            this.chkOutTotal = 0;
            if (this.customArray !== undefined) {
              for (let t = 0; t < this.customArray.length; t++) {
                this.chkOutTotal =
                  this.chkOutTotal + this.customArray[t].total_price;
              }
            }

            if (this.timeSlotDetail !== undefined) {
              this.timeSlotDetail = this.timeSlotDetail.business_type;
            }
            console.log(
              "@#%#$%#$%#$%#$%$%#$%#$%#$%#$%#$@#%#$%#$%#$%",
              this.timeSlotDetail_delivery_charges,
              this.timeSlotDetail
            );
            this.updateFinalCartPop();
          } else {
            if (data["success"] == false) {
              toastr.error(this.timeSlotDetailOrignal.message);
              return;
            }
          }
        },
        (error) => {}
      );
  }

  showTimeSlotPop() {
    $("#timeSlotPop").modal("show");
  }

  addDayAndTime(day, dayIndex, time, index) {
    if (time.is_available === false) {
      toastr.error("This slot is not available");
      return;
    }
    $(".clearSlotSelection").removeClass("selectedSlot");
    setTimeout(() => {
      $("#selectedSlot" + index).addClass("selectedSlot");
    }, 100);
    this.dayOfGivenTime = day;
    this.dayIndex = dayIndex;
    this.time = time;
  }

  OvernightFunction(type, charges) {
    if (type === "night") {
      this.breakdownCharges =
        this.timeSlotDetailOrignal.over_night_charges_calculated;
      this.overnight = "Overnight (£ " + this.over_night_charges + ")";
      this.third_party_string = "Overnight";
      this.timeSlotDetail_delivery_charges = Math.round(
        this.over_night_charges
      );
      if (this.customArray !== undefined && this.customArray.length !== 0) {
        this.customArray[0].delivery_charges = Math.round(
          this.timeSlotDetail_delivery_charges
        );
        this.customArray[0].total_price = Math.round(
          this.customArray[0].sub_total +
            this.customArray[0].sub_total *
              this.customArray[0].fix_tax_charges +
            this.timeSlotDetail_delivery_charges +
            this.customArray[0].platform_fee
        );
      }
    }
    if (type === "day") {
      this.breakdownCharges =
        this.timeSlotDetailOrignal.days_charges_calculated;
      this.overnight = "3-5 Business Days (£ " + this.days_charges + ")";
      this.third_party_string = "3-5 Business Days";
      this.timeSlotDetail_delivery_charges = Math.round(this.days_charges);
      if (this.customArray !== undefined && this.customArray.length !== 0) {
        this.customArray[0].delivery_charges = Math.round(
          this.timeSlotDetail_delivery_charges
        );
        this.customArray[0].total_price = Math.round(
          this.customArray[0].sub_total +
            this.customArray[0].sub_total *
              this.customArray[0].fix_tax_charges +
            this.timeSlotDetail_delivery_charges +
            this.customArray[0].platform_fee
        );
      }
    }
  }

  clear_values() {
    this.time = null;
    this.timeSlotDetail_delivery_show = [];
    this.dayOfGivenTime = "";
    this.overnight = "";
    this.timeSlotDetail = null;
    this.dayIndex = null;
    this.outletIdToSendInCheckout = null;
    this.third_party_string = "";
  }

  callPackgesFunction() {
    if (localStorage.getItem("purchaseSubscription") === "true") {
      this.callPackgesPurchaseListing();
    } else {
      this.callPackgesListing();
    }
  }
  callPackgesPurchaseListing() {
    $("#packgesPopup").modal("hide");
    var dayForTime = new Date();
    var currentSystemTime = new Date(dayForTime).toISOString();
    this.dataService
      .getPurchaseDeliveryPlanPackges(
        localStorage.getItem("token"),
        this.deliveryPackageDataLimit,
        currentSystemTime
      )
      .subscribe(
        (data) => {
          this.subscriptionPurchaseData = data;
          this.subscriptionPurchaseData = this.subscriptionPurchaseData.items;
          $("#packgesSubscriptionListPopup").modal("show");
        },
        (error) => {}
      );
    setTimeout(() => {
      $(".purchaseSubscriptionPackages0").addClass("selectedPackage");
    }, 500);
  }
  addBgColorOfpurchasePackage(index) {
    $(".purchaseSubscriptionPackagesMain").removeClass("selectedPackage");
    if ($(".purchaseSubscriptionCollapse" + index).hasClass("in")) {
    } else {
      setTimeout(() => {
        $(".purchaseSubscriptionPackages" + index).addClass("selectedPackage");
      }, 100);
    }
  }

  callPackgesListing() {
    this.dataService
      .getDeliveryPlanPackges(
        localStorage.getItem("token"),
        this.deliveryPackageDataLimit
      )
      .subscribe(
        (data) => {
          this.deliveryPackageData = data;
          this.deliveryPackageData = this.deliveryPackageData.items;
          $("#packgesPopup").modal("show");
        },
        (error) => {}
      );
    setTimeout(() => {
      $(".SubscriptionPackages0").addClass("selectedPackage");
    }, 500);
  }
  addBgColorOfPackage(index) {
    $(".SubscriptionPackagesMain").removeClass("selectedPackage");
    if ($(".SubscriptionCollapse" + index).hasClass("in")) {
    } else {
      setTimeout(() => {
        $(".SubscriptionPackages" + index).addClass("selectedPackage");
      }, 100);
    }
  }

  calDeliveryPackagesPaymentFun(value) {
    this.deliveryPlanId = value;
    if (value.is_purchased_before === true) {
      $("#subscriptionBuyAlertPop").modal("show");
    } else {
      this.DeliveryPackagesPaymentFun();
    }
  }
  DeliveryPackagesPaymentFun() {
    $("#subscriptionBuyAlertPop").modal("hide");
    this.deliveryPlanmethod = -1;
    this.deliveryPlanPurchase = false;
    this.purchaseDeliveryPlanBtn = false;
    this.nao.go(30);
    this.dataService
      .getPaymentMethodlist(localStorage.getItem("token"))
      .subscribe(
        (data) => {
          this.nao.go(100);
          this.paymentCardData = data;
          this.paymentCardData = this.paymentCardData.Dictionary_Payment_List;
          for (let i = 0; i < this.paymentCardData.length; i++) {
            if (this.paymentCardData[i].primary === true) {
              this.deliveryPlanmethod = 1;
              this.primaryCardNo = this.paymentCardData[i].cardNumber;
            }
          }
          $("#DeliveryPlanPaymentPopup").modal("show");
          if (this.paymentCardData.length > 0) {
            $("#planWishCash").css("display", "none");
          } else {
            $("#planWishCash").css("display", "block");
            this.deliveryPlanmethod = 0;
          }
        },
        (error) => {
          this.nao.go(100);
        }
      );
  }
  purchasePlanUsingPaymentCard(id, index) {
    this.deliveryPlanmethod = 1;
    this.nao.go(30);
    this.dataService

      .makePaymentMethodPrimary(localStorage.getItem("token"), id)

      .subscribe(
        (data) => {
          
          this.nao.go(100);
          this.primaryDataSuccess = data;
          if (this.primaryDataSuccess.success === true) {
            for (let i = 0; i < this.paymentCardData.length; i++) {
              this.paymentCardData[i].primary = false;
            }
            this.paymentCardData[index].primary = true;
          } else {
            toastr.error("Something went wrong");
          }
          $("#planWishCash").css("display", "none");
        },
        (error) => {
          this.nao.go(100);
          toastr.error("Some thing went wrong");
        }
      );
  }
  purchasePlanUsingCash() {
    $("#planWishCash").css("display", "block");
    for (let i = 0; i < this.paymentCardData.length; i++) {
      this.paymentCardData[i].primary = false;
    }
    this.deliveryPlanmethod = 0;
  }

  openPackageConformationPopup() {
    if (this.deliveryPlanmethod === -1) {
      toastr.error("Please select payment method");
      return;
    } else {
      $("#purchasePackgePopup").modal("show");
      this.addBlur();
    }
  }
  purchasePackgePopupClose() {
    $("#purchasePackgePopup").modal("hide");
    this.clearBlur();
  }

  purchaseDeliveryPlan() {
    this.purchaseDeliveryPlanBtn = true;
    this.nao.go(30);
    this.dataService
      .purchaseSubscriptionDeliveryPlan(
        localStorage.getItem("token"),
        this.deliveryPlanmethod,
        this.deliveryPlanId.plan_id
      )
      .subscribe(
        (data) => {
          this.nao.go(100);
          this.deliveryPlanSuccessData = data;
          $("#purchasePackgePopup").modal("hide");
          if (this.deliveryPlanSuccessData.success === true) {
            toastr.success(this.deliveryPlanSuccessData.message);
            localStorage.setItem("purchaseSubscription", "true");
            localStorage.setItem(
              "packageCount",
              this.deliveryPlanSuccessData.package_count
            );
            this.callPackgesPurchaseListing();
            if (this.deliveryPlanmethod === 0) {
              $("#DeliveryPlanPaymentPopup").modal("hide");
            } else {
              this.deliveryPlanPurchase = true;
            }
          } else {
            toastr.error(this.deliveryPlanSuccessData.message);
          }
        },
        (error) => {
          this.nao.go(100);
          toastr.error("Some thing went wrong");
          this.purchaseDeliveryPlanBtn = true;
        }
      );
  }

  disableBackground() {
    $("html").css("overflow-y", "hidden");
  }

  enableBackground() {
    $("html").css("overflow-y", "auto");
  }
  textUsYourOrder() {
    this.dataControlService.textUsOrderInvoke.next(true);
  }
  mytoken: any;
  isOnBusinessDetailPage: boolean = false;
  isOnbusinessPage: boolean = false;
  isWishlistClicked: boolean = false;
  branchFunction() {
    console.log("table number", this.tableNumber);
    let data = {
      outletId: this.dineinOutletId,
      dispensaryId: this.dataService.businessIdForIndependentBusinessData,
      name: this.businessDetailData.name,
      imgUrl: this.businessDetailData.image,
      businessId: this.businessDetailData.business_category_id,
      tableNo: this.tableNumber,
    };
    if (this.type && (this.type == "dinein" || this.type == "pickup")) {
      var isDeepLink = this.type == "dinein" ? "true" : "false";
      this.appData =
        "https://kqi7k.app.link/Lt1Xmrj4jbb?outletId=" +
        this.dineinOutletId +
        "&dispensaryId=" +
        this.businessDetailData.id +
        "&name=" +
        this.outletName +
        "&businessId=" +
        this.businessDetailData.business_category_id +
        "&tableNo=" +
        this.tableNumber +
        "&isDeepLink=" +
        isDeepLink;
    } else {
      this.appData =
        "https://kqi7k.app.link/Lt1Xmrj4jbb?outletId=" +
        this.dineinOutletId +
        "&dispensaryId=" +
        this.businessDetailData.id +
        "&name=" +
        this.outletName +
        "&businessId=" +
        this.businessDetailData.business_category_id +
        "&tableNo=" +
        this.tableNumber;
    }
    console.log("URL ===>", this.appData);
  }

  openAppFunction() {
    this.router.navigate(["/download"]);
  }

  closeDownloadBanner() {
    this.events.publish("closeBanner", false);
    this.qrCode = "false";
    this.showDownloadBanner = false;
    this.dataControlService.showBanner$.next(false);
  }
  deviceType: number = -1;
  ngOnInit() {
    $('.sideModal').on('hidden.bs.modal', function () {
      // Your function here
      console.log('Side modal has closed');
      $("html").css("overflow-y", "auto");
  });
    let table;
    if (/android/i.test(navigator.userAgent)) {
      this.deviceType = 0;
    } else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      this.deviceType = 1;
    } else if (/Windows|Linux|Mac/i.test(navigator.userAgent)) {
      this.deviceType = 3;
    }
    this.route.queryParams.subscribe((params) => {
      if (params["qrcode"] != undefined || params["qrcode"] != null) {
        this.qrCode = params["qrcode"];
        this.landedFromQR = true;
        this.showDownloadBanner = true;
        this.dataControlService.showBanner$.next(true);
      }
      if (params["tablenumber"] != undefined || params["tablenumber"] != null) {
        this.tableNumber = params["tablenumber"];
        table = this.tableNumber;
      }
      if (params["outlet_id"] != undefined || params["outlet_id"] != null) {
        this.dineinOutletId = params["outlet_id"];
      }
      if (params["outletname"] != undefined || params["outletname"] != null) {
        this.outletName = params["outletname"];
      }
      if (params["type"] != undefined || params["type"] != null) {
        this.type = params["type"];
        this.type = this.type.toLowerCase();
        this.showTimeSlots = false; // * if landed via qr code, hide time slots initially.
        if (this.type == "dinein") {
          this.dineIn = true;
          this.selfPickup = false;
          this.delivery = false;
        } else if (this.type == "pickup") {
          this.dineIn = false;
          this.selfPickup = true;
          this.delivery = false;
        } else {
          this.dineIn = false;
          this.selfPickup = false;
          this.delivery = true;
        }
      }
    });
  
    // if (this.widthOfWindow < 768) {
    //   $('#dine-in').addClass('checkout-tags');
    //   this.dineIn = true;
    //   this.selfPickup = false;
    //   this.delivery = false;
    //   this.checkOutState = 0;
    // }

    if (this.widthOfWindow > 768) {
      $("#delivery").addClass("checkout-tags");
      this.dineIn = false;
      this.selfPickup = false;
      this.delivery = true;
      this.checkOutState = 1;
    }

    this.userCurrentAddress = window.localStorage.getItem("delivery_address");
    // this.addressUpdate = this.events.subscribe('addressUpdate').subscribe((data) => {
    //   if(data == 'true') {
    //     this.userCurrentAddress = JSON.parse(window.localStorage.getItem('profileAddressCheckout'));
    //   }
    // });

    this.pubsub.subscribe("policyLink").subscribe((data) => {
      this.policyLink = data;
      console.log(this.policyLink);
    });

    this.widthOfWindow = $(window).width();
    // this.getBusinessData();
    // this.getBusinessMailAndPhone ();
    this.getAreaList();
    $("#myAddressLabelApp").click(function () {
      $("#streetDeliveryApp").focus();
    });
    this.injectGoogleAnalyticsandFacebook();
    if (this.globalAddress != "Please Select your location") {
      this.dataControlService.setAddressOnAddressInput.next(true);
    }
    this.dataControlService.isSignInWishlistClicked.next(false);

    this.dataControlService.isSignInWishlistClicked.subscribe((data) => {
      if (data == true) {
        this.isWishlistClicked = true;
      } else {
        this.isWishlistClicked = false;
      }
    });
    this.dataControlService.mytokenVal.subscribe((data) => {
      this.mytoken = data;
    });

    this.dataControlService.inBusinessesPage.subscribe((data) => {
      if (data == true) {
        this.isOnbusinessPage = true;
      } else {
        this.isOnbusinessPage = false;
      }
    });
    this.dataControlService.isOnBusinessDetailPage.subscribe((data) => {
      if (data == true) {
        this.isOnBusinessDetailPage = true;
      } else {
        this.isOnBusinessDetailPage = false;
      }
    });

    this.dataControlService.emptyCartOnAppComponent.subscribe((data) => {
      if (data == true) {
        this.emptyCartFromHome();
      }
    });
    this.locationFormModal = this.formBuilder.group({
      premisLocationModal: "",
      placeLabelLocationModal: "",
      areaLocationModal: "",
      houseNoLocationModal: "",
      aptLocationModal: "",
      streetLocationModal: "",
      cityLocationModal: "",
      stateLocationModal: "",
      postalLocationModal: "",
      countryLocationModal: "",
    });
    $(".dropdown.keep-open").on({
      "shown.bs.dropdown": function () {
        this.closable = false;
      },
      click: function () {
        this.closable = false;
      },
      "hide.bs.dropdown": function () {
        return this.closable;
      },
    });
    $("#onOnleBusinessCartWishlist").on("hidden.bs.modal", function () {
      $("#addCartPopWishlist").css("-webkit-filter", "blur(0px)");
      $("#addCartPopWishlist").css("-moz-filter", "blur(0px)");
      $("#addCartPopWishlist").css("-o-filter", "blur(0px)");
      $("#addCartPopWishlist").css("-ms-filter", "blur(0px)");
      $("#addCartPopWishlist").css("filter", "blur(0px)");
    });
    $("#onOnleBusinessCartWishlist").on("show.bs.modal", function () {
      $("#addCartPopWishlist").css("-webkit-filter", "blur(5px)");
      $("#addCartPopWishlist").css("-moz-filter", "blur(5px)");
      $("#addCartPopWishlist").css("-o-filter", "blur(5px)");
      $("#addCartPopWishlist").css("-ms-filter", "blur(5px)");
      $("#addCartPopWishlist").css("filter", "blur(5px)");
    });

    $("#addCartPopWishlist").on("hidden.bs.modal", function () {
      $("#wishlistPopup").css("-webkit-filter", "blur(0px)");
      $("#wishlistPopup").css("-moz-filter", "blur(0px)");
      $("#wishlistPopup").css("-o-filter", "blur(0px)");
      $("#wishlistPopup").css("-ms-filter", "blur(0px)");
      $("#wishlistPopup").css("filter", "blur(0px)");
    });
    $("#addCartPopWishlist").on("show.bs.modal", function () {
      $("#wishlistPopup").css("-webkit-filter", "blur(5px)");
      $("#wishlistPopup").css("-moz-filter", "blur(5px)");
      $("#wishlistPopup").css("-o-filter", "blur(5px)");
      $("#wishlistPopup").css("-ms-filter", "blur(5px)");
      $("#wishlistPopup").css("filter", "blur(5px)");
    });

    var contax = this;
    $("#wishlistModalPopup").on("scroll", function () {
      if (contax.wishlistDataLastPage === false) {
        contax.wishListLoadMore = true;
        if (
          $(this).scrollTop() + $(this).innerHeight() >=
          $(this)[0].scrollHeight - 20
        ) {
          contax.wishlistDataLastPage = true;
          setTimeout(() => {
            contax.callwishlistFunctionByPage();
          }, 500);
        }
      }
    });

    $("#orderPopupMain").on("scroll", function () {
      if (contax.orderType === 0 && contax.orderLastPage === false) {
        if (
          $(this).scrollTop() + $(this).innerHeight() >=
          $(this)[0].scrollHeight - 10
        ) {
          setTimeout(() => {
            contax.callOrderFunctionByPage();
          }, 500);
        }
      }
      if (contax.orderType === 1 && contax.orderLastPage === false) {
        if (
          $(this).scrollTop() + $(this).innerHeight() >=
          $(this)[0].scrollHeight - 10
        ) {
          setTimeout(() => {
            contax.callOrderFunctionByPage();
          }, 500);
        }
      }
      if (contax.orderType === 2 && contax.orderLastPage === false) {
        if (
          $(this).scrollTop() + $(this).innerHeight() >=
          $(this)[0].scrollHeight - 40
        ) {
          setTimeout(() => {
            contax.callOrderFunctionByPage();
          }, 500);
        }
      }
    });

    $("#health-records").on("scroll", function () {
      if (contax.isHealthRecordlastPage === false) {
        if (
          $(this).scrollTop() + $(this).innerHeight() >=
          $(this)[0].scrollHeight - 10
        ) {
          setTimeout(() => {
            contax.gethealthRecordsNextPage();
          }, 500);
        }
      }
    });

    this.windowCurrentWidth = $(window).width();
    window.addEventListener("resize", () => {
      this.windowCurrentWidth = $(window).width();
    });

    $("#profileAddressMain").css("display", "none");
    if (
      localStorage.getItem("token") !== "" &&
      localStorage.getItem("token") !== undefined &&
      localStorage.getItem("token") !== null
    ) {
      this.callPushNotificationFunction();
    }
    for (let z = 0; z < this.businessRaiting; z++) {
      this.businessRaitingArray.push({
        value: "true",
      });
    }

    $("#image-cropper").cropit();
    $("#image-cropper").cropit({
      $preview: $(".cropit-preview"),
      allowDragNDrop: true,
    });

    $("#aaa").css("display", "none");
    this.showloder = "pin.svg";
    const context = this;
    $(function () {
      this.maxYear = new Date().getFullYear();
      this.maxYear = parseInt(this.maxYear) - 21;
      $("#datepicker").datepicker({
        format: "mm/dd/yyyy", // Notice the Extra space at the beginning
        defaultDate: "-10d",
        endDate: new Date("12/31/" + this.maxYear),
      });
    });
    $(function () {
      $("body").on("keydown", ":input", function (e) {
        if (e.which === 32 && e.target.selectionStart === 0) {
          return false;
        }
      });

      $("#signIn").on("hidden.bs.modal", function () {
        var filterBlur = "blur(0px)";

        $(".addCartShort").css("-webkit-filter", filterBlur);
        $(".addCartShort").css("-moz-filter", filterBlur);
        $(".addCartShort").css("-o-filter", filterBlur);
        $(".addCartShort").css("-ms-filter", filterBlur);
        $(".addCartShort").css("filter", filterBlur);
      });

      $("#codePop").on("hidden.bs.modal", function () {
        var filterBlur = "blur(0px)";

        $(".addCartShort").css("-webkit-filter", filterBlur);
        $(".addCartShort").css("-moz-filter", filterBlur);
        $(".addCartShort").css("-o-filter", filterBlur);
        $(".addCartShort").css("-ms-filter", filterBlur);
        $(".addCartShort").css("filter", filterBlur);
      });
    });
    this.router.events.subscribe((navEnd: NavigationEnd) => {
      if (
        window.location.hostname.includes("bestbuymall.co") ||
        window.location.hostname.includes("bestbuymall.co") ||
        window.location.hostname.includes("dev.bestbuymall.co") ||
        window.location.hostname.includes("dev.bestbuymall.co") ||
        window.location.hostname.includes("localhost") ||
        window.location.hostname.includes("bbm.com.pk")
      ) {
        if (
          !navEnd.url.includes("blog") &&
          !navEnd.url.includes("terms-conditions") &&
          !navEnd.url.includes("privacy-policy") &&
          !navEnd.url.includes("return-policy") &&
          window.innerWidth < 768
        ) {
          $("#mobModal").modal({
            backdrop: "static",
            keyboard: false,
          });
          $("#mobModal").modal("show");
        } else {
          $("#mobModal").modal("hide");
        }
      }
    });
  
  }

  // --------------------
  // Modal location work
  // --------------------

  get isUserToken(): any {
    return localStorage.getItem("token");
  }
  locationFormModal: FormGroup;

  getcurrentLocationFromHomeModal() {
    this.getcurrentLocationModal();
    this.setPlaceLabel("Home");
  }

  toggleDisableFields() {
    if (this.deliveryAddressSearchLoader) {
      this.locationFormModal.controls["premisLocationModal"].disable();
      // this.locationFormModal.controls['placeLabelLocation'].disable();
      this.locationFormModal.controls["areaLocationModal"].disable();
      this.locationFormModal.controls["houseNoLocationModal"].disable();
      this.locationFormModal.controls["aptLocationModal"].disable();
      // this.locationFormModal.controls['streetLocation'].disable();
      this.locationFormModal.controls["cityLocationModal"].disable();
      this.locationFormModal.controls["stateLocationModal"].disable();
      this.locationFormModal.controls["postalLocationModal"].disable();
      this.locationFormModal.controls["countryLocationModal"].disable();
    } else {
      this.locationFormModal.controls["premisLocationModal"].enable();
      // this.locationFormModal.controls['placeLabelLocation'].enable();
      this.locationFormModal.controls["areaLocationModal"].enable();
      this.locationFormModal.controls["houseNoLocationModal"].enable();
      this.locationFormModal.controls["aptLocationModal"].enable();
      // this.locationFormModal.controls['streetLocation'].enable();
      // this.locationFormModal.controls['cityLocationModal'].enable();
      // this.locationFormModal.controls['stateLocationModal'].enable();
      this.locationFormModal.controls["postalLocationModal"].enable();
      this.locationFormModal.controls["countryLocationModal"].enable();
    }
  }
  getcurrentLocationModal() {
    this.myPinCity = "";

    this.isMenuAddressClicked === true;
    this.currentLocationCount = 0;

    this.isLocationFinalized = false;
    this.shopnowState = "disable";
    let tempplaceLabel =
      this.locationFormModal.controls.placeLabelLocationModal.value;
    this.locationFormModal.reset();
    this.locationFormModal.controls.placeLabelLocationModal.setValue(
      tempplaceLabel
    );

    this.streetDelivery = "";
    this.cityDelivery = "";
    this.countryDelivery = "";
    this.stateDelivery = "";
    this.zipDelivery = "";
    this.apartDelivery = "";
    this.orderNotesNew = "";
    this.deliveryAddressSearchLoader = true;
    this.toggleDisableFields();

    // this.checkoutAddress = true;
    $(".myMapDiv").css("display", "block");
    const contex = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
    }

    function showError(error) {
      const contex1 = this;
      toastr.error("Allow location service from your browser");
      contex.deliveryAddressSearchLoader = false;
      contex.checkoutAddress = false;
      $(".myMapDiv").css("display", "none");
    }

    function showPosition(position) {
      contex.deliveryAddressSearchLoader = true;
      contex.latitudeDelivery = position.coords.latitude;
      contex.longitudeDelivery = position.coords.longitude;
      contex.initMapFromCurrentLocationModal();
    }
  }

  initMapFromCurrentLocationModal() {
    var context = this;
    var map;
    var marker;
    var adr;
    var myLatlng = new google.maps.LatLng(
      this.latitudeDelivery,
      this.longitudeDelivery
    );
    var geocoder = new google.maps.Geocoder();
    var infowindow = new google.maps.InfoWindow();
    var mapOptions = {
      zoom: 14,
      center: myLatlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      navigationControl: false,
      // mapTypeControl: false,
      // disableDefaultUI: true
    };
    var endImage = {
      url: "assets/pin.png", // url
      scaledSize: new google.maps.Size(30, 42), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(16, 41), // anchor
    };
    if (this.isBusinessLandingPage == true) {
      this.url = "assets/pinBlack.png";
    }
    map = new google.maps.Map(
      document.getElementById("myMapModal"),
      mapOptions
    );
    marker = new google.maps.Marker({
      map: map,
      position: myLatlng,
      draggable: true,
      icon: endImage,
    });

    geocoder.geocode({ latLng: myLatlng }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          infowindow.setContent(results[0].formatted_address);
          infowindow.open(map, marker);
          context.latitudeDelivery = marker.getPosition().lat();
          context.longitudeDelivery = marker.getPosition().lng();
          context.getDeliveryAddressDetailFromCurrentLocationModal(results[0]);
        }
      }
    });

    google.maps.event.addListener(marker, "dragend", function () {
      geocoder.geocode(
        { latLng: marker.getPosition() },
        function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              context.myPinCity = results[0];
              infowindow.setContent(results[0].formatted_address);
              infowindow.open(map, marker);
              context.latitudeDelivery = marker.getPosition().lat();
              context.longitudeDelivery = marker.getPosition().lng();
              // context.addressNew =  results[0].formatted_address;
            }
          }
        }
      );
    });
    this.deliveryAddressSearchLoader = false;
    this.toggleDisableFields();
  }
  async getDeliveryAddressDetailFromCurrentLocationModal(result) {
    this.streetDelivery = "";
    this.ProfileApart = "";
    this.cityDelivery = "";
    this.stateDelivery = "";
    this.zipDelivery = "";
    this.zip = "";
    this.apartDelivery = "";
    //this.changeRef.detectChanges();
    this.locationFormModal.controls.areaLocationModal.setValue("");

    // this.streetDelivery = result.formatted_address;
    for (var i = 0; i < result.address_components.length; i++) {
      for (var j = 0; j < result.address_components[i].types.length; j++) {
        if (
          result.address_components[i].types[j] ===
          "administrative_area_level_2"
        ) {
          this.locationFormModal.controls.cityLocationModal.setValue(
            result.address_components[i].long_name
          );
          console.log(this.locationFormModal.controls.cityLocationModal.value);
          // console.log(this.cityDelivery );
        }
        if (result.address_components[i].types[j] === "premise") {
          this.locationFormModal.controls.premisLocationModal.setValue(
            result.address_components[i].long_name
          );
        }

        // if (result.address_components[i].types[j] === 'route') {
        //   this.locationFormModal.controls.streetLocationModal.setValue(result.address_components[i].long_name);
        //   //this.changeRef.detectChanges();
        // }
        if (result.address_components[i].types[j] === "sublocality_level_2") {
          this.locationFormModal.controls.areaLocationModal.setValue("");
          this.locationFormModal.controls.streetLocationModal.setValue("");
          this.locationFormModal.controls.areaLocationModal.setValue(
            result.address_components[i].long_name
          );
          this.locationFormModal.controls.streetLocationModal.setValue(
            result.address_components[i].long_name
          );
        }
        if (result.address_components[i].types[j] === "sublocality_level_1") {
          this.locationFormModal.controls.areaLocationModal.setValue(
            this.locationFormModal.controls.areaLocationModal.value +
              " " +
              result.address_components[i].long_name
          );
        }

        if (
          result.address_components[i].types[j] ===
          "administrative_area_level_1"
        ) {
          this.locationFormModal.controls.stateLocationModal.setValue(
            result.address_components[i].short_name
          );
        }

        if (result.address_components[i].types[j] === "country") {
          this.locationFormModal.controls.countryLocationModal.setValue(
            result.address_components[i].long_name
          );
        }
        if (result.address_components[i].types[j] === "postal_code") {
          this.locationFormModal.controls.postalLocationModal.setValue(
            result.address_components[i].long_name
          );
          this.locationFormModal.controls.postalLocationModal.setValue(
            result.address_components[i].long_name
          );
        }
      }
    }

    let isEnteredCityCorrect = false;
    for (i = 0; i < this.areaData.length; i++) {
      if (
        this.areaData[i].city.toLowerCase() ==
        this.locationFormModal.controls.cityLocationModal.value.toLowerCase()
      ) {
        isEnteredCityCorrect = true;
        break;
      }
    }

    if (isEnteredCityCorrect == false) {
      // wrong modal here
      this.selectedCity =
        this.locationFormModal.controls.cityLocationModal.value;

      $("#selectedAddressPopUpModal").modal("hide");
      $("#wrongCityPopUpModal").modal("show");
      this.blurAddressModal();
    } else {
      $("#selectedAddressPopUpModal").modal("show");
      $("#wrongCityPopUpModal").modal("hide");
    }
    this.deliveryAddressSearchLoader = false;
    this.toggleDisableFields();
    // $('#selectedAddressPopUpModal').modal('show');
    $("#headAddressSelection").modal("hide");

    this.currentLocationCount = this.currentLocationCount + 1;
    if (this.currentLocationCount <= 2) {
      this.initMapFromCurrentLocationModal();
    }
  }
  blurAddressModal() {
    $(".addCartShort").css("-webkit-filter", "blur(3px)");
    $(".addCartShort").css("-moz-filter", "blur(3px)");
    $(".addCartShort").css("-o-filter", "blur(3px)");
    $(".addCartShort").css("-ms-filter", "blur(3px)");
    $(".addCartShort").css("filter", "blur(3px)");
  }
  unBlurAddressModal() {
    $(".addCartShort").css("-webkit-filter", "blur(0px)");
    $(".addCartShort").css("-moz-filter", "blur(0px)");
    $(".addCartShort").css("-o-filter", "blur(0px)");
    $(".addCartShort").css("-ms-filter", "blur(0px)");
    $(".addCartShort").css("filter", "blur(0px)");
  }

  isSuggestionAddressShownModal: boolean = false;
  isSearchAddressShownModal: boolean = false;

  toggleSuggestedFieldsModal() {
    if (this.isSuggestionAddressShownModal) {
      this.isSuggestionAddressShownModal = false;
    } else {
      this.isSuggestionAddressShownModal = true;
      this.isSearchAddressShownModal = false;
    }
    // this.isSuggestionAddressShownModal = !this.isSuggestionAddressShownModal;
    document.getElementById("streetDeliveryApp").focus();
    document
      .getElementById("streetDeliveryApp")
      .setAttribute("aria-expanded", "true");

    // let el: HTMLElement = this.myLocationSearch.nativeElement;
    // el.click();
  }
  citiesWeOperateInModal() {
    $("#wrongCityPopUpModal").modal("hide");
    // $('#operatedCitiesPopUp').modal('hide');
    $("#selectedAddressPopUpModal").modal("hide");
    $("#headAddressSelection").modal("hide");

    var serviceAreaSection = document.getElementById("myServiceAreas");
    serviceAreaSection.scrollIntoView();
  }
  goToNotFoundCityModal() {
    this.blurAddressModal();

    $("#wrongCityPopUpModal").modal("show");
    // $('#operatedCitiesPopUp').modal('hide');
    $("#selectedAddressPopUpModal").modal("hide");
    $("#headAddressSelection").modal("hide");
  }

  goToLocationSelectionModal() {
    $("#selectedAddressPopUpModal").modal("hide");
    $("#wrongCityPopUpModal").modal("hide");
    // $('#operatedCitiesPopUp').modal('hide');
    $("#headAddressSelection").modal("show");
    $("#myAddressLabelApp").css("color", "unset");
    setTimeout(() => {
      this.isLocationFinalized = false;
      this.shopnowState = "disable";
      this.streetDelivery = "";
      document.getElementById("streetDeliveryApp").focus();
    }, 100);
  }
  openAfterPlaceSelectPopupModal(name, id) {
    this.myPinCity = "";

    $("#streetDelivery").dropdown("toggle");
    this.isMenuAddressClicked = true;
    this.currentLocationCount = 0;
    this.isSuggestionAddressShownModal = false;
    this.isSearchAddressShownModal = false;

    this.locationFormModal.reset();
    this.deliveryAddressSearchLoader = true;
    this.toggleDisableFields();
    this.setPlaceLabel("Home");

    // this.areaLocationModal = name;
    this.locationFormModal.controls.areaLocationModal.setValue(name);
    this.dataService.googleAddressDetail(id).subscribe(
      (data) => {
        this.placeDetailDataDelivery = data;
        // this.checkoutAddress = true;
        $(".myMapDiv").css("display", "block");
        this.placeDetailDataDelivery = this.placeDetailDataDelivery.result;
        this.latitudeDelivery =
          this.placeDetailDataDelivery.geometry.location.lat;
        this.longitudeDelivery =
          this.placeDetailDataDelivery.geometry.location.lng;
        setTimeout(() => {
          this.locationFormModal.controls.areaLocationModal.setValue("");
          this.getDeliveryAddressDetailFromSearchLocationModal(
            this.placeDetailDataDelivery
          );
          this.addressLoadDelivery = false;
        }, 1000);
      },
      (error) => {}
    );
  }
  getAvailabilityDataByCity(city) {
    this.businessDetName = localStorage.getItem("businessDetName");
    this.selectedCity = this.locationFormModal.controls.cityLocationModal.value;

    this.dataService
      .business_outlet_availability(this.businessDetName, city)
      .subscribe(
        (data) => {
          this.businessDetailObj = data;
          if (this.businessDetailObj.success == true) {
            localStorage.setItem("delivery_address", city);
            localStorage.setItem("myDeliveryAddress", city);
            localStorage.setItem("profileAddress", null);
            localStorage.setItem("profileAddressCheckout", null);
            this.streetDelivery = "";

            $("#selectedAddressPopUpModal").modal("hide");
            $("#headAddressSelection").modal("hide");
            $("#wrongCityPopUpModal").modal("hide");
            this.setValuesinBusinessDetailPage(this.businessDetailObj);
            this.router.navigate(["/businesses", city, this.businessDetName]);
          } else if (this.businessDetailObj.success == false) {
            // business not found modal show
            this.selectedCity = city;
            $("#selectedAddressPopUpModal").modal("hide");
            $("#onChangingCityOnHomeModal").modal("hide");
            $("#wrongCityPopUpModal").modal("hide");
            // $('#headAddressSelection').modal('hide');
            $("#businessNotFoundCityModal").modal("show");
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  serviceAreaGet(obj, index) {
    if (this.isOnBusinessDetailPage == true) {
      this.getAvailabilityDataByCity(obj.city_short_name);
    } else {
      localStorage.setItem("delivery_address", obj.city);
      localStorage.setItem("myDeliveryAddress", obj.city);
      localStorage.setItem("profileAddress", null);
      localStorage.setItem("profileAddressCheckout", null);
      this.streetDelivery = "";

      $("#selectedAddressPopUpModal").modal("hide");
      $("#headAddressSelection").modal("hide");
      $("#wrongCityPopUpModal").modal("hide");

      this.router.navigate(["/businesses", obj.city_short_name]);
    }
    this.events.publish("addressUpdate", "true");
  }
  myDropDownPlaceLabel: any;
  isCustomPlaceLabel: boolean = false;
  selectedCity: any;
  async getDeliveryAddressDetailFromSearchLocationModal(result) {
    this.apartDelivery = "";
    this.countryDelivery = "";
    this.cityDelivery = "";
    this.stateDelivery = "";
    this.zipDelivery = "";
    this.zip = "";
    this.locationFormModal.controls.areaLocationModal.setValue("");

    for (var i = 0; i < result.address_components.length; i++) {
      for (var j = 0; j < result.address_components[i].types.length; j++) {
        if (
          result.address_components[i].types[j] ===
          "administrative_area_level_2"
        ) {
          this.cityDelivery = result.address_components[i].long_name;
          this.locationFormModal.controls.cityLocationModal.setValue(
            result.address_components[i].long_name
          );
        } else if (result.address_components[i].types[j] === "locality") {
          this.cityDelivery = result.address_components[i].long_name;
          this.locationFormModal.controls.cityLocationModal.setValue(
            result.address_components[i].long_name
          );
          console.log(this.locationFormModal.controls.cityLocationModal.value);
          console.log(this.cityDelivery);
        }

        if (
          result.address_components[i].types[j] ===
          "administrative_area_level_1"
        ) {
          this.stateDelivery = result.address_components[i].short_name;
          this.locationFormModal.controls.stateLocationModal.setValue(
            result.address_components[i].short_name
          );
        }
        if (result.address_components[i].types[j] === "route") {
          this.locationFormModal.controls.areaLocationModal.setValue(
            result.address_components[i].long_name
          );
        }
        if (result.address_components[i].types[j] === "sublocality_level_2") {
          if (
            this.locationFormModal.controls.areaLocationModal.value !== "" &&
            this.locationFormModal.controls.areaLocationModal.value !== null
          ) {
            this.locationFormModal.controls.areaLocationModal.setValue(
              this.locationFormModal.controls.areaLocationModal.value +
                " " +
                result.address_components[i].long_name
            );
          } else {
            this.locationFormModal.controls.areaLocationModal.setValue(
              result.address_components[i].long_name
            );
          }
        }
        if (result.address_components[i].types[j] === "sublocality_level_1") {
          if (
            this.locationFormModal.controls.areaLocationModal.value !== "" &&
            this.locationFormModal.controls.areaLocationModal.value !== null
          ) {
            this.locationFormModal.controls.areaLocationModal.setValue(
              this.locationFormModal.controls.areaLocationModal.value +
                " " +
                result.address_components[i].long_name
            );
          } else {
            this.locationFormModal.controls.areaLocationModal.setValue(
              result.address_components[i].long_name
            );
          }
        }
        if (result.address_components[i].types[j] === "country") {
          this.countryDelivery = result.address_components[i].long_name;
          this.locationFormModal.controls.countryLocationModal.setValue(
            result.address_components[i].long_name
          );
        }

        if (result.address_components[i].types[j] === "postal_code") {
          this.zipDelivery = result.address_components[i].long_name;
          this.locationFormModal.controls.postalLocationModal.setValue(
            result.address_components[i].short_name
          );
        }
      }
    }

    let isEnteredCityCorrect = false;
    if (
      this.locationFormModal.controls.cityLocationModal.value == "" ||
      this.locationFormModal.controls.cityLocationModal.value == null ||
      this.locationFormModal.controls.cityLocationModal.value == undefined
    ) {
      this.isCityEmpty = true;
    } else {
      this.isCityEmpty = false;
      for (i = 0; i < this.areaData.length; i++) {
        if (
          this.areaData[i].city.toLowerCase() ==
          this.locationFormModal.controls.cityLocationModal.value.toLowerCase()
        ) {
          isEnteredCityCorrect = true;
          break;
        }
      }
    }

    if (isEnteredCityCorrect == false) {
      // wrong modal here
      this.selectedCity =
        this.locationFormModal.controls.cityLocationModal.value;
      $("#selectedAddressPopUpModal").modal("hide");
      $("#headAddressSelection").modal("hide");
      $("#wrongCityPopUpModal").modal("show");
      this.blurAddressModal();
    } else {
      $("#selectedAddressPopUpModal").modal("show");
      $("#wrongCityPopUpModal").modal("hide");
      $("#headAddressSelection").modal("hide");

      this.toggleDisableFields();

      this.initMapFromSearchLocationModal();
    }

    this.deliveryAddressSearchLoader = false;
  }

  initMapFromSearchLocationModal() {
    var context = this;
    var map;
    var marker;
    var adr;
    var myLatlng = new google.maps.LatLng(
      this.latitudeDelivery,
      this.longitudeDelivery
    );
    var geocoder = new google.maps.Geocoder();
    var infowindow = new google.maps.InfoWindow();
    var mapOptions = {
      zoom: 14,
      center: myLatlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      navigationControl: false,
    };
    var endImage = {
      url: "assets/pin.png", // url
      scaledSize: new google.maps.Size(30, 42), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(16, 41), // anchor
    };
    if (this.isBusinessLandingPage == true) {
      this.url = "assets/pinBlack.png";
    }
    map = new google.maps.Map(
      document.getElementById("myMapModal"),
      mapOptions
    );

    marker = new google.maps.Marker({
      map: map,
      position: myLatlng,
      draggable: true,
      icon: endImage,
    });

    geocoder.geocode({ latLng: myLatlng }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          infowindow.setContent(results[0].formatted_address);
          infowindow.open(map, marker);
          context.latitudeDelivery = marker.getPosition().lat();
          context.longitudeDelivery = marker.getPosition().lng();
          context.currentLocationCount = context.currentLocationCount + 1;
          if (context.currentLocationCount <= 2) {
            context.getDeliveryAddressDetailFromSearchLocationModal(results[0]);
          }
        }
      }
    });

    google.maps.event.addListener(marker, "dragend", function () {
      geocoder.geocode(
        { latLng: marker.getPosition() },
        function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              context.myPinCity = results[0];
              infowindow.setContent(results[0].formatted_address);
              infowindow.open(map, marker);
              context.latitudeDelivery = marker.getPosition().lat();
              context.longitudeDelivery = marker.getPosition().lng();
              // context.addressNew =  results[0].formatted_address;
            }
          }
        }
      );
    });
    this.deliveryAddressSearchLoader = false;
    this.toggleDisableFields();
  }
  setPlaceLabel(value) {
    if (value == "Home") {
      this.locationFormModal.controls["placeLabelLocationModal"].disable();
      this.locationFormModal.controls.placeLabelLocationModal.setValue("Home");
      this.myDropDownPlaceLabel = "Home";
      this.isCustomPlaceLabel = false;
    } else if (value == "Work") {
      this.locationFormModal.controls["placeLabelLocationModal"].disable();
      this.locationFormModal.controls.placeLabelLocationModal.setValue("Work");
      this.myDropDownPlaceLabel = "Work";
      this.isCustomPlaceLabel = false;
    } else {
      this.locationFormModal.controls["placeLabelLocationModal"].enable();
      this.locationFormModal.controls.placeLabelLocationModal.setValue("");
      this.myDropDownPlaceLabel = "Custom";
      this.isCustomPlaceLabel = true;
      this.clearTimeout = setTimeout(() => {
        document.getElementById("placeLabelLocationModal").focus();
        clearTimeout(this.clearTimeout);
      }, 100);
    }
  }

  mySuggestedAddressSelectedIndex: any;
  isLocationFinalized: boolean = false;
  showAddressInput: boolean = true;

  addressLabelClicked() {
    $("#streetDeliveryApp").focus();
  }
  closeModal() {
    $("#myAddressLabelApp").css("color", "unset");
    this.shopnowState = "disable";
    this.showAddressInput = false;
    this.locationFormModal.reset();

    setTimeout(() => {
      this.streetDelivery = "";
      this.isSuggestionAddressShownModal = false;
      this.isSearchAddressShownModal = false;
      document
        .getElementById("streetDeliveryApp")
        .setAttribute("aria-expanded", "true");
      this.showAddressInput = true;
    }, 100);
  }
  suggestedAddressSelectedModal(index) {
    let i = 0;
    this.mySelectedaddress = "";
    this.isMenuAddressClicked = true;
    this.isDismissTryAgain = false;

    for (i = 0; i < this.areaData.length; i++) {
      if (
        this.areaData[i].city.toLowerCase() ==
        this.customerProfileListApp.address_dictionary[index].city.toLowerCase()
      ) {
        // this.mySelectedaddress = this.mySelectedaddress + this.customerProfileListApp.address_dictionary[index].address_line1 + ', ';

        if (
          this.customerProfileListApp.address_dictionary[index].zip == null ||
          this.customerProfileListApp.address_dictionary[index].zip == ""
        ) {
          if (
            this.customerProfileListApp.address_dictionary[index]
              .house_building_no !== ""
          ) {
            this.mySelectedaddress =
              this.mySelectedaddress +
              this.customerProfileListApp.address_dictionary[index]
                .house_building_no +
              ", ";
          }
          if (
            this.customerProfileListApp.address_dictionary[index].apart_no !==
            ""
          ) {
            this.mySelectedaddress =
              this.mySelectedaddress +
              this.customerProfileListApp.address_dictionary[index].apart_no +
              ", ";
          }
          this.mySelectedaddress =
            this.mySelectedaddress +
            this.customerProfileListApp.address_dictionary[index]
              .address_line1 +
            ", ";
          this.mySelectedaddress =
            this.mySelectedaddress +
            this.customerProfileListApp.address_dictionary[index].city;
          //  + ", " + this.customerProfileListApp.address_dictionary[index].state + ', ' + this.customerProfileListApp.address_dictionary[index].country;
        } else {
          if (
            this.customerProfileListApp.address_dictionary[index]
              .house_building_no !== ""
          ) {
            this.mySelectedaddress =
              this.mySelectedaddress +
              this.customerProfileListApp.address_dictionary[index]
                .house_building_no +
              ", ";
          }
          if (
            this.customerProfileListApp.address_dictionary[index].apart_no !==
            ""
          ) {
            this.mySelectedaddress =
              this.mySelectedaddress +
              this.customerProfileListApp.address_dictionary[index].apart_no +
              ", ";
          }
          this.mySelectedaddress =
            this.mySelectedaddress +
            this.customerProfileListApp.address_dictionary[index]
              .address_line1 +
            ", ";
          this.mySelectedaddress =
            this.mySelectedaddress +
            this.customerProfileListApp.address_dictionary[index].city;
          //  + ", " + this.customerProfileListApp.address_dictionary[index].state + " " + this.customerProfileListApp.address_dictionary[index].zip + ', ' + this.customerProfileListApp.address_dictionary[index].country;
        }

        break;
      }
      if (i == this.areaData.length - 1) {
        this.selectedCity =
          this.customerProfileListApp.address_dictionary[index].city;
        $("#selectedAddressPopUpModal").modal("hide");
        $("#wrongCityPopUpModal").modal("show");
        this.blurAddressModal();
        if (this.streetDelivery == "") {
          $("#myAddressLabelApp").css("color", "unset");
        }
        // this.isLocationFinalized = false;
      }
    }
    if (i < this.areaData.length) {
      this.locationFormModal.controls.cityLocationModal.setValue(
        this.customerProfileListApp.address_dictionary[index].city
      );
      this.locationFormModal.controls.postalLocationModal.setValue(
        this.customerProfileListApp.address_dictionary[index].zip
      );
      this.locationFormModal.controls.stateLocationModal.setValue(
        this.customerProfileListApp.address_dictionary[index].state
      );
      this.locationFormModal.controls.countryLocationModal.setValue(
        this.customerProfileListApp.address_dictionary[index].country
      );
      this.locationFormModal.controls.aptLocationModal.setValue(
        this.customerProfileListApp.address_dictionary[index].apart_no
      );
      this.locationFormModal.controls.houseNoLocationModal.setValue(
        this.customerProfileListApp.address_dictionary[index].house_building_no
      );
      this.locationFormModal.controls.areaLocationModal.setValue(
        this.customerProfileListApp.address_dictionary[index].address_line1
      );
      this.locationFormModal.controls.placeLabelLocationModal.setValue(
        this.customerProfileListApp.address_dictionary[index].place_label
      );
      this.latitudeDelivery =
        this.customerProfileListApp.address_dictionary[index].latitude;
      this.longitudeDelivery =
        this.customerProfileListApp.address_dictionary[index].longitude;
      this.orderNotesNew =
        this.customerProfileListApp.address_dictionary[index].notes;
      //check if city same or not if not empty cart.
      this.myProfileAddress = JSON.parse(
        localStorage.getItem("profileAddress")
      );
      let cartItems = localStorage.getItem("cartItems");
      this.mycartItems = cartItems;
      this.businessDetName = localStorage.getItem("businessDetName");
      // && this.businessDetName.toLowerCase() !== this.locationFormModal.controls.cityLocationModal.value.toLowerCase()

      if (this.isOnBusinessDetailPage == true) {
        this.checkAddressInBusinessdetailPageForSuggestions(index);
        return;
      } else {
        if (
          this.myProfileAddress !== "null" &&
          this.myProfileAddress !== null
        ) {
          if (
            this.myProfileAddress.city !==
              this.customerProfileListApp.address_dictionary[index].city &&
            cartItems != "[]" &&
            cartItems != null
          ) {
            // show empty cart
            $("#onChangingCityOnSuggestionModal").modal("show");
            this.blurAddressModal();
            this.isLocationFinalized = false;
            this.shopnowState = "disable";
            this.addressId =
              this.customerProfileListApp.address_dictionary[index].id;
            return;
          }
        }
        $("#myAddressLabelApp").css("color", "unset");

        this.isLocationFinalized = true;
        this.shopnowState = "enable";
        this.streetDelivery = "";
        this.mySuggestedAddressSelectedIndex =
          this.customerProfileListApp.address_dictionary[index].id;

        this.streetDelivery = this.mySelectedaddress;
        this.isSuggestionAddressShownModal = false;
        this.isSearchAddressShownModal = false;

        $("#selectedAddressPopUpModal").modal("hide");
        this.addressId =
          this.customerProfileListApp.address_dictionary[index].id;
        this.orderNotesNew =
          this.customerProfileListApp.address_dictionary[index].notes;

        // after shop now remove code
        this.myDeliveryObj = {
          id: this.addressId,
          street: this.locationFormModal.controls.areaLocationModal.value,
          address_line1:
            this.locationFormModal.controls.areaLocationModal.value,
          zip: this.locationFormModal.controls.postalLocationModal.value,
          city: this.locationFormModal.controls.cityLocationModal.value,
          country: this.locationFormModal.controls.countryLocationModal.value,
          state: this.locationFormModal.controls.stateLocationModal.value,
          latitude: this.latitudeDelivery,
          longitude: this.longitudeDelivery,
          apart_no: this.locationFormModal.controls.aptLocationModal.value,
          house_building_no:
            this.locationFormModal.controls.houseNoLocationModal.value,
          place_label:
            this.locationFormModal.controls.placeLabelLocationModal.value,
          notes: this.orderNotesNew,
        };
        localStorage.setItem(
          "profileAddress",
          JSON.stringify(this.myDeliveryObj)
        );
        localStorage.setItem(
          "profileAddressCheckout",
          JSON.stringify(this.myDeliveryObj)
        );

        this.makePrimeryAddress(this.myDeliveryObj.id, this.myDeliveryObj);
        localStorage.setItem("delivery_address", this.streetDelivery);
        localStorage.setItem("myDeliveryAddress", this.streetDelivery);

        $("#headAddressSelection").modal("hide");
        this.router.navigate([
          "/businesses",
          this.locationFormModal.controls.cityLocationModal.value,
        ]);
        // after shop now remove code end
      }
    }
    this.events.publish("addressUpdate", "true");
  }
  addressId: any;
  mySelectedaddress: any;
  functionTogetPlaceDeliveryModal() {
    this.isLocationFinalized = false;
    this.shopnowState = "disable";
    $("#myAddressLabelApp").css("color", "unset");

    // if (this.checkoutAddress === true) {
    //   return;
    // }
    this.deliveryAddressSearchLoader = true;
    this.isSuggestionAddressShownModal = false;
    this.isSearchAddressShownModal = true;

    this.placeDataFound = false;
    this.placeSuggestionsDataDeliveryModal = [];
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      this.addressLoadDelivery = true;
      this.dataService.googleAddress(this.streetDelivery).subscribe(
        (data) => {
          this.placeSuggestionsDataDeliveryModal = data;
          this.placeSuggestionsDataDeliveryModal =
            this.placeSuggestionsDataDeliveryModal.predictions;
          if (this.placeSuggestionsDataDeliveryModal.length > 0) {
            this.placeDataFound = true;
          }
          this.deliveryAddressSearchLoader = false;
          this.toggleDisableFields();

          if ($(".myDrop").hasClass("open")) {
          } else {
            $(".myDrop").addClass("open");
          }
          this.addressLoad = false;
        },
        (error) => {}
      );
    }, 1000);
  }

  shopNowModal() {
    // if(this.shopnowState == 'disable'){
    //   // focus field, show error messege and make label red

    //   toastr.error("Please select a valid address");
    //   $('#streetDeliveryApp').focus();
    //   $('#myAddressLabelApp').css('color', '#B80D38');

    //   return;
    // }

    this.myDeliveryObj = {
      id: this.addressId,
      street: this.locationFormModal.controls.areaLocationModal.value,
      address_line1: this.locationFormModal.controls.areaLocationModal.value,
      zip: this.locationFormModal.controls.postalLocationModal.value,
      city: this.locationFormModal.controls.cityLocationModal.value,
      country: this.locationFormModal.controls.countryLocationModal.value,
      state: this.locationFormModal.controls.stateLocationModal.value,
      latitude: this.latitudeDelivery,
      longitude: this.longitudeDelivery,
      apart_no: this.locationFormModal.controls.aptLocationModal.value,
      house_building_no:
        this.locationFormModal.controls.houseNoLocationModal.value,
      place_label:
        this.locationFormModal.controls.placeLabelLocationModal.value,
      notes: this.orderNotesNew,
    };
    localStorage.setItem("profileAddress", JSON.stringify(this.myDeliveryObj));
    localStorage.setItem(
      "profileAddressCheckout",
      JSON.stringify(this.myDeliveryObj)
    );

    this.makePrimeryAddress(this.myDeliveryObj.id, this.myDeliveryObj);
    localStorage.setItem("delivery_address", this.streetDelivery);
    localStorage.setItem("myDeliveryAddress", this.streetDelivery);

    $("#headAddressSelection").modal("hide");

    if (this.isOnBusinessDetailPage == true) {
      this.setValuesinBusinessDetailPage(this.businessDetailObj);
      this.router.navigate([
        "/businesses",
        this.locationFormModal.controls.cityLocationModal.value,
        this.businessDetName,
      ]);
    } else {
      this.router.navigate([
        "/businesses",
        this.locationFormModal.controls.cityLocationModal.value,
      ]);
    }
  }
  myPinCity: any = "";
  mySelectedPinCity: any;
  deliverHere() {
    this.isDismissTryAgain = false;
    this.orderNotesNew = "";

    if (
      this.locationFormModal.controls.placeLabelLocationModal.value === "" ||
      this.locationFormModal.controls.placeLabelLocationModal.value ===
        undefined
    ) {
      toastr.error("Kindly Select Place Label!");
      return;
    }
    if (
      this.locationFormModal.controls.areaLocationModal.value === "" ||
      this.locationFormModal.controls.areaLocationModal.value === undefined
    ) {
      toastr.error("Kindly Enter Area!");
      return;
    }
    if (
      this.locationFormModal.controls.cityLocationModal.value === "" ||
      this.locationFormModal.controls.cityLocationModal.value === null
    ) {
      toastr.error("Kindly Enter City!");
      return;
    }
    if (
      this.myPinCity != undefined &&
      this.myPinCity != null &&
      this.myPinCity != ""
    ) {
      for (let p = 0; p < this.myPinCity.address_components.length; p++) {
        for (
          var j = 0;
          j < this.myPinCity.address_components[p].types.length;
          j++
        ) {
          if (
            this.myPinCity.address_components[p].types[j] ===
            "administrative_area_level_2"
          ) {
            this.mySelectedPinCity =
              this.myPinCity.address_components[p].long_name;
          }
        }
      }
      console.log(this.mySelectedPinCity);
      if (
        this.mySelectedPinCity.toLowerCase() !==
        this.locationFormModal.controls.cityLocationModal.value.toLowerCase()
      ) {
        toastr.error(
          "Your Pin Location is not in " +
            this.locationFormModal.controls.cityLocationModal.value +
            "!"
        );
        return;
      }
    }

    var mycity =
      this.locationFormModal.controls.cityLocationModal.value.toLowerCase();
    let i = 0;
    for (i = 0; i < this.areaData.length; i++) {
      if (this.areaData[i].city.toLowerCase() == mycity) {
        this.streetDelivery = "";

        if (
          this.locationFormModal.controls.houseNoLocationModal.value != "" &&
          this.locationFormModal.controls.houseNoLocationModal.value != null
        ) {
          if (this.streetDelivery != ", " && this.streetDelivery != "") {
            this.streetDelivery =
              this.streetDelivery +
              ", " +
              this.locationFormModal.controls.houseNoLocationModal.value;
          } else {
            this.streetDelivery =
              this.locationFormModal.controls.houseNoLocationModal.value;
          }
        }
        if (
          this.locationFormModal.controls.aptLocationModal.value != "" &&
          this.locationFormModal.controls.aptLocationModal.value != null
        ) {
          if (this.streetDelivery != ", " && this.streetDelivery != "") {
            this.streetDelivery =
              this.streetDelivery +
              ", " +
              this.locationFormModal.controls.aptLocationModal.value;
          } else {
            this.streetDelivery =
              this.locationFormModal.controls.aptLocationModal.value;
          }
        }
        if (
          this.locationFormModal.controls.areaLocationModal.value != "" &&
          this.locationFormModal.controls.areaLocationModal.value != null
        ) {
          if (this.streetDelivery != ", " && this.streetDelivery != "") {
            this.streetDelivery =
              this.streetDelivery +
              ", " +
              this.locationFormModal.controls.areaLocationModal.value;
          } else {
            this.streetDelivery =
              this.locationFormModal.controls.areaLocationModal.value;
          }
        }
        if (
          this.locationFormModal.controls.cityLocationModal.value != "" &&
          this.locationFormModal.controls.cityLocationModal.value != null
        ) {
          if (this.streetDelivery != ", " && this.streetDelivery != "") {
            this.streetDelivery =
              this.streetDelivery +
              ", " +
              this.locationFormModal.controls.cityLocationModal.value;
          } else {
            this.streetDelivery =
              this.locationFormModal.controls.cityLocationModal.value;
          }
        }

        break;
      }
      if (i == this.areaData.length - 1) {
        this.selectedCity = mycity;
        this.isMenuAddressClicked = true;
        $("#selectedAddressPopUpModal").modal("hide");
        $("#wrongCityPopUpModal").modal("show");
        this.blurAddressModal();
      }
    }

    if (i < this.areaData.length) {
      //check if city same or not if not empty cart.
      this.myProfileAddress = JSON.parse(
        localStorage.getItem("profileAddress")
      );
      let cartItems = localStorage.getItem("cartItems");
      this.mycartItems = cartItems;

      $("#myAddressLabelApp").css("color", "unset");

      if (this.isOnBusinessDetailPage == true) {
        this.checkAddressInBusinessdetailPage();
        return;
      } else {
        if (
          this.myProfileAddress !== "null" &&
          this.myProfileAddress !== null
        ) {
          if (
            this.myProfileAddress.city !==
              this.locationFormModal.controls.cityLocationModal.value &&
            cartItems != "[]" &&
            cartItems != null
          ) {
            // show empty cart
            $("#onChangingCityOnHomeModal").modal("show");
            this.blurAddressModal();
            return;
          }
        }
        this.orderNotesNew = "";
        $("#selectedAddressPopUpModal").modal("hide");
        localStorage.setItem("delivery_address", this.streetDelivery);
        localStorage.setItem("myDeliveryAddress", this.streetDelivery);

        this.myDeliveryObj = {
          id: "",
          street: this.locationFormModal.controls.areaLocationModal.value,
          address_line1:
            this.locationFormModal.controls.areaLocationModal.value,
          zip: this.locationFormModal.controls.postalLocationModal.value,
          city: this.locationFormModal.controls.cityLocationModal.value,
          country: this.locationFormModal.controls.countryLocationModal.value,
          state: this.locationFormModal.controls.stateLocationModal.value,
          latitude: this.latitudeDelivery,
          longitude: this.longitudeDelivery,
          apart_no: this.locationFormModal.controls.aptLocationModal.value,
          house_building_no:
            this.locationFormModal.controls.houseNoLocationModal.value,
          place_label:
            this.locationFormModal.controls.placeLabelLocationModal.value,
          notes: this.orderNotesNew,
        };
        localStorage.setItem(
          "profileAddress",
          JSON.stringify(this.myDeliveryObj)
        );
        localStorage.setItem(
          "profileAddressCheckout",
          JSON.stringify(this.myDeliveryObj)
        );
        // if(this.isOnbusinessPage){
        // this.dataControlService.submitFilteredData.next(true);
        // }
        // else{
        //   this.dataControlService.submitFilteredData.next(false);
        // }

        this.setValuesinBusinessDetailPage(this.businessDetailObj);
        this.events.publish("addressUpdate", "true");
        this.router.navigate([
          "/businesses",
          this.locationFormModal.controls.cityLocationModal.value,
        ]);
      }
    }
  }
  mycartItems: any;
  checkAddressInBusinessdetailPageForEmptyCart() {
    $("#selectedAddressPopUpModal").modal("hide");
    $("#onChangingCityOnHomeModal").modal("hide");
    $("#wrongCityPopUpModal").modal("hide");
    $("#headAddressSelection").modal("hide");

    this.setValuesinBusinessDetailPage(this.businessDetailObj);
    localStorage.setItem("delivery_address", this.streetDelivery);
    localStorage.setItem("myDeliveryAddress", this.streetDelivery);

    this.myDeliveryObj = {
      id: "",
      street: this.locationFormModal.controls.areaLocationModal.value,
      address_line1: this.locationFormModal.controls.areaLocationModal.value,
      zip: this.locationFormModal.controls.postalLocationModal.value,
      city: this.locationFormModal.controls.cityLocationModal.value,
      country: this.locationFormModal.controls.countryLocationModal.value,
      state: this.locationFormModal.controls.stateLocationModal.value,
      latitude: this.latitudeDelivery,
      longitude: this.longitudeDelivery,
      apart_no: this.locationFormModal.controls.aptLocationModal.value,
      house_building_no:
        this.locationFormModal.controls.houseNoLocationModal.value,
      place_label:
        this.locationFormModal.controls.placeLabelLocationModal.value,
      notes: this.orderNotesNew,
    };
    localStorage.setItem("profileAddress", JSON.stringify(this.myDeliveryObj));
    localStorage.setItem(
      "profileAddressCheckout",
      JSON.stringify(this.myDeliveryObj)
    );
    this.router.navigate([
      "/businesses",
      this.locationFormModal.controls.cityLocationModal.value,
      this.businessDetName,
    ]);
  }

  checkAddressInBdetailPageOnSuggestionEmptyCart() {
    $("#selectedAddressPopUpModal").modal("hide");
    $("#onChangingCityOnHomeModal").modal("hide");
    $("#wrongCityPopUpModal").modal("hide");

    // this.setValuesinBusinessDetailPage(this.businessDetailObj);
    localStorage.setItem("delivery_address", this.streetDelivery);
    localStorage.setItem("myDeliveryAddress", this.streetDelivery);

    this.myDeliveryObj = {
      id: "",
      street: this.locationFormModal.controls.areaLocationModal.value,
      address_line1: this.locationFormModal.controls.areaLocationModal.value,
      zip: this.locationFormModal.controls.postalLocationModal.value,
      city: this.locationFormModal.controls.cityLocationModal.value,
      country: this.locationFormModal.controls.countryLocationModal.value,
      state: this.locationFormModal.controls.stateLocationModal.value,
      latitude: this.latitudeDelivery,
      longitude: this.longitudeDelivery,
      apart_no: this.locationFormModal.controls.aptLocationModal.value,
      house_building_no:
        this.locationFormModal.controls.houseNoLocationModal.value,
      place_label:
        this.locationFormModal.controls.placeLabelLocationModal.value,
      notes: this.orderNotesNew,
    };
    this.makePrimeryAddress(this.myDeliveryObj.id, this.myDeliveryObj);

    localStorage.setItem("profileAddress", JSON.stringify(this.myDeliveryObj));
    localStorage.setItem(
      "profileAddressCheckout",
      JSON.stringify(this.myDeliveryObj)
    );
    this.router.navigate([
      "/businesses",
      this.locationFormModal.controls.cityLocationModal.value,
      this.businessDetName,
    ]);
  }
  getAvailabilityData(check) {
    this.businessDetName = localStorage.getItem("businessDetName");
    this.selectedCity = this.locationFormModal.controls.cityLocationModal.value;
    this.dataService
      .business_outlet_availability(
        this.businessDetName,
        this.locationFormModal.controls.cityLocationModal.value
      )
      .subscribe(
        (data) => {
          this.businessDetailObj = data;
          if (check == "deliver") {
            this.setValuesinBusinessDetailPage(this.businessDetailObj);
          }
          // after shop now removed code
          this.myDeliveryObj = {
            id: this.addressId,
            street: this.locationFormModal.controls.areaLocationModal.value,
            address_line1:
              this.locationFormModal.controls.areaLocationModal.value,
            zip: this.locationFormModal.controls.postalLocationModal.value,
            city: this.locationFormModal.controls.cityLocationModal.value,
            country: this.locationFormModal.controls.countryLocationModal.value,
            state: this.locationFormModal.controls.stateLocationModal.value,
            latitude: this.latitudeDelivery,
            longitude: this.longitudeDelivery,
            apart_no: this.locationFormModal.controls.aptLocationModal.value,
            house_building_no:
              this.locationFormModal.controls.houseNoLocationModal.value,
            place_label:
              this.locationFormModal.controls.placeLabelLocationModal.value,
            notes: this.orderNotesNew,
          };
          localStorage.setItem(
            "profileAddress",
            JSON.stringify(this.myDeliveryObj)
          );
          localStorage.setItem(
            "profileAddressCheckout",
            JSON.stringify(this.myDeliveryObj)
          );

          this.makePrimeryAddress(this.myDeliveryObj.id, this.myDeliveryObj);
          localStorage.setItem("delivery_address", this.streetDelivery);
          localStorage.setItem("myDeliveryAddress", this.streetDelivery);

          $("#headAddressSelection").modal("hide");

          setTimeout(() => {
            this.setValuesinBusinessDetailPage(this.businessDetailObj);
          }, 100);
          this.router.navigate([
            "/businesses",
            this.locationFormModal.controls.cityLocationModal.value,
            this.businessDetName,
          ]);
          // after shop now removed code end
        },
        (error) => {}
      );
  }
  checkAddressInBusinessdetailPage() {
    this.businessDetName = localStorage.getItem("businessDetName");
    this.selectedCity = this.locationFormModal.controls.cityLocationModal.value;
    this.dataService
      .business_outlet_availability(
        this.businessDetName,
        this.locationFormModal.controls.cityLocationModal.value
      )
      .subscribe(
        (data) => {
          this.businessDetailObj = data;
          if (this.businessDetailObj.success == true) {
            // check cart
            if (
              this.myProfileAddress !== "null" &&
              this.myProfileAddress !== null
            ) {
              if (
                this.myProfileAddress.city !==
                  this.locationFormModal.controls.cityLocationModal.value &&
                this.mycartItems != "[]" &&
                this.mycartItems != null
              ) {
                // show empty cart
                $("#onChangingCityOnHomeModal").modal("show");
                this.blurAddressModal();
                return;
              }
            }
            $("#selectedAddressPopUpModal").modal("hide");
            $("#onChangingCityOnHomeModal").modal("hide");
            $("#wrongCityPopUpModal").modal("hide");
            $("#headAddressSelection").modal("hide");

            this.setValuesinBusinessDetailPage(this.businessDetailObj);
            localStorage.setItem("delivery_address", this.streetDelivery);
            localStorage.setItem("myDeliveryAddress", this.streetDelivery);

            this.myDeliveryObj = {
              id: "",
              street: this.locationFormModal.controls.areaLocationModal.value,
              address_line1:
                this.locationFormModal.controls.areaLocationModal.value,
              zip: this.locationFormModal.controls.postalLocationModal.value,
              city: this.locationFormModal.controls.cityLocationModal.value,
              country:
                this.locationFormModal.controls.countryLocationModal.value,
              state: this.locationFormModal.controls.stateLocationModal.value,
              latitude: this.latitudeDelivery,
              longitude: this.longitudeDelivery,
              apart_no: this.locationFormModal.controls.aptLocationModal.value,
              house_building_no:
                this.locationFormModal.controls.houseNoLocationModal.value,
              place_label:
                this.locationFormModal.controls.placeLabelLocationModal.value,
              notes: this.orderNotesNew,
            };
            localStorage.setItem(
              "profileAddress",
              JSON.stringify(this.myDeliveryObj)
            );
            localStorage.setItem(
              "profileAddressCheckout",
              JSON.stringify(this.myDeliveryObj)
            );
            this.router.navigate([
              "/businesses",
              this.locationFormModal.controls.cityLocationModal.value,
              this.businessDetName,
            ]);
          } else if (this.businessDetailObj.success == false) {
            // business not found modal show

            $("#selectedAddressPopUpModal").modal("hide");
            $("#onChangingCityOnHomeModal").modal("hide");
            $("#wrongCityPopUpModal").modal("hide");
            $("#headAddressSelection").modal("hide");
            $("#businessNotFoundCityModal").modal("show");
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  shopnowState: any = "";
  isCityEmpty: any = false;

  checkAddressInBusinessdetailPageForSuggestions(index) {
    this.businessDetName = localStorage.getItem("businessDetName");
    this.selectedCity = this.locationFormModal.controls.cityLocationModal.value;
    this.dataService
      .business_outlet_availability(
        this.businessDetName,
        this.locationFormModal.controls.cityLocationModal.value
      )
      .subscribe(
        (data) => {
          this.businessDetailObj = data;
          if (this.businessDetailObj.success == true) {
            if (
              this.myProfileAddress !== "null" &&
              this.myProfileAddress !== null
            ) {
              if (
                this.myProfileAddress.city !==
                  this.customerProfileListApp.address_dictionary[index].city &&
                this.mycartItems != "[]" &&
                this.mycartItems != null
              ) {
                $("#onChangingCityOnSuggestionModal").modal("show");
                this.blurAddressModal();
                this.isLocationFinalized = false;
                this.shopnowState = "disable";
                this.addressId =
                  this.customerProfileListApp.address_dictionary[index].id;
                return;
              }
            }
            $("#selectedAddressPopUpModal").modal("hide");
            $("#onChangingCityOnSuggestionModal").modal("hide");
            $("#wrongCityPopUpModal").modal("hide");
            // this.setValuesinBusinessDetailPage(this.businessDetailObj);
            this.isLocationFinalized = true;
            this.shopnowState = "enable";
            this.mySuggestedAddressSelectedIndex =
              this.customerProfileListApp.address_dictionary[index].id;
            this.streetDelivery = this.mySelectedaddress;
            this.isSuggestionAddressShownModal = false;
            this.isSearchAddressShownModal = false;
            this.addressId =
              this.customerProfileListApp.address_dictionary[index].id;
            this.orderNotesNew =
              this.customerProfileListApp.address_dictionary[index].notes;

            // after shop now removed code
            this.myDeliveryObj = {
              id: this.addressId,
              street: this.locationFormModal.controls.areaLocationModal.value,
              address_line1:
                this.locationFormModal.controls.areaLocationModal.value,
              zip: this.locationFormModal.controls.postalLocationModal.value,
              city: this.locationFormModal.controls.cityLocationModal.value,
              country:
                this.locationFormModal.controls.countryLocationModal.value,
              state: this.locationFormModal.controls.stateLocationModal.value,
              latitude: this.latitudeDelivery,
              longitude: this.longitudeDelivery,
              apart_no: this.locationFormModal.controls.aptLocationModal.value,
              house_building_no:
                this.locationFormModal.controls.houseNoLocationModal.value,
              place_label:
                this.locationFormModal.controls.placeLabelLocationModal.value,
              notes: this.orderNotesNew,
            };
            localStorage.setItem(
              "profileAddress",
              JSON.stringify(this.myDeliveryObj)
            );
            localStorage.setItem(
              "profileAddressCheckout",
              JSON.stringify(this.myDeliveryObj)
            );

            this.makePrimeryAddress(this.myDeliveryObj.id, this.myDeliveryObj);
            localStorage.setItem("delivery_address", this.streetDelivery);
            localStorage.setItem("myDeliveryAddress", this.streetDelivery);

            $("#headAddressSelection").modal("hide");

            setTimeout(() => {
              this.setValuesinBusinessDetailPage(this.businessDetailObj);
            }, 100);
            this.router.navigate([
              "/businesses",
              this.locationFormModal.controls.cityLocationModal.value,
              this.businessDetName,
            ]);
            // after shop now removed code end
          } else if (this.businessDetailObj.success == false) {
            // business not found modal show

            $("#selectedAddressPopUpModal").modal("hide");
            $("#onChangingCityOnHomeModal").modal("hide");
            $("#wrongCityPopUpModal").modal("hide");
            // $('#headAddressSelection').modal('hide');
            $("#businessNotFoundCityModal").modal("show");
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  businessDetName: any;
  businessDetailObj: any;
  setValuesinBusinessDetailPage(businessDetailObj) {
    this.dataControlService.setValuesinBusinessDetailPage.next(
      businessDetailObj
    );
  }

  emptyCartOnDeliverHereModal() {
    // change city so empty cart here
    $("#myAddressLabelApp").css("color", "unset");
    this.dataControlService.emptyCartOnAppComponent.next(true);
    this.disableMultiClick = true;
    this.events.publish("cartUpdate", []);
    localStorage.removeItem("cartItems");
    $("#onChangingCityOnHomeModal").modal("hide");
    this.disableMultiClick = false;
    $("#selectedAddressPopUpModal").modal("hide");

    if (this.isOnBusinessDetailPage == true) {
      this.checkAddressInBusinessdetailPageForEmptyCart();
      return;
    } else {
      localStorage.setItem("delivery_address", this.streetDelivery);
      localStorage.setItem("myDeliveryAddress", this.streetDelivery);

      this.myDeliveryObj = {
        id: "",
        street: this.locationFormModal.controls.areaLocationModal.value,
        address_line1: this.locationFormModal.controls.areaLocationModal.value,
        zip: this.locationFormModal.controls.postalLocationModal.value,
        city: this.locationFormModal.controls.cityLocationModal.value,
        country: this.locationFormModal.controls.countryLocationModal.value,
        state: this.locationFormModal.controls.stateLocationModal.value,
        latitude: this.latitudeDelivery,
        longitude: this.longitudeDelivery,
        apart_no: this.locationFormModal.controls.aptLocationModal.value,
        house_building_no:
          this.locationFormModal.controls.houseNoLocationModal.value,
        place_label:
          this.locationFormModal.controls.placeLabelLocationModal.value,
        notes: this.orderNotesNew,
      };
      localStorage.setItem(
        "profileAddress",
        JSON.stringify(this.myDeliveryObj)
      );
      localStorage.setItem(
        "profileAddressCheckout",
        JSON.stringify(this.myDeliveryObj)
      );
      this.router.navigate([
        "/businesses",
        this.locationFormModal.controls.cityLocationModal.value,
      ]);
    }
  }

  myDeliveryObj: any;

  emptyCartOnSuggestionModal() {
    $("#myAddressLabelApp").css("color", "unset");
    this.dataControlService.emptyCartOnAppComponent.next(true);
    this.disableMultiClick = true;
    this.events.publish("cartUpdate", []);
    localStorage.removeItem("cartItems");
    $("#onChangingCityOnSuggestionModal").modal("hide");
    this.disableMultiClick = false;
    this.isSuggestionAddressShownModal = false;
    this.isSearchAddressShownModal = false;
    this.isLocationFinalized = true;
    this.shopnowState = "enable";
    this.streetDelivery = this.mySelectedaddress;
    $("#selectedAddressPopUp").modal("hide");

    if (this.isOnBusinessDetailPage == true) {
      this.getAvailabilityData("empty");
    } else {
      // after shop now remove code
      this.myDeliveryObj = {
        id: this.addressId,
        street: this.locationFormModal.controls.areaLocationModal.value,
        address_line1: this.locationFormModal.controls.areaLocationModal.value,
        zip: this.locationFormModal.controls.postalLocationModal.value,
        city: this.locationFormModal.controls.cityLocationModal.value,
        country: this.locationFormModal.controls.countryLocationModal.value,
        state: this.locationFormModal.controls.stateLocationModal.value,
        latitude: this.latitudeDelivery,
        longitude: this.longitudeDelivery,
        apart_no: this.locationFormModal.controls.aptLocationModal.value,
        house_building_no:
          this.locationFormModal.controls.houseNoLocationModal.value,
        place_label:
          this.locationFormModal.controls.placeLabelLocationModal.value,
        notes: this.orderNotesNew,
      };
      localStorage.setItem(
        "profileAddress",
        JSON.stringify(this.myDeliveryObj)
      );
      localStorage.setItem(
        "profileAddressCheckout",
        JSON.stringify(this.myDeliveryObj)
      );

      this.makePrimeryAddress(this.myDeliveryObj.id, this.myDeliveryObj);
      localStorage.setItem("delivery_address", this.streetDelivery);
      localStorage.setItem("myDeliveryAddress", this.streetDelivery);

      $("#headAddressSelection").modal("hide");
      this.router.navigate([
        "/businesses",
        this.locationFormModal.controls.cityLocationModal.value,
      ]);
      // after shop now remove code end
    }
  }

  addProfileAddressModal() {
    this.orderNotesNew = "";

    this.dataService
      .addNewProfileAddreess(
        localStorage.getItem("token"),
        this.locationFormModal.controls.areaLocationModal.value,
        this.locationFormModal.controls.cityLocationModal.value,
        this.locationFormModal.controls.stateLocationModal.value,
        this.locationFormModal.controls.countryLocationModal.value,
        this.locationFormModal.controls.postalLocationModal.value,
        this.latitudeDelivery,
        this.longitudeDelivery,
        this.locationFormModal.controls.aptLocationModal.value,
        this.locationFormModal.controls.placeLabelLocationModal.value,
        this.locationFormModal.controls.houseNoLocationModal.value,
        this.locationFormModal.controls.streetLocationModal.value,
        this.orderNotesNew
      )
      .subscribe(
        (data) => {
          this.addAddressSuccess = data;
          if (this.addAddressSuccess.success === true) {
            this.myAddressObj = {
              address_id: this.addAddressSuccess.address_id,
              isAddedAddress: false,
              areaLocation:
                this.locationFormModal.controls.areaLocationModal.value,
              cityLocation:
                this.locationFormModal.controls.cityLocationModal.value,
              stateLocation:
                this.locationFormModal.controls.stateLocationModal.value,
              countryLocation:
                this.locationFormModal.controls.countryLocationModal.value,
              postalLocation:
                this.locationFormModal.controls.postalLocationModal.value,
              latitudeDelivery: this.latitudeDelivery,
              longitudeDelivery: this.longitudeDelivery,
              aptLocation:
                this.locationFormModal.controls.aptLocationModal.value,
              placeLabelLocation:
                this.locationFormModal.controls.placeLabelLocationModal.value,
              houseNoLocation:
                this.locationFormModal.controls.houseNoLocationModal.value,
              streetLocation:
                this.locationFormModal.controls.streetLocationModal.value,
              orderNotesNew: this.orderNotesNew,
            };
            this.makePrimeryAddress(
              this.myAddressObj.address_id,
              this.myAddressObj
            );

            $("#selectedAddressPopUpModal").modal("hide");

            localStorage.setItem("delivery_address", this.streetDelivery);
            localStorage.setItem("myDeliveryAddress", this.streetDelivery);
            this.router.navigate([
              "/businesses",
              this.locationFormModal.controls.cityLocationModal.value,
            ]);
          } else {
            toastr.error(this.addAddressSuccess.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  updateModifierPorduct(variantId, index, orderObj) {
    this.selectedVariant = index;
    if (orderObj.uniqueId != "") {
      this.oldUniqueId = orderObj.uniqueId;
    }
    this.dataService.getVariantDetail(orderObj.variant_id).subscribe(
      (res) => {
        let modifierObj = {
          quantityTotal: 1,
          name: orderObj.product_name,
          image: orderObj.product_image,
          variantName: orderObj.variant_name,
          optionValue: orderObj.variant_value,
          price: Math.round(orderObj.price),
          compare_price: Math.round(orderObj.compare_price),
          is_on_sale: orderObj.is_on_sale,
          variant_id: orderObj.variant_id,
          modifiers: res["modifiers"],
          addons: res["addons"],
          noOption: res["no_options"],
          error_msg: "",
        };
        this.productModifierDataArray = new Array();
        this.productModifierDataArray.push(modifierObj);
        console.log(this.customArray);
        this.newCart = this.customArray[0].products;
        for (
          let i = 0;
          i < this.productModifierDataArray[0].modifiers.length;
          i++
        ) {
          for (
            let j = 0;
            j < this.productModifierDataArray[0].modifiers[i].options.length;
            j++
          ) {
            orderObj["modifiers"].forEach((orderElement) => {
              if (
                this.productModifierDataArray[0].modifiers[i].options[j].id ==
                orderElement.options.id
              ) {
                this.productModifierDataArray[0].modifiers[i].options[
                  j
                ].selected = true;
              }
            });
          }
        }

        if (
          orderObj["addons"].length > 0 &&
          orderObj["addons"] != undefined &&
          orderObj["addons"] != null
        ) {
          for (
            let i = 0;
            i < this.productModifierDataArray[0].addons.options.length;
            i++
          ) {
            orderObj["addons"].forEach((orderElement) => {
              if (
                this.productModifierDataArray[0].addons.options[i].id ==
                orderElement.id
              ) {
                this.productModifierDataArray[0].addons.options[i].selected =
                  true;
              }
            });
          }
        }
        if (
          this.productModifierDataArray[0].noOption != null &&
          this.productModifierDataArray[0].noOption != undefined &&
          this.productModifierDataArray[0].noOption.options != undefined &&
          this.productModifierDataArray[0].noOption.options.length > 0
        ) {
          for (
            let i = 0;
            i < this.productModifierDataArray[0].noOption.options.length;
            i++
          ) {
            this.productModifierDataArray[0].noOption.options[i].selected =
              true;
          }
        }

        console.log(orderObj);
        if (
          orderObj["no_options"].length > 0 &&
          orderObj["no_options"] != undefined &&
          orderObj["no_options"] != null
        ) {
          for (let j = 0; j < orderObj["no_options"].length; j++) {
            for (
              let i = 0;
              i < this.productModifierDataArray[0].noOption.options.length;
              i++
            ) {
              if (
                this.productModifierDataArray[0].noOption.options[i].id ==
                orderObj["no_options"][j].id
              ) {
                this.productModifierDataArray[0].noOption.options[i].selected =
                  false;
                break;
              }
            }
          }
        }
        console.log(this.productModifierDataArray);
        console.log(this.modifierProductList);
        this.totalPriceCalculation();
        this.fromCart = true;
        $("#addVariantsModifiersPopUp").modal("show");
      },
      (err) => {
        console.log(err);
      }
    );
  }

  modModifiers = [];
  modAddons = [];
  modNoOptions = [];
  totalPriceCalculation() {
    let total = this.productModifierDataArray[0].price;
    let addOnPrice = 0;
    this.modifiersArr = [];
    this.modAddons = [];
    this.modNoOptions = [];
    console.log(this.newCart);
    console.log(this.selectedVariant);
    // this.newCart[this.selectedVariant].modifiers = [];
    this.addonsArr = [];
    // this.newCart[this.selectedVariant].addons = [];
    this.noOptionsArr = [];
    // this.newCart[this.selectedVariant].noOption = [];
    // this.varImage = this.productModifierDataArray[0].image;
    // this.varName = this.productModifierDataArray[0].variantNme;
    this.productModifierDataArray[0].modifiers.forEach((element) => {
      for (let i = 0; i < element.options.length; i++) {
        if (
          element.options[i].selected &&
          element.options[i].selected === true
        ) {
          total += element.options[i].price;
          addOnPrice += element.options[i].price;
          this.modifiersArr.push({
            title: element.title,
            options: element.options[i],
            id: element.id,
          });
        }
      }
    });

    if (
      this.productModifierDataArray[0].addons != undefined &&
      this.productModifierDataArray[0].addons != null &&
      this.productModifierDataArray[0].addons.options != null
    ) {
      this.productModifierDataArray[0].addons.options.forEach((element) => {
        if (element.selected && element.selected === true) {
          this.addonsArr.push(element);
          this.modAddons.push(element);
          // this.newCart[this.selectedVariant].addons.push(element);
          total += element.price;
          addOnPrice += element.price;
        }
      });
    }

    if (
      this.productModifierDataArray[0].noOption != undefined &&
      this.productModifierDataArray[0].noOption != null &&
      this.productModifierDataArray[0].noOption.options != null
    ) {
      this.productModifierDataArray[0].noOption.options.forEach((element) => {
        if (element.selected && element.selected === true) {
          total += element.price;
        }
        if (element.selected === false) {
          this.noOptionsArr.push(element);
          this.modNoOptions.push(element);
          // this.newCart[this.selectedVariant].noOption.push(element);
        }
      });
    }
    // this.newCart[this.selectedVariant].modifierPrice = total;
    // this.newCart[this.selectedVariant].additionalPrice = addOnPrice;
    this.addItValue = total;
    this.additonalValue = addOnPrice;
    console.log("Total price>>>>>>>>>>>>>>>>>>>>>", total, addOnPrice);
  }

  plusModifierValue(groupId, optionId) {
    // this.newCart = this.cartFinal;
    this.modifiersArr = [];
    this.modModifiers = [];
    // this.newCart[this.selectedVariant].modifiers = [];
    for (
      let i = 0;
      i < this.productModifierDataArray[0].modifiers.length;
      i++
    ) {
      if (this.productModifierDataArray[0].modifiers[i].id == groupId) {
        for (
          let j = 0;
          j < this.productModifierDataArray[0].modifiers[i].options.length;
          j++
        ) {
          if (
            this.productModifierDataArray[0].modifiers[i].options[j].id ==
            optionId
          ) {
            this.productModifierDataArray[0].modifiers[i].options[j].selected =
              true;
            if (
              this.productModifierDataArray[0].modifiers[i].options[j]
                .selected == true
            ) {
              let obj = {};
              obj = {
                id: this.productModifierDataArray[0].modifiers[i].id,
                title: this.productModifierDataArray[0].modifiers[i].title,
                options:
                  this.productModifierDataArray[0].modifiers[i].options[j],
              };
              this.modModifiers.push(obj);
              // this.newCart[this.selectedVariant].modifiers.push(obj);
            }
          } else {
            this.productModifierDataArray[0].modifiers[i].options[j].selected =
              false;
          }
        }
      }
    }
    this.totalPriceCalculation();
  }

  plusAddonsValue(optionId, event) {
    this.addonsArr = [];
    this.modAddons = [];
    console.log("Addons changed>>>>>>>>>>>>>>>>>>");
    // this.newCart[this.selectedVariant].addons = [];
    for (
      let i = 0;
      i < this.productModifierDataArray[0].addons.options.length;
      i++
    ) {
      if (optionId === this.productModifierDataArray[0].addons.options[i].id) {
        if (event.target.checked === true) {
          this.productModifierDataArray[0].addons.options[i].selected = true;
        } else if (event.target.checked === false) {
          this.productModifierDataArray[0].addons.options[i].selected = false;
        }
      }
    }
    this.totalPriceCalculation();
  }

  subNoOptionValue(optionId, event) {
    this.noOptionsArr = [];
    this.modNoOptions = [];
    // this.newCart[this.selectedVariant].noOption = [];
    for (
      let i = 0;
      i < this.productModifierDataArray[0].noOption.options.length;
      i++
    ) {
      if (
        optionId === this.productModifierDataArray[0].noOption.options[i].id
      ) {
        if (event.target.checked === false) {
          this.productModifierDataArray[0].noOption.options[i].selected = false;
        } else if (event.target.checked === true) {
          this.productModifierDataArray[0].noOption.options[i].selected = true;
        }
      }
    }
    this.totalPriceCalculation();
  }

  addItFunction(price, id) {
    this.newCart[this.selectedVariant].modifiers = this.modModifiers;
    this.newCart[this.selectedVariant].addons = this.modAddons;
    this.newCart[this.selectedVariant].noOption = this.modNoOptions;
    this.newCart[this.selectedVariant].modifierPrice = this.addItValue;
    this.newCart[this.selectedVariant].additionalPrice = this.additonalValue;
    this.updateCart = true;
    this.uniqueId = "";
    this.uniqueId = this.newCart[this.selectedVariant].variant_id;
    for (
      let i = 0;
      i < this.newCart[this.selectedVariant].modifiers.length;
      i++
    ) {
      this.uniqueId =
        this.uniqueId +
        "_" +
        this.newCart[this.selectedVariant].modifiers[i].options.id;
    }
    this.newCart[this.selectedVariant].addons.forEach((element) => {
      this.uniqueId = this.uniqueId + "_" + element.id;
    });
    this.newCart[this.selectedVariant].noOption.forEach((element) => {
      this.uniqueId = this.uniqueId + "_" + element.id;
    });
    if (this.oldUniqueId == "") {
      let result = this.existingModifierCheck(
        this.newCart[this.selectedVariant].variant_id,
        this.uniqueId
      );
      if (result == false) {
        this.newCart[this.selectedVariant].uniqueId = this.uniqueId;
        this.cartDataFromStorage = JSON.parse(
          localStorage.getItem("cartItems")
        );
        if (this.cartDataFromStorage == null) {
          this.cartDataFromStorage = [];
        }
        this.cartDataFromStorage.push(this.newCart[this.selectedVariant]);
        this.events.publish("cartUpdate", this.cartDataFromStorage);
      }
    } else {
      if (this.uniqueId == this.oldUniqueId) {
        console.log("here");
        this.oldUniqueId = "";
      } else {
        this.cartDataFromStorage = JSON.parse(
          localStorage.getItem("cartItems")
        );
        this.newCart[this.selectedVariant].uniqueId = this.uniqueId;
        var foundAtIndex = null;
        var matchedWithIndex = null;

        for (let i = 0; i < this.cartDataFromStorage.length; i++) {
          if (
            this.cartDataFromStorage[i].uniqueId != "" &&
            this.cartDataFromStorage[i].uniqueId == this.oldUniqueId
          ) {
            foundAtIndex = i;
            console.log(this.cartDataFromStorage);
          } else if (
            this.newCart[this.selectedVariant].uniqueId ==
            this.cartDataFromStorage[i].uniqueId
          ) {
            matchedWithIndex = i;
          }
        }
        if (foundAtIndex != null && matchedWithIndex != null) {
          this.cartDataFromStorage[matchedWithIndex].quantity += 1;
          this.cartDataFromStorage.splice(foundAtIndex, 1);
        } else if (foundAtIndex != null) {
          this.newCart[this.selectedVariant].uniqueId = this.uniqueId;
          // this.cartDataFromStorage[foundAtIndex] = this.newCart[this.selectedVariant];
          this.cartDataFromStorage[foundAtIndex].uniqueId =
            this.newCart[this.selectedVariant].uniqueId;
          this.cartDataFromStorage[foundAtIndex].addons =
            this.newCart[this.selectedVariant].addons;
          this.cartDataFromStorage[foundAtIndex].modifiers =
            this.newCart[this.selectedVariant].modifiers;
          this.cartDataFromStorage[foundAtIndex].noOption =
            this.newCart[this.selectedVariant].noOption;
          this.cartDataFromStorage[foundAtIndex].additionalPrice =
            this.newCart[this.selectedVariant].additionalPrice;
          this.cartDataFromStorage[foundAtIndex].hasModifier =
            this.newCart[this.selectedVariant].hasModifier;
          this.cartDataFromStorage[foundAtIndex].modifierPrice =
            this.newCart[this.selectedVariant].modifierPrice;
          this.cartDataFromStorage[foundAtIndex].priceWithAddons =
            this.newCart[this.selectedVariant].priceWithAddons;
        }
        this.oldUniqueId = "";
        foundAtIndex = null;
        matchedWithIndex = null;
        this.events.publish("cartUpdate", this.cartDataFromStorage);
        setTimeout(() => {
          console.log(
            "############################### Add it Function #################################"
          );
          this.updateFinalCartPop();
        }, 1000);
      }
    }

    $("#addVariantsModifiersPopUp").modal("hide");
  }
  existingModifierCheck(variantId, uniqueId) {
    const cartItem = JSON.parse(localStorage.getItem("cartItems"));
    let check = false;
    if (cartItem != undefined) {
      for (let i = 0; i < cartItem.length; i++) {
        if (
          cartItem[i].variant_id == variantId &&
          cartItem[i].uniqueId == uniqueId
        ) {
          cartItem[i].quantity = cartItem[i].quantity + 1;
          check = true;
        }
      }
    }
    this.events.publish("cartUpdate", cartItem);
    return check;
  }

  async modifierQuantityPlus(index, quantity, obj) {
    this.cartDataFromStorage = JSON.parse(localStorage.getItem("cartItems"));
    this.modifierProductList[index].quantity = quantity + 1;
    for (let i = 0; i < this.cartDataFromStorage.length; i++) {
      if (
        this.cartPopDataAll.business_id ===
          this.cartDataFromStorage[i].business_id &&
        this.cartPopDataWishlist.id ===
          this.cartDataFromStorage[i].product_id &&
        obj.variant_id === this.cartDataFromStorage[i].variant_id &&
        obj.uniqueId === this.cartDataFromStorage[i].uniqueId
      ) {
        this.cartDataFromStorage[i].quantity =
          this.modifierProductList[index].quantity;
        this.events.publish("cartUpdate", this.cartDataFromStorage);
      }
    }

    const cartData = JSON.parse(localStorage.getItem("cartItems"));
    for (var i = 0; i < this.wishlistData.length; i++) {
      for (var j = 0; j < this.wishlistData[i].product.length; j++) {
        this.wishlistData[i].product[j].count = 0;
        for (
          var k = 0;
          k < this.wishlistData[i].product[j].variant.length;
          k++
        ) {
          for (var l = 0; l < cartData.length; l++) {
            if (
              cartData[l].variant_id ===
              this.wishlistData[i].product[j].variant[k].id
            ) {
              this.wishlistData[i].product[j].count += cartData[l].quantity;
            }
          }
        }
      }
    }
  }
  async modifierQuantityMinus(index, quantity, obj) {
    this.cartDataFromStorage = JSON.parse(localStorage.getItem("cartItems"));
    this.modifierProductList[index].quantity = quantity - 1;
    for (let i = 0; i < this.cartDataFromStorage.length; i++) {
      if (
        this.cartPopDataAll.business_id ===
          this.cartDataFromStorage[i].business_id &&
        this.cartPopDataWishlist.id ===
          this.cartDataFromStorage[i].product_id &&
        obj.variant_id === this.cartDataFromStorage[i].variant_id &&
        obj.uniqueId === this.cartDataFromStorage[i].uniqueId
      ) {
        if (this.cartFinalWishlist[index].quantity < 1) {
          this.cartDataFromStorage.splice(i, 1);
          this.events.publish("cartUpdate", this.cartDataFromStorage);
        } else {
          this.cartDataFromStorage[i].quantity =
            this.modifierProductList[index].quantity;
          this.events.publish("cartUpdate", this.cartDataFromStorage);
        }
      }
    }
    if (this.modifierProductList[index].quantity < 1) {
      this.modifierProductList[index].quantity = 1;
      this.modifierProductList[index].addCartBtnShow = true;
    }

    const cartData = JSON.parse(localStorage.getItem("cartItems"));
    for (var i = 0; i < this.wishlistData.length; i++) {
      for (var j = 0; j < this.wishlistData[i].product.length; j++) {
        this.wishlistData[i].product[j].count = 0;
        for (
          var k = 0;
          k < this.wishlistData[i].product[j].variant.length;
          k++
        ) {
          for (var l = 0; l < cartData.length; l++) {
            if (
              cartData[l].variant_id ===
              this.wishlistData[i].product[j].variant[k].id
            ) {
              this.wishlistData[i].product[j].count += cartData[l].quantity;
            }
          }
        }
      }
    }
  }

  textUs() {
    this.closeNav();
    this.events.publish("textUsYourOrder", true);
  }

  Listing = [
    {
      group_name: "Flavour",
      add_option: [
        {
          option: ["chocolate", "banana", "apple", "vanila"],
        },
      ],
    },
    {
      group_name: "cakes",
      addOption: [
        {
          option: ["caremal", "chocolate, pineaple"],
        },
      ],
    },
  ];
  customOrder() {
    this.dataService.customOrder(this.business_id_customerOrder).subscribe(
      (data) => {
        this.closeNav();
        this.orderCustomizeDetail = data;
        console.log(this.orderCustomizeDetail);

        this.orderCustomizeDetail = this.orderCustomizeDetail.data;
        this.listing = this.orderCustomizeDetail.custom_order_details;
        console.log(this.listing);
        console.log(this.Listing);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  addCustomOrder() {
    this.orderbtn = false;
    this.nao.go(30);
    if (this.orderText === "" && this.customProductImage.length === 0) {
      toastr.error("Please add text or upload image");
      this.orderbtn = false;
      return;
    }

    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === undefined ||
      localStorage.getItem("token") === null
    ) {
      this.orderbtn = true;
      this.addBlur();
      this.events.publish("openLoginFunction", "true");
      return;
    }
    this.clearBlur();
    this.customProductImagesCount = 0;
    this.customOrderImageSuccessUrl = new Array();

    // this.orderbtn = true;
    if (this.customProductImage.length > 0) {
      var file = this.customProductImage[0];
      this.uploadOrderImage(file);
    } else {
      this.uploadOrderText();
    }
    // setTimeout(() => {
    // location.reload();
    // }, 5000 );
  }

  uploadOrderImage(file) {
    let data = {
      auth_token: localStorage.getItem("token"),
      image: file,
      dispensary_id: this.business_id_customerOrder,
      delivery_address: this.userCurrentAddress,
    };

    this.dataService.customOrderData(data).subscribe(
      (data) => {
        this.customOrderImageSuccess = data;
        if ((this.customOrderImageSuccess.success = true)) {
          this.customOrderImageSuccessUrl.push(
            this.customOrderImageSuccess.url
          );
          this.customProductImagesCount++;
          if (this.customProductImage.length > this.customProductImagesCount) {
            var file = this.customProductImage[this.customProductImagesCount];
            this.uploadOrderImage(file);
          } else {
            this.uploadOrderText();
          }
        } else {
          this.orderbtn = false;
          toastr.error("Something went wrong");
        }
      },
      (error) => {}
    );
  }

  customOrderSuccess: any;

  uploadOrderText() {
    this.nao.go(30);
    this.orderbtn = false;
    let data = {
      auth_token: localStorage.getItem("token"),
      images_list: this.customOrderImageSuccessUrl,
      order_text:
        this.finalOrderText +
        "," +
        "Additional Instructions" +
        ": " +
        this.orderText,
      dispensary_id: this.business_id_customerOrder,
      delivery_address: this.userCurrentAddress,
    };
    this.dataService.orderRequestText(data).subscribe(
      (data) => {
        this.nao.go(100);
        this.customOrderSuccess = data;
        if (this.customOrderSuccess.success === true) {
          toastr.success(
            "Your request has been successfuly sent. Our team will contact you soon."
          );
          $("#customiseOrder").modal("hide");
          this.finalOrderText = "";
          this.customOrderImageSuccessUrl = new Array();
          this.customProductImages = new Array();
        }
      },
      (error) => {}
    );
    console.log(this.customProductImage);
  }

  openOrderRequestPopup() {
    setTimeout(() => {
      $(".dz-preview").remove();
    }, 100);
    const Dropzone = require("dropzone");
    const context = this;
    $("#customiseOrder").modal("show");
    try {
      new Dropzone("#my-awesome-dropzone1", {
        maxFiles: 10,
        addRemoveLinks: true,
        dictDefaultMessage: "Upload Images",
        autoProcessQueue: false,
        paramName: "image",
        acceptedMimeTypes: "image/jpeg, image/jpg, image/png",
        progress: false,
        init: function () {
          context.dropzone = this;
          context.customProductImage = [];
          this.on("addedfile", function (file) {
            if (!file.type.match(/image.*/)) {
              // This is not an image, so Dropzone doesn't create a thumbnail.
              // Set a default thumbnail:
              // this.emit('thumbnail', file, '../app/assets/images/interface.png');
              // You could of course generate another image yourself here,
              // and set it as a data url.
            }
            context.customProductImage.push(file);
            context.checkBtnCondition("xyz");
          });

          this.on("error", function (file, response) {
            // $(file.previewElement).find('.dz-error-message').text(response.file_name);
            // context.error_count++;
          });

          this.on("queuecomplete", function () {});

          this.on("removedfile", function (file) {
            for (var i = 0; i < context.customProductImage.length; i++) {
              if (context.customProductImage[i].name === file.name) {
                context.customProductImage.splice(i, 1);
                context.checkBtnCondition("xyz");
              }
            }
          });

          this.on("processing", function () {
            this.options.autoProcessQueue = false;
          });

          context.dropzone = this;
        },
      });
    } catch (err) {}
  }

  checkBtnCondition(value) {
    let option = (<HTMLInputElement>document.getElementById("selectOrder"))
      .value;
    // let orderText = (<HTMLInputElement>document.getElementById('orderText')).value;
    console.log(this.customProductImage.length);
    if (option != "Select Option") {
      this.orderbtn = true;
    } else {
      this.orderbtn = false;
    }
  }
  selectDropdown(heading, option) {
    console.log(this.finalOrderText.search(heading));
    if (this.finalOrderText.search(heading) > -1) {
      let orderTextArray = this.finalOrderText.split(",");
      for (let i = 0; i < orderTextArray.length; i++) {
        if (orderTextArray[i].search(heading) > -1) {
          orderTextArray.splice(i, 1);
        }
        this.finalOrderText = orderTextArray.toString();
      }
    }
    this.groupOptions = heading + ": " + option;
    if (this.finalOrderText == "") {
      this.finalOrderText = this.finalOrderText + this.groupOptions;
    } else {
      this.finalOrderText = this.finalOrderText + "," + this.groupOptions;
    }
    console.log(this.finalOrderText);
  }

  showTaxInfo() {
    this.isFromHistory = false;
    $("#showTaxInfoPopup").modal("show");
    this.taxInfoProd = this.customArray[0].products;
    console.log("Custom Array tax info popup", this.taxInfoProd);
  }
  closeTaxInfoPop() {
    $("#showTaxInfoPopup").modal("hide");
  }

  dineinCheckoutFunction() {
    // this.paymentMethodOptionValue = 0;
    this.checkoutErrorMessage = "";
    this.checkoutFinalErrorMessagePriceUpdate = "";
    this.checkoutFinalErrorMessageOutOfStock = "";
    this.checkoutFinalErrorCount = 0;
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === undefined
    ) {
      this.number = "";
      this.isPlaceOrderSignin = true;
      $("#signIn").modal("show");
      this.fbRegLogin();
      $(".addCartShort").css("-webkit-filter", "blur(3px)");
      $(".addCartShort").css("-moz-filter", "blur(3px)");
      $(".addCartShort").css("-o-filter", "blur(3px)");
      $(".addCartShort").css("-ms-filter", "blur(3px)");
      $(".addCartShort").css("filter", "blur(3px)");
      this.callSigninIntlInput();
      return;
    }
    if (
      this.tableNumber == "" ||
      this.tableNumber == undefined ||
      this.tableNumber == -1
    ) {
      this.checkoutErrorMessage = "Please enter your table number.";
      return;
    }

    $("#dineinEnableLocation").modal({
      backdrop: "static",
      keyboard: false,
    });
    $("#dineinEnableLocation").modal("show");
    this.addBlur();
  }

  closeEnableLocationPop() {
    this.clearBlur();
    $("#dineinEnableLocation").modal("hide");
  }

  allowLocationFunction() {
    let clientLat;
    let clientLng;
    navigator.geolocation.getCurrentPosition((position) => {
      console.log(position);
      clientLat = position.coords.latitude;
      clientLng = position.coords.longitude;
      this.selectedLatitude = clientLat;
      this.selectedLongitude = clientLng;
      let data = {
        latitude: clientLat,
        longitude: clientLng,
        outlet_id: this.dineinOutletId != undefined ? this.dineinOutletId : -1,
        business_id: this.dataService.businessIdForIndependentBusinessData,
      };
      this.dataService.dineinVerifyLocation(data).subscribe(
        (res) => {
          console.log(res);
          if (res["show_popup"] == false) {
            this.closeEnableLocationPop();
            // $('#dineinEnableLocation').modal('hide');
            // $('#dineinFinalCheckoutPop').modal('show');
            this.startDineinOrderTimer();
          } else if (res["show_popup"] == true) {
            $("#dineinEnableLocation").modal("hide");
            $("#dineinErrorLocation").modal("show");
          }
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }

  startDineinOrderTimer() {
    console.log("order timer started!");
    this.orderTimerStart = true;
    this.orderTimer = setTimeout(() => {
      console.log("placing order!");
      this.orderTimerStart = false;
      this.disableCheckout = true;
      this.dineinPlaceOrderFinally();
    }, 3000);
  }

  stopDineinOrder() {
    console.log("stopping order");
    this.orderTimerStart = false;
    clearTimeout(this.orderTimer);
  }

  closeLocationErrorPop() {
    this.clearBlur();
    $("#dineinErrorLocation").modal("hide");
  }

  closeDineinFinalCheckOutPopup() {
    this.clearBlur();
    $("#dineinFinalCheckoutPop").modal("hide");
  }

  dineinPlaceOrderFinally() {
    this.checkoutDone = true;
    var dayForTime = new Date();
    var currentSystemTime = new Date(dayForTime).toISOString();
    var todayData = this.todayData;
    if (this.todayData === 0) {
      todayData = 7;
    }
    let paymentType;
    if (this.dineinPayment == "cash") {
      paymentType = 0;
    }
    if (this.dineinPayment == "card") {
      paymentType = 1;
    }
    let data = {
      auth_token: localStorage.getItem("token"),
      data_list: this.customArray,
      payment_gateway: paymentType,
      business_id: this.dataService.businessIdForIndependentBusinessData,
      outlet_id: this.dineinOutletId != undefined ? this.dineinOutletId : -1,
      current_time: currentSystemTime,
      table_num: this.tableNumber,
      current_day: todayData,
      day_index: todayData,
      selected_latitude: this.selectedLatitude,
      selected_longitude: this.selectedLongitude,
    };
    this.dataService.dineinCheckOut(data).subscribe(
      (data) => {
        this.disableCheckout = false;
        this.checkoutData = data;
        this.orderID = this.checkoutData.tracking_id;
        this.helplineNumber = this.checkoutData.helpline_number;
        this.checkoutMessage = this.checkoutData.message;
        this.checkoutSuccess = this.checkoutData.success;
        if (this.checkoutData.rest_error_code === 5) {
          toastr.error(this.checkoutData.message);
        }
        if (this.checkoutData.rest_error_code === 1) {
          toastr.error("Unable to placed the order");
        }
        if (this.checkoutData.rest_error_code === 2) {
          this.number = "";
          $("#signIn").modal("show");
          this.fbRegLogin();
          this.callSigninIntlInput();
          $(".addCartShort").css("-webkit-filter", "blur(3px)");
          $(".addCartShort").css("-moz-filter", "blur(3px)");
          $(".addCartShort").css("-o-filter", "blur(3px)");
          $(".addCartShort").css("-ms-filter", "blur(3px)");
          $(".addCartShort").css("filter", "blur(3px)");
          localStorage.setItem("isauthenticated", "false");
          localStorage.setItem("token", "");
          localStorage.setItem("displayName", "");
          localStorage.setItem("freeDeliveryCount", null);
          localStorage.setItem("userImage", "");
          this.setTokenToService();

          // this.router.navigate(['']);
          setTimeout(() => {
            var width = $(window).width();
            if (width >= 769) {
              if (this.dataService.pageName === "home") {
                if (window.pageYOffset === 0 || window.pageYOffset < 69) {
                  // $('.logInbtn').css('color', '#ffffff');
                } else {
                  // $('.logInbtn').css('color', this.colorCodeVar);
                }
              }
            } else {
              // $('.logInbtn').css('color', this.colorCodeVar);
              $(".logInbtn").css("color", "#FFFFFF");
            }
            if (this.dataService.pageName === "businesses") {
              // $('.logInbtn').css('color', this.colorCodeVar);
              $(".logInbtn").css("color", "#FFFFFF");
            }
          }, 1500);
        }
        if (this.checkoutSuccess === true) {
          this.orderPlacedFrom = JSON.parse(
            localStorage.getItem("cartBusinessData")
          ).name;
          // save address if not

          let myProfileAddress = JSON.parse(
            localStorage.getItem("profileAddress")
          );
          if (myProfileAddress != "null" && myProfileAddress != null) {
            if (myProfileAddress.id === "") {
              // save address
              this.labelDelivery = myProfileAddress.place_label;
              this.editAddressId = myProfileAddress.id;
              this.streetDelivery = myProfileAddress.address_line1;
              this.zipDelivery = myProfileAddress.zip;
              this.cityDelivery = myProfileAddress.city;
              this.countryDelivery = myProfileAddress.country;
              this.stateDelivery = myProfileAddress.state;
              this.latitudeDelivery = myProfileAddress.latitude;
              this.longitudeDelivery = myProfileAddress.longitude;
              this.apartDelivery = myProfileAddress.apart_no;
              this.orderNotesNew = myProfileAddress.notes;
              this.houseNoDelivery = myProfileAddress.house_building_no;
              this.streetNoDelivery = myProfileAddress.street;
              this.addProfileAddress();
            }
          }
          this.addressUsedForOrder = "Dining at:";
          this.OrderSubmitted = true;
          this.fbOrderPlaced();
          $("#dineinFinalCheckoutPop").modal("hide");
          this.clearBlur();
          toastr.success("Your order has been placed");
          this.orderNotes = "";
          this.events.publish("cartUpdate", null);
          this.events.publish("checkoutDone", null);
          localStorage.setItem(
            "freeDeliveryCount",
            this.checkoutData.free_delivery_count
          );
          this.carBtnShowHide = false;
          this.setCheckoutAddressMainAddress();
          $("#orderDeliveredSuccess").modal("show");
          this.orderNotes = "";
          this.orderNotesNew = "";
          setTimeout(() => {
            this.checkoutDone = false;
          }, 2000);
        }
        this.showOrderMediumNav = false;
        setTimeout(() => {
          this.checkoutDone = false;
        }, 2000);
        // this.getBusinessData();
      },
      (error) => {
        this.disableCheckout = false;
      }
    );
  }

  changeTableNumber() {
    $("#changeTableNumberPopup").modal("show");
  }

  openOutletAddressPop(openPop: boolean = true) {
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === undefined ||
      localStorage.getItem("token") === null
    ) {
      this.number = "";
      $("#signIn").modal("show");
      this.fbRegLogin();
      this.callSigninIntlInput();
      $(".addCartShort").css("-webkit-filter", "blur(3px)");
      $(".addCartShort").css("-moz-filter", "blur(3px)");
      $(".addCartShort").css("-o-filter", "blur(3px)");
      $(".addCartShort").css("-ms-filter", "blur(3px)");
      $(".addCartShort").css("filter", "blur(3px)");
    } else {
      console.log(this.businessDetailData);
      let data = {
        auth_token: localStorage.getItem("token"),
        business_id:
          localStorage.getItem("cartBusinessData") == null
            ? this.businessDetailData.id
            : JSON.parse(localStorage.getItem("cartBusinessData")).id,
      };
      this.dataService.getOutlet(data).subscribe(
        (res) => {
          this.outlets = res["items"];
          if (openPop) {
            if (!this.pickupAddress) {
              console.log("insomniac: selecting first outlet", this.outlets[0]);
              this.selectPickupOutlet(
                this.outlets[0].address,
                this.outlets[0].id,
                this.outlets[0].name,
                true
              );
            }
            $("#outletAddresslisting").modal("show");
          } else {
            // select the outlet whose ID was found in @param 'dineinOutletId'
            this.outlets.forEach((outlet) => {
              if (parseInt(this.dineinOutletId) == outlet.id) {
                console.log("insomniac: selecting outlet", outlet);
                if (this.landedFromQR) {
                  this.pickupAddressString =
                    this.businessDetailData.name + " - " + this.outletName;
                }
                this.selectPickupOutlet(
                  outlet.address,
                  outlet.id,
                  outlet.name,
                  true
                );
              }
            });
          }
          console.log(this.outlets);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  selectPickupOutlet(
    addressObj,
    id,
    name,
    showTimeSlotAfterSelect: boolean = false
  ) {
    this.checkOutState = 2;
    this.pickupAddressId = id;
    this.pickupAddress = addressObj;
    if (this.landedFromQR && showTimeSlotAfterSelect) {
      this.pickupAddressString =
        this.businessDetailData.name + " - " + this.outletName;
    } else {
      this.pickupAddressString =
        name + " - " + this.pickupAddress.address_line1;
    }
    this.deliveryAddressObj = {
      id: id,
      street: this.pickupAddress.street,
      address_line1: this.pickupAddress.address_line1,
      zip: this.pickupAddress.zip,
      city: this.pickupAddress.city,
      country: this.pickupAddress.area,
      state: this.pickupAddress.state,
      latitude: this.pickupAddress.latitude,
      longitude: this.pickupAddress.longitude,
      apart_no: this.pickupAddress.apart_no,
      notes: "",
      house_building_no: "",
    };
    console.log("Delivery address obj set to: => ", this.deliveryAddressObj);
    this.getSlotDataForOutlet();
    $("#outletAddresslisting").modal("hide");
    this.enableBackground();
  }

  pickupCheckoutFunction() {
    // this.paymentMethodOptionValue = 0;
    this.checkoutErrorMessage = "";
    this.checkoutFinalErrorMessagePriceUpdate = "";
    this.checkoutFinalErrorMessageOutOfStock = "";
    this.checkoutFinalErrorCount = 0;
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === undefined
    ) {
      this.number = "";
      this.isPlaceOrderSignin = true;
      $("#signIn").modal("show");
      this.fbRegLogin();
      $(".addCartShort").css("-webkit-filter", "blur(3px)");
      $(".addCartShort").css("-moz-filter", "blur(3px)");
      $(".addCartShort").css("-o-filter", "blur(3px)");
      $(".addCartShort").css("-ms-filter", "blur(3px)");
      $(".addCartShort").css("filter", "blur(3px)");
      this.callSigninIntlInput();
      return;
    }

    if (this.pickupAddress === null || this.pickupAddress === undefined) {
      this.checkoutErrorMessage = "Please select your pickup point";
      return;
    }

    if (this.dineinPayment === "-1") {
      this.checkoutErrorMessage = "Please select payment type";
      return;
    }

    if (this.timeSlotDetail === 0) {
      if (this.third_party_string === "") {
        toastr.error("Please select your delivery way");
        return;
      }
    }
    if (this.timeSlotDetail === 1) {
      if (this.time === undefined || this.time === null) {
        toastr.error("Please select a time slot");
        return;
      }
    }
    if (this.time === null) {
      this.time = {};
    }
    this.startPickupOrderTimer();

    // $('#pickupFinalCheckoutPop').modal({
    //   backdrop: 'static',
    //   keyboard: false
    // });
    // $('#pickupFinalCheckoutPop').modal('show');
    // this.addBlur();
  }

  startPickupOrderTimer() {
    console.log("order timer started!");
    this.orderTimerStart = true;
    this.orderTimer = setTimeout(() => {
      console.log("placing order!");
      this.orderTimerStart = false;
      this.disableCheckout = true;
      this.pickupPlaceOrderFinally();
    }, 3000);
  }

  stopPickupOrder() {
    console.log("stopping order");
    this.orderTimerStart = false;
    clearTimeout(this.orderTimer);
  }

  closePickupFinalCheckOutPopup() {
    this.clearBlur();
    $("#pickupFinalCheckoutPop").modal("hide");
  }

  pickupPlaceOrderFinally() {
    this.checkoutDone = true;
    var dayForTime = new Date();
    var currentSystemTime = new Date(dayForTime).toISOString();
    var todayData = this.todayData;
    if (this.todayData === 0) {
      todayData = 7;
    }
    let data = {
      auth_token: localStorage.getItem("token"),
      data_list: this.customArray,
      payment_gateway: this.paymentMethodOptionValue,
      business_id: this.dataService.businessIdForIndependentBusinessData,
      outlet_id: this.outletIdToSendInCheckout,
      current_time: currentSystemTime,
      current_day: todayData,
      day_index: todayData,
      open_time: this.time.open_time,
      close_time: this.time.close_time,
      pick_up_notes: this.pickupNotes,
    };
    console.log(data);
    this.dataService.pickupCheckOut(data).subscribe(
      (data) => {
        this.disableCheckout = false;
        this.checkoutData = data;
        this.orderID = this.checkoutData.tracking_id;
        this.helplineNumber = this.checkoutData.helpline_number;
        this.checkoutMessage = this.checkoutData.message;
        this.checkoutSuccess = this.checkoutData.success;
        if (this.checkoutData.rest_error_code === 5) {
          toastr.error(this.checkoutData.message);
        }
        if (this.checkoutData.rest_error_code === 1) {
          toastr.error("Unable to placed the order");
        }
        if (this.checkoutData.rest_error_code === 2) {
          this.number = "";
          $("#signIn").modal("show");
          this.fbRegLogin();
          this.callSigninIntlInput();
          $(".addCartShort").css("-webkit-filter", "blur(3px)");
          $(".addCartShort").css("-moz-filter", "blur(3px)");
          $(".addCartShort").css("-o-filter", "blur(3px)");
          $(".addCartShort").css("-ms-filter", "blur(3px)");
          $(".addCartShort").css("filter", "blur(3px)");
          localStorage.setItem("isauthenticated", "false");
          localStorage.setItem("token", "");
          localStorage.setItem("displayName", "");
          localStorage.setItem("freeDeliveryCount", null);
          localStorage.setItem("userImage", "");
          this.setTokenToService();

          // this.router.navigate(['']);
          setTimeout(() => {
            var width = $(window).width();
            if (width >= 769) {
              if (this.dataService.pageName === "home") {
                if (window.pageYOffset === 0 || window.pageYOffset < 69) {
                  // $('.logInbtn').css('color', '#ffffff');
                } else {
                  // $('.logInbtn').css('color', this.colorCodeVar);
                }
              }
            } else {
              // $('.logInbtn').css('color', this.colorCodeVar);
              $(".logInbtn").css("color", "#FFFFFF");
            }
            if (this.dataService.pageName === "businesses") {
              // $('.logInbtn').css('color', this.colorCodeVar);
              $(".logInbtn").css("color", "#FFFFFF");
            }
          }, 1500);
        }
        if (this.checkoutSuccess === true) {
          this.orderPlacedFrom = JSON.parse(
            localStorage.getItem("cartBusinessData")
          ).name;
          // save address if not

          let myProfileAddress = JSON.parse(
            localStorage.getItem("profileAddress")
          );
          if (myProfileAddress != "null" && myProfileAddress != null) {
            if (myProfileAddress.id === "") {
              // save address
              this.labelDelivery = myProfileAddress.place_label;
              this.editAddressId = myProfileAddress.id;
              this.streetDelivery = myProfileAddress.address_line1;
              this.zipDelivery = myProfileAddress.zip;
              this.cityDelivery = myProfileAddress.city;
              this.countryDelivery = myProfileAddress.country;
              this.stateDelivery = myProfileAddress.state;
              this.latitudeDelivery = myProfileAddress.latitude;
              this.longitudeDelivery = myProfileAddress.longitude;
              this.apartDelivery = myProfileAddress.apart_no;
              this.orderNotesNew = myProfileAddress.notes;
              this.houseNoDelivery = myProfileAddress.house_building_no;
              this.streetNoDelivery = myProfileAddress.street;
              this.addProfileAddress();
            }
          }
          this.addressUsedForOrder = "Pickup Point:";
          this.OrderSubmitted = true;
          this.fbOrderPlaced();
          $("#pickupFinalCheckoutPop").modal("hide");
          this.clearBlur();
          toastr.success("Your order has been placed");
          this.orderNotes = "";
          this.events.publish("cartUpdate", null);
          this.events.publish("checkoutDone", null);
          localStorage.setItem(
            "freeDeliveryCount",
            this.checkoutData.free_delivery_count
          );
          this.carBtnShowHide = false;
          this.setCheckoutAddressMainAddress();
          $("#orderDeliveredSuccess").modal("show");
          this.orderNotes = "";
          this.orderNotesNew = "";
          setTimeout(() => {
            this.checkoutDone = false;
          }, 2000);
        }
        this.showOrderMediumNav = false;
        setTimeout(() => {
          this.checkoutDone = false;
        }, 2000);
        // this.getBusinessData();
      },
      (error) => {
        this.disableCheckout = false;
      }
    );
  }

  closeOutletErrorPop() {
    if (this.checkQrStatus == 1) {
      $("#delivery").addClass("checkout-tags");
      $("#dine-in").removeClass("checkout-tags");
      $("#self-pickup").removeClass("checkout-tags");
      this.dineIn = false;
      this.selfPickup = false;
      this.delivery = true;
      this.tableNumber = -1;
      this.qrCode = "false";
      this.checkQrStatus = 1;
    } else if (this.checkQrStatus == 2) {
      $("#self-pickup").addClass("checkout-tags");
      $("#dine-in").removeClass("checkout-tags");
      $("#delivery").removeClass("checkout-tags");
      $(".qr-scanner").css("display", "none");
      this.dineIn = false;
      this.selfPickup = true;
      this.delivery = false;
      this.tableNumber = -1;
      this.qrCode = "false";
      this.checkQrStatus = 2;
    } else {
      let table;
      this.route.queryParams.subscribe((params) => {
        if (params["qrcode"] != undefined || params["qrcode"] != null) {
          this.qrCode = params["qrcode"];
          this.landedFromQR = true;
          this.showDownloadBanner = true;
          this.dataControlService.showBanner$.next(true);
        }
        if (
          params["tablenumber"] != undefined ||
          params["tablenumber"] != null
        ) {
          this.tableNumber = params["tablenumber"];
          table = this.tableNumber;
          console.log("table number", this.tableNumber);
        }
        if (params["outlet_id"] != undefined || params["outlet_id"] != null) {
          this.dineinOutletId = params["outlet_id"];
        }
        if (params["outletname"] != undefined || params["outletname"] != null) {
          this.outletName = params["outletname"];
        }
        if (params["type"] != undefined || params["type"] != null) {
          this.type = params["type"];
          this.type = this.type.toLowerCase();
          if (this.type == "dinein") {
            this.dineIn = true;
            this.selfPickup = false;
            this.delivery = false;
            this.dineinAddressString =
              this.businessDetailData.name + " - " + this.outletName;
          } else if (this.type == "pickup") {
            this.dineIn = false;
            this.selfPickup = true;
            this.delivery = false;
          } else {
            this.dineIn = false;
            this.selfPickup = false;
            this.delivery = true;
          }
        }
      });
      $("#dine-in").addClass("checkout-tags");
      $("#delivery").removeClass("checkout-tags");
      $("#self-pickup").removeClass("checkout-tags");
      this.dineIn = true;
      this.selfPickup = false;
      this.delivery = false;
      this.tableNumber = table;
      this.qrCode = "true";
    }
    this.clearBlur();
    $("#dineinOutletError").modal("hide");
  }

  orderHistoryTaxInfo(variant) {
    this.isFromHistory = true;
    this.taxInfoProd = new Array();
    let obj;
    console.log("Tax variant", variant);
    for (let i = 0; i < variant.length; i++) {
      obj = {
        product_name: variant[i].variant.name,
        taxPrice: variant[i].variant.tax_on_variant,
        price: variant[i].variant.variant_modifier_price_string,
        quantity: variant[i].quantity,
        unitTax: variant[i].variant.tax_percentage,
      };
      console.log("history tax Info", obj);
      this.taxInfoProd.push(obj);
    }
    setTimeout(() => {
      $("#showTaxInfoPopup").modal("show");
    }, 100);
  }

  taxInfoCalculate(price, quantity, taxPercentage) {
    return taxPercentage !== 0
      ? Math.round(price * +quantity * (taxPercentage / 100))
      : 0;
  }

  openHealthRecordsModal() {
    this.closeNav();
    this.disableBackground();
    $("#healthRecordProfileListingModal").modal("show");
    this.getHealthRecordProfiles();
    this.gethealthRecords(this.healthRecordType);
  }

  // health record listing variables.
  healthRecordType: number = 0;
  healthRecordPage: number = 0;
  isHealthRecordLoading: boolean = true;
  isHealthRecordlastPage: boolean = false;
  healthRecords: any[] = [];

  gethealthRecords(type) {
    this.healthRecordType = type;
    this.healthRecords = [];
    this.isHealthRecordLoading = true;
    this.nao.go(30);
    this.dataService
      .getHealthRecords(
        localStorage.getItem("token"),
        this.activeHealthProfileId,
        this.healthRecordType,
        this.healthRecordPage,
        10
      )
      .subscribe((res: any) => {
        if (res.items) {
          this.healthRecords = res.items || [];
          this.isHealthRecordlastPage = res.last_page;
          this.isHealthRecordLoading = false;
        } else {
          toastr.error("Something went wrong.");
        }
        this.nao.go(100);
      });
  }

  gethealthRecordsNextPage() {
    this.nao.go(30);
    this.healthRecordPage++;
    this.dataService
      .getHealthRecords(
        localStorage.getItem("token"),
        this.activeHealthProfileId,
        this.healthRecordType,
        this.healthRecordPage,
        10
      )
      .subscribe((res: any) => {
        res.items.forEach((item) => {
          this.healthRecords.push(item);
        });
        this.isHealthRecordlastPage = res.last_page;
        this.nao.go(100);
      });
  }

  // *********************************************** Health Profile Data ***********************************************

  openRecordProfileListing() {
    $("#healthRecordProfileListingModal").modal("show");
    this.getHealthRecordProfiles();
  }

  openAddRecordProfileModal() {
    this.healthProfileData = {
      id: null,
      image: undefined,
      imagePath: "",
      imageURL: "",
      firstName: "",
      lastName: "",
      phone_number: "",
      isPhoneNumberValid: false,
      dob: undefined,
      relation: "",
      loading: false,
      editFlag: false,
    };
    $("#addHealthRecordProfileModal").modal("show");
    this.initializeTelInput();
  }

  initializeTelInput() {
    setTimeout(() => {
      $("#health-profile-phone").intlTelInput({
        // autoFormat: true,
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.9/js/utils.js",
        autoPlaceholder: false,
        preferredCountries: ["GB"],
        preventInvalidNumbers: true,
        // stop the user from typing invalid dial codes
        preventInvalidDialCodes: true,
        formatOnDisplay: true,
        separateDialCode: false,
      });
      var countryCode;
      setTimeout(() => {
        countryCode = $("#health-profile-phone").intlTelInput(
          "getSelectedCountryData"
        ).dialCode;
        $("#health-profile-phone").val("+" + countryCode);
      }, 1000);
      $("#health-profile-phone").change(function () {
        countryCode = $("#health-profile-phone").intlTelInput(
          "getSelectedCountryData"
        ).dialCode;
        $("#health-profile-phone").val("+" + countryCode);
      });
    }, 1000);
  }

  activeHealthProfileId: number = undefined;
  activeHealthProfile: any = undefined;
  healthRecordProfiles: any[] = [];
  getHealthRecordProfiles() {
    this.nao.go(30);
    this.dataService.getHealthRecordProfiles().subscribe((res: any) => {
      this.nao.go(100);
      this.healthRecordProfiles = res.profiles || [];
      console.log("health", this.healthRecordProfiles);
      this.healthRecordProfiles.forEach((profile) => {
        if (profile.id == this.activeHealthProfileId) {
          profile.isActive = true;
          this.activeHealthProfile = profile;
        } else {
          profile.isActive = false;
        }
      });
    });
  }

  selectHealthProfile(profile_id) {
    this.activeHealthProfileId = profile_id;
    $("#healthRecordProfileListingModal").modal("hide");
    this.healthRecordProfiles.forEach((profile) => {
      if (profile.id == this.activeHealthProfileId) {
        profile.isActive = true;
        this.activeHealthProfile = profile;
      } else {
        profile.isActive = false;
      }
    });
    setTimeout(() => {
      $("#healthRecordModal").modal("show");
    }, 500);
    $('[href="#Prescription"]').tab("show");

    this.healthRecordType = 0;
    this.gethealthRecords(this.healthRecordType);
  }

  healthProfileData: {
    id: number;
    image: File;
    imagePath: any;
    imageURL: String;
    firstName: String;
    lastName: String;
    phone_number: String;
    isPhoneNumberValid: boolean;
    dob: Date;
    relation: String;
    loading: boolean;
    editFlag: boolean;
  } = {
    id: null,
    image: undefined,
    imagePath: "",
    imageURL: "",
    firstName: "",
    lastName: "",
    phone_number: "",
    isPhoneNumberValid: false,
    dob: undefined,
    relation: "",
    loading: false,
    editFlag: false,
  };

  healthProfilePhoneValidation() {
    var code = $("#health-profile-phone").intlTelInput(
      "getSelectedCountryData"
    ).dialCode;
    code = "+" + code;
    var val = $("#health-profile-phone-value").val();
    for (var i = 0; i < this.cs.country.length; i++) {
      if (code === this.cs.country[i].countryCode) {
        if (val.length === this.cs.country[i].length) {
          this.healthProfileData.isPhoneNumberValid = true;
          this.healthProfileData.phone_number = code + val;
          return;
        }
      }
    }
  }

  onProfileImageChanged(file: File) {
    this.healthProfileData.image = file;
    const reader = new FileReader();
    reader.onload = (e) => (this.healthProfileData.imagePath = reader.result);
    reader.readAsDataURL(file);
  }

  ValidateProfileData() {
    if (!this.healthProfileData.firstName) {
      return toastr.error("Add First Name.");
    } else if (!this.healthProfileData.dob) {
      return toastr.error("Select the date of birth.");
    } else if (!this.healthProfileData.relation) {
      return toastr.error("Add a Relation.");
    } else if (
      this.healthProfileData.phone_number &&
      !this.healthProfileData.isPhoneNumberValid
    ) {
      return toastr.error("Phone number is not valid.");
    } else if (!this.healthProfileData.image) {
      this.addNewHealthProfile();
    } else {
      this.uploadProfileImage();
    }
  }

  uploadProfileImage() {
    this.healthProfileData.loading = true;
    this.dataService
      .updateHealthProfileImage(
        localStorage.getItem("token"),
        this.healthProfileData.imagePath.split(",")[1]
      )
      .subscribe((res: any) => {
        if (res.img_url) {
          this.healthProfileData.imageURL = res.img_url;
          this.addNewHealthProfile();
        } else {
          toastr.error("Something went wrong!");
        }
      });
  }

  addNewHealthProfile() {
    this.healthProfileData.loading = true;
    var payload;
    // let newDob:any = new Date(this.healthProfileData.dob);
    // newDob=newDob.toLocaleString().split(',')[0];
    if (this.healthProfileData.id) {
      payload = {
        auth_token: localStorage.getItem("token"),
        profile_id: this.healthProfileData.id,
        first_name: this.healthProfileData.firstName,
        last_name: this.healthProfileData.lastName,
        dob: this.healthProfileData.dob,
        relation: this.healthProfileData.relation || "",
        phone_number: this.healthProfileData.phone_number || "",
        image: this.healthProfileData.imageURL || "",
      };
    } else {
      payload = {
        auth_token: localStorage.getItem("token"),
        first_name: this.healthProfileData.firstName,
        last_name: this.healthProfileData.lastName,
        dob: this.healthProfileData.dob,
        relation: this.healthProfileData.relation || "",
        phone_number: this.healthProfileData.phone_number || "",
        image: this.healthProfileData.imageURL || "",
      };
    }
    let newDate: any = payload.dob;
    newDate ? (payload.dob = newDate.formatted) : "";
    if (this.healthProfileData.editFlag) {
      this.dataService.editHealthProfile(payload).subscribe((res: any) => {
        if (res.success == true) {
          this.resetHealthProfileData();
          toastr.success(res.message);
          $("#addHealthRecordProfileModal").modal("hide");
          this.openRecordProfileListing();
        } else {
          toastr.error("Something went wrong!");
        }
        this.healthProfileData.loading = false;
      });
    } else {
      this.dataService.addHealthProfile(payload).subscribe((res: any) => {
        if (res.success == true) {
          this.resetHealthProfileData();
          toastr.success(res.message);
          $("#addHealthRecordProfileModal").modal("hide");
          this.openRecordProfileListing();
        } else {
          toastr.error("Something went wrong!");
        }
        this.healthProfileData.loading = false;
      });
    }
  }

  editHealthProfile(profile) {
    this.healthProfileData = {
      id: profile.id,
      image: profile.image,
      imagePath: profile.image,
      imageURL: "",
      firstName: profile.first_name,
      lastName: profile.last_name,
      phone_number: profile.phone_number,
      isPhoneNumberValid: false,
      dob: profile.dob,
      relation: profile.relation,
      loading: false,
      editFlag: true,
    };
    // let newDob :any =  this.healthProfileData.dob;
    //  newDob =  newDob.split('/') ;
    // let  model: any = {date: {year: newDob[2], month: newDob[0], day: newDob[1]}};
    // this.healthProfileData.dob= model;
    this.initializeTelInput();
    $("#addHealthRecordProfileModal").modal("show");
    if (profile.phone_number) {
      var phone: string = profile.phone_number;
      $("#health-profile-phone-value").val(phone.substring(3));
    }
  }

  deleteHealthProfile(profile) {
    var payload = {
      auth_token: localStorage.getItem("token"),
      profile_id: profile.id,
    };
    this.nao.go(30);
    this.dataService.deleteHealthProfile(payload).subscribe((res: any) => {
      this.nao.go(100);
      if (res.success == true) {
        toastr.success(res.message);
        this.getHealthRecordProfiles();
      } else {
        toastr.error("Something went wrong");
      }
    });
  }

  resetHealthProfileData() {
    this.healthProfileData = {
      id: null,
      image: undefined,
      imagePath: "",
      imageURL: "",
      firstName: "",
      lastName: "",
      phone_number: "",
      isPhoneNumberValid: false,
      dob: undefined,
      relation: "",
      loading: false,
      editFlag: false,
    };
  }

  // *********************************************** Health Record Data ***********************************************

  addRecord: {
    id: number;
    type: number;
    date: Date;
    clinicName: String;
    doctorName: String;
    notes: String;
    img_list: File[];
    img_urls: String[];
    old_img_urls: String[];
    uploaded_images_count: number;
    editFlag: boolean;
    loading: boolean;
  } = {
    id: null,
    type: 0,
    date: undefined,
    clinicName: "",
    doctorName: "",
    notes: "",
    img_list: [],
    img_urls: [],
    old_img_urls: [],
    uploaded_images_count: 0,
    editFlag: false,
    loading: false,
  };

  openAddRecordModal() {
    this.addRecord = {
      id: null,
      type: 0,
      date: undefined,
      clinicName: "",
      doctorName: "",
      notes: "",
      img_list: [],
      img_urls: [],
      old_img_urls: [],
      uploaded_images_count: 0,
      editFlag: false,
      loading: false,
    };
    $("#addHealthRecordModal").modal("show");
    this.initializeDropzone();
  }

  initializeDropzone() {
    const Dropzone = require("dropzone");
    const context = this;
    try {
      new Dropzone("#record-image-dropzone", {
        maxFiles: 10,
        addRemoveLinks: true,
        dictDefaultMessage: "Upload Images",
        autoProcessQueue: false,
        paramName: "image",
        acceptedMimeTypes: "image/jpeg, image/jpg, image/png",
        progress: false,
        init: function () {
          context.dropzone = this;

          this.on("addedfile", function (file) {
            if (!file.type.match(/image.*/)) {
              // This is not an image, so Dropzone doesn't create a thumbnail.
              // Set a default thumbnail:
              // this.emit('thumbnail', file, '../app/assets/images/interface.png');
              // You could of course generate another image yourself here,
              // and set it as a data url.
            }
            context.addRecord.img_list.push(file);
          });

          this.on("error", function (file, response) {
            // $(file.previewElement).find('.dz-error-message').text(response.file_name);
            // context.error_count++;
          });

          this.on("queuecomplete", function () {
            // $('#myloader').removeClass('cssload-container');
            // $('#loader_container').removeClass('floatingBarsG');
            // $('.blockG').addClass('myopc');
            // if(context.error_count>0){
            //     toastr.error('Error uploading images.');
            // }
            // else{
            //      toastr.success('Property added successfully.');
            //      context.navigate();
            // }
          });
          this.on("removedfile", function (file) {
            for (var i = 0; i < context.addRecord.img_list.length; i++) {
              if (context.addRecord.img_list[i].name === file.name) {
                context.addRecord.img_list.splice(i, 1);
              }
            }
          });
          this.on("processing", function () {
            this.options.autoProcessQueue = false;
          });
          context.dropzone = this;
        },
      });
    } catch (err) {}
  }

  ValidateNewRecordData() {
    if (!this.addRecord.clinicName) {
      if (this.healthRecordType == 0) {
        return toastr.error("Enter Hospital/Clinic name.");
      } else {
        return toastr.error("Enter Lab name.");
      }
    }
    //  else if(!this.addRecord.doctorName && this.healthRecordType==0) {
    //   return toastr.error("Enter Doctor name.")
    // }
    else if (!this.addRecord.editFlag && this.addRecord.img_list.length == 0) {
      return toastr.error("Select record image.");
    } else {
      // data Validated, now see if we are adding new or updating.
      if (this.addRecord.editFlag) {
        // we are editing, check if we have atleast one image.
        if (
          !this.addRecord.old_img_urls.length &&
          !this.addRecord.img_list.length
        ) {
          return toastr.error("Please insert record iamges.");
        } else {
          // Upload any new images.
          if (this.addRecord.img_list.length > 0) {
            this.uploadRecordImage(this.addRecord.img_list[0]);
          } else {
            // If no new images, just udpate the data.
            this.addNewRecord();
          }
        }
      } else {
        // adding new record.
        this.uploadRecordImage(this.addRecord.img_list[0]);
      }
    }
  }

  uploadRecordImage(file) {
    this.addRecord.loading = true;
    let data = {
      auth_token: localStorage.getItem("token"),
      image: file,
      dispensary_id: -1,
      delivery_address: {},
    };
    this.dataService.customOrderData(data).subscribe((data: any) => {
      if (data.success == true) {
        this.addRecord.img_urls.push(data.url);
        this.addRecord.uploaded_images_count++;
        if (
          this.addRecord.img_list.length > this.addRecord.uploaded_images_count
        ) {
          var file =
            this.addRecord.img_list[this.addRecord.uploaded_images_count];
          this.uploadRecordImage(file);
        } else {
          this.addNewRecord();
        }
      } else {
        this.addRecord.loading = true;
        toastr.error("Something went wrong");
      }
    });
  }

  addNewRecord() {
    this.healthRecordType == 0
      ? (this.addRecord.type = 0)
      : (this.addRecord.type = 1);
    this.addRecord.img_urls = [
      ...this.addRecord.old_img_urls,
      ...this.addRecord.img_urls,
    ];
    var payload = {
      auth_token: localStorage.getItem("token"),
      profile_id: this.activeHealthProfileId,
      health_record_id: this.addRecord.id,
      hospital_name: this.addRecord.clinicName || "",
      doctor_name: this.addRecord.doctorName || "",
      record_date: this.addRecord.date || "",
      notes: this.addRecord.notes,
      record_type: this.addRecord.type,
      image_list: this.addRecord.img_urls,
    };
    let newDate: any = payload.record_date;
    payload.record_date ? (payload.record_date = newDate.formatted) : "";
    this.nao.go(30);
    if (this.addRecord.editFlag) {
      this.dataService.editHealthRecord(payload).subscribe((res: any) => {
        this.nao.go(100);
        if (res.success == true) {
          toastr.success(res.message);
          this.resetRecordData();
        } else {
          toastr.error("Something went wrong");
        }
        this.addRecord.loading = false;
      });
    } else {
      this.dataService.addHealthRecord(payload).subscribe((res: any) => {
        this.nao.go(100);
        if (res.success == true) {
          toastr.success(res.message);
          this.resetRecordData();
        } else {
          toastr.error("Something went wrong");
        }
        this.addRecord.loading = false;
      });
    }
  }

  resetRecordData() {
    this.addRecord = {
      id: null,
      type: 0,
      date: undefined,
      clinicName: "",
      doctorName: "",
      notes: "",
      img_list: [],
      img_urls: [],
      old_img_urls: [],
      uploaded_images_count: 0,
      editFlag: false,
      loading: false,
    };
    $("#addHealthRecordModal").modal("hide");
    setTimeout(() => {
      // this.openHealthRecordsModal();
      this.gethealthRecords(this.healthRecordType);
    }, 500);
  }

  editHealthRecord(record) {
    this.addRecord = {
      id: record.id,
      type: record.record_type,
      date: record.record_date,
      clinicName: record.hospital_name,
      doctorName: record.doctor_name,
      notes: record.notes,
      img_list: [],
      img_urls: [],
      old_img_urls: record.image_list,
      uploaded_images_count: 0,
      editFlag: true,
      loading: false,
    };
    $("#addHealthRecordModal").modal("show");
    this.initializeDropzone();
  }

  deleteHealthRecord(record) {
    var payload = {
      auth_token: localStorage.getItem("token"),
      profile_id: this.activeHealthProfileId,
      health_record_id: record.id,
    };
    this.nao.go(30);
    this.dataService.deleteHealthRecord(payload).subscribe((res: any) => {
      this.nao.go(100);
      if (res.success == true) {
        toastr.success(res.message);
        this.healthRecordPage = 0;
        this.gethealthRecords(this.healthRecordType);
      } else {
        toastr.error("Something went wrong");
      }
    });
  }

  changeLocationpopUp() {
    if (
      (!this.primaryCardNo || this.paymentCardData.length == 0) &&
      this.paymentMethodOptionValue == 1
    ) {
      toastr.error("Please select a payment method");
      return;
    }
    $("#changeLocation").modal("show");
  }

  confirmPaymentMethod() {
    this.reConfirmPayment = "";
    this.paymentMethodOptionValue = 1;
    this.nao.go(30);
    $("#pendingOnlinePayment").modal("hide");
    let paylaods = {
      auth_token: localStorage.getItem("token"),
      order_id: this.pendingPayment.tracking_id,
    };
    this.dataService.getOrderDetailsByID(paylaods).subscribe((res) => {
      this.paymentConfirmOrder = res;
      this.nao.go(100);
      $("#confirmOnlinePay").modal("show");
    });
    (Error) => {};
  }

  payNow() {
    if (this.reConfirmPayment == "COD") {
      $("#pendingOnlinePaymentCash").modal("show");
    } else {
      const url = `https://api.dev.bestbuymall.co/payment/otp/${this.pendingPayment.tracking_id}`;
      localStorage.setItem("orderPlaced", "1");
      window.open(url, "_blank").focus();
    }
  }

  payNowCash(pay) {
    if (pay) {
      const payload = {
        auth_token: localStorage.getItem("token"),
        order_id: this.pendingPayment.tracking_id,
        payment_gateway: 0,
      };
      this.nao.go(30);
      this.dataService.updateDeclinedOrder(payload).subscribe((res) => {
        this.reConfirmPayment = "SUBMITTED";
        $("#pendingOnlinePaymentCash").modal("hide");
        this.nao.go(100);
      });
    } else {
      $("#pendingOnlinePaymentCash").modal("hide");
    }
  }

  cancelPendingOrder(cancel) {
    if (cancel) {
      const payload = {
        auth_token: localStorage.getItem("token"),
        order_id: this.pendingPayment.tracking_id,
      };
      this.nao.go(30);
      this.dataService.declinedOrderData(payload).subscribe((res) => {
        $("#cancelPendingOrder").modal("hide");
        $("#pendingOnlinePaymentCash").modal("hide");
        $("#confirmOnlinePay").modal("hide");
        this.nao.go(100);
      });
    } else {
      $("#cancelPendingOrder").modal("hide");
    }
  }

  closeConfirmation(confirmed) {
    localStorage.removeItem("orderPlaced");
    if (confirmed) {
      $("#confirmOnlinePay").modal("hide");
      $("#reConfirmPaymentMethodOption").modal("hide");
      $("#orderPlacedSucessFull").modal("hide");
    }
  }
}
