import { Component, OnInit, HostListener } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { DataControlService } from '../data-control.service';
declare var $: any;
declare var Nanobar: any;


@Component({
  selector: 'app-recipe-listing',
  templateUrl: './recipe-listing.component.html',
  styleUrls: ['./recipe-listing.component.css']
})
export class RecipeListingComponent implements OnInit {

  constructor(
    private router: Router,
    private dataService: DataService,
    private dataControlService: DataControlService
  ) { }
  
  loadingData:any = true;
  windowCurrentWidth:any;
  nao = new Nanobar();
  storyPage = 0;
  storylimit = 12;
  storyData: any;
  storyDataNew: any;
  storylastPage = true;
  disabledBtn = false;
  loaderCondition:any = false;
  ngOnInit() {
    let contax = this;
    window.scrollTo(0, 0);

    $(window).scroll(function (e) {
      if (contax.loadingData == false) {
        if (contax.storylastPage === false) {
          if ($(window).scrollTop() + $(window).height() > $(document).height() - 200) {
            contax.storylastPage = true;
            contax.loaderCondition = true;
            setTimeout(() => {
              
              contax.getRecipeListByPage();
            }, 100);
          }
        }
      }

    });


    this.windowCurrentWidth = $(window).width();
    window.addEventListener('resize', () => {
      this.windowCurrentWidth = $(window).width();
    });
    this.getRecipeList();
    $('.bigFooterSection').css('top', '0');
    $('.myMobileFooter').css('top', '0');
  }
  @HostListener('window:resize', ['$event'])
  onResize(event){
    $('.bigFooterSection').css('top', '0');
    $('.myMobileFooter').css('top', '0');
  }
  getRecipeList() {
    this.loadingData = true;
    this.storyPage = 0;
    this.dataService.getRecipe(this.storylimit).subscribe(
      (data) => {
        this.storyData = data;
        this.storylastPage = this.storyData.last_page;
        this.storyData = this.storyData.items;
        this.storyPage++;
        this.loadingData = false;
      },
      (error) => {
      }
    );
  }
  getRecipeListByPage() {
    this.nao.go(30);
    this.dataService.getRecipeByPage(this.storyPage, this.storylimit).subscribe(
      (data) => {
        this.nao.go(100);
        this.storyPage++;
        this.storyDataNew = data;
        this.storylastPage = this.storyDataNew.last_page;
        this.storyDataNew = this.storyDataNew.items;
        for (var i = 0; i < this.storyDataNew.length; i++) {
          this.storyData.push(this.storyDataNew[i]);
        }
        this.loaderCondition = false;
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }

  ngOnDestroy(){
    $('.bigFooterSection').css('top', '35rem');
    $('.myMobileFooter').css('top', '15rem');
    $(window).unbind('scroll');

  }

}
