import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {DataService} from '../data.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { AsyncLocalStorage } from 'angular-async-local-storage';
import {EventsService} from 'angular4-events';
import {MetaService} from 'ng2-meta';
import { Constants } from '../constants';
import { DataControlService } from '../data-control.service';


declare var $: any;
declare var Nanobar: any;
declare var toastr: any;
declare var grecaptcha: any;
declare var verifyCallback: any;

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {
  nao = new Nanobar();
  faqsDataList: any;
  colorCode: string;

  constructor(private dataControlService: DataControlService, private router: Router, private dataService: DataService, private localStorage: AsyncLocalStorage,
              private events: EventsService, private ref: ChangeDetectorRef, private route: ActivatedRoute, private metaService: MetaService) {
    this.colorCode = Constants.colorCode;
    this.dataService.pageName = 'businesses';

    this.dataService.businessIdForIndependentBusinessData = -1;
    if ( window.location.hostname.includes('businessweb.bestbuymall.co') || window.location.hostname.includes(Constants.domainNmae) ) {
      this.router.navigate(['faqs/x---bbm-route-config-1@#$#^@$!---YY234215--X--saffg-12454jh-4b5jsj'], { skipLocationChange: true });
    } else if (window.location.hostname.includes('bestbuymall.co') || window.location.hostname.includes('bestbuymall.co') || window.location.hostname.includes('dev.bestbuymall.co') || window.location.hostname.includes('dev.bestbuymall.co') || window.location.hostname.includes('localhost') || window.location.hostname.includes('bbm.com.pk') ){
      this.dataService.pageName = 'home';
      window.localStorage.setItem('delivery_address', 'Please Select your location');
    } else {
      this.router.navigate(['faqs/x---bbm-route-config-1@#$#^@$!---YY234215--X--saffg-12454jh-4b5jsj'], { skipLocationChange: true });
    }
    this.dataService.userFrom = 'web';
  }
  getFaqsListing(){
    var context = this;
    this.dataService.getFaqsList().subscribe(
      (data) => {
        this.faqsDataList = data;
        setTimeout(() => {
          // var acc = document.getElementsByClassName('accordion');
          // var i;
          // for (i = 0; i < acc.length; i++) {
          //   acc[i].addEventListener('click', function() {
          //     this.classList.toggle('active');
          //   });
          // }
        }, 500);
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }
  // toggleIcon(e) {
  //   $(e.target)
  //     .prev('.panel-heading')
  //     .find(".more-less")
  //     .toggleClass('glyphicon-plus glyphicon-minus');
  // }
  ngOnInit() {
    this.getFaqsListing();
    localStorage.setItem('isFirstTimeHomeOpen', "false");
    //this.dataControlService.setHeaderAddressVisiblity.next(true);
    this.dataControlService.headerSignInBtn.next(true);

    window.scrollTo(0, 0);
    $('.storyBackBtn').css('display', 'none');
    $('.ds-logo').css('margin-left', '0px');
    $('.navbar').removeClass('sticky-header');
    $('.navbar').css('background-color', '#fff');
    $('.navbar').css('position', 'fixed');
    $('.logInbtn').css('color', '#FFFFFF');
    $('.navbar').css('border-bottom', '1px solid #ebebeb');
    $('#whiteLogo').css('display', 'none');
    $('#blackLogo').css('display', 'inline-block');
    $('.cartHeadBtn').css('background', this.colorCode);
    $('.cartHeadBtn').css('color', '#ffffff');
    $('.topName').css('color', this.colorCode);
    $('.username').css('color', '#000000');
    $('.business-logo').css('display', 'none');
    $('.navbar-header').css('margin-top', '0px');
  }

}

