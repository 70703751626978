import { Constants } from '../constants';
import {Component, OnInit } from '@angular/core';
import {DataService} from '../data.service';


declare var $: any;
declare var Nanobar: any;
declare var toastr: any;
declare var grecaptcha: any;
declare var verifyCallback: any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  nao = new Nanobar();
  name: any;
  email: any;
  subject: any;
  message: any;
  phone: any;
  error_count: any;
  emailError = '';
  is_client = true;
  contactData: any;
  successMessage: any;
  recaptchaChk = false;
  colorCode: string;

  constructor(private dataService: DataService) {
    this.colorCode = Constants.colorCode;
    this.dataService.pageName = 'businesses';
  }
  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  sendMessage() {
    this.recaptchaChk = false;
    var captcha = $( '#recaptcha' ),
      response = grecaptcha.getResponse();

    if (response.length === 0) {
      $( '.msg-error').text( 'reCAPTCHA is mandatory' );
      if( !captcha.hasClass( 'error' ) ){
        captcha.addClass( 'error' );
      }
    } else {
      $( '.msg-error' ).text('');
      captcha.removeClass( 'error' );
      // alert( 'reCAPTCHA marked' );
      this.recaptchaChk = true;
    }
    this.emailError = '';
    this.error_count = 0;
    $('.c-label label').css('color', '#3d3e3d');
    $('.c-label input').css('border-color', '#ccc');
    $('.c-label textarea').css('border-color', '#ccc');
    if (this.name === '' || this.name === undefined) {
      $('#name label').css('color', '#a10a0d');
      $('#name input').css('border-color', '#a10a0d');
      this.error_count ++;
    }
    if (this.email === '' || this.email === undefined) {
      $('#email label').css('color', '#a10a0d');
      $('#email input').css('border-color', '#a10a0d');
      this.error_count ++;
    }
    // if (this.subject === '' || this.subject === undefined) {
    //   $('#subject label').css('color', '#a10a0d');
    //   $('#subject input').css('border-color', '#a10a0d');
    //   this.error_count ++;
    // }
    if (this.message === '' || this.message === undefined) {
      $('#message label').css('color', '#a10a0d');
      $('#message textarea').css('border-color', '#a10a0d');
      this.error_count ++;
    }
    // if (this.phone === '' || this.phone === undefined || this.phone === null ) {
    //   $('#phoneno label').css('color', '#a10a0d');
    //   $('#phoneno input').css('border-color', '#a10a0d');
    //   this.error_count ++;
    // }
    if (this.error_count > 0) {
      toastr.error('Please fill all the required fields');
      return;
    }
    var mail = this.validateEmail(this.email);
    if ( mail === false ) {
      this.emailError = 'Please enter valid email address';
    } else {
      if (this.recaptchaChk === false) {
        toastr.error('Recaptcha not varified');
        return;
      } else {
        this.dataService.contactSport(this.name, this.email, this.phone, this.message, this.subject, this.is_client).subscribe(
          (data) => {
            this.nao.go(100);
            this.contactData = data;
            grecaptcha.reset();
            if ( this.contactData.success === true ){
              $('#showmessage').modal('show');
            }
            if ( this.contactData.success === false ){
              toastr.error('Something went wrong');
            }
            this.successMessage = this.contactData.message;
            this.name = '';
            this.email = '';
            this.message = '';
            this.subject = '';
            this.phone = '';
          },
          (error) => {
            this.nao.go(100);
          }
        );
      }
    }
  }
  chkRecapturaVal(){

  }
  ngOnInit() {
    localStorage.setItem('isFirstTimeHomeOpen', "false");
    //this.dataControlService.setHeaderAddressVisiblity.next(true);

    setTimeout(() => {
      // grecaptcha.reset();
      grecaptcha.render('recaptura12');
    }, 2000);
    window.scrollTo(0, 0);
    $('.storyBackBtn').css('display', 'none');
    $('.ds-logo').css('margin-left', '0px');
    $('.navbar').removeClass('sticky-header');
    $('.navbar').css('background-color', '#fff');
    $('.navbar').css('position', 'fixed');
    $('.logInbtn').css('color', '#FFFFFF');
    $('.navbar').css('border-bottom', '1px solid #ebebeb');
    $('#whiteLogo').css('display', 'none');
    $('#blackLogo').css('display', 'inline-block');
    $('.cartHeadBtn').css('background', this.colorCode);
    $('.cartHeadBtn').css('color', '#ffffff');
    $('.topName').css('color', this.colorCode);
    $('.username').css('color', '#000000');
    $('.business-logo').css('display', 'none');
    $('.navbar-header').css('margin-top', '0px');
  }

}

