import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventsService } from 'angular4-events';
import { Constants } from '../constants';
import { Meta, Title } from '@angular/platform-browser';

import { DataService } from '../data.service';
import { DataControlService } from '../data-control.service';

declare var $: any;
declare var Nanobar: any;
declare var toastr: any;
declare var require: any;

@Component({
  selector: 'app-dedicated-category-products',
  templateUrl: './dedicated-category-products.component.html',
  styleUrls: ['./dedicated-category-products.component.css']
})
export class DedicatedCategoryProductsComponent implements OnInit {

  nao = new Nanobar();
  priceIconForSingle = Constants.iconForSinglePrice;
  priceIconForDouble = Constants.iconForDoublePrice;
  businessId: any;
  id: any;
  parentCatId: any;
  todayDate = new Date();
  todayData = this.todayDate.getDay();
  businessDetailData: any;
  businessDetalSuccess: any;
  colorSaturate: string;
  colorInvert: string;
  colorSepia: string;
  colorHue_Rotate: string;
  colorContrast: string;
  colorBrightness: string;
  color_code: string;
  businessBackgroundImage: any;
  businessName: any;
  business_image: any;
  business_delivery_charges: any;
  business_platform_fee: any;
  business_total_charges: any;
  taxOfBusiness: any;
  cartDataFromStorage: any;
  payment_through_card: any;
  payment_through_cash: any;
  payment_over_due: any;
  fullDayStatus: any;
  timeObj: any;
  businessThemeStructure: any;
  dispensaryAlias: any;
  detailDataLoad: boolean;
  outletAddress: { area: any; city: any; state: any; zip: any; country: any; longitude: any; latitude: any; };
  businessCover: boolean;
  sidebarCategory: Object;
  serchstring1: any;
  loaderCondition = false;
  PaginationPageByCategory: any;
  businessCategoryProductsBypage: any;
  getProductsViaCategoryLimit: any = 50;
  searchStringCat: any = '';
  searchString: any = '';
  subCatid: any = -1;
  childCatid: any = -1;
  lastPageOfCategory = true;
  detailProductSuccess: any;
  businessCategoryProducts = new Array;
  widthOfWindow: number;
  idForNavigate: any;
  cartFinal: any[];
  cartPopData: any;
  variantData: any;
  objToOveride: any;
  indexToOveride: any;
  searchProductByCategoryByID: any;
  businessDetailProducts: any;
  loadPreviousProductsData: any;
  loadPreviousProductsDataCount = 0;
  loadPreviousProductsDataLastPage = true;
  PagnationPage = 0;
  paginationPage = 0;
  lastPage = true;
  searchItem = false;
  cat_id: any;
  cat_name: any;
  businessDetailProductSearchString = '';
  limit = 6;
  productFound = false;
  loadingData = true;
  clearTimeouthome: any;
  PaginationPageForSearch = 0;
  limitForSearch = 25;
  searchProductByCategoryByIDLastPage = true;
  loadingDataCat = true;
  searchItemCat = false;
  businessDetailProductsPaginate: any;
  pageCondition = true;
  searchProductByCategoryByIDByPage: any;
  disableMultiClick = false;
  productId: any;
  productDetailData: any;
  productSuccess: any;
  productDetailDataImagesLength: any;
  appleicon: any;
  SeeAllLoader = false;
  addWishlistSuccess: any;
  isloginValue: any;
  islogin: any;
  businessDetailProductsUpdate: any;
  businessCategoryProductsUpdate: any;
  parentCatName: any = '';
  subCatName: any = '';
  childCatName: any = '';
  navLoadData: any = true;
  colorCode: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private events: EventsService,
    private dataService: DataService,
    private businessInfo: EventsService,
    private meta: Meta,
    private title: Title,
    private dataControlService: DataControlService,
  ) {
    this.colorCode = Constants.colorCode;
    // this.route.params.subscribe(params => {
    //   console.log(params);
    //   this.businessId = params['id'];
    //   this.id = params['id'];
    //   this.parentCatId = params['parentId'];
    // });
    this.dataControlService.isBusinessLandingPage.next(true);
    this.dataService.pageName = 'businesses';
    this.isloginValue = this.events.subscribe('inlogin').subscribe((data) => {
      setTimeout(() => {
        this.islogin = data;
        if (this.islogin === 'true') {
          // console.log('islogin', this.islogin);
          this.updateBusinessDetailProducts1();
          if ($('#SeeMorePopup').is(':visible')) {
            this.updateBusinessCategoryProducts();
          }
        }
        if (this.islogin === 'false') {
          // console.log('islogin', this.islogin);
          for (var i = 0; i < this.businessDetailProducts.length; i++) {
            for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
              this.businessDetailProducts[i].products[j].is_wish_list = false;
            }
          }
        }
      }, 500);
    });

    this.route.queryParams.filter( params => params.id).subscribe( params => {
      console.log(params);
      this.productId = params.id;
      this.getproductDetail(params.id);
    });

    this.route.params.subscribe(params => {
      console.log(params);
      this.businessId = params['id'];
      this.id = params['id'];
      this.parentCatId = params['parentId'];
      this.idForNavigate = this.id;
      this.parentCatName = localStorage.getItem('parentCatName');
      if(params['subcatId'] != undefined && params['subcatId'] != '') {
        this.subCatid = params['subcatId'];
        this.subCatName = localStorage.getItem('subCatName');
      }
      if(params['childcatId'] != undefined && params['childcatId'] != '') {
        this.childCatid = params['childcatId'];
        this.childCatName = localStorage.getItem('childCatName');
      }
      // this.businessCategoryProducts = [];
      this.getBusinessDetail();
      this.getBusinessCategoryProductsByPage();
    });
  }

  ngOnInit() {
    // this.route.params.subscribe(params => {
    //   console.log(params);
    //   this.businessId = params['id'];
    //   this.id = params['id'];
    //   this.parentCatId = params['parentId'];
    //   this.idForNavigate = this.id;
    //   this.parentCatName = localStorage.getItem('parentCatName');
    //   if(params['subcatId'] != undefined && params['subcatId'] != '') {
    //     this.subCatid = params['subcatId'];
    //     this.subCatName = localStorage.getItem('subCatName');
    //     // this.businessCategoryProducts = [];
    //     // this.getBusinessDetail();
    //     // this.getBusinessCategoryProductsByPage();
    //   }
    //   if(params['childcatId'] != undefined && params['childcatId'] != '') {
    //     this.childCatid = params['childcatId'];
    //     this.childCatName = localStorage.getItem('childCatName');
    //     // this.businessCategoryProducts = [];
    //     // this.getBusinessDetail();
    //     // this.getBusinessCategoryProductsByPage();
    //   }
    //   this.businessCategoryProducts = [];
    //   this.getBusinessDetail();
    //   this.getBusinessCategoryProductsByPage();
    // });
    this.dataControlService.setHeaderAddressVisiblity.next(false);
    var contax = this;
    $(window).scroll(function () {
      if (contax.searchItem === false && contax.loadingData === false) {
        if (contax.lastPageOfCategory === false) {
          contax.loaderCondition = true;
          if ($(window).scrollTop() >= $(document).height() - $(window).height() - 313) {
            contax.pageCondition = false;
            contax.lastPageOfCategory = true;
            contax.loadMoreFunction();
          }
        }
      }

      if (contax.searchItem === true && contax.loadingData === false) {
        if (contax.searchProductByCategoryByIDLastPage === false) {
          contax.loaderCondition = true;
          if ($(window).scrollTop() >= $(document).height() - $(window).height() - 313) {
            contax.pageCondition = false;
            contax.searchProductByCategoryByIDLastPage = true;
            contax.loadMoreFunctionByCategoryBySearch();
          }
        }
      }

    });
    this.widthOfWindow = $(window).width();
    // this.getBusinessDetail();
    $('#onOnleBusinessCart').on('hidden.bs.modal', function () {
      $('.addCartShort').css('-webkit-filter', 'blur(0px)');
      $('.addCartShort').css('-moz-filter', 'blur(0px)');
      $('.addCartShort').css('-o-filter', 'blur(0px)');
      $('.addCartShort').css('-ms-filter', 'blur(0px)');
      $('.addCartShort').css('filter', 'blur(0px)');
    });
    $('#onOnleBusinessCart').on('show.bs.modal', function () {
      $('.addCartShort').css('-webkit-filter', 'blur(5px)');
      $('.addCartShort').css('-moz-filter', 'blur(5px)');
      $('.addCartShort').css('-o-filter', 'blur(5px)');
      $('.addCartShort').css('-ms-filter', 'blur(5px)');
      $('.addCartShort').css('filter', 'blur(5px)');
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.widthOfWindow = $(window).width();
    $('.business-logo').css('display', 'inline');
  }

  getBusinessDetail() {
    window.scrollTo(0, 0);
    this.searchString = '';
    this.loaderCondition = false;
    // this.SeeAllLoader = false;

    $('.ProductTab').removeClass('active');
    $('.AboutTab').removeClass('active');
    $('.CategoriesTab').removeClass('active');
    $('#products').removeClass('active');
    $('#about').removeClass('active');
    $('#categories').removeClass('active');
    $('#products').addClass('active');
    $('.ProductTab').addClass('active');
    $('#products').addClass('in');

    // this.nao.go(30);
    // if ( this.id === '' ){
    //   return;
    // }
    // this.businessStatus = '';
    // this.openOrCloseIn = '';
    // this.businessRaitingArray = new Array();

    var todayData = this.todayData;
    if ( this.todayData === 0 ) {
      todayData = 7;
    }
    this.dataService.businessDetail(this.businessId, todayData).subscribe(
      (data) => {
        // this.nao.go(100);
        this.businessDetailData = data;
        console.log(this.businessDetailData.business_detail.business_category_id);
        localStorage.setItem('business_store_category_id', this.businessDetailData.business_detail.business_category_id);

        if (this.businessDetailData.rest_error_code === 4 || this.businessDetailData.rest_error_code === 1) {
          this.router.navigate(['/notfound']);
          return;

        }


        // nothing
        this.businessDetalSuccess = this.businessDetailData.success;
        this.businessDetailData = this.businessDetailData.business_detail;
        if(this.businessDetailData.theme_color_attributes.color_saturate == '' || this.businessDetailData.theme_color_attributes.color_saturate == undefined) {
            this.colorSaturate = '5194%';
            this.colorInvert = '18%';
            this.colorSepia = '5%';
            this.colorHue_Rotate = '202deg';
            this.colorContrast = '94%';
            this.colorBrightness = '94%';
            this.color_code = this.colorCode;
        }
        else {
        this.colorSaturate = this.businessDetailData.theme_color_attributes.color_saturate;
        this.colorInvert = this.businessDetailData.theme_color_attributes.color_invert;
        this.colorSepia = this.businessDetailData.theme_color_attributes.color_sepia;
        this.colorHue_Rotate = this.businessDetailData.theme_color_attributes.color_hue_rotate;
        this.colorContrast = this.businessDetailData.theme_color_attributes.color_contrast;
        this.colorBrightness = this.businessDetailData.theme_color_attributes.color_brightness;
        this.color_code = this.businessDetailData.theme_color_attributes.color_code;
        this.businessBackgroundImage = this.businessDetailData.theme_color_attributes.business_background_image;
        localStorage.setItem('colorChangeCode', this.color_code);
        }

        document.documentElement.style.setProperty('--colorCodeVar', this.color_code);
        document.documentElement.style.setProperty('--saturateVar', this.colorSaturate);
        document.documentElement.style.setProperty('--invertVar', this.colorInvert);
        document.documentElement.style.setProperty('--hue_rotateVar', this.colorHue_Rotate);
        document.documentElement.style.setProperty('--constrastVar', this.colorContrast);
        document.documentElement.style.setProperty('--brightnessVar', this.colorBrightness);
        document.documentElement.style.setProperty('--sepiaVar', this.colorSepia);

        this.businessName = this.businessDetailData.name;
        this.businessId = this.businessDetailData.id;
        this.business_image = this.businessDetailData.square_image;
        this.business_delivery_charges = this.businessDetailData.delivery_charges;
        this.business_platform_fee = this.businessDetailData.platform_fee;
        this.business_total_charges = this.businessDetailData.total_charges;
        this.taxOfBusiness = this.businessDetailData.tax_percentage;
        this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
        this.payment_through_card = this.businessDetailData.payment_through_card;
        this.payment_through_cash = this.businessDetailData.payment_through_cash;
        this.payment_over_due = this.businessDetailData.payment_over_due;
        this.fullDayStatus = this.businessDetailData.full_day;
        this.timeObj = this.businessDetailData.time_list;
        this.businessThemeStructure = this.businessDetailData.theme_structure_id;
        this.dispensaryAlias = this.businessDetailData.alias;
        setTimeout(()=> {
          // this.events.publish('businessAliasId', this.dispensaryAlias);
          this.events.publish('idForNavigate', this.idForNavigate);
        }, 1000);
        this.events.publish('businessLogoUpdate', this.businessDetailData.business_logo_image);
        this.events.publish('businessThemeStructure', this.businessDetailData.theme_structure_id);
        this.events.publish('businessLandingAlisa', this.businessDetailData);
        this.businessInfo.publish('businessContInfo', this.businessDetailData);

        if (this.businessDetalSuccess === true) {
          this.detailDataLoad = true;
          this.outletAddress = {
            area: this.businessDetailData.street,
            city: this.businessDetailData.city,
            state: this.businessDetailData.state,
            zip: this.businessDetailData.zip,
            country: this.businessDetailData.area,
            longitude: this.businessDetailData.longitude,
            latitude: this.businessDetailData.latitude
          };
          this.openNav();
        }
        // if(this.businessThemeStructure === 1) {
        //   this.dedicatedCategoryListing(this.dispensaryAlias);
        // }
        // this.nao.go(100);
        // this.getBusinessCategoryProductsByPage();
        // this.CategoryListing();
      },
      (error) => {
        // this.nao.go(100);
      }
    );
  }

  getproductDetail(id) {
    this.productId = id;
    var token;
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    this.nao.go(30);
    this.dataService.productDetail(id, token).subscribe(
      (data) => {
        this.nao.go(100);
        this.productDetailData = data;
        this.productSuccess = this.productDetailData.success;
        this.productDetailData = this.productDetailData.product;
        this.setMetaData(this.productDetailData.title, this.productDetailData.image[0], this.productDetailData.description, this.productDetailData.product_tags);
        this.productDetailDataImagesLength = this.productDetailData.image.length;
        if(this.productDetailDataImagesLength <= 1){
          // console.log('working');
          $('.carousel-indicators').removeClass('display-block');
          $('.carousel-indicators').addClass('display-none');
        }
        if(this.productDetailDataImagesLength > 1){
          // console.log('working');
          $('.carousel-indicators').removeClass('display-none');
          $('.carousel-indicators').addClass('display-block');
        }
        this.productDetailData.description = this.productDetailData.description.replace(/(?:\r\n|\r|\n)/g, '<br>');

        if (this.productSuccess === true) {
          this.disableBackground();
          this.addCartPopOpen(this.productDetailData, 2);
          this.openNavAll();
        }
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }

  openNav() {
    this.initSlider();
    this.iniatlizeSlider();
    $('#businessDetail').modal({
      backdrop: 'static',
      keyboard: false,
    }, 'show');
    // setTimeout(() => {
    this.detailDataLoad = false;
    // }, 1500);
    // document.getElementById('main').style.marginRight = '250px';
  }

  openNavAll() {
    $('#businessAll').modal({}, 'show');
  }

  closeNav() {
    this.SeeAllLoader = false;
    this.loaderCondition = false;
    if(this.subCatid != -1) {
      this.subCatid = -1;
    }
    if(this.childCatid != -1) {
      this.childCatid = -1;
    }
    this.searchItemCat = false;
    this.searchStringCat = '';
    this.PaginationPageByCategory = 0;
  }

  // dedicatedCategoryListing(dispensaryId) {
  //   this.dataService.dedicatedCategoryListing(dispensaryId).subscribe(
  //     data => {
  //       this.sidebarCategory = data;
  //     },
  //     err => {
  //       console.log(err);
  //     }
  //   );
  // }

  searchVal(val) {
    console.log(val);

    this.serchstring1 = val;
    if (this.serchstring1 === '') {
      this.loaderCondition = false;
      this.searchString = '';
      this.loadingDataCat = false;
      this.businessDetailProducts = this.loadPreviousProductsData;
      this.PagnationPage = this.loadPreviousProductsDataCount;
      this.lastPage = this.loadPreviousProductsDataLastPage;
      this.initSlider();
      this.iniatlizeSlider();
      this.searchItem = false;

      const cartData = JSON.parse(localStorage.getItem('cartItems'));
      if (cartData !== null) {
        if(this.businessDetailProducts != undefined) {
          for (var i = 0; i < this.businessDetailProducts.length; i++) {
            for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
              this.businessDetailProducts[i].products[j].count = 0;
              for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
                for (var l = 0; l < cartData.length; l++) {
                  if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                    this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                  }
                }
              }
            }
          }
        }
      }
    }

    else {
      this.loadingData = true;
      // this.businessDetailProducts = [];
      this.searchProductsByCategoryId();
      this.searchItem = true;
      this.loadingDataCat = true;
    }
  }

  getBusinessCategoryProductsByPage() {
    this.PaginationPageByCategory++;
    this.searchItem = false;
    // clearInterval(this.clearTimeout1);
    // this.businessCategoryProductsBypage = [];
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    // console.log('cartData', cartData);
    let token;
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    this.nao.go(30);
    this.businessCategoryProducts = [];
    this.dataService.businessCategoryProductList(this.businessId, this.getProductsViaCategoryLimit, this.PaginationPageByCategory, this.parentCatId, this.searchStringCat, this.subCatid, this.childCatid, token, -1, -1).subscribe(
      (data) => {
        // this.SeeAllLoader = false;
        this.businessCategoryProductsBypage = data;
        this.lastPageOfCategory = this.businessCategoryProductsBypage.last_page;
        this.loadingDataCat = false;
        this.loadingData = false;
        this.detailProductSuccess = this.businessCategoryProductsBypage.success;
        this.PagnationPage = this.businessCategoryProductsBypage.page;
        this.businessCategoryProductsBypage = this.businessCategoryProductsBypage.items;
        // console.log('this.businessCategoryProducts', this.businessCategoryProducts);
        for (var k = 0; k < this.businessCategoryProductsBypage.length; k++) {
          this.businessCategoryProducts.push(this.businessCategoryProductsBypage[k]);
        }
        if (cartData !== null) {
          for (var i = 0; i < this.businessCategoryProducts.length; i++) {
            this.businessCategoryProducts[i].count = 0;
            for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
              for (var l = 0; l < cartData.length; l++) {
                if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
                  this.businessCategoryProducts[i].count += cartData[l].quantity;
                  // console.log('search in', this.businessCategoryProducts[i].count);
                }
              }
            }
          }
        }
          this.navLoadData = false;
        this.nao.go(100);
        console.log(this.businessCategoryProducts);
      },
      (error) => {
        // this.nao.go(100);
        // console.log('error', error);
      }
    );
  }

  addFadeInAnimation(animation , targetClass){
    if (this.widthOfWindow > 420) {
      if($('#'+targetClass).hasClass(animation) === false){
        $('#'+targetClass).addClass(animation);
      }
      setTimeout(() => {
        $('#' + targetClass).removeClass(animation);
      }, 1000);
    }
  }

  // parentCatFunction(id, name) {
  //   this.parentCatName = name;
  //   this.subCatName = '';
  //   this.childCatName = '';
  //   localStorage.setItem('parentCatName', this.parentCatName);
  //   localStorage.setItem('subCatName', this.subCatName);
  //   localStorage.setItem('childCatName', this.childCatName);
  //   this.router.navigate([this.id, id]);
  //   this.businessCategoryProducts = [];
  //   this.getBusinessDetail();
  // }

  // subCatFunction(parentId, subcatId, parentName, name) {
  //   this.parentCatName = parentName;
  //   this.subCatName = name;
  //   this.childCatName = '';
  //   localStorage.setItem('parentCatName', this.parentCatName);
  //   localStorage.setItem('subCatName', this.subCatName);
  //   localStorage.setItem('childCatName', this.childCatName);
  //   this.router.navigate([this.id, parentId, subcatId]);
  //   this.businessCategoryProducts = [];
  //   this.getBusinessDetail();
  // }

  // childCatFunction(parentId, subcatId, childcatId, parentName, subCatName, name) {
  //   this.parentCatName = parentName;
  //   this.subCatName = subCatName;
  //   this.childCatName = name;
  //   localStorage.setItem('parentCatName', this.parentCatName);
  //   localStorage.setItem('subCatName', this.subCatName);
  //   localStorage.setItem('childCatName', this.childCatName);
  //   this.router.navigate([this.id, parentId, subcatId, childcatId]);
  //   this.businessCategoryProducts = [];
  //   this.getBusinessDetail();
  // }

  addCartfromshortPop(obj, val) {
    this.addCartPopOpen(obj, val);
    // $('.addCartShort').css('-webkit-filter', 'blur(3px)');
    // $('.addCartShort').css('-moz-filter', 'blur(3px)');
    // $('.addCartShort').css('-o-filter', 'blur(3px)');
    // $('.addCartShort').css('-ms-filter', 'blur(3px)');
    // $('.addCartShort').css('filter', 'blur(3px)');
  }

  addCartPopOpen(obj, val) {
    this.swipe();
    this.cartFinal = [];
    this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
    this.cartPopData = obj;
    this.variantData = this.cartPopData.variant;
    for (let i = 0; i < this.variantData.length; i++) {
      if (this.cartDataFromStorage !== null && this.checkObject(this.cartDataFromStorage, this.variantData[i]) === true) {
        for (let j = 0; j < this.cartDataFromStorage.length; j++) {
          if (this.businessId === this.cartDataFromStorage[j].business_id && this.cartPopData.id === this.cartDataFromStorage[j].product_id && this.variantData[i].id === this.cartDataFromStorage[j].variant_id) {
            this.cartFinal.push({
              business_image: this.business_image,
              business_name: this.businessName,
              business_id: this.businessId,
              business_delivery_charges: this.business_delivery_charges,
              business_platform_fee: this.business_platform_fee,
              business_total_charges: this.business_total_charges,
              business_tax: this.taxOfBusiness,
              product_id: this.cartPopData.id,
              product_name: this.variantData[i].product_name,
              product_image: this.variantData[i].image,
              variant_id: this.variantData[i].id,
              variant_name: this.variantData[i].option_name,
              variant_value: this.variantData[i].option_value,
              price: this.variantData[i].price,
              sale_price: this.variantData[i].sale_price,
              is_on_sale: this.variantData[i].is_on_sale,
              quantity: this.cartDataFromStorage[j].quantity,
              addCartBtnShow: false,
              outlet_address: this.outletAddress,
            });
          }
        }
      } else {
        this.cartFinal.push({
          business_image: this.business_image,
          business_name: this.businessName,
          business_id: this.businessId,
          business_delivery_charges: this.business_delivery_charges,
          business_platform_fee: this.business_platform_fee,
          business_total_charges: this.business_total_charges,
          business_tax: this.taxOfBusiness,
          product_id: this.cartPopData.id,
          product_name: this.variantData[i].product_name,
          product_image: this.variantData[i].image,
          variant_id: this.variantData[i].id,
          variant_name: this.variantData[i].option_name,
          variant_value: this.variantData[i].option_value,
          price: this.variantData[i].price,
          sale_price: this.variantData[i].sale_price,
          is_on_sale: this.variantData[i].is_on_sale,
          quantity: 1,
          addCartBtnShow: true,
          outlet_address: this.outletAddress,
        });
      }
    }
    if (val === 1) {
      // window.localStorage.setItem('paymentMethod', this.payment_through_card);
      // window.localStorage.setItem('paymentThroughCard', this.payment_through_card);
      // window.localStorage.setItem('paymentThroughCash', this.payment_through_cash);
      // window.localStorage.setItem('paymentOverDue', this.payment_over_due);
      window.localStorage.setItem('cartBusinessData', JSON.parse(this.businessDetailData));
      $('#addCartPop').modal('show');
    }
  }

  swipe(){
    $(".carousel").on("touchstart", function(event){
      var xClick = event.originalEvent.touches[0].pageX;
      $(this).one("touchmove", function(event){
        var xMove = event.originalEvent.touches[0].pageX;
        if( Math.floor(xClick - xMove) > 5 ){
          $(this).carousel('next');
        }
        else if( Math.floor(xClick - xMove) < -5 ){
          $(this).carousel('prev');
        }
      });
      $(".carousel").on("touchend", function(){
        $(this).off("touchmove");
      });
    });
  }

  // Cart Flow
  checkObject(array, obj) {
    var value = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].variant_id === obj.id) {
        value = true;
        break;
      }
    }
    return value;
  }

  async openFinalCart() {
    // console.log('in');
    this.events.publish('openCartPopup', 'true');
  }

  addToCartOnExisting() {
    this.disableMultiClick = true;
    this.cartDataFromStorage = [];
    this.events.publish('cartUpdate', this.cartDataFromStorage);
    // window.localStorage.setItem('cartItems', '');
    localStorage.removeItem('cartItems');
    setTimeout(() => {
      this.addToCart(this.objToOveride, this.indexToOveride);
      $('#onOnleBusinessCart').modal('hide');
      this.disableMultiClick = false;
    }, 1000);
  }

  async addToCart(obj, index) {
    if (this.payment_over_due === true) {
      $('#onOnleOverDue').modal('show');
      return;
    }
    // if (this.businessStatus === 'Closed') {
    //   $('#weAreClosed').modal('show');
    //   return;
    // }
    this.objToOveride = obj;
    this.indexToOveride = index;
    // console.log('obj chk in add cart .....', obj);
    let cartDataNewChk = JSON.parse(localStorage.getItem('cartItems'));
    if (cartDataNewChk !== null) {
      if (cartDataNewChk.length > 0) {
        if (this.businessId !== cartDataNewChk[0].business_id) {
          $('#onOnleBusinessCart').modal('show');
          return;
        }
      }
    }

    const objToPass = obj;
    this.cartFinal[index].addCartBtnShow = false;
    if (this.cartDataFromStorage === null || this.cartDataFromStorage === undefined) {
      this.cartDataFromStorage = [];
    }
    this.cartDataFromStorage.push(objToPass);
    this.events.publish('cartUpdate', this.cartDataFromStorage);
    const cartData = JSON.parse(localStorage.getItem('cartItems'));

    if(this.searchProductByCategoryByID != undefined) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        this.searchProductByCategoryByID[i].count = 0;
        for (var k = 0; k < this.searchProductByCategoryByID[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.searchProductByCategoryByID[i].variant[k].id) {
              this.searchProductByCategoryByID[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    if(this.businessCategoryProducts != undefined) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        this.businessCategoryProducts[i].count = 0;
        for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
              this.businessCategoryProducts[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    if ($('#scrollId').is('visible')) {
      if(this.businessDetailProducts != undefined) {
        for (var i = 0; i < this.businessDetailProducts.length; i++) {
          for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
            this.businessDetailProducts[i].products[j].count = 0;
            for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
              for (var l = 0; l < cartData.length; l++) {
                if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                  this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                  // console.log('in count plus', this.businessDetailProducts[i].products[j].count);
                }
              }
            }
          }
        }
      }
    }
    // window.localStorage.setItem('paymentMethod', this.payment_through_card);
    // window.localStorage.setItem('paymentThroughCard', this.payment_through_card);
    // window.localStorage.setItem('paymentThroughCash', this.payment_through_cash);
    // window.localStorage.setItem('paymentOverDue', this.payment_over_due);
    window.localStorage.setItem('cartBusinessData', JSON.stringify(this.businessDetailData));
  }

  async quantityMinus(index, quantity, obj) {
    this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
    this.cartFinal[index].quantity = quantity - 1;
    for (let i = 0; i < this.cartDataFromStorage.length; i++) {
      if (this.businessId === this.cartDataFromStorage[i].business_id && this.cartPopData.id === this.cartDataFromStorage[i].product_id && obj.variant_id === this.cartDataFromStorage[i].variant_id) {
        if (this.cartFinal[index].quantity < 1) {
          this.cartDataFromStorage.splice(i, 1);
          this.events.publish('cartUpdate', this.cartDataFromStorage);
        } else {
          this.cartDataFromStorage[i].quantity = this.cartFinal[index].quantity;
          this.events.publish('cartUpdate', this.cartDataFromStorage);
        }
      }
    }
    if (this.cartFinal[index].quantity < 1) {
      this.cartFinal[index].quantity = 1;
      this.cartFinal[index].addCartBtnShow = true;
    }

    const cartData = JSON.parse(localStorage.getItem('cartItems'));

    if(this.searchProductByCategoryByID != undefined) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        this.searchProductByCategoryByID[i].count = 0;
        for (var k = 0; k < this.searchProductByCategoryByID[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.searchProductByCategoryByID[i].variant[k].id) {
              this.searchProductByCategoryByID[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    if(this.businessCategoryProducts != undefined) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        this.businessCategoryProducts[i].count = 0;
        for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
              this.businessCategoryProducts[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    if ($('#scrollId').is('visible')) {
      if(this.businessDetailProducts != undefined) {
        for (var i = 0; i < this.businessDetailProducts.length; i++) {
          for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
            this.businessDetailProducts[i].products[j].count = 0;
            for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
              for (var l = 0; l < cartData.length; l++) {
                if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                  this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                }
              }
            }
          }
        }
      }
    }

  }

  async quantityPlus(index, quantity, obj) {
    this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
    this.cartFinal[index].quantity = quantity + 1;
    for (let i = 0; i < this.cartDataFromStorage.length; i++) {
      if (this.businessId === this.cartDataFromStorage[i].business_id && this.cartPopData.id === this.cartDataFromStorage[i].product_id && obj.variant_id === this.cartDataFromStorage[i].variant_id) {
        this.cartDataFromStorage[i].quantity = this.cartFinal[index].quantity;
        this.events.publish('cartUpdate', this.cartDataFromStorage);
      }
    }
    const cartData = JSON.parse(localStorage.getItem('cartItems'));

    if(this.searchProductByCategoryByID != undefined) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        this.searchProductByCategoryByID[i].count = 0;
        for (var k = 0; k < this.searchProductByCategoryByID[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.searchProductByCategoryByID[i].variant[k].id) {
              this.searchProductByCategoryByID[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    if(this.businessCategoryProducts != undefined) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        this.businessCategoryProducts[i].count = 0;
        for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
              this.businessCategoryProducts[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    if ($('#scrollId').is('visible')) {
      if(this.businessDetailProducts != undefined) {
        for (var i = 0; i < this.businessDetailProducts.length; i++) {
          for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
            this.businessDetailProducts[i].products[j].count = 0;
            for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
              for (var l = 0; l < cartData.length; l++) {
                if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                  this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                }
              }
            }
          }
        }
      }
    }
  }

  getBusinessDetailProducts() {
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    // console.log('cartData', cartData);
    this.nao.go(30);
    this.dataService.businessDetailProducts(this.id, token).subscribe(
      (data) => {
        this.businessDetailProducts = data;
        this.nao.go(100);
        // this.searchItem = false;
        // this.loadingData = false;
        this.initSlider();
        this.iniatlizeSlider();
        this.detailProductSuccess = this.businessDetailProducts.success;
        this.businessDetailProducts = this.businessDetailProducts.products;
        // console.log('this.businessDetailProducts', this.businessDetailProducts);
        for (var i = 0; i < this.businessDetailProducts.length; i++) {
          for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
            for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
              for (var l = 0; l < cartData.length; l++) {
                if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                  this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                  // console.log('in count', this.businessDetailProducts[i].products[j].count);
                }
              }
            }
          }
        }
        this.iniatlizeSlider();
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }

  getBusinessDetailProducts1() {
    this.PagnationPage = 0;
    this.loaderCondition = false;
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    this.businessDetailProducts = [];
    // console.log('Hello Asad', cartData);
    // this.nao.go(30);
    this.cat_id = this.id;
    this.dataService.businessSearchProducts(this.id, this.businessDetailProductSearchString, this.limit, this.PagnationPage, token).subscribe(
      (data) => {
        this.businessDetailProducts = data;
        // this.PagnationPage++;
        this.iniatlizeSlider();
        this.lastPage = this.businessDetailProducts.last_page;
        this.detailProductSuccess = this.businessDetailProducts.success;
        this.businessDetailProducts = this.businessDetailProducts.items;
        // console.log('businessDetailProducts', this.businessDetailProducts)
        // this.initSlider();
        this.initSlider();
        this.iniatlizeSlider();
        if(this.businessDetailProducts !== undefined){
           if (this.businessDetailProducts.length !== 0) {
            this.productFound = true;
          }
          if (cartData !== null) {
            for (var i = 0; i < this.businessDetailProducts.length; i++) {
              for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
                for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
                  // console.log('cartData', cartData);
                  for (var l = 0; l < cartData.length; l++) {
                    if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                      this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                      // console.log('in count11', this.businessDetailProducts[i].products[j].count);
                    }
                  }
                }
              }
            }
          }
        }
        console.log(this.businessDetailProducts);
        if (this.businessDetailProductSearchString === '') {
          this.loadPreviousProductsData = this.businessDetailProducts;
          this.loadPreviousProductsDataCount = this.PagnationPage;
          this.loadPreviousProductsDataLastPage = this.lastPage;
        }
        this.searchItem = false;
        this.loadingData = false;
        this.iniatlizeSlider();
      },

      (error) => {
        // this.nao.go(100);
      }
    );

  }

  loadMoreFunction() {
    this.getBusinessDetailProducts1ByPage();
  }

  loadMoreFunctionByCategoryBySearch() {
    this.searchProductsByCategoryIdByPage();
  }

  getBusinessDetailProducts1ByPage() {
    this.PagnationPage++;
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    this.businessDetailProductsPaginate = [];
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    // console.log('cartData', cartData);
    // this.nao.go(30);

    this.cat_id = this.id;
    this.dataService.businessCategoryProductList(this.businessId, this.getProductsViaCategoryLimit, this.PagnationPage, this.parentCatId, this.searchStringCat, this.subCatid, this.childCatid, token, -1, -1).subscribe(
      (data) => {
        this.loaderCondition = false;
        // this.nao.go(100);
        // clearInterval(this.clearTimeoutProduct);
        this.businessDetailProductsPaginate = data;

        this.searchItem = false;
        this.loadingData = false;
        // this.PagnationPage++;
        this.lastPage = this.businessDetailProductsPaginate.last_page;
        if (this.businessDetailProductSearchString === '') {
          this.loadPreviousProductsDataCount = this.PagnationPage;
          this.loadPreviousProductsDataLastPage = this.lastPage;
        }
        this.detailProductSuccess = this.businessDetailProductsPaginate.success;
        this.businessDetailProductsPaginate = this.businessDetailProductsPaginate.items;
        // this.initSlider();
        this.initSlider();
        this.iniatlizeSlider();
        if (this.businessDetailProductsPaginate.length !== 0) {
          this.productFound = true;
        }
        if (cartData !== null) {
          for (var i = 0; i < this.businessDetailProductsPaginate.length; i++) {
            for (var j = 0; j < this.businessDetailProductsPaginate[i].products.length; j++) {
              for (var k = 0; k < this.businessDetailProductsPaginate[i].products[j].variant.length; k++) {
                for (var l = 0; l < cartData.length; l++) {
                  if (cartData[l].variant_id === this.businessDetailProductsPaginate[i].products[j].variant[k].id) {
                    this.businessDetailProductsPaginate[i].products[j].count += cartData[l].quantity;
                    // console.log('in count11', this.businessDetailProducts[i].products[j].count);
                  }
                }
              }
            }
          }
        }
        for (var k = 0; k < this.businessDetailProductsPaginate.length; k++) {
          this.businessCategoryProducts.push(this.businessDetailProductsPaginate[k]);
        }
        this.loadPreviousProductsData = this.businessDetailProducts;
        this.pageCondition = true;
      },
      (error) => {
        // this.nao.go(100);
      }
    );

  }

  iniatlizeSlider(){
    // this.showBusinessProductsliderLoading = true;
    setTimeout(() => {
      $('#catID').css('visibility', 'visible');
      var owl = $('.owlProductsSlider');
      owl.owlCarousel({
        loop: false,
        dots: false,
        navSpeed: 700,
        // filter: invert(var(--invertVar)) sepia(var(--sepiaVar)) saturate(var(--saturateVar)) hue-rotate(var(--hue_rotateVar)) brightness(var(--brightnessVar)) contrast(var(--constrastVar))!important;"
        // filter: invert(var(--invertVar)) sepia(var(--sepiaVar)) saturate(var(--saturateVar)) hue-rotate(var(--hue_rotateVar)) brightness(var(--brightnessVar)) contrast(var(--constrastVar))!important;"
        navText: ['<img src="../../assets/arrowLeftWhite.png" style="width: 50px;">', '<img src="../../assets/arrowRightWhite.png" style="width: 50px;">'],
        responsive: {
          0: {
            items: 2,
            stagePadding: 20
          },
          330: {
            items: 2,
            stagePadding: 30
          },
          560: {
            items: 3,
            stagePadding: 50
          },
          768: {
            items: 4,
            stagePadding: 60
          },
          900: {
            items: 4,
            stagePadding: 80
          },
        },
        nav: true
      });
      owl.on('changed.owl.carousel', function(data) {
        var position = data.page.size + data.property.value;
        // console.log(position, data);
        if (data.item.count - position === 0 || data.item.count - position === 0.5) {
          $('#'+data.currentTarget.id+' .owl-next').addClass('disabled');
          $('#'+data.currentTarget.id+' .owl-next').addClass('visibility-hidden');
        } else {
          $('#'+data.currentTarget.id+' .owl-next').removeClass('disabled');
          $('#'+data.currentTarget.id+' .owl-next').removeClass('visibility-hidden');
        }

        if (data.item.index > 0) {
          $('#'+data.currentTarget.id+' .owl-prev').removeClass('visibility-hidden');
        }
        if (data.item.index === 0) {
          $('#'+data.currentTarget.id+' .owl-prev').addClass('visibility-hidden');
        }


      });
    }, 500);
    setTimeout(() => {
      // this.showBusinessProductsliderLoading = false;
      $('.owl-nav').css('visibility', 'hidden');
      // $('.owl-prev').addClass('visibility-hidden');
    }, 500 );
  }

  initSlider() {
    setTimeout(() => {
      // $(document).ready(function () {
      //   $('.sliderList').slick({
      //
      //     // normal options...
      //     infinite: false,
      //     autoplay: false,
      //     slidesToShow: 4.5,
      //     arrows: true,
      //     slidesToScroll: 4,
      //     accessibility: false,
      //     setPosition: 0,
      //     centerMode: false,
      //     // ltr: true,
      //     // initialSlide: 0,
      //     dots: false,
      //     zIndex: 3,
      //     // the magic
      //     responsive: [{
      //
      //       breakpoint: 1024,
      //       settings: {
      //         slidesToShow: 3.5,
      //         slidesToScroll: 3,
      //         infinite: false
      //       }
      //     }, {
      //       breakpoint: 768,
      //       settings: {
      //         slidesToShow: 3.5,
      //         slidesToScroll: 2,
      //         dots: false
      //       }
      //     }, {
      //       breakpoint: 560,
      //       settings: {
      //         slidesToShow: 2.5,
      //         slidesToScroll: 2,
      //         dots: false
      //       }
      //     }, {
      //       breakpoint: 300,
      //       settings: 'unslick' // destroys slick
      //     }]
      //   });
        //     $('.sliderList').on('afterChange', function (event, slick, currentSlide) {
        //
        //     if(currentSlide === 4) {
        //         $('.slick-next').addClass('hidden');
        //     }
        //     else {
        //         $('.slick-next').removeClass('hidden');
        //     }
        //
        //     if(currentSlide === 0) {
        //         $('.slick-prev').addClass('hidden');
        //     }
        //     else {
        //         $('.slick-prev').removeClass('hidden');
        //     }
        // });
        // $('.slick-list').css('overflow', 'visible');
      // });
      $(document).ready(function () {
        const width = $('.businessTile').width();
        const height = width * 0.75;
        $('.sdetail').css('-webkit-box-orient', 'vertical');
        $('.slick-track').css('margin-left', 0);
        $('.slick-track').css('margin-right', 0);
        $('.businessTile').css('height', height);
      });
    }, 2000);
  }

  searchProductsByCategoryId() {
    this.PaginationPageForSearch = 0;
    this.loaderCondition = false;
    this.searchProductByCategoryByID = [];
    // clearInterval(this.clearTimeout1);
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    // this.businessCatLoding = true;;
    clearTimeout(this.clearTimeouthome);
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    this.clearTimeouthome = setTimeout(() => {
      this.dataService.searchProductsViaCategoryList(this.businessId, this.limitForSearch, this.PaginationPageForSearch, this.serchstring1, token, -1, -1, this.parentCatId, this.subCatid, this.childCatid).subscribe(
        (data) => {
          // this.businessCatLoding = false;
          this.searchProductByCategoryByID = data;
          this.searchProductByCategoryByIDLastPage = this.searchProductByCategoryByID.last_page;
          this.searchProductByCategoryByID = this.searchProductByCategoryByID.items;
          // this.businessCatLoding = false;
          this.loadingData = false;
          if (cartData !== null) {
            for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
              this.searchProductByCategoryByID[i].count = 0;
              for (var k = 0; k < this.searchProductByCategoryByID[i].variant.length; k++) {
                for (var l = 0; l < cartData.length; l++) {
                  if (cartData[l].variant_id === this.searchProductByCategoryByID[i].variant[k].id) {
                    this.searchProductByCategoryByID[i].count += cartData[l].quantity;
                    // console.log('search in', this.searchProductByCategoryByID[i].count);
                  }
                }
              }
            }
          }
        },
        (error) => {
          // console.log('error', error);
        }
      );
    }, 1000);
  }

  searchProductsByCategoryIdByPage() {
    this.PaginationPageForSearch++;
    this.searchProductByCategoryByIDByPage = [];
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    // this.businessCatLoding = true;;
    clearTimeout(this.clearTimeouthome);
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    this.clearTimeouthome = setTimeout(() => {
      this.dataService.searchProductsByCategory(this.businessId, this.limitForSearch, this.PaginationPageForSearch, this.serchstring1, token, -1, -1).subscribe(
        (data) => {
          this.loaderCondition = false;
          this.searchProductByCategoryByIDByPage = data;
          this.searchProductByCategoryByIDLastPage = this.searchProductByCategoryByIDByPage.last_page;
          this.searchProductByCategoryByIDByPage = this.searchProductByCategoryByIDByPage.items;
          // console.log('this.searchProductByCategoryByID ', this.searchProductByCategoryByIDByPage);
          // this.businessCatLoding = false;
          this.loadingData = false;
          if (cartData !== null) {
            for (var i = 0; i < this.searchProductByCategoryByIDByPage.length; i++) {
              this.searchProductByCategoryByIDByPage[i].count = 0;
              for (var k = 0; k < this.searchProductByCategoryByIDByPage[i].variant.length; k++) {
                for (var l = 0; l < cartData.length; l++) {
                  if (cartData[l].variant_id === this.searchProductByCategoryByIDByPage[i].variant[k].id) {
                    this.searchProductByCategoryByIDByPage[i].count += cartData[l].quantity;
                    // console.log('search in', this.searchProductByCategoryByIDByPage[i].count);
                  }
                }
              }
            }
          }

          for (var k = 0; k < this.searchProductByCategoryByIDByPage.length; k++) {
            this.searchProductByCategoryByID.push(this.searchProductByCategoryByIDByPage[k]);
          }
          this.pageCondition = true;
        },
        (error) => {
          // console.log('error', error);
        }
      );
    }, 1000);
  }

  clearSearch() {
    // this.searchString = '';
    this.loaderCondition = false;
    this.searchString = '';
    this.loadingDataCat = false;
    // this.businessDetailProductSearchString = '';
    // this.loadingData = true;
    // this.getBusinessDetailProducts1();
    // // this.initSlider();
    // // this.iniatlizeSlider();
    this.businessDetailProducts = this.loadPreviousProductsData;
    this.PagnationPage = this.loadPreviousProductsDataCount;
    this.lastPage = this.loadPreviousProductsDataLastPage;
    this.initSlider();
    this.iniatlizeSlider();
    this.searchItem = false;

    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    if (cartData !== null) {
      if(this.businessDetailProducts != undefined) {
        for (var i = 0; i < this.businessDetailProducts.length; i++) {
          for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
            this.businessDetailProducts[i].products[j].count = 0;
            for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
              for (var l = 0; l < cartData.length; l++) {
                if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                  this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                  // console.log('in count plus', this.businessDetailProducts[i].products[j].count);
                }
              }
            }
          }
        }
      }
    }
  }

  clearBlurbusinessAll() {
    $('#businessAll').css('-webkit-filter', 'blur(0px)');
    $('#businessAll').css('-moz-filter', 'blur(0px)');
    $('#businessAll').css('-o-filter', 'blur(0px)');
    $('#businessAll').css('-ms-filter', 'blur(0px)');
    $('#businessAll').css('filter', 'blur(0px)');
  }
  addBlurbusinessAll() {
    $('#businessAll').css('-webkit-filter', 'blur(3px)');
    $('#businessAll').css('-moz-filter', 'blur(3px)');
    $('#businessAll').css('-o-filter', 'blur(3px)');
    $('#businessAll').css('-ms-filter', 'blur(3px)');
    $('#businessAll').css('filter', 'blur(3px)');
  }
  setMetaData(name, image, about, searchwords) {
    // console.log('name, image, about', name, image, about);
    this.appleicon = 'assets/apple-icon-114x114.png';

    this.meta.updateTag({ property: 'og:title', content: name + ' | order online from ' + this.id });
    this.meta.updateTag({ property: 'og:image', content: image });
    //ßthis.meta.updateTag({ property: 'og:url', content: image });
    this.meta.updateTag({ property: 'description', content: about });
    this.meta.updateTag({ property: 'og:description', content: about });
    this.meta.updateTag({ property: 'og:site_name', content: this.id });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'keywords', content: searchwords + ' best buy mall bestbuymall best buymall bestbuy mall shopping online purchase grocery items food medicine delivery order online free home delivery quick track system United Kingdom' });
    this.title.setTitle(name + ' | order online from ' + this.id);



    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ name: 'twitter:site', content: this.id });
    this.meta.updateTag({ name: 'twitter:title', content: name + ' | order online from ' +  this.id });
    this.meta.updateTag({ name: 'twitter:description', content: about });
    this.meta.updateTag({ name: 'twitter:image', content: image });


    // this.metaService.setTitle(name + ' | order online from bestbuymall');
    // this.metaService.setTag('og:title', name + ' | order online from bestbuymall');
    // this.metaService.setTag('description', about);
    // this.metaService.setTag('og:description', about);
    // this.metaService.setTag('og:site_name', 'bestbuymall');
    // this.metaService.setTag('og:type', 'product');
    // this.metaService.setTag('og:image', image);
    // this.metaService.setTag('og:url', image);
    //
    // this.metaService.setTag('twitter:title', name + ' | order online from bestbuymall');
    // this.metaService.setTag('twitter:description', about);
    // this.metaService.setTag('twitter:image', image);
    // this.metaService.setTag('twitter:card', 'summary_large_image');

  }

  disableBackground(){
    $('html').css('overflow-y' , 'hidden');
  }

  enableBackground(){
    $('html').css('overflow-y' , 'auto');
  }

  addToWishlist(obj) {
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined || window.localStorage.getItem('token') === null) {
      this.addBlurbusinessAll();
      this.events.publish('openLoginFunction', 'true');
      return;
    }
    this.clearBlurbusinessAll();
    // if ( $('#businessAll').is(':visible') )  {
    //   this.productDetailData.is_wish_list = true;
    // }
    //
    // for ( var i = 0; i < this.businessDetailProducts.length; i ++ ) {
    //   for( var j = 0; j < this.businessDetailProducts[i].products.length; j++ ) {
    //     if ( obj.id === this.businessDetailProducts[i].products[j].id ) {
    //       this.businessDetailProducts[i].products[j].is_wish_list = true;
    //     }
    //   }
    // }
    if ($('#businessAll').is(':visible')) {
      this.productDetailData.is_wish_list = true;
    }

    if(this.businessDetailProducts != undefined) {
      for (var i = 0; i < this.businessDetailProducts.length; i++) {
        for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
          if (obj.id === this.businessDetailProducts[i].products[j].id) {
            this.businessDetailProducts[i].products[j].is_wish_list = true;
          }
        }
      }
    }

    if (this.searchItem === true && this.loadingData === false) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        if (obj.id === this.searchProductByCategoryByID[i].id) {
          this.searchProductByCategoryByID[i].is_wish_list = true;
        }
      }
    }

    if ($('#SearchProductsByCategory').is(':visible')) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        if (obj.id === this.businessCategoryProducts[i].id) {
          this.businessCategoryProducts[i].is_wish_list = true;
        }
      }
    }


    this.dataService.addProductInWishList(window.localStorage.getItem('token'), obj.id, this.businessId).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }

      },
      (error) => {
      }
    );
    // this.updateBusinessDetailProducts1();
  }

  removeToWishlist(obj) {
    if ($('#businessAll').is(':visible')) {
      this.productDetailData.is_wish_list = false;
    }

    if(this.businessDetailProducts != undefined) {
      for (var i = 0; i < this.businessDetailProducts.length; i++) {
        for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
          if (obj.id === this.businessDetailProducts[i].products[j].id) {
            this.businessDetailProducts[i].products[j].is_wish_list = false;
          }
        }
      }
    }

    if (this.searchItem === true && this.loadingData === false) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        if (obj.id === this.searchProductByCategoryByID[i].id) {
          this.searchProductByCategoryByID[i].is_wish_list = false;
        }
      }
    }

    if ($('#SearchProductsByCategory').is(':visible')) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        if (obj.id === this.businessCategoryProducts[i].id) {
          this.businessCategoryProducts[i].is_wish_list = false;
        }
      }
    }


    this.dataService.removeProductInWishList(window.localStorage.getItem('token'), obj.id).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }

      },
      (error) => {
      }
    );
    // this.updateBusinessDetailProducts1();
  }

  updateBusinessDetailProducts1() {
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }

    // this.dataService.businessDetailProducts(this.id, token).subscribe(
    this.dataService.businessDetailProducts1(this.businessId, token).subscribe(
      (data) => {
        this.businessDetailProductsUpdate = data;
        this.businessDetailProductsUpdate = this.businessDetailProductsUpdate.items;
        for (var i = 0; i < this.businessDetailProductsUpdate.length; i++) {
          for (var j = 0; j < this.businessDetailProductsUpdate[i].products.length; j++) {
            this.businessDetailProducts[i].products[j].is_wish_list = this.businessDetailProductsUpdate[i].products[j].is_wish_list;
          }
        }

        if ($('#businessAll').is(':visible')) {
          for (var i = 0; i < this.businessDetailProductsUpdate.length; i++) {
            for (var j = 0; j < this.businessDetailProductsUpdate[i].products.length; j++) {
              if (this.businessDetailProductsUpdate[i].items.products[j].id === this.productDetailData.id) {
                this.productDetailData.is_wish_list = this.businessDetailProductsUpdate[i].products[j].is_wish_list;
              }
            }
          }
        }
      },
      (error) => {
      }
    );
  }

  updateBusinessCategoryProducts() {
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    this.dataService.businessCategoryProducts(this.id, this.cat_id, token).subscribe(
      (data) => {
        this.businessCategoryProductsUpdate = data;
        this.businessCategoryProductsUpdate = this.businessCategoryProductsUpdate.products;
        for (var i = 0; i < this.businessCategoryProductsUpdate.length; i++) {
          for (var j = 0; j < this.businessCategoryProductsUpdate[i].products.length; j++) {
            this.businessCategoryProducts[i].products[j].is_wish_list = this.businessCategoryProductsUpdate[i].products[j].is_wish_list;
          }
        }
      },
      (error) => {
      }
    );
  }

}
