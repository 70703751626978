import { Component, OnInit } from "@angular/core";
import { DataService } from "../data.service";

import { EventsService } from "angular4-events";
import { Constants } from "../constants";
import { DataControlService } from "../data-control.service";

declare var $: any;
declare var toastr: any;
declare var require: any;
declare var Nanobar: any;

@Component({
  selector: "app-text-us-order",
  templateUrl: "./text-us-order.component.html",
  styleUrls: ["./text-us-order.component.css"],
})
export class TextUsOrderComponent implements OnInit {
  businessLandingVisitFlag: string;
  userCurrentAddress: any;
  addressUpdate: any;
  orderText: any;
  businessDetail: any;
  business_id_customerOrder: any;
  orderCustomizeDetail: any;
  listing: any;
  orderbtn = false;
  customTitle: any;
  groupOptions: string;
  finalOrderText: string = "";
  customProductImage: any;
  custom_order_form: any;
  customImage: any;
  nao = new Nanobar();
  colorCodeVar: string;
  previousPreciptionImages: any[] = [];
  constructor(
    private dataService: DataService,
    private events: EventsService,
    private dataControlService: DataControlService
  ) {
    this.colorCodeVar = Constants.colorCode;
    this.businessLandingVisitFlag = window.localStorage.getItem(
      "businessLandingPageVisitFlag"
    );

    this.events.subscribe("businessDetailData").subscribe((data) => {
      console.log(data);
      this.businessDetail = data["business_detail"];
      this.custom_order_form = this.businessDetail.has_custom_order_form;
      this.customTitle = this.businessDetail.custom_order_title;
      this.business_id_customerOrder = this.businessDetail.id;
      this.customImage = this.businessDetail.custom_title_image;
    });

    this.events.subscribe("textUsYourOrder").subscribe((data) => {
      if (data == true) {
        console.log(data);
        this.openCustomOrderOpoup();
      }
    });
    this.events.subscribe("customOrder").subscribe((data) => {
      if (data == true) {
        console.log(data);
        this.customiseOrder();
      }
    });
    this.userCurrentAddress = JSON.parse(
      window.localStorage.getItem("profileAddressCheckout")
    );
    this.addressUpdate = this.events
      .subscribe("addressUpdate")
      .subscribe((data) => {
        if (data == "true") {
          this.userCurrentAddress = JSON.parse(
            window.localStorage.getItem("profileAddressCheckout")
          );
        }
      });
  }

  ngOnInit() {
    this.businessDetail = JSON.parse(
      window.localStorage.getItem("businessDetailData")
    );
    this.custom_order_form = this.businessDetail[
      "business_detail"
    ].has_custom_order_form;
    this.customTitle = this.businessDetail[
      "business_detail"
    ].custom_order_title;
    this.business_id_customerOrder = this.businessDetail["business_detail"].id;
    this.customImage = this.businessDetail[
      "business_detail"
    ].custom_title_image;

    // this.events.subscribe('businessDetailData').subscribe(
    //   data => {

    //     console.log(data);
    //     this.businessDetail = data['business_detail'];
    //     this.custom_order_form = this.businessDetail.has_custom_order_form;
    //     this.customTitle = this.businessDetail.custom_order_title
    //     this.business_id_customerOrder = this.businessDetail.id;
    //     this.customImage = this.businessDetail.custom_title_image;
    //   }
    // );

    console.log(this.businessDetail);

    this.userCurrentAddress = JSON.parse(
      window.localStorage.getItem("profileAddressCheckout")
    );
    this.addressUpdate = this.events
      .subscribe("addressUpdate")
      .subscribe((data) => {
        if (data == "true") {
          this.userCurrentAddress = JSON.parse(
            window.localStorage.getItem("profileAddressCheckout")
          );
        }
      });

    this.events.subscribe("textUsYourOrder").subscribe((data) => {
      if (data == true) {
        console.log(data);
        this.openCustomOrderOpoup();
      }
    });

    this.events.subscribe("customOrder").subscribe((data) => {
      if (data == true) {
        console.log(data);
        this.customiseOrder();
      }
    });
  }

  addBlur() {
    $("#customOrderPopup").css("-webkit-filter", "blur(3px)");
    $("#customOrderPopup").css("-moz-filter", "blur(3px)");
    $("#customOrderPopup").css("-o-filter", "blur(3px)");
    $("#customOrderPopup").css("-ms-filter", "blur(3px)");
    $("#customOrderPopup").css("filter", "blur(3px)");
  }
  clearBlur() {
    $("#customOrderPopup").css("-webkit-filter", "blur(0px)");
    $("#customOrderPopup").css("-moz-filter", "blur(0px)");
    $("#customOrderPopup").css("-o-filter", "blur(0px)");
    $("#customOrderPopup").css("-ms-filter", "blur(0px)");
    $("#customOrderPopup").css("filter", "blur(0px)");
  }
  customOrderText = "<ul><li></li></ul>";
  customProductImagesCount = 0;
  customOrderImageSuccessUrl: any[] = new Array();

  // this.businessLandingVisitFlag = window.localStorage.getItem('businessLandingFlag');

  addCustomOrder() {
    if (this.previousPreciptionImages.length == 0) {
      if (
        (this.customOrderText === "" ||
          this.customOrderText === "<ul><li></li></ul>") &&
        this.customProductImages.length === 0
      ) {
        toastr.error("Please add text or upload image");
        return;
      }
    }
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === undefined ||
      localStorage.getItem("token") === null
    ) {
      this.addBlur();
      this.events.publish("openLoginFunction", "true");
      return;
    }
    this.clearBlur();
    this.customProductImagesCount = 0;
    this.customOrderImageSuccessUrl = new Array();

    this.addCustomOrderBtn = true;
    if (this.customProductImages.length > 0) {
      var file = this.customProductImages[0];
      this.uploadCustomeOrderImage(file);
    } else {
      this.uploadCustomeOrderText();
    }
  }
  customProductImages = new Array();
  addCustomOrderBtn = false;
  customOrderImageSuccess: any;

  uploadCustomeOrderImage(file) {
    this.dataService
      .uploadOrderImage(
        localStorage.getItem("token"),
        -1,
        file,
        this.userCurrentAddress
      )
      .subscribe(
        (data) => {
          this.customOrderImageSuccess = data;
          if ((this.customOrderImageSuccess.success = true)) {
            this.customOrderImageSuccessUrl.push(
              this.customOrderImageSuccess.url
            );
            this.customProductImagesCount++;
            if (
              this.customProductImages.length > this.customProductImagesCount
            ) {
              var file = this.customProductImages[
                this.customProductImagesCount
              ];
              this.uploadCustomeOrderImage(file);
            } else {
              this.uploadCustomeOrderText();
            }
          } else {
            this.addCustomOrderBtn = false;
            toastr.error("Something went wrong");
          }
        },
        (error) => {}
      );
  }

  disableBackground() {
    $("html").css("overflow-y", "hidden");
  }

  convertHTMLtoText(html) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }
  customOrderSuccess: any;
  dropzone: any;

  uploadCustomeOrderText() {
    var d = this.convertHTMLtoText(this.customOrderText);
    this.previousPreciptionImages
      ? this.customOrderImageSuccessUrl.push(...this.previousPreciptionImages)
      : "";
    this.dataService
      .uploadCustomeOrderText(
        localStorage.getItem("token"),
        -1,
        d,
        this.customOrderImageSuccessUrl,
        this.userCurrentAddress
      )
      .subscribe(
        (data) => {
          this.customOrderSuccess = data;
          if (this.customOrderSuccess.success === true) {
            toastr.success(
              "Your request has been successfuly sent. Our team will contact you soon."
            );
            $("#customOrderPopup").modal("hide");
            this.previousPreciptionImages = [];
            this.dataControlService.prescriptionReport$.next({});
            this.addCustomOrderBtn = false;
            this.customOrderImageSuccessUrl = new Array();
            this.customProductImages = new Array();
          }
        },
        (error) => {}
      );
  }

  enableBackground() {
    $("html").css("overflow-y", "auto");
  }

  closeNav() {
    // document.getElementById('main').style.marginRight = '0';
  }
  openCustomOrderOpoup() {
    this.customOrderText = "<ul><li></li></ul>";
    this.addCustomOrderBtn = false;
    $("#customOrderPopup").modal("show");
    this.dataControlService.prescriptionReport$.subscribe((data: any) => {
      if (data) {
        this.customOrderText = data.notes || "";
        this.previousPreciptionImages = data.image_list || [];
      }
    });
    $(".dz-default").css("display", "block");
    setTimeout(() => {
      $(".dz-preview").remove();
    }, 500);
    const Dropzone = require("dropzone");
    const context = this;
    try {
      new Dropzone("#my-awesome-dropzone", {
        maxFiles: 10,
        addRemoveLinks: true,
        dictDefaultMessage: "Upload Images",
        autoProcessQueue: false,
        paramName: "image",
        acceptedMimeTypes: "image/jpeg, image/jpg, image/png",
        progress: false,
        init: function () {
          context.dropzone = this;
          this.on("addedfile", function (file) {
            if (!file.type.match(/image.*/)) {
              // This is not an image, so Dropzone doesn't create a thumbnail.
              // Set a default thumbnail:
              // this.emit('thumbnail', file, '../app/assets/images/interface.png');
              // You could of course generate another image yourself here,
              // and set it as a data url.
            }
            context.customProductImages.push(file);
          });

          this.on("error", function (file, response) {
            // $(file.previewElement).find('.dz-error-message').text(response.file_name);
            // context.error_count++;
          });

          this.on("queuecomplete", function () {
            // $('#myloader').removeClass('cssload-container');
            // $('#loader_container').removeClass('floatingBarsG');
            // $('.blockG').addClass('myopc');
            // if(context.error_count>0){
            //     toastr.error('Error uploading images.');
            // }
            // else{
            //      toastr.success('Property added successfully.');
            //      context.navigate();
            // }
          });

          this.on("removedfile", function (file) {
            for (var i = 0; i < context.customProductImages.length; i++) {
              if (context.customProductImages[i].name === file.name) {
                context.customProductImages.splice(i, 1);
              }
            }
          });

          this.on("processing", function () {
            this.options.autoProcessQueue = false;
          });

          context.dropzone = this;
        },
      });
    } catch (err) {}
  }

  customiseOrder() {
    console.log("text us component ts");
    $("#customOrder").modal("show");
  }

  customOrder() {
    this.dataService.customOrder(this.business_id_customerOrder).subscribe(
      (data) => {
        this.orderCustomizeDetail = data;
        console.log(this.orderCustomizeDetail);

        this.orderCustomizeDetail = this.orderCustomizeDetail.data;
        this.listing = this.orderCustomizeDetail.custom_order_details;
        console.log(this.listing);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  textUs() {
    $("#customOrderPopup").modal("show");
  }

  closeModal() {
    this.previousPreciptionImages = [];
    this.dataControlService.prescriptionReport$.next({});
  }

  checkBtnCondition() {
    let option = (<HTMLInputElement>document.getElementById("selectOrder"))
      .value;
    // let image = (<HTMLInputElement>document.getElementById('imageId'));

    if (option != "Select Option") {
      this.orderbtn = true;
    } else {
      this.orderbtn = false;
    }
  }
  selectDropdown(heading, option) {
    console.log(this.finalOrderText.search(heading));
    if (this.finalOrderText.search(heading) > -1) {
      let orderTextArray = this.finalOrderText.split(",");
      for (let i = 0; i < orderTextArray.length; i++) {
        if (orderTextArray[i].search(heading) > -1) {
          orderTextArray.splice(i, 1);
        }
        this.finalOrderText = orderTextArray.toString();
      }
    }
    this.groupOptions = heading + ": " + option;
    if (this.finalOrderText == "") {
      this.finalOrderText = this.finalOrderText + this.groupOptions;
    } else {
      this.finalOrderText = this.finalOrderText + "," + this.groupOptions;
    }
    console.log(this.finalOrderText);
  }

  openOrderRequestPopup() {
    setTimeout(() => {
      $(".dz-preview").remove();
    }, 100);
    const Dropzone = require("dropzone");
    const context = this;
    try {
      new Dropzone("#my-awesome-dropzone1", {
        maxFiles: 10,
        addRemoveLinks: true,
        dictDefaultMessage: "Upload Images",
        autoProcessQueue: false,
        paramName: "image",
        acceptedMimeTypes: "image/jpeg, image/jpg, image/png",
        progress: false,
        init: function () {
          context.dropzone = this;

          this.on("addedfile", function (file) {
            if (!file.type.match(/image.*/)) {
              // This is not an image, so Dropzone doesn't create a thumbnail.
              // Set a default thumbnail:
              // this.emit('thumbnail', file, '../app/assets/images/interface.png');
              // You could of course generate another image yourself here,
              // and set it as a data url.
            }
            context.customProductImages.push(file);
            context.checkBtnCondition();
          });

          this.on("error", function (file, response) {
            // $(file.previewElement).find('.dz-error-message').text(response.file_name);
            // context.error_count++;
          });

          this.on("queuecomplete", function () {});

          this.on("removedfile", function (file) {
            for (var i = 0; i < context.customProductImages.length; i++) {
              if (context.customProductImages[i].name === file.name) {
                context.customProductImages.splice(i, 1);
                context.checkBtnCondition();
              }
            }
          });

          this.on("processing", function () {
            this.options.autoProcessQueue = false;
          });

          context.dropzone = this;
        },
      });
    } catch (err) {}
  }

  addCustomOrders() {
    this.orderbtn = false;
    this.nao.go(30);
    if (this.orderText === "" && this.customProductImages.length === 0) {
      toastr.error("Please add text or upload image");
      this.orderbtn = false;
      return;
    }
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === undefined ||
      localStorage.getItem("token") === null
    ) {
      this.addBlur();
      this.orderbtn = true;
      this.events.publish("openLoginFunction", "true");
      return;
    }
    this.clearBlur();
    this.customProductImagesCount = 0;
    this.customOrderImageSuccessUrl = new Array();

    if (this.customProductImages.length > 0) {
      var file = this.customProductImages[0];
      this.uploadOrderImage(file);
    } else {
      this.uploadOrderText();
    }
    // setTimeout(() => {
    // location.reload();
    // }, 5000 );
  }

  uploadOrderImage(file) {
    let data = {
      auth_token: localStorage.getItem("token"),
      image: file,
      dispensary_id: this.business_id_customerOrder,
      delivery_address: this.userCurrentAddress,
    };

    this.dataService.customOrderData(data).subscribe(
      (data) => {
        this.customOrderImageSuccess = data;
        if ((this.customOrderImageSuccess.success = true)) {
          this.customOrderImageSuccessUrl.push(
            this.customOrderImageSuccess.url
          );
          this.customProductImagesCount++;
          if (this.customProductImages.length > this.customProductImagesCount) {
            var file = this.customProductImages[this.customProductImagesCount];
            this.uploadOrderImage(file);
          } else {
            this.uploadOrderText();
          }
        } else {
          this.orderbtn = false;
          toastr.error("Something went wrong");
        }
      },
      (error) => {}
    );
  }

  uploadOrderText() {
    this.nao.go(30);
    let data = {
      auth_token: localStorage.getItem("token"),
      images_list: this.customOrderImageSuccessUrl,
      order_text:
        this.finalOrderText +
        "," +
        "Additional Instructions" +
        ": " +
        this.orderText,
      dispensary_id: this.business_id_customerOrder,
      delivery_address: this.userCurrentAddress,
    };

    this.dataService.orderRequestText(data).subscribe(
      (data) => {
        this.nao.go(100);
        this.customOrderSuccess = data;
        if (this.customOrderSuccess.success === true) {
          toastr.success(
            "Your request has been successfuly sent. Our team will contact you soon."
          );
          $("#customiseOrder").modal("hide");
          this.orderbtn = false;
          this.customOrderImageSuccessUrl = new Array();
          this.customProductImages = new Array();
        }
      },
      (error) => {}
    );
  }

  smallPopUp() {
    $("#customOrder").modal("show");
  }
}
