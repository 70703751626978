import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { DataControlService } from '../data-control.service';
import { Constants } from '../constants';

declare var $: any;
declare var Nanobar: any;
declare var toastr: any;


@Component({
  selector: 'app-notefound',
  templateUrl: './notefound.component.html',
  styleUrls: ['./notefound.component.css']
})
export class NotefoundComponent implements OnInit {

  colorCode: string;

  constructor(private dataControlService: DataControlService, private router: Router) {
    this.colorCode = Constants.colorCode;
  }
  goToHome(){
    this.router.navigate(['']);
  }
  ngOnInit() {
    localStorage.setItem('isFirstTimeHomeOpen', "false");
    //this.dataControlService.setHeaderAddressVisiblity.next(true);

    window.scrollTo(0, 0);
    $('.navbar').removeClass('sticky-header');
    $('.navbar').css('background-color', '#fff');
    $('.navbar').css('position', 'fixed');
    $('.logInbtn').css('color', '#FFFFFF');
    $('.navbar').css('border-bottom', '1px solid #ebebeb');
    $('#whiteLogo').css('display', 'none');
    $('#blackLogo').css('display', 'inline-block');
    $('.cartHeadBtn').css('background', this.colorCode);
    $('.cartHeadBtn').css('color', '#ffffff');
    $('.topName').css('color', this.colorCode);
    $('.username').css('color', '#000000');
    $('.business-logo').css('display', 'none');
    $('.navbar-header').css('margin-top', '0px');
  }

}
