
export const fb_events_consts = {
    PAGE_VIEW: 'PageView',
    FIND_LOCATION: 'Fb_Find location',
    SEARCH: 'Fb_Search',
    VIEW_CONTENT: 'Fb_View Content_',
    MICRO_DATA_1: 'Fb_Microdata automatically detected',
    MICRO_DATA_2: 'Microdata automatically Detected',
    AUTO_LOCATE_ME: 'Fb_Buttonclick automatically detected (Locate me)',
    UNIQUE_VIEW_CONTENT: 'Fb_unique view content_',
    FB_BUTTON_CLICK: 'Fb_Buttonclick automatically detected (+)',
    ADD_TO_CART: 'Fb_Add to cart_',
    UNIQUE_ADD_TO_CART: 'Fb_unique add to cart_',
    ADD_TO_WISHLIST: 'Fb_Add to wishlist_',
    AUTO_ADD_TO_CART: 'Fb_Buttonclick automatically detected (Add to cart)',
    CHECKOUT_1: '_Intiated checkout 1',
    CHECKOUT_2: '_Intiated checkout_2',
    REGISTER_LOGIN: 'Fb_reg_Login',
    REGISTERATION: 'Fb_complete registration',
    AUTO_PLACE_ORDER: 'Fb_Buttonclick automatically detected (Place order)',
    PURCHASE: '_purchase',
    DOWNLOAD_IOS_APP: 'Fb_Download app_IOS',
    DOWNLOAD_ANDROID_APP: 'Fb_Download app_Android',
}