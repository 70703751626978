import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { AuthenticationService} from '../authentication.service';
import {CountryService} from '../country.service';
import {EventsService} from 'angular4-events';
import {DataService} from '../data.service';
import {Router} from '@angular/router';
import { DataControlService } from '../data-control.service';
import { Constants } from '../constants';

declare var firebase: any;
declare var Nanobar: any;
declare var context: any;
declare var toastr: any;
declare var jQuery: any;
declare var google: any;
declare var $: any;

@Component({
  selector: 'app-header-landing',
  templateUrl: './header-landing.component.html',
  styleUrls: ['./header-landing.component.css']
})
export class HeaderLandingComponent implements OnInit {

  getCartItems: any;
  carBtnShowHide = false;
  cartCount = 0;
  customArray = new Array();
  cartDataFromStorage = new Array();
  chkOutTotal = 0;
  cartPush: any;
  orderNotes = '';
  colorCode: string;
  get authChk(): any {
    return localStorage.getItem('isauthenticated');
  }
  get getUserName(): any {
    return localStorage.getItem('displayName');
  }
  constructor(private dataControlService: DataControlService, private authService: AuthenticationService, private dataService: DataService, private events: EventsService,
              private changeRef: ChangeDetectorRef, private router: Router, private cs: CountryService) {
    this.colorCode = Constants.colorCode;
    this.getCartItems = JSON.parse(localStorage.getItem('cartItems'));
    if (this.getCartItems === null) {
      this.carBtnShowHide = false;
      this.cartCount = 0;
    } else {
      if (this.getCartItems.length > 0) {
        this.cartCount = this.getCartItems.length;
        this.carBtnShowHide = true;
      }
    }
    // this.pushReceive = this.events.subscribe('dataChange').subscribe((data) => {
    //   this.cityname = data;
    // });
    this.cartPush = this.events.subscribe('cartUpdate').subscribe((data) => {
      window.localStorage.setItem('cartItems', JSON.stringify(data));
      setTimeout(() => {
        this.getCartItems = JSON.parse(localStorage.getItem('cartItems'));
        if (this.getCartItems.length > 0) {
          this.carBtnShowHide = true;
          this.cartCount = this.getCartItems.length;
          //this.changeRef.detectChanges();
          if (this.dataService.pageName === 'businesses') {
            $('.cartHeadBtn').css('background', this.colorCode);
            $('.cartHeadBtn').css('color', '#ffffff');
          }
          
        } else {
          this.carBtnShowHide = false;
          //this.changeRef.detectChanges();
        }
      }, 500);
    });
  }

  GetUnique(inputArray) {
    var outputArray = [];
    for (var i = 0; i < inputArray.length; i++) {
      if ((jQuery.inArray(inputArray[i].business_id, outputArray)) === -1) {
        outputArray.push(inputArray[i].business_id);
      }
    }
    return outputArray;
  }
  logoutFunction(){

  }
  signInPop(){
    
  }
  openFinalCartPop() {
    this.customArray = [];
    this.chkOutTotal = 0;
    this.orderNotes = '';
    this.getCartItems = JSON.parse(localStorage.getItem('cartItems'));
    // for (let a = 0; a < this.getCartItems.length; a++) {
    //   if (this.getCartItems[a].inventory_count === 0) {
    //     // this.getCartItems.splice(a);
    //   }
    // }
    const outputData = this.GetUnique(this.getCartItems);
    const array = [];
    let obj;
    let orders = [];
    let store_index;
    for (let i = 0; i < outputData.length; i++) {
      orders = [];
      for (let j = 0; j < this.getCartItems.length; j++) {
        if (outputData[i] === this.getCartItems[j].business_id) {
          store_index = j;
          orders.push({
            product_id: this.getCartItems[j].product_id,
            product_name: this.getCartItems[j].product_name,
            product_image: this.getCartItems[j].product_image,
            variant_id: this.getCartItems[j].variant_id,
            variant_name: this.getCartItems[j].variant_name,
            variant_value: this.getCartItems[j].variant_value,
            inventory_count: this.getCartItems[j].inventory_count,
            price: this.getCartItems[j].price,
            quantity: this.getCartItems[j].quantity
          });
        }
      }
      let sum = 0;
      for (let a = 0; a < orders.length; a++) {
        sum = sum + (orders[a].quantity * orders[a].price);
      }
      obj = {
        'dispensary_id': this.getCartItems[store_index].business_id,
        'business_image': this.getCartItems[store_index].business_image,
        'business_name': this.getCartItems[store_index].business_name,
        'outlet_address': this.getCartItems[store_index].outlet_address,
        'business_delivery_charges': this.getCartItems[store_index].business_delivery_charges,
        'business_platform_fee': this.getCartItems[store_index].business_platform_fee,
        'business_total_charges': this.getCartItems[store_index].business_total_charges,
        'discount': 0,
        'products': orders,
        'sub_total': sum,
        'delivery_charges': this.getCartItems[store_index].business_total_charges,
        'tax_charges': sum * 0.1,
        'total_price': sum + this.getCartItems[store_index].business_total_charges + (sum * 0.1)

      };
      this.customArray.push(obj);
    }
    for (let t = 0; t < this.customArray.length; t++) {
      this.chkOutTotal = this.chkOutTotal + this.customArray[t].total_price;
    }
    $('#chkoutPop').modal('show');
  }

  ngOnInit() {
    localStorage.setItem('isFirstTimeHomeOpen', "false");

  }

}

