import { Component, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { DataService } from '../data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EventsService } from 'angular4-events';
import { Constants } from '../constants';
import { Meta, Title } from '@angular/platform-browser';
import { DataControlService } from '../data-control.service';
import 'rxjs/add/operator/filter';

declare var $: any;
declare var Nanobar: any;
declare var toastr: any;
declare var require: any;

@Component({
  selector: 'app-business-landing',
  templateUrl: './business-landing.component.html',
  styleUrls: ['./business-landing.component.css']
})
export class BusinessLandingComponent implements OnInit {
  owl2: any;
  priceIconForSingle = Constants.iconForSinglePrice;
  priceIconForDouble = Constants.iconForDoublePrice;
  starsCount: any;
  websiteUrl = '';
  nao = new Nanobar();
  id: any;
  private sub: any;
  businessData: any;
  businessDetailData: any;
  businessRaiting: any;
  businessRaitingArray = new Array();
  businessDetalSuccess: any;
  cityName: any;
  businessDetailProducts: any;
  businessDetailProductsUpdate: any;
  detailProductSuccess: any;
  loadingData = true;
  detailDataLoad = false;
  productDetailData: any;
  productSuccess: any;
  cartPopData: any;
  variantData: any;
  businessId: any;
  cartFinal = new Array();
  cartDataFromStorage = new Array();
  businessName: any;
  business_image: any;
  businessCover = true;
  businessCoverHeight: any;
  business_delivery_charges: any;
  business_platform_fee: any;
  business_total_charges: any;
  taxOfBusiness: any;
  searchString = '';
  searchStringCat = '';
  clearTimeout: any;
  searchedData: any;
  searchItem = false;
  pushReceive: any;
  gmt_date: any;
  gmt_date2: any;
  staticDate = '2018/05/16';
  dateObjOpen: any;
  dateObjClose: any;
  zoneOperator: any;
  milliseconds: any;
  seconds: any;
  minutes: any;
  hours: any;
  currentSystemTime: any;
  businessStatus: any;
  openOrCloseIn: any;
  outletAddress: any;
  key: any;
  businessCategoryProducts: any;
  businessCategoryProductsUpdate: any;
  cat_id: any;
  cat_name: any;
  loadingDataCat = true;
  searchItemCat = false;
  isFoundBusiness: any;
  clearTimeout1: any;
  clearTimeouthome: any;
  cartPush: any;
  cartChngeFromCheckout: any;
  productFound = false;
  payment_through_card: any;
  payment_through_cash: any;
  payment_over_due: any;
  // cartChngeFromCheckout1: any;
  objToOveride: any;
  indexToOveride: any;
  disableMultiClick = false;
  finalDisplayTime: any;
  fullDayStatus: any;
  priceUpdateFromCheckoutVal: any;
  priceUpdateFromCheckout: any;
  islogin: any;
  isloginValue: any;
  storyData: any;
  storylimit = 3;
  addWishlistSuccess: any;
  todayData1 = new Date();
  todayData = this.todayData1.getDay();
  businessDetailProductSsearchString = '';
  timeObj = [];
  appleicon: any;
  widthOfWindow: any;
  limit = 6;
  PagnationPage = 0;
  paginationPage = 0;
  businessDetailProductSearchString = '';
  lastPage = true;
  serchstring1: any;
  loadPreviousProductsData: any;
  loadPreviousProductsDataCount = 0;
  loadPreviousProductsDataLastPage = true;
  searchProductByCategoryByID: any;
  conditionToCheck = true;
  scrollPosition: any;
  businessDetailProductsPaginate: any;
  businessCategoryProductsBypage: any;
  lastPageOfCategory = true;
  PaginationPageByCategory = 0;
  getProductsViaCategoryLimit = 50;
  limitForSearch = 25;
  PaginationPageForSearch = 0;
  searchProductByCategoryByIDLastPage = true;
  searchProductByCategoryByIDByPage: any;
  loaderCondition = false;
  SeeAllLoader = false;
  pageCondition = true;
  dropzone: any;
  customProductImages = new Array();
  customOrderText = '<ul><li></li></ul>';
  customOrderSuccess: any;
  customOrderImageSuccess: any;
  addCustomOrderBtn = false;
  customProductImagesCount = 0;
  customOrderImageSuccessUrl = new Array();
  showBusinessProductsliderLoading = false;
  catList: any;
  subCatData: any;
  subCatLength = 0;
  subCatid = -1;
  subCatslider = false;
  hell = false;
  productDetailDataImagesLength: any;
  clearTimeoutProductWhenEmpty: any;
  productId: '';
  subcatLoadingShimmer = false;
  businessCategoryProductslength = false;
  showBusinessTiming = false;
  idForNavigate = 'products';
  fromDomain = false;

  // businessLandingPageVisitFlag = "false";

  colorSaturate: string;
  colorInvert: string;
  colorSepia: string;
  colorHue_Rotate: string;
  colorContrast: string;
  colorBrightness: string;
  color_code: string;
  isBusinessLandingPage: Boolean = false;
  businessBackgroundImage: any;
  businessThemeStructure: any;
  dispensaryAlias: any;
  sidebarCategory: any;
  parentCatName: any = '';
  subCatName: any = '';
  childCatName: any = '';
  navLoadData: any = true;
  userCurrentAddress: any;

  productData: any;
  addItValue: any;
  productModifierDataArray = new Array;
  modifiersArr: any[];
  addonsArr: any[];
  noOptionsArr: any[];
  additonalValue: number;
  newCart: any;
  selectedVariant: any;
  uniqueId: any;
  modifierProductList = new Array;
  oldUniqueId: any = '';
  orderCustomizeDetail: any;
  cartUnavailHead: any = '';
  cartUnavailText: any = '';
  hasCustomOrder: any;
  qrCode: any;
  loadFooter: boolean = false;
  colorCode: string;

  constructor(
    private dataControlService: DataControlService,
    private changeRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private events: EventsService,
    private meta: Meta,
    private businessInfo: EventsService,
    private title: Title,

  ) {
    this.colorCode = Constants.colorCode;
    $(window).on('popstate', function () {
      $('.modal').modal('hide');
      $('.modal-backdrop').remove();
      $('.in').remove();

      // this.events.subscribe('customOrder').subscribe((data) => {
      //   if(data == true) {
      //     console.log(data);
      //     this.customiseOrder();
      //   }
      // });

    });
    this.dataControlService.isBusinessLandingPage.next(true);
    localStorage.setItem('colorChangeCode', this.colorCode);
    localStorage.setItem('isBusinessFlag', 'true');
    // this.businessFlag.publish(this.isBusinessFlag);
    this.sub = this.route.params.subscribe(params => {
      if (params['id'] !== '') {
        this.id = params['id'];
        // console.log('this.id', this.id);
        this.idForNavigate = this.id;
      }
    });
    if (this.id === 'x---bbm-route-config-1@#$#^@$!---YY234215--X--saffg-12454jh-4b5jsj' || this.id === 'products') {
      this.id = window.location.hostname;
      this.idForNavigate = 'products';
    }

    if (window.location.hostname.includes('businessweb.bestbuymall.co') || window.location.hostname.includes(Constants.domainNmae)) {
      this.fromDomain = true;
    } else if (window.location.hostname.includes('bestbuymall.co') || window.location.hostname.includes('bestbuymall.co') || window.location.hostname.includes('dev.bestbuymall.co') || window.location.hostname.includes('dev.bestbuymall.co') || window.location.hostname.includes('localhost') || window.location.hostname.includes('bbm.com.pk') ) {
      this.fromDomain = false;
    } else {
      this.fromDomain = true;
    }

    // console.log('this.id host', this.id);

    // if( this.id === 'bestbuymall.co' || this.id === 'dev.bestbuymall.co' ) {
    //   this.router.navigate(['/notfound']);
    // }

    this.route.queryParams.filter(params => params.id).subscribe(params => {
      // console.log('paramsparamsparams', params);
      this.productId = params.id;
      this.getproductDetail(params.id);
    });


    this.dataService.businessIdForIndependentBusinessData = this.id;
    this.dataService.userFrom = 'store';

    var width = $(window).width();
    // console.log('widthwidthwidth', width);
    this.businessCoverHeight = 5 / 12 * width;
    // this.businessCoverHeight = this.businessCoverHeight - 150;
    this.businessCoverHeight = this.businessCoverHeight + 'px';
    this.dataService.pageName = 'businesses';
    this.isloginValue = this.events.subscribe('inlogin').subscribe((data) => {
      setTimeout(() => {
        this.islogin = data;
        if (this.islogin === 'true') {
          // console.log('islogin', this.islogin);
          this.updateBusinessDetailProducts1();
          if ($('#SeeMorePopup').is(':visible')) {
            this.updateBusinessCategoryProducts();
          }
        }
        if (this.islogin === 'false') {
          // console.log('islogin', this.islogin);
          for (var i = 0; i < this.businessDetailProducts.length; i++) {
            for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
              this.businessDetailProducts[i].products[j].is_wish_list = false;
            }
          }
        }
      }, 500);
    });

    this.cartPush = this.events.subscribe('checkoutDone').subscribe((data) => {
      setTimeout(() => {
        for (var i = 0; i < this.businessDetailProducts.length; i++) {
          for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
            for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
              this.businessDetailProducts[i].products[j].count = 0;
              // console.log('in count', this.businessDetailProducts[i].products[j].count);
            }
          }
        }
        for (var i = 0; i < this.businessCategoryProducts.length; i++) {
          for (var j = 0; j < this.businessCategoryProducts[i].products.length; j++) {
            for (var k = 0; k < this.businessCategoryProducts[i].products[j].variant.length; k++) {
              this.businessCategoryProducts[i].products[j].count = 0;
              // console.log('in count', this.businessCategoryProducts[i].products[j].count);
            }
          }
        }
        //this.changeRef.detectChanges();
      }, 2000);
    });

    this.cartChngeFromCheckout = this.events.subscribe('cartUpdate').subscribe((data) => {
      setTimeout(() => {
        this.cartChngeFromCheckoutDone();
        //this.changeRef.detectChanges();
      }, 1000);
    });
    this.priceUpdateFromCheckoutVal = this.events.subscribe('priceUpdateFromCheckout').subscribe((data) => {
      setTimeout(() => {
        this.priceUpdateFromCheckout = data;
        this.updateBusinessData();
      }, 500);
    });

    this.events.subscribe('closeBanner').subscribe(
      (data) => {
        if (data == false) {
          this.qrCode = false;
        }
      })

  }




  updateBusinessData() {
    if (this.id !== undefined && this.id !== '' && this.id !== null) {
      this.getBusinessDetail();
      this.getBusinessDetailProducts1();
      if ($('#SeeMorePopup').is(':visible')) {
        this.getBusinessCategoryProducts();
      }
    }
  }

  tConvert(time) {
    // console.log('time in t convert', time);
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    // console.log('time after convert', time);

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      // console.log('tme',time);
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    // console.log('time final', time);
    return time.join(''); // return adjusted time or original string
  }

  setMetaTags(title, image, description, keyword) {
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:image', content: image });
    //this.meta.updateTag({ property: 'og:url', content: this.websiteUrl + this.router.url });
    this.meta.updateTag({ property: 'description', content: description });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ property: 'keywords', content: keyword });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.title.setTitle(title);

    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ name: 'twitter:title', content: title });
    this.meta.updateTag({ name: 'twitter:description', content: description });
    this.meta.updateTag({ name: 'twitter:image', content: image });
  }

  // changeThemeColor(primaryColor: string ) {
  //   // debugger;
  //   document.documentElement.style.setProperty( '--themeColorVar', primaryColor);

  // }

  getBusinessDetail() {
    this.loaderCondition = false;
    this.SeeAllLoader = false;

    $('.ProductTab').removeClass('active');
    $('.AboutTab').removeClass('active');
    $('.CategoriesTab').removeClass('active');
    $('#products').removeClass('active');
    $('#about').removeClass('active');
    $('#categories').removeClass('active');

    $('#categories').addClass('active');
    $('.CategoriesTab').addClass('active');
    $('#categories').addClass('in');

    // this.nao.go(30);
    // if ( this.id === '' ){
    //   return;
    // }
    this.businessStatus = '';
    this.openOrCloseIn = '';
    this.businessRaitingArray = new Array();

    var todayData = this.todayData;
    if (this.todayData === 0) {
      todayData = 7;
    }
    this.dataService.businessDetail(this.id, todayData).subscribe(
      (data) => {
        // this.nao.go(100);
        this.businessDetailData = data;
        this.loadFooter = true;
        console.log(this.businessDetailData.business_detail.business_category_id);
        localStorage.setItem('business_store_category_id', this.businessDetailData.business_detail.business_category_id);

        if (this.businessDetailData.rest_error_code === 4 || this.businessDetailData.rest_error_code === 1) {
          this.router.navigate(['/notfound']);
          return;

        }

        if (this.businessDetailData.business_detail.show_cart_on_web == false) {
          console.log(this.businessDetailData.business_detail.show_cart_on_web);
          this.cartUnavailHead = this.businessDetailData.business_detail.cart_unavail_head;
          this.cartUnavailText = this.businessDetailData.business_detail.cart_unavail_text;
          $('#cartAvailablePopUp').modal('show');
        }
        this.hasCustomOrder = this.businessDetailData.business_detail.has_custom_order_form;


        // nothing
        this.businessDetalSuccess = this.businessDetailData.success;
        this.businessDetailData = this.businessDetailData.business_detail;
        if (this.businessDetailData.theme_color_attributes.color_saturate == '' || this.businessDetailData.theme_color_attributes.color_saturate == undefined) {
          this.colorSaturate = '5194%';
          this.colorInvert = '18%';
          this.colorSepia = '5%';
          this.colorHue_Rotate = '202deg';
          this.colorContrast = '94%';
          this.colorBrightness = '94%';
          this.color_code = Constants.colorCode;
        }
        else {
          this.colorSaturate = this.businessDetailData.theme_color_attributes.color_saturate;
          this.colorInvert = this.businessDetailData.theme_color_attributes.color_invert;
          this.colorSepia = this.businessDetailData.theme_color_attributes.color_sepia;
          this.colorHue_Rotate = this.businessDetailData.theme_color_attributes.color_hue_rotate;
          this.colorContrast = this.businessDetailData.theme_color_attributes.color_contrast;
          this.colorBrightness = this.businessDetailData.theme_color_attributes.color_brightness;
          this.color_code = this.businessDetailData.theme_color_attributes.color_code;
          this.businessBackgroundImage = this.businessDetailData.theme_color_attributes.business_background_image;
          localStorage.setItem('colorChangeCode', this.color_code);
        }

        document.documentElement.style.setProperty('--colorCodeVar', this.color_code);
        document.documentElement.style.setProperty('--saturateVar', this.colorSaturate);
        document.documentElement.style.setProperty('--invertVar', this.colorInvert);
        document.documentElement.style.setProperty('--hue_rotateVar', this.colorHue_Rotate);
        document.documentElement.style.setProperty('--constrastVar', this.colorContrast);
        document.documentElement.style.setProperty('--brightnessVar', this.colorBrightness);
        document.documentElement.style.setProperty('--sepiaVar', this.colorSepia);



        // this.appleicon = this.businessDetailData.square_image;

        if (this.productId === '' || this.productId === undefined || this.productId === null) {
          // this.setMetaData(this.businessDetailData.name, this.businessDetailData.square_logo_image, this.businessDetailData.about, 'Best Buy Mall Fast Delivery Last Mile Quick Track System Location Online Home Purchase');
          this.setMetaTags(this.businessDetailData.seo_title, this.businessDetailData.square_logo_image, this.businessDetailData.seo_description, this.businessDetailData.seo_keyword);

        }

        this.businessRaiting = this.businessDetailData.rating;
        this.starsCount = this.businessDetailData.rating;
        this.businessRaiting = Math.round(this.businessRaiting);
        for (let z = 0; z < this.businessRaiting; z++) {
          this.businessRaitingArray.push({
            value: 'true'
          });
        }
        let businessRaitingArraylen = this.businessRaitingArray.length;
        for (let x = 5; x > businessRaitingArraylen; x--) {
          this.businessRaitingArray.push({
            value: 'false'
          });
        }

        // Time Work Start
        if (this.businessDetailData.full_day === true) {
          this.businessStatus = 'Open';
          this.openOrCloseIn = '';
          this.finalDisplayTime = '';
        } else {
          var cDate = new Date().getDate();
          var cYear = new Date().getFullYear();
          var cMonth = new Date().getUTCMonth() + 1;
          var openTime = this.businessDetailData.open_time;
          var tOpen = cYear + '/' + cMonth + '/' + cDate + ' ' + openTime + ' ' + 'UTC';
          this.dateObjOpen = new Date(tOpen);
          // console.log('this.dateObjOpen', this.dateObjOpen);
          var closeTime = this.businessDetailData.close_time;
          var tClose = cYear + '/' + cMonth + '/' + cDate + ' ' + closeTime + ' ' + 'UTC';
          this.dateObjClose = new Date(tClose);
          // console.log('this.dateObjClose', this.dateObjClose);
          this.currentSystemTime = new Date().getTime();
          // console.log('in time zone open check', this.currentSystemTime);
          // this.currentSystemTime = this.currentSystemTime / 1000;
          var convertedTimeWithZone = new Date(this.dateObjOpen).getTime();
          // console.log('in time zone open check', convertedTimeWithZone);
          // convertedTimeWithZone = convertedTimeWithZone / 1000;
          var convertedTimeWithZone2 = new Date(this.dateObjClose).getTime();
          // console.log('in time zone Close check', convertedTimeWithZone2);
          // convertedTimeWithZone2 = convertedTimeWithZone2 / 1000;
          if (convertedTimeWithZone2 < convertedTimeWithZone) {
            convertedTimeWithZone2 = convertedTimeWithZone2 + 86400000;
            // this.currentSystemTime = this.currentSystemTime + 86400000;
          }
          if (convertedTimeWithZone2 === convertedTimeWithZone) {
            convertedTimeWithZone2 = convertedTimeWithZone2 + 86400000 + 86400000;
            // this.currentSystemTime = this.currentSystemTime + 86400000;
          }
          if ((this.currentSystemTime >= convertedTimeWithZone) && (this.currentSystemTime <= convertedTimeWithZone2)) {
            this.businessStatus = 'Open';
            this.openOrCloseIn = 'Will close ';
            // this.openOrCloseIn = 'Will close ' + this.businessDetailData.close_time;
            this.finalDisplayTime = this.dateObjClose;
          } else {
            this.businessStatus = 'Closed';
            // console.log('in close chek function', this.businessStatus);
            // this.openOrCloseIn = 'Will reopen ' + this.businessDetailData.open_time;
            this.openOrCloseIn = 'Will reopen';
            this.finalDisplayTime = this.dateObjOpen;
          }
          // console.log('finalDisplayTimefinalDisplayTimefinalDisplayTimefinalDisplayTime', convertedTimeWithZone, convertedTimeWithZone2);
        }
        // Time Work End


        this.businessName = this.businessDetailData.name;
        this.businessId = this.businessDetailData.id;
        this.business_image = this.businessDetailData.square_image;
        this.business_delivery_charges = this.businessDetailData.delivery_charges;
        this.business_platform_fee = this.businessDetailData.platform_fee;
        this.business_total_charges = this.businessDetailData.total_charges;
        this.taxOfBusiness = this.businessDetailData.tax_percentage;
        this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
        this.payment_through_card = this.businessDetailData.payment_through_card;
        this.payment_through_cash = this.businessDetailData.payment_through_cash;
        this.payment_over_due = this.businessDetailData.payment_over_due;
        this.fullDayStatus = this.businessDetailData.full_day;
        this.timeObj = this.businessDetailData.time_list;
        this.businessThemeStructure = this.businessDetailData.theme_structure_id;
        console.log(this.businessThemeStructure);
        this.dispensaryAlias = this.businessDetailData.alias;
        // localStorage.setItem('businessAliasId', this.dispensaryAlias);
        // window.localStorage.setItem('businessLogoUpdate', this.businessDetailData.business_logo_image);
        // debugger;
        setTimeout(() => {
          // this.events.publish('businessAliasId', this.dispensaryAlias);
          this.events.publish('idForNavigate', this.idForNavigate);
        }, 1000);
        this.events.publish('businessLogoUpdate', this.businessDetailData.business_logo_image);
        this.events.publish('businessThemeStructure', this.businessDetailData.theme_structure_id);
        this.events.publish('businessLandingAlisa', this.businessDetailData);
        this.businessInfo.publish('businessContInfo', this.businessDetailData);
        // console.log('payment_over_due',this.payment_over_due);
        if (this.businessDetalSuccess === true) {
          this.detailDataLoad = true;
          this.outletAddress = {
            area: this.businessDetailData.street,
            city: this.businessDetailData.city,
            state: this.businessDetailData.state,
            zip: this.businessDetailData.zip,
            country: this.businessDetailData.area,
            longitude: this.businessDetailData.longitude,
            latitude: this.businessDetailData.latitude
          };
          // console.log('outlet address', this.outletAddress);
          this.openNav();

          setTimeout(() => {
            $('#businessCoverMain').css('display', 'none');
            $('#businessCoverMain1').css('display', 'block');
            this.businessCover = false;
            for (var j = 0; j < this.timeObj.length; j++) {
              if (this.timeObj[j].fullday === false) {
                var timeData = this.convertTimeFunction(this.timeObj[j].open, this.timeObj[j].close);
                this.timeObj[j].open = timeData[0];
                this.timeObj[j].close = timeData[1];
                // console.log('this.timeObj', this.timeObj[j]);
              }
            }
            this.showBusinessTiming = true;
          }, 1000);
        }
        // if(this.businessThemeStructure === 1) {
        //   this.dedicatedCategoryListing(this.dispensaryAlias);
        // }
        this.navLoadData = false;
        if (this.businessThemeStructure != 1) {
        }
        this.CategoryListing();
      },
      (error) => {
        // this.nao.go(100);
      }
    );
  }

  // dedicatedCategoryListing(dispensaryId) {
  //   this.dataService.dedicatedCategoryListing(dispensaryId).subscribe(
  //     data => {
  //       this.sidebarCategory = data;
  //       console.log(this.sidebarCategory);
  //     },
  //     err => {
  //       console.log(err);
  //     }
  //   );
  // }

  convertTimeFunction(open_time, close_time) {
    // Open Time
    var cDate = new Date().getDate();
    var cYear = new Date().getFullYear();
    var cMonth = new Date().getUTCMonth() + 1;
    var openTime = open_time;
    var tOpen = cYear + '/' + cMonth + '/' + cDate + ' ' + openTime + ' ' + 'UTC';
    var dateObjOpen = new Date(tOpen);
    // Close Time
    var cDateClose = new Date().getDate();
    var cYearClose = new Date().getFullYear();
    var cMonthClose = new Date().getUTCMonth() + 1;
    var closeTime = close_time;
    var tClose = cYearClose + '/' + cMonthClose + '/' + cDateClose + ' ' + closeTime + ' ' + 'UTC';
    var dateObjClose = new Date(tClose);

    var returnedArray = [];
    returnedArray.push(dateObjOpen);
    returnedArray.push(dateObjClose);
    return returnedArray;
  }

  getBusinessDetailProducts() {
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    // console.log('cartData', cartData);
    this.nao.go(30);
    this.dataService.businessDetailProducts(this.id, token).subscribe(
      (data) => {
        this.businessDetailProducts = data;
        this.nao.go(100);
        // this.searchItem = false;
        // this.loadingData = false;
        this.initSlider();
        this.iniatlizeSlider();
        this.detailProductSuccess = this.businessDetailProducts.success;
        this.businessDetailProducts = this.businessDetailProducts.products;
        // console.log('this.businessDetailProducts', this.businessDetailProducts);
        for (var i = 0; i < this.businessDetailProducts.length; i++) {
          for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
            for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
              for (var l = 0; l < cartData.length; l++) {
                if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                  this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                  // console.log('in count', this.businessDetailProducts[i].products[j].count);
                }
              }
            }
          }
        }
        this.iniatlizeSlider();
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }

  getBusinessDetailProducts1ByPage() {
    this.PagnationPage++;
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    this.businessDetailProductsPaginate = [];
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    // console.log('cartData', cartData);
    // this.nao.go(30);

    this.cat_id = this.id;
    this.dataService.businessSearchProducts(this.id, this.businessDetailProductSearchString, this.limit, this.PagnationPage, token).subscribe(
      (data) => {
        this.loaderCondition = false;
        // this.nao.go(100);
        // clearInterval(this.clearTimeoutProduct);
        this.businessDetailProductsPaginate = data;

        this.searchItem = false;
        this.loadingData = false;
        // this.PagnationPage++;
        this.lastPage = this.businessDetailProductsPaginate.last_page;
        if (this.businessDetailProductSearchString === '') {
          this.loadPreviousProductsDataCount = this.PagnationPage;
          this.loadPreviousProductsDataLastPage = this.lastPage;
        }
        this.detailProductSuccess = this.businessDetailProductsPaginate.success;
        this.businessDetailProductsPaginate = this.businessDetailProductsPaginate.items;
        // this.initSlider();
        this.initSlider();
        this.iniatlizeSlider();
        if (this.businessDetailProductsPaginate.length !== 0) {
          this.productFound = true;
        }
        if (cartData !== null) {
          for (var i = 0; i < this.businessDetailProductsPaginate.length; i++) {
            for (var j = 0; j < this.businessDetailProductsPaginate[i].products.length; j++) {
              for (var k = 0; k < this.businessDetailProductsPaginate[i].products[j].variant.length; k++) {
                for (var l = 0; l < cartData.length; l++) {
                  if (cartData[l].variant_id === this.businessDetailProductsPaginate[i].products[j].variant[k].id) {
                    this.businessDetailProductsPaginate[i].products[j].count += cartData[l].quantity;
                    // console.log('in count11', this.businessDetailProducts[i].products[j].count);
                  }
                }
              }
            }
          }
        }
        for (var k = 0; k < this.businessDetailProductsPaginate.length; k++) {
          this.businessDetailProducts.push(this.businessDetailProductsPaginate[k]);
        }
        this.pageCondition = true;
      },
      (error) => {
        // this.nao.go(100);
      }
    );

  }

  getBusinessDetailProducts1() {
    this.PagnationPage = 0;
    this.loaderCondition = false;
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    this.businessDetailProducts = [];
    // console.log('Hello Asad', cartData);
    // this.nao.go(30);
    this.cat_id = this.id;
    this.dataService.businessSearchProducts(this.id, this.businessDetailProductSearchString, this.limit, this.PagnationPage, token).subscribe(
      (data) => {
        this.businessDetailProducts = data;
        // this.PagnationPage++;
        this.iniatlizeSlider();
        this.lastPage = this.businessDetailProducts.last_page;
        this.detailProductSuccess = this.businessDetailProducts.success;
        this.businessDetailProducts = this.businessDetailProducts.items;
        // console.log('businessDetailProducts', this.businessDetailProducts)
        // this.initSlider();
        this.initSlider();
        this.iniatlizeSlider();
        if (this.businessDetailProducts !== undefined) {
          if (this.businessDetailProducts.length !== 0) {
            this.productFound = true;
          }
          if (cartData !== null) {
            for (var i = 0; i < this.businessDetailProducts.length; i++) {
              for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
                for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
                  // console.log('cartData', cartData);
                  for (var l = 0; l < cartData.length; l++) {
                    if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                      this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                      // console.log('in count11', this.businessDetailProducts[i].products[j].count);
                    }
                  }
                }
              }
            }
          }
        }

        if (this.businessDetailProductSearchString === '') {
          this.loadPreviousProductsData = this.businessDetailProducts;
          this.loadPreviousProductsDataCount = this.PagnationPage;
          this.loadPreviousProductsDataLastPage = this.lastPage;
        }
        this.searchItem = false;
        this.loadingData = false;
        this.iniatlizeSlider();
      },

      (error) => {
        // this.nao.go(100);
      }
    );

  }

  updateBusinessDetailProducts1() {
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }

    // this.dataService.businessDetailProducts(this.id, token).subscribe(
    this.dataService.businessDetailProducts1(this.businessId, token).subscribe(
      (data) => {
        this.businessDetailProductsUpdate = data;
        this.businessDetailProductsUpdate = this.businessDetailProductsUpdate.items;
        for (var i = 0; i < this.businessDetailProductsUpdate.length; i++) {
          for (var j = 0; j < this.businessDetailProductsUpdate[i].products.length; j++) {
            this.businessDetailProducts[i].products[j].is_wish_list = this.businessDetailProductsUpdate[i].products[j].is_wish_list;
          }
        }

        if ($('#businessAll').is(':visible')) {
          for (var i = 0; i < this.businessDetailProductsUpdate.length; i++) {
            for (var j = 0; j < this.businessDetailProductsUpdate[i].products.length; j++) {
              if (this.businessDetailProductsUpdate[i].items.products[j].id === this.productDetailData.id) {
                this.productDetailData.is_wish_list = this.businessDetailProductsUpdate[i].products[j].is_wish_list;
              }
            }
          }
        }
      },
      (error) => {
      }
    );
  }


  getBusinessSearchProducts() {
    var token = '';
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    clearTimeout(this.clearTimeout);
    setTimeout(() => {
      const cartData = JSON.parse(localStorage.getItem('cartItems'));
      // console.log('cartData', cartData);
      // this.nao.go(30);
      this.dataService.businessSearchProducts(this.id, this.searchString, this.limit, this.paginationPage, token).subscribe(
        (data) => {
          // console.log('data', data);
          this.businessDetailProducts = data;
          this.loadingData = false;
          // this.searchItem = true;
          // this.nao.go(100);
          this.detailProductSuccess = this.businessDetailProducts.success;
          this.businessDetailProducts = this.businessDetailProducts.products;
          // console.log('this.businessDetailProducts', this.businessDetailProducts);
          for (var i = 0; i < this.businessDetailProducts.length; i++) {
            for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
              for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
                for (var l = 0; l < cartData.length; l++) {
                  if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                    this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                    // console.log('in count', this.businessDetailProducts[i].products[j].count);
                  }
                }
              }
            }
          }
          // setTimeout(() => {
          this.loadingData = false;
          // },  500 );
        },
        (error) => {
          // this.nao.go(100);
        }
      );
    }, 1000);
  }

  searchVal(val) {

    this.serchstring1 = val;
    // console.log('this.serchstring1', this.serchstring1);
    if (this.serchstring1 === '') {
      this.loaderCondition = false;
      // this.loadingData = true;
      // this.getBusinessDetailProducts();
      // this.searchItem = false;
      // this.initSlider();
      // this.getBusinessDetailProducts1();

      this.clearTimeoutProductWhenEmpty = setTimeout(() => {
        this.businessDetailProducts = this.loadPreviousProductsData;
        this.PagnationPage = this.loadPreviousProductsDataCount;
        this.lastPage = this.loadPreviousProductsDataLastPage;
        this.searchItem = false;
        const cartData = JSON.parse(localStorage.getItem('cartItems'));
        if (cartData !== null) {
          for (var i = 0; i < this.businessDetailProducts.length; i++) {
            for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
              this.businessDetailProducts[i].products[j].count = 0;
              for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
                for (var l = 0; l < cartData.length; l++) {
                  if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                    this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                    // console.log('in count plus', this.businessDetailProducts[i].products[j].count);
                  }
                }
              }
            }
          }
        }
        this.initSlider();
        this.iniatlizeSlider();
      }, 1000);

    }

    else {
      this.loadingData = true;
      this.businessDetailProducts = [];
      this.searchProductsByCategoryId();
      this.searchItem = true;
    }
  }

  clearSearch() {
    // this.searchString = '';
    this.loaderCondition = false;
    this.searchString = '';
    // this.businessDetailProductSearchString = '';
    // this.loadingData = true;
    // this.getBusinessDetailProducts1();
    // // this.initSlider();
    // // this.iniatlizeSlider();
    this.businessDetailProducts = this.loadPreviousProductsData;
    this.PagnationPage = this.loadPreviousProductsDataCount;
    this.lastPage = this.loadPreviousProductsDataLastPage;
    this.initSlider();
    this.iniatlizeSlider();
    this.searchItem = false;

    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    if (cartData !== null) {
      for (var i = 0; i < this.businessDetailProducts.length; i++) {
        for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
          this.businessDetailProducts[i].products[j].count = 0;
          for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
            for (var l = 0; l < cartData.length; l++) {
              if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                // console.log('in count plus', this.businessDetailProducts[i].products[j].count);
              }
            }
          }
        }
      }
    }
  }

  removeToWishlistsearch(obj) {
    if ($('#businessAll').is(':visible')) {
      this.productDetailData.is_wish_list = false;
    }

    for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
      if (obj.id === this.searchProductByCategoryByID[i].id) {
        this.searchProductByCategoryByID[i].is_wish_list = false;
      }
    }

    for (var i = 0; i < this.loadPreviousProductsData.length; i++) {
      for (var j = 0; j < this.loadPreviousProductsData[i].products.length; j++) {
        // console.log('Hello');
        if (obj.id === this.loadPreviousProductsData[i].products[j].id) {
          this.loadPreviousProductsData[i].products[j].is_wish_list = false;
        }
      }
    }

    if ($('#SearchProductsByCategory').is(':visible')) {

      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        if (obj.id === this.businessCategoryProducts[i].id) {
          this.businessCategoryProducts[i].is_wish_list = false;
        }
      }
    }
    this.dataService.removeProductInWishList(window.localStorage.getItem('token'), obj.id).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }

      },
      (error) => {
      }
    );
  }

  addToWishlistsearch(obj) {
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined || window.localStorage.getItem('token') === null) {
      this.events.publish('openLoginFunction', 'true');
      return;
    }
    if ($('#businessAll').is(':visible')) {
      this.productDetailData.is_wish_list = true;
    }

    for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
      if (obj.id === this.searchProductByCategoryByID[i].id) {
        this.searchProductByCategoryByID[i].is_wish_list = true;
      }
    }

    for (var i = 0; i < this.loadPreviousProductsData.length; i++) {
      for (var j = 0; j < this.loadPreviousProductsData[i].products.length; j++) {
        // console.log('Hello');
        if (obj.id === this.loadPreviousProductsData[i].products[j].id) {
          this.loadPreviousProductsData[i].products[j].is_wish_list = true;
        }
      }
    }

    if ($('#SearchProductsByCategory').is(':visible')) {

      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        if (obj.id === this.businessCategoryProducts[i].id) {
          this.businessCategoryProducts[i].is_wish_list = true;
        }
      }
    }
    this.dataService.addProductInWishList(window.localStorage.getItem('token'), obj.id, this.businessId).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }

      },
      (error) => {
      }
    );
  }

  searchProductsByCategoryIdByPage() {
    this.PaginationPageForSearch++;
    this.searchProductByCategoryByIDByPage = [];
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    // this.businessCatLoding = true;;
    clearTimeout(this.clearTimeouthome);
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    this.clearTimeouthome = setTimeout(() => {
      this.dataService.searchProductsByCategory(this.businessId, this.limitForSearch, this.PaginationPageForSearch, this.serchstring1, token, -1, -1).subscribe(
        (data) => {
          this.loaderCondition = false;
          this.searchProductByCategoryByIDByPage = data;
          this.searchProductByCategoryByIDLastPage = this.searchProductByCategoryByIDByPage.last_page;
          this.searchProductByCategoryByIDByPage = this.searchProductByCategoryByIDByPage.items;
          // console.log('this.searchProductByCategoryByID ', this.searchProductByCategoryByIDByPage);
          // this.businessCatLoding = false;
          this.loadingData = false;
          if (cartData !== null) {
            for (var i = 0; i < this.searchProductByCategoryByIDByPage.length; i++) {
              this.searchProductByCategoryByIDByPage[i].count = 0;
              for (var k = 0; k < this.searchProductByCategoryByIDByPage[i].variant.length; k++) {
                for (var l = 0; l < cartData.length; l++) {
                  if (cartData[l].variant_id === this.searchProductByCategoryByIDByPage[i].variant[k].id) {
                    this.searchProductByCategoryByIDByPage[i].count += cartData[l].quantity;
                    // console.log('search in', this.searchProductByCategoryByIDByPage[i].count);
                  }
                }
              }
            }
          }

          for (var k = 0; k < this.searchProductByCategoryByIDByPage.length; k++) {
            this.searchProductByCategoryByID.push(this.searchProductByCategoryByIDByPage[k]);
          }
          this.pageCondition = true;
        },
        (error) => {
          // console.log('error', error);
        }
      );
    }, 1000);
  }

  searchProductsByCategoryId() {
    this.PaginationPageForSearch = 0;
    this.loaderCondition = false;
    this.searchProductByCategoryByID = [];
    // clearInterval(this.clearTimeout1);
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    // this.businessCatLoding = true;;
    clearTimeout(this.clearTimeouthome);
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    this.clearTimeouthome = setTimeout(() => {
      this.dataService.searchProductsByCategory(this.businessId, this.limitForSearch, this.PaginationPageForSearch, this.serchstring1, token, -1, -1).subscribe(
        (data) => {
          // this.businessCatLoding = false;
          this.searchProductByCategoryByID = data;
          this.searchProductByCategoryByIDLastPage = this.searchProductByCategoryByID.last_page;
          this.searchProductByCategoryByID = this.searchProductByCategoryByID.items;
          // this.businessCatLoding = false;
          this.loadingData = false;
          if (cartData !== null) {
            for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
              this.searchProductByCategoryByID[i].count = 0;
              for (var k = 0; k < this.searchProductByCategoryByID[i].variant.length; k++) {
                for (var l = 0; l < cartData.length; l++) {
                  if (cartData[l].variant_id === this.searchProductByCategoryByID[i].variant[k].id) {
                    this.searchProductByCategoryByID[i].count += cartData[l].quantity;
                    // console.log('search in', this.searchProductByCategoryByID[i].count);
                  }
                }
              }
            }
          }
        },
        (error) => {
          // console.log('error', error);
        }
      );
    }, 1000);
  }

  getproductDetail(id) {
    this.productId = id;
    var token;
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    this.nao.go(30);
    this.dataService.productDetail(id, token).subscribe(
      (data) => {
        this.nao.go(100);
        this.productDetailData = data;
        this.productSuccess = this.productDetailData.success;
        this.productDetailData = this.productDetailData.product;
        this.setMetaData(this.productDetailData.title, this.productDetailData.image[0], this.productDetailData.description, this.productDetailData.product_tags);
        this.productDetailDataImagesLength = this.productDetailData.image.length;
        if (this.productDetailDataImagesLength <= 1) {
          // console.log('working');
          $('.carousel-indicators').removeClass('display-block');
          $('.carousel-indicators').addClass('display-none');
        }
        if (this.productDetailDataImagesLength > 1) {
          // console.log('working');
          $('.carousel-indicators').removeClass('display-none');
          $('.carousel-indicators').addClass('display-block');
        }
        this.productDetailData.description = this.productDetailData.description.replace(/(?:\r\n|\r|\n)/g, '<br>');

        if (this.productSuccess === true) {
          this.disableBackground();
          this.addCartPopOpen(this.productDetailData, 2);
          this.openNavAll();
        }
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }

  getSeeMprePopup(cat_id, catname) {
    this.searchStringCat = '';
    this.cat_id = cat_id;
    this.cat_name = catname;
    this.disableBackground();
    $('#SeeMorePopup').modal('show');
    this.loadingDataCat = true;
    $('#subCategorySlider').css('visibility', 'hidden');
    this.subCategoryListing();
  }

  // getBusinessCategoryProducts() {
  //   clearInterval(this.clearTimeout1);
  //   // $('.sliderList').slick('unslick');
  //   const cartData = JSON.parse(localStorage.getItem('cartItems'));
  //   console.log('cartData', cartData);
  //   if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
  //     var token = '';
  //   } else {
  //     token = window.localStorage.getItem('token');
  //   }
  //   this.nao.go(30);
  //   this.dataService.businessCategoryProducts(this.id, this.cat_id, token).subscribe(
  //     (data) => {
  //       this.businessCategoryProducts = data;
  //       this.nao.go(100);
  //       this.loadingDataCat = false;
  //       this.detailProductSuccess = this.businessCategoryProducts.success;
  //       this.businessCategoryProducts = this.businessCategoryProducts.products;
  //       console.log('this.businessCategoryProducts', this.businessCategoryProducts);
  //       for(var i = 0; i < this.businessCategoryProducts.length; i++) {
  //         for (var j = 0; j < this.businessCategoryProducts[i].products.length; j++) {
  //           for (var k = 0; k < this.businessCategoryProducts[i].products[j].variant.length; k++) {
  //             for (var l = 0; l < cartData.length; l++) {
  //               if (cartData[l].variant_id === this.businessCategoryProducts[i].products[j].variant[k].id) {
  //                 this.businessCategoryProducts[i].products[j].count += cartData[l].quantity;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     },
  //     (error) => {
  //       this.nao.go(100);
  //     }
  //   );
  // }

  getBusinessCategoryProductsByPage() {
    this.PaginationPageByCategory++;
    clearInterval(this.clearTimeout1);
    this.businessCategoryProductsBypage = [];
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    // console.log('cartData', cartData);
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    // this.nao.go(30);
    // this.dataService.businessCategoryProducts(this.businessId, this.cat_id, token).subscribe(
    this.dataService.businessCategorySearchProduct(this.businessId, this.getProductsViaCategoryLimit, this.PaginationPageByCategory, this.cat_id, this.searchStringCat, this.subCatid, token, -1, -1).subscribe(
      (data) => {
        this.SeeAllLoader = false;
        this.businessCategoryProductsBypage = data;
        this.lastPageOfCategory = this.businessCategoryProductsBypage.last_page;
        // this.nao.go(100);
        this.loadingDataCat = false;
        this.detailProductSuccess = this.businessCategoryProductsBypage.success;
        this.businessCategoryProductsBypage = this.businessCategoryProductsBypage.items;
        // console.log('this.businessCategoryProducts', this.businessCategoryProducts);
        this.initSlider();
        this.iniatlizeSubCatSlider();
        if (cartData !== null) {
          for (var i = 0; i < this.businessCategoryProducts.length; i++) {
            this.businessCategoryProducts[i].count = 0;
            for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
              for (var l = 0; l < cartData.length; l++) {
                if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
                  this.businessCategoryProducts[i].count += cartData[l].quantity;
                  // console.log('search in', this.businessCategoryProducts[i].count);
                }
              }
            }
          }
        }


        for (var k = 0; k < this.businessCategoryProductsBypage.length; k++) {
          this.businessCategoryProducts.push(this.businessCategoryProductsBypage[k]);
        }
      },
      (error) => {
        this.nao.go(100);
        // console.log('error', error);
      }
    );
  }

  getBusinessCategoryProducts() {
    this.businessCategoryProductslength = false;
    this.PaginationPageByCategory = 0;
    clearInterval(this.clearTimeout1);
    this.businessCategoryProducts = [];
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    // console.log('cartData', cartData);
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    this.dataService.businessCategorySearchProduct(this.businessId, this.getProductsViaCategoryLimit, this.PaginationPageByCategory, this.cat_id, this.searchStringCat, this.subCatid, token, -1, -1).subscribe(
      (data) => {
        this.SeeAllLoader = false;
        this.subcatLoadingShimmer = false;
        this.businessCategoryProducts = data;
        this.lastPageOfCategory = this.businessCategoryProducts.last_page;
        this.loadingDataCat = false;
        this.detailProductSuccess = this.businessCategoryProducts.success;
        this.businessCategoryProducts = this.businessCategoryProducts.items;
        if (this.businessCategoryProducts.length === 0) {
          this.businessCategoryProductslength = true;
        }
        this.initSlider();
        // console.log('this.cartData', cartData);
        if (cartData !== null) {
          for (var i = 0; i < this.businessCategoryProducts.length; i++) {
            this.businessCategoryProducts[i].count = 0;
            for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
              for (var l = 0; l < cartData.length; l++) {
                if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
                  this.businessCategoryProducts[i].count += cartData[l].quantity;
                  // console.log('search in', this.businessCategoryProducts[i].count);
                }
              }
            }
          }
        }

      },
      (error) => {
        // this.nao.go(100);
      }
    );
  }

  updateBusinessCategoryProducts() {
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
      var token = '';
    } else {
      token = window.localStorage.getItem('token');
    }
    this.dataService.businessCategoryProducts(this.id, this.cat_id, token).subscribe(
      (data) => {
        this.businessCategoryProductsUpdate = data;
        this.businessCategoryProductsUpdate = this.businessCategoryProductsUpdate.products;
        for (var i = 0; i < this.businessCategoryProductsUpdate.length; i++) {
          for (var j = 0; j < this.businessCategoryProductsUpdate[i].products.length; j++) {
            this.businessCategoryProducts[i].products[j].is_wish_list = this.businessCategoryProductsUpdate[i].products[j].is_wish_list;
          }
        }
      },
      (error) => {
      }
    );
  }

  getBusinessCategorySearchProducts() {
    clearInterval(this.clearTimeout1);
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      const cartData = JSON.parse(localStorage.getItem('cartItems'));
      // console.log('cartData', cartData);
      if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined) {
        var token = '';
      } else {
        token = window.localStorage.getItem('token');
      }
      this.dataService.businessCategorySearchProducts(this.id, this.cat_id, this.searchStringCat, token).subscribe(
        (data) => {
          this.businessCategoryProducts = data;
          this.loadingDataCat = false;
          this.detailProductSuccess = this.businessCategoryProducts.success;
          this.businessCategoryProducts = this.businessCategoryProducts.products;
          for (var i = 0; i < this.businessCategoryProducts.length; i++) {
            for (var j = 0; j < this.businessCategoryProducts[i].products.length; j++) {
              for (var k = 0; k < this.businessCategoryProducts[i].products[j].variant.length; k++) {
                for (var l = 0; l < cartData.length; l++) {
                  if (cartData[l].variant_id === this.businessCategoryProducts[i].products[j].variant[k].id) {
                    this.businessCategoryProducts[i].products[j].count += cartData[l].quantity;
                    // console.log('in count', this.businessCategoryProducts[i].products[j].count);
                  }
                }
              }
            }
          }
        },
        (error) => {
        }
      );
    }, 3000);
  }


  searchValCat(val) {
    clearInterval(this.clearTimeout1);
    this.clearTimeout1 = setInterval(() => {
      // console.log('val', val);
      if (val === '') {
        this.loaderCondition = false;
        // this.loadingDataCat = true;
        this.subcatLoadingShimmer = true;
        this.getBusinessCategoryProducts();
        this.searchItemCat = false;
      } else {
        this.loaderCondition = false;
        // this.loadingDataCat = true;
        this.subcatLoadingShimmer = true;
        this.getBusinessCategoryProducts();
        this.searchItemCat = true;
      }
    }, 1000);
  }

  clearSearchCat() {
    this.searchStringCat = '';
    this.SeeAllLoader = false;
    this.subcatLoadingShimmer = true;
    this.getBusinessCategoryProducts();
    this.searchItemCat = false;
    // this.initSlider();
    // this.iniatlizeSlider();
  }

  initSlider() {
    setTimeout(() => {
      // $(document).ready(function () {
      //   $('.sliderList').slick({
      //
      //     // normal options...
      //     infinite: false,
      //     autoplay: false,
      //     slidesToShow: 4.5,
      //     arrows: true,
      //     slidesToScroll: 4,
      //     accessibility: false,
      //     setPosition: 0,
      //     centerMode: false,
      //     // ltr: true,
      //     // initialSlide: 0,
      //     dots: false,
      //     zIndex: 3,
      //     // the magic
      //     responsive: [{
      //
      //       breakpoint: 1024,
      //       settings: {
      //         slidesToShow: 3.5,
      //         slidesToScroll: 3,
      //         infinite: false
      //       }
      //     }, {
      //       breakpoint: 768,
      //       settings: {
      //         slidesToShow: 3.5,
      //         slidesToScroll: 2,
      //         dots: false
      //       }
      //     }, {
      //       breakpoint: 560,
      //       settings: {
      //         slidesToShow: 2.5,
      //         slidesToScroll: 2,
      //         dots: false
      //       }
      //     }, {
      //       breakpoint: 300,
      //       settings: 'unslick' // destroys slick
      //     }]
      //   });
      //     $('.sliderList').on('afterChange', function (event, slick, currentSlide) {
      //
      //     if(currentSlide === 4) {
      //         $('.slick-next').addClass('hidden');
      //     }
      //     else {
      //         $('.slick-next').removeClass('hidden');
      //     }
      //
      //     if(currentSlide === 0) {
      //         $('.slick-prev').addClass('hidden');
      //     }
      //     else {
      //         $('.slick-prev').removeClass('hidden');
      //     }
      // });
      // $('.slick-list').css('overflow', 'visible');
      // });
      $(document).ready(function () {
        const width = $('.businessTile').width();
        const height = width * 0.75;
        $('.sdetail').css('-webkit-box-orient', 'vertical');
        $('.slick-track').css('margin-left', 0);
        $('.slick-track').css('margin-right', 0);
        $('.businessTile').css('height', height);
      });
    }, 2000);
  }

  openNav() {
    this.initSlider();
    this.iniatlizeSlider();
    $('#businessDetail').modal({
      backdrop: 'static',
      keyboard: false,
    }, 'show');
    // setTimeout(() => {
    this.detailDataLoad = false;
    // }, 1500);
    // document.getElementById('main').style.marginRight = '250px';
  }

  openNavAll() {
    $('#businessAll').modal({}, 'show');
  }


  closeNav() {
    this.SeeAllLoader = false;
    this.loaderCondition = false;
    this.subCatid = -1;
    this.searchItemCat = false;
    this.searchStringCat = '';
    this.PaginationPageByCategory = 0;
  }

  closeNav1() {
    $('#subCategorySlider').css('visibility', 'hidden');
    this.SeeAllLoader = false;
    var dSlider = $('.subCategorySlider');
    dSlider.trigger('destroy.owl.carousel');
    this.subCatid = -1;
    this.searchItemCat = false;
    this.searchStringCat = '';
    this.PaginationPageByCategory = 0;
  }

  closeNavAll() {
    // document.getElementById('main').style.marginRight = '0';
  }

  inProgressPop() {
    $('#wipPop').modal('show');
  }

  // Cart Flow
  checkObject(array, obj) {
    var value = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].variant_id === obj.id) {
        value = true;
        break;
      }
    }
    return value;
  }

  swipe() {
    $(".carousel").on("touchstart", function (event) {
      var xClick = event.originalEvent.touches[0].pageX;
      $(this).one("touchmove", function (event) {
        var xMove = event.originalEvent.touches[0].pageX;
        if (Math.floor(xClick - xMove) > 5) {
          $(this).carousel('next');
        }
        else if (Math.floor(xClick - xMove) < -5) {
          $(this).carousel('prev');
        }
      });
      $(".carousel").on("touchend", function () {
        $(this).off("touchmove");
      });
    });
  }

  addCartPopOpen(obj, val) {
    this.swipe();
    this.cartFinal = [];
    this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
    console.log('Object', obj)
    this.cartPopData = obj;
    console.log(this.cartPopData);
    this.modifierProductList = [];
    this.variantData = this.cartPopData.variant;
    for (let i = 0; i < this.variantData.length; i++) {
      let foundModifier = false;
      if (this.cartDataFromStorage != undefined) {
        for (let l = 0; l < this.cartDataFromStorage.length; l++) {
          if (this.cartDataFromStorage[l].variant_id == this.variantData[i].id) {
            if (this.cartDataFromStorage[l].hasModifier == true && this.cartDataFromStorage[l].uniqueId != '') {
              this.modifierProductList.push(this.cartDataFromStorage[l]);
              foundModifier = true;
            }
          }
        }
      }

      if (foundModifier) {
        this.cartFinal.push({
          business_image: this.business_image,
          business_name: this.businessName,
          business_id: this.businessId,
          business_delivery_charges: this.business_delivery_charges,
          business_platform_fee: this.business_platform_fee,
          business_total_charges: this.business_total_charges,
          business_tax: this.taxOfBusiness,
          product_id: this.cartPopData.id,
          product_name: this.variantData[i].product_name,
          product_image: this.variantData[i].image,
          variant_id: this.variantData[i].id,
          variant_name: this.variantData[i].option_name,
          variant_value: this.variantData[i].option_value,
          price: Math.round(this.variantData[i].price),
          compare_price: Math.round(this.variantData[i].compare_price),
          sale_price: this.variantData[i].sale_price,
          is_on_sale: this.variantData[i].is_on_sale,
          quantity: 1,
          addCartBtnShow: true,
          outlet_address: this.outletAddress,
          hasModifier: this.variantData[i].has_modifier,
          uniqueId: '',
          modifiers: [],
          addons: [],
          noOption: [],
          card_tax_price: this.variantData[i].card_tax_price,
          cash_tax_price: this.variantData[i].cash_tax_price,
          card_tax_percentage: this.variantData[i].card_tax_percentage,
          cash_tax_percentage: this.variantData[i].cash_tax_percentage
        });
        continue
      }

      if (this.cartDataFromStorage !== null && this.checkObject(this.cartDataFromStorage, this.variantData[i]) === true) {
        for (let j = 0; j < this.cartDataFromStorage.length; j++) {
          if (this.businessId === this.cartDataFromStorage[j].business_id && this.cartPopData.id === this.cartDataFromStorage[j].product_id && this.variantData[i].id === this.cartDataFromStorage[j].variant_id) {
            this.cartFinal.push({
              business_image: this.business_image,
              business_name: this.businessName,
              business_id: this.businessId,
              business_delivery_charges: this.business_delivery_charges,
              business_platform_fee: this.business_platform_fee,
              business_total_charges: this.business_total_charges,
              business_tax: this.taxOfBusiness,
              product_id: this.cartPopData.id,
              product_name: this.variantData[i].product_name,
              product_image: this.variantData[i].image,
              variant_id: this.variantData[i].id,
              variant_name: this.variantData[i].option_name,
              variant_value: this.variantData[i].option_value,
              price: this.variantData[i].price,
              sale_price: this.variantData[i].sale_price,
              is_on_sale: this.variantData[i].is_on_sale,
              quantity: this.cartDataFromStorage[j].quantity,
              addCartBtnShow: false,
              outlet_address: this.outletAddress,
              hasModifier: this.variantData[i].has_modifier,
              uniqueId: '',
              modifiers: [],
              addons: [],
              noOption: [],
              card_tax_price: this.variantData[i].card_tax_price,
              cash_tax_price: this.variantData[i].cash_tax_price,
              card_tax_percentage: this.variantData[i].card_tax_percentage,
              cash_tax_percentage: this.variantData[i].cash_tax_percentage
            });
          }
        }
      } else {
        this.cartFinal.push({
          business_image: this.business_image,
          business_name: this.businessName,
          business_id: this.businessId,
          business_delivery_charges: this.business_delivery_charges,
          business_platform_fee: this.business_platform_fee,
          business_total_charges: this.business_total_charges,
          business_tax: this.taxOfBusiness,
          product_id: this.cartPopData.id,
          product_name: this.variantData[i].product_name,
          product_image: this.variantData[i].image,
          variant_id: this.variantData[i].id,
          variant_name: this.variantData[i].option_name,
          variant_value: this.variantData[i].option_value,
          price: this.variantData[i].price,
          sale_price: this.variantData[i].sale_price,
          is_on_sale: this.variantData[i].is_on_sale,
          quantity: 1,
          addCartBtnShow: true,
          outlet_address: this.outletAddress,
          hasModifier: this.variantData[i].has_modifier,
          uniqueId: '',
          modifiers: [],
          addons: [],
          noOption: [],
          card_tax_price: this.variantData[i].card_tax_price,
          cash_tax_price: this.variantData[i].cash_tax_price,
          card_tax_percentage: this.variantData[i].card_tax_percentage,
          cash_tax_percentage: this.variantData[i].cash_tax_percentage
        });
      }
    }
    if (val === 1) {
      $('#addCartPop').modal('show');
      // this.disableBackground();

    } else {
      // localStorage.setItem('paymentMethod', this.payment_through_card);
      // localStorage.setItem('paymentThroughCard', this.payment_through_card);
      // localStorage.setItem('paymentThroughCash', this.payment_through_cash);
      // localStorage.setItem('paymentOverDue', this.payment_over_due);
      window.localStorage.setItem('cartBusinessData', JSON.stringify(this.businessDetailData));
    }
    // if (val === 1) {
    //   window.localStorage.setItem('paymentMethod', this.payment_through_card);
    //   window.localStorage.setItem('paymentThroughCard', this.payment_through_card);
    //   window.localStorage.setItem('paymentThroughCash', this.payment_through_cash);
    //   window.localStorage.setItem('paymentOverDue', this.payment_over_due);
    //   $('#addCartPop').modal('show');
    // }
  }

  async openFinalCart() {
    // console.log('in');
    this.events.publish('openCartPopup', 'true');
  }

  addToCartOnExisting() {
    this.disableMultiClick = true;
    this.cartDataFromStorage = [];
    this.events.publish('cartUpdate', this.cartDataFromStorage);
    // window.localStorage.setItem('cartItems', '');
    localStorage.removeItem('cartItems');
    setTimeout(() => {
      this.addToCart(this.objToOveride, this.indexToOveride);
      $('#onOnleBusinessCart').modal('hide');
      this.disableMultiClick = false;
    }, 1000);
  }

  async addToCart(obj, index) {
    // window.localStorage.setItem('paymentMethod', this.payment_through_card);
    // window.localStorage.setItem('paymentThroughCard', this.payment_through_card);
    // window.localStorage.setItem('paymentThroughCash', this.payment_through_cash);
    // window.localStorage.setItem('paymentOverDue', this.payment_over_due);
    window.localStorage.setItem('cartBusinessData', JSON.stringify(this.businessDetailData));

    // window.localStorage.setItem('isDineinAvailable', this.businessDetailData.is_dine_in_available);
    // window.localStorage.setItem('isPickupAvailable', this.businessDetailData.is_pickup_available);
    // window.localStorage.setItem('deliveryPaymentOptions', JSON.stringify(this.businessDetailData.delivery_payment_options));
    // window.localStorage.setItem('dineInPaymentOptions', JSON.stringify(this.businessDetailData.dine_in_payment_options));
    // window.localStorage.setItem('pickupPaymentOptions', JSON.stringify(this.businessDetailData.pickup_payment_options));

    if (obj.hasModifier == true) {
      this.objToOveride = obj;
      this.indexToOveride = index;
      let cartDataNewChk = JSON.parse(localStorage.getItem('cartItems'));
      if (cartDataNewChk !== null) {
        if (cartDataNewChk.length > 0) {
          if (this.businessId !== cartDataNewChk[0].business_id) {
            $('#onOnleBusinessCart').modal('show');
            this.disableBackground();

            return;
          }
        }
      }
      this.selectedVariant = index;
      this.dataService.getVariantDetail(obj.variant_id).subscribe(
        res => {
          console.log(res);
          let modifierObj = {
            quantityTotal: 1,
            name: obj.product_name,
            image: obj.product_image,
            variantName: obj.variant_name,
            optionValue: obj.variant_value,
            price: Math.round(obj.price),
            compare_price: Math.round(obj.compare_price),
            is_on_sale: obj.is_on_sale,
            variant_id: obj.variant_id,
            modifiers: res['modifiers'],
            addons: res['addons'],
            noOption: res['no_options'],
            error_msg: ''
          }
          this.productModifierDataArray = new Array;
          this.productModifierDataArray.push(modifierObj);
          this.newCart = this.cartFinal;
          // this.newCart[this.selectedVariant].modifiers = [];
          this.modModifiers = [];
          for (let i = 0; i < this.productModifierDataArray[0].modifiers.length; i++) {
            // for(let j = 0; j < this.productModifierDataArray[0].modifiers[i].options.length; j++) {
            this.productModifierDataArray[0].modifiers[i].options[0].selected = true;
            let objSelected = {};
            objSelected = {
              id: this.productModifierDataArray[0].modifiers[i].id,
              title: this.productModifierDataArray[0].modifiers[i].title,
              options: this.productModifierDataArray[0].modifiers[i].options[0]
            };
            // this.newCart[this.selectedVariant].modifiers.push(objSelected);
            this.modModifiers.push(objSelected);
            // }
          }
          if (this.productModifierDataArray[0].noOption != undefined && this.productModifierDataArray[0].noOption != null && this.productModifierDataArray[0].noOption.options != null) {
            for (let i = 0; i < this.productModifierDataArray[0].noOption.options.length; i++) {
              this.productModifierDataArray[0].noOption.options[i].selected = true;
            }
          }
          this.totalPriceCalculation();
        },
        err => console.log(err)
      );
      $('#addVariantsModifiersPop').modal('show');
    } else {
      if (this.payment_over_due === true) {
        $('#onOnleOverDue').modal('show');
        return;
      }
      // if (this.businessStatus === 'Closed') {
      //   $('#weAreClosed').modal('show');
      //   return;
      // }
      this.objToOveride = obj;
      this.indexToOveride = index;
      // console.log('obj chk in add cart .....', obj);
      let cartDataNewChk = JSON.parse(localStorage.getItem('cartItems'));
      if (cartDataNewChk !== null) {
        if (cartDataNewChk.length > 0) {
          if (this.businessId !== cartDataNewChk[0].business_id) {
            $('#onOnleBusinessCart').modal('show');
            return;
          }
        }
      }

      const objToPass = obj;
      this.cartFinal[index].addCartBtnShow = false;
      // console.log('push data in storage', objToPass);
      // console.log('this.cartDataFromStorage', this.cartDataFromStorage);
      if (this.cartDataFromStorage === null || this.cartDataFromStorage === undefined) {
        this.cartDataFromStorage = [];
      }
      this.cartDataFromStorage.push(objToPass);
      this.events.publish('cartUpdate', this.cartDataFromStorage);
      const cartData = JSON.parse(localStorage.getItem('cartItems'));

      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        this.searchProductByCategoryByID[i].count = 0;
        for (var k = 0; k < this.searchProductByCategoryByID[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.searchProductByCategoryByID[i].variant[k].id) {
              this.searchProductByCategoryByID[i].count += cartData[l].quantity;
              // console.log('search in', this.searchProductByCategoryByID[i].count);
            }
          }
        }
      }

      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        this.businessCategoryProducts[i].count = 0;
        for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
              this.businessCategoryProducts[i].count += cartData[l].quantity;
              // console.log('search in', this.businessCategoryProducts[i].count);
            }
          }
        }
      }

      if ($('#scrollId').is('visible')) {
        for (var i = 0; i < this.businessDetailProducts.length; i++) {
          for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
            this.businessDetailProducts[i].products[j].count = 0;
            for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
              for (var l = 0; l < cartData.length; l++) {
                if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                  this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                  // console.log('in count plus', this.businessDetailProducts[i].products[j].count);
                }
              }
            }
          }
        }
      }
    }
  }

  async cartChngeFromCheckoutDone() {
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    this.modifierProductList = [];
    let foundModifier = false;
    if (this.variantData != undefined && this.variantData != 'null' && cartData != undefined) {
      for (let i = 0; i < cartData.length; i++) {
        for (let j = 0; j < this.variantData.length; j++) {
          if (cartData[i].variant_id == this.variantData[j].id) {
            if (cartData[i].hasModifier == true && cartData[i].uniqueId != '') {
              this.modifierProductList.push(cartData[i]);
              foundModifier = true;
            }
          }
        }
      }
    }

    if (foundModifier) {
      return;
    }

    if (cartData !== null) {
      if (this.searchItem === false && this.loadingData === false) {
        for (var i = 0; i < this.businessDetailProducts.length; i++) {
          for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
            this.businessDetailProducts[i].products[j].count = 0;
            for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
              for (var l = 0; l < cartData.length; l++) {
                if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                  this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                  // console.log('in count', this.businessDetailProducts[i].products[j].count);
                }
              }
            }
          }
        }
      }
    }

    if (cartData !== null) {
      if (this.searchItem === true && this.loadingDataCat === false) {
        for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
          this.searchProductByCategoryByID[i].count = 0;
          for (var k = 0; k < this.searchProductByCategoryByID[i].variant.length; k++) {
            for (var l = 0; l < cartData.length; l++) {
              if (cartData[l].variant_id === this.searchProductByCategoryByID[i].variant[k].id) {
                this.searchProductByCategoryByID[i].count += cartData[l].quantity;
                // console.log('search in', this.searchProductByCategoryByID[i].count);
              }
            }
          }
        }
      }
    }

    if (cartData !== null) {
      if (this.searchItemCat === false && this.loadingDataCat === false) {
        for (var i = 0; i < this.businessCategoryProducts.length; i++) {
          this.businessCategoryProducts[i].count = 0;
          for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
            for (var l = 0; l < cartData.length; l++) {
              if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
                this.businessCategoryProducts[i].count += cartData[l].quantity;
                // console.log('search in', this.businessCategoryProducts[i].count);
              }
            }
          }
        }
      }
    }

    if (cartData !== null) {
      if (this.searchItemCat === true && this.loadingDataCat === false) {
        for (var i = 0; i < this.businessCategoryProducts.length; i++) {
          this.businessCategoryProducts[i].count = 0;
          for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
            for (var l = 0; l < cartData.length; l++) {
              if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
                this.businessCategoryProducts[i].count += cartData[l].quantity;
                // console.log('search in', this.businessCategoryProducts[i].count);
              }
            }
          }
        }
      }
    }


    if ($('#businessAll').is(':visible')) {
      this.cartFinal = [];
      this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
      for (let i = 0; i < this.variantData.length; i++) {
        if (this.cartDataFromStorage !== null && this.checkObject(this.cartDataFromStorage, this.variantData[i]) === true) {
          for (let j = 0; j < this.cartDataFromStorage.length; j++) {
            if (this.businessId === this.cartDataFromStorage[j].business_id && this.cartPopData.id === this.cartDataFromStorage[j].product_id && this.variantData[i].id === this.cartDataFromStorage[j].variant_id) {
              window.localStorage.setItem('businessName', this.businessName);
              this.cartFinal.push({
                business_image: this.business_image,
                business_name: this.businessName,
                business_id: this.businessId,
                business_delivery_charges: this.business_delivery_charges,
                business_platform_fee: this.business_platform_fee,
                business_total_charges: this.business_total_charges,
                business_tax: this.taxOfBusiness,
                product_id: this.cartPopData.id,
                product_name: this.variantData[i].product_name,
                product_image: this.variantData[i].image,
                variant_id: this.variantData[i].id,
                variant_name: this.variantData[i].option_name,
                variant_value: this.variantData[i].option_value,
                price: Math.round(this.variantData[i].price),
                sale_price: this.variantData[i].sale_price,
                is_on_sale: this.variantData[i].is_on_sale,
                quantity: this.cartDataFromStorage[j].quantity,
                addCartBtnShow: false,
                outlet_address: this.outletAddress,
                hasModifier: this.variantData[i].has_modifier,
                uniqueId: '',
                modifiers: [],
                addons: [],
                noOption: [],
                card_tax_price: this.variantData[i].card_tax_price,
                cash_tax_price: this.variantData[i].cash_tax_price,
                card_tax_percentage: this.variantData[i].card_tax_percentage,
                cash_tax_percentage: this.variantData[i].cash_tax_percentage
              });
              // console.log('cart finall', this.cartFinal);
            }
          }
        } else {
          this.cartFinal.push({
            business_image: this.business_image,
            business_name: this.businessName,
            business_id: this.businessId,
            business_delivery_charges: this.business_delivery_charges,
            business_platform_fee: this.business_platform_fee,
            business_total_charges: this.business_total_charges,
            business_tax: this.taxOfBusiness,
            product_id: this.cartPopData.id,
            product_name: this.variantData[i].product_name,
            product_image: this.variantData[i].image,
            variant_id: this.variantData[i].id,
            variant_name: this.variantData[i].option_name,
            variant_value: this.variantData[i].option_value,
            price: Math.round(this.variantData[i].price),
            sale_price: this.variantData[i].sale_price,
            is_on_sale: this.variantData[i].is_on_sale,
            quantity: 1,
            addCartBtnShow: true,
            outlet_address: this.outletAddress,
            hasModifier: this.variantData[i].has_modifier,
            uniqueId: '',
            modifiers: [],
            addons: [],
            noOption: [],
            card_tax_price: this.variantData[i].card_tax_price,
            cash_tax_price: this.variantData[i].cash_tax_price,
            card_tax_percentage: this.variantData[i].card_tax_percentage,
            cash_tax_percentage: this.variantData[i].cash_tax_percentage
          });
        }
      }
    }

  }

  async quantityMinus(index, quantity, obj) {
    this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
    this.cartFinal[index].quantity = quantity - 1;
    for (let i = 0; i < this.cartDataFromStorage.length; i++) {
      // console.log('this.businessId', this.businessId, this.cartDataFromStorage[i].business_id);
      // console.log('this.cartPopData.id', this.cartPopData.id, this.cartDataFromStorage[i].product_id);
      // console.log('obj.variant_id', obj.variant_id, this.cartDataFromStorage[i].variant_id);
      if (this.businessId === this.cartDataFromStorage[i].business_id && this.cartPopData.id === this.cartDataFromStorage[i].product_id && obj.variant_id === this.cartDataFromStorage[i].variant_id) {
        if (this.cartFinal[index].quantity < 1) {
          this.cartDataFromStorage.splice(i, 1);
          this.events.publish('cartUpdate', this.cartDataFromStorage);
          // console.log('this.cartDataFromStorage', this.cartDataFromStorage);
        } else {
          this.cartDataFromStorage[i].quantity = this.cartFinal[index].quantity;
          this.events.publish('cartUpdate', this.cartDataFromStorage);
          // console.log('this.cartDataFromStorage', this.cartDataFromStorage);
        }
      }
    }
    // this.cartFinal[index].quantity = quantity - 1;
    if (this.cartFinal[index].quantity < 1) {
      this.cartFinal[index].quantity = 1;
      this.cartFinal[index].addCartBtnShow = true;
    }

    const cartData = JSON.parse(localStorage.getItem('cartItems'));

    for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
      this.searchProductByCategoryByID[i].count = 0;
      for (var k = 0; k < this.searchProductByCategoryByID[i].variant.length; k++) {
        for (var l = 0; l < cartData.length; l++) {
          if (cartData[l].variant_id === this.searchProductByCategoryByID[i].variant[k].id) {
            this.searchProductByCategoryByID[i].count += cartData[l].quantity;
            // console.log('search in', this.searchProductByCategoryByID[i].count);
          }
        }
      }
    }

    for (var i = 0; i < this.businessCategoryProducts.length; i++) {
      this.businessCategoryProducts[i].count = 0;
      for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
        for (var l = 0; l < cartData.length; l++) {
          if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
            this.businessCategoryProducts[i].count += cartData[l].quantity;
            // console.log('search in', this.businessCategoryProducts[i].count);
          }
        }
      }
    }

    if ($('#scrollId').is('visible')) {
      for (var i = 0; i < this.businessDetailProducts.length; i++) {
        for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
          this.businessDetailProducts[i].products[j].count = 0;
          for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
            for (var l = 0; l < cartData.length; l++) {
              if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                // console.log('in count plus', this.businessDetailProducts[i].products[j].count);
              }
            }
          }
        }
      }
    }

  }

  async quantityPlus(index, quantity, obj) {
    // console.log('obj chk for plus', obj);
    this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
    this.cartFinal[index].quantity = quantity + 1;
    for (let i = 0; i < this.cartDataFromStorage.length; i++) {
      // console.log('this.businessId', this.businessId, this.cartDataFromStorage[i].business_id);
      // console.log('this.cartPopData.id', this.cartPopData.id, this.cartDataFromStorage[i].product_id);
      // console.log('obj.variant_id', obj.variant_id, this.cartDataFromStorage[i].variant_id);
      if (this.businessId === this.cartDataFromStorage[i].business_id && this.cartPopData.id === this.cartDataFromStorage[i].product_id && obj.variant_id === this.cartDataFromStorage[i].variant_id) {
        this.cartDataFromStorage[i].quantity = this.cartFinal[index].quantity;
        this.events.publish('cartUpdate', this.cartDataFromStorage);
        // console.log('this.cartDataFromStorage', this.cartDataFromStorage);
      }
    }
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    // console.log('businessDetailProducts', this.businessDetailProducts);

    for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
      this.searchProductByCategoryByID[i].count = 0;
      for (var k = 0; k < this.searchProductByCategoryByID[i].variant.length; k++) {
        for (var l = 0; l < cartData.length; l++) {
          if (cartData[l].variant_id === this.searchProductByCategoryByID[i].variant[k].id) {
            this.searchProductByCategoryByID[i].count += cartData[l].quantity;
            // console.log('search in', this.searchProductByCategoryByID[i].count);
          }
        }
      }
    }

    for (var i = 0; i < this.businessCategoryProducts.length; i++) {
      this.businessCategoryProducts[i].count = 0;
      for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
        for (var l = 0; l < cartData.length; l++) {
          if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
            this.businessCategoryProducts[i].count += cartData[l].quantity;
            // console.log('search in', this.businessCategoryProducts[i].count);
          }
        }
      }
    }

    if ($('#scrollId').is('visible')) {
      for (var i = 0; i < this.businessDetailProducts.length; i++) {
        for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
          this.businessDetailProducts[i].products[j].count = 0;
          for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
            for (var l = 0; l < cartData.length; l++) {
              if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
                this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
                // console.log('in count plus', this.businessDetailProducts[i].products[j].count);
              }
            }
          }
        }
      }
    }


    // this.events.publish('cartUpdate', this.cartFinal);
    // console.log('this.cartFinal[index].quantity', this.cartFinal[index].quantity);
  }

  getStoryListing() {
    this.dataService.getStoryByBusiness(this.storylimit, this.id).subscribe(
      (data) => {
        this.storyData = data;
        this.storyData = this.storyData.items;
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }

  removeToWishlistByPopUp(obj) {
    if ($('#businessAll').is(':visible')) {
      this.productDetailData.is_wish_list = false;
    }
    for (var i = 0; i < this.businessDetailProducts.length; i++) {
      for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
        if (obj.id === this.businessDetailProducts[i].products[j].id) {
          this.businessDetailProducts[i].products[j].is_wish_list = false;
        }
      }
    }
    for (var i = 0; i < this.businessCategoryProducts.length; i++) {
      if (obj.id === this.businessCategoryProducts[i].id) {
        this.businessCategoryProducts[i].is_wish_list = false;
      }
    }

    this.dataService.removeProductInWishList(window.localStorage.getItem('token'), obj.id).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }

      },
      (error) => {
      }
    );
    // this.updateBusinessDetailProducts1();
  }

  addToWishlistByPopUp(obj) {
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined || window.localStorage.getItem('token') === null) {
      this.events.publish('openLoginFunction', 'true');
      return;
    }
    if ($('#businessAll').is(':visible')) {
      this.productDetailData.is_wish_list = true;
    }
    for (var i = 0; i < this.businessDetailProducts.length; i++) {
      for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
        if (obj.id === this.businessDetailProducts[i].products[j].id) {
          this.businessDetailProducts[i].products[j].is_wish_list = true;
        }
      }
    }

    for (var i = 0; i < this.businessCategoryProducts.length; i++) {
      if (obj.id === this.businessCategoryProducts[i].id) {
        this.businessCategoryProducts[i].is_wish_list = true;
      }
    }

    this.dataService.addProductInWishList(window.localStorage.getItem('token'), obj.id, this.businessId).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }

      },
      (error) => {
      }
    );
  }

  addToWishlist(obj) {
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined || window.localStorage.getItem('token') === null) {
      this.addBlurbusinessAll();
      this.events.publish('openLoginFunction', 'true');
      return;
    }
    this.clearBlurbusinessAll();
    // if ( $('#businessAll').is(':visible') )  {
    //   this.productDetailData.is_wish_list = true;
    // }
    //
    // for ( var i = 0; i < this.businessDetailProducts.length; i ++ ) {
    //   for( var j = 0; j < this.businessDetailProducts[i].products.length; j++ ) {
    //     if ( obj.id === this.businessDetailProducts[i].products[j].id ) {
    //       this.businessDetailProducts[i].products[j].is_wish_list = true;
    //     }
    //   }
    // }

    if ($('#businessAll').is(':visible')) {
      this.productDetailData.is_wish_list = true;
    }


    for (var i = 0; i < this.businessDetailProducts.length; i++) {
      for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
        if (obj.id === this.businessDetailProducts[i].products[j].id) {
          this.businessDetailProducts[i].products[j].is_wish_list = true;
        }
      }
    }

    if (this.searchItem === true && this.loadingData === false) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        if (obj.id === this.searchProductByCategoryByID[i].id) {
          this.searchProductByCategoryByID[i].is_wish_list = true;
        }
      }
    }

    if ($('#SearchProductsByCategory').is(':visible')) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        if (obj.id === this.businessCategoryProducts[i].id) {
          this.businessCategoryProducts[i].is_wish_list = true;
        }
      }
    }


    this.dataService.addProductInWishList(window.localStorage.getItem('token'), obj.id, this.businessId).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }

      },
      (error) => {
      }
    );
    // this.updateBusinessDetailProducts1();
  }

  removeToWishlistCatsearch(obj) {
    for (var i = 0; i < this.businessCategoryProducts.length; i++) {
      if (obj.id === this.businessCategoryProducts[i].id) {
        this.businessCategoryProducts[i].is_wish_list = false;
      }

    }
    this.dataService.removeProductInWishList(window.localStorage.getItem('token'), obj.id).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }
        this.updateBusinessDetailProducts1();

      },
      (error) => {
      }
    );
  }

  addToWishlistCatsearch(obj) {
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined || window.localStorage.getItem('token') === null) {
      this.addBlurSeeMorePopup();
      this.events.publish('openLoginFunction', 'true');
      return;
    }
    this.clearBlurSeeMorePopup();
    for (var i = 0; i < this.businessCategoryProducts.length; i++) {
      if (obj.id === this.businessCategoryProducts[i].id) {
        this.businessCategoryProducts[i].is_wish_list = true;
      }
    }
    this.dataService.addProductInWishList(window.localStorage.getItem('token'), obj.id, this.businessId).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }
        this.updateBusinessDetailProducts1();

      },
      (error) => {
      }
    );
  }

  addToWishlistCat(obj) {
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined || window.localStorage.getItem('token') === null) {
      this.events.publish('openLoginFunction', 'true');
      return;
    }
    for (var i = 0; i < this.businessCategoryProducts.length; i++) {
      for (var j = 0; j < this.businessCategoryProducts[i].products.length; j++) {
        if (obj.id === this.businessCategoryProducts[i].products[j].id) {
          this.businessCategoryProducts[i].products[j].is_wish_list = true;
        }
      }
    }
    this.dataService.addProductInWishList(window.localStorage.getItem('token'), obj.id, this.businessId).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }
        this.updateBusinessDetailProducts1();

      },
      (error) => {
      }
    );
  }

  removeToWishlist(obj) {
    if ($('#businessAll').is(':visible')) {
      this.productDetailData.is_wish_list = false;
    }

    for (var i = 0; i < this.businessDetailProducts.length; i++) {
      for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
        if (obj.id === this.businessDetailProducts[i].products[j].id) {
          this.businessDetailProducts[i].products[j].is_wish_list = false;
        }
      }
    }

    if (this.searchItem === true && this.loadingData === false) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        if (obj.id === this.searchProductByCategoryByID[i].id) {
          this.searchProductByCategoryByID[i].is_wish_list = false;
        }
      }
    }

    if ($('#SearchProductsByCategory').is(':visible')) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        if (obj.id === this.businessCategoryProducts[i].id) {
          this.businessCategoryProducts[i].is_wish_list = false;
        }
      }
    }


    this.dataService.removeProductInWishList(window.localStorage.getItem('token'), obj.id).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }

      },
      (error) => {
      }
    );
    // this.updateBusinessDetailProducts1();
  }

  removeToWishlistCat(obj) {
    for (var i = 0; i < this.businessCategoryProducts.length; i++) {
      for (var j = 0; j < this.businessCategoryProducts[i].products.length; j++) {
        if (obj.id === this.businessCategoryProducts[i].products[j].id) {
          this.businessCategoryProducts[i].products[j].is_wish_list = false;
        }
      }
    }
    this.dataService.removeProductInWishList(window.localStorage.getItem('token'), obj.id).subscribe(
      (data) => {
        this.addWishlistSuccess = data;
        this.addWishlistSuccess = this.addWishlistSuccess.success;
        if (this.addWishlistSuccess === true) {
        } else {
          toastr.error('Somethimg went wrong');
        }
        this.updateBusinessDetailProducts1();

      },
      (error) => {
      }
    );
  }

  addCartfromshortPop(obj, val) {
    console.log(obj);
    // console.log('working');
    this.addCartPopOpen(obj, val);
    $('.addCartShort').css('-webkit-filter', 'blur(3px)');
    $('.addCartShort').css('-moz-filter', 'blur(3px)');
    $('.addCartShort').css('-o-filter', 'blur(3px)');
    $('.addCartShort').css('-ms-filter', 'blur(3px)');
    $('.addCartShort').css('filter', 'blur(3px)');
  }

  //  getWindowWidth() {
  //   var windowWidth =  $(window).width();
  //   // console.log('get width in app main', windowWidth);
  //   return windowWidth;
  // }

  loadMoreFunction() {
    this.getBusinessDetailProducts1ByPage();
  }

  loadMoreFunctionByCategory() {
    this.getBusinessCategoryProductsByPage();
  }

  FunToAddId(val) {
    this.initSlider();
    this.iniatlizeSlider();
    if (val === 'products') {
      this.conditionToCheck = true;
    }
    if (val === 'about') {
      this.conditionToCheck = false;
    }
    if (val === 'categories') {
      this.conditionToCheck = false;
    }
  }

  loadMoreFunctionByCategoryBySearch() {
    this.searchProductsByCategoryIdByPage();
  }

  // @HostListener('window:scroll', ['$event'])
  // scrollHandler(event) {
  //   if(this.searchItem === false && this.loadingData === false) {
  //     if (this.lastPage === false) {
  //       this.loaderCondition = true;
  //       console.log('$(window).scrollTop()', $(window).scrollTop());
  //       console.log('$(document).height() - $(window).height()', $(document).height() - $(window).height() - 313);
  //       if($(window).scrollTop() >= $(document).height() - $(window).height() - 313) {
  //         //   313
  //         // ajax call get data from server and append to the div
  //         this.pageCondition = false;
  //         console.log('fisdhisdhisdhfihsdfgisdigsdhdjkshfkjdhfkjdhfkjdhfksdhfisdhfids');
  //         this.lastPage = true;
  //         this.loadMoreFunction();
  //       }
  //     }
  //   }
  // }

  // help(event){
  //   if(event.which === 13){
  //       console.log('event.charCodeAt(1)');
  //   }
  // }

  // customiseOrderGet(){
  //     this.dataService.customOrder(localStorage.getItem('token'), this.id).subscribe(
  //     data => {
  //       this.orderCustomizeDetail = data;
  //       console.log(this.orderCustomizeDetail);

  //     },
  //     err => {
  //       console.log(err);
  //     }
  //   );
  // }

  // customiseOrder(){
  //   console.log('text us component ts')
  //   $('#customiseOrder').modal('show');

  // }

  openCustomOrderOpoup() {
    this.customOrderText = '<ul><li></li></ul>';
    this.addCustomOrderBtn = false;
    $('#customOrderPopup').modal('show');
    $('.dz-default').css('display', 'block');
    setTimeout(() => {
      $('.dz-preview').remove();
    }, 500);
    const Dropzone = require('dropzone');
    const context = this;
    try {
      new Dropzone('#my-awesome-dropzone', {
        maxFiles: 10, addRemoveLinks: true, dictDefaultMessage: 'Upload Images', autoProcessQueue: false, paramName: 'image',
        acceptedMimeTypes: 'image/jpeg, image/jpg, image/png', progress: false, init: function () {
          context.dropzone = this;

          this.on('addedfile', function (file) {
            // console.log('file', file);
            if (!file.type.match(/image.*/)) {
              // This is not an image, so Dropzone doesn't create a thumbnail.
              // Set a default thumbnail:
              // this.emit('thumbnail', file, '../app/assets/images/interface.png');

              // You could of course generate another image yourself here,
              // and set it as a data url.
            }
            context.customProductImages.push(file);
          });

          this.on('error', function (file, response) {
            // $(file.previewElement).find('.dz-error-message').text(response.file_name);
            // context.error_count++;
            // console.log(response);
          });

          this.on('queuecomplete', function () {
            // $('#myloader').removeClass('cssload-container');
            // $('#loader_container').removeClass('floatingBarsG');
            // $('.blockG').addClass('myopc');


            // if(context.error_count>0){
            //     toastr.error('Error uploading images.');
            // }
            // else{
            //      toastr.success('Property added successfully.');
            //      context.navigate();
            // }

          });

          this.on('removedfile', function (file) {

            // console.log('remove file', file);


            for (var i = 0; i < context.customProductImages.length; i++) {
              if (context.customProductImages[i].name === file.name) {
                context.customProductImages.splice(i, 1);
              }
            }

            // console.log('after remove file', context.customProductImages);

          });

          this.on('processing', function () {
            this.options.autoProcessQueue = false;
            // console.log('after processing file', context.customProductImages);
          });

          context.dropzone = this;
        }
      });
    }
    catch (err) {
      // console.log('error');
    }
  }

  addCustomOrder() {
    if ((this.customOrderText === '' || this.customOrderText === '<ul><li></li></ul>') && this.customProductImages.length === 0) {
      toastr.error('Please add text or upload image');
      return;
    }
    if (window.localStorage.getItem('token') === '' || window.localStorage.getItem('token') === undefined || window.localStorage.getItem('token') === null) {
      this.addBlur();
      this.events.publish('openLoginFunction', 'true');
      return;
    }
    this.clearBlur();
    this.customProductImagesCount = 0;
    this.customOrderImageSuccessUrl = new Array();

    this.addCustomOrderBtn = true;
    if (this.customProductImages.length > 0) {
      var file = this.customProductImages[0];
      this.uploadCustomeOrderImage(file);
    } else {
      this.uploadCustomeOrderText();
    }
  }

  uploadCustomeOrderImage(file) {
    this.dataService.uploadOrderImage(window.localStorage.getItem('token'), this.businessId, file, this.userCurrentAddress).subscribe(
      (data) => {
        this.customOrderImageSuccess = data;
        if (this.customOrderImageSuccess.success = true) {
          this.customOrderImageSuccessUrl.push(this.customOrderImageSuccess.url);
          this.customProductImagesCount++;
          if (this.customProductImages.length > this.customProductImagesCount) {
            var file = this.customProductImages[this.customProductImagesCount];
            this.uploadCustomeOrderImage(file);
          } else {
            this.uploadCustomeOrderText();
          }
        } else {
          this.addCustomOrderBtn = false;
          toastr.error('Something went wrong');
        }
      },
      (error) => {
      }
    );
  }

  convertHTMLtoText(html) {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  uploadCustomeOrderText() {
    var d = this.convertHTMLtoText(this.customOrderText);
    this.dataService.uploadCustomeOrderText(window.localStorage.getItem('token'), this.businessId, d, this.customOrderImageSuccessUrl, this.userCurrentAddress).subscribe(
      (data) => {
        this.customOrderSuccess = data;
        if (this.customOrderSuccess.success === true) {
          toastr.success('Your request has been successfuly sent. Our team will contact you soon.');
          $('#customOrderPopup').modal('hide');
          this.addCustomOrderBtn = false;
          this.customOrderImageSuccessUrl = new Array();
          this.customProductImages = new Array();
        }
      },
      (error) => {
      }
    );
  }

  CategoryListing() {
    // console.log('this.businessId', this.businessId);
    this.dataService.getCategoryList(window.localStorage.getItem('token'), this.businessId).subscribe(
      (data) => {
        this.catList = data;
        // console.log('getCategoryList', this.catList);
      },
      (error) => {
      }
    );
  }

  subCategoryListing() {
    this.subCatData = [];
    this.dataService.getSubCategoryList(window.localStorage.getItem('token'), this.businessId, this.cat_id).subscribe(
      (data) => {
        this.subCatData = data;
        this.iniatlizeSubCatSlider();
        this.subCatLength = this.subCatData.length;
        setTimeout(() => {
          $('#subCategorySlider').css('visibility', 'visible');
          this.loadingDataCat = false;
          this.subcatLoadingShimmer = true;
          this.getBusinessCategoryProducts();
        }, 1000);
      },
      (error) => {
      }
    );
  }
  catidSend(id) {
    this.subCatid = id;
    this.getBusinessCategoryProducts();
  }

  iniatlizeSubCatSlider() {
    // this.subCatslider = false;
    var context = this;


    setTimeout(() => {
      $(function () {
        context.owl2 = $('.subCategorySlider');
        context.owl2.trigger('initialized');
        context.owl2.owlCarousel({
          loop: false,
          mouseDrag: false,
          touchDrag: false,
          pullDrag: false,
          dots: false,
          navSpeed: 700, // filter: invert(var(--invertVar)) sepia(var(--sepiaVar)) saturate(var(--saturateVar)) hue-rotate(var(--hue_rotateVar)) brightness(var(--brightnessVar)) contrast(var(--constrastVar))!important;"
          // filter: invert(var(--invertVar)) sepia(var(--sepiaVar)) saturate(var(--saturateVar)) hue-rotate(var(--hue_rotateVar)) brightness(var(--brightnessVar)) contrast(var(--constrastVar))!important;"
          // filter: invert(var(--invertVar)) sepia(var(--sepiaVar)) saturate(var(--saturateVar)) hue-rotate(var(--hue_rotateVar)) brightness(var(--brightnessVar)) contrast(var(--constrastVar))!important;"
          navText: ['<img src="../../assets/arrowLeftWhite.png" style="width: 35px;">', '<img src="../../assets/arrowRightWhite.png" style="width: 35px; ">'],
          responsive: {
            0: {
              items: 3
            },
            420: {
              items: 4
            },
            560: {
              items: 4
            },
            720: {
              items: 5
            },
            950: {
              items: 5
            },
          },
          nav: true,
        });
        $('.subCategorySlider .owl-prev').addClass('visibility-hidden');
        context.owl2.on('changed.owl.carousel', function (data) {
          var position = data.page.size + data.property.value;
          if (data.item.count - position === 0) {
            $('.subCategorySlider .owl-next').addClass('disabled');
            $('.subCategorySlider .owl-next').addClass('visibility-hidden');
          } else {
            $('.subCategorySlider .owl-next').removeClass('disabled');
            $('.subCategorySlider .owl-next').removeClass('visibility-hidden');
          }

          if (data.item.index > 0) {
            $('.subCategorySlider .owl-prev').removeClass('visibility-hidden');
          }
          if (data.item.index === 0) {
            $('.subCategorySlider .owl-prev').addClass('visibility-hidden');
          }
        });
      });
    }, 500);
    // setTimeout(() => {
    //   this.subCatslider = false;
    // }, 500);
  }

  activeClassFunc(index) {
    $('.animateRemoveClass').removeClass('animateImg');
    this.subcatLoadingShimmer = true;
    this.SeeAllLoader = false;
    // this.loadingDataCat = true;
    setTimeout(() => {
      $('.subCatTile').removeClass('subCatTextdis');
      $('.subCatTile').addClass('none');
      $('.subCatImg').removeClass('subCatimgMinOpacity');
      $('.subCatImg').addClass('subCatimgMaxOpacity');

      // $('.textOpc').removeClass('subCatimgMinOpacity');
      // $('.textOpc').addClass('subCatimgMaxOpacity');

      $('#cross' + index).removeClass('none');
      $('#cross' + index).addClass('subCatTextdis');
      $('#subCatImg' + index).addClass('subCatimgMinOpacity');
      $('#subCatText' + index).addClass('subCatimgMinOpacity');
    }, 100);
  }

  activeCrossFunc(index) {
    $('.animateRemoveClass').addClass('animateImg');
    this.subcatLoadingShimmer = true;
    this.SeeAllLoader = false;
    // this.loadingDataCat = true;
    this.subCatid = -1;
    this.getBusinessCategoryProducts();
    // $('.textOpc').removeClass('subCatimgMaxOpacity');
    // $('.textOpc').addClass('subCatimgMinOpacity');
    $('#cross' + index).removeClass('subCatTextdis');
    $('#cross' + index).addClass('none');
    $('.opac').removeClass('subCatimgMaxOpacity');
    $('.opac').addClass('subCatimgMinOpacity');
  }

  clearBlur() {
    $('#customOrderPopup').css('-webkit-filter', 'blur(0px)');
    $('#customOrderPopup').css('-moz-filter', 'blur(0px)');
    $('#customOrderPopup').css('-o-filter', 'blur(0px)');
    $('#customOrderPopup').css('-ms-filter', 'blur(0px)');
    $('#customOrderPopup').css('filter', 'blur(0px)');
  }
  addBlur() {
    $('#customOrderPopup').css('-webkit-filter', 'blur(3px)');
    $('#customOrderPopup').css('-moz-filter', 'blur(3px)');
    $('#customOrderPopup').css('-o-filter', 'blur(3px)');
    $('#customOrderPopup').css('-ms-filter', 'blur(3px)');
    $('#customOrderPopup').css('filter', 'blur(3px)');
  }

  clearBlurSeeMorePopup() {
    $('#SeeMorePopup').css('-webkit-filter', 'blur(0px)');
    $('#SeeMorePopup').css('-moz-filter', 'blur(0px)');
    $('#SeeMorePopup').css('-o-filter', 'blur(0px)');
    $('#SeeMorePopup').css('-ms-filter', 'blur(0px)');
    $('#SeeMorePopup').css('filter', 'blur(0px)');
  }
  addBlurSeeMorePopup() {
    $('#SeeMorePopup').css('-webkit-filter', 'blur(3px)');
    $('#SeeMorePopup').css('-moz-filter', 'blur(3px)');
    $('#SeeMorePopup').css('-o-filter', 'blur(3px)');
    $('#SeeMorePopup').css('-ms-filter', 'blur(3px)');
    $('#SeeMorePopup').css('filter', 'blur(3px)');
  }

  clearBlurbusinessAll() {
    $('#businessAll').css('-webkit-filter', 'blur(0px)');
    $('#businessAll').css('-moz-filter', 'blur(0px)');
    $('#businessAll').css('-o-filter', 'blur(0px)');
    $('#businessAll').css('-ms-filter', 'blur(0px)');
    $('#businessAll').css('filter', 'blur(0px)');
  }
  addBlurbusinessAll() {
    $('#businessAll').css('-webkit-filter', 'blur(3px)');
    $('#businessAll').css('-moz-filter', 'blur(3px)');
    $('#businessAll').css('-o-filter', 'blur(3px)');
    $('#businessAll').css('-ms-filter', 'blur(3px)');
    $('#businessAll').css('filter', 'blur(3px)');
  }

  iniatlizeSlider() {
    // this.showBusinessProductsliderLoading = true;
    setTimeout(() => {
      $('#catID').css('visibility', 'visible');
      var owl = $('.owlProductsSlider');
      owl.owlCarousel({
        loop: false,
        dots: false,
        navSpeed: 700,
        // filter: invert(var(--invertVar)) sepia(var(--sepiaVar)) saturate(var(--saturateVar)) hue-rotate(var(--hue_rotateVar)) brightness(var(--brightnessVar)) contrast(var(--constrastVar))!important;"
        // filter: invert(var(--invertVar)) sepia(var(--sepiaVar)) saturate(var(--saturateVar)) hue-rotate(var(--hue_rotateVar)) brightness(var(--brightnessVar)) contrast(var(--constrastVar))!important;"
        navText: ['<img src="../../assets/arrowLeftWhite.png" style="width: 50px; margin-left: 25px;">', '<img src="../../assets/arrowRightWhite.png" style="width: 50px; margin-right: 25px;">'],
        responsive: {
          0: {
            items: 3,
            stagePadding: 20
          },
          330: {
            items: 3,
            stagePadding: 30
          },
          560: {
            items: 3,
            stagePadding: 40
          },
          768: {
            items: 5,
            stagePadding: 50
          },
          950: {
            items: 5,
            stagePadding: 70
          },
          1250: {
            items: 5,
            stagePadding: 70
          },
          1440: {
            items: 7,
            stagePadding: 80
          }
        },
        nav: true
      });
      owl.on('changed.owl.carousel', function (data) {
        var position = data.page.size + data.property.value;
        // console.log(position, data);
        if (data.item.count - position === 0 || data.item.count - position === 0.5) {
          $('#' + data.currentTarget.id + ' .owl-next').addClass('disabled');
          $('#' + data.currentTarget.id + ' .owl-next').addClass('visibility-hidden');
        } else {
          $('#' + data.currentTarget.id + ' .owl-next').removeClass('disabled');
          $('#' + data.currentTarget.id + ' .owl-next').removeClass('visibility-hidden');
        }

        if (data.item.index > 0) {
          $('#' + data.currentTarget.id + ' .owl-prev').removeClass('visibility-hidden');
        }
        if (data.item.index === 0) {
          $('#' + data.currentTarget.id + ' .owl-prev').addClass('visibility-hidden');
        }


      });
    }, 500);
    setTimeout(() => {
      // this.showBusinessProductsliderLoading = false;
      $('.owl-nav').css('visibility', 'hidden');
      // $('.owl-prev').addClass('visibility-hidden');
    }, 500);
  }
  mouseEnterOnSlider(index) {

    $('#carouselSlider' + index + ' .owl-nav').css('visibility', 'visible');
  }
  mouseLeaveOnSlider(index) {
    $('#carouselSlider' + index + ' .owl-nav').css('visibility', 'hidden');
  }

  addFadeInAnimation(animation, targetClass) {
    if (this.widthOfWindow > 420) {
      if ($('#' + targetClass).hasClass(animation) === false) {
        $('#' + targetClass).addClass(animation);
      }
      setTimeout(() => {
        $('#' + targetClass).removeClass(animation);
      }, 1000);
    }

  }

  disableBackground() {
    $('html').css('overflow-y', 'hidden');
  }

  enableBackground() {
    $('html').css('overflow-y', 'auto');
  }

  setMetaData(name, image, about, searchwords) {
    // console.log('name, image, about', name, image, about);
    this.appleicon = 'assets/apple-icon-114x114.png';

    this.meta.updateTag({ property: 'og:title', content: name + ' | order online from ' + this.id });
    this.meta.updateTag({ property: 'og:image', content: image });
    //ßthis.meta.updateTag({ property: 'og:url', content: image });
    this.meta.updateTag({ property: 'description', content: about });
    this.meta.updateTag({ property: 'og:description', content: about });
    this.meta.updateTag({ property: 'og:site_name', content: this.id });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'keywords', content: searchwords + ' best buy mall bestbuymall best buymall bestbuy mall shopping online purchase grocery items food medicine delivery order online free home delivery quick track system United Kingdom' });
    this.title.setTitle(name + ' | order online from ' + this.id);



    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ name: 'twitter:site', content: this.id });
    this.meta.updateTag({ name: 'twitter:title', content: name + ' | order online from ' + this.id });
    this.meta.updateTag({ name: 'twitter:description', content: about });
    this.meta.updateTag({ name: 'twitter:image', content: image });


    // this.metaService.setTitle(name + ' | order online from bestbuymall');
    // this.metaService.setTag('og:title', name + ' | order online from bestbuymall');
    // this.metaService.setTag('description', about);
    // this.metaService.setTag('og:description', about);
    // this.metaService.setTag('og:site_name', 'bestbuymall');
    // this.metaService.setTag('og:type', 'product');
    // this.metaService.setTag('og:image', image);
    // this.metaService.setTag('og:url', image);
    //
    // this.metaService.setTag('twitter:title', name + ' | order online from bestbuymall');
    // this.metaService.setTag('twitter:description', about);
    // this.metaService.setTag('twitter:image', image);
    // this.metaService.setTag('twitter:card', 'summary_large_image');

  }
  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
        if ((params['qrcode'] != undefined || params['qrcode'] != null)) {
          this.qrCode = params['qrcode'];
        }
      }
    );
    // this.customiseOrderGet();
    this.userCurrentAddress = JSON.parse(window.localStorage.getItem('profileAddressCheckout'));
    this.events.publish('businessLogoUpdate', '');
    $('.star-icon:before').remove();
    $('head').append('<style>.star-icon:before {color:#d77229 !important;}</style>');
    this.websiteUrl = window.location.origin;
    // if ( this.idForNavigate === '' ) {
    //   var pathname = window.location.pathname;
    //   console.log('pathname', pathname);
    //     var pVal = location.href;
    //     pVal  = pVal.split('?product=')[1];
    //     console.log('pValpValpValpValpValpValpValpVal', pVal);
    //     if ( pVal !== undefined && pVal !== null  ) {
    //       pVal  = pVal.split('&')[0];
    //       console.log('pVal1', pVal);
    //       if ( pVal !== undefined && pVal !== null || pVal !== '' ){
    //         this.getproductDetail(pVal);
    //       }
    //     }
    // }
    this.dataControlService.getAppBtnOnApp.subscribe(data => {
      if (data == false) {

        if (this.widthOfWindow >= 768) {
          var myFooter = document.getElementById("businessWebFooter");
          myFooter.scrollIntoView();

        }
        else {
          var myMobileFooter = document.getElementById("businessMobileFooter");
          myMobileFooter.scrollIntoView();
        }
      }
    });

    this.dataControlService.setHeaderAddressVisiblity.next(false);


    var contax = this;
    $(window).scroll(function () {
      if (contax.searchItem === false && contax.loadingData === false) {
        if (contax.lastPage === false) {
          contax.loaderCondition = true;
          if ($(window).scrollTop() >= $(document).height() - $(window).height() - 313) {
            contax.pageCondition = false;
            contax.lastPage = true;
            contax.loadMoreFunction();
          }
        }
      }

      if (contax.searchItem === true && contax.loadingData === false) {
        if (contax.searchProductByCategoryByIDLastPage === false) {
          contax.loaderCondition = true;
          if ($(window).scrollTop() >= $(document).height() - $(window).height() - 313) {
            contax.pageCondition = false;
            contax.searchProductByCategoryByIDLastPage = true;
            contax.loadMoreFunctionByCategoryBySearch();
          }
        }
      }

    });
    this.widthOfWindow = $(window).width();


    $('#SearchProductsByCategory').on('scroll', function () {

      if (contax.lastPageOfCategory === false) {
        contax.SeeAllLoader = true;
        var scrollHeight = $('#SearchProductsByCategory').height();
        contax.scrollPosition = $('#SearchProductsByCategory').height() + $('#SearchProductsByCategory').scrollTop();
        // console.log((this.scrollHeight - contax.scrollPosition) / this.scrollHeight);
        // if ((this.scrollHeight - contax.scrollPosition) / this.scrollHeight === 0) {
        //         //   contax.lastPageOfCategory = true;
        //         //   contax.loadMoreFunctionByCategory();
        //         // }
        if (($(this).scrollTop() + $(this).innerHeight()) >= ($(this)[0].scrollHeight - 20)) {
          // console.log('in fun');
          contax.lastPageOfCategory = true;
          setTimeout(() => {
            contax.loadMoreFunctionByCategory();
          }, 100)
        }
      }
    });

    $('.subCategorySlider').on('scroll', function () {
      var context = this;
      context.owl2.trigger('initialized');
    });


    this.dataService.userFrom = 'store';
    this.widthOfWindow = $(window).width();
    $('.addCartShort').css('-webkit-filter', 'blur(0px)');
    $('.addCartShort').css('-moz-filter', 'blur(0px)');
    $('.addCartShort').css('-o-filter', 'blur(0px)');
    $('.addCartShort').css('-ms-filter', 'blur(0px)');
    $('.addCartShort').css('filter', 'blur(0px)');

    $('#addCartPop').on('hidden.bs.modal', function () {
      $('.addCartShort').css('-webkit-filter', 'blur(0px)');
      $('.addCartShort').css('-moz-filter', 'blur(0px)');
      $('.addCartShort').css('-o-filter', 'blur(0px)');
      $('.addCartShort').css('-ms-filter', 'blur(0px)');
      $('.addCartShort').css('filter', 'blur(0px)');
    });
    $('#weAreClosed').on('hidden.bs.modal', function () {
      $('.addCartShort').css('-webkit-filter', 'blur(0px)');
      $('.addCartShort').css('-moz-filter', 'blur(0px)');
      $('.addCartShort').css('-o-filter', 'blur(0px)');
      $('.addCartShort').css('-ms-filter', 'blur(0px)');
      $('.addCartShort').css('filter', 'blur(0px)');
    });
    $('#onOnleBusinessCart').on('hidden.bs.modal', function () {
      $('.addCartShort').css('-webkit-filter', 'blur(0px)');
      $('.addCartShort').css('-moz-filter', 'blur(0px)');
      $('.addCartShort').css('-o-filter', 'blur(0px)');
      $('.addCartShort').css('-ms-filter', 'blur(0px)');
      $('.addCartShort').css('filter', 'blur(0px)');
    });
    $('#onOnleBusinessCart').on('show.bs.modal', function () {
      $('.addCartShort').css('-webkit-filter', 'blur(5px)');
      $('.addCartShort').css('-moz-filter', 'blur(5px)');
      $('.addCartShort').css('-o-filter', 'blur(5px)');
      $('.addCartShort').css('-ms-filter', 'blur(5px)');
      $('.addCartShort').css('filter', 'blur(5px)');
    });
    // $('#SeeMorePopup').on('shown.bs.modal', function (e) {
    //   $('body.modal-open').css('position', 'fixed');
    // });
    // $('#SeeMorePopup').on('hidden.bs.modal', function (e) {
    //   $('body.modal-open').css('position', 'absolute');
    // });

    $(window).resize(function () {
      setTimeout(() => {
        // $(document).ready(function () {
        //   $('.sliderList').slick({
        //
        //     // normal options...
        //     infinite: false,
        //     autoplay: false,
        //     slidesToShow: 4.5,
        //     arrows: true,
        //     slidesToScroll: 4,
        //     accessibility: false,
        //     setPosition: 0,
        //     centerMode: false,
        //     // ltr: true,
        //     // initialSlide: 0,
        //     dots: false,
        //     zIndex: 3,
        //     // the magic
        //     responsive: [{
        //
        //       breakpoint: 1024,
        //       settings: {
        //         slidesToShow: 3.5,
        //         slidesToScroll: 3,
        //         infinite: false
        //       }
        //     }, {
        //       breakpoint: 768,
        //       settings: {
        //         slidesToShow: 3.5,
        //         slidesToScroll: 3,
        //         dots: false
        //       }
        //     }, {
        //       breakpoint: 560,
        //       settings: {
        //         slidesToShow: 2.5,
        //         slidesToScroll: 2,
        //         dots: false
        //       }
        //     }, {
        //       breakpoint: 300,
        //       settings: 'unslick' // destroys slick
        //     }]
        //   });
        //   $('.slick-list').css('overflow', 'visible');
        // });

        $(document).ready(function () {
          const width = $('.businessTile').width();
          const height = width * 0.75;
          $('.sdetail').css('-webkit-box-orient', 'vertical');
          $('.slick-track').css('margin-left', 0);
          $('.slick-track').css('margin-right', 0);
          $('.businessTile').css('height', height);
        });
      }, 500);
    });
    this.iniatlizeSlider();
    this.getStoryListing();
    this.getBusinessDetail();
    this.getBusinessDetailProducts1();
    window.scrollTo(0, 0);
    $('.storyBackBtn').css('display', 'none');
    $('.ds-logo').css('margin-left', '0px');
    // $('.navbar').removeClass('sticky-header');
    // $('.navbar').css('background-color', '#fff');
    // $('.navbar').css('position', 'relative');
    $('.logInbtn').css('color', '#FFFFFF');
    // $('.navbar').css('border-bottom', '1px solid #ebebeb');
    // $('#whiteLogo').css('display', 'none');
    // $('#blackLogo').css('display', 'inline-block');
    $('.cartHeadBtn').css('background', this.colorCode);
    $('.cartHeadBtn').css('color', '#ffffff');
    $('.topName').css('color', this.colorCode);
    $('.username').css('color', '#000000');
    $('.ds-logo').css('display', 'inline-block');
    $('.footer-main').css('display', 'none');
    $('.business-logo').css('display', 'inline');
    var width = $(window).width();
    // console.log('widthwidthwidth', width);
    this.businessCoverHeight = 5 / 12 * width;
    this.businessCoverHeight = this.businessCoverHeight + 'px';
    $('#businessCoverMain').css('display', 'block');
    $('#businessCoverMain1').css('display', 'none');
    $('.businessCoverHeight').css('height', this.businessCoverHeight);

    // $(window).resize(function () {

    //   $('.storyBackBtn').css('display', 'none');
    //   $('.navbar').css('background-color', '#fff');
    //   $('.logInbtn').css('color', '#FFFFFF');
    //   $('.navbar').css('border-bottom', '1px solid #ebebeb');
    //   $('#whiteLogo').css('display', 'none');
    //   $('#blackLogo').css('display', 'inline-block');
    //   $('.cartHeadBtn').css('background', this.colorCode);
    //   $('.cartHeadBtn').css('color', '#ffffff');
    //   $('.topName').css('color', this.colorCode);
    //   $('.username').css('color', '#000000');
    //   $('.ds-logo').css('display', 'inline-block');
    //   $('.footer-main').css('display', 'none');
    //   $('.business-logo').css('display', 'inline');
    // });

    // window.addEventListener('resize', () => {
    //   // this.iniatlizeSlider();
    //   this.widthOfWindow = $(window).width();
    //   // console.log('inresize', this.widthOfWindow);
    //   this.events.publish('businessLandingAlisa', this.businessDetailData);
    //   $('.storyBackBtn').css('display', 'none');
    //   $('.ds-logo').css('margin-left', '0px');
    //   // $('.navbar').removeClass('sticky-header');
    //   $('.navbar').css('background-color', '#fff');
    //   // $('.navbar').css('position', 'relative');
    //   $('.logInbtn').css('color', '#FFFFFF');
    //   $('.navbar').css('border-bottom', '1px solid #ebebeb');
    //   $('#whiteLogo').css('display', 'none');
    //   $('#blackLogo').css('display', 'inline-block');
    //   $('.cartHeadBtn').css('background', this.colorCode);
    //   $('.cartHeadBtn').css('color', '#ffffff');
    //   $('.topName').css('color', this.colorCode);
    //   $('.username').css('color', '#000000');
    //   $('.ds-logo').css('display', 'inline-block');
    //   $('.footer-main').css('display', 'none');
    //   $('.business-logo').css('display', 'inline');
    //   $('.business-logo').css('width', '100px');

    //   $('.business-logo').trigger('click');

    //   var contax = this;
    //   contax.changeRef.detectChanges();
    //   contax.changeRef.detectChanges();
    // });

  }

  ngOnDestroy() {
    this.dataControlService.getAppBtnOnApp.next(true);
    this.dataControlService.setHeaderAddressVisiblity.next(true);
    $('.footer-main').css('display', 'block');
    // $('.business-logo').css('display', 'none');

  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // this.iniatlizeSlider();
    this.widthOfWindow = $(window).width();
    // console.log('inresize', this.widthOfWindow);
    this.events.publish('businessLandingAlisa', this.businessDetailData);
    $('.storyBackBtn').css('display', 'none');
    $('.ds-logo').css('margin-left', '0px');
    // $('.navbar').removeClass('sticky-header');
    $('.navbar').css('background-color', '#fff');
    // $('.navbar').css('position', 'relative');
    $('.logInbtn').css('color', '#FFFFFF');
    $('.navbar').css('border-bottom', '1px solid #ebebeb');
    $('#whiteLogo').css('display', 'none');
    $('#blackLogo').css('display', 'inline-block');
    $('.cartHeadBtn').css('background', this.colorCode);
    $('.cartHeadBtn').css('color', '#ffffff');
    $('.topName').css('color', this.colorCode);
    $('.username').css('color', '#000000');
    $('.ds-logo').css('display', 'inline-block');
    $('.footer-main').css('display', 'none');
    $('.business-logo').css('display', 'inline');
    $('.business-logo').css('width', '100px');
    $('.business-logo').trigger('click');

    var contax = this;
    contax.changeRef.detectChanges();
    contax.changeRef.detectChanges();

  }

  // parentCatFunction(id) {
  //   this.router.navigate([this.id, id]);
  // }

  // subCatFunction(parentId, subcatId) {
  //   this.router.navigate([this.id, parentId, subcatId]);
  // }

  // childCatFunction(parentId, subcatId, childcatId) {
  //   this.router.navigate([this.id, parentId, subcatId, childcatId]);
  // }

  parentCatFunction(id, name) {
    window.scrollTo(0, 0);
    this.parentCatName = name;
    this.subCatName = '';
    this.childCatName = '';
    localStorage.setItem('parentCatName', this.parentCatName);
    localStorage.setItem('subCatName', this.subCatName);
    localStorage.setItem('childCatName', this.childCatName);
    this.router.navigate([this.id, id]);
    this.businessCategoryProducts = [];
    this.getBusinessDetail();
  }

  subCatFunction(parentId, subcatId, parentName, name) {
    window.scrollTo(0, 0);
    this.parentCatName = parentName;
    this.subCatName = name;
    this.childCatName = '';
    localStorage.setItem('parentCatName', this.parentCatName);
    localStorage.setItem('subCatName', this.subCatName);
    localStorage.setItem('childCatName', this.childCatName);
    this.router.navigate([this.id, parentId, subcatId]);
    this.businessCategoryProducts = [];
    this.getBusinessDetail();
  }

  childCatFunction(parentId, subcatId, childcatId, parentName, subCatName, name) {
    window.scrollTo(0, 0);
    this.parentCatName = parentName;
    this.subCatName = subCatName;
    this.childCatName = name;
    localStorage.setItem('parentCatName', this.parentCatName);
    localStorage.setItem('subCatName', this.subCatName);
    localStorage.setItem('childCatName', this.childCatName);
    this.router.navigate([this.id, parentId, subcatId, childcatId]);
    this.businessCategoryProducts = [];
    this.getBusinessDetail();
  }

  closeModal() {
    this.productData = new Array;
    this.addItValue = 0;
  }

  modModifiers = [];
  modAddons = [];
  modNoOptions = [];
  totalPriceCalculation() {
    let total = this.productModifierDataArray[0].price;
    let addOnPrice = 0;
    this.modifiersArr = [];
    // this.newCart[this.selectedVariant].modifiers = [];
    this.addonsArr = [];
    // this.newCart[this.selectedVariant].addons = [];
    this.noOptionsArr = [];
    this.modAddons = [];
    this.modNoOptions = [];
    // this.newCart[this.selectedVariant].noOption = [];
    // this.varImage = this.productModifierDataArray[0].image;
    // this.varName = this.productModifierDataArray[0].variantNme;
    this.productModifierDataArray[0].modifiers.forEach(element => {
      for (let i = 0; i < element.options.length; i++) {
        if (element.options[i].selected && element.options[i].selected === true) {
          total += element.options[i].price;
          addOnPrice += element.options[i].price;
          this.modifiersArr.push({
            title: element.title,
            options: element.options[i],
            id: element.id
          });
        }
      }
    });

    if (this.productModifierDataArray[0].addons != undefined && this.productModifierDataArray[0].addons != null && this.productModifierDataArray[0].addons.options != null) {
      this.productModifierDataArray[0].addons.options.forEach(element => {
        if (element.selected && element.selected === true) {
          this.addonsArr.push(
            element
          );
          // this.newCart[this.selectedVariant].addons.push(element);
          this.modAddons.push(element);
          total += element.price;
          addOnPrice += element.price;
        }
      });
    }

    if (this.productModifierDataArray[0].noOption != undefined && this.productModifierDataArray[0].noOption != null && this.productModifierDataArray[0].noOption.options != null) {
      this.productModifierDataArray[0].noOption.options.forEach(element => {
        if (element.selected && element.selected === true) {
          total += element.price;
        }
        if (element.selected === false) {
          this.noOptionsArr.push(
            element
          );
          // this.newCart[this.selectedVariant].noOption.push(element);
          this.modNoOptions.push(element);
        }
      });
    }
    // this.newCart[this.selectedVariant].modifierPrice = total;
    // this.newCart[this.selectedVariant].additionalPrice = addOnPrice;
    this.addItValue = total;
    this.additonalValue = addOnPrice;
  }

  plusModifierValue(groupId, optionId) {
    // this.newCart = this.cartFinal;
    this.modifiersArr = [];
    // this.newCart[this.selectedVariant].modifiers = [];
    this.modModifiers = [];
    for (let i = 0; i < this.productModifierDataArray[0].modifiers.length; i++) {
      if (this.productModifierDataArray[0].modifiers[i].id == groupId) {
        for (let j = 0; j < this.productModifierDataArray[0].modifiers[i].options.length; j++) {
          if (this.productModifierDataArray[0].modifiers[i].options[j].id == optionId) {
            this.productModifierDataArray[0].modifiers[i].options[j].selected = true;
            if (this.productModifierDataArray[0].modifiers[i].options[j].selected == true) {
              let obj = {};
              obj = {
                id: this.productModifierDataArray[0].modifiers[i].id,
                title: this.productModifierDataArray[0].modifiers[i].title,
                options: this.productModifierDataArray[0].modifiers[i].options[j]
              };
              // this.newCart[this.selectedVariant].modifiers.push(obj);
              this.modModifiers.push(obj);
            }
          } else {
            this.productModifierDataArray[0].modifiers[i].options[j].selected = false;
          }
        }
      }
    }
    this.totalPriceCalculation();
  }

  plusAddonsValue(optionId, event) {
    this.addonsArr = [];
    this.modAddons = [];
    // this.newCart[this.selectedVariant].addons = [];
    for (let i = 0; i < this.productModifierDataArray[0].addons.options.length; i++) {
      if (optionId === this.productModifierDataArray[0].addons.options[i].id) {
        if (event.target.checked === true) {
          this.productModifierDataArray[0].addons.options[i].selected = true;
        } else if (event.target.checked === false) {
          this.productModifierDataArray[0].addons.options[i].selected = false;

        }
      }
    }
    this.totalPriceCalculation();
  }

  subNoOptionValue(optionId, event) {
    this.noOptionsArr = [];
    this.modNoOptions = [];
    // this.newCart[this.selectedVariant].noOption = [];
    for (let i = 0; i < this.productModifierDataArray[0].noOption.options.length; i++) {
      if (optionId === this.productModifierDataArray[0].noOption.options[i].id) {
        if (event.target.checked === false) {
          this.productModifierDataArray[0].noOption.options[i].selected = false;
        } else if (event.target.checked === true) {
          this.productModifierDataArray[0].noOption.options[i].selected = true;
        }
      }
    }
    this.totalPriceCalculation();
  }

  addItFunction(price, id) {
    this.newCart[this.selectedVariant].modifiers = this.modModifiers;
    this.newCart[this.selectedVariant].addons = this.modAddons;
    this.newCart[this.selectedVariant].noOption = this.modNoOptions;
    this.newCart[this.selectedVariant].modifierPrice = this.addItValue;
    this.newCart[this.selectedVariant].additionalPrice = this.additonalValue;
    this.uniqueId = '';
    this.uniqueId = this.newCart[this.selectedVariant].variant_id;
    console.log(this.newCart[this.selectedVariant]);
    for (let i = 0; i < this.newCart[this.selectedVariant].modifiers.length; i++) {
      this.uniqueId = this.uniqueId + '_' + this.newCart[this.selectedVariant].modifiers[i].options.id;
    }
    this.newCart[this.selectedVariant].addons.forEach(element => {
      this.uniqueId = this.uniqueId + '_' + element.id;
    });
    this.newCart[this.selectedVariant].noOption.forEach(element => {
      this.uniqueId = this.uniqueId + '_' + element.id;
    });
    if (this.oldUniqueId == '') {
      let result = this.existingModifierCheck(this.newCart[this.selectedVariant].variant_id, this.uniqueId);
      if (result == false) {
        this.newCart[this.selectedVariant].uniqueId = this.uniqueId;
        this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
        if (this.cartDataFromStorage == null) {
          this.cartDataFromStorage = [];
        }
        this.cartDataFromStorage.push(this.newCart[this.selectedVariant]);
        this.events.publish('cartUpdate', this.cartDataFromStorage);
      }
    } else {
      if (this.uniqueId == this.oldUniqueId) {
        console.log('here');
        this.oldUniqueId = '';
      } else {
        this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
        this.newCart[this.selectedVariant].uniqueId = this.uniqueId;
        var foundAtIndex = null;
        var matchedWithIndex = null;

        for (let i = 0; i < this.cartDataFromStorage.length; i++) {
          if (this.cartDataFromStorage[i].uniqueId != '' && (this.cartDataFromStorage[i].uniqueId == this.oldUniqueId)) {
            foundAtIndex = i;
            console.log(this.cartDataFromStorage);
          }
          if (this.newCart[this.selectedVariant].uniqueId == this.cartDataFromStorage[i].uniqueId) {
            matchedWithIndex = i;
          }
        }
        if (foundAtIndex != null && matchedWithIndex != null) {
          this.cartDataFromStorage[matchedWithIndex].quantity += 1;
          // this.cartDataFromStorage[matchedWithIndex].price += this.newCart[this.selectedVariant].price;
          this.cartDataFromStorage.splice(foundAtIndex, 1);
        } else if (foundAtIndex != null) {
          this.newCart[this.selectedVariant].uniqueId = this.uniqueId;
          this.cartDataFromStorage[foundAtIndex] = this.newCart[this.selectedVariant];
        }
        this.oldUniqueId = '';
        foundAtIndex = null;
        matchedWithIndex = null;
        this.events.publish('cartUpdate', this.cartDataFromStorage);
      }
    }

    $('#addVariantsModifiersPop').modal('hide');
    const cartData = JSON.parse(localStorage.getItem('cartItems'));
    if (this.searchProductByCategoryByID != undefined) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        this.searchProductByCategoryByID[i].count = 0;
        for (var k = 0; k < this.searchProductByCategoryByID[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.searchProductByCategoryByID[i].variant[k].id) {
              this.searchProductByCategoryByID[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }
    if (this.businessCategoryProducts != undefined) {

      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        this.businessCategoryProducts[i].count = 0;
        for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
              this.businessCategoryProducts[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }
    // if ($('#scrollId').is('visible')) {
    for (var i = 0; i < this.businessDetailProducts.length; i++) {
      for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
        this.businessDetailProducts[i].products[j].count = 0;
        for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
              this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
            }
          }
        }
      }
    }
    // }
  }
  existingModifierCheck(variantId, uniqueId) {
    const cartItem = JSON.parse(localStorage.getItem('cartItems'));
    let check = false;
    if (cartItem != undefined) {
      for (let i = 0; i < cartItem.length; i++) {
        if (cartItem[i].variant_id == variantId && cartItem[i].uniqueId == uniqueId) {
          cartItem[i].quantity = cartItem[i].quantity + 1;
          check = true;
        }
      }
    }
    this.events.publish('cartUpdate', cartItem);
    return check;
  }
  updateModifierPorduct(orderObj, variantId, index) {
    this.selectedVariant = index;
    if (orderObj.uniqueId != '') {
      this.oldUniqueId = orderObj.uniqueId;
    }
    this.dataService.getVariantDetail(orderObj.variant_id).subscribe(
      res => {
        // console.log(res);
        let modifierObj = {
          quantityTotal: 1,
          name: orderObj.product_name,
          image: orderObj.product_image,
          variantName: orderObj.variant_name,
          optionValue: orderObj.variant_value,
          price: Math.round(orderObj.price),
          compare_price: Math.round(orderObj.compare_price),
          is_on_sale: orderObj.is_on_sale,
          variant_id: orderObj.variant_id,
          modifiers: res['modifiers'],
          addons: res['addons'],
          noOption: res['no_options'],
          error_msg: ''
        }
        this.productModifierDataArray = new Array;
        this.productModifierDataArray.push(modifierObj);
        this.newCart = this.modifierProductList;
        for (let i = 0; i < this.productModifierDataArray[0].modifiers.length; i++) {
          for (let j = 0; j < this.productModifierDataArray[0].modifiers[i].options.length; j++) {
            orderObj['modifiers'].forEach(orderElement => {
              if (this.productModifierDataArray[0].modifiers[i].options[j].id == orderElement.options.id) {
                this.productModifierDataArray[0].modifiers[i].options[j].selected = true;
              }
            });
          }
        }

        if (orderObj['addons'].length > 0 && orderObj['addons'] != undefined && orderObj['addons'] != null) {
          for (let i = 0; i < this.productModifierDataArray[0].addons.options.length; i++) {
            orderObj['addons'].forEach(orderElement => {
              if (this.productModifierDataArray[0].addons.options[i].id == orderElement.id) {
                this.productModifierDataArray[0].addons.options[i].selected = true;
              }
            });
          }
        }

        if (this.productModifierDataArray[0].noOption != null && this.productModifierDataArray[0].noOption != undefined && this.productModifierDataArray[0].noOption.options != undefined && this.productModifierDataArray[0].noOption.options.length > 0) {
          for (let i = 0; i < this.productModifierDataArray[0].noOption.options.length; i++) {
            this.productModifierDataArray[0].noOption.options[i].selected = true;
          }
        }

        if (orderObj['noOption'].length > 0 && orderObj['noOption'] != undefined && orderObj['noOption'] != null) {
          for (let j = 0; j < orderObj['noOption'].length; j++) {
            for (let i = 0; i < this.productModifierDataArray[0].noOption.options.length; i++) {
              if (this.productModifierDataArray[0].noOption.options[i].id == orderObj['noOption'][j].id) {
                this.productModifierDataArray[0].noOption.options[i].selected = false;
                break;
              }
            }
          }
        }
        console.log(this.productModifierDataArray);
        console.log(this.modifierProductList);
        // debugger
        this.totalPriceCalculation();
        $('#addVariantsModifiersPop').modal('show');
        $('#addVariantsModifiersPop').css('overflow-y', 'auto');
      },
      err => {
        console.log(err);
      }
    );
  }
  async modifierQuantityPlus(index, quantity, obj) {
    this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
    // this.cartFinal[index].quantity = quantity + 1;
    this.modifierProductList[index].quantity = quantity + 1;
    for (let i = 0; i < this.cartDataFromStorage.length; i++) {
      if (this.businessId === this.cartDataFromStorage[i].business_id && this.cartPopData.id === this.cartDataFromStorage[i].product_id && obj.variant_id === this.cartDataFromStorage[i].variant_id && obj.uniqueId == this.cartDataFromStorage[i].uniqueId) {
        this.cartDataFromStorage[i].quantity = this.modifierProductList[index].quantity;
        this.events.publish('cartUpdate', this.cartDataFromStorage);
      }
    }
    const cartData = JSON.parse(localStorage.getItem('cartItems'));

    if (this.searchProductByCategoryByID) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        this.searchProductByCategoryByID[i].count = 0;
        for (var k = 0; k < this.searchProductByCategoryByID[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.searchProductByCategoryByID[i].variant[k].id) {
              this.searchProductByCategoryByID[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    if (this.businessCategoryProducts) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        this.businessCategoryProducts[i].count = 0;
        for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
              this.businessCategoryProducts[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    // if ($('#scrollId').is('visible')) {
    for (var i = 0; i < this.businessDetailProducts.length; i++) {
      for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
        this.businessDetailProducts[i].products[j].count = 0;
        for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
              this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
            }
          }
        }
      }
    }
    // }
  }
  async modifierQuantityMinus(index, quantity, obj) {
    this.cartDataFromStorage = JSON.parse(localStorage.getItem('cartItems'));
    // this.cartFinal[index].quantity = quantity - 1;
    this.modifierProductList[index].quantity = quantity - 1;
    for (let i = 0; i < this.cartDataFromStorage.length; i++) {
      if (this.businessId === this.cartDataFromStorage[i].business_id && this.cartPopData.id === this.cartDataFromStorage[i].product_id && obj.variant_id === this.cartDataFromStorage[i].variant_id && obj.uniqueId === this.cartDataFromStorage[i].uniqueId) {
        if (this.modifierProductList[index].quantity < 1) {
          this.cartDataFromStorage.splice(i, 1);
        } else {
          this.cartDataFromStorage[i].quantity = this.modifierProductList[index].quantity;
          // this.events.publish('cartUpdate', this.cartDataFromStorage);
        }
        this.events.publish('cartUpdate', this.cartDataFromStorage);
      }
    }
    if (this.modifierProductList[index].quantity < 1) {
      this.modifierProductList[index].quantity = 1;
      this.modifierProductList[index].addCartBtnShow = true;
    }

    const cartData = JSON.parse(localStorage.getItem('cartItems'));

    if (this.searchProductByCategoryByID) {
      for (var i = 0; i < this.searchProductByCategoryByID.length; i++) {
        this.searchProductByCategoryByID[i].count = 0;
        for (var k = 0; k < this.searchProductByCategoryByID[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.searchProductByCategoryByID[i].variant[k].id) {
              this.searchProductByCategoryByID[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    if (this.businessCategoryProducts) {
      for (var i = 0; i < this.businessCategoryProducts.length; i++) {
        this.businessCategoryProducts[i].count = 0;
        for (var k = 0; k < this.businessCategoryProducts[i].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.businessCategoryProducts[i].variant[k].id) {
              this.businessCategoryProducts[i].count += cartData[l].quantity;
            }
          }
        }
      }
    }

    // if ($('#scrollId').is('visible')) {
    for (var i = 0; i < this.businessDetailProducts.length; i++) {
      for (var j = 0; j < this.businessDetailProducts[i].products.length; j++) {
        this.businessDetailProducts[i].products[j].count = 0;
        for (var k = 0; k < this.businessDetailProducts[i].products[j].variant.length; k++) {
          for (var l = 0; l < cartData.length; l++) {
            if (cartData[l].variant_id === this.businessDetailProducts[i].products[j].variant[k].id) {
              this.businessDetailProducts[i].products[j].count += cartData[l].quantity;
            }
          }
        }
      }
    }
    // }
  }

  textUsOrderFunction() {
    setTimeout(() => {
      if (this.hasCustomOrder == false) {
        this.events.publish('textUsYourOrder', true);
      } else {
        this.events.publish('customOrder', true);
      }
    }, 500)
    $('#cartAvailablePopUp').modal('hide');
  }
}

