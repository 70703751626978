// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   apiAddress: 'https://rb3phgwqhl.execute-api.ap-southeast-1.amazonaws.com/bbm-dev/client_web',
//   apiClient: 'https://rb3phgwqhl.execute-api.ap-southeast-1.amazonaws.com/bbm-dev/client',
//   apiOnline: 'https://rb3phgwqhl.execute-api.ap-southeast-1.amazonaws.com/bbm-dev/online',
//   businessUrl: 'https://business.dev.bestbuymall.co',
//   partnerUrl: 'https://partner.dev.bestbuymall.co',
//   websiteUrl: 'https://dev.bestbuymall.co',
//   paymentUrl: 'https://rb3phgwqhl.execute-api.ap-southeast-1.amazonaws.com/bbm-dev',
//   colorCode: '',
//   assetsPath: '',

// };
export const environment = {
  production: false,
  apiAddress: 'https://api-uk.dev.bestbuymall.co/client_web',
  apiClient: 'https://api-uk.dev.bestbuymall.co/client',
  apiOnline: 'https://api-uk.dev.bestbuymall.co/online',
  businessUrl: 'https://business.dev.bestbuymall.co',
  partnerUrl: 'https://partner.dev.bestbuymall.co',
  websiteUrl: 'https://dev.bestbuymall.co',
  paymentUrl: 'https://api-uk.dev.bestbuymall.co',
  colorCode: '',
  assetsPath: '',

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
