import { Component, OnInit } from '@angular/core';
import { DataControlService } from '../data-control.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(private dataControlService: DataControlService) { }

  ngOnInit() {
    //this.dataControlService.setHeaderAddressVisiblity.next(true);
    window.scrollTo(0, 0);

  }

}
