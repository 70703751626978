import { Injectable } from '@angular/core';
import { fb_events_consts } from './fbq-constants'

declare var fbq: any;

@Injectable({
  providedIn: 'root'
})
export class FbEventsService {

  constructor() { }

  // Facebook Find Location Event Call 
  findLocation() {
    fbq('track', fb_events_consts.FIND_LOCATION);
  }

  // Facebook View Content Business Event Call 
  fbViewContentBusiness(business) {
    console.log('business', business.alias);
    fbq('trackCustom', `${fb_events_consts.VIEW_CONTENT}${business.alias}`);
  }

  // Facebook Search Event Call 
  fbSearch() {
    fbq('track', fb_events_consts.SEARCH);
  }

  // Facebook + Button Automatically Detect Event Call 
  buttonAutomaticallyDetect() {
    fbq('track', fb_events_consts.FB_BUTTON_CLICK);
  }

  // Facebook Unique view content product Event Call 
  fbViewContentProduct(productName) {
    fbq('trackCustom', `${fb_events_consts.UNIQUE_VIEW_CONTENT}${productName}`);
  }

  // Facebook Add to Wish List Event Call 
  fbAddToWishList(productName, businessName) {
    fbq('trackCustom', `${fb_events_consts.ADD_TO_WISHLIST}${businessName}_${productName}`);
  }

  // Facebook Add to Cart Event Call 
  fbAddToCart(businessName) {
    fbq('trackCustom', `${fb_events_consts.ADD_TO_CART}${businessName}`);
  }

  // Facebook Unique Add to Cart Event Call 
  fbUniqueAddToCart(businessName) {
    fbq('trackCustom', `${fb_events_consts.UNIQUE_ADD_TO_CART}${businessName}`);
  }

  // Facebook Checkout 1 Event Call 
  fbCheckout1(businessName) {
    fbq('trackCustom', `Fb_${businessName}${fb_events_consts.CHECKOUT_1}`);
  }

  // Facebook Checkout 2 Event Call 
  fbCheckout2(businessName) {
    fbq('trackCustom', `Fb_${businessName}${fb_events_consts.CHECKOUT_2}`);
  }

  // Facebook Oder Placed Event Call 
  fbOrderPlaced(businessName) {
    fbq('trackCustom', `Fb__${businessName}${fb_events_consts.PURCHASE}`);
  }

  // Facebook Reg Login Event Call 
  fbRegLogin() {
    fbq('trackCustom', fb_events_consts.REGISTER_LOGIN);
  }

  // Facebook Reg Completes Event Call
  fbRegistration() {
    fbq('track', fb_events_consts.REGISTERATION)
  }

  // Facebook Download IOS App Event Call
  fbDownloadIOSApp() {
    fbq('track', fb_events_consts.DOWNLOAD_IOS_APP)
  }

  // Facebook Download Android App Event Call
  fbDownloadAndroidApp() {
    fbq('track', fb_events_consts.DOWNLOAD_ANDROID_APP)
  }

}
