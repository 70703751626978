import { Component, OnInit } from '@angular/core';
import { EventsService } from 'angular4-events';
import { DataService } from '../data.service';
import { DataControlService } from '../data-control.service';
import { Constants } from '../constants'


// declare var firebase: any;
declare var Nanobar: any;
// declare var context: any;
// declare var toastr: any;
// declare var jQuery: any;
// declare var google: any;
declare var $: any;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  carBtnShowHide = false;
  cartCount = 0;
  getCartItems: any;
  colorCodeVar: string;
  isBusinessLandingPage: Boolean;
  nao = new Nanobar();
  cartPush: any;
  constructor( private dataService: DataService, private events: EventsService, private dataControlService: DataControlService,) {
    this.colorCodeVar = Constants.colorCode;

    this.getCartItems = JSON.parse(localStorage.getItem('cartItems'));
    
    this.colorCodeVar = window.localStorage.getItem('colorChangeCode');
    if (this.colorCodeVar === 'undefined' || this.colorCodeVar === null) {
      this.colorCodeVar = Constants.colorCode;
    }
            
    document.documentElement.style.setProperty('--colorCodeVar', this.colorCodeVar);
            
    if (this.getCartItems === null) {
      this.carBtnShowHide = false;
      this.cartCount = 0;
    } else {
      if (this.getCartItems.length > 0) {
        this.carBtnShowHide = true;
        this.cartCount = this.getCartItems.length;
      }
    }
    // debugger;
    this.dataControlService.inBusinessesPage.subscribe(data => {
      if (data == true) {
        this.isBusinessLandingPage = true;
      }
      else {
        this.isBusinessLandingPage = false;
      }
    });
    this.cartPush = this.events.subscribe('cartUpdate').subscribe((data) => {
      window.localStorage.setItem('cartItems', JSON.stringify(data));
      setTimeout(() => {
        this.getCartItems = JSON.parse(localStorage.getItem('cartItems'));
        if (this.getCartItems !== null) {
          this.carBtnShowHide = true;
          this.cartCount = this.getCartItems.length;
        } else {
          this.carBtnShowHide = false;
          //this.changeRef.detectChanges();
        }
        if ( this.getCartItems === null ) {
          this.carBtnShowHide = false;
          //this.changeRef.detectChanges();
        }
        if(this.getCartItems !== null){
          if ( this.getCartItems.length === 0 ) {
            this.carBtnShowHide = false;
            //this.changeRef.detectChanges();
          }
        }
      }, 500);
    });
    
  }


  

  ngOnInit() {
    localStorage.setItem('isFirstTimeHomeOpen', "false");
    // //this.dataControlService.setHeaderAddressVisiblity.next(true);

  }
  ngOnDestroy () {
    // this.events.publish('dataChange', '');
  }

}

