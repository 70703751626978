import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {DataService} from '../data.service';

import {Router, ActivatedRoute} from '@angular/router';
import { AsyncLocalStorage } from 'angular-async-local-storage';
import {EventsService} from 'angular4-events';
import {MetaService} from 'ng2-meta';
import {Constants} from '../constants';
import { DataControlService } from '../data-control.service';

declare var $: any;
declare var Nanobar: any;
declare var toastr: any;

@Component({
  selector: 'app-faqs-business',
  templateUrl: './faqs-business.component.html',
  styleUrls: ['./faqs-business.component.css']
})
export class FaqsBusinessComponent implements OnInit {
  nao = new Nanobar();
  id: any;
  private sub: any;
  businessDetailData: any;
  businessDetalSuccess: any;
  privacyPolicy: any;
  loadingData = true;
  todayData1 = new Date();
  todayData = this.todayData1.getDay();
  faqsDataList: any;
  widthOfWindow: any;
  fromDomain = false;
  colorCode: string;

  constructor(private dataControlService: DataControlService, private router: Router, private dataService: DataService, private localStorage: AsyncLocalStorage,
              private events: EventsService, private ref: ChangeDetectorRef, private route: ActivatedRoute, private metaService: MetaService) {
    this.sub = this.route.params.subscribe(params => {
      this.colorCode = Constants.colorCode;
      this.id = params['id'];
    });


    if ( window.location.hostname.includes('businessweb.bestbuymall.co') || window.location.hostname.includes(Constants.domainNmae) ) {
      this.fromDomain = true;
    } else if (window.location.hostname.includes('bestbuymall.co') || window.location.hostname.includes('bestbuymall.co') || window.location.hostname.includes('dev.bestbuymall.co') || window.location.hostname.includes('dev.bestbuymall.co') || window.location.hostname.includes('localhost') || window.location.hostname.includes('bbm.com.pk') ){
      this.fromDomain = false;
    } else {
      this.fromDomain = true;
    }

    this.dataService.businessIdForIndependentBusinessData = this.id;
    this.dataService.userFrom = 'store';

    if (this.id === 'x---bbm-route-config-1@#$#^@$!---YY234215--X--saffg-12454jh-4b5jsj') {
      this.id = window.location.hostname;
    }
  }

  getBusinessDetail() {
    // this.nao.go(30);
    this.dataService.businessDetail(this.id, this.todayData).subscribe(
      (data) => {
        this.businessDetailData = data;
        if ( this.businessDetailData.rest_error_code === 4 || this.businessDetailData.rest_error_code === 1 ) {
          this.router.navigate(['notfound']);
        }

        this.businessDetalSuccess = this.businessDetailData.success;
        this.businessDetailData = this.businessDetailData.business_detail;

        this.metaService.setTitle(this.businessDetailData.name + ' | Best Buy Mall');
        this.metaService.setTag('og:image', this.businessDetailData.cover_image);
        this.metaService.setTag('description', this.businessDetailData.about);
        this.metaService.setTag('og:description', this.businessDetailData.about);
        this.metaService.setTag('og:type', 'website');

        this.metaService.setTag('twitter:title', this.businessDetailData.name + ' | Best Buy Mall');
        this.metaService.setTag('twitter:description', this.businessDetailData.about);
        this.metaService.setTag('twitter:image', this.businessDetailData.cover_image);
        this.metaService.setTag('twitter:card', 'summary_large_image');

        // window.localStorage.setItem('businessLogoUpdate', this.businessDetailData.business_logo_image);
        this.events.publish('businessLogoUpdate', this.businessDetailData.business_logo_image);
        this.events.publish('businessLandingAlisa', this.businessDetailData);
      },
      (error) => {
        // this.nao.go(100);
      }
    );
  }
  getFaqsListing(){
    var context = this;
    this.loadingData = true;
    this.dataService.getFaqsList().subscribe(
      (data) => {
        this.faqsDataList = data;
        this.loadingData = false;
        setTimeout(() => {
        }, 500);
      },
      (error) => {
        this.nao.go(100);
      }
    );
  }

  ngOnInit() {
    localStorage.setItem('isFirstTimeHomeOpen', "false");
    //this.dataControlService.setHeaderAddressVisiblity.next(true);

    window.scrollTo(0, 0);
    this.getBusinessDetail();
    this.getFaqsListing();
    // $('.storyBackBtn').css('display', 'none');
    // $('.ds-logo').css('margin-left', '0px');
    // $('.navbar').removeClass('sticky-header');
    // $('.navbar').css('background-color', '#fff');
    // $('.navbar').css('position', 'fixed');
    // $('.logInbtn').css('color', '#FFFFFF');
    // $('.navbar').css('border-bottom', '1px solid #ebebeb');
    // $('#whiteLogo').css('display', 'none');
    // $('#blackLogo').css('display', 'inline-block');
    // $('.cartHeadBtn').css('background', this.colorCode);
    // $('.cartHeadBtn').css('color', '#ffffff');
    // $('.topName').css('color', this.colorCode);
    // $('.username').css('color', '#000000');
    // $('.ds-logo').css('display', 'none');
    // $('.footer-main').css('display', 'none');

    this.widthOfWindow = $(window).width();
    $('.storyBackBtn').css('display', 'none');
    $('.ds-logo').css('margin-left', '0px');
    // $('.navbar').removeClass('sticky-header');
    $('.navbar').css('background-color', '#fff');
    // $('.navbar').css('position', 'relative');
    $('.logInbtn').css('color', '#FFFFFF');
    $('.navbar').css('border-bottom', '1px solid #ebebeb');
    $('#whiteLogo').css('display', 'none');
    $('#blackLogo').css('display', 'inline-block');
    $('.cartHeadBtn').css('background', this.colorCode);
    $('.cartHeadBtn').css('color', '#ffffff');
    $('.topName').css('color', this.colorCode);
    $('.username').css('color', '#000000');
    $('.ds-logo').css('display', 'inline-block');
    $('.footer-main').css('display', 'none');
    // $('.business-logo').css('display', 'inline');
    var width = $(window).width();

    $(window).resize(function () {

      $('.storyBackBtn').css('display', 'none');
      $('.navbar').css('background-color', '#fff');
      $('.logInbtn').css('color', '#FFFFFF');
      $('.navbar').css('border-bottom', '1px solid #ebebeb');
      $('#whiteLogo').css('display', 'none');
      $('#blackLogo').css('display', 'inline-block');
      $('.cartHeadBtn').css('background', this.colorCode);
      $('.cartHeadBtn').css('color', '#ffffff');
      $('.topName').css('color', this.colorCode);
      $('.username').css('color', '#000000');
      $('.ds-logo').css('display', 'inline-block');
      $('.footer-main').css('display', 'none');
      // $('.business-logo').css('display', 'inline');
    });

    window.addEventListener('resize', () => {
      this.widthOfWindow = $(window).width();
      this.events.publish('businessLandingAlisa', this.businessDetailData);
      $('.storyBackBtn').css('display', 'none');
      $('.ds-logo').css('margin-left', '0px');
      // $('.navbar').removeClass('sticky-header');
      $('.navbar').css('background-color', '#fff');
      // $('.navbar').css('position', 'relative');
      $('.logInbtn').css('color', '#FFFFFF');
      $('.navbar').css('border-bottom', '1px solid #ebebeb');
      $('#whiteLogo').css('display', 'none');
      $('#blackLogo').css('display', 'inline-block');
      $('.cartHeadBtn').css('background', this.colorCode);
      $('.cartHeadBtn').css('color', '#ffffff');
      $('.topName').css('color', this.colorCode);
      $('.username').css('color', '#000000');
      $('.ds-logo').css('display', 'inline-block');
      $('.footer-main').css('display', 'none');
      // $('.business-logo').css('display', 'inline');
      $('.business-logo').css('width', '100px');

      $('.business-logo').trigger('click');

      var contax = this;
      contax.ref.detectChanges();
      contax.ref.detectChanges();
    });

  }
  ngOnDestroy() {
    $('.footer-main').css('display', 'block');

  }
}
