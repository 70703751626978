import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(public router: Router) {}
  canActivate(): boolean {
    if (/bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent)) {
      window.location.href = 'http://localhost:3000/render/http://127.0.0.1:4401';
      return false;
    }
    return true;
  }
}
