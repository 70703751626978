import { Component, OnInit, Input, Output, EventEmitter,  HostListener, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from "lodash";
import { DataControlService } from '../../data-control.service';
import { fb_events_consts } from '../../fbq-constants'
import { FbEventsService } from 'src/app/fb-events.service';


declare var $: any;
declare var fbq: any;

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit, OnChanges {

  // @HostListener('document:click', ['$event'])
  // clickout(event) {
  //   if(!this.eRef.nativeElement.contains(event.target)) {
  //     this.showMenu(false);
  //   }
  // }

  @Input('filteredData') filteredData: any;
  @Output() filterDataSend = new EventEmitter<any>();
  @Output() hideShow = new EventEmitter<any>();


  searchString: '';
  filterInput = {
    search: false,
    filter: true
  }
  showDropDown: boolean = false;
  showMinDropDown: boolean = false;
  showMaxDropDown: boolean = false;

  sendMinPrice: any;
  sendMaxPrice: any;

  minPriceList: any;
  maxPriceList: any;
  minInput:any;
  maxInput:any;

  constructor(
    private eRef: ElementRef,
    private dataControlService: DataControlService,
    private fbEventsService: FbEventsService,
    ) {
    }

  // Facebook Search Event Call
  fbSearch() {
    this.fbEventsService.fbSearch();
  }

  widthOfWindow:any;
  isOnbusinessPage:any= false;
  isSeeAllProducts:boolean = false;
  ngOnInit() {
    this.minPriceList = this.filteredData.price_range.min_price;
    this.maxPriceList = this.filteredData.price_range.min_price;
    this.sendMinPrice = 'any';
    this.sendMaxPrice = 'any';
    this.widthOfWindow = $(window).width();
    window.addEventListener('resize', () => {
      this.widthOfWindow = $(window).width();
    });

    this.dataControlService.inBusinessesPage.subscribe(data => {
      if (data == true) {
        this.isOnbusinessPage = true;
      }
      else{
        this.isOnbusinessPage = false;
      }
    });
    this.dataControlService.resetFilterWithoutAPI.subscribe(data => {
      if (data == true) {
        if(this.isOnbusinessPage){
          this.resetFilterWithoutAPI();
        }
      }
    });

    this.dataControlService.expandPrefClicked.subscribe(data => {
      if (data == true) {
        // this.showDropDown = true;
        this.showMenu(true);
      }
      else{
        this.showMenu(false);
        // this.showDropDown = false;
      }
    });
    this.dataControlService.getMoreproductDetailOnProductPage.subscribe(data => {
      if (data == true) {
        setTimeout(() => {
          this.submitFilteredData('','','');
          this.dataControlService.getMoreproductDetailOnProductPage.next(false);
        }, 1000);
      }
    });
    this.dataControlService.getproductDetailOnProductPage.subscribe(data => {
      if (data !== false) {
        this.searchString = '';
        this.minInput = undefined;
        this.maxInput = undefined;
        this.dataControlService.getproductDetailOnProductPage.next(false);
      }
    });
    this.dataControlService.filterSearchString.subscribe(data =>{
      this.searchString = data;
    });
    this.dataControlService.isSeeAllProducts.subscribe(data =>{
      if(data == true){
        this.isSeeAllProducts = true;
      }
      else{
        this.isSeeAllProducts = false;
      }
    });
    this.dataControlService.filterMinVal.subscribe(data =>{
      this.minInput = data;
    });
    this.dataControlService.filterMaxVal.subscribe(data =>{
      this.maxInput = data;
    });
    this.dataControlService.submitFilteredData.subscribe(data =>{
      if(data == true){
        this.resetFilter();
        // this.dataControlService.submitFilteredData.next(false);
      }
    });
    this.dataControlService.closeFilterSearch.subscribe(data =>{
      if(data == true){
        this.searchString = '';
        this.submitFilteredData('','','');
        this.dataControlService.closeFilterSearch.next(false);

      }
    });

    this.dataControlService.emitFilterValues.subscribe(data =>{
      if(data == true){
        this.emitFilterValues();
      }
    });


    this.dataControlService.setDefaultValuesInFilterComp.subscribe(data => {
      if(data == true){
        this.minPriceList = 'any';
        this.maxPriceList = 'any';
        this.sendMinPrice = 'any';
        this.sendMaxPrice = 'any';
        this.searchString = '';
        this.maxInput = undefined;
        this.minInput = undefined;
        this.filteredData = {
          price_range: {
            min_price: 'any',
            max_price: 'any',
          },
          search: ''
        }

      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.filteredData) {
      this.searchString = this.filteredData.search;
      this.sendMinPrice = 'any';
      this.sendMaxPrice = 'any';
    }
  }

  changeInputSize(search, filter){


    this.filterInput.search = search;
    this.filterInput.filter = filter;
  }

  showMenu(status) {
    if(status == false) {
      this.showDropDown = false;
    } else{

      // if(this.showDropDown == false){
      //   this.filteredData = this.previousSavedData;
      // }
      this.showDropDown = !this.showDropDown;
      if(this.showDropDown == false){
        this.showDropDown = false;
      }
    }
    this.hideShow.emit(this.showDropDown);
  }

  showPriceMenu(listFor) {
    if(listFor === 'min') {
      this.showMaxDropDown = false;
      this.showMinDropDown = !this.showMinDropDown;
    } else if(listFor === 'max') {
      this.showMinDropDown = false;
      this.showMaxDropDown = !this.showMaxDropDown;
    }
  }
  minValChange(){

    if(this.minInput < 0){
      this.minInput = '';
    }
    this.dataControlService.filterMinVal.next(this.minInput);
  }
  maxValChange(){
    if(this.maxInput < 0){
      this.maxInput = '';
    }
    this.dataControlService.filterMaxVal.next(this.maxInput);
  }

  resetFilterWithoutAPI(){
    if(this.isOnbusinessPage){
      this.filteredData = {
        price_range: {
          min_price: 'any',
          max_price: 'any',
        },
        offers:{
          free_delivery: false,
          online_payment : false
        },
        sort: {
          near_by: false,
          name_A_to_Z: false,
          recent_updated: false,
          most_popular: false
        },
        search: ''
      }

    }
    else{
      this.filteredData = {
        price_range: {
          min_price: 'any',
          max_price: 'any',
        },
        search: ''
      }
    }

    this.searchString = '';
    this.minInput = undefined;
    this.maxInput = undefined;
    this.dataControlService.filterSearchString.next('');
    this.dataControlService.filterMaxVal.next(undefined);
    this.dataControlService.filterMinVal.next(undefined);
  }
  resetFilter(){
    if(this.isOnbusinessPage){
      this.filteredData = {
        price_range: {
          min_price: 'any',
          max_price: 'any',
        },
        offers:{
          free_delivery: false,
          online_payment: false
        },
        sort: {
          near_by: false,
          name_A_to_Z: false,
          recent_updated: false,
          most_popular: false
        },
        search: ''
      }

    }
    else{
      this.filteredData = {
        price_range: {
          min_price: 'any',
          max_price: 'any',
        },
        search: ''
      }
    }
    // this.previousSavedData = _.cloneDeep(this.filteredData);

    // this.searchString = '';
    this.minInput = undefined;
    this.maxInput = undefined;
    // this.dataControlService.filterSearchString.next('');
    this.dataControlService.filterMaxVal.next(undefined);
    this.dataControlService.filterMinVal.next(undefined);
    this.submitFilteredData('', '', '');

  }

  sendFilteredData:any;
  submitFilteredData(type, key, value){
    if(type == '' && key == '' && value == '' && this.isSeeAllProducts == false){
      this.dataControlService.filterSearchString.next(this.searchString);
    }
    this.minInput
    this.filteredData.search = this.searchString;
    if(type === 'offers') {
      this.filteredData[type][key] = value;
    }

    if(type === 'sort'){
      this.filteredData[type] = _.mapValues(this.filteredData[type], () => false);
      this.filteredData[type][key] = value;
    }

    this.sendFilteredData = _.cloneDeep(this.filteredData);
    // this.sendFilteredData = this.filteredData;
    if(type === 'price_range'){

      if(key === 'min_price') {
        let index = 0;
        // this.filteredData.price_range.min_price = this.minPriceList;
        // this.sendMinPrice = value;
        this.sendMinPrice = this.minInput;
        index =_.findIndex(this.minPriceList, function(o) { return o == value });
        this.filteredData.price_range.max_price = _.drop(this.minPriceList, index);
        if(this.sendMinPrice == 'any') {
          this.filteredData.price_range.max_price = this.maxPriceList;
        }
      }

      if( key === 'max_price') {
        let index = 0;
        // this.filteredData.price_range.max_price = this.maxPriceList;
        this.sendMaxPrice = this.maxInput;
        index =_.findIndex(this.maxPriceList, function(o) { return o == value });
        this.filteredData.price_range.min_price = _.dropRight(this.maxPriceList, this.maxPriceList.length - index - 1);
        if(this.sendMaxPrice == 'any') {
          this.filteredData.price_range.min_price  = this.minPriceList;
        }
      }

      this.sendMinPrice = this.minInput;
      this.sendMaxPrice = this.maxInput;
      this.sendFilteredData[type]['min_price'] = this.sendMinPrice;
      this.sendFilteredData[type]['max_price'] = this.sendMaxPrice;
    }
    if(this.minInput == null || this.minInput == ''){
      this.sendMinPrice = 'any';
    }
    else{
      this.sendMinPrice = this.minInput;
    }
    if(this.maxInput == null || this.maxInput == ''){
      this.sendMaxPrice = 'any';
    }
    else{
      this.sendMaxPrice = this.maxInput;
    }
    this.sendFilteredData['price_range']['min_price'] = this.sendMinPrice;
    this.sendFilteredData['price_range']['max_price'] = this.sendMaxPrice;
    if(type == '' && key == '' && value == ''){
      clearInterval(this.clearTimeout1);
      this.clearTimeout1 = setInterval(() => {
        this.filterDataSend.emit(this.sendFilteredData);
        clearInterval(this.clearTimeout1);

      }, 1000);
    }
  }
  clearTimeout1;any;
  previousSavedData:any;

  emitFilterValues(){
    this.sendFilteredData = _.cloneDeep(this.filteredData);
    if(this.minInput !== null && this.maxInput !== null){
      if(this.minInput > this.maxInput){
        let temp = this.maxInput;
        this.maxInput = this.minInput;
        this.minInput = temp;
      }

    }

    if(this.minInput == null || this.minInput == ''){
      this.sendMinPrice = 'any';
    }
    else{
      this.sendMinPrice = this.minInput;
    }
    if(this.maxInput == null || this.maxInput == ''){
      this.sendMaxPrice = 'any';
    }
    else{
      this.sendMaxPrice = this.maxInput;
    }
    this.sendFilteredData['price_range']['min_price'] = this.sendMinPrice;
    this.sendFilteredData['price_range']['max_price'] = this.sendMaxPrice;
    // let previousData = JSON.stringify(this.previousSavedData);
    // let sendData = JSON.stringify(this.sendFilteredData);
    // if(previousData !== sendData){
      // this.previousSavedData = _.cloneDeep(this.sendFilteredData);
      this.filterDataSend.emit(this.sendFilteredData);
    // }
    // else if(this.isOnbusinessPage){
    //   this.filterDataSend.emit(this.sendFilteredData);

    // }

    this.showMenu(false);
  }


}
