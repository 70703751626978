import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  country = [
    {countryCode: '+92', length: 10},
    {countryCode: '+1', length: 10},
    {countryCode: '+213', length: 9},
    {countryCode: '+973', length: 8},
    {countryCode: '+880', length: 10},
    {countryCode: '+32', length: 9},
    {countryCode: '+55', length: 11},
    {countryCode: '+61', length: 9},
    {countryCode: '+379', length: 9},
    {countryCode: '+387', length: 8},
    {countryCode: '+246', length: 7},
    {countryCode: '+359', length: 9},
    {countryCode: '+266', length: 8},
    {countryCode: '+855', length: 9},
    {countryCode: '+235', length: 6},
    {countryCode: '+56', length: 8},
    {countryCode: '+86', length: 11},
    {countryCode: '+57', length: 10},
    {countryCode: '+682', length: 5},
    {countryCode: '+385', length: 9},
    {countryCode: '+357', length: 6},
    {countryCode: '+420', length: 9},
    {countryCode: '+45', length: 8},
    {countryCode: '+246', length: 7},
    {countryCode: '+20', length: 10},
    {countryCode: '+44', length: 10},
    {countryCode: '+33', length: 9},
    {countryCode: '+49', length: 10},
    {countryCode: '+30', length: 10},
    {countryCode: '+852', length: 8},
    {countryCode: '+36', length: 9},
    {countryCode: '+91', length: 10},
    {countryCode: '+972', length: 9},
  ];
  constructor() { }

}
