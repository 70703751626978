import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from './constants';


import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';


// const httpOptions = {
//   headers: new HttpHeaders({ 'Content-Type': 'application/json' })
// };
// const version_number = 2.4;
// const version_number_string = '2.4';

const version_number = 3.0;
const version_number_string = '3.0';
var show_product = true;
const headers = new HttpHeaders({});
@Injectable()
export class DataService {
  pageName = '';
  businessIdForIndependentBusinessData = -1;
  userFrom = '';
  constructor(private http: HttpClient) { }
  validateCity(zip) {
    return this.http.post(Constants.apiClient + '/validate_zip', JSON.stringify({ zip, version_number }),
      { headers: headers });
  }
  getBusinessCategories(city_id) {
    return this.http.post(Constants.apiClient + '/get_business_category', JSON.stringify({ city_id, version_number }),
      { headers: headers });
  }
  getBusinessCategoryOutlet(auth_token, page, limit, city_id, business_category_id, search_string, filter_type, latitude, longitude, selected_latitude, selected_longitude, price_range_min, price_range_max, is_free_delivery, is_online_payment) {
    return this.http.post(Constants.apiClient + '/get_business_outlets', JSON.stringify({ auth_token, page, limit, city_id, business_category_id, search_string, filter_type, latitude, longitude, selected_latitude, selected_longitude, version_number, price_range_min, price_range_max, is_free_delivery, is_online_payment  }),
      { headers: headers });
  }
  getBusinessesWithCategory(city_id) {
    return this.http.post(Constants.apiAddress + '/get_business_outlets', JSON.stringify({ city_id, version_number }),
      { headers: headers });
  }
  getBusinessesWithCategoryId(city_id, name, search_string) {
    return this.http.post(Constants.apiAddress + '/get_business_outlets_with_category', JSON.stringify({ city_id, name, search_string, version_number }),
      { headers: headers });
  }
  getBusinessesSearch(city_id, search_string, auth_token) {
    return this.http.post(Constants.apiAddress + '/get_businesses', JSON.stringify({ city_id, search_string, auth_token, version_number }),
      { headers: headers });
  }
  businessDetail(business_id, current_day) {
    return this.http.post(Constants.apiAddress + '/get_business_detail', JSON.stringify({ business_id, current_day, version_number }),
      { headers: headers });
  }
  businessDetailWithServiceArea(business_id, city_id, current_day, selected_latitude, selected_longitude) {
    var business_category_id = parseInt(localStorage.getItem('business_store_category_id'));
    return this.http.post(Constants.apiAddress + '/get_business_detail', JSON.stringify({ business_id, business_category_id, city_id, current_day, selected_latitude, selected_longitude, version_number }),
      { headers: headers });
  }
  business_outlet_availability(business_id, city_id){
    return this.http.post(Constants.apiClient + '/business_outlet_availability', JSON.stringify({ business_id, city_id, version_number }),
      { headers: headers });
  }
  businessDetailFooter(business_id, current_day) {
    return this.http.post(Constants.apiAddress + '/get_business_detail', JSON.stringify({ business_id, current_day, version_number }),
      { headers: headers });
  }
  businessDetailProducts(business_outlet_id, auth_token) {
    return this.http.post(Constants.apiAddress + '/get_products', JSON.stringify({ business_outlet_id, auth_token, version_number }),
      { headers: headers });
  }
  businessDetailProducts1(dispensary_id, auth_token) {
    return this.http.post(Constants.apiClient + '/get_dispensary_product', JSON.stringify({ dispensary_id, auth_token, version_number, show_product }),
      { headers: headers });
  }
  businessSearchProducts(dispensary_id, search_string, limit, page, auth_token) {
    var business_category_id = parseInt(localStorage.getItem('business_store_category_id'));
    return this.http.post(Constants.apiClient + '/get_dispensary_product', JSON.stringify({ dispensary_id, show_product, search_string, limit, page, business_category_id, auth_token, version_number }),
      { headers: headers });
  }
  businessCategorySearchProduct(business_outlet_id, limit, page, category_id, search_string, sub_category_id, auth_token, price_range_min, price_range_max) {
    return this.http.post(Constants.apiClient + '/get_products_via_category', JSON.stringify({ business_outlet_id, limit, page, category_id, auth_token, search_string, show_product, sub_category_id, version_number, price_range_min, price_range_max }),
      { headers: headers });
  }
  businessCategoryProductList(business_outlet_id, limit, page, category_id, search_string, sub_category_id, child_category_id, auth_token, price_range_min, price_range_max) {
    return this.http.post(Constants.apiClient + '/get_products_via_category', JSON.stringify({ business_outlet_id, limit, page, category_id, auth_token, search_string, show_product, sub_category_id, child_category_id, version_number, price_range_min, price_range_max }),
      { headers: headers });
  }
  // SubCategoryProducts(business_outlet_id, limit, page, category_id, search_string, sub_category_id, auth_token) {
  //   return this.http.post(Constants.apiClient + '/get_products_via_category', JSON.stringify({ business_outlet_id, limit, page, category_id, auth_token, search_string, sub_category_id, show_product, version_number }),
  //     { headers: headers });
  // }

  searchProductsByCategory(dispensary_id, limit, page, search_string, auth_token, price_range_max, price_range_min){
    var business_category_id = parseInt(localStorage.getItem('business_store_category_id'));
    return this.http.post(Constants.apiClient + '/search_product', JSON.stringify({dispensary_id, limit, page, search_string, business_category_id, auth_token, show_product, version_number, price_range_max , price_range_min}),
      { headers: headers });
  }
  businessCategoryProducts(business_outlet_id, category_id, auth_token) {
    return this.http.post(Constants.apiAddress + '/get_products', JSON.stringify({ business_outlet_id, category_id, auth_token, version_number }),
      { headers: headers });
  }
  businessCategorySearchProducts(business_outlet_id, category_id, search_string, auth_token) {
    return this.http.post(Constants.apiAddress + '/get_products', JSON.stringify({ business_outlet_id, category_id, search_string, auth_token, version_number }),
      { headers: headers });
  }
  BannerNoticeMessage(city_id) {
    return this.http.post(Constants.apiClient + '/get_notices', JSON.stringify({ city_id, version_number }),
      { headers: headers });
  }
  productDetail(product_id, auth_token) {
    return this.http.post(Constants.apiAddress + '/get_product_detail', JSON.stringify({ product_id, auth_token, version_number }),
      { headers: headers });
  }

  generateUUID()
  {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    });
    return uuid;
  }
  googleAddress(input_string) {
    var sessiontoken = localStorage.getItem('sessiontoken');
    if (sessiontoken == null || sessiontoken == "")
    {
      sessiontoken = this.generateUUID();
      localStorage.setItem('sessiontoken', sessiontoken);
    }


    return this.http.post(Constants.apiAddress + '/get_google_place_suggestion', JSON.stringify({ input_string,sessiontoken}),
      { headers: headers });
  }
  googleAddressDetail(placeId) {
    var sessiontoken = localStorage.getItem('sessiontoken');
    localStorage.setItem('sessiontoken', "");

    return this.http.post(Constants.apiAddress + '/get_google_place_detail', JSON.stringify({ placeId,sessiontoken}),
      { headers: headers });
  }
  finalCheckout(auth_token, data_list, delivery_address, payment_gateway, business_id, current_day, business_type, open_time, close_time, day_index, outlet_id, third_party_string, current_time) {
    return this.http.post(Constants.apiAddress + '/check_out', JSON.stringify({auth_token, data_list, delivery_address, payment_gateway, business_id, current_day, business_type, open_time, close_time, day_index, outlet_id, third_party_string, current_time,  version_number}),
      { headers: headers });
  }
  get_slot_timing_of_outlet_before_check_out(auth_token, data_list, delivery_address, business_id, current_day, current_time) {
    return this.http.post(Constants.apiClient + '/get_slot_timing_of_outlet_before_check_out', JSON.stringify({auth_token, data_list, delivery_address, business_id, current_day, current_time, version_number}),
      { headers: headers });
  }
  getProfile(auth_token) {
    return this.http.post(Constants.apiClient + '/get_client_profile', JSON.stringify({auth_token,  version_number}),
      { headers: headers });
  }
  updateProfileFname(auth_token, first_name) {
    return this.http.post(Constants.apiClient + '/update_profile', JSON.stringify({auth_token,  version_number, first_name}),
      { headers: headers });
  }
  updateProfileLname(auth_token, last_name) {
    return this.http.post(Constants.apiClient + '/update_profile', JSON.stringify({auth_token,  version_number, last_name}),
      { headers: headers });
  }
  updateProfileGender(auth_token, gender) {
    return this.http.post(Constants.apiClient + '/update_profile', JSON.stringify({auth_token,  version_number, gender}),
      { headers: headers });
  }
  updateProfileDob(auth_token, date_of_birth) {
    return this.http.post(Constants.apiClient + '/update_profile', JSON.stringify({auth_token,  version_number, date_of_birth}),
      { headers: headers });
  }
  changeProfileNo(auth_token, phone_number) {
    return this.http.post(Constants.apiClient + '/update_phone', JSON.stringify({auth_token,  version_number, phone_number}),
      { headers: headers });
  }
  validateCodeProfile(auth_token, phone_number, verification_code) {
    return this.http.post(Constants.apiClient + '/validate_code', JSON.stringify({auth_token,  version_number, phone_number, verification_code}),
      { headers: headers });
  }
  changeProfileEmail(auth_token, email) {
    return this.http.post(Constants.apiClient + '/update_email', JSON.stringify({auth_token,  version_number, email}),
      { headers: headers });
  }
  validateEmailProfile(auth_token, email, verification_code) {
    return this.http.post(Constants.apiClient + '/validate_email', JSON.stringify({auth_token,  version_number, email, verification_code}),
      { headers: headers });
  }
  updateProfileImage(auth_token, file) {
    return this.http.post(Constants.apiClient + '/update_image', JSON.stringify({auth_token,  version_number, file}),
      { headers: headers });
  }
  updateHealthProfileImage(auth_token, file) {
    return this.http.post(Constants.apiClient + '/update_health_profile_image', JSON.stringify({auth_token,  version_number, file}),
      { headers: headers });
  }
  getOrderListing(auth_token, type, limit, business_id) {
    return this.http.post(Constants.apiClient + '/get_order_listing', JSON.stringify({auth_token,  version_number, type, limit, business_id}),
      { headers: headers });
  }
  getOrderListingBypage(auth_token, type, limit, page, business_id) {
    return this.http.post(Constants.apiClient + '/get_order_listing', JSON.stringify({auth_token,  version_number, type, limit, page, business_id}),
      { headers: headers });
  }
  getTrackDetail(auth_token, order_id) {
    return this.http.post(Constants.apiClient + '/track_order', JSON.stringify({auth_token,  version_number, order_id}),
      { headers: headers });
  }
  getDriverPosition(auth_token, order_id) {
    return this.http.post(Constants.apiClient + '/get_driver_position', JSON.stringify({auth_token,  version_number, order_id}),
      { headers: headers });
  }

  getStoryByBusiness(limit, dispensary_id) {
    return this.http.post(Constants.apiClient + '/get_article', JSON.stringify({version_number, limit, dispensary_id}),
      { headers: headers });
  }
  getStory(limit) {
    return this.http.post(Constants.apiClient + '/get_article', JSON.stringify({version_number, limit}),
      { headers: headers });
  }

  getStoryByPage(page, limit) {
    return this.http.post(Constants.apiClient + '/get_article', JSON.stringify({version_number, page, limit}),
      { headers: headers });
  }
  getStoryDetail(title_url) {
    return this.http.post(Constants.apiClient + '/get_article_detail', JSON.stringify({version_number, title_url}),
      { headers: headers });
  }
  getRecipeDetail(title_url) {
    return this.http.post(Constants.apiClient + '/get_recipe_detail', JSON.stringify({version_number, title_url}),
      { headers: headers });
  }
  getRecipe(limit) {
    return this.http.post(Constants.apiClient + '/get_recipe', JSON.stringify({version_number, limit}),
      { headers: headers });
  }
  getRecipeByPage(page, limit) {
    return this.http.post(Constants.apiClient + '/get_recipe', JSON.stringify({version_number, page, limit}),
      { headers: headers });
  }
  contactSport(name, email, phone_number, description, subject, is_client) {
    return this.http.post(Constants.apiClient + '/contact_support', JSON.stringify({version_number, name, email, phone_number, description, subject, is_client}),
      { headers: headers });
  }
  addNewPaymentCard(auth_token, card_number, holder_name, expiery_date, card_cvv, card_type) {
    return this.http.post(Constants.apiClient + '/add_payment_method', JSON.stringify({auth_token, version_number, card_number, holder_name, expiery_date, card_cvv, card_type}),
      { headers: headers });
  }
  removePaymentMethod(auth_token, payment_profile_id) {
    return this.http.post(Constants.apiClient + '/remove_payment_method', JSON.stringify({auth_token, version_number, payment_profile_id}),
      { headers: headers });
  }
  getPaymentMethodlist(auth_token) {
    return this.http.post(Constants.apiClient + '/get_payment_method', JSON.stringify({auth_token, version_number}),
      { headers: headers });
  }
  makePaymentMethodPrimary(auth_token, payment_profile_id) {
    return this.http.post(Constants.apiClient + '/make_payment_method_primary', JSON.stringify({auth_token, version_number, payment_profile_id}),
      { headers: headers });
  }

  cancelUserOrder(auth_token, order_id) {
    return this.http.post(Constants.apiClient + '/cancel_order', JSON.stringify({auth_token, version_number, order_id}),
      { headers: headers});
  }

  get_pending_rating(auth_token) {
    return this.http.post(Constants.apiClient + '/get_pending_rating', JSON.stringify({auth_token, version_number}),
      { headers: headers});
  }
  getReviewList(dispensary_id) {
    return this.http.post(Constants.apiClient + '/rating_detail_list', JSON.stringify({dispensary_id, version_number}),
      { headers: headers});
  }
  getReviewListPage(dispensary_id, page) {
    return this.http.post(Constants.apiClient + '/rating_detail_list', JSON.stringify({version_number, dispensary_id, page}),
      { headers: headers});
  }

  rate_driver(auth_token, rating_id, dispensary_rating_id, rating, dispensary_rating, comment) {
    return this.http.post(Constants.apiClient + '/rate_driver', JSON.stringify({auth_token, version_number, rating_id, dispensary_rating_id,  rating, dispensary_rating, comment}),
      { headers: headers});
  }

  cancel_rating(auth_token, rating_id, dispensary_rating_id) {
    return this.http.post(Constants.apiClient + '/cancel_rating', JSON.stringify({auth_token, version_number, rating_id, dispensary_rating_id}),
      { headers: headers});
  }
  addProductInWishList(auth_token, product_id, business_id) {
    return this.http.post(Constants.apiClient + '/add_product_to_wishlist', JSON.stringify({auth_token, version_number, product_id, business_id}),
      { headers: headers});
  }
  removeProductInWishList(auth_token, product_id) {
    return this.http.post(Constants.apiClient + '/remove_product_from_wish_list', JSON.stringify({auth_token, version_number, product_id}),
      { headers: headers});
  }
  getWishList(auth_token, limit, page, search_string, business_id) {
    return this.http.post(Constants.apiClient + '/get_wish_list', JSON.stringify({auth_token, limit, page, search_string, version_number, business_id}),
      { headers: headers});
  }
  getAddressList(auth_token) {
    return this.http.post(Constants.apiClient + '/get_client_address', JSON.stringify({auth_token, version_number}),
      { headers: headers});
  }
  deleteAddressList(auth_token, id) {
    return this.http.post(Constants.apiClient + '/remove_client_address', JSON.stringify({auth_token, version_number, id}),
      { headers: headers});
  }
  addNewProfileAddreess(auth_token, address_line1, city, state, country, zip, latitude, longitude, apart_no, place_label, house_building_no, route, notes) {
    return this.http.post(Constants.apiClient + '/add_new_client_address', JSON.stringify({auth_token, version_number, address_line1, city, state, country, zip, latitude, longitude, apart_no, place_label, house_building_no, route, notes}),
      { headers: headers});
  }
  editProfileAddreess(auth_token, address_line1, city, state, country, zip, latitude, longitude, apart_no, place_label, id, house_building_no, route, notes) {
    return this.http.post(Constants.apiClient + '/edit_client_address', JSON.stringify({auth_token, version_number, address_line1, city, state, country, zip, latitude, longitude, apart_no, place_label, id, house_building_no, route, notes}),
      { headers: headers});
  }
  makeProfileAddreessPrimery(auth_token, id, ) {
    return this.http.post(Constants.apiClient + '/set_primary_client_address', JSON.stringify({auth_token, version_number, id,}),
      { headers: headers});
  }
  addPhoneNoForDownload(auth_token, phone_number) {
    return this.http.post(Constants.apiClient + '/save_number_for_subscription', JSON.stringify({ auth_token, phone_number, version_number }),
      { headers: headers });
  }
  getDeliveryPlanPackges(auth_token, limit) {
    return this.http.post(Constants.apiClient + '/get_delivery_plan_packages', JSON.stringify({ auth_token, limit, version_number }),
      { headers: headers });
  }
  getPurchaseDeliveryPlanPackges(auth_token, limit, current_time) {
    return this.http.post(Constants.apiClient + '/subscription_packages_listing', JSON.stringify({ auth_token, limit, current_time, version_number }),
      { headers: headers });
  }

  purchaseSubscriptionDeliveryPlan(auth_token, payment_method, plan_id) {
    return this.http.post(Constants.apiClient + '/buy_subscription_package', JSON.stringify({ auth_token,  payment_method, plan_id, version_number }),
      { headers: headers });
  }

  uploadCustomeOrderText(auth_token, dispensary_id, order_text, images_list, delivery_address) {
    return this.http.post(Constants.apiClient + '/add_order_text', JSON.stringify({ auth_token,  dispensary_id, order_text, images_list, delivery_address, version_number }),
      { headers: headers });
  }

  uploadOrderImage(auth_token, dispensary_id, image, delivery_address) {
    const body = new FormData();
    body.append('version_number', version_number_string);
    body.append('auth_token', auth_token);
    body.append('dispensary_id', dispensary_id);
    body.append('image', image);
    body.append('delivery_address', delivery_address);
    return this.http.post(Constants.apiClient + '/upload_order_text_image', body,
      { headers: headers });
  }

  getCategoryList(auth_token, dispensary_id) {
    var business_category_id = parseInt(localStorage.getItem('business_store_category_id'));
    return this.http.post(Constants.apiClient + '/get_category_list', JSON.stringify({auth_token, dispensary_id, business_category_id, version_number }),
      { headers: headers });
  }

  getSubCategoryList(auth_token, dispensary_id, category_id) {
    return this.http.post(Constants.apiClient + '/get_sub_category_list', JSON.stringify({auth_token, dispensary_id, category_id, version_number }),
      { headers: headers });
  }
  getFaqsList() {
    return this.http.post(Constants.apiClient + '/get_faqs_listing', JSON.stringify({version_number }),
      { headers: headers });
  }
  getBusinessSeo(city_id) {
    return this.http.post(Constants.apiClient + '/get_city_seo', JSON.stringify({version_number, city_id }),
    { headers: headers });
  }
  getDealProducts() {
    return this.http.post(Constants.apiClient + '/get_deal_products', JSON.stringify({version_number }),
    { headers: headers });
  }
  get_home_seo(){
    return this.http.post(Constants.apiClient + '/get_home_seo', JSON.stringify({version_number }),
    { headers: headers });
  }
  dedicatedCategoryListing(dispensary_id) {
    return this.http.post(Constants.apiClient + '/get_dedicated_page_category_list', JSON.stringify({dispensary_id, version_number}), {
      headers: headers
    });
  }
  searchProductsViaCategoryList(business_outlet_id, limit, page, search_string, auth_token, price_range_max, price_range_min, category_id, sub_category_id, child_category_id) {
    var business_category_id = parseInt(localStorage.getItem('business_store_category_id'));
    return this.http.post(Constants.apiClient + '/get_products_via_category', JSON.stringify({ business_outlet_id, limit, page, auth_token, search_string, show_product, version_number, price_range_max, price_range_min, category_id, sub_category_id, child_category_id}),
      { headers: headers });
  }

  sendEmailToBusiness(name, email, phone_number, description, subject, is_client, business_id) {
    return this.http.post(Constants.apiClient + '/business_contact_support', JSON.stringify({version_number, name, email, phone_number, description, subject, is_client, business_id}),
      { headers: headers });
  }

  getVariantDetail(variant_id) {
    return this.http.post(Constants.apiClient + '/get_variant_detail', JSON.stringify({version_number, variant_id }),
      { headers: headers });
  }
  customOrder(business_id) {
    return this.http.post(Constants.apiClient + '/display_custom_order_form', JSON.stringify({business_id, version_number}),
      { headers: headers });
  }
  customOrderData(data) {
    data['version_number'] = version_number;
    const body = new FormData();
    body.append('version_number', version_number_string);
    body.append('auth_token', data['auth_token']);
    body.append('image', data['image']);
    body.append('dispensary_id', data['dispensary_id']);
    body.append('delivery_address', data['delivery_address']);
    return this.http.post(Constants.apiClient + '/upload_order_text_image', body,
      { headers: headers });
  }
  orderRequestText(data) {
    data['version_number'] = version_number;
    return this.http.post(Constants.apiClient + '/add_order_text', JSON.stringify(data),
      { headers: headers });
  }
  dineinVerifyLocation(data) {
    data['version_number'] = version_number;
    return this.http.post(Constants.apiClient + '/dine_in_verify_location', JSON.stringify(data),
      { headers: headers });
  }
  dineinCheckOut(data) {
    data['version_number'] = version_number;
    return this.http.post(Constants.apiClient + '/dine_in_check_out', JSON.stringify(data),
      { headers: headers });
  }
  getOutlet(data) {
    data['version_number'] = version_number;
    return this.http.post(Constants.apiClient + '/get_outlets', JSON.stringify(data),
     { headers: headers });
  }
  pickupCheckOut(data) {
    data['version_number'] = version_number;
    return this.http.post(Constants.apiClient + '/pick_up_check_out', JSON.stringify(data),
      { headers: headers });
  }

  getHealthRecords(auth_token, profile_id, record_type, page, limit) {
    return this.http.post(Constants.apiClient + '/health_record_listing', JSON.stringify({auth_token ,profile_id, record_type, page, limit, version_number }),
      { headers: headers });
  }

  addHealthRecord(data) {
    data['version_number'] = version_number;
    return this.http.post(Constants.apiClient + '/add_health_record', JSON.stringify(data),
    { headers: headers });
  }

  editHealthRecord(data) {
    data['version_number'] = version_number;
    return this.http.post(Constants.apiClient + '/edit_health_record', JSON.stringify(data),
    { headers: headers });
  }

  deleteHealthRecord(data) {
    data['version_number'] = version_number;
    return this.http.post(Constants.apiClient + '/delete_health_record', JSON.stringify(data),
    { headers: headers });
  }

  getHealthRecordProfiles() {
    return this.http.post(Constants.apiClient + '/health_profile_listing', JSON.stringify({auth_token: localStorage.getItem('token'), version_number}),
    { headers: headers });
  }

  addHealthProfile(payload) {
    payload['version_number'] = version_number;
    return this.http.post(Constants.apiClient + '/add_health_profile', JSON.stringify(payload),
      { headers: headers });
  }

  editHealthProfile(payload) {
    payload['version_number'] = version_number;
    return this.http.post(Constants.apiClient + '/edit_health_profile', JSON.stringify(payload),
      { headers: headers });
  }

  deleteHealthProfile(payload) {
    payload['version_number'] = version_number;
    return this.http.post(Constants.apiClient + '/delete_health_profile', JSON.stringify(payload),
      { headers: headers });
  }

  getOrderDetailsByID (payload) {
    payload['version_number'] = version_number;
    return this.http.post(Constants.apiClient +'/get_order_detail_order_module', JSON.stringify(payload), { headers: headers })
  }

  updateDeclinedOrder (payload){
    payload['version_number'] = version_number;
    return this.http.post(Constants.apiClient +'/update_declined_order', JSON.stringify(payload), { headers: headers })
  }
  declinedOrderData (payload){
    payload['version_number'] = version_number;
    return this.http.post(Constants.apiClient +'/declined_order_data', JSON.stringify(payload), { headers: headers })
  }
}


