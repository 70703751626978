import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AsyncLocalStorageModule } from 'angular-async-local-storage';
import {AuthenticationService} from './authentication.service';
import { DataService } from './data.service';
import { CountryService } from './country.service';
import { PushNotificationService } from './push-notification.service';
// import { SortablejsModule } from 'angular-sortablejs';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { EventsModule } from 'angular4-events';
import { TimezonePickerModule } from 'ng2-timezone-selector';
import { MyDatePickerModule } from 'mydatepicker';
import { ShareButtonsModule } from 'ngx-sharebuttons';
import { ReCaptchaModule } from 'angular5-recaptcha';
import { CardModule } from 'ngx-card';
import { RatingModule } from 'ngx-rating';
import { EmojifyModule } from 'angular-emojify';
import { ImageCropperModule } from 'ngx-image-cropper';

import { MetaGuard } from 'ng2-meta';
import { MetaModule } from 'ng2-meta';
import { MetaService } from 'ng2-meta';
import { MetaConfig } from 'ng2-meta';
import {PhonePipe} from './phone-number.pipe';
import { AuthGuardService as AuthGuard } from './auth-guard.service';
import { HostGuardService as HostGuard } from './host-guard.service';



import { AppComponent } from './app.component';
import { BusinessLandingComponent } from './business-landing/business-landing.component';
import { BusinessStoryComponent } from './business-story/business-story.component';
import { CartComponent } from './cart/cart.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DownloadQrComponent } from './download-qr/download-qr.component';
import { HeaderComponent } from './header/header.component';
import { HeaderLandingComponent } from './header-landing/header-landing.component';
import { HomeComponent } from './home/home.component';
import { NotefoundComponent } from './notefound/notefound.component';
import { PolicyComponent } from './policy/policy.component';
import { PolicyBusinessComponent } from './policy-business/policy-business.component';
import { SearchedBusinessesViewComponent } from './searched-businesses-view/searched-businesses-view.component';
import { SigninComponent } from './signin/signin.component';
import { StoryComponent } from './story/story.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { FaqsComponent } from './faqs/faqs.component';
import { FaqsBusinessComponent } from './faqs-business/faqs-business.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { SearchedBusinessProductsViewComponent } from './searched-business-products-view/searched-business-products-view.component';
import { StoryListingComponent } from './story-listing/story-listing.component';
import { RecipeListingComponent } from './recipe-listing/recipe-listing.component';
import { RecipeDetailComponent } from './recipe-detail/recipe-detail.component';
import { RecipeDetailsComponent } from './recipe-details/recipe-details.component';
import { ReturnPolicyComponent } from './return-policy/return-policy.component';
import { DedicatedCategoryProductsComponent } from './dedicated-category-products/dedicated-category-products.component';
import { ContactUsBusinessComponent } from './contact-us-business/contact-us-business.component';
import { AboutUsBusinessComponent } from './about-us-business/about-us-business.component';
import { CareersComponent } from './careers/careers.component';
import { StoreLocationsComponent } from './store-locations/store-locations.component';



const site_title = 'Best Buy Mall';
const site_description = 'Any Time Any Where';
// const site_image = 'https://s3.amazonaws.com/bestbuymall/images/app_icons/icon.png';
const site_image = 'https://cdn.bestbuymall.co/images/MDCLoVQptlSxjYLncii.png';

const metaConfig: MetaConfig = {
  useTitleSuffix: false,
  defaults: {
    title: site_title,
    titleSuffix: site_description,
    'og:image': site_image,
    'twitter:title': site_title,
    'twitter:description': site_description,
    'twitter:image': site_image,
    'twitter:card': 'summary_large_image'
  }
};


const routes: Routes = [
  {path: '', component: HomeComponent, canActivate: [AuthGuard]},
  // {path: '', component: HomeComponent
  //   , canActivate: [MetaGuard],
  //   data: {
  //     meta: {
  //       title: site_title,
  //       titleSuffix: site_description,
  //       'og:image': site_image,
  //       'twitter:title': site_title,
  //       'twitter:description': site_description,
  //       'twitter:image': site_image,
  //       'twitter:card': 'summary_large_image'
  //     }
  //   }
  // },
  {path: 'contact-us', component: ContactUsComponent},
  {path: 'terms-conditions', component: TermsConditionsComponent
    , canActivate: [MetaGuard, AuthGuard],
    data: {
      meta: {
        title: site_title,
        titleSuffix: 'Terms and Conditions | ' + site_title ,
        'og:image': site_image,
        'twitter:title': site_title,
        'twitter:description': 'Terms and Conditions | ' + site_title,
        'twitter:image': site_image,
        'twitter:card': 'summary_large_image'
      }
    }
  },
  {path: 'privacy-policy', component: PolicyComponent
    , canActivate: [MetaGuard],
    data: {
      meta: {
        title: site_title,
        titleSuffix: 'Privacy Policy | ' + site_title ,
        'og:image': site_image,
        'twitter:title': site_title,
        'twitter:description': 'Privacy Policy | ' + site_title,
        'twitter:image': site_image,
        'twitter:card': 'summary_large_image'
      }
    }
  },
  {path: 'return-policy', component: ReturnPolicyComponent
    , canActivate: [MetaGuard],
    data: {
      meta: {
        title: site_title,
        titleSuffix: 'Return Policy | ' + site_title ,
        'og:image': site_image,
        'twitter:title': site_title,
        'twitter:description': 'Return Policy | ' + site_title,
        'twitter:image': site_image,
        'twitter:card': 'summary_large_image'
      }
    }
  },
  {path: 'signin', component: SigninComponent
    , canActivate: [MetaGuard],
    data: {
      meta: {
        title: site_title,
        titleSuffix: site_description,
        'og:image': site_image,
        'twitter:title': site_title,
        'twitter:description': site_description,
        'twitter:image': site_image,
        'twitter:card': 'summary_large_image'
      }
    }
  },
  {path: 'notfound', component: NotefoundComponent
    , canActivate: [MetaGuard],
    data: {
      meta: {
        title: site_title,
        titleSuffix: site_description,
        'og:image': site_image,
        'twitter:title': site_title,
        'twitter:description': site_description,
        'twitter:image': site_image,
        'twitter:card': 'summary_large_image'
      }
    }
  },
  // {path: 'faqs', component: FaqsComponent
  //   , canActivate: [MetaGuard],
  //   data: {
  //     meta: {
  //       title: site_title,
  //       titleSuffix: site_description,
  //       'og:image': site_image,
  //       'twitter:title': site_title,
  //       'twitter:description': site_description,
  //       'twitter:image': site_image,
  //       'twitter:card': 'summary_large_image'
  //     }
  //   }
  // },
  // {path: 'faqs/:id', component: FaqsBusinessComponent
  //   , canActivate: [MetaGuard],
  //   data: {
  //     meta: {
  //       title: site_title,
  //       titleSuffix: site_description,
  //       'og:image': site_image,
  //       'twitter:title': site_title,
  //       'twitter:description': site_description,
  //       'twitter:image': site_image,
  //       'twitter:card': 'summary_large_image'
  //     }
  //   }
  // },
  {path: 'download', component: DownloadQrComponent},
  {path: 'blog/:id', component: StoryComponent},
  {path: 'blog-posts/:id', component: BusinessStoryComponent},
  {path: 'story/:id', redirectTo:'/blog/:id'},
  {path: 'stories/:id', redirectTo:'/blog-posts/:id'},
  {path: 'business-policy/:id', component: PolicyBusinessComponent},
  {path: 'business-contact-us/:id', component: ContactUsBusinessComponent},
  {path: 'business-about-us/:id', component: AboutUsBusinessComponent},
  {path: 'business-careers/:id', component: CareersComponent},
  {path: 'business-store-locations/:id', component: StoreLocationsComponent},
  {path: 'searched-business/:id', component: SearchedBusinessesViewComponent},
  {path: 'businesses/:cityname', component: SearchedBusinessesViewComponent},
  {path: 'businesses/:cityname/:businessName', component: SearchedBusinessProductsViewComponent},
  {path: 'about-us', component: AboutUsComponent},
  // {path: 'blog', component: StoryListingComponent},
  {path: 'stories', redirectTo: '/blog'},
  
  {path: 'recipes', component: RecipeListingComponent},
  {path: 'jhsdjhasd', component: RecipeDetailComponent},
  { path: 'recipe/:id', component: RecipeDetailsComponent},
  

  // {path: 'business-detail/:id', component: BusinessLandingComponent},
  {path: ':id', component: BusinessLandingComponent},
  {path: ':id/:parentId', component: DedicatedCategoryProductsComponent},
  {path: ':id/:parentId/:subcatId', component: DedicatedCategoryProductsComponent},
  {path: ':id/:parentId/:subcatId/:childcatId', component: DedicatedCategoryProductsComponent},
  {path: '**', redirectTo: '/notfound'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes),
    FormsModule,
    HttpClientModule,
    AsyncLocalStorageModule,
    // SortablejsModule.forRoot({ animation: 150 }),
    AmazingTimePickerModule,
    EventsModule.forRoot(),
    TimezonePickerModule,
    MyDatePickerModule,
    MetaModule.forRoot(metaConfig),
    ShareButtonsModule.forRoot(),
    ReCaptchaModule,
    CardModule,
    RatingModule,
    EmojifyModule,
    ImageCropperModule],
  exports: [RouterModule],
  providers: [DataService, AuthenticationService, CountryService, PushNotificationService, HttpClientModule],
  bootstrap: [AppComponent]
})
export class AppRoutingModule { }
