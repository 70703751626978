import { Component, OnInit } from '@angular/core';
import {DataService} from '../data.service';
import { DataControlService } from '../data-control.service';
import { Constants } from '../constants';

declare var $: any;
declare var Nanobar: any;
declare var toastr: any;


@Component({
  selector: 'app-return-policy',
  templateUrl: './return-policy.component.html',
  styleUrls: ['./return-policy.component.css']
})
export class ReturnPolicyComponent implements OnInit {

  colorCode: string;

  constructor(private dataControlService: DataControlService, private dataService: DataService) {
    this.colorCode = Constants.colorCode;
    this.dataService.pageName = 'businesses';
  }

  ngOnInit() {
    localStorage.setItem('isFirstTimeHomeOpen', "false");
    this.dataControlService.showBanner$.next(false);
    //this.dataControlService.setHeaderAddressVisiblity.next(true);

    window.scrollTo(0, 0);
    $('.storyBackBtn').css('display', 'none');
    $('.navbar').removeClass('sticky-header');
    $('.navbar').css('background-color', '#fff');
    $('.navbar').css('position', 'fixed');
    $('.logInbtn').css('color', '#FFFFFF');
    $('.navbar').css('border-bottom', '1px solid #ebebeb');
    $('#whiteLogo').css('display', 'none');
    $('#blackLogo').css('display', 'inline-block');
    $('.cartHeadBtn').css('background', this.colorCode);
    $('.cartHeadBtn').css('color', '#ffffff');
    $('.topName').css('color', this.colorCode);
    $('.username').css('color', '#000000');
    $('.business-logo').css('display', 'none');
    $('.navbar-header').css('margin-top', '0px');
  }
  ngOnDestroy(){
    this.dataControlService.showBanner$.next(true);
  }
}
