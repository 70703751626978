import { Component, OnInit } from "@angular/core";
import { DataService } from "../data.service";
import { Router } from "@angular/router";
import { Constants } from "../constants";
import { DataControlService } from "../data-control.service";

declare var $: any;
declare var Nanobar: any;
declare var toastr: any;

@Component({
  selector: "app-policy",
  templateUrl: "./policy.component.html",
  styleUrls: ["./policy.component.css"],
})
export class PolicyComponent implements OnInit {
  colorCode: string;

  constructor(
    private dataControlService: DataControlService,
    private dataService: DataService,
    private router: Router
  ) {
    this.colorCode = Constants.colorCode;
    this.dataService.pageName = "businesses";
    this.dataControlService.showBanner$.next(false);
    this.dataService.businessIdForIndependentBusinessData = -1;
    if (
      window.location.hostname.includes("businessweb.bestbuymall.co") ||
      window.location.hostname.includes(Constants.domainNmae)
    ) {
      this.router.navigate(
        [
          "business-policy/x---bbm-route-config-1@#$#^@$!---YY234215--X--saffg-12454jh-4b5jsj",
        ],
        { skipLocationChange: true }
      );
    } else if (
      window.location.hostname.includes("bestbuymall.co") ||
      window.location.hostname.includes("bestbuymall.co") ||
      window.location.hostname.includes("dev.bestbuymall.co") ||
      window.location.hostname.includes("dev.bestbuymall.co") ||
      window.location.hostname.includes("localhost") ||
      window.location.hostname.includes("bbm.com.pk")
    ) {
      this.dataService.pageName = "home";
      // window.localStorage.setItem('delivery_address', 'Please Select your location');
    } else {
      this.router.navigate(
        [
          "business-policy/x---bbm-route-config-1@#$#^@$!---YY234215--X--saffg-12454jh-4b5jsj",
        ],
        { skipLocationChange: true }
      );
    }
    this.dataService.userFrom = "web";
  }

  ngOnInit() {
    localStorage.setItem("isFirstTimeHomeOpen", "false");
    //this.dataControlService.setHeaderAddressVisiblity.next(true);

    window.scrollTo(0, 0);
    $(".storyBackBtn").css("display", "none");
    $(".ds-logo").css("margin-left", "0px");
    $(".navbar").removeClass("sticky-header");
    $(".navbar").css("background-color", "#fff");
    $(".navbar").css("position", "fixed");
    $(".logInbtn").css("color", "#FFFFFF");
    $(".navbar").css("border-bottom", "1px solid #ebebeb");
    $("#whiteLogo").css("display", "none");
    $("#blackLogo").css("display", "inline-block");
    $(".cartHeadBtn").css("background", this.colorCode);
    $(".cartHeadBtn").css("color", "#ffffff");
    $(".topName").css("color", this.colorCode);
    $(".username").css("color", "#000000");
    $(".business-logo").css("display", "none");
    $(".navbar-header").css("margin-top", "0px");
  }
  ngOnDestroy() {
    this.dataControlService.showBanner$.next(true);
  }
}
