import { Component, OnInit } from '@angular/core';
import {DataService} from '../data.service';
import {Router} from '@angular/router';
import { AsyncLocalStorage } from 'angular-async-local-storage';
import {EventsService} from 'angular4-events';
import { DataControlService } from '../data-control.service';

declare var $: any;
declare var Nanobar: any;
declare var toastr: any;

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  nao = new Nanobar();
  alias = '';
  errorAlias = false;
  success: any;
  constructor(private dataControlService: DataControlService, private router: Router, private dataService: DataService, private localStorage: AsyncLocalStorage,
              private events: EventsService) { }
  // chkAlliasFunction() {
  //   if (this.alias === '') {
  //     this.errorAlias = true;
  //     return;
  //   }
  //   this.nao.go(30);
  //   this.dataService.chkAlias(this.alias).subscribe(
  //     (data) => {
  //       this.nao.go(100);
  //       this.success = data;
  //       this.success = this.success.success;
  //       if (this.success === true) {
  //         window.location.href = 'http://'+this.alias+'.bestbuymall.co';
  //       } else {
  //         this.errorAlias = true;
  //       }
  //     },
  //     (error) => {
  //       this.nao.go(100);
  //     }
  //   );
  // }
  ngOnInit() {
    localStorage.setItem('isFirstTimeHomeOpen', "false");
    //this.dataControlService.setHeaderAddressVisiblity.next(true);

    $('.business-logo').css('display', 'none');
  }

}

